import React, { useContext } from "react";
import { isBefore } from "date-fns";
import Grid from "pages/timesheet/timesheet-grid/Grid";
import Header from "pages/timesheet/timesheet-grid/Header";
import VStack from "components/design-system/layout/VStack";
import SubmitTimesheet from "pages/timesheet/timesheet-grid/SubmitTimesheet";
import LockedTimesheetFooter from "pages/timesheet/timesheet-grid/LockedTimesheetFooter";
import {
    getDateFnsFormatDate,
    getWeekStartOrEndDate,
} from "components/design-system/controls/week-navigator/WeekNavigatorMain";
import { getCalendarDateFromNewDate } from "pages/admin/offices/OfficesForm";

import { TimesheetProjectRowExpansionContextProvider } from "context/Timesheet/TimesheetProjectRowExpansionContext";
import { SubmitTimesheetContextProvider } from "context/Timesheet/SubmitTimesheetContext";
import { DrawerContextProvider } from "context/Drawer/DrawerContext";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import SessionContext from "context/app/SessionContext";

const MemoizedHeader = React.memo(Header);
const MemoizedGrid = React.memo(Grid);

export const DAY_HOURS_LIMIT = 24;

export const checkTenureNotStarted = (selectedDate, tenureStartDate) => {
    const calendarWeekStartDateOfTenure = getWeekStartOrEndDate(
        0,
        true,
        getCalendarDateFromNewDate(new Date(tenureStartDate))
    );
    return isBefore(
        getDateFnsFormatDate(
            selectedDate.year,
            selectedDate.month,
            selectedDate.day
        ),
        getDateFnsFormatDate(
            calendarWeekStartDateOfTenure.year,
            calendarWeekStartDateOfTenure.month,
            calendarWeekStartDateOfTenure.day
        )
    );
};

const TimesheetGrid = () => {
    const session = useContext(SessionContext);

    if (!session.timesheetRequired){
        window.location.replace("/timesheets/");
    }

    const { selectedTimesheetStartDate } = useContext(WeekNavigatorContext);

    const tenureNotStarted = selectedTimesheetStartDate
        ? checkTenureNotStarted(selectedTimesheetStartDate, session.startDate)
        : true;

    return (
        <TimesheetProjectRowExpansionContextProvider>
            <SubmitTimesheetContextProvider>
                <DrawerContextProvider>
                    <VStack spacing="Two">
                        <SubmitTimesheet />
                        <VStack spacing="Zero_25">
                            <MemoizedHeader />
                            {!tenureNotStarted && <MemoizedGrid />}
                        </VStack>
                        {!tenureNotStarted && <LockedTimesheetFooter />}
                    </VStack>
                </DrawerContextProvider>
            </SubmitTimesheetContextProvider>
        </TimesheetProjectRowExpansionContextProvider>
    );
};

export default TimesheetGrid;
