import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import env from "react-dotenv";

import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import EmptyState from "components/design-system/tables/EmptyState";
import FilterDrawer, {
    filterDropdownNames,
} from "pages/admin/retainers/FilterDrawer";
import RetainerDrawer from "pages/admin/retainers/RetainerDrawer";

import DrawerContext from "context/Drawer/DrawerContext";
import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";
import { DropdownMultiSelectContext } from "context/DropdownMultiSelect/DropdownMultiSelectContext";

import updateColumnsShowAttribute from "components/design-system/tables/updateColumnsShowAttribute";

import useRetainers from "pages/admin/retainers/useRetainers";

const getAPIStatusIcon = (value) => {
    if (value === 3) {
        return "success";
    } else if (value === 4) {
        return "error";
    } else return "warning";
};

const columnNamesAccessor = {
    Num: "JobCode",
    Name: "RetainerName",
    ClientName: "ClientName",
    LeadOffice: "LeadOfficeShortName",
    LeadProducer: "LeadProducerName",
    Start: "RetStartDate",
    End: "RetEndDate",
    SOWSigned: "SOWSigned",
    Billable: "IsNonBillable",
    API: "SAP_API_status_ID",
};

const columns = [
    {
        headCellProps: {
            text: "Code",
            width: getCellWidthInPercent(96),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            color: "Gray_3",
            style: { cursor: "not-allowed" },
            actionProps: cell.row.original.Active
                ? {
                    "aria-label": "job id",
                    href:
                        "/redirectto?url=" +
                        env.BESTWORK_REACT_REPORTER10_LINK +
                        "client/retainers/RetainerReport.aspx?retainerID=" +
                        cell.row.original.RetainerID +
                        "&env=" +
                        env.ENVIRONMENT_NAME +
                        "&vpn=true",
                    target: "_blank",
                    rel: "noopener noreferrer",
                }
                : {},
        }),
        accessor: columnNamesAccessor.Num,
    },
    {
        headCellProps: {
            text: "Name",
        },
        accessor: columnNamesAccessor.Name,
    },
    {
        headCellProps: {
            text: "Client Name",
            width: getCellWidthInPercent(192),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            tooltip: cell.value,
            placement: "bottom-start",
        }),
        accessor: columnNamesAccessor.ClientName,
    },
    {
        headCellProps: {
            text: `Lead \nOffice`,
            width: getCellWidthInPercent(96),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.LeadOffice,
        disableSortBy: true,
    },
    {
        headCellProps: {
            text: "Lead Producer",
            width: getCellWidthInPercent(160),
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value && !cell.row.original.Active ? "error" : null,
            iconColor: "Orange_1",
            color: cell.value?.length <= 0 || !cell.value ? "Orange_1" : null,
            text: cell.value?.length > 1 ? cell.value : "Not Assigned!",
            tooltip:
                cell.value && !cell.row.original.Active
                    ? `${cell.value} is inactive`
                    : cell.value,
            placement: "bottom-start",
        }),
        accessor: columnNamesAccessor.LeadProducer,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Start",
            width: getCellWidthInPercent(112),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "dd/MM/yyyy"),
        }),
        accessor: columnNamesAccessor.Start,
    },
    {
        headCellProps: {
            text: "End",
            width: getCellWidthInPercent(112),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "dd/MM/yyyy"),
        }),
        accessor: columnNamesAccessor.End,
    },
    {
        headCellProps: {
            text: "SOW \nSigned",
            centered: true,
            width: getCellWidthInPercent(80),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            justifyContent: "center",
            icon: cell.value ? "success" : "error",
            tooltip: cell.value ? "True" : "False",
        }),
        accessor: columnNamesAccessor.SOWSigned,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Billable",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            justifyContent: "center",
            icon: cell.value ? "error" : "success",
            tooltip: cell.value ? "False" : "True",
        }),
        accessor: columnNamesAccessor.Billable,
        show: true,
        disableSortBy: true,
    },
    {
        headCellProps: {
            text: "API",
            centered: true,
            width: getCellWidthInPercent(64),
        },
        getBodyCellProps: (cell) => ({
            justifyContent: "center",
            icon: getAPIStatusIcon(cell.value),
            tooltip: cell.value
                ? cell.row.original.SAP_API_status_Name
                : "Undefined",
        }),
        accessor: columnNamesAccessor.API,
        show: true,
        disableSortBy: true,
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(32),
        },
        accessor: "Edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=edit&id=${cell.row.original.RetainerID}`,
                replace: true,
                "aria-label": "Edit",
            },
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add Retainer",
    to: "?action=add",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.original.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: columnNamesAccessor.Num,
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
    {
        showColumnFilter: true,
        name: "Columns",
        formInputWrapperWidth: "192px",
    },
];

const multiSelectDropownOptionsArr = [
    { parent: "Lead Producer" },
    { parent: "Attributes", children: ["SOW \nSigned", "Billable", "API"] },
];

const RetainersTable = () => {
    const { queryPageIndex, queryPageSize, querySortBy, totalItemsUpdate } =
        useContext(ServerSidePaginationContext);

    const { useRetainerSSList } = useRetainers();

    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState([]);
    const [filtersAll, setFiltersAll] = useState([]);

    const showFilterVal = useRef();

    switch (
    activeFilter?.find((f) => f.id === columnNamesAccessor.Num)?.value
    ) {
        case undefined:
            showFilterVal.current = "";
            break;
        case "active":
            showFilterVal.current = true;
            break;
        case "inactive":
            showFilterVal.current = false;
            break;
        default:
            showFilterVal.current = true;
            break;
    }

    useEffect(() => {
        setFiltersAll([...filters, ...activeFilter]);
    }, [filters, activeFilter]);

    const retainersDataApi = useRetainerSSList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: showFilterVal.current,
        clientIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.client)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        clientStatus:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.clientStatus)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        apiStatusIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.API)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
    });

    const { multiSelectDropdownOptionsList, setMultiSelectDropdownOptions } =
        useContext(DropdownMultiSelectContext);

    const { show, setShow } = useContext(DrawerContext);
    const filtersConfigRef = useRef(FiltersConfig);
    const [columnsNew, setColumnsNew] = useState(columns);

    const [drawerRetainerId, setDrawerRetainerId] = useState();
    const [isRetainerDrawer, setIsRetainerDrawer] = useState(false);

    const handleDrawerClick = (retainerId) => {
        setShow(true);
        setDrawerRetainerId(retainerId);
        setIsRetainerDrawer(true);
    };

    const handleDrawerClose = () => {
        setShow(false);
        setDrawerRetainerId(null);
        setIsRetainerDrawer(false);
    };

    useEffect(() => {
        const i = columns.findIndex((f) => f.headCellProps.text === "Name");
        let newObj = [...columnsNew];

        newObj[i].getBodyCellProps = (cell) => ({
            text: cell.value ?? "-",
            actionIcon: "caret-right",
            actionProps: {
                "aria-label": "Retainer Name",
                onClick: () => handleDrawerClick(cell.row.original.RetainerID),
            },
        });

        setColumnsNew(newObj);
    }, []);

    useEffect(() => {
        if (!retainersDataApi.isLoading) {
            totalItemsUpdate(retainersDataApi?.data?.TotalItems);
        }
    }, [retainersDataApi?.data?.TotalItems]);

    useEffect(() => {
        setMultiSelectDropdownOptions(multiSelectDropownOptionsArr);
    }, []);

    const handleOnChangeDropdownColumn = (selectedValues) => {
        let newObj = [...columnsNew];
        updateColumnsShowAttribute(
            columns,
            multiSelectDropownOptionsArr,
            newObj,
            selectedValues
        );

        setColumnsNew(newObj);
    };

    const handleClick = () => {
        setShow(true);
        setDrawerRetainerId(null);
        setIsRetainerDrawer(false);
    };

    const memoizedTable = useMemo(
        () => (
            <>
                <Table
                    dataIsFetching={retainersDataApi.isFetching}
                    showTableBorder={true}
                    columns={columnsNew}
                    data={retainersDataApi?.data?.Items}
                    getRowProps={getRowProps}
                    paginationFromFE={false}
                    emptyActionProps={tableCTAProps}
                />
            </>
        ),
        [retainersDataApi, columnsNew]
    );

    if (retainersDataApi.isLoading) {
        return <>Loading...</>;
    }

    if (retainersDataApi.isError) {
        return <>Error</>;
    }

    const CTAProps = {
        leadingIcon: "filter",
        label: "Filter",
        onClick: handleClick,
    };

    return (
        <>
            {isRetainerDrawer && show && (
                <MasterDrawer
                    stickyAction={true}
                    onRequestClose={handleDrawerClose}
                    isCTAAllActions={true}
                >
                    <RetainerDrawer retainerId={drawerRetainerId} />
                </MasterDrawer>
            )}
            {show && !isRetainerDrawer && (
                <>
                    <MasterDrawer
                        stickyAction={true}
                        onRequestClose={() => setShow(false)}
                    >
                        <FilterDrawer
                            setFiltersData={setFilters}
                            initialData={filtersAll}
                        />
                    </MasterDrawer>
                </>
            )}
            {filters?.length === 0 ? (
                <>
                    <HStack
                        justify="flex-end"
                        style={{ paddingBottom: "16px" }}
                    >
                        <SecondaryButton {...tableCTAProps} />
                    </HStack>
                    <EmptyState
                        message={
                            show
                                ? "Create a list by applying your filters"
                                : "No results to show. Start by applying filters"
                        }
                        actionPropsForPrimary={CTAProps}
                    />
                </>
            ) : (
                <VStack>
                    <HStack align="end" justify="space-between">
                        <HStack align="flex-end">
                            <TableFilters
                                initialFilterData={[
                                    { name: "all", value: "all" },
                                ]}
                                filtersConfig={filtersConfigRef.current}
                                onChange={setActiveFilter}
                                multiSelectDropdownOptionsList={
                                    multiSelectDropdownOptionsList
                                }
                                handleOnChangeDropdownColumn={
                                    handleOnChangeDropdownColumn
                                }
                            />
                            <TertiaryButton
                                leadingIcon="filter"
                                label="Filter"
                                onClick={handleClick}
                                style={{ marginBottom: "5px" }}
                            />
                        </HStack>
                        <SecondaryButton {...tableCTAProps} />
                    </HStack>
                    {memoizedTable}
                </VStack>
            )}
        </>
    );
};

export default React.memo(RetainersTable);
