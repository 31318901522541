import styled from "styled-components";
import { Col } from "react-bootstrap";

export const Wrapper = styled.div`
    border-bottom: ${global.config.border.small};
    height: 40px;
`;
export const Cells = styled.div`
    display: flex;
    background-color: ${(props) =>
        props.highlighted
            ? global.config.colors.gray9
            : global.config.colors.white};
    height: 100%;
`;
export const AllocationColumn = styled(Col)`
    background: ${(props) =>
        props.disabled ? global.config.colors.gray8 : "transparent"};
    border-right: ${global.config.border.small};
    padding: 0;
    text-align: center;
    border-right: ${(props) =>
        props.outlined?.right
            ? global.config.border.cellAlert
            : global.config.border.small};
    border-left: ${(props) =>
        props.outlined?.left ? global.config.border.cellAlert : "none"};
    border-top: ${(props) =>
        props.outlined?.top ? global.config.border.cellAlert : "none"};
    border-bottom: ${(props) =>
        props.outlined?.bottom ? global.config.border.cellAlert : "none"};
`;
