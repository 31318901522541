import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import DownArrow from "components/Icon/DownArrow";
import AngleCirculeRight from "components/Icon/AngleCirculeRight";

const IndentColumn = styled(Col)`
    border: none;
    flex: 0 0 8%;
`;

const DataColumn = styled(Col)`
    border: none;
    padding: 0;
`;

const WrapperClient = styled.div`
    display: flex;
`;

const Wrapper = styled(Row)`
    padding: 0;
    margin: 0;

    background-color: ${(props) =>
        props.selected
            ? global.config.colors.gray9
            : "#FCFCFC"};
    &:hover {
        background: ${(props) => !props.selected && global.config.colors.gray9};
    }

    ${DataColumn} {
        border-bottom: ${global.config.border.small};
    }
    &:first-child {
        ${DataColumn} {
            border-top: none;
        }
    }
    &:last-child {
        ${DataColumn} {
            border-bottom: none;
        }
    }

    &:hover .rightArrow {
        path,
        circle {
            stroke: ${global.config.colors.black};
        }
    }
    &:hover .downArrow {
        path,
        circle {
            stroke: ${global.config.colors.black};
        }
    }

    .rightArrow {
        path,
        circle {
            stroke: ${global.config.colors.gray3};
        }
    }
`;

const ClientLabel = styled.span`
    margin-top: -1px;
    font-size: 10px;
    font-family: ${global.config.fonts.families.regular};
    font-weight: 400;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    left: -1.875rem;
    top: -0.2rem;
`;

const ClientHeader = ({ name, isOpen, onToggleClick, id }) => {
    return (
        <>
            <Wrapper id={`wrapper_client_${name}`}>
                <IndentColumn xs={1} />
                <DataColumn id={`col_client_${name}`}>
                    <WrapperClient id={`div_client_${name}`}>
                        <ButtonWrapper>
                            <div id={`ico1_client_${id}`} onClick={onToggleClick} style={{display: isOpen ? "" : "none"}}>
                                <DownArrow color={global.config.colors.black} size="12px"/>
                            </div>
                            <div id={`ico2_client_${id}`} onClick={onToggleClick} style={{display: isOpen ? "none" : ""}}>
                                <AngleCirculeRight  size="12px" />
                            </div>
                        </ButtonWrapper>
                        <div style={{marginTop: "-2px"}}>
                            <ClientLabel>{name}</ClientLabel>
                        </div>
                    </WrapperClient>
                </DataColumn>
            </Wrapper>
        </>
    );
};

ClientHeader.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ClientHeader;
