import { ValidationError, ServerError } from "utilities/Errors/ErrorsTypes";
import Validation from "resources/Validation";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import { useErrors } from "hooks/Errors/useErrors";

export const useValidationError = () => {
    const { insightsException } = useInsigths();
    const { handleError } = useErrors();

    const handleFormErrorResponse = (error, setError) => {
        insightsException(error);
        if (
            error instanceof ServerError &&
            error.status &&
            error?.status &&
            error?.status === 400
        ) {
            const verror = new ValidationError(
                Validation.validation,
                error.status,
                error.correlationId,
                error.body
            );
            processValidationError(verror, setError);
            return false;
        } else if (error instanceof ValidationError) {
            processValidationError(error, setError);
            return false;
        } else {
            handleError(error);
            return true;
        }
    };

    const processValidationError = (error, setError) => {
        Object.keys(error.body).forEach((key) => {
            setError(key.charAt(0).toLowerCase() + key.slice(1), {
                type: "manual",
                message: error.body[key],
            });
        });
    };

    return { handleFormErrorResponse };
};
