import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Close from "../Icon/Close";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    * > svg {
        position: absolute;
        pointer-events: none;
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        line-height: 1;
        right: 1rem;
        top: 0.9rem;
    }
`;
const TextField = styled.input`
    height: 3rem;
    border-radius: ${global.config.sizes.borderRadius};
    border: ${global.config.border.small3};
    padding: 1.5rem 2.5rem 1.5rem 3rem;
    font-size: 1rem;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    &:focus,
    :hover,
    .active {
        border: ${global.config.border.smallBlack};
    }
`;

const MagnifierWrapper = styled.span`
    position: absolute;
    top: 0;
    background-color: ${global.config.border.white};
    width: 3rem;
    height: 3rem;
    border-radius: 0.3rem;
`;

const CloseWrapper = styled.span`
    position: absolute;
    top: 0rem;
    right: 0;
    background-color: ${global.config.border.white};
    width: 2rem;
    height: 3rem;
    border-radius: 0.3rem;
    cursor: pointer;
    > svg {
        display: block;
        margin: 0 auto;
        top: 1.3rem;
        right: 0.7rem;
    }
`;

const TextInput = (props) => {
    const { type, defaultValue, icon, onChange } = props;

    const [value, setValue] = useState(defaultValue);

    const searchInput = useRef();

    const handleCancelSearch = () => {
        setValue("");
        onChange("");
        searchInput.current.focus();
    };

    function handleChange(e) {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    useEffect(() => {
        searchInput.current.focus();
    }, []);

    return (
        <Wrapper>
            <MagnifierWrapper>{icon}</MagnifierWrapper>
            <TextField
                type={type}
                value={value}
                onChange={handleChange}
                placeholder="Employee Name"
                ref={searchInput}
            />

            <CloseWrapper onClick={() => handleCancelSearch()}>
                <Close size="9"></Close>
            </CloseWrapper>
        </Wrapper>
    );
};

TextInput.defaultProps = {
    variant: "text",
    defaultValue: "",
};

TextInput.propTypes = {
    label: PropTypes.string,
};

export default TextInput;
