import React, { useContext, useEffect, useRef, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { format, isBefore } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "components/design-system/ui/Tooltip";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import { DAY_HOURS_LIMIT } from "pages/timesheet/timesheet-grid/TimesheetGrid";
import {
    DropdownListStyled,
    StyledDropdown,
} from "pages/timesheet/timesheet-grid/TimesheetGridCellDropdownStyledElement";
import { sendNotification } from "utilities/Notification";
import {
    InputFieldWrapper,
    StyledTextInput,
    StyledTimesheetCell,
    StyledTextCell,
    ReadOnlyCellWrapper,
} from "pages/timesheet/timesheet-grid/StyledElementsCell";

import SessionContext from "context/app/SessionContext";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import SubmitTimesheetContext from "context/Timesheet/SubmitTimesheetContext";
import TimesheetGridContext from "context/Timesheet/TimesheetGridContext";
import moment from "moment";
import { AllotmentTypes } from "resources/Enums";

import useTimesheetGrid from "pages/timesheet/timesheet-grid/useTimesheetGrid";

const timeOffDropdownOptions = [
    { label: "--", value: 0 },
    { label: "4 - Half Day", value: 4 },
    { label: "8 - Full Day", value: 8 },
];

const CellWithLoader = ({ inProgress, children }) => {
    return (
        <InputFieldWrapper>
            {inProgress && (
                <div
                    style={{
                        position: "absolute",
                        top: "1px",
                        right: "10px",
                        zIndex: "999",
                    }}
                >
                    <CircularProgress
                        style={{
                            color: COLOR_VALUES.Gray_2,
                            height: "12px",
                            width: "12px",
                        }}
                    />
                </div>
            )}
            {children}
        </InputFieldWrapper>
    );
};

const getTimeOffDropdownValue = (val) => {
    return timeOffDropdownOptions.filter((f) => f.value == val)?.[0]?.value;
};

const Cell = ({
    data,
    projectId,
    isTimeOff,
    readOnlyCell,
    isHolidayData,
    vacationRequestId,
    tooltipText,
    reasonId,
    projectHours
}) => {
    const OVERLAPS_PENDING_HR_TIME_OFF_MESSAGE = "You already have an HR pending request for this day. Please, contact talentsupport@rga.com";
    const MAX_HOURS_EXCEEDED_TIME_OFF_MESSAGE = "You have entered more than 8 time-off hours for a day. Please reduce the time-off hours";
    const OVERLAPS_APPROVED_TIME_OFF_MESSAGE = "You already have an approved request for this day";
    const ALLOTMENT_EXCEEDED_TIME_OFF_MESSAGE = "Unpaid leave requested. Follow up your request in Time-off tab";

    const session = useContext(SessionContext);
    const { selectedTimesheetStartDate } = useContext(WeekNavigatorContext);
    const {
        selectedDateTimesheetStatus,
        setHoursUpdateState,
        setHoursUpdateInProgress,
    } = useContext(SubmitTimesheetContext);

    const {
        pendingHRTimeOffInWeek,
        approvedTimeOffInWeek,
        holidays,
        setDisableSubmitTimesheetButton,
        usedAllotment
    } = useContext(TimesheetGridContext);

    const { updateHoursTimeEntries } = useTimesheetGrid();
    const [inputValue, setInputValue] = useState(data?.timeSpan || "");
    const inputValRef = useRef("");
    const [hoursUpdated, setHoursUpdated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const backspaceKeyDownRef = useRef(false);
    const [isShown, setIsShown] = useState(false);
    const [disabledOptionsList, setDisabledOptionsList] = useState([]);

    const [dropdownSelectedValue, setDropdownSelectedValue] = useState(
        getTimeOffDropdownValue(data?.timeSpan)
    );

    useEffect(() => {
        !inProgress && setInputValue(data?.timeSpan || "");
    }, [data, selectedTimesheetStartDate]);

    useEffect(() => {
        if (dropdownSelectedValue === 4 && isTimeOff) {
            setDisabledOptionsList([{ label: "8 - Full Day", value: 8 }]);
        }
        else {
            setDisabledOptionsList([]);
        }
    }, [dropdownSelectedValue]);

    useEffect(() => {
        !inProgress &&
            setDropdownSelectedValue(getTimeOffDropdownValue(data?.timeSpan));
    }, [data, selectedTimesheetStartDate]);

    const handleOnInputChange = (e) => {
        let checkCondition = e.target.value.match(/^[0-9.\b]+$/)?.length === 1;

        if (checkCondition || backspaceKeyDownRef.current) {
            const value = checkCondition
                ? e.target.value
                : backspaceKeyDownRef.current && "";

            setInputValue(value);
            inputValRef.current = value;
            if (Number(value) !== data?.timeSpan) {
                setHoursUpdated(true);
            } else {
                setHoursUpdated(false);
            }

            if (value > 24) {
                setDisableSubmitTimesheetButton(true);
                sendNotification(
                    undefined,
                    "More than 24 hours have been entered to a day. Please reduce the hours for that day.",
                    5000,
                    "",
                    null,
                    true,
                    false,
                    null,
                    true,
                    "warning"
                );
            } else {
                setDisableSubmitTimesheetButton(false);
            }
        }
    };

    const errorCondition =
        Number(inputValRef.current) > DAY_HOURS_LIMIT ? true : false;
    const handleClickAway = () => {
        if (hoursUpdated && !errorCondition) {
            setHoursUpdated(false);
            setHoursUpdateState(true);
            const val = Number(inputValRef.current);
            let inputValUpdate = val;
            const valCheck = val - Math.floor(val);
            let beforeDecimal = inputValRef.current?.toString().split(".")[0];
            beforeDecimal = beforeDecimal && Number(beforeDecimal);

            if (valCheck !== 0) {
                const floatVal = parseFloat(valCheck).toFixed(2);

                switch (true) {
                    case floatVal <= 0.12:
                        setInputValue(beforeDecimal);
                        inputValUpdate = beforeDecimal;
                        break;
                    case floatVal >= 0.13 && floatVal <= 0.37:
                        setInputValue(beforeDecimal + 0.25);
                        inputValUpdate = beforeDecimal + 0.25;
                        break;
                    case floatVal >= 0.38 && floatVal <= 0.62:
                        setInputValue(beforeDecimal + 0.5);
                        inputValUpdate = beforeDecimal + 0.5;
                        break;
                    case floatVal >= 0.63 && floatVal <= 0.87:
                        setInputValue(beforeDecimal + 0.75);
                        inputValUpdate = beforeDecimal + 0.75;
                        break;
                    case floatVal >= 0.88:
                        setInputValue(beforeDecimal + 1);
                        inputValUpdate = beforeDecimal + 1;
                        break;
                    default:
                        return;
                }
            }

            if (inputValUpdate >= 0) {
                setInProgress(true);
                setHoursUpdateInProgress(true);

                let entryDateVal = format(new Date(data.timeDay), "yyyy-MM-dd");
                let requestData = {
                    employeeId: session.legacyId,
                    jobId: projectId,
                    titleId: session.currentLegacyTitleId,
                    entryDate: entryDateVal,
                    hours: Number(inputValUpdate),
                    vacationRequestId:
                        vacationRequestId == null ? 0 : vacationRequestId,
                    agencyId: session.agencyId,
                    isHoliday: !!isHolidayData,
                    officeId: session.currentOfficeId,
                    reasonId: reasonId ?? 0
                };
                updateHoursTimeEntries.mutateAsync(requestData).then(() => {
                    setInProgress(false);
                    setHoursUpdateInProgress(false);
                });
            }
        }
    };

    const handleOnKeyDown = (e) => {
        if (e.key === "Backspace") {
            backspaceKeyDownRef.current = true;
        } else {
            backspaceKeyDownRef.current = false;
        }

        if (e.key === "Enter" || e.key === "Tab") {
            handleClickAway();
        }
    };

    const saveHoursCall = (hoursVal) => {
        setInProgress(true);
        setHoursUpdateInProgress(true);

        let date =
            data.timeDay == null ? new Date(data.Date) : new Date(data.timeDay);
        let entryDateVal = format(date, "yyyy-MM-dd");
        let requestData = {
            employeeId: session.legacyId,
            jobId: projectId,
            titleId: session.currentLegacyTitleId,
            entryDate: entryDateVal,
            hours: isNaN(hoursVal) ? 0 : Number(hoursVal),
            vacationRequestId:
                vacationRequestId == null ? 0 : vacationRequestId,
            agencyId: session.agencyId,
            isHoliday: !!isHolidayData,
            officeId: session.currentOfficeId,
            reasonId: reasonId ?? 0
        };
        updateHoursTimeEntries.mutateAsync(requestData).then(() => {
            setInProgress(false);
            setHoursUpdateInProgress(false);
        });
    };

    const handleOnChangeDropdwon = (newVal) => {
        if (newVal === 0 || isTimeOffValid(newVal)) {
            allotmentWarning(newVal);
            setDropdownSelectedValue(newVal);
            saveHoursCall(newVal);
        }
    };

    const isTimeOffValid = (hours) => {
        if (isTimeOff || isHolidayData) {
            if (overlapsInMiddleOfRequest(pendingHRTimeOffInWeek, OVERLAPS_PENDING_HR_TIME_OFF_MESSAGE)) {
                return false;
            }

            if (overlapsInMiddleOfRequest(approvedTimeOffInWeek, OVERLAPS_APPROVED_TIME_OFF_MESSAGE)) {
                return false;
            }

            if (isMaxHoursForTimeOffExceeded(hours)) {
                return false;
            }
        }
        return true;
    }

    const allotmentWarning = (hours) => {
        if (vacationRequestId)
            return;

        var ptoData = usedAllotment.find(x =>
            x.allotmentTypeId === AllotmentTypes.PTO
            && x.reasonId === reasonId);

        if (isTimeOff && ptoData && hours !== 0) {
            var remaining = ptoData.allotment - ptoData.daysUsed - (hours / 8);
            if (remaining < 0) {
                sendWarningNotification(ALLOTMENT_EXCEEDED_TIME_OFF_MESSAGE);
            }
        }
    }

    const isMaxHoursForTimeOffExceeded = (hours) => {
        // check for exceeded with holidays
        // if the user is changing a holiday we don't need to calculate this.
        let totalHoursHolidays = 0
        if (!isHolidayData) {
            totalHoursHolidays = getSavedHolidaysForCurrentDay();
            if (totalHoursHolidays + hours > 8) {
                sendWarningNotification(MAX_HOURS_EXCEEDED_TIME_OFF_MESSAGE);
                return true;
            }
        }

        // check for exceeded with already approved requests
        const totalHoursApproved = getTotalHoursInCurrentDay(approvedTimeOffInWeek);
        if (totalHoursApproved + hours > 8) {
            sendWarningNotification(MAX_HOURS_EXCEEDED_TIME_OFF_MESSAGE);
            return true;
        }

        // check for exceeded with pending HR requests
        const totalHoursPendingHR = getTotalHoursInCurrentDay(pendingHRTimeOffInWeek);
        if (totalHoursPendingHR + hours > 8) {
            sendWarningNotification(OVERLAPS_PENDING_HR_TIME_OFF_MESSAGE);
            return true;
        }

        // check for exceeded all together
        if (totalHoursPendingHR + totalHoursApproved + totalHoursHolidays + hours > 8) {
            sendWarningNotification(MAX_HOURS_EXCEEDED_TIME_OFF_MESSAGE);
            return true;
        }

        return false;
    };

    const overlapsInMiddleOfRequest = (requests, message) => {
        if (requests) {

            const currentDate = data.timeDay
                ? moment(data.timeDay).format("YYYY-MM-DD")
                : moment(data.Date).format("YYYY-MM-DD");

            const overlaps = requests.some(x => {

                if (vacationRequestId && vacationRequestId === x.vacationRequestId)
                    return false;

                const FD = moment(x.firstDayOut).format("YYYY-MM-DD");
                const LD = moment(x.lastDayOut).format("YYYY-MM-DD");
                if ((moment(FD).isSame(LD))) {
                    return false;
                }
                const isBetween = moment(currentDate).isAfter(FD) && moment(currentDate).isBefore(LD);
                return isBetween;
            });

            if (overlaps) {
                sendWarningNotification(message);
                return true;
            }
        }
        return false;
    }

    // We check only for first day and last day for every request
    const getTotalHoursInCurrentDay = (requests) => {

        let totalHours = 0;
        const currentDate = data.timeDay
            ? moment(data.timeDay).format("YYYY-MM-DD")
            : moment(data.Date).format("YYYY-MM-DD");

        requests?.forEach(x => {

            if (vacationRequestId && vacationRequestId === x.vacationRequestId)
                return false;

            let FD = moment(x.firstDayOut).format("YYYY-MM-DD");
            let LD = moment(x.lastDayOut).format("YYYY-MM-DD");

            if (moment(currentDate).isSame(FD)) {
                totalHours += x.fdMorning && x.fdAfternoon ? 8 : 4;
            }
            else if (!(moment(LD).isSame(FD))) {
                if (moment(currentDate).isSame(LD)) {
                    totalHours += x.ldMorning && x.ldAfternoon ? 8 : 4;
                }
            }
        });

        return totalHours;
    }

    const getSavedHolidaysForCurrentDay = () => {

        if (!holidays?.Holidays) {
            return 0;
        }

        const currentDate = moment(data.timeDay).format("YYYY-MM-DD");

        // get TO projects
        const toProjects = projectHours.filter(x => x.isHoliday).map(x => x.hours);
        const toProjectsFlatted = [].concat(...toProjects);

        // get Saved hours
        const hoursSaved = toProjectsFlatted.filter(x => x.hasOwnProperty("Id") || x.hasOwnProperty("jobId"));

        // get saved hours on current date
        const sameDate = hoursSaved.filter(x => {
            const date = x.timeDay ? x.timeDay : x.Date;
            return moment(currentDate).isSame(moment(date).format("YYYY-MM-DD"));
        });

        const allHours = sameDate.map(x => x.timeSpan);
        const total = allHours.reduce((a, b) => a + b, 0);

        return total;
    }

    const sendWarningNotification = (message) => {
        sendNotification(undefined,
            message,
            5000,
            "",
            null,
            true,
            false,
            null,
            true,
            "warning"
        );
    }

    const tenureNotStarted = isBefore(
        new Date(data.timeDay),
        new Date(session.startDate)
    );

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <StyledTimesheetCell
                    disabled={
                        tenureNotStarted
                        || selectedDateTimesheetStatus
                        || (isHolidayData && !data?.timeSpan)
                        || data.isWeekendCellDisable
                        || readOnlyCell
                    }
                >
                    {data
                        && !tenureNotStarted
                        && !data.isWeekendCellDisable
                        && readOnlyCell
                        && (
                            <Tooltip enterDelay={0} text={tooltipText}>
                                <ReadOnlyCellWrapper>
                                    <StyledTextCell variant="Body_2_2" color="Gray_1">
                                        {data.timeSpan}
                                    </StyledTextCell>
                                </ReadOnlyCellWrapper>
                            </Tooltip>

                        )}

                    {data &&
                        !tenureNotStarted &&
                        !readOnlyCell &&
                        (isTimeOff || isHolidayData) &&
                        !data.isWeekendCellDisable && (
                            <CellWithLoader inProgress={inProgress}>
                                <div style={{ width: "100%" }}>
                                    <DropdownListStyled />
                                    <StyledDropdown
                                        value={dropdownSelectedValue}
                                        displayValueOnButton={true}
                                        options={timeOffDropdownOptions}
                                        onChange={(val) =>
                                            handleOnChangeDropdwon(val)
                                        }
                                        placeholder="--"
                                        disabledOptionsList={disabledOptionsList}
                                        $hasSelection={!!dropdownSelectedValue}
                                        showIcon={false}
                                        showIconOnHover={isShown}
                                        onMouseEnter={() => setIsShown(true)}
                                        onMouseLeave={() => setIsShown(false)}
                                        disabled={selectedDateTimesheetStatus}
                                    />
                                </div>
                            </CellWithLoader>
                        )}
                    {data &&
                        !tenureNotStarted &&
                        !readOnlyCell &&
                        !isTimeOff &&
                        !isHolidayData && (
                            <CellWithLoader inProgress={inProgress}>
                                <StyledTextInput
                                    placeholder="--"
                                    value={inputValue}
                                    onChange={handleOnInputChange}
                                    onKeyDown={handleOnKeyDown}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => {
                                        e.target.placeholder = "--";
                                        if (Number(e.target.value) === 0) {
                                            setInputValue("");
                                        }
                                    }}
                                    feedbackType={
                                        errorCondition
                                            ? FEEDBACK_TYPES.Error
                                            : FEEDBACK_TYPES.Success
                                    }
                                    disabled={selectedDateTimesheetStatus}
                                />
                            </CellWithLoader>
                        )}
                    {((!data && !data.isWeekendCellDisable) ||
                        tenureNotStarted) && (
                            <StyledTextCell variant="Body_2_1" color="Gray_4">
                                &#45;&#45;
                            </StyledTextCell>
                        )}
                </StyledTimesheetCell>
            </ClickAwayListener>
        </>
    );
};

export default Cell;
