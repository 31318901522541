import PropTypes from "prop-types";
import styled from "styled-components";

const Path = styled.path`
    stroke: ${(props) => props.color};
`;

const Icon = styled.svg`
    fill: none;

    ${Path} {
        stroke: ${(props) => props.color};
    }

    &:hover {
        ${Path} {
            stroke: ${(props) =>
                props.hover ? props.hoverColor : props.color};
        }
    }
`;

const Note = ({ size, color, viewBox, hover, hoverColor }) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={"0 0 " + viewBox + " " + viewBox}
            width={size}
            height={size}
            color={color}
            hover={hover}
            hoverColor={hoverColor}
        >
            <g clipPath="url(#clip0)">
                <Path
                    d="M4.00098 2.34277V15.6651H13.9927V6.34526H10.2103V2.34277H4.00098Z"
                    color={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <Path
                    d="M10.3027 2.23242L14.0851 6.2407"
                    color={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="10.6694"
                        height="14"
                        fill="white"
                        transform="translate(3.66504 2)"
                    />
                </clipPath>
            </defs>
        </Icon>
    );
};

Note.defaultProps = {
    size: "18",
    color: global.config.colors.gray2,
    viewBox: "16",
    hover: false,
    hoverColor: global.config.colors.black,
};

Note.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    viewBox: PropTypes.string,
    hover: PropTypes.bool,
    hoverColor: PropTypes.string,
};

export default Note;
