import { useEffect, useState } from "react";
import env from "react-dotenv";
import moment from "moment";
import { format } from "date-fns";
import { useCrypto } from "hooks/Crypto/useCrypto";

import Loader from "components/UI/Loader";
import Subject from "components/design-system/ui/drawer/Subject";
import ContactCard from "components/design-system/ui/drawer/ContactCard";
import DataGroup from "components/design-system/ui/drawer/DataGroup";
import DataItem from "components/design-system/ui/drawer/DataItem";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";

import { useProjects } from "utilities/API/Projects";

const ProjectDrawer = (props) => {
    const { getProjectDetail } = useProjects();
    const { encrypt } = useCrypto();
    const { projectId } = props;
    const [project, setProject] = useState({
        name: null,
        jobCode: null,
        client: null,
        clientFamily: null,
        startDate: null,
        endDate: null,
        status: null,
        completion: 0,
        producers: [],
        jobId: null,
        statusName: null,
        statusUpdatedDate: null,
        statusLikelihood: 0,
    });

    const updatedDate = format(
        new Date(project.statusUpdatedDate),
        "MMM dd, yyyy"
    );

    useEffect(() => {
        if (projectId) {
            getProjectDetail([{ name: "$jobId", value: projectId }])
                .then((payload) => {
                    if (!payload.error && payload.length > 0) {
                        setProject({
                            name: payload[0].JobName,
                            jobCode: payload[0].JobCode,
                            client: payload[0].Client,
                            clientFamily: payload[0].ClientFamily,
                            startDate: payload[0].JobStartDate,
                            endDate: payload[0].JobEndDate,
                            status: payload[0].SOWStatus,
                            completion: payload[0].CompletePercentage
                                ? parseInt(payload[0].CompletePercentage)
                                : 0,
                            producers: payload[0].LeadProducer,
                            jobId: payload[0].JobId,
                            office: payload[0].Office,
                            retainer: payload[0].Retainer,
                            statusName: payload[0].SowStatusName,
                            statusUpdatedDate: payload[0].SOWStatusUpdated,
                            statusLikelihood: payload[0].SowStatusLikelihood,
                        });
                    }
                })
                .catch(() => {
                    /* */
                });
        }
    }, [projectId]);

    return (
        <>
            {projectId && project?.name ? (
                <>
                    <Subject
                        subjectName={project.name}
                        codeNumber={`Job Code: ${project.jobCode}`}
                        showPhoto={false}
                        isMoreIcon={false}
                    />

                    {project.statusLikelihood !== 0 &&
                        project.statusLikelihood &&
                        project.statusName !== "N/A" &&
                        project.statusName !== "None" && (
                            <DataItem
                                label="SOW Status"
                                updatedDate={updatedDate}
                                value={project.statusLikelihood}
                                title={project.statusName}
                                showMessage={true}
                            />
                        )}

                    {project.producers.map((producer, index) => (
                        <ContactCard
                            key={index}
                            contactCardLabel="Lead Producer"
                            photoUrl={
                                producer.LargePhotoUrl &&
                                `${env.BESTWORK_REACT_ASSET_DOMAIN}/${producer.LargePhotoUrl}`
                            }
                            name={producer.FullName}
                            jobPost={producer.Title}
                            department={producer.Department}
                            location={producer.Locations}
                            emailId={producer.Email}
                            slackId={producer.SlackUsername}
                        />
                    ))}

                    <DataGroup
                        groupData={[
                            {
                                label: "Client",
                                value: project.client,
                            },
                            {
                                label: "Client Family",
                                value: project.clientFamily,
                            },
                            {
                                label: "Retainer",
                                value: project.retainer,
                            },
                            {
                                label: "Lead Office",
                                value: project.office,
                            },
                            {
                                label: "Project Start Date",
                                value: moment(project.startDate).format(
                                    "MMM D, YY"
                                ),
                            },
                            {
                                label: " Expected End",
                                value: moment(project.endDate).format(
                                    "MMM D, YY"
                                ),
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Completion",
                                value: `${project.completion}%`,
                                percent: parseInt(project.completion),
                            },
                        ]}
                    />

                    <DrawerActions
                        primaryAction={{
                            type: "button",
                            label: "View Project Allocations",
                            to: `${
                                global.config.routes.allocation
                            }?filters=${encrypt(
                                "jobId=" + project.jobId
                            )}`,
                            isInternalUrlNewTab: true,
                        }}
                    />
                </>
            ) : (
                <Loader height="5rem" width="5rem" />
            )}
        </>
    );
};

export default ProjectDrawer;
