import React, { useContext, useEffect, useRef, useState } from "react";
import { today, getLocalTimeZone, isToday } from "@internationalized/date";
import { compareAsc, format } from "date-fns";
import styled from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon from "components/design-system/ui/Icon";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import WeekNavigatorMain from "components/design-system/controls/week-navigator/WeekNavigatorMain";
import {
    TimesheetCellHeadDayText,
    TimesheetRowHead,
    TimesheetCellHead,
    TimesheetCellDropdown,
} from "pages/timesheet/timesheet-grid/StyledElements";
import { DAY_HOURS_LIMIT } from "pages/timesheet/timesheet-grid/TimesheetGrid";

import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import TimesheetGridContext from "context/Timesheet/TimesheetGridContext";
import SessionContext from "context/app/SessionContext";

import { useWeeksTimeEntriesApi } from "pages/timesheet/timesheet-grid/useTimesheetGrid";
import { useTitles } from "utilities/API/Titles";
import moment from "moment";
import useTimeOffApi from "../time-off/useTimeOff";

const StyledErrorHStack = styled(HStack)`
    ::after {
        content: "";
        width: 100%;
        height: 2px;
        background: ${COLOR_VALUES.Orange_1};
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
    }
`;

const Header = () => {
    const session = useContext(SessionContext);
    const { useWeeksTimeEntries } = useWeeksTimeEntriesApi();

    const {
        setIncompleteWeekStartDateList,
        fromDateToUpdateList,
        updateList,
        setUpdateList,
        setIsWeekChange,
        selectedTimesheetStartDate,
        setSelectedTimesheetStartDate,
    } = useContext(WeekNavigatorContext);

    const {
        projectHours,
        setWeekColumnDayHoursLimitExceed,
        setWeekDetails,
        setTotalOfWeekHours,
    } = useContext(TimesheetGridContext);

    const weekHoursRef = useRef([]);
    const [weekColumnTotal, setWeekColumnTotal] = useState([]);

    let fromDateVal = fromDateToUpdateList ?? selectedTimesheetStartDate;
    fromDateVal = fromDateVal ?? today(getLocalTimeZone());

    const requestData = {
        employeeId: session.legacyId,
        fromDate: `${fromDateVal.year}-${fromDateVal.month}-${fromDateVal.day}`,
    };
    const weeksData = useWeeksTimeEntries(requestData);

    const handleOnChange = (dateValue) => {
        setSelectedTimesheetStartDate(dateValue);
        setIsWeekChange(true);
    };

    const { useGetUserTitles } = useTitles();

    const titlesQueryResult = useGetUserTitles({ userId: session.legacyId });

    const { useGetApprovedTimeOffByDate } = useTimeOffApi();
    const approvedTimeOffs = useGetApprovedTimeOffByDate({
        from: `${fromDateVal.year - 1}-1-1`,
        until: `${fromDateVal.year + 1}-1-1`,
    });

    useEffect(() => {
        const len = projectHours?.length || 0;
        weekHoursRef.current = [0, 0, 0, 0, 0, 0, 0];

        for (let i = 0; i < len; i++) {
            for (let j = 0; j < 7; j++) {
                weekHoursRef.current[j] =
                    weekHoursRef.current[j] +
                    (projectHours[i]?.hours[j]?.timeSpan || 0);
            }
        }
        let total = 0;
        weekHoursRef.current.forEach((hour) => (total += hour));
        setTotalOfWeekHours(total);
        setWeekColumnTotal(weekHoursRef.current);

        if (weekHoursRef.current.some((d) => d > DAY_HOURS_LIMIT)) {
            setWeekColumnDayHoursLimitExceed(true);
        } else {
            setWeekColumnDayHoursLimitExceed(false);
        }
    }, [projectHours]);

    useEffect(() => {
        if (!weeksData.isLoading) {
            setWeekDetails(weeksData?.data);
        }
    }, [weeksData]);

    useEffect(() => {
        if (!weeksData.isLoading && updateList) {
            setIncompleteWeekStartDateList(
                weeksData?.data?.filter(
                    (w) =>
                        !w.completed &&
                        compareAsc(new Date(w.date), new Date()) <= 0
                )
            );
            setUpdateList(false);
        }
    }, [weeksData, updateList]);

    if (weeksData.isError) {
        return <>Error</>;
    }

    let weekDates = [];
    if (selectedTimesheetStartDate) {
        for (let i = 0; i < 7; i++) {
            const weekDayDate = selectedTimesheetStartDate.add({ days: i });
            const dateFormat = new Date(
                weekDayDate.year,
                weekDayDate.month - 1,
                weekDayDate.day
            );

            weekDates.push({
                calendarDate: weekDayDate,
                dayName: format(dateFormat, "EEE"),
                monthName: format(dateFormat, "MMM"),
            });
        }
    }

    const getTimesheetRequiredDateRanges = () => {

        let dateRangesTimesheetRequired = [];

        titlesQueryResult?.data?.forEach(x => {
            if (x.Title.TimeSheetRequired) {
                dateRangesTimesheetRequired.push({
                    startDate: moment(x.TitleStartDate).startOf("isoWeek").format("YYYY-MM-DD").toString(),
                    endDate: x.TitleEndDate
                        ? x.TitleEndDate
                        : moment(new Date()).format("YYYY-MM-DD")
                });
            }
        });

        return dateRangesTimesheetRequired;
    }

    const getOnleaveDateRanges = () => {
        let dateRangesOnLeave = [];
        if (session.freelancer) {
            approvedTimeOffs?.data?.forEach(x => {
                dateRangesOnLeave.push({
                    startDate: moment(x.firstDayOut).format("YYYY-MM-DD").toString(),
                    endDate: moment(x.lastDayOut).format("YYYY-MM-DD").toString(),
                    icon: "error",
                    label: "On Leave"
                });
            });
        }

        return dateRangesOnLeave;
    }

    return (
        <>
            <TimesheetRowHead>
                <TimesheetCellDropdown>
                    <WeekNavigatorMain
                        label="Week Navigator"
                        defaultValue={today(getLocalTimeZone())}
                        onChange={handleOnChange}
                        dateOnChange={selectedTimesheetStartDate}
                        dateRangesWithWarning={getTimesheetRequiredDateRanges()}
                        dateRangesWithIcon={getOnleaveDateRanges()}
                    />
                </TimesheetCellDropdown>
                {weekDates.map((data, index) => (
                    <TimesheetCellHead key={index}>
                        <VStack>
                            <VStack spacing="Zero_25">
                                <TimesheetCellHeadDayText
                                    variant="Headline_5"
                                    color="Black"
                                    current={isToday(data.calendarDate)}
                                >
                                    {data.dayName}
                                </TimesheetCellHeadDayText>
                                <Text variant="Descriptive_2_1" color="Gray_2">
                                    {data.monthName} {data.calendarDate.day}
                                </Text>
                            </VStack>
                            {(data.dayName === "Sun" && !weekColumnTotal[6]) ||
                                (data.dayName === "Sat" && !weekColumnTotal[5]) ? (
                                <Text variant="Headline_4" color="Black">
                                    &#45;&#45;
                                </Text>
                            ) : (
                                <>
                                    {weekColumnTotal[index] >
                                        DAY_HOURS_LIMIT ? (
                                        <StyledErrorHStack
                                            align="center"
                                            justify="space-between"
                                        >
                                            <Text
                                                variant="Headline_4"
                                                color="Orange_1"
                                            >
                                                {weekColumnTotal[index]}
                                            </Text>
                                            <Icon
                                                color="Orange_1"
                                                name="error"
                                            />
                                        </StyledErrorHStack>
                                    ) : (
                                        <Text
                                            variant="Headline_4"
                                            color="Black"
                                        >
                                            {weekColumnTotal[index] ?? 0}
                                        </Text>
                                    )}
                                </>
                            )}
                        </VStack>
                    </TimesheetCellHead>
                ))}
            </TimesheetRowHead>
        </>
    );
};

export default Header;
