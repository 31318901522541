import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    background-color: inherit;
    border: none;
    padding: 0;
    width: fit-content;
    cursor: pointer;
    padding: 0.3rem 0.5rem;
    &:hover {
        background-color: ${global.config.colors.gray7};
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:active {
        background-color: ${global.config.colors.gray5};
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        outline: none;
    }
`;
const IconButton = ({ icon, onClick, ...props }) => {
    return (
        <StyledButton
            className={props.className}
            onClick={(event) => onClick(event)}
            {...props}
        >
            {icon}
        </StyledButton>
    );
};

IconButton.propTypes = {};

export default IconButton;
