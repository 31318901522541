import PropTypes from "prop-types";

const Dot = ({ width, height, color }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={"0 0 " + width + " " + height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="4" cy="4" r="4" fill={color} />
        </svg>
    );
};

Dot.defaultProps = {
    width: "8",
    height: "8",
    color: "inherit",
};

Dot.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default Dot;
