import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import VStack from "components/design-system/layout/VStack";
import Icon from "components/design-system/ui/Icon";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";

export const AccordionWrapperStyle = `
    align-items: flex-start;
    cursor: pointer;
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    &:hover {
        border-bottom-color: ${COLOR_VALUES.Gray_4};
    }
`;

export const AccordionWrapper = styled(VStack)`
    ${AccordionWrapperStyle}

    ${(props) =>
        props?.hasNoBorder &&
        css`
            border-bottom: none;
            &:hover {
                border-bottom-color: unset;
            }
        `}

    ${(props) =>
        props?.hasNoPadding &&
        css`
            padding: 0;
        `}
`;

export const AccordionContent = styled(HStack)`
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 4px;
`;

export const AccordionTitle = styled(Text)`
    font-style: normal;
    color: ${COLOR_VALUES.Gray_2};
    font-variation-settings: "opsz" 18;

    &:active {
        color: ${COLOR_VALUES.Black};
    }

    ${(props) =>
        props.show &&
        css`
            color: ${COLOR_VALUES.Black};
        `}
`;

export const AccordionDescription = styled(Text)`
    ${TEXT_VARIANT_STYLES.Body_1_3};
    color: ${COLOR_VALUES.Black};
    font-weight: 400;
    font-style: normal;
`;

export const AccordionIcon = styled(Icon)`
    color: ${COLOR_VALUES.Gray_2};
    &:active {
        color: ${COLOR_VALUES.Black};
    }
    ${(props) =>
        props.show &&
        css`
            color: ${COLOR_VALUES.Black};
        `}
`;

export const AccordionButtonStyle = `
    border: none;
    background: transparent;
    width: 100%;
    padding: 0;
`;

export const AccordionButton = styled.button`
    ${AccordionButtonStyle}
`;
