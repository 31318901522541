import React from "react";
import styled from "styled-components";

import Text from "components/UI/Text";

const Wrapper = styled.div`
    text-align: center;
    padding: 6em 0;
    > button {
        margin-top: 1em;
    }
`;

const Unauthorized = (_props) => {
    return (
        <Wrapper className="unauth-msg-wrapper">
            <Text variant="largeBold">Unauthorized Access</Text>
            <Text variant="smallLight">
                You're trying to view a page to which you do not have access. If
                this is an error, please contact an adminstrator.
            </Text>
        </Wrapper>
    );
};

Unauthorized.propTypes = {};

export default Unauthorized;
