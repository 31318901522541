import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";
import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

const useRetainers = () => {
    const { getDataAsync, putData, postData } = useApi();
    const queryClient = useQueryClient();

    const getRetainersApiCall = useCallback(
        async ({
            sortBy = "",
            pageSize = PAGER_INITIAL_PAGE_SIZE,
            pageIndex = 0,
            active = "",
            clientIds = [],
            clientStatus = [],
            apiStatusIds = [],
        }) => {
            const response = await getDataAsync("retainerSS", {
                urlParams: [
                    { name: "$active", value: active },
                    { name: "$sortBy", value: sortBy },
                    { name: "$pageSize", value: pageSize },
                    { name: "$pageNumber", value: pageIndex + 1 },
                    { name: "$ClientIds", value: clientIds },
                    { name: "$ClientStatus", value: clientStatus },
                    { name: "$APIStatusIds", value: apiStatusIds },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createRetainersApiCall = useCallback(
        (body) => {
            return postData("retainers", {
                body,
            });
        },
        [postData]
    );

    const updateRetainersApiCall = useCallback(
        (body) => {
            return putData("retainers", {
                body,
            });
        },
        [putData]
    );

    const getRetainerByIdApiCall = useCallback(
        async (retainerId) => {
            const response = await getDataAsync("getRetainerById", {
                urlParams: [{ name: "$retainerId", value: retainerId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const useRetainerById = (retainerId) =>
        useQuery(
            ["retainerDataById", retainerId],
            () => getRetainerByIdApiCall(retainerId)
        );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("retainersList");
            },
        };
    }, [queryClient]);

    const createRetainer = useMutation(createRetainersApiCall, mutationOptions);
    const updateRetainer = useMutation(updateRetainersApiCall, mutationOptions);

    return useMemo(() => {
        const useRetainerSSList = (dataObj) =>
            useQuery(
                ["retainersList", dataObj],
                () => getRetainersApiCall(dataObj),
                { keepPreviousData: true, staleTime: Infinity }
            );

        return {
            useRetainerSSList,
            createRetainer,
            updateRetainer,
            useRetainerById,
        };
    }, [
        getRetainersApiCall,
        createRetainer,
        updateRetainer,
        getRetainerByIdApiCall,
    ]);
};

export default useRetainers;

export const useBizUnits = () => {
    const { getDataAsync } = useApi();

    const getBizUnitsApiCall = useCallback(async () => {
        const response = await getDataAsync("getBizUnits");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const bizUnits = useQuery("bizUnitsList", getBizUnitsApiCall);

    return useMemo(() => {
        return {
            bizUnits,
        };
    }, [bizUnits]);
};

export const useLeadProducers = () => {
    const { getDataAsync } = useApi();

    const getLeadPProducersApiCall = useCallback(async () => {
        const response = await getDataAsync("getLeadProducers");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const leadProducers = useQuery(
        "leadProducerList",
        getLeadPProducersApiCall
    );

    return useMemo(() => {
        return {
            leadProducers,
        };
    }, [leadProducers]);
};
