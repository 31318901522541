import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import GlobalConfig from "configs/Global";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import EmptyState from "components/design-system/tables/EmptyState";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { COLOR_VALUES } from "components/design-system/config/colors";

import useSelectorRetainers from "pages/projects/retainers-dashboard/useRetainersDashboard";

const InputLabel = styled.label`
    color: ${COLOR_VALUES.Gray_1};
    ${TEXT_VARIANT_STYLES.Headline_5}
`;

export const StyledEmptyState = styled(EmptyState)`
    padding-top: 12px;
    padding-bottom: 12px;
`;

const RetainersDashboardNav = ({ refreshButtonElement = null }) => {
    const { retainerId } = useParams();
    const [value, setValue] = useState(retainerId);
    const navigate = useNavigate();
    const projectsRetainersDashboardPath =
        GlobalConfig.routes.projectsRetainersDashboardLanding;

    const { selectorRetainers } = useSelectorRetainers();

    const handleOnChange = (id) => {
        setValue(id);
        navigate(`${projectsRetainersDashboardPath}/${id}`);
    };

    const retainerIdStored = JSON.parse(
        localStorage.getItem("storedRetainerId")
    );

    useEffect(async () => {
        if (
            !selectorRetainers?.isLoading &&
            selectorRetainers?.data?.length > 0 &&
            (!value || !retainerIdStored)
        ) {
            navigate(
                `${projectsRetainersDashboardPath}/${selectorRetainers?.data[0].Id}`
            );
        }
    }, [selectorRetainers]);

    useEffect(() => {
        setValue(retainerIdStored);
    }, [retainerIdStored]);

    const retainerOptions = getDropdownOptionsNoDefaultVal(
        selectorRetainers?.data
    );

    if (selectorRetainers.isError) {
        return <>Error</>;
    }

    const selectorRetainersLength = selectorRetainers?.data?.length;

    return (
        <VStack>
            {selectorRetainersLength > 0 && !selectorRetainers.isLoading && (
                <HStack align="center">
                    <InputLabel>Retainers</InputLabel>
                    <DropdownSingleSelectWithoutSearch
                        className="dashboard-selector"
                        isSmallSize={true}
                        options={retainerOptions}
                        value={value}
                        onChange={handleOnChange}
                        disabled={
                            selectorRetainers.isLoading ||
                            retainerOptions.length === 0
                        }
                    />
                    {refreshButtonElement}
                </HStack>
            )}
            {selectorRetainersLength > 0
                ? !value && (
                    <EmptyState message="There are no content to show. Start by selecting retainer." />
                )
                : !selectorRetainers.isLoading && (
                    <StyledEmptyState message="You don't have any assigned retainer." />
                )}
        </VStack>
    );
};

export default RetainersDashboardNav;
