import React, { useState } from "react";
import { format } from "date-fns";

import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";

import useAccessGroupMembersApi from "pages/admin/access-groups/single/useAccessGroupMembers";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

const columns = [
    {
        headCellProps: {
            text: "Members",
            width: "54%",
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "memberName",
    },
    {
        headCellProps: {
            text: "Department",
            width: "17%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "departmentName",
    },
    {
        headCellProps: {
            text: "Type",
            width: "16%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows.filter((row) => {
                return row.original.type === filterValue;
            });
        },
        accessor: "typeName",
    },
    {
        headCellProps: {
            text: "Date Added",
            width: "13%",
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy"),
        }),
        accessor: "createDate",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(80),
        },
        accessor: "delete",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "delete",
            actionProps: {
                to: `?action=delete&id=${cell.row.original.memberId}`,
                replace: true,
                "aria-label": "Delete Access Group Member",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "type",
        columnAccessor: "typeName",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "U",
                label: "Employees",
            },
            {
                value: "D",
                label: "Departments",
            },
        ],
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add",
    to: "?action=add",
    replace: true,
};

const AccessGroupMembersTable = ({ groupId }) => {
    const { useAccessGroupMembers } = useAccessGroupMembersApi();
    const memberData = useAccessGroupMembers(groupId);

    const [filters, setFilters] = useState();
    const sortBy = [
        {
            id: "memberName",
            asc: true,
        },
    ];

    if (memberData.isLoading) {
        return <>Loading...</>;
    }

    if (memberData.isError) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="center" justify="space-between">
                    <TableFilters
                        filtersConfig={FiltersConfig}
                        onChange={setFilters}
                    />
                    <SecondaryButton
                        leadingIcon="expand"
                        label="Add"
                        to="?action=add"
                        replace={true}
                    />
                </HStack>
                <Table
                    columns={columns}
                    data={memberData.data}
                    filters={filters}
                    emptyActionProps={tableCTAProps}
                    sortBy={sortBy}
                />
            </VStack>
        </>
    );
};

export default React.memo(AccessGroupMembersTable);
