import React, { useEffect, useState } from "react";
import { useDateRangePickerState } from "@react-stately/datepicker";
import { useDateRangePicker } from "@react-aria/datepicker";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { parseDate, CalendarDate } from "@internationalized/date";

import {
    OverlayContainer,
    OverlayProvider,
    useOverlayPosition,
    useOverlayTrigger,
} from "@react-aria/overlays";
import styled, { css } from "styled-components";
import RangeCalendarPopOver from "./RangeCalendarPopOver";
import { RangeCalendar } from "./RangeCalendar";
import { DateRangeField } from "./DateRangeField";
import BaseButton from "components/design-system/controls/button/BaseButton";
import Icon from "components/design-system/ui/Icon";
import { TertiaryButtonStyle } from "components/design-system/controls/button/StyledElements";
import {
    TextInputStyles,
    TextInputStylesSuccess,
    TextInputStylesWarning,
    TextInputStylesError,
    TextInputStylesDisabled,
} from "components/design-system/controls/input/StyledTextInput";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";

const StyledRangeDateFieldWrapper = styled.div`
    ${TEXT_VARIANT_STYLES.Body_1_1}

    ${TextInputStyles}

     ${(props) =>
        props.isSmallSize
            ? css`
                  ${TextInputStyles} {
                      min-height: 36px;
                      padding: 8px 8px 8px 12px;
                  }
                  ${TEXT_VARIANT_STYLES.Body_2_1};
              `
            : css`
                  ${TextInputStyles} {
                      padding: 12px 8px 12px 12px;
                  }
              `}

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Success &&
        css`
            ${TextInputStylesSuccess}
        `}
     ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Warning
            ? css`
                  ${TextInputStylesWarning}
              `
            : props.feedbackType === FEEDBACK_TYPES.Error &&
            css`
                  ${TextInputStylesError}
              `}

    ${(props) =>
        props.disabled &&
        css`
            ${TextInputStylesDisabled}
        `}

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
`;

const StyledBaseButton = styled(BaseButton)`
    padding: 2px;

    ${TertiaryButtonStyle}
`;

const RangeCalendarMain = ({
    onChange = () => void 0,
    onBlur = () => void 0,
    value,
    disabled = false,
    feedbackType,
    isSmallSize = false,
    confirmedChange,
    minDate,
    disableDays,
    enableDaysFromDate,
    enableDaysUntilDate,
    feedbackMessage,
    ...props
}) => {
    const ref = React.useRef();
    const dateRangeState = useDateRangePickerState(props);
    const [confirmedDateValue, setConfirmedDateValue] = useState(null);
    const {
        groupProps,
        startFieldProps,
        endFieldProps,
        buttonProps,
        dialogProps,
        calendarProps,
    } = useDateRangePicker(props, dateRangeState, ref);

    const overlayState = useOverlayTriggerState({});
    const triggerRef = React.useRef();
    const overlayRef = React.useRef();

    const { triggerProps, overlayProps } = useOverlayTrigger(
        { type: "dialog" },
        overlayState,
        triggerRef
    );

    const { overlayProps: positionProps } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef,
        placement: "bottom right",
        crossOffset: 9,
        isOpen: overlayState.isOpen,
    });

    const handleOnClickCalendar = () => {
        overlayState.setOpen(!overlayState.isOpen);
        dateRangeState.setOpen(!dateRangeState.isOpen);
    };

    useEffect(() => {
        if (value != undefined && value && value!= confirmedDateValue) {
            let date = null;
            if (value?.start && value?.end) {
                if (!value.start.calendar) {
                    date = {
                        start: parseDate(value.start),
                        end: parseDate(value.end),
                    };
                } else {
                    date = value;
                }
                dateRangeState.setValue(date);
                setConfirmedDateValue(date);
                confirmedChange(date);
            }
        }
    }, [value]);

    const handleOnClickCancel = () => {
        if (confirmedDateValue?.start && confirmedDateValue?.end) {
            dateRangeState.setValue(confirmedDateValue);
        } else {
            dateRangeState.setValue({ start: null, end: null });
        }
        overlayState.setOpen(false);
    };

    const onCloseCalendar = () => {
        confirmedNewDate();
    };

    const handleOnClickDone = (e) => {
        confirmedNewDate();
    };

    const confirmedNewDate = () => {
        setConfirmedDateValue(dateRangeState.value);
        onChange(dateRangeState.value);
        confirmedChange(dateRangeState.value);
        onBlur(dateRangeState.value);
        overlayState.setOpen(false);
    };

    const handleInputBlur = () => {
        const date = { start: startFieldProps.value, end: endFieldProps.value };
        dateRangeState.setValue(date);
        setConfirmedDateValue(dateRangeState.value);
        onChange(dateRangeState.value);
        onBlur(dateRangeState.value);
        confirmedChange(dateRangeState.value);
    };

    return (
        <>
            <VStack spacing={"Zero_25"} style={{ cursor: "not-allowed" }}>
                <StyledRangeDateFieldWrapper
                    {...groupProps}
                    onBlur={handleInputBlur}
                    ref={ref}
                    feedbackType={
                        !overlayState.isOpen && feedbackMessage
                            ? feedbackType
                            : FEEDBACK_TYPES.None
                    }
                    disabled={disabled}
                    isSmallSize={isSmallSize}
                >
                    <HStack>
                        <DateRangeField
                            disabled={disabled}
                            isSmallSize={isSmallSize}
                            {...startFieldProps}
                        />
                        -
                        <DateRangeField
                            disabled={disabled}
                            isSmallSize={isSmallSize}
                            {...endFieldProps}
                        />
                    </HStack>

                    {disabled ? (
                        <StyledBaseButton disabled={disabled}>
                            <Icon name="calendar" />
                        </StyledBaseButton>
                    ) : (
                        <StyledBaseButton
                            type="button"
                            onClick={handleOnClickCalendar}
                            {...Object.entries(buttonProps).splice(
                                Object.keys(buttonProps).indexOf("onPress"),
                                1
                            )}
                            {...Object.entries(triggerProps).splice(
                                Object.keys(triggerProps).indexOf("onPress"),
                                1
                            )}
                            ref={triggerRef}
                        >
                            <Icon name="calendar" />
                        </StyledBaseButton>
                    )}
                </StyledRangeDateFieldWrapper>

                {!disabled && (
                    <OverlayProvider>
                        {overlayState.isOpen && (
                            <>
                                <OverlayContainer>
                                    <RangeCalendarPopOver
                                        {...dialogProps}
                                        {...overlayProps}
                                        {...positionProps}
                                        ref={overlayRef}
                                        isOpen={overlayState.isOpen}
                                        onClose={onCloseCalendar}
                                    >
                                        <RangeCalendar
                                            disableDays={disableDays}
                                            enableDaysFromDate={enableDaysFromDate}
                                            enableDaysUntilDate={enableDaysUntilDate}
                                            minDate={minDate}
                                            onClickCancel={handleOnClickCancel}
                                            onClickDone={handleOnClickDone}
                                            {...calendarProps}
                                        />
                                    </RangeCalendarPopOver>
                                </OverlayContainer>
                            </>
                        )}
                    </OverlayProvider>
                )}
            </VStack>
        </>
    );
};

export default RangeCalendarMain;
