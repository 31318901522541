import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";

export const useUser = () => {
    const { getDataAsync } = useApi();

    const getUserDetail = async (urlParams) => {
        return getDataAsync("userDetail", { urlParams: urlParams }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getEmployeesForSearch = async () => {
        return getDataAsync("getEmployeesForSearch").then((payload) => {
            return payload.json();
        });
    };

    return useMemo(() => {
        return {
            getUserDetail,
            getEmployeesForSearch,
        };
    }, []);
};
