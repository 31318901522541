import PropTypes from "prop-types";
import styled from "styled-components";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import BaseButton from "components/design-system/controls/button/BaseButton";
import Tooltip from "components/design-system/ui/Tooltip";
import { TertiaryButtonStyle } from "components/design-system/controls/button/StyledElements";

const StyledTertiaryButton = styled(BaseButton)`
    color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    padding: ${({ $reducePadding }) =>
        $reducePadding ? "4px 4px" : "4px 8px"};
    min-height: 24px;

    ${TertiaryButtonStyle}
`;

const TertiaryButton = ({
    label,
    leadingIcon,
    trailingIcon,
    tooltip,
    disabled = false,
    iconWidth = 16,
    ...rest
}) => {
    const ariaLabel = rest["aria-label"];

    if (!label && !ariaLabel) {
        console.error("Icon-only button missing aria-label");
    }

    return (
        <Tooltip text={ariaLabel} enabled={!label}>
            <>
                <StyledTertiaryButton
                    $reducePadding={!label}
                    disabled={disabled}
		            tooltip={tooltip}                    
		            {...rest}
                >
                    {leadingIcon && (
                        <Icon name={leadingIcon} width={iconWidth} />
                    )}
                    {label && <Text variant="Body_2_2">{label}</Text>}
                    {trailingIcon && (
                        <Icon name={trailingIcon} width={iconWidth} />
                    )}
                </StyledTertiaryButton>
            </>
        </Tooltip>
    );
};

TertiaryButton.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    leadingIcon: PropTypes.oneOf(ICON_NAMES),
    trailingIcon: PropTypes.oneOf(ICON_NAMES),
    "aria-label": PropTypes.string,
    disabled: PropTypes.bool,
};

export default TertiaryButton;
