import React from "react";
import { format } from "date-fns";

import { COLOR_VALUES } from "components/design-system/config/colors";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import useAccessGroups from "pages/admin/access-groups/useAccessGroups";

const columns = [
    {
        headCellProps: {
            text: "Access Group",
            width: "76.22%",
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
            placement: "bottom-start",
            tooltip: cell.row.original.description
                ? `${cell.row.original.description}`
                : "",
            actionIcon: "caret-right",
            actionProps: {
                to: `${cell.row.original.id}`,
            },
        }),
        accessor: "name",
    },
    {
        headCellProps: {
            text: "Members",
            width: "9.15%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "membersCount",
    },
    {
        headCellProps: {
            text: "Date Added",
            width: "12.2%",
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy"),
        }),
        accessor: "createDate",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(80),
        },
        accessor: "edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=edit&id=${cell.row.original.id}`,
                replace: true,
                "aria-label": "Edit Access Group",
            },
        }),
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const AccessGroupTable = () => {
    const { accessGroups } = useAccessGroups();
    const sortBy = [
        {
            id: "name",
            asc: true,
        },
    ];

    return (
        <>
            {accessGroups.isLoading ? (
                <>Loading...</>
            ) : accessGroups.error ? (
                <>Error</>
            ) : (
                <VStack>
                    <HStack justify="flex-end">
                        <SecondaryButton
                            leadingIcon="expand"
                            label="Add Access Group"
                            to="?action=add"
                            replace={true}
                        />
                    </HStack>
                    <Table
                        showTableBorder={false}
                        columns={columns}
                        data={accessGroups.data}
                        getRowProps={getRowProps}
                        sortBy={sortBy}
                    />
                </VStack>
            )}
        </>
    );
};

export default React.memo(AccessGroupTable);
