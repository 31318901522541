import { useState } from "react";
import styled from "styled-components";
import ArrowUp from "../../../Icon/Up";
import ArrowDown from "../../../Icon/Down";

const StyledButton = styled.button`
    background-color: inherit;
    border: none;
    padding: 0;
    &:hover {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
    }
    &:active {
        background-color: ${global.config.colors.orange1};
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:focus {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
        outline: none;
    }
`;

const ButtonsLayer = ({
    onClickArrowUp,
    onClickArrowDown,
    disableUp,
    disableDown,
    onUpdate,
    ...props
}) => {
    const [arrowClicked, setArrowClicked] = useState(false);

    const handleClicklArrowUp = (event) => {
        setArrowClicked(true);
        onClickArrowUp(event);
    };
    const handleClicklArrowDown = (event) => {
        setArrowClicked(true);
        onClickArrowDown(event);
    };

    const handleMouseLeave = (event) => {
        arrowClicked && onUpdate(event);
        setArrowClicked(false);
    };

    return (
        <>
            <div className={props.className}>
                <StyledButton
                    onClick={(event) => handleClicklArrowUp(event)}
                    disable={disableUp}
                    onMouseLeave={(event) => handleMouseLeave(event)}
                >
                    <ArrowUp hover={!disableUp} />
                </StyledButton>
                <StyledButton
                    onClick={(event) => handleClicklArrowDown(event)}
                    disable={disableDown}
                    onMouseLeave={(event) => handleMouseLeave(event)}
                >
                    <ArrowDown hover={!disableDown} />
                </StyledButton>
            </div>
        </>
    );
};

ButtonsLayer.propTypes = {};

export default ButtonsLayer;
