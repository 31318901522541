import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../../ui/Icon";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import {
    TextareaWrapper,
    StyledTextarea,
    InputTextareaRow,
    StyledTextareaClearIcon,
    StyledCharacterCounter,
} from "./StyledInputTextarea";

const InputTextArea = React.forwardRef(
    (
        {
            value = "",
            onChange,
            disabled,
            feedbackType,
            placeholder,
            showClearIcon,
            characterCounter,
            ...rest
        },
        ref
    ) => {
        const resetValue = () => {
            onChange("");
        };

        return (
            <TextareaWrapper disabled={disabled}>
                <StyledTextarea
                    {...rest}
                    disabled={disabled}
                    feedbackType={feedbackType}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                />
                <InputTextareaRow>
                    {showClearIcon && value?.length >= 1 && (
                        <StyledTextareaClearIcon
                            disabled={disabled}
                            type="button"
                            tabindex={-1}
                            onClick={resetValue}
                            aria-label="clear search field"
                        >
                            <Icon
                                name="clear"
                                color="Gray_2"
                                style={{ flexShrink: 0 }}
                            />
                        </StyledTextareaClearIcon>
                    )}
                    {characterCounter && (
                        <StyledCharacterCounter
                            variant="Descriptive_1_1"
                            color="Gray_4"
                        >
                            {value.length}
                        </StyledCharacterCounter>
                    )}
                </InputTextareaRow>
            </TextareaWrapper>
        );
    }
);

InputTextArea.propTypes = {
    disabled: PropTypes.bool,
    feedbackType: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
    placeholder: PropTypes.string,
    showClearIcon: PropTypes.bool,
    characterCounter: PropTypes.bool,
};
export default InputTextArea;
