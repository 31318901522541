import PropTypes from "prop-types";
import React from "react";
import SectionHead from "components/design-system/ui/SectionHead";
import Icon from "components/design-system/ui/Icon";
import {
    StyledModal,
    CustomStyles,
    StyledHeader,
    StyledIcon,
    StyledForm,
} from "components/design-system/ui/modal/StyledMasterModal";

const MasterModal = ({
    title,
    description,
    stickyAction,
    width,
    show,
    onRequestClose,
    children,
    zIndex,
    showFooter,
}) => {
    function handleClose() {
        if (onRequestClose) {
            onRequestClose();
        }
    }

    const titleHeader = (
        <StyledHeader spacing="Zero">
            <SectionHead title={title} description={description} />
            <StyledIcon
                type="button"
                onClick={handleClose}
                aria-label="Close Modal"
            >
                <Icon name="close" color="Gray_2" size={24} />
            </StyledIcon>
        </StyledHeader>
    );

    return (
        <>
            <StyledModal
                isOpen={show}
                onRequestClose={handleClose}
                contentLabel="Modal"
                style={CustomStyles}
                width={width}
                scrollable={true}
                stickyAction={stickyAction}
                onAfterClose={() => {
                    document.activeElement.blur();
                }}
                zIndex={zIndex}
                showFooter={showFooter}
            >
                {title && title.length > 0 ? titleHeader : null}
                <StyledForm>{children}</StyledForm>
            </StyledModal>
        </>
    );
};

MasterModal.defaultProps = {
    show: true,
    onRequestClose: null,
    title: "",
    description: null,
    stickyAction: false,
    width: 480,
    zIndex: 1001,
    showFooter: true,
};

MasterModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    stickyAction: PropTypes.bool,
    width: PropTypes.number,
    zIndex: PropTypes.number,
    showFooter: PropTypes.bool,
};

export default MasterModal;
