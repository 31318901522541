import { useMemo, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useManageUserTimeOff = () => {
    const { getDataAsync, postData } = useApi();
    const queryClient = useQueryClient();

    const getManageUserTimeOffApiCall = useCallback(
        async (approverId) => {
            const response = await getDataAsync("manageTimeOff", {
                urlParams: [{ name: "$approverId", value: approverId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const approveUserTimeOffApiCall = useCallback(
        ({ timeOffRequestId, body }) => {
            return postData("approveTimeOff", {
                urlParams: [
                    { name: "$timeOffRequestId", value: timeOffRequestId },
                ],
                body,
            });
        },
        [postData]
    );

    const rejectUserTimeOffApiCall = useCallback(
        ({ timeOffRequestId, body }) => {
            return postData("rejectTimeOff", {
                urlParams: [
                    { name: "$timeOffRequestId", value: timeOffRequestId },
                ],
                body,
            });
        },
        [postData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("userTimeOffRequests");
            },
        };
    }, [queryClient]);

    const approveTimeOff = useMutation(
        approveUserTimeOffApiCall,
        mutationOptions
    );
    const rejectTimeOff = useMutation(
        rejectUserTimeOffApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useManageUserTimeOffApi = (approverId) =>
            useQuery({
                queryKey: ["userTimeOffRequests", approverId],
                queryFn: () => getManageUserTimeOffApiCall(approverId),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });

        return {
            useManageUserTimeOffApi,
            approveTimeOff,
            rejectTimeOff,
        };
    }, [getManageUserTimeOffApiCall, approveTimeOff, rejectTimeOff]);
};

export default useManageUserTimeOff;

export const useManageUsersAsApprover = () => {
    const { getDataAsync } = useApi();

    const getManageUsersAsApproverApiCall = useCallback(
        async (userId) => {
            const response = await getDataAsync("getManageUsersAsApprover", {
                urlParams: [{ name: "$userId", value: userId }],
            });

            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useManageUserAsApproverApi = (userId) =>
            useQuery(["manageUserAsApprover", userId], () =>
                getManageUsersAsApproverApiCall(userId)
            );

        return {
            useManageUserAsApproverApi,
        };
    }, [getManageUsersAsApproverApiCall]);
};
