import SessionContext from "context/app/SessionContext";
import { useMemo, useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const PAGE_CODE = "AC_PLANNER_VIEW";

export const useLeadProducerEmp = () => {
    const { getDataAsync } = useApi();
    const session = useContext(SessionContext);

    const pageIdVal = session.permissions.filter(f => f.pageRoute === global.config.routes.projectsDashboardLanding)[0]?.pageId;

    const getLeadProducerEmpIdsApiCall = useCallback(async () => {
        if (pageIdVal >= 0) {
            const response = await getDataAsync("getLeadProducerEmpIds", {
                urlParams: [
                    { name: "$pageId", value: pageIdVal },
                    { name: "$code", value: PAGE_CODE }
                ],
            });
            return response.status != 204 ? response.json() : null;
        } else {
            return null;
        }
    }, [getDataAsync]);

    const leadProducerEmpIds = useQuery(
        "leadProducerEmpIdData",
        getLeadProducerEmpIdsApiCall
    );

    return useMemo(() => {
        return {
            leadProducerEmpIds,
        };
    }, [leadProducerEmpIds]);
};
