import { makeStyles } from "@material-ui/core/styles";

export const useStylesForMenu = makeStyles((_theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: global.config.colors.white,
        borderRadius: global.config.sizes.borderRadius,
        "& div": {
            "& button:focus": {
                outline: "0",
            },
        },
        "& button:hover": {
            backgroundColor: global.config.colors.gray8,
        },
        "& .rc-menu-container": {
            zIndex: 100000,
            "& ul": {
                marginLeft: "8.5rem",
                marginTop: "-0.6rem",
            },
        },
        "& .MuiListItem-button:hover": {
            backgroundColor: global.config.colors.gray8,
        },
    },
    addIcon: {
        margin: "0.313rem",
    },
    openMenu: {
        backgroundColor: global.config.colors.gray7,
    },
    selectMenu: {
        backgroundColor: global.config.colors.white,
        maxHeight: "20rem",
        width: "14rem",
        border: global.config.border.small2,
        borderRadius: global.config.sizes.borderRadius,
        zIndex: 1051,
        boxShadow: "none",
        overflow: "auto",
        marginTop: "0.438rem",
        "&:hover": {
            border: global.config.border.smallBlack,
        },
        "& span": {
            fontSize: global.config.fonts.sizes.small,
            fontFamily: global.config.fonts.families.light,
        },
        "& li": {
            paddingRight: "0.5rem",
        },
    },
    clearButton: {
        marginLeft: "0.25rem",
        maxHeight: "1rem",
    },
    customMenu: {
        maxHeight: "24rem",
        maxWidth: "17.65rem",
        minWidth: "15.65rem",
        border: global.config.border.small2,
        borderRadius: global.config.sizes.borderRadius,
        "&:hover": {
            border: global.config.border.smallBlack,
        },
        zIndex: 3,
        marginTop: "0.438rem",
    },
    searchInput: {
        borderTop: "0.063rem solid",
        borderTopColor: global.config.colors.gray6,
        "&::-webkit-input-placeholder, ::placeholder": {
            color: global.config.colors.gray2,
            opacity: "1",
            fontSize: global.config.fonts.sizes.smedium,
            fontFamily: global.config.fonts.families.light,
        },
    },
    diskButton: {
        padding: "padding: 0.3rem 0.5rem",
        backgroundColor: "inherit",
        "&:hover": {
            backgroundColor: "inherit",
        },
        "& svg:hover": {
            backgroundColor: global.config.colors.gray7,
        },
    },
}));

export const useStylesForModal = makeStyles((_theme) => ({
    root: {
        "& .modal-dialog": {
            maxWidth: "28.125rem",
        },
        "& .modal-content": {
            maxHeight: "38rem",
            minHeight: "32.75rem",
        },
        "& .modal-title": {
            width: "100%",
            margin: "4rem 1.5rem 1rem 3.438rem",
        },
        "& .modal-footer button": {
            minWidth: "8.75rem",
            marginLeft: "1.563rem",
        },
        "& .modal-footer": {
            marginRight: "4.688rem",
            marginBottom: "1.75rem",
        },
        "& textarea": {
            height: "6rem",
        },
        "& .form-group": {
            marginBottom: "2rem",
        },
    },
    alignRightHeader: {
        "&.modal-header": {
            alignSelf: "flex-end",
        },
    },
}));

export const useStylesForAboutDialog = makeStyles((_theme) => ({
    root: {
        "& .modal-dialog": {
            height: "32rem",
            width: "28.25rem",
            padding: "0 0 0 0",
        },
        "& .modal-content": {
            padding: "0 0 0 0",
        },
        "& .modal-title": {
            padding: "4.5rem 0 0 6rem",
            margin: "1rem 1rem 2rem 1.5rem",
        },
        "& .modal-body": {
            padding: "0 0 2rem 2rem",
        },
        "& .modal-footer button": {
            minWidth: "8.75rem",
            marginLeft: "1.5rem",
        },
        "& .modal-footer": {
            height: "6rem",
        },
        "& textarea": {
            height: "6rem",
        },
        "&.btn-link:hover": {
            color: "initial",
        },
    },
}));

export const useStylesForSessionExpiredDialog = makeStyles((_theme) => ({
    root: {
        "& .modal-dialog": {
            height: "32rem",
            width: "28.25rem",
            padding: "0 0 0 0",
        },
        "& .modal-content": {
            padding: "2rem 0 0 0",
            height: "36rem",
        },
        "& .modal-title": {
            padding: "4.5rem 0 0 6rem",
            margin: "1rem 1rem 2rem 1.5rem",
        },
        "& .modal-body": {
            padding: "0 0 2rem 1.5rem",
        },
        "& .modal-footer button": {
            minWidth: "8.75rem",
            marginLeft: "1.5rem",
        },
        "& .modal-footer": {
            height: "11rem",
        },
        "& textarea": {
            height: "6rem",
        },
        "&.btn-link:hover": {
            color: "initial",
        },
    },
}));

export const useStylesForButtonGroups = makeStyles((_theme) => ({
    customStyles: {
        "&.MuiToggleButtonGroup-root": {
            width: "100%",
        },
        "&.MuiToggleButton-root": {
            textTransform: "none",
            padding: "0.375rem",
            backgroundColor: global.config.colors.white,
            border: "1px solid #EDEFF2",
            color: global.config.colors.black,
            width: "100%",
            fontSize: ".75rem",
            fontFamily: global.config.fonts.families.regular,
        },
        "&.MuiToggleButton-root:focus": {
            outline: "none",
        },
        "&.MuiToggleButton-root:hover": {
            backgroundColor: global.config.colors.gray6,
        },
        "&.MuiToggleButton-root.Mui-selected": {
            backgroundColor: "#EDEFF2",
            color: global.config.colors.black,
        },
        "&.MuiToggleButton-root.Mui-selected:hover": {
            backgroundColor: global.config.colors.gray6,
        },
    },
    orphan: {
        "&.MuiToggleButton-root": {
            borderLeft: `${global.config.border.small} !important`,
            marginLeft: "0.5rem !important",
        },
    },
    small: {
        "&.MuiToggleButton-root": {
            width: "4.5em",
            padding: "0.4rem",
        },
    },
}));

export const useStylesForButton = makeStyles((_theme) => ({
    primaryButton: {
        "&.MuiButton-root": {
            textTransform: "none",
            padding: "0.7em 1em",
            marginRight: "0.5em",
            borderRadius: global.config.sizes.borderRadius,
            fontSize: global.config.fonts.sizes.small,
            fontFamily: global.config.fonts.families.regular,
            "&.MuiButton-root:hover": {
                opacity: "0.75",
                boxShadow: "none",
                backgroundColor: global.config.colors.black,
            },
        },
        "&.MuiButton-contained": {
            backgroundColor: global.config.colors.black,
            color: global.config.colors.white,
            fontFamily: "inherit",
            boxShadow: "none",
        },
        "&.MuiButton-contained:disabled": {
            backgroundColor: global.config.colors.gray3,
            color: global.config.colors.white,
            fontFamily: "inherit",
        },
    },
    secondaryButton: {
        "&.MuiButton-root": {
            backgroundColor: "transparent",
            color: global.config.colors.gray2,
            textTransform: "none",
            boxShadow: "none",
            padding: "0",
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.small,
            "&.MuiButton-root:hover": {
                color: global.config.colors.black,
                boxShadow: "none",
                backgroundColor: global.config.colors.gray7,
            },
        },
        "&.MuiButton-contained:disabled": {
            border: "0",
            color: global.config.colors.gray4,
        },
    },
    primaryLinkButton: {
        "&.MuiButton-root": {
            backgroundColor: "transparent",
            color: global.config.colors.black,
            textTransform: "none",
            boxShadow: "none",
            padding: "0",
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.small,
            textDecoration: "underline",
            transition: "none",
            "&.MuiButton-root:hover": {
                opacity: "0.75",
                background: "transparent",
                boxShadow: "none",
            },
        },
        "&.MuiButton-contained:disabled": {
            border: "0",
        },
    },

    button: {
        "&.MuiButton-root": {
            textTransform: "none",
            padding: "0.7em 1em",
            marginRight: "0.5em",
            borderRadius: global.config.sizes.borderRadius,
            fontSize: global.config.fonts.sizes.small,
            fontFamily: global.config.fonts.families.regular,
            "&.MuiButton-root:hover": {
                opacity: "0.75",
                boxShadow: "none",
                backgroundColor: global.config.colors.black,
            },
        },
        "&.MuiButton-contained": {
            backgroundColor: global.config.colors.black,
            color: global.config.colors.white,
            fontFamily: "inherit",
        },
        "&.MuiButton-contained:disabled": {
            backgroundColor: global.config.colors.gray3,
            color: global.config.colors.white,
            fontFamily: "inherit",
        },
    },
    secondary: {
        "&.MuiButton-root": {
            border: global.config.border.medium,
            backgroundColor: global.config.colors.white,
            color: global.config.colors.black,
            height: "2.625rem",
            "&.MuiButton-root:hover": {
                opacity: "0.75",
                boxShadow: "none",
                backgroundColor: global.config.colors.black,
                color: global.config.colors.white,
            },
            boxShadow: "none",
        },
        "&.MuiButton-contained:disabled": {
            border: "0",
        },
    },
    noMargin: {
        "&.MuiButton-root": {
            margin: 0,
        },
    },
    tooltipLink: {
        color: global.config.colors.black,
        fontSize: global.config.fonts.sizes.xsmall,
        padding: 0,
        textDecoration: "underline",
        verticalAlign: "baseline",
        textTransform: "none",
        minWidth: "unset",
        "& :focus": {
            boxShadow: "none",
        },
        "&.btn-link:hover": {
            color: "inherit",
        },
    },
    medium: {
        fontSize: global.config.fonts.sizes.medium,
    },
    smallSize: {
        minWidth: "6.75rem",
    },
}));

export const useStylesForAvatar = makeStyles((_theme) => ({
    root: {
        "&.MuiAvatar-root": {
            width: "2.25rem",
            height: "2.25rem",
        },
    },
}));

export const useStylesForText = makeStyles((_theme) => ({
    root: {
        "&.MuiTypography-root": {
            color: global.config.colors.black,
        },
    },
    black: {
        "&.MuiTypography-root": {
            color: global.config.colors.black,
        },
    },
    white: {
        "&.MuiTypography-root": {
            color: global.config.colors.white,
        },
    },
    borderBottom: {
        "&:hover": {
            borderBottom: global.config.border.large,
        },
    },
    gray2: {
        "&.MuiTypography-root": {
            color: global.config.colors.gray2,
        },
    },
    red1: {
        "&.MuiTypography-root": {
            color: global.config.colors.red1,
        },
    },
}));

export const useStylesForGrid = makeStyles((_theme) => ({}));
