import React, { useReducer } from "react";

const EmployeeRowsContext = React.createContext();

const counterReducer = (state, action) => {
    switch (action.type) {
      case 'increment': {
        return {count: state.count + 1}
      }
      case 'decrement': {
        return {count: state.count - 1}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
};

export const EmployeeRowsProvider = (props) => {
    const [employeesInProgress, setEmployeesInProgress] = useReducer(counterReducer, { count: 0});

    const contextValue = {
        employeesInProgress,
        setEmployeesInProgress,
    };

    return (
        <EmployeeRowsContext.Provider value={contextValue}>
            {props.children}
        </EmployeeRowsContext.Provider>
    );
};

export default EmployeeRowsContext;
