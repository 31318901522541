import React, { useState, useMemo } from "react";

const ClientContext = React.createContext();

export const ClientContextProvider = (props) => {
    const [activeInactiveState, setActiveInactiveState] = useState(true);

    const contextValue = useMemo(() => {
        return {
            activeInactiveState,
            setActiveInactiveState,
        };
    }, [activeInactiveState, setActiveInactiveState]);

    return (
        <ClientContext.Provider value={contextValue}>
            {props.children}
        </ClientContext.Provider>
    );
};

export default ClientContext;