import PropTypes from "prop-types";
import styled from "styled-components";

import { COLOR_VALUES } from "../config/colors";

const ICONS = {
    "cursor-default": (
        <>
            <path
                d="M18.4831 0.769942L18.4834 0.769441C18.4992 0.733968 18.5039 0.694531 18.4968 0.656335C18.4909 0.624417 18.477 0.594623 18.4566 0.569659L18.443 0.556077C18.4155 0.528702 18.3805 0.510273 18.3423 0.503213C18.3043 0.496165 18.265 0.500775 18.2296 0.516435L18.4831 0.769942ZM18.4831 0.769942L10.6535 18.3863L10.6535 18.3865C10.6383 18.4207 10.6134 18.4497 10.582 18.47C10.5506 18.4902 10.5139 18.5009 10.4765 18.5006C10.4391 18.5003 10.4026 18.4891 10.3714 18.4683C10.3403 18.4476 10.3159 18.4182 10.3013 18.3838L10.3009 18.3829L7.47602 11.7864L7.39724 11.6025L7.21327 11.5236L0.616154 8.69768C0.616069 8.69764 0.615984 8.69761 0.615899 8.69757C0.581725 8.68281 0.552572 8.65843 0.531993 8.6274C0.511365 8.5963 0.500249 8.55985 0.500004 8.52253C0.49976 8.4852 0.510398 8.44862 0.530617 8.41724C0.550836 8.38587 0.579759 8.36107 0.61385 8.34587L18.2294 0.516519L18.4831 0.769942Z"
                fill="currentColor"
                stroke="white"
            />
        </>
    ),
    user: (
        <>
            <path
                d="M8 11C8.98891 11 9.95561 10.7068 10.7779 10.1573C11.6001 9.60794 12.241 8.82705 12.6194 7.91342C12.9978 6.99979 13.0969 5.99446 12.9039 5.02455C12.711 4.05465 12.2348 3.16373 11.5355 2.46447C10.8363 1.76521 9.94536 1.289 8.97545 1.09608C8.00555 0.90315 7.00022 1.00217 6.08659 1.3806C5.17295 1.75904 4.39206 2.39991 3.84265 3.22215C3.29325 4.0444 3 5.0111 3 6C3 7.32609 3.52679 8.59785 4.46447 9.53554C5.40215 10.4732 6.67392 11 8 11ZM8 2C8.79113 2 9.56449 2.2346 10.2223 2.67412C10.8801 3.11365 11.3928 3.73836 11.6955 4.46927C11.9983 5.20017 12.0775 6.00444 11.9231 6.78036C11.7688 7.55629 11.3878 8.26902 10.8284 8.82843C10.269 9.38784 9.55629 9.7688 8.78036 9.92314C8.00444 10.0775 7.20017 9.99827 6.46927 9.69552C5.73836 9.39277 5.11365 8.88008 4.67412 8.22228C4.2346 7.56449 4 6.79113 4 6C4 4.93914 4.42143 3.92172 5.17158 3.17158C5.92172 2.42143 6.93914 2 8 2Z"
                fill="currentColor"
            />
            <path
                d="M4.68 13H11.32L13.69 14.89L14.31 14.11L11.68 12H4.32L1.69 14.11L2.31 14.89L4.68 13Z"
                fill="currentColor"
            />
        </>
    ),
    exit: (
        <>
            <path
                d="M12 14H4V2H12V3H13V1H3V15H13V13H12V14Z"
                fill="currentColor"
            />
            <path
                d="M11 4.82L13.68 7.5H6.5V8.5H13.68L11 11.18L11.71 11.89L15.6 8L11.71 4.11L11 4.82Z"
                fill="currentColor"
            />
        </>
    ),
    success: (
        <>
            <path
                d="M13.4 4.29995L12.6 3.69995L6.99998 11.17L4.39998 7.69995L3.59998 8.29995L6.99998 12.83L13.4 4.29995Z"
                fill="currentColor"
            />
        </>
    ),
    warning: (
        <>
            <path
                d="M8.40997 12H7.58997V13.01H8.40997V12Z"
                fill="currentColor"
            />
            <path
                d="M8.49997 6.49998H7.49997V11H8.49997V6.49998Z"
                fill="currentColor"
            />
            <path
                d="M0.659973 15H15.34L7.99997 1.34998L0.659973 15ZM2.33997 14L7.99997 3.45998L13.66 14H2.33997Z"
                fill="currentColor"
            />
        </>
    ),
    error: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                fill="currentColor"
            />
            <path
                d="M5.88 5.17L5.17 5.88L7.29 8L5.17 10.12L5.88 10.83L8 8.71L10.12 10.83L10.83 10.12L8.71 8L10.83 5.88L10.12 5.17L8 7.29L5.88 5.17Z"
                fill="currentColor"
            />
        </>
    ),
    info: (
        <>
            <path
                d="M8.5 4.5L7.5 4.5L7.5 6L8.5 6L8.5 4.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 7L8.5 11.5L7.5 11.5L7.5 7L8.5 7Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8 1C4.13401 1 0.999999 4.13401 0.999999 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1Z"
                fill="currentColor"
            />
        </>
    ),
    expand: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                fill="currentColor"
            />
            <path
                d="M7.5 7.49998V4.49998H8.5V7.49998H11.5V8.49998H8.5V11.5H7.5V8.49998H4.5V7.49998H7.5Z"
                fill="currentColor"
            />
        </>
    ),
    collapse: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 8.5H4.5V7.5H11.5V8.5Z"
                fill="currentColor"
            />
        </>
    ),
    options: (
        <>
            <path
                d="M7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12Z"
                fill="currentColor"
            />
            <path
                d="M7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55229 8.55228 9 8 9C7.44772 9 7 8.55229 7 8Z"
                fill="currentColor"
            />
            <path
                d="M7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4Z"
                fill="currentColor"
            />
        </>
    ),
    close: (
        <>
            <path
                d="M4.81999 11.89L7.99999 8.70999L11.18 11.89L11.89 11.18L8.70999 7.99999L11.89 4.81999L11.18 4.10999L7.99999 7.28999L4.81999 4.10999L4.10999 4.81999L7.28999 7.99999L4.10999 11.18L4.81999 11.89Z"
                fill="currentColor"
            />
        </>
    ),
    "caret-up": (
        <>
            <path
                d="M12.24 10.41L7.99999 6.17001L3.75999 10.41L3.04999 9.71001L7.99999 4.76001L12.95 9.71001L12.24 10.41Z"
                fill="currentColor"
            />
        </>
    ),
    "caret-down": (
        <>
            <path
                d="M7.99999 11.24L3.04999 6.28997L3.75999 5.58997L7.99999 9.82997L12.24 5.58997L12.95 6.28997L7.99999 11.24Z"
                fill="currentColor"
            />
        </>
    ),
    "caret-left": (
        <>
            <path
                d="M9.71001 12.95L4.76001 8.00005L9.71001 3.05005L10.41 3.76005L6.17001 8.00005L10.41 12.24L9.71001 12.95Z"
                fill="currentColor"
            />
        </>
    ),
    "caret-right": (
        <>
            <path
                d="M6.28997 12.95L5.58997 12.24L9.82997 8.00005L5.58997 3.76005L6.28997 3.05005L11.24 8.00005L6.28997 12.95Z"
                fill="currentColor"
            />
        </>
    ),
    "arrow-up": (
        <>
            <path
                d="M12.95 7.35002L7.99999 2.40002L3.04999 7.35002L3.75999 8.05002L7.49999 4.31002V13H8.49999V4.31002L12.24 8.05002L12.95 7.35002Z"
                fill="currentColor"
            />
        </>
    ),
    "arrow-down": (
        <>
            <path
                d="M12.24 7.95L8.49999 11.69V3H7.49999V11.69L3.75999 7.95L3.04999 8.65L7.99999 13.6L12.95 8.65L12.24 7.95Z"
                fill="currentColor"
            />
        </>
    ),
    "arrow-left": (
        <>
            <path
                d="M13 7.49999H4.31002L8.05002 3.75999L7.35002 3.04999L2.40002 7.99999L7.35002 12.95L8.05002 12.24L4.31002 8.49999H13V7.49999Z"
                fill="currentColor"
            />
        </>
    ),
    "arrow-right": (
        <>
            <path
                d="M8.65 3.05005L7.95 3.76005L11.69 7.50005H3V8.50005H11.69L7.95 12.24L8.65 12.95L13.6 8.00005L8.65 3.05005Z"
                fill="currentColor"
            />
        </>
    ),
    place: (
        <>
            <path
                d="M8 1C6.67392 1 5.40215 1.52678 4.46447 2.46447C3.52678 3.40215 3 4.67392 3 6C3 8.76 8 15 8 15C8 15 13 8.76 13 6C13 5.34339 12.8707 4.69321 12.6194 4.08658C12.3681 3.47995 11.9998 2.92876 11.5355 2.46447C11.0712 2.00017 10.52 1.63188 9.91342 1.3806C9.30679 1.12933 8.65661 1 8 1ZM4 6C4 4.93913 4.42143 3.92172 5.17157 3.17157C5.92172 2.42143 6.93913 2 8 2C9.06087 2 10.0783 2.42143 10.8284 3.17157C11.5786 3.92172 12 4.93913 12 6C12 7.52 9.79 11 8 13.37C6.21 11 4 7.52 4 6Z"
                fill="currentColor"
            />
            <path
                d="M8 3C7.60444 3 7.21776 3.1173 6.88886 3.33706C6.55996 3.55682 6.30362 3.86918 6.15224 4.23463C6.00087 4.60009 5.96126 5.00222 6.03843 5.39018C6.1156 5.77814 6.30608 6.13451 6.58579 6.41421C6.86549 6.69392 7.22186 6.8844 7.60982 6.96157C7.99778 7.03874 8.39991 6.99913 8.76537 6.84776C9.13082 6.69638 9.44318 6.44004 9.66294 6.11114C9.8827 5.78224 10 5.39556 10 5C10 4.46957 9.78929 3.96086 9.41421 3.58579C9.03914 3.21071 8.53043 3 8 3ZM8 6C7.80222 6 7.60888 5.94135 7.44443 5.83147C7.27998 5.72159 7.15181 5.56541 7.07612 5.38268C7.00043 5.19996 6.98063 4.99889 7.01921 4.80491C7.0578 4.61093 7.15304 4.43275 7.29289 4.29289C7.43275 4.15304 7.61093 4.0578 7.80491 4.01921C7.99889 3.98063 8.19996 4.00043 8.38268 4.07612C8.56541 4.15181 8.72159 4.27998 8.83147 4.44443C8.94135 4.60888 9 4.80222 9 5C9 5.26522 8.89464 5.51957 8.70711 5.70711C8.51957 5.89464 8.26522 6 8 6Z"
                fill="currentColor"
            />
        </>
    ),
    phone: (
        <>
            <path
                d="M2 6.71L9.29 14H14V10H9V12.29L3.71 7H6V2H2V6.71ZM10 11H13V13H10V11ZM3 3H5V6H3V3Z"
                fill="currentColor"
            />
        </>
    ),
    chat: (
        <>
            <path
                d="M1 13.44L4.62 11H15V3H1V13.44ZM2 4H14V10H4.31L2 11.56V4Z"
                fill="currentColor"
            />
        </>
    ),
    mail: (
        <>
            <path
                d="M1 13H15V3H1V13ZM2 12V4.71L8 10.71L14 4.71V12H2ZM2.71 4H13.29L8 9.29L2.71 4Z"
                fill="currentColor"
            />
        </>
    ),
    folder: (
        <>
            <path
                d="M5.71 2H1V14H15V3H6.71L5.71 2ZM14 13H2V3H5.29L6.29 4H14V13Z"
                fill="currentColor"
            />
        </>
    ),
    copy: (
        <>
            <path d="M3 2V15H12V14H4V2H3Z" fill="currentColor" />
            <path
                d="M5 13H13V6L8 1H5V13ZM6 2H8L12 6V12H6V2Z"
                fill="currentColor"
            />
            <path d="M12 6L11 5H9V3L8 2V6H12Z" fill="currentColor" />
        </>
    ),
    edit: (
        <>
            <path
                d="M12 14.0001H4V3.00006H7V2.00006H3V15.0001H13V8.00006H12V14.0001Z"
                fill="currentColor"
            />
            <path
                d="M7 5.79004V8.00004H9.21L14.21 3.00004L12 0.790039L7 5.79004ZM12.79 3.00004L8.79 7.00004H8V6.21004L12 2.21004L12.79 3.00004Z"
                fill="currentColor"
            />
        </>
    ),
    save: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 1H12L14 5V14H2V1ZM3 2V13H13V5.25L11.5 2H3Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.05841 7H11.0584V13H10.0584V8H5.05841V13H4.05841V7Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3V1H5V4H10V1H9V3H6Z"
                fill="currentColor"
            />
        </>
    ),
    file: (
        <>
            <path
                d="M3 1V15H13V6L8 1H3ZM12 6V14H4V2H8L12 6Z"
                fill="currentColor"
            />
            <path d="M9 5V3L8 2V6H12L11 5H9Z" fill="currentColor" />
        </>
    ),
    upload: (
        <>
            <path d="M13 13H3V14H13V13Z" fill="currentColor" />
            <path
                d="M7.49999 3.31002V12H8.49999V3.31002L12.24 7.05002L12.95 6.35002L7.99999 1.40002L3.04999 6.35002L3.75999 7.05002L7.49999 3.31002Z"
                fill="currentColor"
            />
        </>
    ),
    download: (
        <>
            <path d="M13 13H3V14H13V13Z" fill="currentColor" />
            <path
                d="M12.95 7.65L12.24 6.95L8.49999 10.69V2H7.49999V10.69L3.75999 6.95L3.04999 7.65L7.99999 12.6L12.95 7.65Z"
                fill="currentColor"
            />
        </>
    ),
    delete: (
        <>
            <path
                d="M12 15V4H13V3H3.08002V4H4.00002V15H12ZM11 4V14H5.00002V4H11Z"
                fill="currentColor"
            />
            <path d="M10 1H6V2H10V1Z" fill="currentColor" />
        </>
    ),
    search: (
        <>
            <path
                d="M9.5 11.5C10.4889 11.5 11.4556 11.2068 12.2779 10.6573C13.1001 10.1079 13.741 9.32705 14.1194 8.41342C14.4978 7.49979 14.5969 6.49446 14.4039 5.52455C14.211 4.55465 13.7348 3.66373 13.0355 2.96447C12.3363 2.26521 11.4454 1.789 10.4755 1.59608C9.50555 1.40315 8.50021 1.50217 7.58658 1.8806C6.67295 2.25904 5.89206 2.89991 5.34265 3.72215C4.79324 4.5444 4.5 5.5111 4.5 6.5C4.5 7.82609 5.02678 9.09785 5.96447 10.0355C6.90215 10.9732 8.17392 11.5 9.5 11.5ZM9.5 2.5C10.2911 2.5 11.0645 2.7346 11.7223 3.17412C12.3801 3.61365 12.8928 4.23836 13.1955 4.96927C13.4983 5.70017 13.5775 6.50444 13.4231 7.28036C13.2688 8.05629 12.8878 8.76902 12.3284 9.32843C11.769 9.88784 11.0563 10.2688 10.2804 10.4231C9.50444 10.5775 8.70017 10.4983 7.96927 10.1955C7.23836 9.89277 6.61365 9.38008 6.17412 8.72228C5.7346 8.06449 5.5 7.29113 5.5 6.5C5.5 5.43914 5.92143 4.42172 6.67157 3.67158C7.42172 2.92143 8.43913 2.5 9.5 2.5Z"
                fill="currentColor"
            />
            <path
                d="M5.11258 10.1686L1.64069 13.6405L2.34779 14.3476L5.81969 10.8757L5.11258 10.1686Z"
                fill="currentColor"
            />
        </>
    ),
    clear: (
        <>
            <path
                d="M6.88 10.83L9 8.71L11.12 10.83L11.83 10.12L9.71 8L11.83 5.88L11.12 5.17L9 7.29L6.88 5.17L6.17 5.88L8.29 8L6.17 10.12L6.88 10.83Z"
                fill="currentColor"
            />
            <path
                d="M0.869995 8L4.76 13H15V3H4.76L0.869995 8ZM14 4V12H5.24L2.13 8L5.24 4H14Z"
                fill="currentColor"
            />
        </>
    ),
    filter: (
        <>
            <path
                d="M6 5C6 4.60444 5.8827 4.21776 5.66294 3.88886C5.44318 3.55996 5.13082 3.30362 4.76537 3.15224C4.39992 3.00087 3.99778 2.96126 3.60982 3.03843C3.22186 3.1156 2.86549 3.30608 2.58579 3.58579C2.30608 3.86549 2.1156 4.22186 2.03843 4.60982C1.96126 4.99778 2.00087 5.39992 2.15224 5.76537C2.30362 6.13082 2.55996 6.44318 2.88886 6.66294C3.21776 6.8827 3.60444 7 4 7C4.53043 7 5.03914 6.78929 5.41421 6.41421C5.78929 6.03914 6 5.53043 6 5ZM3 5C3 4.80222 3.05865 4.60888 3.16853 4.44443C3.27841 4.27998 3.43459 4.15181 3.61732 4.07612C3.80004 4.00043 4.00111 3.98063 4.19509 4.01922C4.38907 4.0578 4.56726 4.15304 4.70711 4.29289C4.84696 4.43275 4.9422 4.61093 4.98079 4.80491C5.01937 4.99889 4.99957 5.19996 4.92388 5.38268C4.84819 5.56541 4.72002 5.72159 4.55557 5.83147C4.39112 5.94135 4.19778 6 4 6C3.73478 6 3.48043 5.89464 3.29289 5.70711C3.10536 5.51957 3 5.26522 3 5Z"
                fill="currentColor"
            />
            <path
                d="M14 11C14 10.6044 13.8827 10.2178 13.6629 9.88886C13.4432 9.55996 13.1308 9.30362 12.7654 9.15224C12.3999 9.00087 11.9978 8.96126 11.6098 9.03843C11.2219 9.1156 10.8655 9.30608 10.5858 9.58579C10.3061 9.86549 10.1156 10.2219 10.0384 10.6098C9.96126 10.9978 10.0009 11.3999 10.1522 11.7654C10.3036 12.1308 10.56 12.4432 10.8889 12.6629C11.2178 12.8827 11.6044 13 12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11ZM12 12C11.8022 12 11.6089 11.9414 11.4444 11.8315C11.28 11.7216 11.1518 11.5654 11.0761 11.3827C11.0004 11.2 10.9806 10.9989 11.0192 10.8049C11.0578 10.6109 11.153 10.4327 11.2929 10.2929C11.4327 10.153 11.6109 10.0578 11.8049 10.0192C11.9989 9.98063 12.2 10.0004 12.3827 10.0761C12.5654 10.1518 12.7216 10.28 12.8315 10.4444C12.9414 10.6089 13 10.8022 13 11C13 11.2652 12.8946 11.5196 12.7071 11.7071C12.5196 11.8946 12.2652 12 12 12Z"
                fill="currentColor"
            />
            <path d="M14 4.5H7V5.5H14V4.5Z" fill="currentColor" />
            <path d="M9 10.5H2V11.5H9V10.5Z" fill="currentColor" />
        </>
    ),
    link: (
        <>
            <path d="M12 14H4V3H7V2H3V15H13V8H12V14Z" fill="currentColor" />
            <path
                d="M8.50002 2H12.29L7.21002 7.08L7.92002 7.79L13 2.71V6.5H14V1H8.50002V2Z"
                fill="currentColor"
            />
        </>
    ),
    calendar: (
        <>
            <path
                d="M11 1H10V2H6V1H5V2H1V14H15V2H11V1ZM10 3V5H6V3H10ZM2 3H5V5H2V3ZM14 13H2V6H5V7H6V6H10V7H11V6H14V13ZM14 3V5H11V3H14Z"
                fill="currentColor"
            />
        </>
    ),
    stopwatch: (
        <>
            <path
                d="M8 15C9.28558 15 10.5423 14.6188 11.6112 13.9046C12.6801 13.1903 13.5132 12.1752 14.0052 10.9874C14.4972 9.79972 14.6259 8.49279 14.3751 7.23191C14.1243 5.97104 13.5052 4.81285 12.5962 3.90381C11.6872 2.99476 10.529 2.3757 9.26809 2.1249C8.00721 1.87409 6.70028 2.00281 5.51256 2.49478C4.32484 2.98675 3.30968 3.81987 2.59545 4.88879C1.88122 5.95771 1.5 7.21442 1.5 8.5C1.5 10.2239 2.18482 11.8772 3.40381 13.0962C4.62279 14.3152 6.27609 15 8 15ZM8 3C9.0878 3 10.1512 3.32257 11.0556 3.92692C11.9601 4.53126 12.6651 5.39025 13.0813 6.39524C13.4976 7.40023 13.6065 8.5061 13.3943 9.573C13.1821 10.6399 12.6583 11.6199 11.8891 12.3891C11.1199 13.1583 10.1399 13.6821 9.073 13.8943C8.0061 14.1065 6.90023 13.9976 5.89524 13.5813C4.89025 13.1651 4.03126 12.4601 3.42692 11.5556C2.82257 10.6512 2.5 9.5878 2.5 8.5C2.50264 7.04212 3.08295 5.64471 4.11383 4.61383C5.14471 3.58295 6.54212 3.00264 8 3Z"
                fill="currentColor"
            />
            <path d="M9.5 0.5H6.5V1.5H9.5V0.5Z" fill="currentColor" />
            <path
                d="M10.85 10.65L8.5 8.29V4.92H7.5V8.71L10.15 11.35L10.85 10.65Z"
                fill="currentColor"
            />
        </>
    ),
    project: (
        <>
            <path d="M5 14H3V15H5V14Z" fill="currentColor" />
            <path d="M8 14H6V15H8V14Z" fill="currentColor" />
            <path
                d="M9 14V15H13V4.29L9.71 1H3V7H4V2H9.29L12 4.71V14H9Z"
                fill="currentColor"
            />
            <path d="M4 11H3V13H4V11Z" fill="currentColor" />
            <path d="M4 8H3V10H4V8Z" fill="currentColor" />
        </>
    ),
    job: (
        <>
            <path
                d="M13 4.29L9.71 1H3V15H13V4.29ZM12 14H4V2H9.29L12 4.71V14Z"
                fill="currentColor"
            />
        </>
    ),
    data: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.31781 3.68742C3.35608 4.12457 3 4.62628 3 5C3 5.37372 3.35608 5.87543 4.31781 6.31258C5.23441 6.72922 6.53579 7 8 7C9.46421 7 10.7656 6.72922 11.6822 6.31258C12.6439 5.87543 13 5.37372 13 5C13 4.62628 12.6439 4.12457 11.6822 3.68742C10.7656 3.27078 9.46421 3 8 3C6.53579 3 5.23441 3.27078 4.31781 3.68742ZM3.90401 2.77705C4.97802 2.28886 6.42664 2 8 2C9.57336 2 11.022 2.28886 12.096 2.77705C13.1249 3.24472 14 3.993 14 5C14 6.007 13.1249 6.75528 12.096 7.22295C11.022 7.71114 9.57336 8 8 8C6.42664 8 4.97802 7.71114 3.90401 7.22295C2.87513 6.75528 2 6.007 2 5C2 3.993 2.87513 3.24472 3.90401 2.77705Z"
                fill="currentColor"
            />
            <path
                d="M13 11C13 11.94 10.86 13 8 13C5.14 13 3 11.94 3 11H2C2 12.71 4.58 14 8 14C11.42 14 14 12.71 14 11H13Z"
                fill="currentColor"
            />
            <path
                d="M13 8C13 8.94 10.86 10 8 10C5.14 10 3 8.94 3 8H2C2 9.71 4.58 11 8 11C11.42 11 14 9.71 14 8H13Z"
                fill="currentColor"
            />
        </>
    ),
    dashboard: (
        <>
            <path
                d="M8 4C4.14 4 1 7.14 1 11H2C2 7.69 4.69 5 8 5C9.11 5 10.15 5.31 11.04 5.84L11.77 5.11C10.68 4.41 9.39 4 8 4Z"
                fill="currentColor"
            />
            <path
                d="M13.16 7.96C13.69 8.85 14 9.89 14 11H15C15 9.61 14.59 8.32 13.89 7.23L13.16 7.96Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64645 10.6464L13.6464 4.64645L14.3536 5.35355L8.35355 11.3536L7.64645 10.6464Z"
                fill="currentColor"
            />
        </>
    ),
    report: (
        <>
            <path
                d="M13 15H3V1H8V2H4V14H12V6L8 2V1L13 6V15Z"
                fill="currentColor"
            />
            <path d="M12 6H8V2L9 3V5H11L12 6Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 9H5V8H11V9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 11H5V10H8V11Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 13H5V12H9V13Z"
                fill="currentColor"
            />
        </>
    ),
    version: (
        <>
            <path
                d="M4 4C4.55 4 5 4.45 5 5C5 5.55 4.55 6 4 6C3.45 6 3 5.55 3 5C3 4.45 3.45 4 4 4ZM4 3C2.9 3 2 3.9 2 5C2 6.1 2.9 7 4 7C5.1 7 6 6.1 6 5C6 3.9 5.1 3 4 3Z"
                fill="currentColor"
            />
            <path
                d="M4 10C4.55 10 5 10.45 5 11C5 11.55 4.55 12 4 12C3.45 12 3 11.55 3 11C3 10.45 3.45 10 4 10ZM4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 5.5H7V4.5H14V5.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 11.5H7V10.5H14V11.5Z"
                fill="currentColor"
            />
        </>
    ),
    table: (
        <>
            <path d="M14 3V13H2V3H14ZM15 2H1V14H15V2Z" fill="currentColor" />
            <path d="M14 5H2V6H14V5Z" fill="currentColor" />
            <path d="M12 8H4V9H12V8Z" fill="currentColor" />
            <path d="M12 10H4V11H12V10Z" fill="currentColor" />
        </>
    ),
    "bar-chart": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 2.00281L1.99998 1.99719L2.0672 13.9615L15.0015 14L14.9985 15L1.07278 14.9585L1 2.00281Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99999 3V13H6.99999V3H8.99999Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 9V13H9.99999V9H12Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99999 8V13H3.99999V8H5.99999Z"
                fill="currentColor"
            />
        </>
    ),
    "line-chart": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 2.00281L1.99998 1.99719L2.0672 13.9615L15.0015 14L14.9985 15L1.07278 14.9585L1 2.00281Z"
                fill="currentColor"
            />
            <path
                d="M2.99999 11.7L2.96999 10L5.89999 5.77L8.94999 8.31L12.63 4.17L13.37 4.83L9.04999 9.69L6.09999 7.23L2.99999 11.7Z"
                fill="currentColor"
            />
        </>
    ),
    "pie-chart": (
        <>
            <path
                d="M13.91 8.99C13.44 11.83 10.97 14 8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2C9.1 2 10.13 2.3 11.01 2.82L11.66 2.04C10.59 1.38 9.34 1 8 1C4.14 1 1 4.14 1 8C1 11.86 4.14 15 8 15C11.52 15 14.44 12.38 14.92 8.98H13.91V8.99Z"
                fill="currentColor"
            />
            <path
                d="M12.48 4.02C12.25 3.76 12.01 3.53 11.74 3.31L8 8H14C14 7.66 13.96 7.33 13.91 7H10L12.48 4.02Z"
                fill="currentColor"
            />
            <path
                d="M13.91 7H14.92C14.71 5.56 14.07 4.27 13.13 3.25L12.48 4.02C13.21 4.84 13.72 5.87 13.91 7Z"
                fill="currentColor"
            />
            <path
                d="M13.91 7C13.97 7.33 14 7.66 14 8H15C15 7.66 14.97 7.33 14.92 7H13.91Z"
                fill="currentColor"
            />
            <path
                d="M12.48 4.02L13.13 3.25C12.89 2.99 12.64 2.75 12.37 2.54L11.75 3.32C12.02 3.53 12.26 3.77 12.49 4.03L12.48 4.02Z"
                fill="currentColor"
            />
        </>
    ),
    "progress-status-circle-1": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6917 6.15715L11.7677 6.53946C11.573 6.06906 11.2843 5.62743 10.8995 5.24262C10.5147 4.8578 10.0731 4.56909 9.60265 4.37446L9.98496 3.45043C10.5747 3.69443 11.1272 4.05612 11.6066 4.53551C12.086 5.0149 12.4477 5.56741 12.6917 6.15715ZM6.15717 3.45043L6.53948 4.37446C6.06908 4.56909 5.62745 4.8578 5.24264 5.24262C4.85783 5.62743 4.56911 6.06906 4.37449 6.53946L3.45045 6.15715C3.69445 5.56741 4.05615 5.0149 4.53553 4.53551C5.01492 4.05612 5.56744 3.69443 6.15717 3.45043ZM3.45045 9.98494C3.69445 10.5747 4.05615 11.1272 4.53553 11.6066C5.01492 12.086 5.56744 12.4477 6.15717 12.6917L6.53948 11.7676C6.06908 11.573 5.62745 11.2843 5.24264 10.8995C4.85783 10.5147 4.56911 10.073 4.37449 9.60263L3.45045 9.98494ZM9.98496 12.6917L9.60265 11.7676C10.0731 11.573 10.5147 11.2843 10.8995 10.8995C11.2843 10.5147 11.573 10.073 11.7677 9.60263L12.6917 9.98494C12.4477 10.5747 12.086 11.1272 11.6066 11.6066C11.1272 12.086 10.5747 12.4477 9.98496 12.6917Z"
                fill="currentColor"
            />
        </>
    ),
    "progress-status-circle-2": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8ZM13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8Z"
                fill="currentColor"
            />
        </>
    ),
    "way-finding-here": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00124 5.31302L5.31424 8.00002L8.00124 10.687L10.6882 8.00002L8.00124 5.31302ZM3.90002 8.00002L8.00124 12.1012L12.1025 8.00002L8.00124 3.8988L3.90002 8.00002Z"
                fill="currentColor"
            />
        </>
    ),
    "way-finding-exit": (
        <>
            <path
                d="M12 14H4V2H12V3H13V1H3V15H13V13H12V14Z"
                fill="currentColor"
            />
            <path
                d="M11 4.82L13.68 7.5H6.5V8.5H13.68L11 11.18L11.71 11.89L15.6 8L11.71 4.11L11 4.82Z"
                fill="currentColor"
            />
        </>
    ),
    "slack-logo": (
        <>
            <path
                d="M4.5212 9.58287C4.5212 10.2767 3.95442 10.8435 3.2606 10.8435C2.56678 10.8435 2 10.2767 2 9.58287C2 8.88905 2.56678 8.32227 3.2606 8.32227H4.5212V9.58287Z"
                fill="#E01E5A"
            />
            <path
                d="M5.15637 9.58287C5.15637 8.88905 5.72315 8.32227 6.41697 8.32227C7.11079 8.32227 7.67758 8.88905 7.67758 9.58287V12.7393C7.67758 13.4331 7.11079 13.9999 6.41697 13.9999C5.72315 13.9999 5.15637 13.4331 5.15637 12.7393V9.58287Z"
                fill="#E01E5A"
            />
            <path
                d="M6.41697 4.5212C5.72315 4.5212 5.15637 3.95442 5.15637 3.2606C5.15637 2.56678 5.72315 2 6.41697 2C7.11079 2 7.67758 2.56678 7.67758 3.2606V4.5212H6.41697Z"
                fill="#36C5F0"
            />
            <path
                d="M6.41699 5.15637C7.11081 5.15637 7.6776 5.72315 7.6776 6.41697C7.6776 7.11079 7.11081 7.67758 6.41699 7.67758H3.2606C2.56678 7.67758 2 7.11079 2 6.41697C2 5.72315 2.56678 5.15637 3.2606 5.15637H6.41699Z"
                fill="#36C5F0"
            />
            <path
                d="M11.4787 6.41697C11.4787 5.72315 12.0455 5.15637 12.7393 5.15637C13.4331 5.15637 13.9999 5.72315 13.9999 6.41697C13.9999 7.11079 13.4331 7.67758 12.7393 7.67758H11.4787V6.41697Z"
                fill="#2EB67D"
            />
            <path
                d="M10.8435 6.41699C10.8435 7.11081 10.2767 7.6776 9.5829 7.6776C8.88908 7.6776 8.3223 7.11081 8.3223 6.41699V3.2606C8.3223 2.56678 8.88908 2 9.5829 2C10.2767 2 10.8435 2.56678 10.8435 3.2606V6.41699Z"
                fill="#2EB67D"
            />
            <path
                d="M9.5829 11.4786C10.2767 11.4786 10.8435 12.0454 10.8435 12.7392C10.8435 13.4331 10.2767 13.9998 9.5829 13.9998C8.88908 13.9998 8.3223 13.4331 8.3223 12.7392V11.4786H9.5829Z"
                fill="#ECB22E"
            />
            <path
                d="M9.5829 10.8435C8.88908 10.8435 8.3223 10.2767 8.3223 9.58287C8.3223 8.88905 8.88908 8.32227 9.5829 8.32227H12.7393C13.4331 8.32227 13.9999 8.88905 13.9999 9.58287C13.9999 10.2767 13.4331 10.8435 12.7393 10.8435H9.5829Z"
                fill="#ECB22E"
            />
        </>
    ),
    "office-pay": (
        <>
            <path d="M3 6H5V11H3V6Z" fill="currentColor" />
            <path d="M14 12V14H2L2 12L14 12Z" fill="currentColor" />
            <path d="M14 4V5L2 5L2 4L8 1L14 4Z" fill="currentColor" />
            <path d="M7 6H9V11H7V6Z" fill="currentColor" />
            <path d="M11 6H13V11H11V6Z" fill="currentColor" />
        </>
    ),
    "office-seat": (
        <>
            <path d="M3 8H1V10H3V8Z" fill="currentColor" />
            <path d="M15 8H13V10H15V8Z" fill="currentColor" />
            <path
                d="M10 2H6C4.89543 2 4 2.89543 4 4V10H12V4C12 2.89543 11.1046 2 10 2Z"
                fill="currentColor"
            />
            <path d="M3 12H5V15H3V12Z" fill="currentColor" />
            <path d="M11 12H13V15H11V12Z" fill="currentColor" />
            <path d="M3 12V11H13V12H3Z" fill="currentColor" />
        </>
    ),
    "toggle-true": (
        <>
            <rect width="32" height="16" rx="8" fill="black" />
            <circle cx="24" cy="8" r="6" fill="white" />
        </>
    ),
    "toggle-false": (
        <>
            <rect width="32" height="16" rx="8" fill="#DADBDE" />
            <circle cx="8" cy="8" r="6" fill="white" />
        </>
    ),
    "sync": (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.20308 4.6219C3.39034 5.57808 3 6.83396 3 8C3 10.3207 4.83113 13 8 13C9.59739 13 10.8501 12.3178 11.7089 11.4589C12.5882 10.5797 13 9.57446 13 9H14C14 9.92554 13.4118 11.1703 12.4161 12.1661C11.3999 13.1822 9.90261 14 8 14C4.16887 14 2 10.7562 2 8C2 6.62758 2.45582 5.13346 3.44115 3.97425C4.43725 2.80236 5.9527 2 8 2C9.8708 2 11.1811 2.6066 12.121 3.47687C12.4636 3.79417 12.7526 4.14251 13 4.50059V2H14V7H9V6H12.7201C12.4011 5.36058 12.0011 4.72873 11.4415 4.21063C10.6939 3.5184 9.6292 3 8 3C6.23961 3 5.00505 3.67841 4.20308 4.6219Z"
                fill="black"
            />
        </>
    ),
    "indicator": (
        <>            
            <circle cx="5" cy="5" r="5" fill="currentColor"/>
        </>
    ),
};

const ICON_NAMES = Object.keys(ICONS);

const StyledIcon = styled.svg`
    color: ${(props) => COLOR_VALUES[props.$color] ?? ""};
    display: block;
`;

const Icon = ({
    name,
    color,
    size,
    width,
    height,
    viewBox = `0 0 16 16`,
    ...rest
}) =>
    ICONS[name] ? (
        <StyledIcon
            $color={color}
            width={width ? width : size}
            height={height ? height : size}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            {ICONS[name]}
        </StyledIcon>
    ) : (
        <></>
    );

Icon.defaultProps = {
    size: 16,
};

Icon.propTypes = {
    name: PropTypes.oneOf(ICON_NAMES),
    color: PropTypes.oneOf(Object.keys(COLOR_VALUES)),
    size: PropTypes.number,
};

export default Icon;
export { ICON_NAMES };
