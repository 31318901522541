import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";

export const DateButton = styled.button`
    ${TEXT_VARIANT_STYLES.Descriptive_1_1}
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: ${COLOR_VALUES.Black};
    border: none;
    background: transparent;
    padding: 0;
    text-align: center;

    &:hover {
        border: 1px solid ${COLOR_VALUES.Black};
    }
    &:disabled {
        color: ${COLOR_VALUES.Gray_4};
        cursor: not-allowed;
    }

    ${(props) =>
        props.isCurrent &&
        css`
            background: ${COLOR_VALUES.Gray_7};
        `};

    ${(props) =>
        props.isSelected &&
        css`
            color: ${COLOR_VALUES.White};
            background: ${COLOR_VALUES.Black};
        `};

    ${(props) =>
        (props.isDisabled || props.isUnavailable) &&
        css`
            color: ${COLOR_VALUES.Gray_4};
            cursor: not-allowed;
        `};

    ${(props) =>
        props.notHover &&
        css`
            &:hover {
                border: none;
            }
        `};
`;
