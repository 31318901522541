module.exports = global.config = {
    i18n: {
        welcome: {
            en: "Welcome",
        },
    },
    routes: {
        root: "/",
        allocation: "/allocations",
        unauthorized: "/unauthorized",
        error: "/error",
        sessionExpired: "/sessionexpired",
        businessIntelligenceLandingPage: "/businessIntelligence/",
        adminLandingPage: "/admin",
        adminAccessGroupsLanding: "/admin/accessgroups",
        adminAgenciesLanding: "/admin/agencies",
        adminRegionsLanding: "/admin/regions",
        adminOfficesLanding: "/admin/offices",
        adminClientsLanding: "/admin/clients",
        adminClientGroups: "groups",
        adminClientContactsAndCards: "contacts-and-cards",
        adminHolidaysLanding: "/admin/holidays",
        adminProjectsLanding: "/admin/projects",
        adminProjectsLinked: "linked",
        adminRetainersLanding: "/admin/retainers",
        timesheetLandingPage: "/timesheets/employee",
        timesheetGrid: "timesheet",
        timesheetIncomplete: "incomplete",
        timeOff: "timeOff",
        timesheetHolidays: "holidays",
        manageTimeOff: "manageTimeOff",
        adminAbsenseReasons: "/admin/HR/absenseReasons",
        adminClientFamilyCorp: "/admin/projects/clientFamily",
        projectLanding: "/projects",
        projectsRetainersDashboardLanding: "/projects/retainers/dashboard",
        projectsRetainersDashboardNotesTab: "notes",
        projectsDashboardLanding: "/projects/dashboard",
        projectsProjectsDashboardNotesTab: "notes",
        projectPlanner: "/projects/planner",
    },
    mails: {
        support: "support@bestwork.com",
    },
    colors: {
        white: "#FFFFFF",
        black: "#000000",
        green: "#1FD323",
        yellow: "#FFCD00",
        gray1: "#3F434D",
        gray2: "#5F636D",
        gray3: "#8C8F97",
        gray4: "#A3A6AB",
        gray5: "#DADBDE",
        gray6: "#E5E8EC",
        gray7: "#EDEFF2",
        gray8: "#F5F6F8",
        gray9: "#FAFAFB",
        gray10: "#E0E0E0",
        blue1: "#0066F5",
        blue2: "#70ACFF",
        blue3: "#C2DBFF",
        blue4: "#E1EEFF",
        blue5: "#F4F7FB",
        blue6: "#A6CCFF",
        orange1: "#FF3D00",
        orange2: "#FFBEAD",
        orange3: "#FFDED6",
        red1: "#B4090A",
        red2: "#E51837",
    },
    fonts: {
        sizes: {
            xxsmall: "0.625rem",
            xsmall: "0.75rem",
            small: "0.85rem",
            smedium: "0.875rem",
            medium: "1rem",
            large: "1.25rem",
            xlarge: "1.5rem",
            xxlarge: "1.75rem",
            xxxlarge: "1.875rem",
        },
        families: {
            light: "HelveticaNeueW01-45Ligh",
            regular: "HelveticaNeueW02-55Roma",
            medium: "HelveticaNeueW01-65Medi",
            bold: "HelveticaNeueW01-75Bold",
            //light: "HelveticaW05Display",
            //regular: "HelveticaW05Display",
            //medium: "HelveticaW05Display",
            //bold: "HelveticaW05Display",
        },
    },
    sizes: {
        grid: {
            clickableCell: "95%",
        },
        sidebar: {
            height: "80vh",
            width: "33%",
            gutter: "0 13%",
        },
        modal: {
            height: "85vh",
        },
        borderRadius: "0.188rem",
    },
    border: {
        small: "0.01em solid #e5e5e5",
        small2: "0.01em solid #A3A6AB",
        small3: "0.01em solid #E5E8EC",
        smallBlack: "0.01rem solid #000000",
        smallGray7: "0.01rem solid #EDEFF2",
        medium: "0.1rem solid #000000",
        scale: "0.04em solid #A3A6AB",
        large: "0.2em solid #000000",
        cellAlert: "0.01em solid #A3A6AB",
    },
    notification: {
        duration: 5000,
    },
    pagination: {
        pageSize: 50,
        maxPage: 50,
        groupByPageSize: 100 /* In allocations/summary endpoint if groupBy parameter value is not null then we receive 100 records though in payload pageSize is 25 */,
    },
    modal: {
        keyboard: true,
        backdrop: "static",
    },
};
