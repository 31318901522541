import React from "react";

import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import DropdownMultiSelect from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelect";
import FormInputWrapper from "../ui/FormInputWrapper";
import { useFormInput } from "./utils";

export const getDropdownMultiSelectOptions = (
    optionDetails = [],
    Id = "Id",
    Name = "Name"
) => {
    return optionDetails.reduce((acc, val) => {
        return [
            ...acc,
            {
                value: val[Id],
                label: val[Name],
                id: val[Id],
            },
        ];
    }, []);
};

const DropdownMultiSelectForm = React.forwardRef(
    (
        {
            name,
            validations,
            label,
            description,
            helpText,
            className,
            isSmallSize,
            initialData = [],
            isListOptionsLoaded = false,
            ...rest
        },
        ref
    ) => {
        const { wrapperProps, inputProps } = useFormInput({
            name,
            validations,
            isControlled: true,
        });

        return (
            <FormInputWrapper
                {...{ label, description, helpText, className, isSmallSize }}
                {...wrapperProps}
            >
                {isListOptionsLoaded ? (
                    <DropdownMultiSelect
                        {...rest}
                        {...inputProps}
                        initialData={initialData}
                        onChangeDropdown={(val) => {
                            inputProps.onChange(val);
                            inputProps.onBlur(val);
                        }}
                        ref={ref}
                    />
                ) : (
                    <DropdownSingleSelectWithoutSearch
                        {...rest}
                        {...inputProps}
                        disabled={true}
                    />
                )}
            </FormInputWrapper>
        );
    }
);

export default DropdownMultiSelectForm;
