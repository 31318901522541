import React from "react";
import styled, { css } from "styled-components";

import Text from "components/design-system/ui/Text";
import VStack from "components/design-system/layout/VStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import ProgressBar from "components/UI/ProgressBar";

export const WrapperVStack = styled(VStack)`
    gap: 24px;
    ${(props) =>
        props.isPadding &&
        css`
            padding-top: 48px;
        `}
`;

export const GroupVStack = styled(VStack)`
    gap: 24px;
`;

export const StyledHR = styled.hr`
    border: 0;
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    width: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
`;

const StyledText = styled(Text)`
    ${(props) =>
        props.isNextLine &&
        css`
            white-space: pre-line;
        `}
`;

const DataGroup = ({ groupData, isPadding = true }) => {
    const data = Object.values(groupData)?.every((e) => !e?.value);

    return data ? (
        <></>
    ) : (
        <WrapperVStack isPadding={isPadding}>
            {groupData.map(
                (data, index) =>
                    data.value && (
                        <GroupVStack key={index}>
                            <VStack spacing="Zero_25">
                                <Text variant="Descriptive_1_1" color="Gray_2">
                                    {data.label}
                                </Text>
                                {data.percent && (
                                    <ProgressBar percent={data.percent} />
                                )}
                                <StyledText
                                    variant="Body_2_1"
                                    color="Black"
                                    isNextLine={data.isNextLine}
                                >
                                    {data.value}
                                </StyledText>
                            </VStack>
                            {data.isHr && <StyledHR />}
                        </GroupVStack>
                    )
            )}
        </WrapperVStack>
    );
};

export default DataGroup;
