import PropTypes from "prop-types";

const CaretRight = ({ size, _color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.1196 17.7048L6.17358 11.1281H4L10.1196 21.3337L21.3333 2.66699H19.1404L10.1196 17.7048Z"
                fill="black"
            />
        </svg>
    );
};

CaretRight.defaultProps = {
    size: "16",
    color: global.config.colors.black,
};

CaretRight.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default CaretRight;
