import PropTypes from "prop-types";

const Email = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.66675 6.66675V14.0001H15.3334V6.66675H4.66675ZM13.8515 7.44194L9.81722 10.6667L6.08389 7.44194H13.8515ZM5.42865 13.2249V7.89155L9.79818 11.6667L14.5715 7.85279V13.2249H5.42865Z"
                fill={color}
            />
        </svg>
    );
};

Email.defaultProps = {
    size: "18",
    viewBox: "18",
    color: global.config.colors.black,
};

Email.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default Email;
