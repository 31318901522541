import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Breadcrumb from "components/design-system/ui/Breadcrumb";
import Text from "components/design-system/ui/Text";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Icon from "components/design-system/ui/Icon";
import Tooltip from "components/design-system/ui/Tooltip";

const StyledPageHeadTitle = styled(Text)`
    font-feature-settings: "pnum" on, "lnum" on;
`;

const StyledListWrapper = styled(HStack)`
    gap: 24px;
`;
const PageHead = ({
    title,
    description,
    menuOptions,
    isSearch,
    onClickSearch,
    listOptions,
    ...rest
}) => {
    return (
        <VStack spacing="One" {...rest}>
            {menuOptions && <Breadcrumb menuOptions={menuOptions} />}
            <HStack justify="space-between" align="flex-end">
                <VStack spacing="Zero_5">
                    {title && (
                        <StyledPageHeadTitle variant="Headline_1" color="Black">
                            {title}
                        </StyledPageHeadTitle>
                    )}
                    {description && (
                        <Text variant="Body_1_1" color="Gray_2">
                            {description}
                        </Text>
                    )}
                    {listOptions && (
                        <StyledListWrapper>
                            {listOptions.map((data, index) => (
                                <Tooltip
                                    text={data.text}
                                    enterDelay={0}
                                    placement="bottom-start"
                                    key={index}
                                >
                                    <HStack spacing="Zero_5" align="center">
                                        {data.name !== "null" && (
                                            <>
                                                <Icon name={data.icon} />
                                                <Text
                                                    variant="Body_1_1"
                                                    color="Gray_2"
                                                >
                                                    {data.name}
                                                </Text>
                                            </>
                                        )}
                                    </HStack>
                                </Tooltip>
                            ))}
                        </StyledListWrapper>
                    )}
                </VStack>
                {isSearch && (
                    <TertiaryButton
                        {...{ "aria-label": "Search" }}
                        leadingIcon="search"
                        onClick={onClickSearch}
                        style={{ marginBottom: "5px" }}
                    />
                )}
            </HStack>
        </VStack>
    );
};

PageHead.defaultProps = {
    isSearch: false,
    onClickSearch: () => void 0,
};

PageHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    menuOptions: PropTypes.array,
    isSearch: PropTypes.bool,
    onClickSearch: PropTypes.func,
};

export default PageHead;
