import { format } from "date-fns";
import { useWatch } from "react-hook-form";

import { sendNotification } from "utilities/Notification";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import TextInput from "components/design-system/forms/TextInput";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Dropdown from "components/design-system/forms/Dropdown";
import DatePicker from "components/design-system/forms/DatePicker";
import Options from "components/design-system/forms/Options";
import {
    getCalendarDateFromNewDate,
    getDropdownOptions,
} from "pages/admin/offices/OfficesForm";

import useOffices from "pages/admin/offices/useOffices";
import useHolidays from "pages/admin/holidays/useHolidays";

const attributesOptions = [
    { label: "All Day", value: "AllDay" },
    { label: "Half Day", value: "HalfDay" },
];

const AddEditHolidayForm = ({
    officeDropdownOptions,
    holidaysData,
    currentHolidayData,
    offices,
    isEditing,
}) => {
    const currentOfficeId = useWatch({ name: "officeName" });
    const currentHolidayDate = useWatch({ name: "holidayDate" });

    const currentOfficeName = officeDropdownOptions?.find(
        (f) => f.value === currentOfficeId
    )?.label;

    let newDefaultDate = currentHolidayData?.Date
        ? getCalendarDateFromNewDate(new Date(currentHolidayData?.Date))
        : null;

    return (
        <>
            <TextInput
                type="text"
                name="name"
                label="Holiday Name"
                validations={{
                    required: true,
                    validate: () =>
                        holidaysData.filter(
                            (holiday) =>
                                holiday.OfficeId === currentOfficeId &&
                                format(new Date(holiday.Date), "yyyy-MM-dd") ===
                                    currentHolidayDate
                        ).length === 0 ||
                        `A Holiday for ${format(
                            new Date(currentHolidayDate),
                            "MM/dd/yyyy"
                        )} in ${currentOfficeName} already exists.`,
                }}
            />
            <Dropdown
                name="officeName"
                validations={{
                    required: true,
                }}
                label="Location"
                options={officeDropdownOptions}
                itemsAreLoading={offices.isLoading}
                disabled={isEditing}
            />
            <DatePicker
                defaultDateValue={newDefaultDate}
                name="holidayDate"
                label="Holiday Date"
                validations={{
                    required: true,
                }}
                disabled={isEditing}
            />
        </>
    );
};

const ModalCTA = ({ isEditing, holidayIdToEdit, onClose }) => {
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add Holiday",
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    label: "Delete",
                    leadingIcon: "delete",
                    to: isEditing && "?action=delete&id=" + holidayIdToEdit,
                },
            })}
            as="fieldset"
        />
    );
};

function transformHolidayDataToFormData(holidayData = {}) {
    return {
        name: holidayData.Name,
        officeName: holidayData.OfficeId,
        holidayDate: holidayData.Date
            ? format(new Date(holidayData.Date), "yyyy-MM-dd")
            : null,
        isHalfDay: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "AllDay"
                    ? !holidayData[attributesOptions[1].value]
                    : holidayData[item]
            )
            .join(","),
    };
}

function transformFormDataToHolidayData(formData = {}, HolidayData = {}) {
    const newHolidayData = { ...HolidayData };
    newHolidayData.Name = formData.name;
    newHolidayData.OfficeId = formData.officeName;
    newHolidayData.Date = formData.holidayDate;
    attributesOptions.forEach((attribute) => {
        newHolidayData[attribute.value] = formData.isHalfDay
            .split(",")
            .includes(attribute.value);
    });
    return newHolidayData;
}

const HolidayForm = ({ holidayIdToEdit, onClose }) => {
    const { holidays, createHoliday, updateHoliday } = useHolidays();
    const { offices } = useOffices();

    if (holidays.isLoading || offices.isLoading) {
        return <div>Loading...</div>;
    }

    if (holidays.error || offices.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof holidayIdToEdit !== "undefined";
    const modifier = isEditing ? updateHoliday : createHoliday;

    const currentHolidayData = holidays.data.find(
        (holiday) => holiday.Date === holidayIdToEdit
    );

    if (isEditing && !currentHolidayData) {
        return <div>Could not find holiday</div>;
    }

    const officeDropdownOptions = !offices.isLoading
        ? getDropdownOptions(offices?.data, "OfficeRegionId")
        : [];

    const holidaysData = isEditing
        ? holidays.data.filter(
              (holiday) =>
                  format(new Date(holiday.Date), "yyyy-MM-dd") !==
                  format(new Date(holidayIdToEdit), "yyyy-MM-dd")
          )
        : holidays.data;

    const onSubmit = (formData) => {
        const holidayData = transformFormDataToHolidayData(
            formData,
            currentHolidayData
        );

        modifier.mutateAsync(holidayData).then(() => {
            onClose();
            sendNotification(
                undefined,
                isEditing ? (
                    <>
                        <strong>{holidayData.Name}</strong> has been updated
                    </>
                ) : (
                    <>
                        <strong>{holidayData.Name}</strong> has been added.
                    </>
                )
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformHolidayDataToFormData(currentHolidayData)}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <VStack>
                        <AddEditHolidayForm
                            officeDropdownOptions={officeDropdownOptions}
                            holidaysData={holidaysData}
                            currentHolidayData={currentHolidayData}
                            offices={offices}
                            isEditing={isEditing}
                        />
                        <Options
                            multiselect={false}
                            name="isHalfDay"
                            label="Attributes"
                            options={attributesOptions}
                            validations={{
                                required: true,
                            }}
                        />
                    </VStack>
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    holidayIdToEdit={holidayIdToEdit}
                    onClose={onClose}
                    isLoading={modifier.isLoading || offices.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default HolidayForm;
