import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import { sendNotification } from "utilities/Notification";
import useTimeOffApi from "./useTimeOff";
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";


const DeleteTimeOffRequestConfirmation = ({
    timeOffRequestIdToDelete,
    onClose,
}) => {
    const { deleteTimeOffRequest } = useTimeOffApi();
    const modifier = deleteTimeOffRequest;
    const [disableSubmitButton, setDisabledSubmitButton] = useState(false);

    const onSubmit = () => {
        const data = { timeOffRequestId: timeOffRequestIdToDelete };
        setDisabledSubmitButton(true);
        modifier.mutateAsync(data).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>Time off request has been deleted</>
            );
        });
    };
    return (
        <Form onSubmit={onSubmit}>
            <VStack spacing="Three">
                <Text variant="Body_1_1" color="Gray_2">
                    Are you sure you'd like to delete time off?
                    {disableSubmitButton && <CircularProgress
                        style={{
                            height: "12px",
                            width: "12px",
                            marginLeft: "12px"
                        }}
                    />}
                </Text>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: "Yes, Delete",
                        disabled: disableSubmitButton
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        to: "?action=edit&id=" + timeOffRequestIdToDelete,
                    }}
                />
            </VStack>
        </Form>
    );
};

export default DeleteTimeOffRequestConfirmation;
