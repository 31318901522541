import React, { useState, useMemo } from "react";

const ManageTimeOffContext = React.createContext();

export const ManageTimeOffContextProvider = (props) => {
    const [
        upcomingPendingTimeOffRequestCount,
        setUpcomingPendingTimeOffRequestCount,
    ] = useState(0);
    const [rejectActionFromMenu, setRejectActionFromMenu] = useState(false);
    const [isManageViewRequest, setIsManageViewRequest] = useState(false);
    const [manageTimeOffRequestsData, setManageTimeOffRequestsData] = useState(
        []
    );

    const contextValue = useMemo(() => {
        return {
            upcomingPendingTimeOffRequestCount,
            setUpcomingPendingTimeOffRequestCount,
            rejectActionFromMenu,
            setRejectActionFromMenu,
            isManageViewRequest,
            setIsManageViewRequest,
            manageTimeOffRequestsData,
            setManageTimeOffRequestsData,
        };
    }, [
        upcomingPendingTimeOffRequestCount,
        setUpcomingPendingTimeOffRequestCount,
        rejectActionFromMenu,
        setRejectActionFromMenu,
        isManageViewRequest,
        setIsManageViewRequest,
        manageTimeOffRequestsData,
        setManageTimeOffRequestsData,
    ]);

    return (
        <ManageTimeOffContext.Provider value={contextValue}>
            {props.children}
        </ManageTimeOffContext.Provider>
    );
};

export default ManageTimeOffContext;
