import { useContext, useEffect, useState, useRef } from "react";
import Context from "context/Allocations/Context";
import Site from "resources/Site";
import Loading from "components/UI/Loading";
import styled from "styled-components";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Text from "components/UI/Text";
import TextDS from "components/design-system/ui/Text";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";

const Message = styled.div``;
const Actions = styled.div`
    margin-top: 1em;
`;

const loadingMessage = (message) => {
    return (
        <MessageWrapper>
            <Loading text={message} />
        </MessageWrapper>
    );
};

const MessageWrapper = styled.div`
    text-align: center;
    padding: 6em 0;
    display: grid;
    justify-content: center;
    align-content: center;
    ${Message} {
    }
    ${Actions} {
    }
`;

const BackToTopWrapper = styled.div`
    padding: 64px;
    display: flex;
    justify-content: center;
`;

const gridMessage = (title, message, actions) => {
    return (
        <MessageWrapper>
            {title && (
                <Message>
                    <Text variant="largeBold">{title}</Text>
                    <Text variant="smallLight">{message}</Text>
                </Message>
            )}
            {actions && <Actions>{actions.map((item) => item)}</Actions>}
        </MessageWrapper>
    );
};

const gridBackToTop = (message, buttonMesssage, footerHeight) => {
    const windowHeight = window.innerHeight;
    const gridContainerHeight =
        document.getElementById("gridContainer").clientHeight;
    const showBackToTopButton = !!(
        windowHeight <
        gridContainerHeight - footerHeight
    );

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <BackToTopWrapper>
            <VStack>
                <HStack>
                    {message && (
                        <TextDS variant="Body_2_1" color="Gray_2">
                            {message}
                        </TextDS>
                    )}
                </HStack>
                {showBackToTopButton && (
                    <HStack justify="center">
                        <TertiaryButton
                            trailingIcon={"arrow-up"}
                            label={buttonMesssage}
                            onClick={scrollToTop}
                        ></TertiaryButton>
                    </HStack>
                )}
            </VStack>
        </BackToTopWrapper>
    );
};

const AllocationsFooter = () => {
    const { isLoadingMore } = useContext(Context);
    const footerContainer = useRef();
    const [footerHeight, setFooterHeight] = useState(0);

    useEffect(() => {
        if (footerContainer.current) {
            setFooterHeight(footerContainer.current.clientHeight);
        }
    });

    const gridFooterToShow = () => {
        if (isLoadingMore.loading){
            return loadingMessage(isLoadingMore.message);
        }
    };

    return <div style={{paddingTop: "100px"}} ref={footerContainer}>{gridFooterToShow()}</div>;
};

export default AllocationsFooter;
