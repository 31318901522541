import React, { useEffect, useState } from "react";
import GlobalConfig from "configs/Global";

import Text from "components/design-system/ui/Text";
import Table from "components/design-system/tables/Table";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import Loading from "components/UI/Loading";
import { getMoneyColumnVal } from "common-methods/commonMethods";
import TableFilters from "components/design-system/tables/TableFilters";
import VStack from "components/design-system/layout/VStack";

import {
    useFixedRowsHours,
    useFixedRowsMoney,
} from "hooks/Projects/useFixedRows";

import {
    useProjectDashboardCurrency,
    useProjectsDashboardRelatedData,
} from "pages/projects/project-dashboard/single/useProjectsDashboardData";

const columnNamesAccessor = {
    Num: "Code",
    Name: "Project",
    TotalFee: "TotalFee",
    Baseline: "Baseline",
    AtCompletion: "AtCompletion",
    Used: "Used",
    Variance: "Variance",
};

const tableTotalWidth = 1088;
const columns = [
    {
        headCellProps: {
            text: "Num.",
            width: getCellWidthInPercent(94, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Num,
    },
    {
        headCellProps: {
            text: "Project",
            width: getCellWidthInPercent(280, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            actionIcon: cell.value ? "caret-right" : null,
            actionProps: {
                to: `${GlobalConfig.routes.projectsDashboardLanding}/${cell.row.original.Id}/overview`,
                "aria-label": "Project",
            },
        }),
        accessor: columnNamesAccessor.Name,
    },
    {
        headCellProps: {
            text: "Total Fee",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(
                      cell.row.original?.Currency?.Symbol,
                      cell.value
                  )
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.TotalFee,
    },
    {
        headCellProps: {
            text: "Budget Baseline",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(
                      cell.row.original?.Currency?.Symbol,
                      cell.value
                  )
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Baseline,
    },
    {
        headCellProps: {
            text: `Est. at \nCompletion`,
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(
                      cell.row.original?.Currency?.Symbol,
                      cell.value
                  )
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.AtCompletion,
    },
    {
        headCellProps: {
            text: "Used to Date",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(
                      cell.row.original?.Currency?.Symbol,
                      cell.value
                  )
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Used,
    },
    {
        headCellProps: {
            text: "Variance",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(
                      cell.row.original?.Currency?.Symbol,
                      cell.value
                  )
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Variance,
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(32, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: "",
        }),
        accessor: "DisplayAs",
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const endpointSuffix = {
    money: "money",
    hours: "hours",
};

const FiltersConfig = [
    {
        filterLabel: "Display as",
        name: "displayAs",
        columnAccessor: "DisplayAs",
        unfilteredValue: endpointSuffix.money,
        isSegmentedControl: true,
        options: [
            {
                value: endpointSuffix.money,
                label: "Currency Totals",
            },
            {
                value: endpointSuffix.hours,
                label: "Total Hours",
            },
        ],
    },
];

const totalsStrColmAtStart = 2;

const totalOneColumns = [
    columnNamesAccessor.TotalFee,
    columnNamesAccessor.Baseline,
    columnNamesAccessor.AtCompletion,
    columnNamesAccessor.Used,
    columnNamesAccessor.Variance,
];

const getInitialDataForTotalRow = () => {
    return [
        { column: columnNamesAccessor.Num, text: "" },
        { column: columnNamesAccessor.Name, text: "" },
        { column: totalOneColumns[0], text: 0, alignedRight: true },
        { column: totalOneColumns[1], text: 0, alignedRight: true },
        { column: totalOneColumns[2], text: 0, alignedRight: true },
        { column: totalOneColumns[3], text: 0, alignedRight: true },
        { column: totalOneColumns[4], text: 0, alignedRight: true },
        { column: "None", text: "" },
    ];
};

const ProjectsDashboardOverviewProject = () => {
    const [filters, setFilters] = useState();
    const { useRelatedProjectsDashboard } = useProjectsDashboardRelatedData();

    const filterVal =
        filters?.find((f) => f.id === "DisplayAs")?.value ??
        endpointSuffix.money;
    const summary = useRelatedProjectsDashboard(filterVal);

    const { currency } = useProjectDashboardCurrency();

    const [totalMoney, setTotalOneUpdate] = useFixedRowsMoney({
        initialData: getInitialDataForTotalRow(),
        summary: summary,
        currency: currency,
        totalsStrColmAtStart: totalsStrColmAtStart,
        totalColumns: totalOneColumns,
    });

    const [totalHours, setTotalTwoUpdate] = useFixedRowsHours({
        initialData: getInitialDataForTotalRow(),
        summary: summary,
        totalsStrColmAtStart: totalsStrColmAtStart,
        totalColumns: totalOneColumns,
    });

    useEffect(() => {
        setTotalOneUpdate(true);
        setTotalTwoUpdate(true);
    }, [filterVal]);

    if (summary.isError || currency.isError) {
        return <>Error</>;
    }

    return (
        <>
            <Text
                variant={"Headline_4"}
                color={"Gray_1"}
                style={{ paddingBottom: "16px" }}
            >
                Related Projects
            </Text>

            <VStack>
                <TableFilters
                    filtersConfig={FiltersConfig}
                    onChange={setFilters}
                />

                {summary.isFetching || currency?.isFetching ? (
                    <Loading />
                ) : (
                    <Table
                        grayBgHeader={true}
                        showTableBorder={false}
                        columns={columns}
                        fixedHeadingRows={[
                            {
                                bgColor: COLOR_VALUES.Gray_7,
                                variant: "Headline_5",
                                borderBottom: false,
                                data:
                                    filterVal == endpointSuffix.money
                                        ? totalMoney
                                        : totalHours,
                            },
                        ]}
                        data={summary.data}
                        getRowProps={getRowProps}
                    />
                )}
            </VStack>
        </>
    );
};

export default ProjectsDashboardOverviewProject;
