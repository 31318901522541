const Objects = {
    sortByKey: function (object) {
        return Object.keys(object)
            .sort()
            .reduce((r, k) => ((r[k] = object[k]), r), {});
    },
    isJson: function (object) {
        try {
            JSON.parse(object);
        } catch (e) {
            return false;
        }
        return true;
    },
    parseJson: function (object) {
        try {
            const test = JSON.parse(object);
            if (test && typeof test === "object") {
                return test;
            }
        } catch (e) {
            return null;
        }
        return null;
    },
};

export default Objects;

export const cleanObject = (obj) => {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj;
};
