import React, { useState, useMemo } from "react";
import ReactDOM from "react-dom";
import ApiError from "components/Modal/ErrorModal";
import GenericModal from "components/Modal/Common/GenericModal";

const AppErrorContext = React.createContext();

export const AppErrorProvider = (props) => {
    const [error, setError] = useState(null);

    const removeError = () => {
        setError(null);
        ReactDOM.render(null, document.getElementById("divAppErrorModal"));
    };

    const addError = (e) => {
        setError(e);
        const modalContent = <ApiError error={e} popup />;
        const modal = (
            <GenericModal content={modalContent} parent="divAppErrorModal" />
        );
        ReactDOM.render(modal, document.getElementById("divAppErrorModal"));
    };

    const redirect = (e) => {
        setError(e);
        const modalContent = <ApiError error={e} />;
        const modal = (
            <GenericModal content={modalContent} parent="divAppErrorModal" />
        );
        ReactDOM.render(modal, document.getElementById("divAppErrorModal"));
        window.location.assign(e.errorPageURL);
    };

    const contextValue = useMemo(() => {
        return {
            error,
            removeError,
            addError,
            redirect,
        };
    }, [error]);

    return (
        <AppErrorContext.Provider value={contextValue}>
            {props.children}
        </AppErrorContext.Provider>
    );
};

export default AppErrorContext;
