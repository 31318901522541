import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Container, FormGroup, Row, Col } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TextAreaInputWithValidation from "../../UI/TextAreaInputWithValidation";
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Link from "../../UI/Link";
import { allocationsGridModes, formModes } from "../../../resources/Enums";
import List from "../../../resources/List";
import { useStylesForButtonGroups, useStylesForButton, useStylesForText} from "../../../styles/MakeStyles";
import { sendNotification } from "../../../utilities/Notification";
import { makeStyles } from "@material-ui/core";
import Loader from "components/UI/Loader";
import useAllocationsNoteUpdate from "hooks/Allocations/useAllocationsNoteUpdate";
import useAllocationsNoteRead from "hooks/Allocations/useAllocationsNoteRead";

const Content = styled.form`
    margin-bottom: 100px;
`;
const FormRow = styled(Row)`
    margin: 0 0 1rem 0;
`;
const FormColumn = styled(Col)`
    margin: 0;
    padding: 0;
`;
const FormField = styled.div`
    
`;
const Action = styled.div`
    text-align: right;
    > button.MuiButton-root {
        margin-left: 2rem;
        margin-right: 0;
        min-width: 6.75rem;
        height: 2.625rem;
    }
    > button.MuiButton-root:hover {
        opacity: 0.75;
        box-shadow: none;
        background-color: ${global.config.colors.black};
    }

    position: absolute;
    bottom: 0;
    right: 0;
    width: 19.375rem;
`;

const SecondaryButtonWrapper = styled.div`
    float: left;
`;

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiToggleButton-root": {
            padding: "0.125rem 0.375rem",
        },
        "& .MuiToggleButtonGroup-root": {
            width: "100%",
        },
        "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
            borderLeft: global.config.border.small,
        },
    },
    title: {
        "& .modal-title": {
            width: "100%",
            margin: "1rem 1rem 1rem 1rem",
        },
    },
    header: {
        height: "5rem",
    },
}));

const Note = ({
    mode,
    title,
    employee,
    project,
    date,
    onAddRemoveNote,
    requestHide,
    isNewNote,
    readOnly,
}) => {
    const { saveNote, deleteNote } = useAllocationsNoteUpdate({
        employeeId: employee.userId,
        jobId: project.jobId,
        titleId: employee.titleId,
        officeId: employee.officeId,
        date,
        mode: allocationsGridModes.weekly,
        isJobScope: project.isJobScope,
        isNewNote: isNewNote,
    });

    const { note } = useAllocationsNoteRead({
        employeeId: employee.userId,
        jobId: project.jobId,
        titleId: employee.titleId,
        officeId: employee.officeId,
        date,
        mode: allocationsGridModes.weekly,
        isJobScope: project.isJobScope,
        isNewNote: isNewNote,
    });

    const { control, formState: { errors }, setValue, setFocus, handleSubmit} = useForm({
        mode: "onChange",
        defaultValues: { note: note.data?.note ?? ""},
    });

    const buttonGroupClasses = useStylesForButtonGroups();
    const buttonClasses = useStylesForButton();
    const classesText = useStylesForText();
    const classes = useStyles();
    const inputRef = useRef();
    const [days, setDays] = useState(note.data?.weekdays ?? []);
    const mutationInProgress = saveNote.isLoading || deleteNote.isLoading;
    const readOnlyInPast = moment(date) < moment().startOf("isoWeek");
    const formDisabled = note.isLoading || readOnlyInPast;

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(
                inputRef.current.value.length,
                inputRef.current.value.length
            );
        }
    }, []);

    useEffect(() => {
        if (note.data) {
            setValue("note", note.data.note);
            setDays(note.data.weekdays);
            inputRef.current.setSelectionRange(
                inputRef.current.value.length,
                inputRef.current.value.length
            );
        }
    }, [setValue, note.data, setFocus]);

    const formatDate = (date) => {
        return (
            moment(date).format("MMM D") +
            " - " +
            moment(date).add(6, "days").format("MMM D")
        );
    };
    
    const snackBarMessage = () => {
        return (
            <>
                A note on <b>{employee.name}</b> has been{" "}
                {mode === formModes.ADD ? "saved" : "edited"}.
            </>
        );
    };

    const handleDay = (event, newDays) => {
        setDays(newDays);
    };

    const handleSave = async function (formValues) {
        if (readOnlyInPast) {
            return false;
        }

        saveNote.mutateAsync({ note: formValues.note, days }).then(() => {
            onAddRemoveNote(true, formValues.note);
            sendNotification(undefined, snackBarMessage());
            requestHide();
        });
    };

    const handleDelete = async function () {
        if (readOnlyInPast) {
            return false;
        }

        deleteNote.mutateAsync().then(() => {
            onAddRemoveNote(false, "");
            sendNotification(
                undefined,
                <>
                    A note on <b>{employee.name}</b> has been deleted.
                </>
            );
            requestHide();
        });
    };

    return (
        <>
            <Modal.Body style={{overflow: "hidden"}}>
                <Container fluid>
                    <Content className={classes.root} onSubmit={handleSubmit(handleSave)}>
                        <FormRow>
                            <FormColumn>
                                <Text variant="xsmall" className={classesText.gray2}>{employee.fullName}</Text>
                                <Text variant="smedium">{project.jobName}</Text>
                            </FormColumn>
                            <FormColumn>
                                <Text variant="xsmall" className={classesText.gray2}>Week of</Text>
                                <Text variant="smedium">{formatDate(date)}</Text>
                            </FormColumn>
                        </FormRow>
                        <FormGroup>
                            <TextAreaInputWithValidation
                                required={true}
                                control={control}
                                errors={errors}
                                label="Add a note"
                                name="note"
                                disabled={formDisabled || readOnly}
                                ref={inputRef}
                            />
                        </FormGroup>
                        <FormField>
                            <Text variant="xsmall" style={{ color: global.config.colors.gray2, marginBottom: "0rem"}}>
                                Specify Days (Optional)
                            </Text>
                            <ToggleButtonGroup value={days} onChange={handleDay} disabled={readOnly} aria-label="text displays">
                                {List.days().map((day, index) => (
                                    <ToggleButton key={index} value={day.value} aria-label="left aligned" className={buttonGroupClasses.customStyles} disabled={formDisabled || readOnly}>
                                        <Text variant="xsmall">{day.label}</Text>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </FormField>
                        {!readOnly && (
                            <Action>
                                {mutationInProgress ? (
                                    <Loader height="50px" width="50px" style={{paddingLeft: "0px"}} />
                                ) : (
                                    <>
                                        {mode === formModes.ADD && (
                                            <>
                                                <Link variant="secondary" onClick={requestHide}>
                                                    Cancel
                                                </Link>
                                                <Button disabled={formDisabled} className={[ buttonClasses.button, buttonClasses.noMargin, buttonClasses.primaryButton]} type="submit">Save</Button>
                                            </>
                                        )}
                                        {mode === formModes.DETAIL && (
                                            <>
                                                <SecondaryButtonWrapper>
                                                    <Button className={[ buttonClasses.button, buttonClasses.secondary, buttonClasses.noMargin, buttonClasses.smallSize]}
                                                        onClick={handleDelete}
                                                        disabled={formDisabled}
                                                        type="button"
                                                    >
                                                        Delete
                                                    </Button>
                                                </SecondaryButtonWrapper>
                                                <Button className={[ buttonClasses.button, buttonClasses.noMargin, buttonClasses.primaryButton]}
                                                    disabled={formDisabled}
                                                    type="submit"
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Action>
                        )}
                    </Content>
                </Container>
            </Modal.Body>
        </>
    );
};

export default Note;
