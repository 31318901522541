const AGENCY_TYPES = [
    {
        value: 1,
        altValue: "ClientService",
        label: "Client Service",
    },
    {
        value: 2,
        altValue: "HoldingCompany",
        label: "Holding Company",
    },
];

function normalizeOption(value, options = []) {
    return options.find(
        (option) => option.value === value || option.altValue === value
    );
}

function getTypeOption(value) {
    return normalizeOption(value, AGENCY_TYPES);
}

export { AGENCY_TYPES, getTypeOption };
