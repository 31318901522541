import PropTypes from "prop-types";
import styled from "styled-components";

const Svg = styled.svg``;

const Magnifier = ({ size, viewBox, ...props }) => {
    return (
        <Svg
            className={props.className}
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="13.0769"
                cy="10.383"
                r="5.92308"
                stroke="black"
                strokeWidth="1.5"
            />
            <line
                x1="4.46967"
                y1="19.0068"
                x2="8.77736"
                y2="14.6991"
                stroke="black"
                strokeWidth="1.5"
            />
        </Svg>
    );
};

Magnifier.defaultProps = {
    size: "20",
    viewBox: "20",
};

Magnifier.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
};

export default Magnifier;
