import InputTextarea from "../controls/inputarea/InputTextarea";
import FormInputWrapper from "../ui/FormInputWrapper";
import { useFormInput } from "./utils";

const TextArea = ({
    name,
    validations,
    label,
    description,
    helpText,
    className,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className }}
            {...wrapperProps}
        >
            <InputTextarea {...rest} {...inputProps} />
        </FormInputWrapper>
    );
};

export default TextArea;
