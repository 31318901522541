export const addWeeksToDate = (date, numberOfWeeks) => {
    const days = (numberOfWeeks * 7);
    return addDaysToDate(date, days);
}

export const addDaysToDate = (date, numberOfDays) => {
    date.setDate(date.getDate() + numberOfDays);
    return date;
}

export const getWeeksBetweenDates = (from, to) => {
    var diff =(from.getTime() - to.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
}

export const getMondayDate = () => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff));
}
