import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useRetainersDashboardUserDetails = () => {
    const { getDataAsync } = useApi();

    const getUserDetailsByIdApiCall = useCallback(
        async (userId) => {
            if (userId) {
                const response = await getDataAsync("getUserDetailsById", {
                    urlParams: [{ name: "$userId", value: userId }],
                });
                return response.status != 204 ? response.json() : null;
            } else {
                return null;
            }
        },
        [getDataAsync]
    );

    const useUserById = (userId) =>
        useQuery(["userDetailsById", userId], () =>
            getUserDetailsByIdApiCall(userId)
        );

    return useMemo(() => {
        return {
            useUserById,
        };
    }, [getUserDetailsByIdApiCall]);
};

export default useRetainersDashboardUserDetails;

export const useRateCard = () => {
    const { getDataAsync } = useApi();
    const getRateCardByIdApiCall = useCallback(
        async (rateCardId) => {
            const response = await getDataAsync("getRateCardById", {
                urlParams: [{ name: "$rateCardId", value: rateCardId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const useRateCardById = (rateCardId) =>
        useQuery(["rateCardById", rateCardId], () =>
            getRateCardByIdApiCall(rateCardId)
        );

    return useMemo(() => {
        return {
            useRateCardById,
        };
    }, [getRateCardByIdApiCall]);
};
