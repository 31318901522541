import { useFormState, useWatch } from "react-hook-form";
import { format } from "date-fns";
import styled from "styled-components";

import VStack from "components/design-system/layout/VStack";
import TextInput from "components/design-system/forms/TextInput";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import DatePicker from "components/design-system/forms/DatePicker";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import {
    useAltJobCodesApi,
    useRateCardsApi,
    useRetainersApi,
    useTaxCodesApi,
    useVantageLOBApi,
} from "pages/admin/projects/useProjects";
import SegmentControlFormElement from "components/design-system/forms/SegmentControlFormElement";
import useOffices from "pages/admin/offices/useOffices";
import { COLOR_VALUES } from "components/design-system/config/colors";

import { vantageStatusOptions } from "pages/admin/projects/projectsFormOptions";
import {
    getCalendarDateFromNewDate,
    getDropdownOptions,
} from "pages/admin/offices/OfficesForm";
import { useClientsFilterApi } from "../projects/single/useProjectsLinked";
import useRetainers, { useBizUnits, useLeadProducers } from "./useRetainers";
import { useClientsFamilies, useCorporates } from "../clients/useClients";

const defaultValue = "";
const defaultLabel = "None";

export const attributesActiveOptions = [{ label: "Active", value: "Active" }];

export const apiStatusOptions = [
    {
        value: defaultValue,
        label: defaultLabel,
    },
    { value: null, label: "undefined" },
    { value: 1, label: "To be extracted" },
    { value: 2, label: "Extracted" },
    { value: 3, label: "Success" },
    { value: 4, label: "Error" },
];
export const attributesSOWSignedOptions = [
    { label: "SOW Signed", value: "SOWSigned" },
];
export const classificationSegmentOptions = [
    {
        value: "IsBillable",
        label: "Billable",
    },
    {
        value: "IsNonBillable",
        label: "Non-Billable",
    },
];
export const rateCardSegmentOptions = [
    {
        value: "segmentClientDefault",
        label: "Client Default",
    },
    {
        value: "segmentAll",
        label: "All",
    },
];
export const attributesEACOptions = [
    {
        label: "Send EAC/PTD to Vantage",
        value: "SendToVantage",
    },
    {
        label: "Overwrite EAC/PTD to Vantage",
        value: "OverwriteEACPTD",
    },
];

const StyledDiv = styled.div`
    border-top: 1px solid ${COLOR_VALUES.Gray_6};
    padding-top: 16px;
`;

const ModalCTA = ({ isEditing, isLoading, onClose }) => {
    const { isValid } = useFormState();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add Retainer",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformRetainerDataToFormData(retainerData = {}) {
    return {
        retainerName: retainerData.RetainerName ?? "",
        jobCode: retainerData.JobCode ?? "",
        // retAlternativeNumber: retainerData.ClientId,
        // altNumberCode: retainerData.QuotaAllotmentTypeID,

        clientFamily: retainerData.ClientFamilyId,
        corporateEntity: retainerData.ClientCorpEntityID,
        client: retainerData.ClientID,

        attributesActiveCancelled: attributesActiveOptions
            .map((attribute) => attribute.value)
            .filter((item) => retainerData[item] ?? true)
            .join(","),
        classificationSegment: retainerData.IsNonBillable
            ? classificationSegmentOptions[1].value
            : classificationSegmentOptions[0].value,
        startDate: retainerData.RetStartDate
            ? format(new Date(retainerData.RetStartDate), "yyyy-MM-dd")
            : null,
        endDate: retainerData.RetEndDate
            ? format(new Date(retainerData.RetEndDate), "yyyy-MM-dd")
            : null,

        rateCardId: retainerData.RateCardTableID,
        // rateCardSegment: retainerData?.clientDefault
        //     ? rateCardSegmentOptions[0].value
        //     : rateCardSegmentOptions[1].value,
        retainerAmount: retainerData.AmountUnAllocated,

        leadOffice: retainerData.LeadOfficeID,
        leadProducer: retainerData.LeadProducerID,

        vantageBusinessUnit: retainerData.BizUnitID,
        vantageStatus: retainerData.SAP_Job_status_ID,
        apiStatus: retainerData.SAP_API_status_ID,
        taxType: retainerData.TypeForTaxID,
        vantageLOB: retainerData.SAPLOBID,
        attributesEAC: attributesEACOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "SendToVantage"
                    ? retainerData[item] ?? true
                    : retainerData[item]
            )
            .join(","),

        oopEstimate: retainerData.OOPExpEstimate ?? "",
        agencyFeeEstimate: retainerData.AgencyFeeEstimate ?? "",
        attributesSOWSigned: attributesSOWSignedOptions
            .map((attribute) => attribute.value)
            .filter((item) => retainerData[item] ?? true)
            .join(","),

        altRetCodeSystemId: retainerData.AltRetainerCodeSystemID,
        altRetCode: retainerData.AltRetainerCode,
    };
}

function transformFormDataToRetainerData(
    formData = {},
    retainerData = {},
    clientName = "",
    rateCardTableName = "",
    clientFamilyName = "",
    clientCorpEntityName = ""
) {
    const newRetainerData = { ...retainerData };

    const attributeUpdate = (attributesOptions, attributeName) => {
        attributesOptions?.forEach((attribute) => {
            newRetainerData[attribute.value] = attributeName
                .split(",")
                .includes(attribute.value);
        });
    };

    newRetainerData.ClientName = clientName;
    newRetainerData.RateCardTableName = rateCardTableName;
    newRetainerData.ClientFamilyName = clientFamilyName;
    newRetainerData.ClientCorpEntityName = clientCorpEntityName;

    newRetainerData.Name = formData.retainerName;
    newRetainerData.Code = formData.jobCode;
    // newRetainerData.ClientId = formData.retAlternativeNumber;
    // newRetainerData.QuotaAllotmentTypeID = formData.altNumberCode;

    newRetainerData.ClientFamilyId = formData.clientFamily;
    newRetainerData.ClientCorpEntityID = formData.corporateEntity;
    newRetainerData.ClientId = formData.client;

    attributeUpdate(
        attributesActiveOptions,
        formData.attributesActiveCancelled
    );
    newRetainerData.IsBillable =
        formData.classificationSegment === classificationSegmentOptions[1].value
            ? true
            : false;
    newRetainerData.StartDate = formData.startDate;
    newRetainerData.EndDate = formData.endDate;

    newRetainerData.RateCardTableId = formData.rateCardId;
    // newRetainerData.clientDefault =
    //     formData.rateCardSegment === rateCardSegmentOptions[0].value
    //         ? true
    //         : false;
    newRetainerData.AmountUnAllocated = formData.retainerAmount;

    newRetainerData.LeadOfficeId = formData.leadOffice;
    newRetainerData.LeadProducerId = formData.leadProducer;

    newRetainerData.BizUnitId = formData.vantageBusinessUnit;
    newRetainerData.SAP_Job_Status_Id = formData.vantageStatus;
    newRetainerData.SAP_API_Status_Id = formData.apiStatus;
    newRetainerData.TypeForTaxId = formData.taxType;
    newRetainerData.SAPLOBID = formData.vantageLOB;
    attributeUpdate(attributesEACOptions, formData.attributesEAC);

    newRetainerData.OOPExpEstimate = formData.oopEstimate;
    newRetainerData.AgencyFeeEstimate = formData.agencyFeeEstimate;
    attributeUpdate(attributesSOWSignedOptions, formData.attributesSOWSigned);
    newRetainerData.AltRetainerCodeSystemId = formData.altRetCodeSystemId;
    newRetainerData.AltRetainerCode = formData.altRetCode;

    return newRetainerData;
}

const RenderDates = () => {
    const currentStartDate = useWatch({ name: "startDate" });
    const currentEndDate = useWatch({ name: "endDate" });
    return (
        <>
            <DatePicker
                defaultDateValue={
                    currentStartDate
                        ? getCalendarDateFromNewDate(new Date(currentStartDate))
                        : null
                }
                name="startDate"
                label="Start Date"
                validations={{
                    required: true,
                }}
            />
            <DatePicker
                defaultDateValue={
                    currentEndDate
                        ? getCalendarDateFromNewDate(new Date(currentEndDate))
                        : null
                }
                name="endDate"
                label="End Date"
                validations={{
                    required: true,
                }}
            />
        </>
    );
};

const RetainersForm = ({ retainerIdToEdit, onClose }) => {
    const { createRetainer, updateRetainer } = useRetainers();
    const { retainers } = useRetainersApi();
    const { corporates } = useCorporates();
    const { clientFamilies } = useClientsFamilies();
    const { clientsFilter } = useClientsFilterApi();
    const { altJobCodes } = useAltJobCodesApi();

    const { rateCards } = useRateCardsApi();
    const { offices } = useOffices();
    const { bizUnits } = useBizUnits();
    // const { leadProducers } = useLeadProducers();

    const { taxCodes } = useTaxCodesApi();
    const { vantageLOB } = useVantageLOBApi();

    const isEditing = typeof retainerIdToEdit !== "undefined";
    const modifier = isEditing ? updateRetainer : createRetainer;

    const currentRetainerData = retainers?.data?.Items?.find(
        (retainer) => retainer?.RetainerID === retainerIdToEdit
    );

    if (retainers?.isLoading) {
        return <>Loading...</>;
    }

    if (retainers?.isError) {
        return <>Error</>;
    }

    if (isEditing && !currentRetainerData) {
        return <div>Could not find retainer</div>;
    }

    const clientFamilyDropdownOptions = !clientFamilies.isLoading
        ? getDropdownOptions(clientFamilies?.data, "clientFamilyID")
        : [];

    const corporatesDropdownOptions = !corporates.isLoading
        ? getDropdownOptions(
              corporates?.data,
              "clientCorpEntityID",
              "clientCorpEntityName"
          )
        : [];

    const clientsDropdownOptions =
        !clientsFilter?.isLoading && clientsFilter?.data
            ? getDropdownOptions(clientsFilter?.data, "ClientId")
            : [];

    const altJobCodesOptions = !altJobCodes.isLoading
        ? getDropdownOptions(altJobCodes?.data)
        : [];

    const rateCardOptions = !rateCards.isLoading
        ? getDropdownOptions(
              rateCards?.data,
              "RateCardTableId",
              "RateCardTableName"
          )
        : [];

    const leadOfficeOptions = !offices.isLoading
        ? getDropdownOptions(offices?.data, "OfficeRegionId")
        : [];

    const taxTypesOptions = !taxCodes.isLoading
        ? getDropdownOptions(taxCodes?.data, "typeForTaxID", "JobTaxTypeName")
        : [];

    const vantageBizUnitsOption = !bizUnits.isLoading
        ? getDropdownOptions(bizUnits?.data, "BizUnitID", "BUName")
        : [];

    // const leadProducersOptions = !leadProducers.isLoading
    //     ? getDropdownOptions(leadProducers?.data, "id", "fullName")
    //     : [];

    const vantageLOBOptions = !vantageLOB.isLoading
        ? getDropdownOptions(vantageLOB?.data, "SAPLOBID", "SAPLOBName")
        : [];

    const onSubmit = (formData) => {
        const retainerData = transformFormDataToRetainerData(
            formData,
            currentRetainerData,
            clientsDropdownOptions?.find((f) => f.value === formData.client)
                ?.label,
            rateCardOptions?.find((f) => f.value === formData.rateCardId)
                ?.label,
            clientFamilyDropdownOptions?.find(
                (f) => f.value === formData.clientFamily
            )?.label,
            corporatesDropdownOptions?.find(
                (f) => f.value === formData.corporateEntity
            )?.label
        );

        modifier.mutateAsync(retainerData).then(() => {
            onClose();
            sendNotification(
                undefined,
                isEditing ? (
                    <>
                        <strong>{retainerData.Name}</strong> has been updated
                    </>
                ) : (
                    <>
                        <strong>{retainerData.Name}</strong> has been added.
                    </>
                )
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformRetainerDataToFormData(currentRetainerData)}
        >
            {/* <VStack spacing="Three"> */}
            <VStack spacing="Two">
                <VStack>
                    <TextInput
                        name="retainerName"
                        label="Name"
                        validations={{
                            required: true,
                        }}
                        placeholder="Retainer Name"
                    />
                    <TextInput
                        name="jobCode"
                        label="Number"
                        validations={{
                            required: true,
                        }}
                        placeholder="e.g. 16161616"
                    />
                    {/* <Dropdown
                        name="retAlternativeNumber"
                        validations={{
                            required: true,
                        }}
                        label="Alternative Number"
                        options={altJobCodesOptions}
                    />
                    <TextInput
                        name="altNumberCode"
                        validations={{
                            required: true,
                        }}
                        placeholder="e.g. 16161616"
                    /> */}
                </VStack>

                <VStack>
                    <Dropdown
                        name="clientFamily"
                        validations={{
                            required: true,
                        }}
                        label="Client Family"
                        options={clientFamilyDropdownOptions}
                    />
                    <Dropdown
                        name="corporateEntity"
                        validations={{
                            required: true,
                        }}
                        label="Corporate Entity"
                        options={corporatesDropdownOptions}
                    />
                    <Dropdown
                        name="client"
                        validations={{
                            required: true,
                        }}
                        label="Client"
                        options={clientsDropdownOptions}
                    />
                </VStack>

                <VStack>
                    <Options
                        name="attributesActiveCancelled"
                        options={attributesActiveOptions}
                    />
                    <SegmentControlFormElement
                        filterLabel={null}
                        name="classificationSegment"
                        options={classificationSegmentOptions}
                    />
                    <RenderDates />
                </VStack>

                <VStack>
                    <Dropdown
                        name="rateCardId"
                        validations={{
                            required: true,
                        }}
                        label="Rate Card"
                        options={rateCardOptions}
                    />
                    {/* <SegmentControlFormElement
                        filterLabel={null}
                        name="rateCardSegment"
                        options={rateCardSegmentOptions}
                    /> */}
                    <TextInput
                        name="retainerAmount"
                        label="Retainer $ Amount"
                        validations={{
                            required: true,
                        }}
                        placeholder="0.00"
                    />
                </VStack>

                <VStack>
                    <Dropdown
                        name="leadOffice"
                        label="Lead Office"
                        validations={{
                            required: true,
                        }}
                        options={leadOfficeOptions}
                    />
                    {/* <Dropdown
                        name="leadProducer"
                        validations={{
                            required: true,
                        }}
                        label="Lead Producer"
                        options={leadProducersOptions}
                    /> */}
                </VStack>

                <VStack>
                    <Dropdown
                        name="vantageBusinessUnit"
                        validations={{
                            required: true,
                        }}
                        label="Vantage Business Unit"
                        options={vantageBizUnitsOption}
                    />
                    <Dropdown
                        name="vantageStatus"
                        validations={{
                            required: true,
                        }}
                        label="Vantage Status"
                        options={vantageStatusOptions}
                    />
                    <Dropdown
                        name="apiStatus"
                        validations={{
                            required: true,
                        }}
                        label="API Status"
                        options={apiStatusOptions}
                    />
                    <Dropdown
                        name="taxType"
                        validations={{
                            required: true,
                        }}
                        label="Type for Tax"
                        options={taxTypesOptions}
                    />
                    <Dropdown
                        name="vantageLOB"
                        validations={{
                            required: true,
                        }}
                        label="Vantage LOB"
                        options={vantageLOBOptions}
                    />
                    <Options
                        name="attributesEAC"
                        options={attributesEACOptions}
                    />
                </VStack>

                <VStack>
                    <StyledDiv>
                        <Text variant="Headline_4" color="Gray_1">
                            Optional
                        </Text>
                        <Text variant="Body_2_1" color="Gray_2">
                            The fields below are not required
                        </Text>
                    </StyledDiv>

                    <Dropdown
                        name="altRetCodeSystemId"
                        validations={{
                            required: true,
                        }}
                        label="Alternative Number"
                        options={altJobCodesOptions}
                    />
                    <TextInput
                        name="altRetCode"
                        label="Alternative Code"
                        placeholder="-"
                    />
                    <TextInput
                        name="oopEstimate"
                        label="OOP Estimate"
                        placeholder="0.00"
                    />
                    <TextInput
                        name="agencyFeeEstimate"
                        label="Agency Fee Estimate"
                        placeholder="0.00"
                    />
                    <Options
                        name="attributesSOWSigned"
                        label="Attributes"
                        options={attributesSOWSignedOptions}
                    />
                </VStack>
            </VStack>

            <ModalCTA
                isEditing={isEditing}
                retainerIdToEdit={retainerIdToEdit}
                onClose={onClose}
                // isLoading={modifier.isLoading || inProgress}
            />
            {/* </VStack> */}
        </Form>
    );
};

export default RetainersForm;
