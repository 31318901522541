import styled, { css } from "styled-components";
import Modal from "react-modal";

import { COLOR_VALUES } from "components/design-system/config/colors";
import VStack from "components/design-system/layout/VStack";

Modal.setAppElement("#root");

export const StyledIcon = styled.button`
    position: absolute;
    border: none;
    background: transparent;
    top: 24px;
    right: 32px;
    padding: 0;
    cursor: pointer;
`;

export const StyledHeader = styled(VStack)`
    align-items: flex-start;
    padding: 24px 32px;
    min-height: 72px;
    max-height: 72px;
`;

export const StyledMasterDrawer = styled(Modal)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${(props) => props.width}% !important;
    box-shadow: 0 0 32px rgba(63, 67, 77, 0.1);
    background: ${COLOR_VALUES.White};

    &:focus {
        outline: none;
    }
`;

const ctaExcludePrimaryActionHeight = "104px";
const transparentScrollbar = `&::-webkit-scrollbar {
    width: 8px;
    border: 1px solid transparent;
}

&::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 999px;
}

&::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}
`;

export const StyledContent = styled.div`
    padding: 24px 16px 24px 64px;
    height: calc(100vh - 72px);
    margin-bottom: 152px;
    overflow: hidden;
    overflow-y: auto;

    ${transparentScrollbar}

    ${(props) =>
        !props.stickyAction &&
        css`
            margin-bottom: 24px;
        `}

    ${(props) =>
        props.stickyAction &&
        !props.isCTAPrimaryAction &&
        css`
            margin-bottom: ${ctaExcludePrimaryActionHeight};
        `}

        ${(props) =>
        props.stickyAction &&
        props.isCTAAllActions &&
        css`
            margin-bottom: 216px;
        `}
        
        &:hover {
        &::-webkit-scrollbar {
            width: 8px;
            border: 1px solid ${COLOR_VALUES.Gray_6};
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${COLOR_VALUES.Gray_5};
            border: 2px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 999px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: ${COLOR_VALUES.Gray_6};
        }
    }
`;

export const StyledAction = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 64px 32px;
    box-shadow: 0 -6px 24px rgba(63, 67, 77, 0.1);
    background-color: ${COLOR_VALUES.White};

    ${(props) =>
        !props.isCTAPrimaryAction &&
        css`
            height: ${ctaExcludePrimaryActionHeight};
        `}

    ${(props) =>
        props.isCTAAllActions &&
        css`
            height: 216px;
        `}
`;
