import PropTypes from "prop-types";

const Delete = ({ size, color, _viewBox }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15 10.4114H5.98389V11.5814H15V10.4114Z" fill={color} />
            <path
                d="M2.97853 9.92562C3.5195 9.92562 3.95528 10.3714 3.95528 10.9174C3.95528 11.4634 3.5195 11.9092 2.97853 11.9092C2.43757 11.9041 2.00179 11.4584 2.00179 10.9124C2.00179 10.3664 2.43757 9.92562 2.97853 9.92562ZM2.97853 8.92383C1.88658 8.92383 1 9.81542 1 10.9124C1 12.0093 1.88658 12.9059 2.97853 12.9059C4.07048 12.9059 4.95707 12.0143 4.95707 10.9124C4.95707 9.81542 4.07048 8.92383 2.97853 8.92383Z"
                fill={color}
            />
            <path d="M15 4.49268H5.98389V5.66268H15V4.49268Z" fill={color} />
            <path
                d="M2.97853 4.00179C3.51449 4.00179 3.95528 4.44758 3.95528 4.99356C3.95528 5.53953 3.5195 5.98533 2.97853 5.98533C2.43757 5.98533 2.00179 5.53953 2.00179 4.99356C2.00179 4.44758 2.43757 4.00179 2.97853 4.00179ZM2.97853 3C1.88658 3 1 3.89159 1 4.99356C1 6.09052 1.88658 6.98712 2.97853 6.98712C4.07048 6.98712 4.95707 6.09553 4.95707 4.99356C4.95707 3.89159 4.07048 3 2.97853 3Z"
                fill={color}
            />
        </svg>
    );
};

Delete.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

Delete.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Delete;
