import { useState, useEffect } from "react";

import {
    getSum,
    updateArrayWithCurrency,
    updateArrayWithCurrencyForSum,
    updateArrayWithToFixed,
} from "common-methods/commonMethodsFixedRowsProjectsTable";

export const useFixedRowsMoney = ({
    initialData = [],
    summary = [],
    currency = [],
    showCurrency = true,
    totalsStrColmAtStart = 1,
    totalColumns = [],
}) => {
    const [totalMoney, setTotalMoney] = useState([...initialData]);
    const [totalOneUpdate, setTotalOneUpdate] = useState(true);

    useEffect(() => {
        if (!summary.isFetching && !currency.isFetching && totalOneUpdate) {
            let moneyArr = [...initialData];            
            const symbol = showCurrency ? currency?.data?.Symbol ?? "" : "";
            // if it's an array else it's an object
            if (summary.data?.length > 0) {
                getSum(
                    totalColumns,
                    totalsStrColmAtStart,
                    summary?.data,
                    moneyArr
                );
                updateArrayWithCurrencyForSum(
                    symbol,
                    totalColumns,
                    moneyArr,
                    totalsStrColmAtStart
                );
            } else {
                updateArrayWithCurrency(
                    symbol,
                    summary?.data,
                    totalColumns,
                    moneyArr,
                    totalsStrColmAtStart
                );
            }
            setTotalOneUpdate(false);
            setTotalMoney(moneyArr);
        }
    }, [summary?.data, currency]);

    return [totalMoney, setTotalOneUpdate];
};

export const useFixedRowsHours = ({
    initialData = [],
    summary = [],
    totalsStrColmAtStart = 1,
    totalColumns = [],
}) => {
    const [totalHours, setTotalHours] = useState([...initialData]);
    const [totalTwoUpdate, setTotalTwoUpdate] = useState(true);

    useEffect(() => {
        if (!summary.isFetching && totalTwoUpdate) {
            let hoursArr = [...initialData];
            getSum(totalColumns, totalsStrColmAtStart, summary?.data, hoursArr);
            updateArrayWithToFixed(
                totalColumns,
                hoursArr,
                totalsStrColmAtStart
            );
            setTotalTwoUpdate(false);
            setTotalHours(hoursArr);
        }
    }, [summary]);

    return [totalHours, setTotalTwoUpdate];
};
