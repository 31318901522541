import { useState, useMemo } from "react";
import { matchSorter } from "match-sorter";
import "styled-components/macro";

const MAX_NUM_OPTIONS_WITHOUT_FILTER = 6;

const useDropdownListOptions = (
    allOptions = [],
    {
        usesExternalFilterUI = false,
        unfilteredResultListShowsEmpty = false,
    } = {}
) => {
    const limitResultLength = usesExternalFilterUI;

    const internalFilterUIShown =
        !usesExternalFilterUI &&
        allOptions.length > MAX_NUM_OPTIONS_WITHOUT_FILTER;
    const [filterValue, setFilterValue] = useState("");

    const filteredOptions = useMemo(() => {
        if (filterValue.length === 0) {
            if (unfilteredResultListShowsEmpty) {
                return [];
            } else {
                return limitResultLength
                    ? allOptions.slice(0, MAX_NUM_OPTIONS_WITHOUT_FILTER)
                    : allOptions;
            }
        } else {
            const matchResults = matchSorter(allOptions, filterValue, {
                keys: ["label"],
            });

            return limitResultLength
                ? matchResults.slice(0, MAX_NUM_OPTIONS_WITHOUT_FILTER)
                : matchResults;
        }
    }, [
        allOptions,
        filterValue,
        limitResultLength,
        unfilteredResultListShowsEmpty,
    ]);

    return {
        filteredOptions,
        filterValue,
        setFilterValue,
        internalFilterUIShown,
    };
};

export default useDropdownListOptions;
