import { Outlet, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import GlobalConfig from "configs/Global";
import PageWrapper from "pages/PageWrapper";
import RetainersDashboard from "pages/projects/retainers-dashboard/RetainersDashboard";
import COLORS from "components/design-system/config/colors";

import { useReloadData } from "pages/projects/useReloadData";
import { useScroll } from "pages/projects/useScroll";

import useRetainers from "pages/admin/retainers/useRetainers";
import { getReporterUrl } from "pages/projects/useExternalUrl";

import RetainersDashboardLayoutContext from "context/Projects/RetainersDashboardLayoutContext";
import { useRetainerSubJobCount } from "./useRetainersDashboardData";
import useOffices from "pages/admin/offices/useOffices";

const ampersand = encodeURIComponent('&');

const REGION_NAME = "Latin America";

const retainerTabs = (retainerId, retainerData, retainerSubjobCount, offices) => {    
    const isRegionLATAM = offices?.data?.filter(f => f.RegionName === REGION_NAME)?.some(s => s.OfficeRegionId === retainerData?.data?.LeadOfficeID);
    const billingPath = isRegionLATAM ? "job/contractBillings.aspx" : "job/contractbilling/contractBillings.aspx";

    return [
        {
            title: "Retainer",
            tabs: [
                {
                    id: 0,
                    label: "Dashboard",
                    icon: "caret-right",
                    code: "AC_DASHBOARD_VIEW",
                },
                {
                    id: 1,
                    label: `Sub-Job List (${retainerSubjobCount?.data})`,
                    icon: "link",
                    to: getReporterUrl(
                        `client/Retainers/projectreport.aspx?retainerId=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_SUBJOBS_DETAILS_VIEW",
                },
                {
                    id: 2,
                    label: "Sub-Job Summary",
                    icon: "caret-right",
                    code: "AC_SUBJOBS_VIEW",
                },
                {
                    id: 3,
                    label: "Planner",
                    icon: "link",
                    to: getReporterUrl(
                        `Planner/RetainerPlanner.aspx?retainerID=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_PLANNER_VIEW",
                },
                {
                    id: 4,
                    label: "Documents",
                    icon: "link",
                    to: getReporterUrl(
                        `client/retainers/retainerDocs.aspx?retainerId=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_DOCUMENTS_VIEW",
                },
                {
                    id: 5,
                    label: "Request to Close",
                    icon: "link",
                    code: "AC_REQUEST_CLOSE",
                    href: `mailto:RGAReporter@rga.com`,
                    isInternalUrlNewTab: true,
                },
                {
                    id: 6,
                    label: "Edit",
                    icon: "link",
                    to: getReporterUrl(
                        `admin/retainers/editRetainer.aspx?retainerID=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_EDIT",
                },
            ],
        },
        {
            title: "Finance",
            tabs: [
                {
                    id: 7,
                    label: "Budgeted vs. Used",
                    icon: "link",
                    to: getReporterUrl(
                        `client/viewRetSOWStat.aspx?FROM=RD${ampersand}retainerId=${retainerId}${ampersand}ClientID=${retainerData?.data?.ClientID}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_BUDGET_USED_VIEW",
                },
                {
                    id: 8,
                    label: "Cost Details ",
                    icon: "link",
                    to: getReporterUrl(
                        `client/retainers/costDetailsOverview.aspx?retainerId=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_COST_DETAILS_VIEW",
                },
                {
                    id: 9,
                    label: isRegionLATAM ? "Retainer Billing" : "Billing and OOP",
                    icon: "link",
                    to: getReporterUrl(
                        `${billingPath}?retainerId=${retainerId}${ampersand}ClientID=${retainerData?.data?.ClientID}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_BILLING_VIEW",
                },
                {
                    id: 10,
                    label: "Discounts / Internal Investments",
                    icon: "link",
                    to: getReporterUrl(
                        `client/retainers/retRequestContAdj.aspx?retainerID=${retainerId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_DISCOUNTS_VIEW",
                },
            ],
        },
    ];
};

const RetainersDashboardLayout = () => {
    const { retainerId } = useParams();
    const { useRetainerById } = useRetainers();
    const retainerData = useRetainerById(retainerId);
    const { useSubjobCount } = useRetainerSubJobCount();
    const retainerSubjobCount = useSubjobCount(retainerId);
    const [handleRefreshDataClick, reloadData] = useReloadData();
    const [fixedHeaderHeight] = useScroll();
    const { offices } = useOffices();

    const { getNotesTabIndicator } = useContext(
        RetainersDashboardLayoutContext
    );
    const showNotesTabIndicator = getNotesTabIndicator(retainerId);

    useEffect(() => {
        localStorage.setItem("storedRetainerId", retainerId);
        return () => {
            localStorage.removeItem("storedRetainerId");
        };
    }, []);

    return (
        <>
            <RetainersDashboard
                handleRefreshDataClick={handleRefreshDataClick}
                fixedHeaderHeight={fixedHeaderHeight}
            />
            <PageWrapper
                displayUnauthorizedMsg={false}
                paddingTop={"6px"}
                title={retainerData?.data?.RetainerName ?? "Error"}
                description={retainerData?.data?.JobCode}
                tabs={[
                    { label: "Overview", to: "overview" },
                    retainerData?.data?.LeadProducerID
                        ? {
                            label: "Details",
                            to: "details",
                        }
                        : {
                            label: "Details",
                            to: "details",
                            trailingIcon: "warning",
                            iconColor: COLORS.Yellow,
                        },
                    {
                        label: "Notes",
                        to: "notes",
                        trailingIcon: showNotesTabIndicator
                            ? "indicator"
                            : null,
                        iconColor: showNotesTabIndicator ? COLORS.Yellow : null,
                    },
                ]}
                tabsMarginBottom={"40px"}
                loading={retainerData.isLoading || reloadData}
                customAuthPath={
                    GlobalConfig.routes.projectsRetainersDashboardLanding
                }
                positionOfWrapper={`${fixedHeaderHeight}px`}
                dashboardSideNav={retainerTabs(retainerId, retainerData, retainerSubjobCount, offices)}
            >
                <Outlet />
            </PageWrapper>
        </>
    );
};

export default RetainersDashboardLayout;
