import React, {
    useContext,
    useLayoutEffect,
    useEffect,
    useState,
    useRef,
} from "react";
import moment from "moment";
import styled from "styled-components";

import Close from "components/Icon/Close";
import Loader from "components/UI/Loader";
import { useTimeOff } from "utilities/API/TimeOff";
import {
    GridUIContextValue,
    GridUIContextActions,
} from "../../../context/Allocations/GridUIContext";
import TimeOffOverlay from "components/Allocations/Grid/Employee/TimeOffOverlay";

const DATA_STATES = {
    READY: "ready",
    LOADING: "loading",
    ERROR: "error",
    BLANK: "blank",
};

const CloseWrapper = styled.button`
    background: transparent;
    cursor: pointer;
    padding: 0 0.25rem;
    width: 1rem;
    float: right;
    border: 0;

    &:hover {
        background: ${global.config.colors.gray2};
    }
`;

const getTooltipPositionStyles = (pillElRect, tooltipElRect, safeArea) => {
    const styles = {
        position: "absolute",
        zIndex: 99,
    };

    // available space above, below, left, and right measured as multiples of tooltip height

    const pillTopMeasuringPoint = pillElRect.top - 10;
    const spaceAbove =
        Math.abs(safeArea.top - pillTopMeasuringPoint) / tooltipElRect.height;

    const pillBottomMeasuringPoint = pillElRect.bottom + 10;
    const spaceBelow =
        Math.abs(safeArea.bottom - pillBottomMeasuringPoint) /
        tooltipElRect.height;

    // if tooltip fits in space above, put it above; otherwise, put it above or below depending on where there is more space
    styles.top =
        window.scrollY +
        (spaceAbove >= 1 || spaceAbove >= spaceBelow
            ? pillTopMeasuringPoint - tooltipElRect.height
            : pillBottomMeasuringPoint);

    const pillHorzMeasuringPoint = pillElRect.left + pillElRect.width / 2;
    const spaceLeft =
        Math.abs(safeArea.left - pillHorzMeasuringPoint) / tooltipElRect.width;
    const spaceRight =
        Math.abs(safeArea.right - pillHorzMeasuringPoint) / tooltipElRect.width;

    // if tooltip fits in space on the right, put it on the right; otherwise, put it on the right or left depending on where there is more space
    styles.left =
        window.scrollX +
        (spaceRight >= 1 || spaceRight >= spaceLeft
            ? pillHorzMeasuringPoint
            : pillHorzMeasuringPoint - tooltipElRect.width);

    return styles;
};

const ContentWrapper = styled.div`
    margin-right: 2.5rem;
`;

const PillTooltipContent = ({ pillElRef, children }) => {
    const { toggleTooltip, getSafeArea } = useContext(GridUIContextActions);
    const [positionStyles, setPositionStyles] = useState({});
    const elRef = useRef();

    useEffect(() => {
        const clickHandler = (event) => {
            if (!elRef.current.contains(event.target)) {
                toggleTooltip(false);
            }
        };

        const keyHandler = (event) => {
            if (event.key === "Escape") {
                toggleTooltip(false);
            }
        };

        document.addEventListener("click", clickHandler);
        document.addEventListener("keydown", keyHandler);

        return () => {
            document.removeEventListener("click", clickHandler);
            document.removeEventListener("keydown", keyHandler);
        };
    }, [toggleTooltip]);

    useLayoutEffect(() => {
        if (pillElRef.current && elRef.current) {
            setPositionStyles(
                getTooltipPositionStyles(
                    pillElRef.current.getBoundingClientRect(),
                    elRef.current.getBoundingClientRect(),
                    getSafeArea()
                )
            );
        }
    }, [children, getSafeArea, pillElRef]);

    return (
        <div className="tooltip-overlay" ref={elRef} style={positionStyles}>
            {children}
        </div>
    );
};

const PillTooltip = () => {
    const { getTimeOff } = useTimeOff();

    const { tooltipRequest } = useContext(GridUIContextValue);
    const { toggleTooltip } = useContext(GridUIContextActions);

    const [dataState, setDataState] = useState(DATA_STATES.BLANK);
    const [tooltipData, setTooltipData] = useState(null);

    useEffect(() => {
        if (!tooltipRequest) {
            setTooltipData(null);
            setDataState(DATA_STATES.BLANK);
            return;
        }

        let validRequest = true;

        const { employeeData, date, allocatedHours, availableHours } =
            tooltipRequest;

        setDataState(DATA_STATES.LOADING);

        const daysOfTheWeek = [];
        for (let index = 0; index < 5; index++) {
            daysOfTheWeek.push(
                moment(date).add(index, "days").format("YYYY-MM-DD")
            );
        }

        const dateFrom = moment(date).format("YYYY-MM-DD");
        const dateTo = moment(date).add(6, "days").format("YYYY-MM-DD");

        getTimeOff({
            userId: employeeData.userId,
            dateFrom: dateFrom,
            dateTo: dateTo,
        })
            .then((timeOffData) => {
                if (validRequest) {
                    setTooltipData({
                        employeeData,
                        timeOffData,
                        daysOfTheWeek,
                        allocatedHours,
                        availableHours,
                    });
                    setDataState(DATA_STATES.READY);
                }
            })
            .catch(() => {
                if (validRequest) {
                    setTooltipData(null);
                    setDataState(DATA_STATES.ERROR);
                }
            });

        return () => {
            validRequest = false;
        };
    }, [getTimeOff, tooltipRequest]);

    return tooltipRequest ? (
        <PillTooltipContent pillElRef={tooltipRequest.pillElRef}>
            {dataState === DATA_STATES.READY && (
                <>
                    <CloseWrapper
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleTooltip(false);
                        }}
                    >
                        <Close color={global.config.colors.white} size="8" />
                    </CloseWrapper>

                    <ContentWrapper>
                        <TimeOffOverlay
                            week={tooltipData.daysOfTheWeek}
                            timeOffData={tooltipData.timeOffData}
                            employeeData={tooltipData.employeeData}
                            allocatedHours={tooltipData.allocatedHours}
                            availableHours={tooltipData.availableHours}
                        />
                    </ContentWrapper>
                </>
            )}

            {dataState === DATA_STATES.LOADING && (
                <Loader height="18px" width="18px" />
            )}

            {dataState === DATA_STATES.Error && "Error"}
        </PillTooltipContent>
    ) : (
        <></>
    );
};

export default PillTooltip;
