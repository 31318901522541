import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SPACING, { SPACING_VALUES } from "../config/spacing";

const StyledHStack = styled.div((props) => {
    return {
        display: "flex",
        flexDirection: "row",
        flexWrap: props.$wrap ? "wrap" : "no-wrap",
        alignItems: props.$align,
        justifyContent: props.$justify,
        gap: props.$spacing ? SPACING_VALUES[props.$spacing] : "0",
    };
});

const HStack = React.forwardRef(
    ({ spacing, wrap, align, justify, ...props }, ref) => {
        return (
            <StyledHStack
                $spacing={spacing}
                $wrap={wrap}
                $align={align}
                $justify={justify}
                {...props}
                ref={ref}
            />
        );
    }
);

HStack.defaultProps = {
    spacing: SPACING.One,
    wrap: false,
};

HStack.propTypes = {
    spacing: PropTypes.oneOf(Object.keys(SPACING)),
    wrap: PropTypes.bool,
};

export default HStack;
