import { sendNotification } from "utilities/Notification";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import useHolidays from "pages/admin/holidays/useHolidays";

const HolidayDeleteForm = ({ holidayIdToDelete, onClose }) => {
    const { holidays, deleteHoliday } = useHolidays();

    const currentHolidayData = holidays.data?.find(
        (holiday) => holiday.Date === holidayIdToDelete
    );

    if (!currentHolidayData) {
        return <div>Could not find holiday</div>;
    }

    const modifier = deleteHoliday;

    const onSubmit = () => {
        const clientData = {
            OfficeId: currentHolidayData.OfficeId,
            Date: holidayIdToDelete,
        };
        modifier.mutateAsync(clientData).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>
                    <strong> {currentHolidayData.Name} </strong> has been
                    deleted
                </>
            );
        });
    };
    return (
        <Form onSubmit={onSubmit}>
            <VStack spacing="Three">
                <Text variant="Body_1_1" color="Gray_2">
                    <strong> {currentHolidayData.Name} </strong> will be
                    deleted.
                </Text>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: "Yes, Delete",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        to: "?action=edit&id=" + holidayIdToDelete,
                    }}
                />
            </VStack>
        </Form>
    );
};

export default HolidayDeleteForm;
