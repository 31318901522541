import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";

export const useProjects = () => {
    const { getDataAsync } = useApi();

    const getProjectsFilter = async (
        clientfamiliesIds,
        clientIds,
        retainerIds
    ) => {
        const params = {
            clientfamilies: clientfamiliesIds,
            clientIds: clientIds,
            retainerIds: retainerIds,
        };
        return getDataAsync("projectsFilters", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getProjectsFiltersByClientFamily = async (clientFamilyId) => {
        const urlParams = [{ name: "$clientFamilyId", value: clientFamilyId }];
        return getDataAsync("projectsFiltersByClientFamily", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json();
        });
    };

    const getProjectsFiltersByClient = async (clientId) => {
        const urlParams = [{ name: "$clientId", value: clientId }];
        return getDataAsync("projectsFiltersByClient", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json();
        });
    };

    const getProjectsFiltersByRetainer = async (retainerId) => {
        const urlParams = [{ name: "$retainerId", value: retainerId }];
        return getDataAsync("projectsFiltersByRetainer", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json();
        });
    };

    const getProjectDetail = async (urlParams) => {
        return getDataAsync("projectDetail", { urlParams: urlParams }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    return useMemo(() => {
        return {
            getProjectsFilter,
            getProjectsFiltersByClientFamily,
            getProjectsFiltersByClient,
            getProjectsFiltersByRetainer,
            getProjectDetail,
        };
    }, []);
};
