import styled, { css } from "styled-components";

import BaseButton from "../controls/button/BaseButton";
import { TEXT_VARIANT_STYLES } from "../ui/Text";
import { COLOR_VALUES } from "../config/colors";

const StyledLink = styled(BaseButton)`
    ${(props) =>
        props.variant
            ? css`
                  ${props.variant};
              `
            : css`
                  ${TEXT_VARIANT_STYLES.Body_2_2};
              `}

    ${(props) =>
        props.color
            ? css`
                  color: ${COLOR_VALUES[props.color]};
              `
            : css`
                  color: ${COLOR_VALUES.Gray_4};
              `}
    

    &:hover {
        color: ${COLOR_VALUES.Gray_2};
    }

    &:active {
        color: ${COLOR_VALUES.Black};
    }

    &:disabled {
        color: ${COLOR_VALUES.Gray_4};
    }
`;

const Link = (props) => {
    return <StyledLink {...props} />;
};

export default Link;
