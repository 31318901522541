import SegmentedControl from "components/design-system/controls/segmentedControl/SegmentedControl";
import FormInputWrapper from "../ui/FormInputWrapper";
import { useFormInput } from "./utils";

const SegmentControlFormElement = ({
    name,
    validations,
    label,
    description,
    helpText,
    className,
    isSmallSize,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className, isSmallSize }}
            {...wrapperProps}
        >
            <SegmentedControl
                {...rest}
                {...inputProps}
                ref={undefined}
                segmentedButtonType="button"
            />
        </FormInputWrapper>
    );
};

export default SegmentControlFormElement;
