import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import env from "react-dotenv";
import styled, { css } from "styled-components";
import Text from "components/UI/Text";
import Card from "components/UI/Card";
import Button from "components/UI/Button";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "styles/Sidebar.css";
import Photo from "components/UI/Photo";
import { useUser } from "utilities/API/Users";
import { useSkillSearchProfileUrlByEmployeeEmailGetter } from "utilities/API/SkillSearch";
import More from "./More";
import { errorNotification } from "utilities/Notification";
import CustomErrors from "resources/CustomErrors";
import Loader from "components/UI/Loader";
import { useStylesForText } from "styles/MakeStyles";
import Tooltip from "components/UI/Tooltip";
import moment from "moment";
import { useAllocationsVisibilityOfUser } from "hooks/Allocations/useAllocationsPermission";
import AccessGroupList from "../AccessGroup/AccessGroup";

const Container = styled.div`
    margin-top: 2rem;
`;

const Body = styled.div`
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${global.config.sizes.sidebar.gutter};
    height: ${(props) =>
        props.canViewAllocations
            ? "calc(100vh - 48px - 2rem - 117px - 2.5vh)"
            : "calc(100vh - 48px - 2rem - 66px - 2.5vh)"};
    ::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
    :hover {
        ::-webkit-scrollbar-thumb {
            background-color: #dde1e5;
        }
    }
`;

const EmployeePicture = styled(Photo)`
    width: 12rem;
    height: 12rem;
    border-radius: 10rem;
    margin: 0 auto;
    object-fit: cover;
    display: flex;
    margin-bottom: 1.5rem;
`;

const Title = styled.div`
    margin: 0;
    display: inline-flex;
    width: -webkit-fill-available;
`;

const Tag = styled.div`
    width: 4.75rem;
    margin: 0 0 0.5em;
    display: flex;
    background-color: ${global.config.colors.gray6};
    border-radius: 0.1rem;
    text-align: left;
    padding: 0.2rem;
    p {
        margin: 0 auto;
    }
`;

const ButtonLink = styled.a`
    p {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    button {
        margin-bottom: 0.5rem;
    }
    margin-bottom: 0.5rem;
`;

const TextLink = styled.a`
    color: ${global.config.colors.black};
    text-decoration: underline;
    display: block;
    &:hover {
        color: ${global.config.colors.black};
    }
`;

const Footer = styled.div`
    padding: ${global.config.sizes.sidebar.gutter};
    padding-top: 40px;
    text-align: center;
    > * {
        margin-bottom: 0.5rem;
    }
    position: absolute;
    width: 100%;
    bottom: 2.5vh;
`;

const MoreMenuContainer = styled.div`
    padding: 0.5rem 0 0 0.5rem;
`;

const labelStyle = { width: "100%", textAlign: "initial" };

const titleStyle = { margin: "0.5rem 0 0 0" };

const Section = styled.div`
    margin: 1em 0 0 0;
    > p {
        width: 100%;
        text-align: initial;
    }
`;

const GroupSection = styled.div`
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    border-bottom: 1px solid;
    border-bottom-color: ${global.config.colors.gray6};

    ${(props) =>
        props.borderBottomNone &&
        css`
            border-bottom: none;
        `}
`;

const SkillSearchContainer = styled.div``;

const EmployeeDrawer = (props) => {
    const location = useLocation();
    const classesText = useStylesForText();
    const { userId } = props;
    const canViewAllocations = useAllocationsVisibilityOfUser(userId);
    const { getUserDetail } = useUser();
    const getSkillSearchUrl = useSkillSearchProfileUrlByEmployeeEmailGetter();
    const [profileLink, setProfileLink] = useState(null);
    const [user, setUser] = useState({
        id: null,
        name: null,
        title: null,
        photo: null,
        type: null,
        department: null,
        departmentId: null,
        location: null,
        talentManager: null,
        departureDate: null,
        community: null,
    });

    useEffect(() => {
        if (userId) {
            getUserDetail([{ name: "$userId", value: userId }])
                .then((payload) => {
                    const fetchedUser = payload;
                    setUser({
                        id: userId,
                        name: fetchedUser.FullName,
                        title: fetchedUser.Title,
                        photo:
                            fetchedUser.LargePhotoUrl &&
                            `${env.BESTWORK_REACT_ASSET_DOMAIN}/${fetchedUser.LargePhotoUrl}`,
                        type: fetchedUser.EmploymentType,
                        department: fetchedUser.Department,
                        departmentId: fetchedUser.DepartmentId,
                        seatOffice: fetchedUser.Office,
                        payOffice: fetchedUser.PayOffice,
                        talentManager: fetchedUser.TalentManager,
                        departureDate: fetchedUser.DepartureDate,
                        community: fetchedUser.Community,
                    });

                    getSkillSearchUrl(fetchedUser.Email).then((url) => {
                        setProfileLink(url);
                    });
                })
                .catch(() => {
                    /**/
                });
        } else {
            errorNotification(undefined, CustomErrors.OnGetUser);
        }
    }, [userId]);

    return (
        <>
            <Container>
                <Body canViewAllocations={canViewAllocations}>
                    {userId && user.name ? (
                        <>
                            <EmployeePicture url={user.photo} />
                            <Title>
                                <Text style={labelStyle} variant="xxxlarge">
                                    {user.name}
                                </Text>
                                <MoreMenuContainer>
                                    <More
                                        visible
                                        disableProfile
                                        employee={user}
                                        skillSearchLink={profileLink}
                                        direction="left"
                                        portal={true}
                                    />
                                </MoreMenuContainer>
                            </Title>
                            {user.type === "Freelancer" && (
                                <Tag>
                                    <Text variant="xxsmallMedium">
                                        FREELANCE
                                    </Text>
                                </Tag>
                            )}
                            <GroupSection>
                                <Section>
                                    <Text
                                        variant="xsmallLight"
                                        className={classesText.gray2}
                                    >
                                        Title
                                    </Text>
                                    <Text style={titleStyle} variant="small">
                                        {user.title}
                                    </Text>
                                </Section>

                                <Section>
                                    <Text
                                        variant="xsmallLight"
                                        className={classesText.gray2}
                                    >
                                        Department
                                    </Text>
                                    <Text style={titleStyle} variant="small">
                                        {user.department}
                                    </Text>
                                </Section>
                            </GroupSection>
                            <GroupSection>
                                <Section>
                                    <Text
                                        variant="xsmallLight"
                                        className={classesText.gray2}
                                    >
                                        Pay Office
                                    </Text>
                                    <Text style={titleStyle} variant="small">
                                        {user.payOffice}
                                    </Text>
                                </Section>
                                <Section>
                                    <Text
                                        variant="xsmallLight"
                                        className={classesText.gray2}
                                    >
                                        Community / Group
                                    </Text>
                                    <Text style={titleStyle} variant="small">
                                        {user.community ? (
                                            user.community
                                        ) : (
                                            <span>&mdash;</span>
                                        )}
                                    </Text>
                                </Section>
                            </GroupSection>
                            <GroupSection>
                                <Section>
                                    <Text
                                        variant="xsmallLight"
                                        className={classesText.gray2}
                                    >
                                        Seat Office
                                    </Text>
                                    <Text style={titleStyle} variant="small">
                                        {user.seatOffice}
                                    </Text>
                                </Section>
                            </GroupSection>

                            {user.departureDate && (
                                <GroupSection>
                                    <Section>
                                        <Text
                                            variant="xsmallLight"
                                            className={classesText.gray2}
                                        >
                                            Departure Date
                                        </Text>
                                        <Text
                                            style={titleStyle}
                                            variant="small"
                                        >
                                            {moment(user.departureDate).format(
                                                "MMM D, yyyy"
                                            )}
                                        </Text>
                                    </Section>
                                </GroupSection>
                            )}
                            {user.talentManager ? (
                                <GroupSection
                                    borderBottomNone={
                                        location.pathname !==
                                        "/admin/accessgroups"
                                    }
                                >
                                    <Card
                                        label="Talent Manager"
                                        photo={
                                            env.BESTWORK_REACT_ASSET_DOMAIN +
                                            "/" +
                                            user.talentManager.SmallPhotoUrl
                                        }
                                        name={user.talentManager.FullName}
                                        title={user.talentManager.Title}
                                        department={
                                            user.talentManager.Department
                                        }
                                        email={user.talentManager.Email}
                                    />
                                </GroupSection>
                            ) : (
                                <></>
                            )}
                            {
                                <AccessGroupList
                                    userId={userId}
                                    departmentId={user.departmentId}
                                    onClose={props.onClose}
                                />
                            }
                        </>
                    ) : (
                        <Loader height="5rem" width="5rem" />
                    )}
                </Body>
                <Footer>
                    {canViewAllocations && (
                        <ButtonLink
                            href={`${global.config.routes.allocation}?userId=${userId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button style={{ width: "100%", whiteSpace: "nowrap" }}>
                                View Employee Allocations
                            </Button>
                        </ButtonLink>
                    )}
                    <Tooltip
                        placement="top"
                        title="Skill Search not available for Freelance"
                        show={user.type === "Freelancer"}
                    >
                        <SkillSearchContainer>
                            <TextLink
                                href={profileLink}
                                target="_blank"
                                rel="noreferrer"
                                disabled={!profileLink}
                            >
                                <Text
                                    className={!profileLink && "disabled"}
                                    variant="xsmall"
                                >
                                    Skill Search Profile
                                </Text>
                            </TextLink>
                        </SkillSearchContainer>
                    </Tooltip>
                </Footer>
            </Container>
        </>
    );
};

export default EmployeeDrawer;
