import React, { useContext, useRef } from "react";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CalendarDate } from "@internationalized/date";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import { COLOR_VALUES } from "components/design-system/config/colors";
import SessionContext from "context/app/SessionContext";
import useIncompleteTimesheetApi from "pages/timesheet/incomplete-timesheet/useIncompleteTimesheet";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const dateConverter = (date) => {
    const incompleteTimesheetDates = new Date(date);
    let startDate = format(
        startOfWeek(incompleteTimesheetDates, { weekStartsOn: 1 }),
        "MMM dd"
    );
    let endDate = format(
        endOfWeek(incompleteTimesheetDates, { weekStartsOn: 1 }),
        "MMM dd"
    );

    return `${startDate} - ${endDate}`;
};

const IncompleteTimesheet = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const navigate = useNavigate();
    const { useIncompleteTimesheet } = useIncompleteTimesheetApi();
    const incompleteTimesheetData = useIncompleteTimesheet(session.legacyId);
    const { setPendingTimesheetCalendarDate } =
        useContext(WeekNavigatorContext);
    const yearWiesData = useRef();
    yearWiesData.current = [];

    const now = new Date();
    insightsTrace("VIEW", "IncompleteTimesheetPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    if (!session.timesheetRequired){
        window.location.replace("/timesheets/");
    }

    const groupByYear = incompleteTimesheetData?.data.reduce((group, data) => {
        let date = new Date(data.date).getFullYear();
        group[date] = group[date] ?? [];
        group[date].push(data);
        return group;
    }, {});

    Object.entries(groupByYear).forEach(([key, value]) =>
        yearWiesData.current.push({ date: key, subRows: value })
    );

    if (incompleteTimesheetData.isLoading) {
        return <>Loading...</>;
    }

    if (incompleteTimesheetData.isError) {
        return <>Error</>;
    }

    const handleClick = (date) => {
        const newDateFormat = new Date(date);
        setPendingTimesheetCalendarDate(
            new CalendarDate(
                "AD",
                format(newDateFormat, "yyyy"),
                format(newDateFormat, "M"),
                format(newDateFormat, "d")
            )
        );

        navigate(
            `${global.config.routes.timesheetLandingPage}` +
                `/${global.config.routes.timesheetGrid}`
        );
    };

    const columns = [
        {
            headCellProps: {
                text: "Incomplete Timesheets",
                width: "85.37%",
            },

            getBodyCellProps: (cell) => ({
                text:
                    cell.value.length === 4
                        ? cell.value
                        : dateConverter(cell.value),
                actionIcon: cell.value.length !== 4 && "caret-right",
                padding: "10px 12px",
                actionProps: cell.value.length !== 4 && {
                    onClick: () => handleClick(cell.value),
                },
                color: "Black",
                variant: "Body_2_2",
                borderColor: cell.value.length === 4 && COLOR_VALUES.Gray_4,
            }),
            accessor: "date",
        },
        {
            headCellProps: {
                text: "Saved Hours",
                width: "12.2%",
            },
            getBodyCellProps: (cell) => ({
                text: cell.value ? (
                    cell.value
                ) : cell.row.original.date.length === 4 ? (
                    cell.value
                ) : (
                    <>&#45;&#45;</>
                ),
                color: "Black",
                padding: "10px 12px",
                borderColor:
                    cell.row.original.date.length === 4 && COLOR_VALUES.Gray_4,
            }),
            accessor: "savedHours",
        },
    ];

    const sortBy = [
        {
            id: "date",
            desc: true,
        },
    ];

    return (
        <>
            <VStack>
                <Table
                    showTableBorder={false}
                    columns={columns}
                    data={yearWiesData.current}
                    sortBy={sortBy}
                    pageSizeInitially={
                        yearWiesData.current?.length +
                        incompleteTimesheetData?.data?.length
                    }
                    displayPager={false}
                    expandedAllRows={true}
                />
            </VStack>
        </>
    );
};

export default IncompleteTimesheet;
