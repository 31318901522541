import "../configs/Global";

const CustomErrors = {
    OnGetUser: "Something went wrong. User not found.",
    OnSaveCustomGroup: "Something went wrong. Group was not saved.",
    OnSaveFilter: "Something went wrong. Filter was not saved.",
    OnSaveAllocationsHours: "Something went wrong saving the allocations.",
    disableFeature:
        "This feature is not enabled on this environment. Contact system administrator.",
    apiErrors: {
        error400: {
            title: "Unknown error",
            message:
                "We couldn't process your request",
            actionMessage:
                "Please check your URL, or reload the page to start again",
        },
        error401: {
            title: "Unauthorized",
            message: "You are no longer authorized to connect to some of the services",
            actionMessage: "contact support team",
        },
        error403: {
            title: "Network issue",
            message: "Something went wrong with network connections",
            actionMessage: `Please contact contact support team`,
        },
        error404: {
            title: "Not Found",
            message: "Some of the services that the page required are unavailable",
            actionMessage: "Please contact support team",
        },
        error408: {
            title: "Request Timed Out",
            message:
                "We were unable to process your request the services took too long to respond",
            actionMessage: `If the problem persists please contact support team `,
        },
        error500: {
            title: "Application Error",
            message:
                "Something failed in the application. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        error501: {
            title: "Application Error",
            message:
                "Something failed in the application. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        error502: {
            title: "Application Error",
            message:
                "Something failed in the application. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        error503: {
            title: "Server is Unavailable",
            message:
                "The server is temporarily unavailable. Please try again in a few minutes",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        error508: {
            title: "Request Timed Out",
            message:
                "The services took too long to respond. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        error: {
            title: "",
            message: "",
            actionMessage: "",
        },
        errorUnknown: {
            title: "Unknown error",
            message:
                "An unknown error occured. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        errorDefault: {
            title: "Unknown error",
            message:
                "An unknown error occured. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        errorTimeout: {
            title: "Timeout",
            message:
                "Services throw timeout error. Please try again.",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support team`,
        },
        errorNetwork: {
            title: "Network error",
            message:
                "There is a network issue",
            actionMessage: `If the problem persists please email support and add a description of what you were doing when the error occured or contact support or systems team`,
        },
    },
};

export default CustomErrors;
