import React, { useEffect, useContext } from "react";
import GlobalConfig from "configs/Global";
import RetainersDashboardNav from "pages/projects/retainers-dashboard/RetainersDashboardNav";
import {
    HeaderPageStyled,
    StyledTertiaryButton,
} from "pages/projects/StyledElements";
import PageWrapper from "pages/PageWrapper";
import { useTopNavHeight } from "pages/projects/useTopNavHeight";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const RetainersDashboard = ({ handleRefreshDataClick, fixedHeaderHeight }) => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const topNavHeight = useTopNavHeight();
    const projectsRetainersDashboardPath =
        GlobalConfig.routes.projectsRetainersDashboardLanding;

    const now = new Date();
    insightsTrace("VIEW", "RetainerDashboardPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    useEffect(() => {
        localStorage.removeItem("storedRetainerId");
    }, []);

    return (
        <div className="selector-nav">
            <HeaderPageStyled
                top={topNavHeight}
                fixedHeaderHeight={fixedHeaderHeight}
            />
            <PageWrapper
                menuOptions={[
                    {
                        children: "Projects",
                        to: GlobalConfig.routes.projectLanding,
                    },
                    {
                        children: "Retainer Dashboard",
                    },
                ]}
                minHeight="max-content"
                displayFooter={false}
                paddingBottom="10px"
                breadcrumbMarginBottom={0}
                customAuthPath={projectsRetainersDashboardPath}
            >
                <RetainersDashboardNav
                    refreshButtonElement={
                        handleRefreshDataClick ? (
                            <StyledTertiaryButton
                                aria-label="Sync"
                                leadingIcon="sync"
                                onClick={handleRefreshDataClick}
                            />
                        ) : null
                    }
                />
            </PageWrapper>
        </div>
    );
};

export default RetainersDashboard;
