const List = {
    allocations: {
        groupBy: [
            {
                label: "Region",
                tagLabel: "Group By: Region",
                value: "region",
                tagValue: "Region",
                checked: false,
            },
            {
                label: "Office",
                tagLabel: "Group By: Office",
                value: "office",
                tagValue: "Office",
                checked: false,
            },
            {
                label: "Communities / Group",
                tagLabel: "Group By: Communities",
                value: "group",
                tagValue: "Group",
                checked: false,
            },
            {
                label: "Department",
                tagLabel: "Group By: Department",
                value: "department",
                tagValue: "Department",
                checked: false,
            },
            {
                label: "None",
                tagLabel: "Group By",
                value: null,
                tagValue: null,
                checked: false,
            },
        ],
        sortBy: [
            {
                label: "Last Name A -> Z",
                tagLabel: "Sort By: Last Name A -> Z",
                value: "lastName,desc",
                checked: true,
            },
            {
                label: "Last Name Z -> A",
                tagLabel: "Sort By: Last Name Z -> A",
                value: "lastName,asc",
                checked: false,
            },
            {
                label: "First Name A -> Z",
                tagLabel: "Sort By: First Name A -> Z",
                value: "firstName,desc",
                checked: false,
            },
            {
                label: "First Name Z -> A",
                tagLabel: "Sort By: First Name Z -> A",
                value: "firstName,asc",
                checked: false,
            },
            {
                label: "Title: A -> Z",
                tagLabel: "Sort By: Title A -> Z",
                value: "title,desc",
                checked: false,
            },
            {
                label: "Title: Z -> A",
                tagLabel: "Sort By: Title Z -> A",
                value: "title,asc",
                checked: false,
            },
            {
                label: "Office: A -> Z",
                tagLabel: "Sort By: Office A -> Z",
                value: "office,desc",
                checked: false,
            },
            {
                label: "Office: Z -> A",
                tagLabel: "Sort By: Office Z -> A",
                value: "office,asc",
                checked: false,
            },
            {
                label: "Department: A -> Z",
                tagLabel: "Sort By: Department A -> Z",
                value: "department,desc",
                checked: false,
            },
            {
                label: "Department: Z -> A",
                tagLabel: "Sort By: Department Z -> A",
                value: "department,asc",
                checked: false,
            },
            {
                label: "Capacity: High to Low",
                tagLabel: "Sort By: Capacity: High to Low",
                value: "Capacity,desc",
                checked: false,
            },
            {
                label: "Capacity: Low to High",
                tagLabel: "Sort By: Capacity: Low to High",
                value: "Capacity,asc",
                checked: false,
            },
            {
                label: "TBD Roles First",
                tagLabel: "Sort By: TBD Roles First",
                value: "TBD,asc",
                checked: false,
            },
            {
                label: "TBD Roles Last",
                tagLabel: "Sort By: TBD Roles Last",
                value: "TBD,desc",
                checked: false,
            },
        ],
    },

    days: function () {
        return [
            {
                label: "M",
                value: 0,
            },
            {
                label: "T",
                value: 1,
            },
            {
                label: "W",
                value: 2,
            },
            {
                label: "T",
                value: 3,
            },
            {
                label: "F",
                value: 4,
            },
            {
                label: "S",
                value: 5,
            },
            {
                label: "S",
                value: 6,
            },
        ];
    },
};

export default List;
