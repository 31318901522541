import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import Icon from "components/design-system/ui/Icon";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";

export const TextInputWrapper = styled.div`
    position: relative;
    height: 100%;

    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const TextInputStylesDisabled = `
pointer-events: none;
background: ${COLOR_VALUES.Gray_9};
border-color: ${COLOR_VALUES.Gray_6};
`;

export const TextInputStyles = `    
    display: block;
    width: 100%;
    min-height: 48px;
    border-radius: 4px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    color: ${COLOR_VALUES.Gray_2};
    background: ${COLOR_VALUES.White};
    cursor: pointer;
    
    &:hover,
    &:visited {
        border-color: ${COLOR_VALUES.Gray_4};
    }

    &:active {
        border-color: ${COLOR_VALUES.Black};
    }

    &:focus {
        border-color: ${COLOR_VALUES.Black};
        color: ${COLOR_VALUES.Black};
        outline: none;
    }

    &::placeholder {
        color: ${COLOR_VALUES.Gray_4};
    }
             
    &:disabled {
        ${TextInputStylesDisabled}
    }
`;

export const TextInputStylesSuccess = ` 
border-color: ${COLOR_VALUES.Gray_4};

&:active {
    border-color: ${COLOR_VALUES.Gray_4};
}

&:hover,
&:focus,
&:focus-visible {
    border-color: ${COLOR_VALUES.Gray_4};
}`;

export const TextInputStylesWarning = `
border-color: ${COLOR_VALUES.Yellow};

&:active {
    border-color: ${COLOR_VALUES.Yellow};
}

&:hover,
&:focus,
&:focus-visible {
    border-color: ${COLOR_VALUES.Yellow};
}
`;

export const TextInputStylesError = `
border-color: ${COLOR_VALUES.Orange_1};

&:active {
    border-color: ${COLOR_VALUES.Orange_1};
}

&:hover,
&:focus,
&:focus-visible {
    border-color: ${COLOR_VALUES.Orange_1};
}
`;

export const StyledTextInput = styled.input`
    ${TEXT_VARIANT_STYLES.Body_1_1}

    ${TextInputStyles}

    ${(props) =>
        props.isSmallSize
            ? css`
                  min-height: 36px;
                  padding: 8px 8px 8px 12px;
                  ${TEXT_VARIANT_STYLES.Body_2_1};
              `
            : css`
                  padding: 12px 8px 12px 12px;
              `}
    
    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Success &&
        css`
            ${TextInputStylesSuccess}
        `}

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Warning &&
        css`
            ${TextInputStylesWarning}
        `}
        
     ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Error &&
        css`
            ${TextInputStylesError}
        `}

    ${(props) =>
        props.type === "search" &&
        css`
            padding-left: 36px;
            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        `}
        
    ${(props) =>
        props.characterCounter &&
        css`
            padding-right: 44px;
        `}

    ${(props) =>
        props.showClearIcon &&
        css`
            padding-right: 44px;
        `}
`;

export const StyledTextInputSearchIcon = styled(Icon)`
    position: absolute;
    border: none;
    width: 16px;
    height: 16px;
    top: 16px;
    left: 12px;
    bottom: 16px;
    color: ${COLOR_VALUES.Gray_2};
    &:disabled {
        color: ${COLOR_VALUES.Gray_4};
    }
`;

export const TextInputRow = styled(HStack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 8px;
`;

export const StyledTextInputClearIcon = styled.button`
    width: 16px;
    height: 16px;
    border: none;
    background: transparent;
    position: absolute;
    top: 16px;
    bottom: 16px;
    right: 12px;
`;

export const StyledCharacterCounter = styled(Text)`
    width: 14px;
    height: 16px;
    border: none;
    background: transparent;
    position: absolute;
    top: 16px;
    bottom: 16px;
    right: 28px;
`;
