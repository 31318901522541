import React, { useState } from "react";
import { format } from "date-fns";

import Loading from "components/UI/Loading";
import Text from "components/design-system/ui/Text";
import DateRangePicker from "components/design-system/forms/DateRangePicker";
import Form from "components/design-system/forms/Form";
import Table from "components/design-system/tables/Table";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import VStack from "components/design-system/layout/VStack";
import {
    getMinPrevDateForDateRangePicker,
    getMoneyColumnVal,
} from "common-methods/commonMethods";

import useProjectsDashboardOverviewBudget, {
    useProjectByIdProjectsDashboard,
} from "pages/projects/project-dashboard/single/useProjectsDashboardOverviewBudget";
import {
    useFixedRowsHours,
    useFixedRowsMoney,
} from "hooks/Projects/useFixedRows";
import { useProjectDashboardCurrency } from "pages/projects/project-dashboard/single/useProjectsDashboardData";

const columnNamesAccessor = {
    Name: "Department",
    Baseline: "Baseline",
    AtCompletion: "AtCompletion",
    Used: "Used",
    Variance: "Variance",
};
const totalOneColumns = [
    columnNamesAccessor.Baseline,
    columnNamesAccessor.AtCompletion,
    columnNamesAccessor.Used,
    columnNamesAccessor.Variance,
];
const tableTotalWidth = 1088;
const columns = [
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(313, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            actionIcon: "caret-right",
            actionProps: {
                to: `${cell.row.original.id}`,
                "aria-label": "Name",
            },
        }),
        accessor: columnNamesAccessor.Name,
    },
    {
        headCellProps: {
            text: "Budget Baseline",
            alignedRight: true,
            width: getCellWidthInPercent(185, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text:
                typeof cell.value === "number"
                    ? getMoneyColumnVal("", cell.value)
                    : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Baseline,
    },
    {
        headCellProps: {
            text: "Est. at Completion",
            alignedRight: true,
            width: getCellWidthInPercent(185, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text:
                typeof cell.value === "number"
                    ? getMoneyColumnVal("", cell.value)
                    : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.AtCompletion,
    },
    {
        headCellProps: {
            text: "Used to Date",
            alignedRight: true,
            width: getCellWidthInPercent(185, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text:
                typeof cell.value === "number"
                    ? getMoneyColumnVal("", cell.value)
                    : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Used,
    },
    {
        headCellProps: {
            text: "Variance",
            alignedRight: true,
            width: getCellWidthInPercent(185, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text:
                typeof cell.value === "number"
                    ? getMoneyColumnVal("", cell.value)
                    : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Variance,
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(32, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: () => ({
            text: "",
        }),
        accessor: "none",
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const getInitialDataForTotalRow = (name) => {
    return [
        { column: "Name", text: name },
        { column: totalOneColumns[0], text: 0, alignedRight: true },
        { column: totalOneColumns[1], text: 0, alignedRight: true },
        { column: totalOneColumns[2], text: 0, alignedRight: true },
        { column: totalOneColumns[3], text: 0, alignedRight: true },
        { column: "None", text: "" },
    ];
};
const totalsStrColmAtStart = 1;

const getFormattedDate = (d) => {
    return format(new Date(d), "yyyy-MM-dd");
};

const currentDate = format(new Date(), "yyyy-MM-dd");

const ProjectsDashboardOverviewBudget = () => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isDateChanged, setIsDateChanged] = useState(false);

    const { projectData } = useProjectByIdProjectsDashboard();
    const { useBudgetSummary } = useProjectsDashboardOverviewBudget();

    const startDateVal = projectData?.data?.ProjStartDate ?? currentDate;
    const endDateVal = projectData?.data?.CurrentEndDate ?? currentDate;

    const summaryMoney = useBudgetSummary({
        endpointSuffix: "money",
        startDate: isDateChanged ? startDate : startDateVal,
        endDate: isDateChanged ? endDate : endDateVal,
    });
    const summaryHours = useBudgetSummary({
        endpointSuffix: "hours",
        startDate: isDateChanged ? startDate : startDateVal,
        endDate: isDateChanged ? endDate : endDateVal,
    });
    const { currency } = useProjectDashboardCurrency();

    const [totalMoney, setTotalOneUpdate] = useFixedRowsMoney({
        initialData: getInitialDataForTotalRow("Currency Totals"),
        summary: summaryMoney,
        currency: currency,
        totalsStrColmAtStart: totalsStrColmAtStart,
        totalColumns: totalOneColumns,
    });

    const [totalHours, setTotalTwoUpdate] = useFixedRowsHours({
        initialData: getInitialDataForTotalRow("Total Hours"),
        summary: summaryHours,
        totalsStrColmAtStart: totalsStrColmAtStart,
        totalColumns: totalOneColumns,
    });

    const dateRangeChange = (dates) => {
        setIsDateChanged(true);
        setStartDate(dates.start);
        setEndDate(dates.end);

        setTotalOneUpdate(true);
        setTotalTwoUpdate(true);
    };

    if (projectData.isLoading) {
        return <Loading />;
    }

    if (projectData.isError || summaryMoney.isError || summaryHours.isError) {
        return <>Error</>;
    }

    return (
        <>
            <Text
                variant={"Headline_4"}
                color={"Gray_1"}
                style={{ paddingBottom: "16px" }}
            >
                Budget Summary Report
            </Text>
            <VStack>
                <Form
                    onSubmit={dateRangeChange}
                    defaultValues={{
                        date: {
                            start: getFormattedDate(
                                projectData?.data
                                    ? projectData?.data.ProjStartDate
                                    : currentDate
                            ),
                            end: getFormattedDate(
                                projectData?.data
                                    ? projectData?.data.CurrentEndDate
                                    : currentDate
                            ),
                        },
                    }}
                >
                    <DateRangePicker
                        name="date"
                        label="Date Range"
                        onChange={dateRangeChange}
                        minDate={getMinPrevDateForDateRangePicker()}
                        isSmallSize={true}
                        maxWidth="240px"
                        enableDaysFromDate={
                            projectData?.data
                                ? new Date(projectData?.data?.ProjStartDate)
                                : currentDate
                        }
                        enableDaysUntilDate={
                            projectData?.data
                                ? new Date(projectData?.data?.CurrentEndDate)
                                : currentDate
                        }
                    />
                </Form>

                {summaryHours.isFetching || summaryMoney.isFetching ? (
                    <Loading />
                ) : (
                    <Table
                        grayBgHeader={true}
                        showTableBorder={false}
                        columns={columns}
                        fixedHeadingRows={[
                            {
                                bgColor: COLOR_VALUES.Gray_7,
                                variant: "Headline_5",
                                borderBottom: false,
                                data: totalMoney,
                            },
                            {
                                bgColor: COLOR_VALUES.Gray_7,
                                variant: "Headline_5",
                                borderBottom: true,
                                data: totalHours,
                            },
                        ]}
                        data={summaryHours.data}
                        getRowProps={getRowProps}
                    />
                )}
            </VStack>
        </>
    );
};

export default ProjectsDashboardOverviewBudget;
