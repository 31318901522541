import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import Loading from "components/UI/Loading";
import Unauthorized from "pages/Unauthorized";
import PageHead from "components/design-system/ui/PageHead";
import LinkTabs from "../components/design-system/ui/LinkTabs";
import Footer from "components/design-system/ui/Footer";
import HStack from "components/design-system/layout/HStack";
import { usePageAccess } from "hooks/Access/usePageAccess.js";
import DashboardSideNav from "components/design-system/ui/dashboard/DashboardSideNav";

export const LoadingWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-content: center;
    align-content: center;
`;

const MainPageContent = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${(props) => props.paddingTop ?? "32px"};
    padding-right: 64px;
    padding-bottom: ${(props) => props.paddingBottom ?? "32px"};
    padding-left: 64px;
    min-height: ${(props) => props.minHeight ?? "calc(100vh - 5rem - 3.5rem)"};
    top: ${(props) => props.positionOfWrapper ?? "0"};
`;

const SideNavSection = styled.div`
    flex-shrink: 0;
    z-index: 2;
`;

const TabsSection = styled.div`
    width: 100%;
    z-index: 1;
`;

const PageWrapper = ({
    menuOptions,
    tabs,
    children,
    loading = false,
    customAuthPath,
    isSearch,
    onClickSearch,
    listOptions,
    isAlreadyAuthorize,
    displayFooter = true,
    minHeight = null,
    paddingTop = null,
    paddingBottom = null,
    breadcrumbMarginBottom = null,
    dashboardSideNav = false,
    tabsMarginBottom = null,
    displayUnauthorizedMsg = true,
    positionOfWrapper = null,
    ...rest
}) => {
    const { pathname } = useLocation();

    const { authorized } = usePageAccess(
        customAuthPath || pathname,
        isAlreadyAuthorize
    );

    const authIsLoading = authorized === null;

    if (authIsLoading || loading) {
        return (
            <LoadingWrapper>
                <Loading text="Loading Page" />
            </LoadingWrapper>
        );
    }

    if (!authorized && !displayUnauthorizedMsg) {
        return <></>;
    }

    if (!authorized) {
        return <Unauthorized />;
    }

    const renderTabsAndChildren = () => {
        return (
            <>
                {tabs && displayFooter && (
                    <LinkTabs
                        tabs={tabs}
                        css={`
                            margin-bottom: ${tabsMarginBottom ?? "32px"};
                        `}
                    />
                )}
                <div>{children}</div>
            </>
        );
    };

    return (
        <>
            <MainPageContent
                minHeight={minHeight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                positionOfWrapper={positionOfWrapper}
            >
                <PageHead
                    css={`
                        margin-top: ${menuOptions ? "0" : "36px"};
                        margin-bottom: ${breadcrumbMarginBottom ?? "32px"};
                    `}
                    menuOptions={menuOptions}
                    isSearch={isSearch}
                    onClickSearch={onClickSearch}
                    listOptions={listOptions}
                    {...rest}
                />
                {dashboardSideNav ? (
                    <HStack spacing="Two">
                        {dashboardSideNav && (
                            <SideNavSection className="sideNav">
                                <DashboardSideNav
                                    sideNavTabs={dashboardSideNav}
                                />
                            </SideNavSection>
                        )}
                        <TabsSection className="linkTabs">
                            {renderTabsAndChildren()}
                        </TabsSection>
                    </HStack>
                ) : (
                    renderTabsAndChildren()
                )}
            </MainPageContent>
            {displayFooter && <Footer />}
        </>
    );
};

export default PageWrapper;
