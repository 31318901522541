import { useMemo, useCallback, useRef } from "react";
import { useQuery } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";

const useAllocationsNoteRead = ({
    employeeId,
    jobId,
    titleId,
    officeId,
    date,
    mode,
    isJobScope,
    isNewNote = true,
}) => {
    const { getDataAsync } = useApi();
    const noteExists = useRef(!isNewNote);

    const queryKey = useMemo(
        () => [
            "allocationsNote",
            employeeId,
            jobId,
            titleId,
            officeId,
            date,
            mode,
            isJobScope,
        ],
        [date, employeeId, isJobScope, mode, officeId, jobId, titleId]
    );

    const getNoteApiCall = useCallback(async () => {
        if (!noteExists.current) {
            return {
                note: null,
                weekdays: null,
            };
        }

        const urlParams = [
            { name: "$userid", value: employeeId },
            { name: "$jobid", value: jobId },
            { name: "$titleid", value: titleId },
            { name: "$officeid", value: officeId },
        ];

        const queryParams = {
            dateFor: date,
            mode,
            isJobScope,
        };

        const response = await getDataAsync("getNote", {
            urlParams: urlParams,
            params: queryParams,
        });
        const json = await response.json();

        return json;
    }, [
        date,
        employeeId,
        getDataAsync,
        isJobScope,
        mode,
        noteExists,
        officeId,
        jobId,
        titleId,
    ]);

    const note = useQuery(queryKey, getNoteApiCall);

    return useMemo(() => {
        return {
            note
        };
    }, [note]);
};

export default useAllocationsNoteRead;
