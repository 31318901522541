import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { useApi } from "utilities/API/Helpers/useApi";

const useClientLOBs = () => {
    const { getDataAsync, putData, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { clientId } = useParams();

    const getClientLOBsApiCall = useCallback(async () => {
        const response = await getDataAsync("getLOBbyClientId", {
            urlParams: [{ name: "$clientId", value: clientId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientLOBs = useQuery("clientLOBsList", getClientLOBsApiCall);

    const createClientLOBApiCall = useCallback(
        ({ clientLOBId, body }) => {
            return postData("updateLOBbyClientId", {
                urlParams: [{ name: "$clientLOBId", value: clientLOBId }],
                body,
            });
        },
        [postData]
    );

    const updateClientLOBApiCall = useCallback(
        ({ clientLOBId, body }) => {
            return putData("updateLOBbyClientId", {
                urlParams: [{ name: "$clientLOBId", value: clientLOBId }],
                body,
            });
        },
        [putData]
    );

    const deleteClientLOBApiCall = useCallback(
        ({ clientLOBId }) => {
            return deleteData("deleteLOBbyClientId", {
                urlParams: [{ name: "$id", value: clientLOBId }],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientLOBsList");
            },
        };
    }, [queryClient]);

    const createClientLOB = useMutation(
        createClientLOBApiCall,
        mutationOptions
    );
    const updateClientLOB = useMutation(
        updateClientLOBApiCall,
        mutationOptions
    );
    const deleteClientLOB = useMutation(
        deleteClientLOBApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            clientLOBs,
            createClientLOB,
            updateClientLOB,
            deleteClientLOB,
        };
    }, [clientLOBs, createClientLOB, updateClientLOB, deleteClientLOB]);
};

export default useClientLOBs;
