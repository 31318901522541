import React, { useContext } from "react";
import styled from "styled-components";
import VStack from "components/design-system/layout/VStack";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import TextArea from "components/design-system/forms/TextArea";
import Form from "components/design-system/forms/Form";
import { sendNotification } from "utilities/Notification";
import useManageUserTimeOff from "pages/timesheet/manage-time-off/useManageUserTimeOff";
import ManageTimeOffContext from "context/ManageTimeOff/ManageTimeOffContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const StyledTextArea = styled(TextArea)`
    textarea {
        min-height: 116px;
    }
`;

function transformFormDataToTimeOffData(formData = {}, timeOffData = {}) {
    const newTimeOffData = { ...timeOffData };
    newTimeOffData.note = " " ?? timeOffData.note;

    return newTimeOffData;
}

const RejectTimeOffForm = ({ timeOffRequestId, onClose }) => {
    const { rejectTimeOff } = useManageUserTimeOff();
    const { rejectActionFromMenu, manageTimeOffRequestsData } =
        useContext(ManageTimeOffContext);
    const currentManageUserData = manageTimeOffRequestsData?.find(
        (data) => data.id === timeOffRequestId
    );
    const isEditing = typeof timeOffRequestId !== "undefined";
    const modifier = rejectTimeOff;
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();

    const now = new Date();
    insightsTrace("VIEW", "RejectTimeOffForm", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    if (isEditing && !currentManageUserData) {
        return <div>Could not find holiday</div>;
    }

    const onSubmit = (formData) => {
        const manageUserData = transformFormDataToTimeOffData(
            formData,
            currentManageUserData
        );

        const allData = {
            timeOffRequestId: timeOffRequestId,
            body: formData.note,
        };

        modifier.mutateAsync(allData).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>
                    Time off request for{" "}
                    {currentManageUserData?.employeeFullName} has been rejected
                </>
            );
        });
    };
    return (
        <>
            <Form onSubmit={onSubmit}>
                <VStack spacing="Two">
                    <StyledTextArea
                        validations={{
                            required: true
                        }}
                        name="note"
                        showClearIcon={true}
                        label="Reason for Decision"
                        description="Required"
                        placeholder="Please provide further explanation"
                    />
                    <ModalActions
                        primaryAction={{
                            type: "submit",
                            label: "Reject",
                            leadingIcon: "error",
                        }}
                        secondaryAction={{
                            type: "button",
                            label: rejectActionFromMenu ? "Cancel" : "Back",
                            to:
                                !rejectActionFromMenu &&
                                `?action=viewRequest&id=${timeOffRequestId}`,
                            onClick: rejectActionFromMenu && onClose,
                        }}
                    />
                </VStack>
            </Form>
        </>
    );
};

export default RejectTimeOffForm;
