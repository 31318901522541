import React, { useContext } from "react";
import PropTypes from "prop-types";

import Icon from "components/design-system/ui/Icon";
import {
    StyledMasterDrawer,
    StyledHeader,
    StyledIcon,
    StyledContent,
} from "components/design-system/ui/drawer/StyledDrawer";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import DrawerContext from "context/Drawer/DrawerContext";
import { createGlobalStyle, css } from "styled-components";

const DrawerStyled = createGlobalStyle`
.ReactModal__Overlay {  
    transition: all 0.5s;
}
.ReactModal__Overlay--after-open{ 
    ${(props) =>
        props.isOpen &&
        css`
            transform: translateX(-100px);
        `}
}
`;

const customStyles = {
    content: {
        position: "absolute",
        left: "auto",
        right: "-100px",
        top: 0,
        overflow: "hidden",
        maxHeight: "100vh",
        paddingRight: "8px",
        transition: "transform 0.5s",
        willChange: "transform",
    },
    overlay: {
        backgroundColor: "transparent",
    },
};

const MasterDrawer = ({
    width,
    stickyAction,
    children,
    isCTAAllActions = false,
}) => {
    const {
        isCTAPrimaryAction,
        show,
        displaySearch,
        setDisplaySearch,
        handleCloseMasterDrawer,
    } = useContext(DrawerContext);

    function handleClose() {
        handleCloseMasterDrawer();
    }

    return (
        <>
            <DrawerStyled isOpen={show} />
            <StyledMasterDrawer
                isOpen={show}
                onRequestClose={handleClose}
                contentLabel="Drawer"
                style={customStyles}
                width={width}
                scrollable={true}
                onAfterClose={() => {
                    document.activeElement.blur();
                }}
            >
                <StyledHeader spacing="Zero">
                    {!displaySearch && (
                        <TertiaryButton
                            leadingIcon="caret-left"
                            label="Back"
                            onClick={() => setDisplaySearch(true)}
                        />
                    )}
                    <StyledIcon
                        type="button"
                        aria-label="Close Drawer"
                        onClick={handleClose}
                    >
                        <Icon name="close" color="Gray_2" size={24} />
                    </StyledIcon>
                </StyledHeader>

                <StyledContent
                    stickyAction={stickyAction}
                    isCTAPrimaryAction={isCTAPrimaryAction}
                    isCTAAllActions={isCTAAllActions}
                >
                    {children}
                </StyledContent>
            </StyledMasterDrawer>
        </>
    );
};

MasterDrawer.defaultProps = {
    stickyAction: false,
    width: 32,
    isCTAAllActions: false,
};

MasterDrawer.propTypes = {
    stickyAction: PropTypes.bool,
    width: PropTypes.number,
    isCTAAllActions: PropTypes.bool,
};

export default MasterDrawer;
