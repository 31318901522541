import PropTypes from "prop-types";
import styled from "styled-components";

const Path = styled.path`
    fill: ${(props) => props.color};
`;

const Icon = styled.svg`
    fill: none;

    ${Path} {
        fill: ${(props) => props.color};
    }

    &:hover {
        ${Path} {
            fill: ${(props) => (props.hover ? props.hoverColor : props.color)};
        }
    }
`;

const Up = ({ size, color, hover, hoverColor }) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={"0 0 " + size + " " + size}
            width={size}
            height={size}
            color={color}
            hover={hover}
            hoverColor={hoverColor}
        >
            <Path
                d="M15 9.00236L9.00236 3L3 9.00236L3.66588 9.66824L8.53011 4.80874V14.9717H9.47462V4.80874L14.3341 9.66824L15 9.00236Z"
                color={color}
            />
        </Icon>
    );
};

Up.defaultProps = {
    size: "18",
    color: global.config.colors.gray2,
    hover: false,
    hoverColor: global.config.colors.black,
};

Up.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    hover: PropTypes.bool,
    hoverColor: PropTypes.string,
};

export default Up;
