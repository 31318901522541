import React, { useState } from "react";
import { useCalendarState } from "@react-stately/calendar";
import {
    useCalendar,
    useCalendarCell,
    useCalendarGrid,
} from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import {
    getWeeksInMonth,
    isToday,
    createCalendar,
} from "@internationalized/date";
import styled from "styled-components";

import DatePickerDisplayMonthAndYear from "components/design-system/controls/datepicker/DatePickerDisplayMonthAndYear";
import Text from "components/design-system/ui/Text";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import HStack from "components/design-system/layout/HStack";
import { DateButton } from "components/design-system/controls/datepicker/StyledElements";
import { COLOR_VALUES } from "components/design-system/config/colors";

export const CalendarTitleBlockPadding = "19px 16px";

const StyledTertiaryButton = styled(TertiaryButton)`
    box-shadow: 0 0 0px 1px ${COLOR_VALUES.Gray_6} inset;
    padding: 0;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50% !important;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;
    }
`;

function CalendarCell({ state, date }) {
    const ref = React.useRef();
    const {
        cellProps,
        buttonProps,
        isSelected,
        isOutsideVisibleRange,
        isDisabled,
        isUnavailable,
        formattedDate,
    } = useCalendarCell({ date }, state, ref);

    return (
        <td {...cellProps}>
            {isDisabled || isUnavailable ? (
                <DateButton
                    ref={ref}
                    hidden={isOutsideVisibleRange}
                    isSelected={isSelected}
                    isUnavailable={isUnavailable}
                    disabled={isDisabled}
                    isCurrent={isToday(date)}
                >
                    {formattedDate}
                </DateButton>
            ) : (
                <DateButton
                    {...buttonProps}
                    ref={ref}
                    hidden={isOutsideVisibleRange}
                    isSelected={isSelected}
                    isUnavailable={isUnavailable}
                    disabled={isDisabled}
                    isCurrent={isToday(date)}
                >
                    {formattedDate}
                </DateButton>
            )}
        </td>
    );
}

function CalendarGrid({ state, ...props }) {
    const { locale } = useLocale();
    const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    return (
        <table
            {...gridProps}
            style={{ padding: "0 7px 0 16px", margin: "0 auto" }}
        >
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th
                            key={index}
                            style={{
                                width: "33px",
                                paddingBottom: "7px",
                                paddingRight: "9px",
                                textAlign: "center",
                            }}
                        >
                            <Text variant="Descriptive_1_1" color="Gray_3">
                                {day}
                            </Text>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex)
                            .map((date, i) =>
                                date ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        date={date}
                                    />
                                ) : (
                                    <td key={i} />
                                )
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default function DatePickerCalendar({
    onClickCancel,
    onClickDone,
    ...props
}) {
    const { locale } = useLocale();
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });
    const ref = React.useRef();
    const { calendarProps, prevButtonProps, nextButtonProps, title } =
        useCalendar(props, state, ref);
    const [showMonthAndYear, setShowMonthAndYear] = useState(false);

    return (
        <div {...calendarProps} ref={ref}>
            <HStack
                align="center"
                justify="space-between"
                style={{ padding: CalendarTitleBlockPadding }}
            >
                <TertiaryButton
                    trailingIcon={showMonthAndYear ? "caret-down" : "caret-up"}
                    label={title}
                    onClick={() => setShowMonthAndYear(!showMonthAndYear)}
                />

                <HStack>
                    <StyledTertiaryButton
                        trailingIcon="caret-left"
                        onClick={prevButtonProps.onPress}
                        {...Object.entries(prevButtonProps).splice(
                            Object.keys(prevButtonProps).indexOf("onPress"),
                            1
                        )}
                        {...{ "aria-label": "Prev" }}
                    />

                    <StyledTertiaryButton
                        trailingIcon="caret-right"
                        onClick={nextButtonProps.onPress}
                        {...Object.entries(nextButtonProps).splice(
                            Object.keys(nextButtonProps).indexOf("onPress"),
                            1
                        )}
                        {...{ "aria-label": "Next" }}
                    />
                </HStack>
            </HStack>
            {showMonthAndYear ? (
                <DatePickerDisplayMonthAndYear
                    state={state}
                    setShowMonthAndYear={setShowMonthAndYear}
                />
            ) : (
                <>
                    <CalendarGrid state={state} />
                    <HStack
                        justify="space-between"
                        style={{ padding: "5px 25px 14px 25px" }}
                    >
                        <TertiaryButton
                            label="Cancel"
                            onClick={onClickCancel}
                        />
                        <TertiaryButton label="Done" onClick={onClickDone} />
                    </HStack>
                </>
            )}
        </div>
    );
}
