import styled, { createGlobalStyle, css } from "styled-components";

import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import { DropdownStylesError } from "components/design-system/controls/dropdown/DropdownStyledComponents";

export const DropdownListStyled = createGlobalStyle`
    .dropdownListContainer{
        width:132.29px;
        top: calc(100%);
        ul > div{
            min-height: 36px;
        }   
        li{
            margin-left: 16px;
            margin-right: 8px;
            padding: 8px 6px;
            border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
        }  
    }
`;

export const StyledDropdown = styled(DropdownSingleSelectWithoutSearch)`
    width: 100%;
    height: 52px;
    padding: 10px 12px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    border-color: transparent;
    color: ${({ $hasSelection }) =>
        $hasSelection ? COLOR_VALUES.Gray_1 : COLOR_VALUES.Gray_4};

    div {
        ${TEXT_VARIANT_STYLES.Body_2_2}
    }

    &:visited {
        div,
        svg {
            color: ${COLOR_VALUES.Gray_1};
        }
    }

    &:hover {
        div,
        svg {
            color: ${COLOR_VALUES.Black};
        }
    }

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Error &&
        css`
            ${DropdownStylesError}
        `}

    ${(props) =>
        props.disabled &&
        css`
            background: transparent;
            border-color: transparent;
        `}
`;
