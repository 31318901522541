import React from "react";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";

const BaseRadio = (props) => {
    return <input {...props} type="radio" />;
};

const Wrapper = styled.div`
    width: max-content;
    height: auto;

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: not-allowed;
        `};
`;

const StyledRadio = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
    appearance: none;
    border-radius: 8px;
    color: ${COLOR_VALUES.Gray_4};
    box-shadow: 0px 0px 0px 1px inset currentColor;

    &:disabled {
        pointer-events: none;
        user-select: none;
    }

    ::after {
        width: 8px;
        height: 8px;
        display: block;
        background: currentColor;
        border-radius: 4px;
        margin: 4px;
    }

    &:checked {
        ::after {
            content: "";
        }
    }

    &:not(:disabled) {
        &:not(:checked) {
            &:hover {
                color: #000;
            }

            &:active {
                color: #000;

                ::after {
                    content: "";
                }
            }
        }

        &:checked {
            color: #000;

            &:hover {
                color: ${COLOR_VALUES.Gray_2};
            }
        }
    }

    &:not(:checked) {
        &:disabled {
            background: ${COLOR_VALUES.Gray_8};
        }
    }

    &:disabled:checked {
        color: ${COLOR_VALUES.Gray_3};
        background: ${COLOR_VALUES.Gray_8};
    }

    &:before {
        position: absolute;
        inset: 0;
        content: "";
        display: ${(props) => (props.$expandHitArea ? "block" : "none")};
    }
`;

const StyledLabel = styled(HStack).attrs({ forwardedAs: "label" })`
    position: relative;
    margin: 0;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  color: ${COLOR_VALUES.Gray_4};
                  pointer-events: none;
                  user-select: none;
              `
            : css`
                  &:hover {
                      color: ${COLOR_VALUES.Gray_2};
                  }
              `}
`;

const Radio = React.forwardRef(({ label, checked, disabled, ...rest }, ref) => {
    const RadioControl = (
        <StyledRadio
            {...rest}
            checked={checked}
            disabled={disabled}
            type="radio"
            ref={ref}
        />
    );
    return (
        <Wrapper $disabled={disabled}>
            {label ? (
                <StyledLabel
                    spacing="Zero_5"
                    align="center"
                    $disabled={disabled}
                >
                    {RadioControl}
                    <Text variant={checked ? "Body_2_2" : "Body_2_1"}>
                        {label}
                    </Text>
                </StyledLabel>
            ) : (
                <BaseRadio {...rest} />
            )}
        </Wrapper>
    );
});

export default Radio;
