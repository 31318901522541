import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;  
  font-family: 'HelveticaNeueW01-45Ligh', helvetica, sans-serif;
  font-size: medium;
  font-weight: 300;
  scrollbar-width: 6px;          
  scrollbar-color: #DDE1E5;
  border-right: 1px solid #EDEFF2;
}

/* width */
::-webkit-scrollbar {
  width: 14px;
  color: #DDE1E5;
}

/* Track */
::-webkit-scrollbar-track {
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #DDE1E5; 
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #DDE1E5; 
}

h1, h2, h3, h4, h5, h6, b, strong {
  font-family: 'HelveticaNeueW01-65Medi', helvetica, sans-serif;
}

#mti_wfs_colophon {
  display: none;
  visibility: hidden;
}

/* Modal */
.modal{
  pointer-events: none;
}

.modal-dialog {
  pointer-events: auto;
  max-width: 95%;
  div.modal-content{
    border: initial;
      border-radius: initial;
  }
}

.modal-header {
  border-bottom: 0;
  padding: 0.5rem 1rem;
}

.modal-header.fullscreen {
  padding: 0.5rem;
  position: absolute;
  right: 0;
  z-index: 1;
}

.modal-header .close {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem -1rem auto;
  font-weight: 100;
  font-size: 2rem;
  opacity: 1;
}

.modal-header .close:not(:disabled):not(.disabled):hover {
  opacity: 0.5;
}
  
.modal-header.fullscreen .close {
  padding: 0;
  margin: 0.5rem 1rem 0 0;
}

.modal-footer {
  border-top: 0;
}

.modal-body {
  padding: 0;
  overflow: auto;
}

/* Animation: Slide up and down */
.slide-up, .slide-down {
  max-height: 0;            
  overflow-y: hidden;
  -webkit-transition: max-height 1.0s ease-in-out;
  -moz-transition: max-height 1.0s ease-in-out;
  -o-transition: max-height 1.0s ease-in-out;
  transition: max-height 1.0s ease-in-out;
}

.slide-down {            
  max-height: 100%;                    
}
  
.transition{
  -webkit-transition: all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out
    -o-transition:all 0.5s ease-in-out;
    transition::all 0.5s ease-in-out;
}

/* Form input */
input {
  background-color: transparent;
  color: #000000;
}

input::placeholder {
  color: #000000;
}

input[type=checkbox], input[type=radio] {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Notification  */
.notification__item--default{
  background-color: #3F434D;
  border-left: 0;
}
.notification__message {
  max-width: initial;
  a {
    text-decoration: underline;
    color: #FFFFFF;
    margin-left: 16px;
  }
  a:hover {
    color: #989494;
  }
}
.notification__content {
  .notification__close:after {
    font-size: 1.188rem;
    background-color: transparent;

  }
  .notification__close  {
    background-color: transparent;
    right: 1rem;
    top: calc(50% - 10px);
  }
  padding: .875rem 3rem .875rem .875rem;
}
.notification__item--default{
  width: max-content;
}

.toggle-button:focus {
  outline: none !important;
  box-shadow: none;
}

/* Checkbox Tree */
.rct-native-display .rct-checkbox {
  display: none;
}
.rct-node-icon {
  display: none;
}
.rct-node {
  margin-bottom: 0.5em;
}
.rct-collapse {
  display: none;
}
.rct-title {
  font-size: 0.85em;
}
.react-checkbox-tree label:hover {
  background: inherit;
}
.react-checkbox-tree ol {
  margin-top: 0.5rem;
}

#searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}
.MuiButton-outlined{
  border: 0.063rem solid #000000;
}
.MuiListItem-button:hover {
  #moreButton {
    margin-left: 0px;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: "center";
    justify-content: "center"
}
}

.MuiInput-underline:before {
  border-bottom: 0.01rem solid #EDEFF2 !important
}

.MuiInput-underline:after {
  border-bottom: 0.01rem solid #EDEFF2 !important
}

button:focus:not(:focus-visible){
  outline:none;
}
.slide-pane__overlay{
  z-index:105;
}
.btn.focus, .btn:focus{
  box-shadow:none;
}
.makeStyles-root-212 ul.rc-menu > li.rc-menu__item--disabled, .makeStyles-root-212 ul.rc-menu > li.rc-menu__item--disabled > a[disabled]{
  color: #DADBDE;
}

p.disabled {
  color: #A3A6AB !important;
}

.tooltip-overlay
{
  text-align:left;
  max-width:40rem;
  width: max-content;
  padding: 0.75rem 1rem;
  background-color: #3F434D;
  border-radius: 0.25rem; 
}
svg.MuiCircularProgress-svg{
  margin:0 !important;
}

.filterScrollbars::-webkit-scrollbar {
  width: 0.35rem; 
} 

.filterScrollbars::-webkit-scrollbar-thumb {
  border-radius: 0.15rem;
  border-right: 0px solid transparent;
  background-clip: none;
  background-color: rgb(163, 166, 171);
}

.Mui-disabled > svg > path{
  stroke:#EDEFF2;
}

.Embed-container {
  height: 870px;
  width: 1500px;
  iframe {
    border: 0;
  }
  .preTextWithEllipsis{
    margin:10px;
  }
}

.preTextWithEllipsis{
  font-size:18px;
}

.allocationsStickyEmployee {
  position: fixed;
  margin: 0px;
  padding: 0px;
  width: 100%;
  z-index: 100;
}

`;


export default GlobalStyle;
