import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import Modal from "components/design-system/ui/modal/MasterModal";
import HStack from "components/design-system/layout/HStack";
import ProjectsLinkedTable from "pages/admin/projects/single/ProjectsLinkedTable";
import ProjectsContentStudioTable from "pages/admin/projects/single/ProjectsContentStudioTable";
import ProjectLinkForm from "pages/admin/projects/single/ProjectLinkForm";
import useProjectsLinked, {
    useProjectRelationTypes,
} from "pages/admin/projects/single/useProjectsLinked";
import ProjectLinkDeleteModal from "pages/admin/projects/single/ProjectLinkDeleteModal";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const linkId = searchParams.get("linkId");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal
                title="Link Project"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <ProjectLinkForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(linkId);

    if (action === "delete" && !isNaN(parsedId)) {
        return (
            <Modal
                title="Remove Project Link"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <ProjectLinkDeleteModal
                    projectLinkIdToDelete={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

export const LINKED_PROJECT_RELATION = "linked";
const CONTENT_STUDIO_RELATION = "related";

export const getIdOfLinkedProjectRelationType = (
    data,
    str = LINKED_PROJECT_RELATION
) => {
    return data?.find((f) => f.Name.toLowerCase().includes(str))?.Id;
};

const ProjectsLinked = () => {
    const { useProjectsRelatedList } = useProjectsLinked();
    const { projectId } = useParams();
    const relatedProjectsList = useProjectsRelatedList({ jobID: projectId });
    const { projectRelationTypes } = useProjectRelationTypes();

    if (relatedProjectsList.isLoading || projectRelationTypes.isLoading) {
        return <>Loading...</>;
    }

    if (relatedProjectsList.isLoading) {
        return <>Error</>;
    }

    const linkedProject = getIdOfLinkedProjectRelationType(
        projectRelationTypes?.data
    );
    const contentStudioProject = getIdOfLinkedProjectRelationType(
        projectRelationTypes?.data,
        CONTENT_STUDIO_RELATION
    );

    return (
        <>
            <FormPresenter />
            <HStack spacing="Two" justify="space-around">
                <ProjectsLinkedTable
                    linkedProjects={relatedProjectsList?.data?.filter(
                        (f) => f.relationTypeID == linkedProject
                    )}
                />

                <ProjectsContentStudioTable
                    contentStudioProjects={relatedProjectsList?.data?.filter(
                        (f) => f.relationTypeID == contentStudioProject
                    )}
                />
            </HStack>
        </>
    );
};

export default ProjectsLinked;
