import React, { useContext, useEffect, useState } from "react";
import env from "react-dotenv";

import {
    Title,
    FormField,
    Wrapper,
    Header,
    WrapperEmployee,
} from "components/Allocations/Grid/Employee/styles/SearchEmployee-Styles";
import Text from "components/UI/Text";
import IconTextInput from "components/UI/IconTextInput";
import Profile from "components/UI/Profile";
import Magnifier from "components/Icon/Magnifier";
import { useUser } from "utilities/API/Users";
import { useLocalStorage } from "utilities/LocalStorage";
import Right from "components/Icon/Right";
import EmployeeDrawerNew from "components/Allocations/Grid/Employee/EmployeeDrawerNew";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import DrawerContext from "context/Drawer/DrawerContext";

const SearchEmployeeNew = (props) => {
    const { defaultValue } = props;
    const { getEmployeesForSearch } = useUser();
    const { setWithExpiry } = useLocalStorage();
    const { getWithExpiry } = useLocalStorage();
    const { get } = useLocalStorage();
    const { set } = useLocalStorage();
    const [employees, setEmployees] = useState([]);
    const [showHistory, setShowHistory] = useState(true);
    const [cache, setCache] = useState(null);
    const [drawerUserId, setDrawerUserId] = useState();
    const customGroupConsumer = useContext(CustomGroupContext);
    const { displaySearch, setDisplaySearch } = useContext(DrawerContext);

    useEffect(() => {
        if (defaultValue) handleOnChange(defaultValue);
    }, [cache]);

    useEffect(() => {
        async function getEmployees() {
            let list = await getWithExpiry("employees-search");
            if (list == null) {
                await getEmployeesForSearch([{ name: "$name", value: "" }])
                    .then((payload) => {
                        setWithExpiry(
                            "employees-search",
                            payload,
                            env.BESTWORK_REACT_EMPLOYEEFORSEARCH_EXPIRYMIN
                        );
                        list = payload;
                    })
                    .catch(() => {
                        /**/
                    });
            }
            setCache(list);
        }
        getEmployees();
    }, []);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleOnChange = async function (inputValue) {
        if (inputValue && cache) {
            filterByName(cache, inputValue);
            setShowHistory(false);
        } else {
            setShowHistory(true);
        }
    };

    const optimisedHandleChange = debounce(handleOnChange, 500);

    const filterByName = (data, inputValue) => {
        const results = data.filter((object) => {
            return object.SearchText.indexOf(inputValue.toLowerCase()) > -1;
        });
        setEmployees(results);
    };

    useEffect(() => {
        if (showHistory) getHistory();
    }, [showHistory]);

    const getHistory = () => {
        let data = get("employees-search-history");
        if (data) setEmployees(data);
        else setEmployees([]);
    };

    const handleSelect = (employee) => {
        saveHistory(employee);
        setDrawerUserId(employee.Id);
        setDisplaySearch(false);
    };

    const saveHistory = (employee) => {
        // Get current history
        let currentData = get("employees-search-history") || [];
        //remove duplicates
        currentData = currentData.filter((data) => data.Id !== employee.Id);
        //save selected
        currentData.unshift(employee);
        set(
            "employees-search-history",
            currentData.slice(
                0,
                env.BESTWORK_REACT_EMPLOYEEFORSEARCH_HISTORYLIMIT
            )
        );
    };

    useEffect(() => {
        return () => {
            setDrawerUserId(null);
        };
    }, []);

    return (
        <>
            {displaySearch && (
                <>
                    <Header>
                        <Title>
                            <Text variant="xxlargeBold">Search Employees</Text>
                        </Title>
                        <FormField>
                            <IconTextInput
                                label="Employee Name"
                                defaultValue={defaultValue}
                                type="text"
                                icon={<Magnifier />}
                                onChange={optimisedHandleChange}
                            />
                        </FormField>

                        <Text variant="xsmallLight">
                            {showHistory && "Recently Viewed"}{" "}
                            {!showHistory &&
                                employees.length &&
                                "Search Results (" + employees.length + ")"}
                            {!showHistory && !employees.length && "No Results"}
                        </Text>
                    </Header>
                    <Wrapper>
                        {employees &&
                            employees.map((employee, index) => (
                                <WrapperEmployee
                                    key={index}
                                    onClick={() => handleSelect(employee)}
                                >
                                    <Profile
                                        name={employee.FullName}
                                        title={employee.Title}
                                        location={employee.Office}
                                        image={
                                            env.BESTWORK_REACT_ASSET_DOMAIN +
                                            "/" +
                                            employee.PhotoURL
                                        }
                                        freelance={false}
                                    />
                                    <Right circle={false} />
                                </WrapperEmployee>
                            ))}
                    </Wrapper>
                </>
            )}
            {drawerUserId && !displaySearch && (
                <CustomGroupContext.Provider value={customGroupConsumer}>
                    <EmployeeDrawerNew userId={drawerUserId} />
                </CustomGroupContext.Provider>
            )}
        </>
    );
};

export default SearchEmployeeNew;
