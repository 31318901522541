import { useParams } from "react-router-dom";
import AccessGroupMembersTable from "./AccessGroupMembersTable";
import { useSearchParams, useNavigate } from "react-router-dom";

import AccessGroupMemberForm from "pages/admin/access-groups/single/AccessGroupMemberForm";
import Modal from "components/design-system/ui/modal/MasterModal";

import useAccessGroupApi from "pages/admin/access-groups/single/useAccessGroup";
import AccessGroupMemberDeleteModal from "pages/admin/access-groups/single/AccessGroupMemberDeleteModal";

const FormPresenter = () => {
    const { groupId } = useParams();
    const { useAccessGroup } = useAccessGroupApi();
    const groupData = useAccessGroup(groupId);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal
                title="Add to Access Group"
                stickyAction={true}
                onRequestClose={onClose}
            >
                <AccessGroupMemberForm
                    key="new"
                    onClose={onClose}
                    accessGroupId={groupId}
                    accessGroupName={groupData.data && groupData.data.name}
                />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "delete" && !isNaN(parsedId)) {
        return (
            <Modal title="Remove from Access Group" onRequestClose={onClose}>
                <AccessGroupMemberDeleteModal
                    accessGroupId={groupId}
                    accessGroupName={groupData.data && groupData.data.name}
                    accessGroupMemberIdToDelete={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const AccessGroupMembers = () => {
    const { groupId } = useParams();

    return (
        <>
            <FormPresenter />
            <AccessGroupMembersTable groupId={groupId} />
        </>
    );
};

export default AccessGroupMembers;
