import env from "react-dotenv";
import { format } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalConfig from "configs/Global";

import Loader from "components/UI/Loader";
import Subject from "components/design-system/ui/drawer/Subject";
import DataGroup, {
    StyledHR,
} from "components/design-system/ui/drawer/DataGroup";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import AttributeDataGroup from "components/design-system/ui/drawer/AttributeDataGroup";
import DataItem from "components/design-system/ui/drawer/DataItem";
import LinkGroup from "components/design-system/ui/drawer/LinkGroup";
import VStack from "components/design-system/layout/VStack";
import {
    classificationSegmentOptions,
    rateCardSegmentOptions,
} from "pages/admin/projects/projectsFormOptions";

import { useProjectsApi } from "pages/admin/projects/useProjects";
import useProjectsLinked from "pages/admin/projects/single/useProjectsLinked";

import DrawerContext from "context/Drawer/DrawerContext";

const attributesCostOptions = [
    {
        label: "Pass through at cost",
        value: "ExpensesPassThru",
    },
    {
        label: "Approval request",
        value: "TimesheetAppReq",
    },
    {
        label: "SOW Signed",
        value: "SOWSigned",
    },
    {
        label: "SOW Received by finance",
        value: "SOWReceivedFin",
    },
    {
        label: "Overwrite EAC/PTD to Vantage",
        value: "OverwriteEACPTD",
    },
];

const ProjectDrawer = ({ projectId }) => {
    const { useProjectById } = useProjectsApi();
    const { useProjectsRelatedList } = useProjectsLinked();
    const relatedProjectsList = useProjectsRelatedList({ jobID: projectId });
    const [linkedProject, setLinkedProject] = useState([]);
    const relatedProjectArr = useRef();

    const projectData = useProjectById(projectId);
    const projectInfo = projectData?.data;
    const { setShow } = useContext(DrawerContext);

    const handleDrawerClose = () => {
        setShow(false);
    };

    useEffect(() => {
        relatedProjectArr.current = [];
        relatedProjectsList?.data?.map((relatedprojectData) => {
            relatedProjectArr.current?.push({
                to:
                    env.BESTWORK_REACT_REPORTER10_LINK +
                    "admin/editProject.aspx?JobID=" +
                    relatedprojectData?.relatedJobID,
                name: `${relatedprojectData?.JobCode} – ${relatedprojectData?.Name}`,
            });
        });
        setLinkedProject([...relatedProjectArr.current]);
    }, [relatedProjectsList?.data]);

    if (projectData.isLoading || relatedProjectsList.isLoading) {
        return <>Loading...</>;
    }

    if (projectData.error || relatedProjectsList.error) {
        return <>Error...</>;
    }

    const projectActiveCancelledCondition =
        projectInfo.Active || projectInfo.ProjectCancelled;

    const getActiveCanceledStatus = () => {
        if (projectInfo.Active && projectInfo.ProjectCancelled) {
            return "Active/Canceled";
        } else if (projectInfo.Active) {
            return "Active";
        } else if (projectInfo.ProjectCancelled) {
            return "Canceled";
        } else return "";
    };

    const getProjectStatus = () => {
        return projectInfo.IsNonBillable
            ? classificationSegmentOptions[1].label
            : classificationSegmentOptions[0].label;
    };

    const getType = () => {
        return projectInfo?.clientDefault
            ? rateCardSegmentOptions[0].label
            : rateCardSegmentOptions[1].label;
    };

    const getRetainerDetails = () => {
        if (projectInfo.RetainerName && projectInfo.SendToVantage) {
            return `${projectInfo.RetainerName} \n Send EAC/PTD to Vantage`;
        } else if (projectInfo.RetainerName) {
            return projectInfo.RetainerName;
        } else if (projectInfo.SendToVantage) {
            return "Send EAC/PTD to Vantage";
        } else return "";
    };

    const getOfficeDetails = () => {
        if (projectInfo.OfficeRegionName && projectInfo.IsRequest) {
            return `${projectInfo.OfficeRegionName} \n In Request`;
        } else if (projectInfo.OfficeRegionName) {
            return "projectInfo.OfficeRegionName";
        } else if (projectInfo.IsRequest) {
            return "In Request";
        } else return "";
    };

    return (
        <>
            {projectId && projectInfo ? (
                <>
                    <Subject
                        subjectName={projectInfo.Name}
                        codeNumber={`Number: ${projectInfo.JobCode} \n Old Number: ${projectInfo.OldJobCode}`}
                        showPhoto={false}
                        isMoreIcon={false}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Name",
                                value: projectInfo.Name,
                                isHr: !projectActiveCancelledCondition,
                            },
                        ]}
                    />

                    {projectActiveCancelledCondition && (
                        <>
                            <DataItem
                                label="Status"
                                iconName="success"
                                title={getActiveCanceledStatus()}
                            />
                            <VStack>
                                <StyledHR />
                            </VStack>
                        </>
                    )}

                    <DataGroup
                        groupData={[
                            {
                                label: "Classificaton",
                                value: projectInfo.ClassificationName,
                            },
                            {
                                label: "Bill",
                                value: getProjectStatus(),
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Rate Card",
                                value: projectInfo?.RateCardTableName,
                            },
                            {
                                label: "Type",
                                value: getType(),
                                isHr: true,
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Lead Office",
                                value: getOfficeDetails(),
                                isNextLine: true,
                                isHr: true,
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Vantage Status",
                                value: projectInfo.SAP_Job_Status_Name,
                            },
                            {
                                label: "API Status",
                                value: projectInfo.SAP_API_Status_Name,
                            },
                            {
                                label: "Type for Tax",
                                value: projectInfo.TypeForTaxName,
                            },
                            {
                                label: "Vantage LOB",
                                value: projectInfo.SAPLOBName,
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Start Date",
                                value: format(
                                    new Date(projectInfo.ProjStartDate),
                                    "yyyy-MM-dd"
                                ),
                            },

                            {
                                label: "Retainer",
                                value: getRetainerDetails(),
                                isNextLine: true,
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Alternative Job Code",
                                value: projectInfo?.OldJobCode,
                            },
                            {
                                label: "Client Group (LOB)",
                                value: projectInfo?.ClientGroupName,
                            },
                            {
                                label: "Client Campaign",
                                value: projectInfo?.ClientCampaignName,
                            },
                            {
                                label: "Total HRs $ Value",
                                value: projectInfo?.SOWTotalCalc,
                            },
                            {
                                label: "OOP Estimate",
                                value: projectInfo?.OOPExpEstimate,
                            },
                            {
                                label: "Agency Fee Estimate",
                                value: projectInfo?.AgencyFeeEstimate,
                                isHr: true,
                            },
                        ]}
                    />
                    <AttributeDataGroup
                        groupData={[
                            {
                                label: "Attributes",
                                list: attributesCostOptions
                                    ?.map((attribute) => attribute)
                                    .filter(
                                        (item) => projectInfo?.[item?.value]
                                    )
                                    ?.map(
                                        (attributeData) => attributeData?.label
                                    ),
                                isHr: true,
                            },
                        ]}
                    />
                    <LinkGroup
                        label="Linked Projects"
                        linkData={linkedProject}
                    />
                    <DrawerActions
                        isCTAAllActions={true}
                        primaryAction={{
                            label: "Edit",
                            leadingIcon: "edit",
                            to: `?action=edit&id=${projectId}`,
                            onClick: handleDrawerClose,
                        }}
                        secondaryAction={{
                            type: "button",
                            label: "Linked Projects",
                            leadingIcon: "project",
                            to: `${projectId}/${GlobalConfig.routes.adminProjectsLinked}`,
                            fullWidth: true,
                            onClick: handleDrawerClose,
                        }}
                        tertiaryAction={{
                            type: "button",
                            label: "Dashboard",
                            trailingIcon: "link",
                            href:
                                env.BESTWORK_REACT_REPORTER10_LINK +
                                "job/JobReport.aspx?JobID=" +
                                projectId +
                                "&env=" +
                                env.ENVIRONMENT_NAME +
                                "&vpn=true",
                            target: "_blank",
                        }}
                    />
                </>
            ) : (
                <Loader height="5rem" width="5rem" />
            )}
        </>
    );
};

export default ProjectDrawer;
