import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useStylesForButtonGroups } from "styles/MakeStyles";
import WarningBox from "./WarningBox";

let renderCount = 1;

const DEFAULT_DISPLAYS = ["Staff", "Freelancer", "TBD"];

const warningMessage =
    "You have adjusted filters. Saved Filters have been cleared.";

const Display = ({
    onChange,
    savedDisplay,
    filterSelected,
    handleSavedFilterChangedWarning,
}) => {
    const [displays, setDisplays] = useState();
    const [showWarning, setShowWarning] = useState(false);
    useEffect(() => {
        if (savedDisplay) {
            setDisplays(savedDisplay);
        } else setDisplays(DEFAULT_DISPLAYS);
    }, [savedDisplay]);

    const handleChange = (event, displays) => {
        setDisplays(displays);
        onChange(displays);

        let checkWarning =
            filterSelected &&
            JSON.stringify(displays) !== JSON.stringify(savedDisplay);

        setShowWarning(checkWarning);
    };
    const classes = useStylesForButtonGroups();

    useEffect(() => {
        if (showWarning) {
            handleSavedFilterChangedWarning(true);
        }
    }, [showWarning]);

    useEffect(() => {
        if (filterSelected && showWarning) {
            setShowWarning(false);
        }
    }, [filterSelected]);

    return (
        <>
            <ToggleButtonGroup
                value={displays}
                onChange={(event, displays) => handleChange(event, displays)}
                aria-label="text displays"
                className={classes.customStyles}
            >
                <ToggleButton
                    value="Staff"
                    aria-label="left aligned"
                    className={classes.customStyles}
                >
                    Staff
                </ToggleButton>
                <ToggleButton
                    value="Freelancer"
                    aria-label="centered"
                    className={classes.customStyles}
                >
                    Freelance
                </ToggleButton>
                <ToggleButton
                    value="TBD"
                    aria-label="right aligned"
                    className={classes.customStyles}
                >
                    TBD Roles
                </ToggleButton>
            </ToggleButtonGroup>

            {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        </>
    );
};

Display.propTypes = {};

export default Display;
