import PropTypes from "prop-types";
import styled from "styled-components";

import { COLOR_VALUES } from "../config/colors";

const TEXT_VARIANT_STYLES = {
    Display_1: {
        fontFamily: "HelveticaW05Display, sans-serif",
        fontWeight: 410,
        fontSize: "128px",
        lineHeight: "132px",
    },
    Display_2: {
        fontFamily: "HelveticaW05Display, sans-serif",
        fontWeight: 410,
        fontSize: "88px",
        lineHeight: "96px",
    },
    Headline_1: {
        fontFamily: "HelveticaW05Display, sans-serif",
        fontWeight: 450,
        fontSize: "40px",
        lineHeight: "48px",
    },
    Headline_2: {
        fontFamily: "HelveticaW05Display, sans-serif",
        fontWeight: 450,
        fontSize: "30px",
        lineHeight: "40px",
    },
    Headline_3: {
        fontFamily: "HelveticaW05Display, sans-serif",
        fontWeight: 460,
        fontSize: "24px",
        lineHeight: "32px",
    },
    Headline_4: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 480,
        fontSize: "18px",
        lineHeight: "24px",
    },
    Headline_5: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 480,
        fontSize: "14px",
        lineHeight: "20px",
    },
    Body_1_1: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 425,
        fontSize: "16px",
        lineHeight: "24px",
    },
    Body_1_2: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 525,
        fontSize: "16px",
        lineHeight: "24px",
    },
    Body_1_3: {
        fontFamily: "HelveticaNowText, sans-serif",
        //fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
    },
    Body_2_1: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 425,
        fontSize: "13.6px",
        lineHeight: "20px",
    },
    Body_2_2: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 525,
        fontSize: "13.6px",
        lineHeight: "20px",
    },
    Body_2_3: {
        fontFamily: "HelveticaNowText, sans-serif",
        //fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 700,
        fontSize: "13.6px",
        lineHeight: "20px",
    },
    Descriptive_1_1: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 450,
        fontSize: "12px",
        lineHeight: "16px",
    },
    Descriptive_1_2: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 650,
        fontSize: "12px",
        lineHeight: "16px",
    },
    Descriptive_2_1: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 450,
        fontSize: "10px",
        lineHeight: "12px",
    },
    Descriptive_2_2: {
        fontFamily: "HelveticaW05Text, sans-serif",
        fontWeight: 650,
        fontSize: "10px",
        lineHeight: "12px",
    },
};

const TEXT_VARIANTS = Object.keys(TEXT_VARIANT_STYLES);

const StyledText = styled.div((props) => {
    return {
        ...TEXT_VARIANT_STYLES[props.$variant],
        ...(props.$color ? { color: COLOR_VALUES[props.$color] } : {}),
    };
});

const Text = ({ variant, color, ...rest }) => {
    return <StyledText $variant={variant} $color={color} {...rest} />;
};

Text.propTypes = {
    variant: PropTypes.oneOf(TEXT_VARIANTS),
    color: PropTypes.oneOf(Object.keys(COLOR_VALUES)),
};

export default Text;
export { TEXT_VARIANTS, TEXT_VARIANT_STYLES };
