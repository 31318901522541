import { COLOR_VALUES } from "components/design-system/config/colors";

export const moreMenuClassStyle = {
    "&.rc-menu": {
        border: `1px solid ${COLOR_VALUES.Gray_5}`,
        minWidth: "fit-content",
        padding: 0,
        paddingLeft: "16px",
        paddingRight: "8px",
        zIndex: 1070,

        "& li.rc-menu__submenu:hover": {
            backgroundColor: global.config.colors.gray8,
        },
        "& > li": {
            "& li.rc-menu__divider": {
                padding: 0,
            },
            "&.noPadding": {
                padding: 0,
            },
            "&.rc-menu__item--active": {
                backgroundColor: "transparent",
            },
            "&.rc-menu__item--disabled": {
                color: global.config.colors.gray5,
                "& > a[disabled]": {
                    color: global.config.colors.gray5,
                    "& > svg path": {
                        stroke: global.config.colors.gray5,
                    },
                },
                "& > svg path": {
                    stroke: global.config.colors.gray5,
                },
            },
            "& svg": {
                marginRight: 0,
            },
        },
    },
    "& .rc-menu__submenu > .rc-menu__item::after": {
        display: "none",
    },
    "& .rc-menu__submenu:hover .sub_menu_arrow >svg": {
        display: "flex",
    },
    "& .rc-menu__item--hover": {
        backgroundColor: global.config.colors.gray8,
    },
    "& .rc-menu__item": {
        padding: "8px 8px 8px 6px",
        borderBottom: `1px solid ${COLOR_VALUES.Gray_6}`,

        "& > a": {
            fontSize: global.config.fonts.sizes.smedium,
            fontFamily: global.config.fonts.families.regular,
        },
    },
};
