import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import { sendNotification } from "utilities/Notification";

import useAccessGroups from "./useAccessGroups";
import { useState } from "react";

const AccessGroupDeleteForm = ({ accessGroupIdToDelete, onClose }) => {
    const { accessGroups, deleteAccessGroup } = useAccessGroups();
    const [inProgress, setInProgress] = useState(false);

    const currentAccessGroupData = accessGroups.data.find(
        (accessGroup) => accessGroup.id === accessGroupIdToDelete
    );

    if (!currentAccessGroupData) {
        return <div>Could not find access group</div>;
    }

    const modifier = deleteAccessGroup;

    const onSubmit = () => {
        setInProgress(true);
        const allData = { groupId: accessGroupIdToDelete };
        modifier.mutateAsync(allData).then(() => {
            setInProgress(false);
            onClose();
            sendNotification(
                undefined,
                <>
                    <strong> {currentAccessGroupData.name} </strong> have been
                    deleted
                </>
            );
        });
    };
    return (
        <Form onSubmit={onSubmit}>
            <VStack spacing="Three">
                <Text variant="Body_1_1" color="Gray_2">
                    Are you sure you'd like to delete{" "}
                    <strong> {currentAccessGroupData.name} </strong>?
                </Text>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: "Yes, Delete",
                        disabled: inProgress,
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        to: "?action=edit&id=" + accessGroupIdToDelete,
                        disabled: inProgress,
                    }}
                />
            </VStack>
        </Form>
    );
};

export default AccessGroupDeleteForm;
