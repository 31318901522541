import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import GenericSidebar from "./GenericSidebar";

const GenericSidebarPortal = (props) => {
    const contentElRef = useRef(document.createElement("div"));

    useEffect(() => {
        const sidebarEl = document.getElementById("divPageSidebar");
        const contentEl = contentElRef.current;

        sidebarEl.append(contentEl);

        return () => {
            contentEl.remove();
        };
    }, []);

    return createPortal(
        <GenericSidebar {...props} includeTheme={false} />,
        contentElRef.current
    );
};

export default GenericSidebarPortal;
