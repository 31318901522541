import PropTypes from "prop-types";
import {
    StyledInputLayer,
    StyledNoteLayer,
    StyledCopyNextLayer,
    Container,
} from "./Styles";
import { formModes } from "../../../../resources/Enums";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";

const handleHeight = 4;

const Cell = ({
    employee,
    project,
    value,
    onChange,
    onUpdate,
    onHover,
    onMouseDown,
    onAdjustStart,
    onAdjustEnd,
    dragged,
    date,
    maxValue,
    minValue,
    stepValue,
    hasNotes,
    noteText,
    onAddRemoveNote,
    resizeMaxValue,
    onClick,
    typing,
    tabIndex,
    cellKey,
    readOnly,
    userHasNotesAccessToAddEdit,
    userHasNotesAccessToView,
    onUpdateNote,
    onCopyHours,
}) => {

    const [currentValue, setCurrentValue] = useState(null);

    const getCappedValueWithMax = (value, maxVal) =>
        Math.min(Math.max(value, minValue), maxVal);

    const getCalculatedValueWithMax = (height, maxHeight, maxVal) =>
        Math.round(((height + handleHeight) / maxHeight) * maxVal);

    const changeValue = (event, value) => {
        setCurrentValue(barValue(value));
        onChange(event, getCappedValueWithMax(value, maxValue), true);
    }
        
    const changeValueResize = (event, value) =>
        onChange(event, getCappedValueWithMax(value, resizeMaxValue));

    const calculateIncrementValue = (height, maxHeight) => {
        let calculatedValue = getCalculatedValueWithMax(
            height,
            maxHeight,
            resizeMaxValue
        );

        if (calculatedValue >= stepValue) {
            calculatedValue =
                Math.round(calculatedValue / stepValue) * stepValue;

            calculatedValue = getCappedValueWithMax(
                calculatedValue,
                resizeMaxValue
            );
        } else {
            calculatedValue = 0;
        }
        return calculatedValue;
    };
    const handleAdjustStart = (e) => {
        if (!readOnly) {
            onAdjustStart();
        }
    };

    // const handleAdjustEnd = (height, maxHeight) => {
    //     if (!readOnly) {
    //         console.log("RRR", height, maxHeight)
    //         onAdjustEnd(calculateIncrementValue(height, maxHeight));
    //     }
    // };

    const handleAdjustEnd = (event, value) => {
        if (!readOnly) {
            //if (dragged) dragged = false;
            onAdjustEnd(value);
        }
        event.preventDefault();
        return;
    };

    const handleAdjusting = (height, maxHeight) => {
        if (!readOnly) {
            changeValueResize(null, calculateIncrementValue(height, maxHeight));
        }
    };

    const handleClick = (event) => {
        if (!readOnly) {
            onClick(event);
        }
    };

    const handleArrowUp = (event) => {
        if (!readOnly) {
            changeValue(event, Number(value) + 1);
            event.stopPropagation();
        }
    };

    const handleArrowDown = (event) => {
        if (!readOnly) {
            changeValue(event, Number(value) - 1);
            event.stopPropagation();
        }
    };

    const handleMouseEnter = (event) => {
        if (!readOnly ) {
            onHover(event);
        }
    };

    const handleUpdate = (event, currentValue) => {
        if (!readOnly) {
            setCurrentValue(barValue(currentValue));
            onUpdate(event, currentValue);
        }
    };

    const preventHorizontalKeyboardNavigation = (event) => {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
          event.preventDefault();
        }
    }

    const barValue = (v) => {
        const calc = (v * 100) / 40;
        return calc + "%";
    }

    const updateValue = (e, v) => {
        setCurrentValue(barValue(v));
    }

    useEffect(() => {
        setCurrentValue(barValue(value));
    }, []);

    return (
        <>
            <Container
                onMouseEnter={(event) => handleMouseEnter(event)}
                //onMouseMove={handleMouseEnter}
                onMouseDown={onMouseDown}
                onClick={handleClick}
                typing={typing && !dragged}
                dragged={dragged}
                tabIndex={tabIndex}
                id={"cellContainer_" + cellKey}
                $readOnly={readOnly}
            >
                <div id={"cellLoading_" + cellKey} style={{ position: "absolute", top: "4px", left: "0.3125rem",  display: "none", zIndex: "1100"}}
                >
                    <CircularProgress style={{ color: global.config.colors.gray2, height: "14px", width: "14px"}}/>
                </div>
                {/* {!typing && (
                    <Slider
                        sx={{
                            width: "100%",
                            padding: '0px',
                            borderRadius: '0px',
                            position: '',
                            color: '#f7fbff',
                            '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                                height: '100%'
                            },
                            '& .MuiSlider-thumb': {
                                borderRadius: '0px',
                                width: '100%',
                                height: `${value > 0 ? '3px' : '5px'}`,
                                backgroundColor: `${value > 0 ? '#5d91ff' : '#2870bd'}` ,
                                '&:before': {
                                    display: 'none',
                                },
                                '&:after': {
                                    display: 'none',
                                },
                                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible, &:after': {
                                    boxShadow: 'none',
                                },
                            },
                            '& .MuiSlider-rail': {
                                borderRadius: '0px',
                                width: '100%',
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: '#9cceff',
                            },
                            '& .MuiSlider-valueLabel': {
                                fontSize: '10px',
                                padding: 1,
                                right: '80%',
                                top: '17px',
                                borderRadius: '50% 50% 50% 50%',
                                backgroundColor: 'white',
                                color:'black',
                            }
                        }}
                        step={1}
                        max={resizeMaxValue}
                        min={0}
                        size="medium"
                        orientation="vertical"
                        defaultValue={value}
                        valueLabelDisplay="auto"
                        onMouseDown={handleAdjustStart}
                        //onChange={handleOnchangeDragging}
                        onChangeCommitted={handleAdjustEnd}
                        disabled={readOnly}
                        onKeyDown={preventHorizontalKeyboardNavigation}
                        //onMouseEnter={handlePepe2}
                        //value={tempValue}
                    />)
                } */}
                {/* <StyledScale show={dragged} divisions={5} />
                {!typing && (
                    <Resizable
                        value={value}
                        maxValue={resizeMaxValue}
                        onAdjustStart={handleAdjustStart}
                        onAdjustEnd={handleAdjustEnd}
                        onAdjusting={handleAdjusting}
                        dragged={dragged}
                        resize={resize}
                        showFill={showFill}
                        stepValue={stepValue}
                        handleHeight={handleHeight}
                        readOnly={readOnly}
                    />
                )} */}
                <div style={{position: "absolute", bottom: "0px", height: currentValue, width: "100%", backgroundColor: "#E1EEFF"}}>&nbsp;</div>
                <StyledInputLayer
                    value={value}
                    onChange={(event) => changeValue(event, event.target.value)}
                    editable={!readOnly && typing}
                    onUpdate={(event) => handleUpdate(event, event.target.value)}
                    onFocus={(event) => updateValue(event, event.target.value)}
                    tabIndex={tabIndex}
                    projectId={project.jobId}
                /> 
                {!typing && (
                    <>
                        {
                            !readOnly && (
                                <StyledCopyNextLayer onClick={(e) => onCopyHours(e, tabIndex)} />
                                // <StyledButtonsLayer
                                //     onClickArrowUp={handleArrowUp}
                                //     onClickArrowDown={handleArrowDown}
                                //     disableUp={value === maxValue}
                                //     disableDown={!value}
                                //     onUpdate={(event) => handleUpdate(event, event.target.value)}
                                // />
                            )
                        }
                        {(userHasNotesAccessToAddEdit ||
                            userHasNotesAccessToView) &&
                            (value > 0 || hasNotes) && (
                                <StyledNoteLayer
                                    mode={hasNotes ? formModes.DETAIL : userHasNotesAccessToAddEdit && formModes.ADD}
                                    readOnly={!userHasNotesAccessToAddEdit && userHasNotesAccessToView}
                                    date={date}
                                    hasNotes={hasNotes}
                                    noteText={noteText}
                                    onAddRemoveNote={onAddRemoveNote}
                                    employee={employee}
                                    project={project}
                                    cellCount={employee.weeks.length}
                                    tabIndex={tabIndex}
                                    onUpdateNote={onUpdateNote}
                                />
                            )}
                    </>
                )}
            </Container>
        </>
    );
};

Cell.defaultProps = {
    value: 0,
    minValue: 0,
    maxValue: 40,
    stepValue: 1,
    onChange: () => void 0,
    onHover: () => void 0,
    onAdjustStart: () => void 0,
    onAdjustEnd: () => void 0,
    onAddRemoveNote: () => void 0,
    resizeMaxValue: 120,
};

Cell.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onChange: PropTypes.func,
    onHover: PropTypes.func,
    onAdjustStart: PropTypes.func,
    onAdjustEnd: PropTypes.func,
    onAddRemoveNote: PropTypes.func,
    resizeMaxValue: PropTypes.number,
};

export default Cell;
