import { useContext } from "react";

import Loader from "components/UI/Loader";
import Subject from "components/design-system/ui/drawer/Subject";
import DataGroup from "components/design-system/ui/drawer/DataGroup";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import AttributeDataGroup from "components/design-system/ui/drawer/AttributeDataGroup";
import GlobalConfig from "configs/Global";

import { useClientsApi } from "pages/admin/clients/useClients";

import DrawerContext from "context/Drawer/DrawerContext";

const attributesOptions = [
    { label: "Active", value: "Active" },
    { label: "Internal", value: "ExcludeInternalOH" },
    { label: "Admin", value: "IsAdmin" },
    { label: "Pitch", value: "IsPitch" },
    { label: "publish", value: "IsConfidential" },
    { label: "External Agency", value: "IsAgency" },
];

const ClientDrawer = ({ clientId }) => {
    const { useClientById } = useClientsApi();
    const { setShow } = useContext(DrawerContext);

    const handleDrawerClose = () => {
        setShow(false);
    };

    const clientData = useClientById(clientId);
    const clientInfo = clientData?.data;

    if (clientData.isLoading) {
        return <>Loading...</>;
    }

    if (clientData.error) {
        return <>Error</>;
    }

    return (
        <>
            {clientId && clientInfo ? (
                <>
                    <Subject
                        subjectName={clientInfo.Name}
                        codeNumber={
                            clientInfo?.ClientId &&
                            `ID: ${clientInfo?.ClientId}`
                        }
                        showPhoto={false}
                        isMoreIcon={false}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Short Name",
                                value: clientInfo.ShortName,
                            },
                            {
                                label: "Sanitized Name",
                                value: clientInfo.SanitizedName,
                            },
                            {
                                label: "External Code",
                                value: clientInfo.ExtAccountingCode,
                            },
                            {
                                label: "Alternative Code",
                                value: clientInfo.AltAccountingCode,
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Agency",
                                value: clientInfo.LeadAgencyName,
                            },
                            {
                                label: "Family",
                                value: clientInfo.ClientFamily,
                            },
                            {
                                label: "Corporate Entity",
                                value: clientInfo.ClientCorporate,
                            },
                            {
                                label: "Lead Office",
                                value: clientInfo.OfficeName,
                                isHr: true,
                            },
                        ]}
                    />
                    <AttributeDataGroup
                        groupData={[
                            {
                                label: "Attributes",
                                list: attributesOptions
                                    ?.map((attribute) => attribute)
                                    .filter((item) => clientInfo?.[item?.value])
                                    ?.map(
                                        (attributeData) => attributeData?.label
                                    ),
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Address line 1",
                                value: clientInfo?.Address1,
                            },
                            {
                                label: "Address line 2",
                                value: clientInfo?.Address2,
                            },
                            { label: "City", value: clientInfo?.City },
                            { label: "State", value: clientInfo?.State },
                        ]}
                    />
                    <DrawerActions
                        primaryAction={{
                            label: "Manage",
                            leadingIcon: "folder",
                            to: `${GlobalConfig.routes.adminClientsLanding}/${clientId}/${GlobalConfig.routes.adminClientGroups}`,
                        }}
                        tertiaryAction={{
                            type: "button",
                            label: "Edit",
                            leadingIcon: "edit",
                            to: `?action=edit&id=${clientId}`,
                            onClick: handleDrawerClose,
                        }}
                    />
                </>
            ) : (
                <Loader height="5rem" width="5rem" />
            )}
        </>
    );
};

export default ClientDrawer;
