import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import ProjectDetail from "components/UI/ProjectDetail";

export const InteractiveCells = styled(Col)`
    padding: 0;

    &:last-child {
        border-right: none;
    }
`;

export const EmptyColumn = styled(Col)`
    flex: 0 0 8%;
`;

export const IndentColumn = styled(Col)`
    flex: 0 0 4%;
`;

export const MoreMenuContainer = styled.div`
    position: absolute;
    right: 0.313rem;
    top: -17px;
`;

export const Column = styled(Col)`
    padding: 0;
    border-right: ${global.config.border.small};
    border-bottom: ${global.config.border.small};
    text-align: left;
    flex: 0 0 21%;
    max-width: 21%;

    #moreButton {
        height:4.8rem;
        align-items: center;
    }

    &:hover {
        background: ${global.config.colors.gray9};

        #moreButton {
            margin-left: 0px;
            display: flex;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: "center";
            justify-content: "center";
        }
    }

    &:last-child {
        border-right: none;
    }
`;

export const Wrapper = styled(Row)`
    margin: 0;
    
    &:hover #moreButton {
        margin-left: 0px;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: "center";
        justify-content: "center";
    }
`;

export const ClickableWrapper = styled.a`
    display: block;
    padding: 0;
    width: 90%;
    position: absolute;
    cursor: pointer;
`;

export const StyledProjectDetail = styled(ProjectDetail)`
    padding: 0;
`;
