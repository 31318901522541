import PropTypes from "prop-types";

const Calendar = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_411_29445)">
                <path
                    d="M11.4056 1V5.06435"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M6.64111 1V5.06435"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M1.89709 3.0321L16.0425 3.0592L16.103 16.607H1.95766L1.89709 3.0321Z"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M16.1032 7.16443H1.91077"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M11.4055 5.06436V9.12871"
                    stroke={color}
                    strokeMiterlimit="10"
                />
                <path
                    d="M6.64111 5.06436V9.12871"
                    stroke={color}
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_411_29445">
                    <rect
                        width="15"
                        height="16"
                        fill="white"
                        transform="translate(1.5 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Calendar.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

Calendar.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Calendar;
