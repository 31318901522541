import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import GlobalConfig from "configs/Global";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import EmptyState from "components/design-system/tables/EmptyState";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";
import { StyledEmptyState } from "pages/projects/retainers-dashboard/RetainersDashboardNav";

import useSelectorProjects from "pages/projects/project-dashboard/useProjectsDashboard";

const ProjectsDashboardNav = ({ refreshButtonElement = null }) => {
    const { projectId } = useParams();
    const [value, setValue] = useState(projectId);
    const navigate = useNavigate();
    const projectsDashboardPath = GlobalConfig.routes.projectsDashboardLanding;

    const { selectorProjects } = useSelectorProjects();

    const handleOnChange = (id) => {
        setValue(id);
        navigate(`${projectsDashboardPath}/${id}`);
    };

    const projectIdStored = JSON.parse(localStorage.getItem("storedProjectId"));

    useEffect(() => {
        if (
            !selectorProjects?.isLoading &&
            selectorProjects?.data?.length > 0 &&
            (!value || !projectIdStored)
        ) {
            navigate(
                `${projectsDashboardPath}/${selectorProjects?.data[0].Id}`
            );
        }
    }, [selectorProjects]);

    useEffect(() => {
        setValue(projectIdStored);
    }, [projectIdStored]);

    const projectsOptions = getDropdownOptionsNoDefaultVal(
        selectorProjects?.data
    );

    if (selectorProjects.isError) {
        return <>Error</>;
    }

    const selectorProjectsLength = selectorProjects?.data?.length;

    return (
        <VStack>
            {selectorProjectsLength > 0 && !selectorProjects.isLoading && (
                <HStack align="center">
                    <DropdownSingleSelectWithoutSearch
                        className="dashboard-selector"
                        isSmallSize={true}
                        options={projectsOptions}
                        value={value}
                        onChange={handleOnChange}
                        disabled={
                            selectorProjects.isLoading ||
                            projectsOptions?.length === 0
                        }
                    />
                    {refreshButtonElement}
                </HStack>
            )}

            {selectorProjectsLength > 0
                ? !value && (
                      <EmptyState message="There are no content to show. Start by selecting project." />
                  )
                : !selectorProjects.isLoading && (
                      <StyledEmptyState
                          className="dashboard-selector"
                          message="You don't have any assigned project"
                      />
                  )}
        </VStack>
    );
};

export default ProjectsDashboardNav;
