import { Outlet, useParams } from "react-router-dom";

import GlobalConfig from "configs/Global";
import PageWrapper from "pages/PageWrapper";

import { useClientsApi } from "pages/admin/clients/useClients";

const ClientsLayout = () => {
    const { clientId } = useParams();
    const { useClientById } = useClientsApi();

    const clientData = useClientById(clientId);

    return (
        <>
            <PageWrapper
                title={clientData?.data?.Name ?? "Error"}
                menuOptions={[
                    {
                        children: "Admin",
                        to: GlobalConfig.routes.adminLandingPage,
                    },
                    {
                        children: "Clients",
                        to: GlobalConfig.routes.adminClientsLanding,
                    },
                ]}
                tabs={[
                    {
                        label: "Groups",
                        to: GlobalConfig.routes.adminClientGroups,
                    },
                    {
                        label: "Contacts and Cards",
                        to: GlobalConfig.routes.adminClientContactsAndCards,
                    },
                ]}
                loading={clientData?.isLoading}
                customAuthPath={GlobalConfig.routes.adminClientsLanding}
            >
                <Outlet />
            </PageWrapper>
        </>
    );
};

export default ClientsLayout;
