import { useContext } from "react";
import { useParams } from "react-router-dom";
import env from "react-dotenv";

import VStack from "components/design-system/layout/VStack";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import { sendNotification } from "utilities/Notification";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";
import ContactCard from "components/design-system/ui/drawer/ContactCard";

import { useProjectByIdDetails } from "pages/projects/project-dashboard/single/useProjectsDashboardNotes";
import useFilters from "pages/admin/projects/useFilters";
import useRetainersDashboardUserDetails from "pages/projects/retainers-dashboard/single/useRetainersDashboardUserDetails";
import { useProjectLeadAssignment } from "pages/projects/project-dashboard/single/useProjectsDashboardDetailsData";
import usePageActionPermission from "hooks/Access/usePageActionPermission";
import SessionContext from "context/app/SessionContext";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";
import { useLeadProducerEmp } from "pages/projects/project-dashboard/single/useProjectsDashboardDetails";

const ModalCTA = ({ onClose, hasPermission }) => {
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: "Confirm",
                disabled: !hasPermission,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
        />
    );
};
function transformAgencyDataToFormData(projectId, projectsData = {}) {
    return {
        producerId: projectsData.EmployeeId,
        ProjectId: projectId,
        StartDate: new Date().toJSON(),
        IsProjectLead: true,
    };
}

function transformFormDataToAgencyData(
    CurrentEndDate,
    formData = {},
    leadProducerData = {}
) {
    const newProjectsData = { ...leadProducerData };

    newProjectsData.EmployeeId = formData.producerId;
    newProjectsData.ProjectId = formData.ProjectId;
    newProjectsData.StartDate = formData.StartDate;
    newProjectsData.EndDate = CurrentEndDate;
    newProjectsData.IsProjectLead = true;

    return newProjectsData;
}

const ProjectDashboardDetailsDataLeadProducerForm = ({
    projectIdToAddEditLead,
    leadProducerId,
    onClose,
}) => {
    const { projectId } = useParams();
    const { leadProducers } = useFilters();

    const session = useContext(SessionContext);
    const { projectByIdDetails } = useProjectByIdDetails();
    const { useUserById } = useRetainersDashboardUserDetails();
    const userDetails = useUserById(leadProducerId);
    const { createAssignment, useLeadAssignment } = useProjectLeadAssignment();
    const leadProducerAssignment = useLeadAssignment(projectId);
    const canUserEdit = leadProducerId
        ? session.legacyId === leadProducerId
        : true;
    const hasPermission = usePageActionPermission(
        "AC_PROJECT_EDIT_LEAD_PRODUCER",
        global.config.routes.projectsDashboardLanding
    );
    const { leadProducerEmpIds } = useLeadProducerEmp();

    const leadProducerOptions = !leadProducers?.isLoading &&
        !leadProducerEmpIds.isLoading &&
        leadProducerEmpIds?.data?.length > 0
        ? getDropdownOptionsNoDefaultVal(leadProducers?.data.filter(f => leadProducerEmpIds.data.includes(f.id)), "id", "fullName")
        : [];

    if (leadProducerAssignment.isLoading) {
        return <div>Loading...</div>;
    }

    if (leadProducers.isError || leadProducerAssignment.isError || leadProducerEmpIds.isError) {
        return <div>Error</div>;
    }

    const isEditing = typeof leadProducerId !== "undefined";
    const modifier = createAssignment;

    const currentLeadProducerData = leadProducerAssignment?.data?.find(
        (leadProducer) => leadProducer.EmployeeId === leadProducerId
    );

    if (isEditing && !currentLeadProducerData) {
        return <div>Could not find lead Producer</div>;
    }

    const onSubmit = (formData) => {
        const leadProducerData = transformFormDataToAgencyData(
            projectByIdDetails?.data?.CurrentEndDate,
            formData,
            currentLeadProducerData
        );

        modifier.mutateAsync(leadProducerData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={"Lead Producer"}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformAgencyDataToFormData(
                projectId,
                currentLeadProducerData
            )}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    {isEditing && (
                        <ContactCard
                            contactCardLabel="Current Lead Producer"
                            contactCardLabelVariant="Body_2_1"
                            photoUrl={
                                env.BESTWORK_REACT_ASSET_DOMAIN +
                                "/" +
                                userDetails?.data?.photoURL
                            }
                            name={userDetails?.data?.fullName}
                            jobPost={userDetails?.data?.currentTitle}
                            department={userDetails?.data?.currentDepartment}
                            location={userDetails?.data?.currentOffice}
                        />
                    )}
                    <Dropdown
                        name="producerId"
                        validations={{
                            required: true,
                        }}
                        placeholder={"Select Lead Producer "}
                        disabled={!(hasPermission && canUserEdit) || leadProducers.isLoading || leadProducerEmpIds.isLoading}
                        label={
                            isEditing
                                ? "Updated Lead Producer"
                                : "Lead Producer for Project"
                        }
                        options={leadProducerOptions}
                    />
                </VStack>
                <ModalCTA
                    projectIdToAddEditLead={projectIdToAddEditLead}
                    onClose={onClose}
                    leadProducerId={leadProducerId}
                    isLoading={modifier.isLoading}
                    hasPermission={hasPermission && canUserEdit}
                />
            </VStack>
        </Form>
    );
};

export default ProjectDashboardDetailsDataLeadProducerForm;
