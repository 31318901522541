import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";

import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import TableFilters from "components/design-system/tables/TableFilters";
import HStack from "components/design-system/layout/HStack";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import EmployeeDrawerNew from "components/Allocations/Grid/Employee/EmployeeDrawerNew";

import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import useManageUserTimeOff, {
    useManageUsersAsApprover,
} from "pages/timesheet/manage-time-off/useManageUserTimeOff";
import SessionContext from "context/app/SessionContext";
import ManageTimeOffContext from "context/ManageTimeOff/ManageTimeOffContext";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import DrawerContext from "context/Drawer/DrawerContext";
import Loading from "components/UI/Loading";
import { sendNotification } from "utilities/Notification";
import styled from "styled-components";

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`;

const getDurationTootip = (value) => {
    let tooltipFirstDay = "";
    if (value.firstDayOutMorning && !value.firstDayOutAfternoon) {
        tooltipFirstDay = "Morning Out";
    } else if (value.firstDayOutAfternoon && !value.firstDayOutMorning) {
        tooltipFirstDay = "Afternoon Out";
    }

    const isOneDay = value.firstDayOut === value.lastDayOut;
    const startDatePrefix = isOneDay || tooltipFirstDay === ""
        ? ""
        : "Start Date: ";

    let tooltipLastDay = "";
    if (!isOneDay) {
        if (value.lastDayOutMorning && !value.lastDayOutAfternoon) {
            tooltipLastDay = "End Date: Morning Out";
        } else if (value.lastDayOutAfternoon && !value.lastDayOutMorning) {
            tooltipLastDay = "End Date: Afternoon Out";
        }
    }
    const lineBreak = tooltipFirstDay && tooltipLastDay ? "\n" : "";
    return `${startDatePrefix}${tooltipFirstDay}${lineBreak}${tooltipLastDay}`;
};

export const getStatusLabel = (value) => {
    if (value.pending && value.deparmentApproved) {
        return "Pending HR Approval";
    }
    return value.status;
};

export const getIcon = (value) => {
    if (value === "Approved") {
        return "success";
    } else if (value === "Rejected") {
        return "warning";
    } else return null;
};

export const getIconColor = (value) => {
    if (value === "Approved") {
        return "Green";
    } else if (value === "Rejected") {
        return "Orange_1";
    } else return null;
};

const sortBy = [
    {
        id: "startDate",
        desc: true,
    },
];

const FiltersConfig = [
    {
        name: "employeeFullName",
        columnAccessor: "employeeFullName",
        isDropdownControl: true,
        dropdownFilterLabel: "Employee",
        defaultValue: "All",
    },
    {
        name: "firstDayOut",
        columnAccessor: "firstDayOut",
        isSegmentedControl: true,
        filterLabel: "Start Date",
        defaultValue: "Upcoming",
        options: [
            { value: "All", label: "All" },
            { value: "Upcoming", label: "Upcoming" },
            { value: "Past", label: "Past" },
        ],
    },
    {
        name: "status",
        columnAccessor: "status",
        isSegmentedControl: true,
        filterLabel: "Status",
        defaultValue: "Pending",
        options: [
            {
                value: "All",
                label: "All",
            },
            {
                value: "Pending",
                label: "Pending",
            },
            {
                value: "Approved",
                label: "Approved",
            },
            {
                value: "Rejected",
                label: "Rejected",
            },
        ],
    },
];

let columns = [];

const ManageTimeOffTable = () => {
    const session = useContext(SessionContext);
    
    const { useManageUserTimeOffApi, approveTimeOff } = useManageUserTimeOff();
    const userTimeOffRequests = useManageUserTimeOffApi(session.legacyId);

    const { setRejectActionFromMenu,
        setIsManageViewRequest,
        upcomingPendingTimeOffRequestCount } = useContext(ManageTimeOffContext);

    const { useManageUserAsApproverApi } = useManageUsersAsApprover();

    const manageUsers = useManageUserAsApproverApi(session.legacyId);

    const [filters, setFilters] = useState();
    const [filtersConfigVal, setFiltersConfigVal] = useState(FiltersConfig);
    const currentDate = new Date().getTime();

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const firstDayOutIndex = newObj.findIndex(
            (f) => f.name === "firstDayOut"
        );
        newObj[firstDayOutIndex].disabled =
            filters?.find((f) => f.id === "status")?.value === "Pending" &&
            filters?.find((f) => f.id === "firstDayOut")?.value === "Upcoming";

        setFiltersConfigVal(newObj);
    }, [filters]);

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const manageUserIndex = newObj.findIndex(
            (f) => f.name === "employeeFullName"
        );
        newObj[manageUserIndex].options = !userTimeOffRequests.isLoading
            ? getDropdownOptions(
                getEmployees(userTimeOffRequests.data),
                "userId",
                "employeeFullName",
                "All",
                "View All"
            )
            : [];

        !userTimeOffRequests.isLoading &&
            newObj[manageUserIndex].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    }, [userTimeOffRequests]);

    const [drawerUserId, setDrawerUserId] = useState();
    const customGroupConsumer = useContext(CustomGroupContext);
    const { setShow } = useContext(DrawerContext);

    if (userTimeOffRequests.isError) {
        return <>Error</>;
    }

    const modifier = approveTimeOff;

    const approveRequestOnClick = (data) => {
        const allData = {
            timeOffRequestId: data?.id,
        };

        modifier.mutateAsync(allData).then(() => {
            sendNotification(
                undefined,
                <>
                    Time off request for {data?.employeeFullName} has been
                    approved
                </>
            );
        });
    };

    const handleClick = () => {
        setIsManageViewRequest(true);
    };

    columns = [
        {
            headCellProps: {
                text: "Employee",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: cell.value ?? "-",
                actionProps: {
                    onClick: () => {
                        setDrawerUserId(cell.row.original.userId);
                        setShow(true);
                    },
                },
            }),
            filter: (rows, _columnIds, filterValue) => {
                if (filterValue === "All") {
                    return rows;
                } else {
                    return rows?.filter((row) => {
                        return filterValue === row.original.userId;
                    });
                }
            },
            accessor: "employeeFullName",
            disableSortBy: true,
        },
        {
            headCellProps: {
                text: "Start Date",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            }),
            filter: (rows, _columnIds, filterValue) => {
                if (filterValue === "All") {
                    return rows;
                } else {
                    return rows?.filter((row) => {
                        let createdDate = new Date(row?.values.firstDayOut);
                        return (
                            (filterValue === "Upcoming" &&
                                (currentDate < createdDate.getTime() ||
                                    new Date().toDateString() ===
                                    createdDate.toDateString())) ||
                            (filterValue === "Past" &&
                                currentDate > createdDate.getTime() &&
                                new Date().toDateString() !==
                                createdDate.toDateString())
                        );
                    });
                }
            },
            accessor: "firstDayOut",
        },
        {
            headCellProps: {
                text: "End Date",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            }),
            accessor: "lastDayOut",
            disableSortBy: true,
        },
        {
            headCellProps: {
                text: "Duration",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: cell.value ?? "-",
                tooltip: getDurationTootip(cell.row.original),
                tooltipEnterDelay: 0,
                placement: "bottom-start",
            }),
            accessor: "amountOfDaysOut",
            disableSortBy: true,
        },
        {
            headCellProps: {
                text: "Reason",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: cell.value ?? "-",
            }),
            accessor: "reasonName",
            disableSortBy: true,
        },
        {
            headCellProps: {
                text: "Date Submitted",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            }),
            accessor: "createdDate",
            disableSortBy: true,
        },
        {
            headCellProps: {
                text: "Status",
                width: getCellWidthInPercent(178.29),
            },
            getBodyCellProps: (cell) => ({
                icon: getIcon(cell.value),
                iconColor: getIconColor(cell.value),
                text: getStatusLabel(cell.row.original),
                padding:
                    cell.value === "Approved" || cell.value === "Rejected"
                        ? "8px 12px"
                        : "8px 36px",
            }),
            filter: (rows, _columnIds, filterValue) => {
                if (filterValue === "All") {
                    return rows;
                } else {
                    return rows.filter((row) => {
                        return filterValue === row.values.status;
                    });
                }
            },
            accessor: "status",
            disableSortBy: true,
        },
        {
            headCellProps: {
                width: getCellWidthInPercent(32),
            },
            accessor: "approveCTA",
            disableSortBy: true,
            getBodyCellProps: (cell) => ({
                shownByRowHover: true,
                actionIcon: showHideIconOnhoverRule(cell.row.original)
                    ? "success"
                    : null,
                actionProps: showHideIconOnhoverRule(cell.row.original) && {
                    type: "submit",
                    onClick: () => approveRequestOnClick(cell.row.original),
                    "aria-label": "Approve",
                },
            }),
        },
        {
            headCellProps: {
                width: getCellWidthInPercent(32),
            },
            accessor: "Edit",
            disableSortBy: true,
            getBodyCellProps: (cell) => ({
                shownByRowHover: true,
                moreMenuActionProps: showHideIconOnhoverRule(cell.row.original)
                    && [
                        {
                            text: "Reject",
                            actionProps: {
                                onClick: () => setRejectActionFromMenu(true),
                                to: `?action=rejectRequest&id=${cell.row.original.id}`,
                                replace: true,
                                "aria-label": "Reject",
                            },
                        },
                        {
                            text: "View Request Details",
                            actionProps: {
                                onClick: () => setRejectActionFromMenu(false),
                                to: `?action=viewRequest&id=${cell.row.original.id}`,
                                replace: true,
                                "aria-label": "View Request",
                            },
                        },
                    ],
                actionIcon: getIconOnHover(cell.row.original),
                actionProps: !showHideIconOnhoverRule(cell.row.original) && {
                    onClick: () => handleClick(),
                    to: `?action=viewRequest&id=${cell.row.original.id}`,
                    replace: true,
                    "aria-label": "View Request",
                },
            }),
        },
    ];

    const getIconOnHover = (value) => {
        return !value.canApproveReject
            || (value.canApproveReject && new Date(value?.lastDayOut) <= currentDate)
            ? "file" : null;
    };

    const showHideIconOnhoverRule = (value) => {
        return (value.canApproveReject && new Date(value?.lastDayOut) >= currentDate);
    };

    const getEmployees = (requests) => {
        let employees = requests.filter((value, index, self) =>
            index === self.findIndex(x => (
                x.userId === value.userId
            ))
        );

        return employees.map(x => (
            {
                userId: x.userId,
                employeeFullName: x.employeeFullName
            }
        ));
    };

    return (
        <>
            <>
                <MasterDrawer stickyAction={true}>
                    <CustomGroupContext.Provider value={customGroupConsumer}>
                        <EmployeeDrawerNew userId={drawerUserId} />
                    </CustomGroupContext.Provider>
                </MasterDrawer>
            </>
            {userTimeOffRequests.isRefetching
                ?
                <LoadingWrapper>
                    <Loading text="Loading..." />
                </LoadingWrapper>
                :
                (<VStack>
                    <HStack>
                        <TableFilters
                            filtersConfig={filtersConfigVal}
                            initialFilterData={[
                                {
                                    name: "status",
                                    value:
                                        upcomingPendingTimeOffRequestCount > 0
                                            ? "Pending"
                                            : "All",
                                },

                                {
                                    name: "firstDayOut",
                                    value: "Upcoming",
                                },
                            ]}
                            autoUpdateFilterData={
                                filters?.find((f) => f.id === "status")?.value ===
                                "Pending" &&
                                filters?.find((f) => f.id === "firstDayOut")
                                    ?.value !== "Upcoming" && [
                                    {
                                        value: "Upcoming",
                                        name: "firstDayOut",
                                    },
                                ]
                            }
                            onChange={setFilters}
                        />
                    </HStack>
                    <Table
                        showTableBorder={false}
                        columns={columns}
                        data={userTimeOffRequests.data}
                        sortBy={sortBy}
                        filters={filters}
                    />
                </VStack>
                )}
        </>
    );
};

export default ManageTimeOffTable;
