import { Outlet, useParams } from "react-router-dom";

import GlobalConfig from "configs/Global";
import PageWrapper from "pages/PageWrapper";

import { useProjectsApi } from "pages/admin/projects/useProjects";

const ProjectsLayout = () => {
    const { projectId } = useParams();
    const { useProjectById } = useProjectsApi();

    const projectData = useProjectById(projectId);

    return (
        <>
            <PageWrapper
                title={projectData?.data?.Name ?? "Error"}
                menuOptions={[
                    {
                        children: "Admin",
                        to: GlobalConfig.routes.adminLandingPage,
                    },
                    {
                        children: "Projects",
                        to: GlobalConfig.routes.adminProjectsLanding,
                    },
                ]}
                loading={projectData?.isLoading}
                customAuthPath={GlobalConfig.routes.adminProjectsLanding}
            >
                <Outlet />
            </PageWrapper>
        </>
    );
};

export default ProjectsLayout;
