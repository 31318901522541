import { useContext, useState } from "react";
import Button from "components/UI/Button";
import EmployeeGridSelectionContext from "context/Allocations/EmployeeGridSelectionContext";
import Checkbox from "components/design-system/controls/checkbox/Checkbox";
import Tooltip from "components/design-system/ui/Tooltip";
import HStack from "components/design-system/layout/HStack";
import Context from "context/Allocations/Context";
import { useAllocations } from "utilities/API/Allocations";

const SelectRows = () => {
    const employeeGridSelection = useContext(EmployeeGridSelectionContext);
    const checkboxTooltip = employeeGridSelection.allSelected ? "Deselect All Employees" : "Select All Employees";
    const buttonTooltip = employeeGridSelection.selectionIsEnabled ? "" : "Select Employees";
    const { query } = useContext(Context);
    const { getAllocationsUsersIds } = useAllocations();
    const [currentQuery, setCurrentQuery] = useState(null);

    return (
        <HStack align="center">
            <Checkbox
                title={checkboxTooltip}
                isSmallSize={true}
                checked={employeeGridSelection.allSelected}
                indeterminate={
                    !employeeGridSelection.allSelected &&
                    employeeGridSelection.selectedEmployeeIDs.length > 0
                }
                onChange={(e) => {
                    const isNowChecked = e.target.checked;
                    if (isNowChecked) {
                        if (query != currentQuery){
                            (setCurrentQuery(query))().then(() => {
                                    getAllocationsUsersIds(query).then((idsResponse) => {
                                        employeeGridSelection.updateSelectableIDs(idsResponse);
                                    }).then(() => {
                                        employeeGridSelection.setSelectionIsEnabled(true);
                                        employeeGridSelection.selectAllEmployeeIDs();
                                    })
                                });
                        } else {
                            employeeGridSelection.setSelectionIsEnabled(true);
                            employeeGridSelection.selectAllEmployeeIDs();
                        }
                    } else {
                        employeeGridSelection.setSelectionIsEnabled(false);
                        employeeGridSelection.deselectAllEmployeeIDs();
                    }
                }}
            />
            <Tooltip text={buttonTooltip}>
                <span>
                    <Button
                        disableRipple={true}
                        onClick={() => {
                            const newSelectionState = !employeeGridSelection.selectionIsEnabled;
                            employeeGridSelection.setSelectionIsEnabled(newSelectionState);
                            if (!newSelectionState) {
                                employeeGridSelection.deselectAllEmployeeIDs();
                            } else {
                                if (query != currentQuery){
                                    (setCurrentQuery(query))().then(() => 
                                    getAllocationsUsersIds(query).then((idsResponse) => {
                                        employeeGridSelection.updateSelectableIDs(idsResponse);
                                    }));
                                }
                            }
                        }}
                        type={employeeGridSelection.selectionIsEnabled ? "link" : "secondary"}
                    >
                        {employeeGridSelection.selectionIsEnabled ? "Cancel" : "Select"}
                    </Button>
                </span>
            </Tooltip>
        </HStack>
    );
};

SelectRows.propTypes = {};
SelectRows.defaultProps = {};

export default SelectRows;
