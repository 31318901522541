import { useEffect, useState, useRef } from "react";
import moment from "moment";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Transform from "utilities/Transform";
import Text from "components/UI/Text";
import Dropdown from "components/UI/Dropdown";
import Link from "components/UI/Link";
import { Subtitle, DDLWrapper } from "../Filter-Styles";
import { useClientFamilies } from "utilities/API/ClientFamilies";
import { useClients } from "utilities/API/Clients";
import { useRetainers } from "utilities/API/Retainers";
import { useProjects } from "utilities/API/Projects";
import Loader from "components/UI/Loader";
import WarningBox from "./WarningBox";

const showLoaders = false;

const warningMessage =
    "You have adjusted filters. Saved Filters have been cleared.";
const ClientsAndProjects = ({
    onChange,
    savedSelection,
    filterSelected,
    handleSavedFilterChangedWarning,
}) => {
    const [clientFamilies, setClientFamilies] = useState([]);
    const [clients, setClients] = useState([]);
    const [retainers, setRetainers] = useState([]);
    const [projects, setProjects] = useState([]);

    const [clientFamiliesUpdate, setClientFamiliesUpdate] = useState(false);
    const [clientsUpdate, setClientsUpdate] = useState(false);
    const [retainerUpdate, setRetainerUpdate] = useState(false);
    const [projectUpdate, setProjectUpdate] = useState(false);

    const [customSelection, setCustomSelection] = useState(false);
    const [noneSelection, setNoneSelection] = useState(false);

    const [selectedClientFamily, setSelectedClientFamily] = useState([]);
    const [selectedClients, setSelectedClient] = useState([]);
    const [selectedRetainer, setSelectedRetainer] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);

    const [loadingClientFamilies, setLoadingClientFamilies] = useState(false);
    const [loadingClients, setLoadingClients] = useState(false);
    const [loadingRetainers, setLoadingRetainers] = useState(false);
    const [loadingProjects, setLoadingProjects] = useState(false);

    const [showAlertClientFamilies, setShowAlertClientFamilies] =
        useState(false);
    const [showAlertClients, setShowAlertClients] = useState(false);
    const [showAlertRetainers, setShowAlertRetainers] = useState(false);
    const [showAlertProjects, setShowAlertProjects] = useState(false);

    const [
        displaySearchCloseIconClientFamilies,
        setDisplaySearchCloseIconClientFamilies,
    ] = useState(false);
    const [displaySearchCloseIconClient, setDisplaySearchCloseIconClient] =
        useState(false);
    const [displaySearchCloseIconRetainer, setDisplaySearchCloseIconRetainer] =
        useState(false);
    const [displaySearchCloseIconProject, setDisplaySearchCloseIconProject] =
        useState(false);

    const { getClientFamiliesFilter } = useClientFamilies();
    const { getClientsFilter } = useClients();
    const { getRetainersFilters } = useRetainers();
    const { getProjectsFilter } = useProjects();

    useEffect(() => {
        async function load() {
            if (
                savedSelection &&
                (savedSelection?.clientFamily?.length > 0 ||
                    savedSelection?.client?.length > 0 ||
                    savedSelection?.retainer ||
                    savedSelection?.project)
            ) {
                initSavedSelection(savedSelection);
            } else {
                init();
            }
        }
        load();
    }, [savedSelection]);

    const init = async () => {
        loadClientFamilies([]);
        setNoneSelection(true);
        setCustomSelection(false);
    };

    const initSavedSelection = (selection) => {
        setNoneSelection(false);
        setCustomSelection(false);
        loadClientFamilies(selection.clientFamily ?? []);
    };

    const loadClientFamilies = async (selectedClientFamilies) => {
        const data =
            !clientFamilies || clientFamilies.length === 0
                ? await getClientFamilies()
                : [...clientFamilies];
        loadDDLClientFamilies(data, selectedClientFamilies);
    };

    const getClientFamilies = async () => {
        setLoadingClientFamilies(true);
        return getClientFamiliesFilter().then((payload) => {
            setLoadingClientFamilies(false);
            return Transform.dropdown(payload, "Name", "ClientFamilyId");
        });
    };

    const loadDDLClientFamilies = async (data, clientFamilies) => {
        if (data) {
            const clientFamiliesCopy = [...data].map((item) => ({
                ...item,
                checked: !!clientFamilies?.find(
                    (x) => String(x.value) === String(item.value)
                ),
            }));
            updateClientFamiliesSelection(clientFamilies);
            setClientFamilies(clientFamiliesCopy);
        }
    };

    const updateClientFamiliesSelection = (clientFamilies) => {
        if (
            clientFamilies?.length === 0 &&
            selectedClientFamily?.length === 0
        ) {
            setClientFamiliesUpdate(!clientFamiliesUpdate);
        } else {
            setSelectedClientFamily(clientFamilies);
        }
    };

    useEffect(() => {
        let clients = [];
        if (!noneSelection) {
            clients = customSelection
                ? selectedClients
                : savedSelection?.client;
        }
        loadClients(selectedClientFamily, clients ?? [], true);
    }, [selectedClientFamily, clientFamiliesUpdate]);

    const handleClientFamilyChange = async (_currentNode, selectedNodes) => {
        setCustomSelection(true);
        setNoneSelection(false);
        setDisplaySearchCloseIconClientFamilies(false);
        const selection = getSelectedNodes(selectedNodes);
        loadDDLClientFamilies([...clientFamilies], selection);

        if (filterSelected) {
            const show = areArraysEqual(
                savedSelection?.clientFamily,
                selection
            );
            setShowAlertClientFamilies(!show);
        } else {
            setShowAlertClientFamilies(false);
        }
    };

    const loadClients = async (
        selectedClientFamilies,
        selectedClients,
        reload
    ) => {
        reload && setClients([]);

        const data = reload
            ? await getClients(selectedClientFamilies)
            : [...clients];
        loadDDLClients(data, selectedClients);
    };

    const getClients = async (clientFamilies) => {
        setLoadingClients(true);
        return getClientsFilter({
            clientFamilyIds:
                clientFamilies?.map((x) => x.value).toString() ?? "",
            active: true,
            onlyAdmin: false,
            clientCorpEntityId: null
        }).then((payload) => {
            setLoadingClients(false);
            return Transform.dropdown(payload, "Name", "ClientId");
        });
    };

    const loadDDLClients = async (data, clients) => {
        if (data) {
            const clientsCopy = [...data].map((item) => ({
                ...item,
                checked: !!clients?.find(
                    (x) => String(x.value) === String(item.value)
                ),
            }));
            updateClientsSelection(clientsCopy);
            setClients(clientsCopy);
        }
    };

    const updateClientsSelection = (clientsCopy) => {
        const selected = clientsCopy
            ?.filter((x) => x.checked)
            .map((x) => {
                return { value: x?.value, label: x.label };
            });

        if (selected.length === 0 && selectedClients.length === 0) {
            setClientsUpdate(!clientsUpdate);
        } else {
            setSelectedClient(selected);
        }
    };

    useEffect(() => {
        let retainer = null;
        if (!noneSelection) {
            retainer = customSelection
                ? selectedRetainer
                : savedSelection?.retainer;
        }
        loadRetainers(selectedClientFamily, selectedClients, retainer, true);
    }, [selectedClients, clientsUpdate]);

    const handleClientChange = async (_currentNode, selectedNodes) => {
        setCustomSelection(true);
        setNoneSelection(false);
        setDisplaySearchCloseIconClient(false);
        const selection = getSelectedNodes(selectedNodes) ?? [];
        loadClients(selectedClientFamily, selection, false);

        if (filterSelected) {
            const show = areArraysEqual(savedSelection?.client, selection);
            setShowAlertClients(!show);
        } else {
            setShowAlertClients(false);
        }
    };

    const loadRetainers = async (
        selectedClientFamilies,
        selectedClients,
        selectedRetainer,
        reload
    ) => {
        reload && setRetainers([]);
        const data = reload
            ? await getRetainers(selectedClientFamilies, selectedClients)
            : [...retainers];
        loadDDLRetainer(data, selectedRetainer);
    };

    const getRetainers = async (selectedClientFamily, selectedClient) => {
        setLoadingRetainers(true);
        let data = [];
        const selectedFamiliesIds =
            selectedClientFamily?.map((x) => x.value).toString() ?? "";
        const selectedClientsIds =
            selectedClient?.map((x) => x.value).toString() ?? "";
        data = await getRetainersFilters(
            selectedFamiliesIds,
            selectedClientsIds
        );
        setLoadingRetainers(false);
        return Transform.dropdown(data, "RetainerName", "RetainerId");
    };

    const loadDDLRetainer = async (data, retainer) => {
        if (data) {
            const retainersCopy = [...data].map((item) => ({
                ...item,
                checked: item.value === retainer?.value,
            }));
            updateRetainerSelection(retainersCopy);
            setRetainers(retainersCopy);
        }
    };

    const updateRetainerSelection = (retainersCopy) => {
        const selected = retainersCopy.find((x) => x.checked);
        if (
            (!selected && !selectedRetainer) ||
            selected?.value === selectedRetainer?.value
        ) {
            setRetainerUpdate(!retainerUpdate);
        } else {
            setSelectedRetainer(
                selected
                    ? { value: selected.value, label: selected.label }
                    : null
            );
        }
    };

    useEffect(() => {
        let project = null;
        if (!noneSelection) {
            project = customSelection
                ? selectedProject
                : savedSelection?.project;
        }

        loadProjects(
            selectedClientFamily,
            selectedClients,
            selectedRetainer,
            project,
            true
        );
    }, [selectedRetainer, retainerUpdate]);

    const handleRetainerChange = async (currentNode, selectedNodes) => {
        setCustomSelection(true);
        setNoneSelection(false);
        setDisplaySearchCloseIconRetainer(false);
        const selection = getSelectedNode(selectedNodes);
        loadRetainers(selectedClientFamily, selectedClients, selection, false);

        if (filterSelected) {
            setShowAlertRetainers(
                savedSelection?.retainer?.value !== selection?.value
            );
        } else {
            setShowAlertRetainers(false);
        }
    };

    const loadProjects = async (
        clientFamily,
        client,
        retainer,
        project,
        reload
    ) => {
        reload && setProjects([]);
        const data = reload
            ? await getProjects(clientFamily, client, retainer)
            : [...projects];
        const sundayOfCurrentWeek = moment().startOf('week');
        const newData = data.filter(f => moment(f.data.CurrentEndDate).isAfter(f.data.ProjStartDate) && moment(f.data.CurrentEndDate).isAfter(sundayOfCurrentWeek));                
        loadDLLProjects(newData, project);
    };

    const getProjects = async (clientFamilies, client, retainer) => {
        setLoadingProjects(true);
        let data = [];
        const selectedClientsIds = client?.map((x) => x.value).toString() ?? "";
        const selectedRetainerId = retainer ? String(retainer.value) : "";
        const selectedFamiliesIds =
            clientFamilies?.map((x) => x.value).toString() ?? "";
        data = await getProjectsFilter(
            selectedFamiliesIds,
            selectedClientsIds,
            selectedRetainerId
        );
        setLoadingProjects(false);
        return Transform.projects(data);
    };

    const loadDLLProjects = async (data, project) => {
        if (data) {
            const projectsCopy = [...data].map((item) => ({
                ...item,
                checked: String(item.value) === String(project?.value),
            }));
            updateProjectSelection(projectsCopy);
            setProjects(projectsCopy);
        }
    };

    const updateProjectSelection = (projectsCopy) => {
        const selected = projectsCopy.find((x) => x.checked);
        if (
            (!selected && !selectedProject) ||
            selected?.value === selectedProject?.value
        ) {
            setProjectUpdate(!projectUpdate);
        } else {
            setSelectedProject(
                selected
                    ? { value: selected.value, label: selected.label }
                    : null
            );
        }
    };

    useEffect(() => {
        onChange(
            selectedClientFamily,
            selectedClients,
            selectedRetainer,
            selectedProject
        );
    }, [selectedProject, projectUpdate]);

    const handleProjectChange = async (currentNode, selectedNodes) => {
        setCustomSelection(true);
        setNoneSelection(false);
        setDisplaySearchCloseIconProject(false);
        const selection = getSelectedNode(selectedNodes);
        loadProjects(null, null, null, selection, false);

        if (filterSelected) {
            setShowAlertProjects(
                savedSelection?.project?.value !== selection?.value
            );
        } else {
            setShowAlertProjects(false);
        }
    };

    const areArraysEqual = (array, arrayToCompare) => {
        if (
            array &&
            arrayToCompare &&
            array?.length === arrayToCompare?.length
        ) {
            return array.every((x) => {
                if (
                    arrayToCompare.any(
                        (y) => String(y.value) === String(x.value)
                    )
                ) {
                    return true;
                }
                return false;
            });
        }
        return false;
    };

    const getSelectedNode = (selectedNodes) => {
        const item = selectedNodes.length > 0 ? selectedNodes[0] : null;
        const selection = item
            ? { value: item?.value, label: item.label }
            : null;
        return selection;
    };

    const getSelectedNodes = (selectedNodes) => {
        const selection = selectedNodes?.map((x) => {
            return { value: x?.value, label: x.label };
        });
        return selection;
    };

    // ClientFamilies, Client, Retainer, Project close button in search field
    const handleDropdownBlur = (dropdownName) => () => {
        switch (dropdownName) {
            case "clientAndFamilies":
                setDisplaySearchCloseIconClientFamilies(false);
                break;
            case "client":
                setDisplaySearchCloseIconClient(false);
                break;
            case "retainer":
                setDisplaySearchCloseIconRetainer(false);
                break;
            case "project":
                setDisplaySearchCloseIconProject(false);
                break;
        }
    };

    const handleDropdownFocus = (dropdownName) => () => {
        switch (dropdownName) {
            case "clientAndFamilies":
                setDisplaySearchCloseIconClientFamilies(true);
                break;
            case "client":
                setDisplaySearchCloseIconClient(true);
                break;
            case "retainer":
                let retainerDiv = document.getElementById(
                    "divFilterRightColumn"
                );
                if (retainerDiv) {
                    retainerDiv.scrollTop = retainerDiv.scrollHeight;
                }
                setDisplaySearchCloseIconRetainer(true);
                break;
            case "project":
                let projectDiv = document.getElementById(
                    "divFilterRightColumn"
                );
                if (projectDiv) {
                    projectDiv.scrollTop = projectDiv.scrollHeight;
                }
                setDisplaySearchCloseIconProject(true);
                break;
        }
    };

    const handleDropdownClearSearch = (e) => {
        e.preventDefault();
        let element = document.querySelector(".search");
        if (element) {
            element.value = "";
        }
    };
    // ClientFamilies, Client, Retainer, Project close button in search field end

    const onClearClientFamilyTree = async () => {
        if (filterSelected) {
            if (savedSelection?.clientFamily?.length > 0) {
                setShowAlertClientFamilies(true);
            }
            if (savedSelection?.client?.length > 0) {
                setShowAlertClients(true);
            }
            if (savedSelection?.retainer?.value) {
                setShowAlertRetainers(true);
            }
            if (savedSelection?.project?.value) {
                setShowAlertProjects(true);
            }
        } else {
            setShowAlertClientFamilies(false);
        }

        init();
        onChange(null, null, null);
    };

    const StyledLoader = () => {
        return <Loader height="1rem" width="1rem" />;
    };

    useEffect(() => {
        if (
            showAlertClientFamilies ||
            showAlertClients ||
            showAlertRetainers ||
            showAlertProjects
        ) {
            handleSavedFilterChangedWarning(true);
        }
    }, [
        showAlertClientFamilies,
        showAlertClients,
        showAlertRetainers,
        showAlertProjects,
    ]);

    useEffect(() => {
        if (filterSelected && showAlertClientFamilies) {
            setShowAlertClientFamilies(false);
        }
        if (filterSelected && showAlertClients) {
            setShowAlertClients(false);
        }
        if (filterSelected && showAlertRetainers) {
            setShowAlertRetainers(false);
        }
        if (filterSelected && showAlertProjects) {
            setShowAlertProjects(false);
        }
    }, [filterSelected]);

    const loadingClassName = "dropdown-custom-content--ClientsAndProjects";
    return (
        <>
            <Subtitle style={{ paddingBottom: "0px" }}>
                <Text variant="medium">Clients &amp; Projects</Text>
                <Link
                    onClick={(e) => onClearClientFamilyTree()}
                    style={{ marginLeft: "1rem", paddingTop: "4px" }}
                    disabled={
                        !selectedClientFamily &&
                        !selectedClients &&
                        !selectedRetainer &&
                        !selectedProject
                    }
                >
                    Clear
                </Link>
            </Subtitle>
            <Subtitle style={{ paddingTop: "40px" }}>
                <Text variant="smedium">Client Family</Text>
            </Subtitle>
            <DDLWrapper>
                <Dropdown
                    data={clientFamilies}
                    placeholder="Search for a Client Family"
                    searchLabel="Search for a Client Family"
                    mode="multiSelect"
                    inlineSearch={true}
                    showPartial={true}
                    displaySearchCloseIcon={
                        displaySearchCloseIconClientFamilies
                    }
                    onChange={(currentNode, selectedNodes) =>
                        handleClientFamilyChange(currentNode, selectedNodes)
                    }
                    onBlur={handleDropdownBlur("clientAndFamilies")}
                    onFocus={handleDropdownFocus("clientAndFamilies")}
                    handleOnClickClearSearchFieldData={
                        handleDropdownClearSearch
                    }
                    className={`dropdown-custom-content`}
                    disabled={!(clientFamilies?.length !== 0)}
                />
                {showLoaders && loadingClientFamilies && <StyledLoader />}
            </DDLWrapper>
            {showAlertClientFamilies && (
                <WarningBox>{warningMessage}</WarningBox>
            )}
            <Subtitle>
                <Text variant="smedium">Client</Text>
            </Subtitle>
            <DDLWrapper>
                <Dropdown
                    data={clients}
                    placeholder="Search for a Client"
                    searchLabel="Search for a Client"
                    mode="multiSelect"
                    inlineSearch={true}
                    displaySearchCloseIcon={displaySearchCloseIconClient}
                    onBlur={handleDropdownBlur("client")}
                    onFocus={handleDropdownFocus("client")}
                    handleOnClickClearSearchFieldData={
                        handleDropdownClearSearch
                    }
                    onChange={(currentNode, selectedNodes) =>
                        handleClientChange(currentNode, selectedNodes)
                    }
                    className={`dropdown-custom-content`}
                    disabled={!(clients?.length !== 0)}
                />
                {showLoaders && loadingClients && <StyledLoader />}
            </DDLWrapper>
            {showAlertClients && <WarningBox>{warningMessage}</WarningBox>}
            <Subtitle>
                <Text variant="smedium">Retainer</Text>
            </Subtitle>
            <DDLWrapper>
                <Dropdown
                    data={retainers}
                    placeholder="Search for a Retainer"
                    searchLabel="Search for a Retainer"
                    mode="simpleSelect"
                    inlineSearch={true}
                    displaySearchCloseIcon={displaySearchCloseIconRetainer}
                    onBlur={handleDropdownBlur("retainer")}
                    onFocus={handleDropdownFocus("retainer")}
                    handleOnClickClearSearchFieldData={
                        handleDropdownClearSearch
                    }
                    onChange={(currentNode, selectedNodes) =>
                        handleRetainerChange(currentNode, selectedNodes)
                    }
                    className={`dropdown-custom-content  ${
                        selectedRetainer &&
                        !loadingRetainers &&
                        loadingClassName
                    }`}
                    disabled={!(retainers?.length !== 0)}
                />
                {showLoaders && loadingRetainers && <StyledLoader />}
            </DDLWrapper>
            {showAlertRetainers && <WarningBox>{warningMessage}</WarningBox>}
            <Subtitle>
                <Text variant="smedium">Project</Text>
            </Subtitle>
            <DDLWrapper
                style={{
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    gap: 0,
                    paddingBottom: "200px",
                }}
            >
                <Dropdown
                    data={projects}
                    placeholder="Search for a Project"
                    searchLabel="Search for a Project"
                    mode="simpleSelect"
                    inlineSearch={true}
                    displaySearchCloseIcon={displaySearchCloseIconProject}
                    onBlur={handleDropdownBlur("project")}
                    onFocus={handleDropdownFocus("project")}
                    handleOnClickClearSearchFieldData={
                        handleDropdownClearSearch
                    }
                    onChange={(currentNode, selectedNodes) =>
                        handleProjectChange(currentNode, selectedNodes)
                    }
                    className={`dropdown-custom-content  ${
                        selectedProject && !loadingProjects && loadingClassName
                    }`}
                    disabled={!(projects?.length !== 0)}
                />
                {showLoaders && loadingProjects && <StyledLoader />}
                {showAlertProjects && (
                    <WarningBox style={{ flexGrow: 1 }}>
                        {warningMessage}
                    </WarningBox>
                )}
            </DDLWrapper>
        </>
    );
};
ClientsAndProjects.propTypes = {};

export default ClientsAndProjects;
