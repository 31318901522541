import styled from "styled-components";

import ToggleButton from "components/design-system/controls/toggleButton/ToggleButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import { COLOR_VALUES } from "components/design-system/config/colors";
import RichText from "components/design-system/ui/RichText";

import { sendNotification } from "utilities/Notification";
import useAccessGroupApi from "pages/admin/access-groups/single/useAccessGroup";
import useAccessGroupPageAccessManagePermissionsApi from "pages/admin/access-groups/single/useAccessGroupPageAccessManagePermissions";
import { useState } from "react";

const StyledTitleText = styled(Text)`
    padding-top: 16px;
    padding-bottom: 16px;
`;

const ListWrapper = styled(HStack)`
    padding: 16px 0 0;
    gap: 10px;
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};

    label {
        align-items: inherit;
    }
`;

const ListMainBlock = styled(VStack)`
    border-bottom: 1px solid ${COLOR_VALUES.Gray_4};
`;

const Wrapper = styled(VStack)`
    width: 100%;
    min-height: 436px;
    background: ${COLOR_VALUES.White};
    border-top: 1px solid ${COLOR_VALUES.Gray_4};

    ${ListMainBlock} ${ListWrapper}:last-child {
        border-bottom: none;
    }

    ${ListMainBlock}:last-child {
        border-bottom: none;

        ${ListMainBlock}:last-child ${ListWrapper} {
            border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
        }
    }
`;

const ListContent = styled(VStack)`
    flex-grow: 1;
`;

const ListContentCard = ({ name, description }) => {
    return (
        <ListContent spacing="One">
            <Text variant="Headline_4" color="Gray_1">
                {name}
            </Text>
            {description && (
                <RichText
                    variant="Descriptive_1_1"
                    color="Gray_4"
                    children={description.replaceAll("\\n", "\n")}
                />
            )}
        </ListContent>
    );
};

const ToggleParent = ({
    parentToggleEnabled,
    parentToggleCode,
    handleOnChange,
}) => {
    const [inProgressParentToggle, setInProgressParentToggle] = useState(false);
    return (
        <ToggleButton
            disabled={inProgressParentToggle}
            checked={parentToggleEnabled}
            onChange={() =>
                handleOnChange(
                    parentToggleCode,
                    parentToggleEnabled,
                    setInProgressParentToggle
                )
            }
            label={["Off", "On"]}
        />
    );
};

const ToggleChild = ({
    parentToggleEnabled,
    childToggleEnabled,
    childToggleCode,
    handleOnChange,
}) => {
    const [inProgressChildToggle, setInProgressChildToggle] = useState(false);

    return (
        <ToggleButton
            disabled={inProgressChildToggle || !parentToggleEnabled}
            checked={childToggleEnabled}
            onChange={() =>
                handleOnChange(
                    childToggleCode,
                    childToggleEnabled,
                    setInProgressChildToggle
                )
            }
            label={["Off", "On"]}
        />
    );
};

const AccessGroupPageAccessManagePermissions = ({ groupId, pageId }) => {
    const { useAccessGroup } = useAccessGroupApi();
    const groupData = useAccessGroup(groupId);

    const {
        useAccessGroupPageAccessPageActionDetails,
        createAccessGroupPageAccessPageAction,
        deleteAccessGroupPageAccessPageAction,
    } = useAccessGroupPageAccessManagePermissionsApi();

    const pageActionDetails = useAccessGroupPageAccessPageActionDetails({
        groupId: groupId,
        pageId: pageId,
    });

    if (pageActionDetails.isLoading) {
        return <>Loading...</>;
    }

    if (pageActionDetails.isError) {
        return <>Error</>;
    }

    const handleOnChange = (code, enabled, setInProgress) => {
        setInProgress(true);
        const modifier = enabled
            ? deleteAccessGroupPageAccessPageAction
            : createAccessGroupPageAccessPageAction;

        const allData = { code: code, groupId: groupId, pageId: pageId };

        modifier.mutateAsync(allData).then(() => {
            setInProgress(false);
            sendNotification(
                undefined,
                <>
                    Action Permissions for{" "}
                    <strong> {groupData.data.name} </strong> have been updated
                </>
            );
        });
    };

    return (
        <>
            {pageActionDetails.data.length === 0 ? (
                <>No data found</>
            ) : (
                <Wrapper>
                    {pageActionDetails.data
                        .filter((a) => a.parentCode === null)
                        .map((parentData, parentIndex) => (
                            <ListMainBlock
                                spacing="Zero"
                                key={"block1" + parentIndex}
                            >
                                <StyledTitleText
                                    variant="Headline_2"
                                    color="Gray_1"
                                >
                                    {parentData.header}
                                </StyledTitleText>

                                <ListWrapper>
                                    <ListContentCard {...parentData} />
                                    <ToggleParent
                                        parentToggleEnabled={parentData.enabled}
                                        parentToggleCode={parentData.code}
                                        handleOnChange={handleOnChange}
                                    />
                                </ListWrapper>

                                {pageActionDetails.data
                                    .filter(
                                        (element, index, arr) =>
                                            parentData.code ===
                                                element.parentCode &&
                                            arr.findIndex(
                                                (d) =>
                                                    element.header == d.header
                                            ) === index
                                    )
                                    .map(
                                        (
                                            childDataParent,
                                            childDataParentIndex
                                        ) => (
                                            <ListMainBlock
                                                spacing="Zero"
                                                key={
                                                    "block2" +
                                                    childDataParentIndex
                                                }
                                                style={{ marginLeft: "64px" }}
                                            >
                                                <StyledTitleText
                                                    variant="Headline_2"
                                                    color="Gray_1"
                                                >
                                                    {childDataParent.header}
                                                </StyledTitleText>
                                                {pageActionDetails.data
                                                    .filter(
                                                        (d) =>
                                                            d.header ===
                                                            childDataParent.header
                                                    )
                                                    .map(
                                                        (
                                                            childData,
                                                            childIndex
                                                        ) => (
                                                            <ListWrapper
                                                                key={
                                                                    "block3" +
                                                                    childIndex
                                                                }
                                                            >
                                                                <ListContentCard
                                                                    {...childData}
                                                                />

                                                                <ToggleChild
                                                                    parentToggleEnabled={
                                                                        parentData.enabled
                                                                    }
                                                                    childToggleEnabled={
                                                                        childData.enabled
                                                                    }
                                                                    childToggleCode={
                                                                        childData.code
                                                                    }
                                                                    handleOnChange={
                                                                        handleOnChange
                                                                    }
                                                                />
                                                            </ListWrapper>
                                                        )
                                                    )}
                                            </ListMainBlock>
                                        )
                                    )}
                            </ListMainBlock>
                        ))}
                </Wrapper>
            )}
        </>
    );
};

export default AccessGroupPageAccessManagePermissions;
