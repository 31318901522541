import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

export const useProjectLeadAssignment = () => {
    const { getDataAsync, postData, putData } = useApi();
    const queryClient = useQueryClient();
    const getProjectLeadAssignmentByIdApiCall = useCallback(
        async (projectId) => {
            const response = await getDataAsync("getLeadAssignmentByProject", {
                urlParams: [{ name: "$projectId", value: projectId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createAssignmentApiCall = useCallback(
        (body) => {
            return postData("assignments", {
                body,
            });
        },
        [postData]
    );

    const updateAssignmentApiCall = useCallback(
        (body) => {
            return putData("assignments", {
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("leadAssignmentList");
            },
        };
    }, [queryClient]);

    const createAssignment = useMutation(
        createAssignmentApiCall,
        mutationOptions
    );

    const updateAssignment = useMutation(
        updateAssignmentApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useLeadAssignment = (projectId) =>
            useQuery(["leadAssignmentList", projectId], () =>
                getProjectLeadAssignmentByIdApiCall(projectId)
            );
        return {
            useLeadAssignment,
            createAssignment,
            updateAssignment,
        };
    }, [
        getProjectLeadAssignmentByIdApiCall,
        createAssignment,
        updateAssignment,
    ]);
};

export const useClientCampaign = () => {
    const { getDataAsync } = useApi();
    const getCampaignsByIdApiCall = useCallback(
        async (campaignId) => {
            const response = await getDataAsync("getCampaignsById", {
                urlParams: [{ name: "$campaignId", value: campaignId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useCampaignById = (campaignId) =>
            useQuery(["campaginById", campaignId], () =>
                getCampaignsByIdApiCall(campaignId)
            );

        return {
            useCampaignById,
        };
    }, [getCampaignsByIdApiCall]);
};

export const useClientOffice = () => {
    const { getDataAsync } = useApi();
    const getOfficeByIdApiCall = useCallback(
        async ({ officeId }) => {
            if (officeId) {
                const response = await getDataAsync("getOfficeById", {
                    urlParams: [{ name: "$id", value: officeId }],
                });
                return response.status != 204 ? response.json() : null;
            } else {
                return null;
            }
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useGetClientOfficeById = (dataObj) =>
            useQuery(["getOfficeByIdApiCall", dataObj], () =>
                getOfficeByIdApiCall(dataObj)
            );

        return {
            useGetClientOfficeById,
        };
    }, [getOfficeByIdApiCall]);
};
