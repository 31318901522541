import PropTypes from "prop-types";

const More = ({ size, color }) => {
    return (
        <svg
            width="2"
            height={size}
            viewBox={"0 0 2 " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="1"
                cy="11"
                r="1"
                transform="rotate(180 1 11)"
                fill={color}
            />
            <circle
                cx="1"
                cy="6"
                r="1"
                transform="rotate(180 1 6)"
                fill={color}
            />
            <circle
                cx="1"
                cy="1"
                r="1"
                transform="rotate(180 1 1)"
                fill={color}
            />
        </svg>
    );
};

More.defaultProps = {
    size: "12",
    color: global.config.colors.black,
};

More.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default More;
