import { getMinutesToMilliseconds } from "common-methods/commonMethods";
import { TIME_INTERVAL_IN_MIN } from "context/Projects/ProjectsDashboardLayoutContext";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useApi } from "utilities/API/Helpers/useApi";

export const useProjectsNoteTypes = () => {
    const { getDataAsync } = useApi();

    const getProjectsNoteTypesApiCall = useCallback(async () => {
        const response = await getDataAsync("projectsNoteTypes");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const projectsNoteTypes = useQuery(
        "projectsNoteTypesList",
        getProjectsNoteTypesApiCall
    );

    return useMemo(() => {
        return {
            projectsNoteTypes,
        };
    }, [projectsNoteTypes]);
};

export const useProjectByIdDetails = () => {
    const { getDataAsync } = useApi();
    const { projectId } = useParams();

    const getProjectByIdDetailsApiCall = useCallback(async () => {        
        const response = await getDataAsync("getProjectById", {
            urlParams: [{ name: "$projectId", value: projectId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const projectByIdDetails = useQuery(
        "projectsByIdData",
        getProjectByIdDetailsApiCall
    );

    return useMemo(() => {
        return {
            projectByIdDetails,
        };
    }, [projectByIdDetails]);
};

const useProjectsDashboardNotes = () => {
    const { getDataAsync, postData, putData, deleteData } = useApi();
    const { projectId } = useParams();    
    const queryClient = useQueryClient();
    const refetchIntervalValue = getMinutesToMilliseconds(TIME_INTERVAL_IN_MIN); // 5min i.e. 300000ms

    const getProjectsApiCall = useCallback(
        async ({ projectIdFE = "", noteTypeId = "" }) => {
            const response = await getDataAsync("projectsDashboardNotesList", {
                urlParams: [
                    { name: "$projectId", value: projectIdFE },
                    { name: "$noteTypeId", value: noteTypeId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createProjectDashboardNoteApiCall = useCallback(
        ({ body }) => {            
            return postData("postProjectsDashboardNote", {
                urlParams: [{ name: "$projectId", value: projectId }],
                body,
            });
        },
        [postData]
    );

    const updateProjectDashboardNoteApiCall = useCallback(
        ({ noteId, body }) => {            
            return putData("updateProjectsDashboardNote", {
                urlParams: [
                    {
                        name: "$projectId",
                        value: projectId,
                    },
                    {
                        name: "$noteId",
                        value: noteId,
                    },
                ],
                body,
            });
        },
        [putData]
    );

    const deleteProjectDashboardNoteApiCall = useCallback(
        ({ noteId }) => {
            return deleteData("updateProjectsDashboardNote", {
                urlParams: [
                    {
                        name: "$projectId",
                        value: projectId,
                    },
                    {
                        name: "$noteId",
                        value: noteId,
                    },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("projectsNotesList");
            },
        };
    }, [queryClient]);

    const createProjectDashboardNote = useMutation(
        createProjectDashboardNoteApiCall,
        mutationOptions
    );
    const updateProjectDashboardNote = useMutation(
        updateProjectDashboardNoteApiCall,
        mutationOptions
    );
    const deleteProjectDashboardNote = useMutation(
        deleteProjectDashboardNoteApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useProjectsNotesList = (dataObj) =>
            useQuery(
                ["projectsNotesList", dataObj],
                () => getProjectsApiCall(dataObj),
                {
                    refetchInterval: refetchIntervalValue,
                }
            );

        return {
            useProjectsNotesList,
            createProjectDashboardNote,
            updateProjectDashboardNote,
            deleteProjectDashboardNote,
        };
    }, [
        getProjectsApiCall,
        createProjectDashboardNote,
        updateProjectDashboardNote,
        deleteProjectDashboardNote,
    ]);
};

export default useProjectsDashboardNotes;
