import React, { useState } from "react";

import {
    AccordionButton,
    AccordionWrapper,
    AccordionContent,
    AccordionTitle,
    AccordionIcon,
} from "components/design-system/controls/accordion/AccordionStyledComponent";

const Accordion = ({ accordionData = [], ...rest }) => {
    const [show, setShow] = useState(-1);
    const handleClick = (index) => {
        setShow(index === show ? -1 : index);
    };

    return (
        <>
            {accordionData.map((data, index) => (
                <AccordionButton
                    key={index}
                    onClick={() => handleClick(index)}
                    rest={rest}
                    type="button"
                >
                    <AccordionWrapper
                        hasNoBorder={data?.hasNoBorder}
                        hasNoPadding={data?.hasNoPadding}
                    >
                        <AccordionContent justify="space-between">
                            {data.title && (
                                <AccordionTitle
                                    variant="Headline_5"
                                    show={show === index}
                                >
                                    {data.title}
                                </AccordionTitle>
                            )}

                            <AccordionIcon
                                name={
                                    show === index ? "caret-up" : "caret-down"
                                }
                                show={show === index}
                            />
                        </AccordionContent>

                        {show === index && <>{data.children}</>}
                    </AccordionWrapper>
                </AccordionButton>
            ))}
        </>
    );
};

export default Accordion;
