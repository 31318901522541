export const sumValue = (currentValue, addition) => {
    let newValue = currentValue;
    if (addition && addition > 0) {
        newValue = newValue + addition;
    }
    return newValue;
};

export const getTransactionId = (transactionId) => {
    let value = undefined;
    if (transactionId) {
        const split = transactionId.split("-");
        const last = split[split.length - 1];
        value = last;
    }
    return value;
};
