import { useContext } from "react";
import PageWrapper from "../../PageWrapper";
import AbsenseReasonsTable from "./AbsenseReasonsTable";
import { ServerSidePaginationContextProvider } from "context/TablePagination/ServerSidePaginationContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const AbsenseReasons = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const now = new Date();
    insightsTrace("VIEW", "AdminAbsenseReasonsPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    return (
        <PageWrapper
            title="Absense Reasons"
            menuOptions={[{ children: "Admin", to: "/admin" }]}
        >
            <ServerSidePaginationContextProvider>
            <AbsenseReasonsTable />
            </ServerSidePaginationContextProvider>
        </PageWrapper>
    );
};

export default AbsenseReasons;
