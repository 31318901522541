import React from "react";

import Checkbox from "components/design-system/controls/checkbox/Checkbox";
import ToggleButton from "components/design-system/controls/toggleButton/ToggleButton";

const DropdownListVariant = React.forwardRef(
    ({ isVariantToggle, label, ...rest }, ref) => {
        return (
            <>
                {isVariantToggle ? (
                    <ToggleButton label={[label, label]} {...rest} ref={ref} />
                ) : (
                    <Checkbox label={label} {...rest} ref={ref} />
                )}
            </>
        );
    }
);

export default DropdownListVariant;
