import React, { useContext, useEffect, useRef, useState } from "react";
import { add, differenceInCalendarDays } from "date-fns";
import moment from "moment";
import Row from "pages/timesheet/timesheet-grid/Row";
import { getDateFnsFormatDate } from "components/design-system/controls/week-navigator/WeekNavigatorMain";
import EmptyState from "components/design-system/tables/EmptyState";
import Snackbar from "components/design-system/ui/Snackbar";
import VStack from "components/design-system/layout/VStack";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import TimesheetGridContext from "context/Timesheet/TimesheetGridContext";
import SessionContext from "context/app/SessionContext";
import {
    TimesheetProjectRowExpansionContextActions,
    TimesheetProjectRowExpansionContextValue,
} from "context/Timesheet/TimesheetProjectRowExpansionContext";
import useTimesheetGrid from "pages/timesheet/timesheet-grid/useTimesheetGrid";
import useTimesheetGridTimeOff from "./useTimesheetGridTimeOff";
import useTimeOffApi from "../time-off/useTimeOff";
import Loading from "components/UI/Loading";
import styled from "styled-components";

const MemoizedEmployeeRow = React.memo(Row);

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`

const PTO_LABELS = ["PTO", "FTO"];
export const TIME_OFF_ROW_ID = "TimeOff";
const NON_CLIENT_ROW_ID = "NonClientRelatedActivities";
const TOOLTIP_READONLY_HOURS_ALLOWED = 'To edit your request, go"\n"to your Time Off page';
const TOOLTIP_READONLY_HOURS_NOT_ALLOWED = 'To edit your request, contact\ntalentsupport@rga.com';

const Grid = () => {
    const session = useContext(SessionContext);

    const { useAssignments, useDepartmentsAssignments, useHoursTimeEntries } =
        useTimesheetGrid();

    const {
        isWeekChange,
        setIsWeekChange,
        selectedTimesheetStartDate,
        pendingTimesheetCalendarDate,
    } = useContext(WeekNavigatorContext);

    const { openAllProjectRows } = useContext(
        TimesheetProjectRowExpansionContextActions
    );

    const { allProjectRowsAreOpen } = useContext(
        TimesheetProjectRowExpansionContextValue
    );

    const {
        useGetEditableReasonsForTimesheet,
        useGetPendingHRTimeOffByDate,
        useGetApprovedTimeOffByDate,
        useGetUsedAllotmentByYear
    } = useTimeOffApi();

    const {
        clientAndProjectDetails,
        setClientAndProjectDetails,
        projectHours,
        setProjectHours,
        weekColumnDayHoursLimitExceed,
        setDisableSubmitTimesheetButton,
        setPendingHRTimeOffInWeek,
        setApprovedTimeOffInWeek,
        setHolidays,
        setDisableGridUpdate,
        setUsedAllotment
    } = useContext(TimesheetGridContext);

    const { useHolidaysTimesheet, useOooReasonsTimesheet } = useTimesheetGridTimeOff();

    const [showSnackbar, setShowSnackbar] = useState(false);

    let fromDateVal = null;
    let untilDateVal = null;
    fromDateVal = selectedTimesheetStartDate
        ? selectedTimesheetStartDate
        : pendingTimesheetCalendarDate;

    fromDateVal = !fromDateVal
        ? null
        : `${fromDateVal.year}-${fromDateVal.month}-${fromDateVal.day}`;

    const weekEndDate = !fromDateVal
        ? null
        : selectedTimesheetStartDate.add({ days: 6 });

    untilDateVal = !fromDateVal
        ? null
        : `${weekEndDate.year}-${weekEndDate.month}-${weekEndDate.day}`;

    const requestData = {
        employeeId: session.legacyId,
        fromDate: fromDateVal,
    };
    const requestDepartmentsAssignmentsData = {
        departmentId: session.currentDepartmentId,
        officeId: session.currentOfficeId,
        agencyId: session.agencyId,
        fromDate: fromDateVal,
    };
    const requestHolidaysData = {
        officeId: session.currentLegacyOfficeId,
        fromDate: fromDateVal,
    };

    const reasonsEditablesForUser = useGetEditableReasonsForTimesheet({
        employeeId: session.legacyId,
        year: weekEndDate.year ?? new Date().getFullYear()
    });

    const userData = useAssignments(requestData);
    const activitiesData = useDepartmentsAssignments(
        requestDepartmentsAssignmentsData
    );
    const hoursData = useHoursTimeEntries(requestData);
    const holidaysData = useHolidaysTimesheet(requestHolidaysData);
    const oooReasonsData = useOooReasonsTimesheet({ fromDate: fromDateVal, agencyId: session.agencyId });
    const pendingsHRTimeOffs = useGetPendingHRTimeOffByDate({ from: fromDateVal, until: untilDateVal });
    const approvedTimeOffs = useGetApprovedTimeOffByDate({ from: fromDateVal, until: untilDateVal });
    const usedAllotment = useGetUsedAllotmentByYear({ userId: session.legacyId, year: weekEndDate.year ?? new Date().getFullYear() });

    const flagRef = useRef(true);

    useEffect(() => {
        if (
            userData?.isFetching
            || activitiesData?.isFetching
            || hoursData?.isFetching
            || oooReasonsData?.isFetching
            || holidaysData?.isFetching
            || usedAllotment?.isFetching
        ) {
            flagRef.current = true;
            setDisableSubmitTimesheetButton(true);
        }
    }, [userData, activitiesData, hoursData, oooReasonsData, holidaysData]);

    useEffect(() => {

        const disabledGrid = hoursData?.isFetching
            || pendingsHRTimeOffs?.isFetching
            || approvedTimeOffs?.isFetching
            || usedAllotment?.isFetching

        setDisableGridUpdate(disabledGrid);

    }, [hoursData, pendingsHRTimeOffs, approvedTimeOffs, setDisableGridUpdate]);

    const startDate = getDateFnsFormatDate(
        selectedTimesheetStartDate?.year,
        selectedTimesheetStartDate?.month,
        selectedTimesheetStartDate?.day
    );

    const getInitialHoursArray = (nullHoursArr) => {
        // initial value for a week
        for (let i = 0; i < 7; i++) {
            nullHoursArr.push({
                timeDay: add(startDate, { days: i }),
            });
        }
    };

    // If we have at least one holiday for the week, we create the project holiday
    const setTimeOffHolidaysData = (holidaysDetails, timeOffDetails) => {
        if (holidaysDetails?.Holidays.length > 0) {
            const holidayProject = {
                JobCode: holidaysDetails.JobCode,
                Id: holidaysDetails.JobId,
                HalfDay: false,
                Name: holidaysDetails.Name,
                isHoliday: true
            }
            timeOffDetails.push(holidayProject);
        }
    };

    const expandAllRowsInitially = () => {
        if (!allProjectRowsAreOpen) {
            openAllProjectRows();
        }
    };

    const updateWeeksDataForHolidays = (holidays, newHolidaysHourArr) => {
        holidays?.map((holiday) => {
            const indexOfHolidayCell = differenceInCalendarDays(
                new Date(holiday.Date),
                startDate
            );

            const isWeekendCell = indexOfHolidayCell === 5 || indexOfHolidayCell === 6;

            let holidayTimeSpan = 0;

            // if we have a timeSpan it means that the user has modified the holiday
            if (holiday.timeSpan !== undefined && holiday.timeSpan !== null) {
                holidayTimeSpan = holiday.timeSpan;
            }
            else {
                holidayTimeSpan = holiday.HalfDay ? 4 : 8;
            }

            const newHoliday = {
                ...holiday,
                timeSpan: isWeekendCell ? null : holidayTimeSpan,
                isWeekendCellDisable: isWeekendCell,
            };

            newHolidaysHourArr[indexOfHolidayCell] = newHoliday;
        });
    };

    const updateModifiedHolidays = (holidays, hours) => {
        if (holidays) {
            let newHolidays = [];
            holidays?.Holidays?.forEach((holiday) => {
                if (hours.length > 0) {
                    const holidayDay = moment(holiday.Date).format("YYYY-MM-DD");

                    // we look for the same day and job id to see if the user has modified the holiday
                    const modifiedHoliday = hours.find(x => {
                        const day = moment(x.timeDay).format("YYYY-MM-DD");
                        return x.jobId === holidays.JobId && (moment(day).isSame(holidayDay));
                    });

                    if (modifiedHoliday) {
                        holiday.timeSpan = modifiedHoliday.timeSpan;
                    }
                }
                newHolidays.push(holiday);
            });
            return newHolidays
        }
    };

    const updateWeeksDataForProjectAndTimeOff = (
        hoursDetails,
        newHourArr,
        isTimeOffDetails
    ) => {
        hoursDetails.map((hour) => {
            const hourCellIndex = differenceInCalendarDays(
                new Date(hour.timeDay),
                startDate
            );

            const isWeekendCell = hourCellIndex === 5 || hourCellIndex === 6;

            if (isWeekendCell && isTimeOffDetails) {
                newHourArr[hourCellIndex] = {
                    ...hour,
                    timeSpan: null,
                    isWeekendCellDisable: true,
                };
            } else {
                newHourArr[hourCellIndex] = {
                    ...hour,
                    isWeekendCellDisable: false,
                };
            }
        });
    };

    const getPtoFtoReasonsList = () => {
        return oooReasonsData?.data?.filter((f) =>
            PTO_LABELS.some((s) => s === f.LegacyJobCode)
        );
    };

    const getUniqueOOOReasons = () => {
        return oooReasonsData?.data;
    };

    const isFutureWeek = () => {
        let rv = false;
        if (startDate) {
            let selectDate = moment(startDate).format("YYYY-MM-DD");
            let currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
            rv = moment(selectDate).isAfter(currentWeek);
        }
        return rv;
    };

    const isOneMonthOldWeek = () => {
        let rv = false;
        if (startDate) {
            let currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
            var oneMonthBack = moment(currentWeek).subtract(4, 'W').format("YYYY-MM-DD");
            let initialWeekDateOfSelected = moment(startDate).startOf("isoWeek").format("YYYY-MM-DD");
            rv = moment(oneMonthBack).isAfter(initialWeekDateOfSelected);
        }
        return rv;
    };

    useEffect(() => {
        let projects = [];

        if (
            !userData?.isFetching &&
            !activitiesData?.isFetching &&
            !hoursData?.isFetching &&
            !oooReasonsData?.isFetching &&
            !holidaysData?.isFetching &&
            !reasonsEditablesForUser?.isLoading &&
            !pendingsHRTimeOffs?.isLoading &&
            !approvedTimeOffs?.isLoading &&
            !usedAllotment?.isLoading &&
            flagRef.current
        ) {
            setDisableSubmitTimesheetButton(false);
            expandAllRowsInitially();
            setShowSnackbar(false);
            setPendingHRTimeOffInWeek(pendingsHRTimeOffs?.data);
            setApprovedTimeOffInWeek(approvedTimeOffs?.data);
            setUsedAllotment(usedAllotment?.data);
            setHolidays(holidaysData?.data);

            const data = userData.data;
            const clientIds = [...new Set(data.map((d) => d.ClientId))];
            let projectIds = [];

            clientIds.forEach((d) => {
                const filteredData = data.filter((f) => f.ClientId === d);
                const rowObj = {
                    clientData: {
                        id: filteredData[0]?.ClientId,
                        name: filteredData[0]?.ClientName,
                    },
                    details: filteredData,
                };
                projects.push(rowObj);
                filteredData.map((p) => projectIds.push(p.ProjectId));
            });

            const nonClientData = activitiesData.data;
            const nonClientRowObj = {
                clientData: {
                    id: NON_CLIENT_ROW_ID,
                    name: "Non-Client-Related Activities",
                },
                details: nonClientData,
            };
            projects.push(nonClientRowObj);
            nonClientData.map((p) => projectIds.push(p.ProjectId));

            let timeOffDetails = [];

            // holidays data
            setTimeOffHolidaysData(holidaysData?.data, timeOffDetails);

            // filter the unique ooo reasons respective to office
            const timeOffDetailsNew = getUniqueOOOReasons();

            if (timeOffDetailsNew?.length > 0) {
                timeOffDetails = [...timeOffDetails, ...timeOffDetailsNew];
            }

            timeOffDetails.sort((a, b) => a.DisplayOrder - b.DisplayOrder);

            // timeoff details in projects
            if (!session.freelancer && !session.contractor) {
                if (timeOffDetails.length > 0) {
                    const timeOffObj = {
                        clientData: {
                            id: TIME_OFF_ROW_ID,
                            name: "Time Off",
                        },
                        details: timeOffDetails,
                        isTimeOffRow: true,
                    };
                    projects.push(timeOffObj);
                }
            }

            let projectHoursArr = [];

            let newHoursData = [...hoursData?.data];

            const ptoFtoReasonsList = getPtoFtoReasonsList();

            // set project hours weeks data
            projects?.map((p) =>
                p?.details?.forEach((detailsData) => {
                    // id of project, ooo and holiday respectively
                    const detailsDataProjectId =
                        detailsData?.ProjectId ||
                        detailsData?.LegacyJobId ||
                        detailsData?.Id;

                    /* second condition added since we need FTO, PTO in one row
                    and when added timeoff request then in jobId getting ReasonId not LegacyJobId */
                    let hours = newHoursData?.filter(x => {
                        if (x.isOOO) {
                            return detailsData.hasOwnProperty("LegacyJobId")
                                && detailsData.LegacyJobId === x.jobId
                                && x.oooReasonId === detailsData.ReasonId;
                        }

                        return (x.jobId === detailsDataProjectId)
                            || (detailsData.ReasonId === x.jobId)
                            || (PTO_LABELS.some(s => s === detailsData.LegacyJobCode)
                                && ptoFtoReasonsList.some(s => s.ReasonId === x.jobId))
                    });

                    let isHoliday = detailsData?.hasOwnProperty("isHoliday");
                    let nullHoursArr = [];
                    getInitialHoursArray(nullHoursArr);

                    // disable weekend cell for timeoff data with null value
                    if (detailsData?.hasOwnProperty("LegacyJobId")) {
                        [5, 6].forEach((d) => {
                            nullHoursArr[d] = {
                                ...nullHoursArr[d],
                                timeSpan: null,
                                isWeekendCellDisable: true,
                            };
                        });
                    }

                    // set project hours for week
                    if (hours?.length > 0 && !isHoliday) {

                        let newHourArr = [...nullHoursArr];

                        updateWeeksDataForProjectAndTimeOff(
                            hours,
                            newHourArr,
                            detailsData?.hasOwnProperty("ReasonId")
                        );

                        const objProjects = {
                            projectId: detailsDataProjectId,
                            isHoliday: false,
                            hours: newHourArr,
                        };

                        if (detailsData?.hasOwnProperty("ReasonId")) {
                            objProjects.ReasonId = detailsData.ReasonId;
                        }

                        projectHoursArr.push(objProjects);
                    }
                    else if (isHoliday) {
                        // update weeks data for holidays
                        // we have one row for holiday representing the project but we might have diferents holidays for that row. 

                        let newHolidaysHourArr = [...nullHoursArr];

                        // We need to update the holidays in case the user did some updates on it
                        const holidaysUpdated = updateModifiedHolidays(holidaysData?.data, newHoursData);

                        updateWeeksDataForHolidays(
                            holidaysUpdated,
                            newHolidaysHourArr
                        );

                        const objHolidays = {
                            projectId: detailsData?.Id,
                            isHoliday: isHoliday || detailsData?.hasOwnProperty("HalfDay"),
                            hours: newHolidaysHourArr,
                        };
                        projectHoursArr.push(objHolidays);
                    }
                    // update project hours with default hours data
                    else {

                        const objDefault = {
                            projectId: detailsDataProjectId,
                            isHoliday: false,
                            hours: nullHoursArr,
                        };

                        if (detailsData?.hasOwnProperty("ReasonId")) {
                            objDefault.ReasonId = detailsData.ReasonId;
                        }

                        projectHoursArr.push(objDefault);
                    }
                })
            );

            const isFutureWeekSelected = isFutureWeek();
            const isOneMonthBack = isOneMonthOldWeek();
            const editableTimeOffJobisIds = getEditableJobsIdsForUser(projects);

            projectHoursArr = setHoursTimeOffFlag(projects, projectHoursArr);
            projectHoursArr = updateTimeOffReadOnlyHours(projectHoursArr, editableTimeOffJobisIds, isFutureWeekSelected, isOneMonthBack);
            projectHoursArr = updateHolidaysReadOnlyHours(projectHoursArr, isOneMonthBack);
            setProjectHours(projectHoursArr);
            projectHoursArr = updateAprovedRejectedReadOnlyHours(projectHoursArr);
            projectHoursArr = setReadOnlyProjectsHoursOutOfDate(projectHoursArr, projects)

            setProjectHours(projectHoursArr);

            projects = filterTimeOffRows(projects, projectHoursArr, isFutureWeekSelected);
            projects = filterNonStaffProjects(projects);
            setClientAndProjectDetails(projects);

            flagRef.current = false;
            isWeekChange && setIsWeekChange(false);
        }
    }, [userData, hoursData, oooReasonsData, holidaysData]);

    const setHoursTimeOffFlag = (projects, projectHoursArr) => {
        const timeOffProjects = projects.filter(x => x.isTimeOffRow)[0];
        const timeoffJobsIds = timeOffProjects?.details?.map(x => x.LegacyJobId).filter(x => x);
        if (timeoffJobsIds) {
            projectHoursArr?.forEach(pHrs => {
                pHrs.isTimeOff = timeoffJobsIds.includes(pHrs.projectId);
            });
        }
        return projectHoursArr;
    }

    const updateTimeOffReadOnlyHours = (projectHoursArr, editableTimeOffJobisIds, isFutureWeek, isOneMonthBack) => {
        projectHoursArr?.forEach(pHrs => {
            if (pHrs.isTimeOff) {
                const isTimeOffEditableForUser = editableTimeOffJobisIds.includes(pHrs.projectId);
                pHrs?.hours?.forEach(hour => {
                    const oldTimeOff = isOneMonthBack && hour.hasOwnProperty("isOOO");
                    const readOnly = oldTimeOff || isFutureWeek || (!isFutureWeek && !isTimeOffEditableForUser);
                    hour.reandOnly = readOnly;
                    if (oldTimeOff) {
                        hour.tooltipText = TOOLTIP_READONLY_HOURS_NOT_ALLOWED;
                    }
                    else if (readOnly && hour.hasOwnProperty("jobId"))
                        hour.tooltipText = isTimeOffEditableForUser
                            ? TOOLTIP_READONLY_HOURS_ALLOWED
                            : TOOLTIP_READONLY_HOURS_NOT_ALLOWED;
                });
            }
        });

        return projectHoursArr;
    }

    const updateHolidaysReadOnlyHours = (projectHoursArr, isOneMonthBack) => {
        projectHoursArr?.forEach(pHrs => {
            if (pHrs.isHoliday) {
                pHrs?.hours?.forEach(hour => {
                    let readOnly = !hour.hasOwnProperty("timeSpan") || isOneMonthBack;
                    hour.reandOnly = readOnly;
                });
            }
        });

        return projectHoursArr;
    }

    const updateAprovedRejectedReadOnlyHours = (projectHoursArr) => {
        projectHoursArr?.forEach(pHrs => {
            if (!pHrs.isHoliday && !pHrs.isTimeOff) {
                const isApprovedOrRejectd = !!pHrs?.hours?.find(hour => hour.teApproved || hour.teRejected);
                pHrs?.hours?.forEach(hour => {
                    let readOnly = isApprovedOrRejectd;
                    hour.reandOnly = readOnly;
                });
            }
        });
        return projectHoursArr;
    }

    const setReadOnlyProjectsHoursOutOfDate = (projectHoursArr, projects) => {
        const projectsDetails = projects.map(p => p.details).flat();
        projectHoursArr?.forEach(pHrs => {
            if (!pHrs.isHoliday && !pHrs.isTimeOff) {
                const project = projectsDetails.find(p => p.ProjectId === pHrs.projectId);
                if (project) {
                    const projectStartDate = project.ProjectStartDate;
                    const projectEndDate = project.ProjectEndDate;
                    const startDate = moment(projectStartDate).format("YYYY-MM-DD");
                    const endDate = moment(projectEndDate).format("YYYY-MM-DD");

                    pHrs?.hours?.forEach(hour => {
                        const day = moment(hour.timeDay).format("YYYY-MM-DD");
                        const isBefore = moment(day).isBefore(startDate);
                        const isAfter = moment(day).isAfter(endDate);
                        if (isBefore || isAfter) {
                            hour.reandOnly = true;
                        }
                    });
                }
            }
        });
        return projectHoursArr;
    }

    const getEditableJobsIdsForUser = (projects) => {
        let jobsIds = [];
        const reasonsIds = reasonsEditablesForUser?.data.map(x => x.reasonId);
        projects?.forEach(project => {
            if (project?.isTimeOffRow) {
                project.details.forEach(timeOffJob => {
                    if (timeOffJob.hasOwnProperty("ReasonId") && reasonsIds.includes(timeOffJob.ReasonId)) {
                        jobsIds.push(timeOffJob.LegacyJobId);
                    }
                });
            }
        });
        return jobsIds;
    }

    // if we are in a future week, we show only time off rows that have hours on it
    // if we are in a current or past week, we show only time off rows that have hours on it plus the ones with "show always on timesheet" enabled
    const filterTimeOffRows = (projects, projectHoursArr, isFutureWeek) => {
        let noTimeOffToshow = false;

        projects.forEach(project => {
            if (project?.isTimeOffRow) {
                project.details = timeOffsToShow(project.details, projectHoursArr, isFutureWeek);
                noTimeOffToshow = project.details.length === 0;
            }
        });

        if (noTimeOffToshow) {
            return projects.filter(x => !x.isTimeOffRow);
        }

        return projects;
    };

    const timeOffsToShow = (projectsDetails, projectHoursArr, futureWeek) => {
        return projectsDetails?.filter(detail => {

            // if current or past and "show always on timehseet" enabled then we show it
            if (!futureWeek && detail.ShowAlwaysOnTimesheet) {
                return true;
            }

            // if it's holiday we always show it
            if (detail?.hasOwnProperty("HalfDay")) {
                return true;
            }

            // get the hours related to that time off/holiday project
            const allHours = projectHoursArr
                ?.filter(x => x.projectId === detail?.LegacyJobId)
                ?.map(x => x.hours)
                ?.flat();

            const hours = allHours?.filter(x => x.oooReasonId === detail?.ReasonId);

            // if there are hours then we show the time off project
            return hours && hours.some(x => x.Id || x.isOOO);
        });
    }

    const filterNonStaffProjects = (projects) => {
        if (session.freelancer || session.contractor) {
            projects.forEach(project => {
                project.details = project?.details?.filter(detail =>
                    !detail.hasOwnProperty("ShowStaffOnly")
                    || !detail?.ShowStaffOnly
                );
            });
        }

        return projects.filter(p => p.details.length !== 0);
    };

    useEffect(() => {
        setShowSnackbar(weekColumnDayHoursLimitExceed);
        setDisableSubmitTimesheetButton(weekColumnDayHoursLimitExceed);
    }, [clientAndProjectDetails, weekColumnDayHoursLimitExceed]);

    const handleOnCloseSnackbar = () => {
        setShowSnackbar(false);
        setDisableSubmitTimesheetButton(false);
    };

    if (isWeekChange) {
        return (
            <LoadingWrapper>
                <Loading text="Loading entries..." />
            </LoadingWrapper>
        );
    }

    if (
        hoursData?.isError
    ) {
        return <>Can't load hours data</>;
    }

    if (
        userData?.isError
    ) {
        return <>Can't load user data</>;
    }

    if (
        oooReasonsData?.isError
    ) {
        return <>Can't load Out of Office reasons data</>;
    }

    if (
        holidaysData?.isError
    ) {
        return <>Can't load holidays data</>;
    }

    if (
        reasonsEditablesForUser?.isError
    ) {
        return <>Can't load reasons editables for user data</>;
    }

    return (
        <>
            {userData?.data?.length > 0 || oooReasonsData?.data?.length > 0 ? (
                <VStack spacing="Two">
                    <div>
                        {clientAndProjectDetails?.map((item, index) => (
                            <MemoizedEmployeeRow
                                key={index}
                                hoursData={hoursData}
                                data={item}
                                projectHours={projectHours}
                                oooReasonsData={oooReasonsData}
                            />
                        ))}
                    </div>
                    {showSnackbar && (
                        <Snackbar
                            icon={true}
                            onClose={handleOnCloseSnackbar}
                            feedbackIcon="warning"
                            message="More than 24 hours have been entered to a day. Please reduce the hours for that day."
                        />
                    )}
                </VStack>
            ) : (
                <EmptyState message="There doesn't appear to be anything here." />
            )}
        </>
    );
};

Grid.propTypes = {};

export default Grid;
