import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import env from "react-dotenv";
import {
    Container,
    Title,
    FormField,
    Wrapper,
    Header,
    Back,
    WrapperEmployee,
} from "./styles/SearchEmployee-Styles";
import Text from "../../../UI/Text";
import IconTextInput from "../../../UI/IconTextInput";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "styles/Sidebar.css";
import Profile from "../../../UI/Profile";
import Magnifier from "../../../Icon/Magnifier";
import { useUser } from "../../../../utilities/API/Users";
import { useLocalStorage } from "../../../../utilities/LocalStorage";
import EmployeeDrawer from "./EmployeeDrawer";
import Left from "../../../Icon/Left";
import Right from "../../../Icon/Right";
import GenericSidebarPortal from "../../../Modal/Common/GenericSidebarPortal";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import Context from "context/Allocations/Context";

const SearchEmployee = (props) => {
    const { defaultValue } = props;
    const { getEmployeesForSearch } = useUser();
    const { setWithExpiry } = useLocalStorage();
    const { getWithExpiry } = useLocalStorage();
    const { get } = useLocalStorage();
    const { set } = useLocalStorage();
    const [employees, setEmployees] = useState([]);
    const [showHistory, setShowHistory] = useState(true);
    const [cache, setCache] = useState(null);
    const [textInput, setTextInput] = useState(null);
    const customGroupConsumer = useContext(CustomGroupContext);
    useEffect(() => {
        if (defaultValue) handleOnChange(defaultValue);
    }, [cache]);
    const contextConsumer = useContext(Context);
    const [drawerUserId, setDrawerUserId] = useState();

    useEffect(() => {
        async function getEmployees() {
            let list = await getWithExpiry("employees-search");
            if (list == null) {
                await getEmployeesForSearch([{ name: "$name", value: "" }])
                    .then((payload) => {
                        setWithExpiry(
                            "employees-search",
                            payload,
                            env.BESTWORK_REACT_EMPLOYEEFORSEARCH_EXPIRYMIN
                        );
                        list = payload;
                    })
                    .catch(() => {
                        /**/
                    });
            }
            setCache(list);
        }
        getEmployees();
    }, []);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleOnChange = async function (inputValue) {
        setTextInput(inputValue);
        if (inputValue && cache) {
            filterByName(cache, inputValue);
            setShowHistory(false);
        } else {
            setShowHistory(true);
        }
    };

    const optimisedHandleChange = debounce(handleOnChange, 500);

    const filterByName = (data, inputValue) => {
        const results = data.filter((object) => {
            return object.SearchText.indexOf(inputValue.toLowerCase()) > -1;
        });
        setEmployees(results);
    };

    useEffect(() => {
        if (showHistory) getHistory();
    }, [showHistory]);

    const getHistory = () => {
        let data = get("employees-search-history");
        if (data) setEmployees(data);
        else setEmployees([]);
    };

    const handleDrawerClose = () => {
        setDrawerUserId(null);
    };

    const handleSelect = (employee) => {
        saveHistory(employee);
        setDrawerUserId(employee.Id);
    };

    const saveHistory = (employee) => {
        // Get current history
        let currentData = get("employees-search-history") || [];
        //remove duplicates
        currentData = currentData.filter((data) => data.Id !== employee.Id);
        //save selected
        currentData.unshift(employee);
        set(
            "employees-search-history",
            currentData.slice(
                0,
                env.BESTWORK_REACT_EMPLOYEEFORSEARCH_HISTORYLIMIT
            )
        );
    };

    return (
        <div id="sidebarSearchEmployeeAllocations">
            <Container>
                <Header>
                    <Title>
                        <Text variant="xxlargeBold">Search Employees</Text>
                    </Title>
                    <FormField>
                        <IconTextInput
                            label="Employee Name"
                            defaultValue={defaultValue}
                            type="text"
                            icon={<Magnifier />}
                            onChange={optimisedHandleChange}
                        />
                    </FormField>

                    <Text variant="xsmallLight">
                        {showHistory
                            ? "Recently Viewed"
                            : employees.length
                            ? "Search Results (" + employees.length + ")"
                            : "No Results"}
                    </Text>
                </Header>
                <Wrapper>
                    {employees &&
                        employees.map((employee, index) => (
                            <WrapperEmployee
                                key={index}
                                onClick={() => handleSelect(employee)}
                            >
                                <Profile
                                    name={employee.FullName}
                                    title={employee.Title}
                                    location={employee.Office}
                                    image={
                                        env.BESTWORK_REACT_ASSET_DOMAIN +
                                        "/" +
                                        employee.PhotoURL
                                    }
                                    freelance={false}
                                />
                                <Right circle={false} />
                            </WrapperEmployee>
                        ))}
                </Wrapper>
            </Container>

            {drawerUserId && (
                <Context.Provider value={contextConsumer}>
                    <CustomGroupContext.Provider value={customGroupConsumer}>
                        <GenericSidebarPortal
                            open={true}
                            title={
                                <Back onClick={handleDrawerClose}>
                                    <Left circle={false} />
                                    <Text variant="small">Results</Text>
                                </Back>
                            }
                            content={
                                <EmployeeDrawer
                                    userId={drawerUserId}
                                    onClose={props.onClose}
                                />
                            }
                            onClose={handleDrawerClose}
                        />
                    </CustomGroupContext.Provider>
                </Context.Provider>
            )}
        </div>
    );
};

export default SearchEmployee;
