import { useParams } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAccessGroupLanding = () => {
    const { getDataAsync } = useApi();

    const getAccessGroupLandingApiCall = useCallback(async () => {
        const response = await getDataAsync("pagesDirectory");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    return useMemo(() => {
        const usePageDirectory = () =>
            useQuery("accessGroupsPages", getAccessGroupLandingApiCall);

        return {
            usePageDirectory,
        };
    }, [getAccessGroupLandingApiCall]);
};

export const useLandingDefaultPage = () => {
    const { getDataAsync, postData } = useApi();
    const queryClient = useQueryClient();
    const { groupId } = useParams();

    const getLandingDefaultPageApiCall = useCallback(
        async () => {
            const response = await getDataAsync("getDefaultPage", {
                urlParams: [{ name: "$groupId", value: groupId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const saveLandingDefaultPage = useCallback(
        ({ pageId }) => {
            return postData("saveDefaultPage", {
                urlParams: [
                    { name: "$groupId", value: groupId },
                    { name: "$pageId", value: pageId },
                ],
            });
        },
        [postData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries(["landingDefaultPage", groupId]);
            },
        };
    }, [queryClient]);

    const saveDefaultPage = useMutation(
        saveLandingDefaultPage,
        mutationOptions
    );

    return useMemo(() => {
        const useDefaultPage = () =>
            useQuery(["landingDefaultPage", groupId], () =>
                getLandingDefaultPageApiCall(groupId)
            );

        return {
            useDefaultPage,
            saveDefaultPage,
        };
    }, [getLandingDefaultPageApiCall, saveDefaultPage]);
};

export default useAccessGroupLanding;
