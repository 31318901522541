import React, { useContext, useEffect, useState, useRef } from "react";

import { COLOR_VALUES } from "components/design-system/config/colors";
import TableFilters from "components/design-system/tables/TableFilters";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";

import useAbsenseReasons from "pages/admin/absense-reasons/useAbsenseReasons";

const columns = [
    {
        headCellProps: {
            text: "Id",
            width: getCellWidthInPercent(50),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "reasonId",
        show: true,
    },
    {
        headCellProps: {
            text: "DO",
            width: getCellWidthInPercent(50),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "displayOrder",
        show: true,
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(120),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "baseName",
        show: true,
    },
    {
        headCellProps: {
            text: "Slot",
            width: getCellWidthInPercent(70),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "allotmentTypeId",
        show: true,
    },
    {
        headCellProps: {
            text: "Code",
            width: getCellWidthInPercent(90),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "code",
        show: true,
    },
    {
        headCellProps: {
            text: "Show",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "showOnTimesheet",
        show: true,
    },
    {
        headCellProps: {
            text: "Super App.",
            width: getCellWidthInPercent(60),
            isNextLine: true,
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "superApprovalRequired",
        show: true,
    },
    {
        headCellProps: {
            text: "Limit",
            width: getCellWidthInPercent(60),
            isNextLine: true,
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "limitByAllotment",
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: `Show on \nLeave`,
            width: getCellWidthInPercent(80),
            isNextLine: true,
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "showOnLeave",
        show: true,
    },
    {
        headCellProps: {
            text: `Auto \nApproved`,
            width: getCellWidthInPercent(70),
            isNextLine: true,
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "autoApproveTimsheetWeek",
        show: true,
    },
    {
        headCellProps: {
            text: "Unpaid",
            width: getCellWidthInPercent(70),
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "isUnPaidLeave",
        show: true,
    },
    {
        headCellProps: {
            text: "Status",
            width: getCellWidthInPercent(60),
        },

        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "active",
        show: true,
       
    },
    {
        headCellProps: {
            text: "Offices",
            width: getCellWidthInPercent(130),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
            tooltip: cell.value,
            placement: "bottom-start",
        }),
        accessor: "officeShortNames",
        show: true,
        disableSortBy: true,
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "active",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const AbsenseReasonsTable = () => {
    const { queryPageIndex, queryPageSize, querySortBy, totalItemsUpdate } =
        useContext(ServerSidePaginationContext);
    const { useAbsenseReasonsList } = useAbsenseReasons();
    const [filters, setFilters] = useState();
    const [columnsNew, setColumnsNew] = useState(columns);
    const [activeFilter, setActiveFilter] = useState([]);

    const showFilterVal = useRef();

    switch (
    activeFilter?.find((f) => f.id === "active")?.value
    ) {
        case undefined:
            showFilterVal.current = "";
            break;
        case "active":
            showFilterVal.current = true;
            break;
        case "inactive":
            showFilterVal.current = false;
            break;
        default:
            showFilterVal.current = true;
            break;
    }

    const absenseReasonsDataApi = useAbsenseReasonsList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: showFilterVal.current,
    });

    useEffect(() => {
        const i = columns.findIndex((f) => f.
            headCellProps.text === "Code");
        let newColArr = [...columnsNew];
        newColArr[i].getBodyCellProps = (cell) => ({
            text: cell.value ?? "-",
            actionIcon: "caret-right",
        });
        setColumnsNew(newColArr);
    }, []);

    useEffect(() => {
        if (!absenseReasonsDataApi.isLoading) {
            totalItemsUpdate(absenseReasonsDataApi?.data?.totalItems);
        }
    }, [absenseReasonsDataApi?.data?.totalItems]);

    if (absenseReasonsDataApi.isLoading) {
        return <>Loading...</>;
    }

    if (absenseReasonsDataApi.isError) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="center" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            initialFilterData={[
                                {
                                    name: "active",
                                    value: "active"
                                },
                            ]}
                            onChange={setActiveFilter}
                            filtersConfig={FiltersConfig}
                        />
                    </HStack>
                </HStack>
                <Table
                    dataIsFetching={absenseReasonsDataApi.isFetching}
                    columns={columnsNew}
                    data={absenseReasonsDataApi?.data?.items}
                    getRowProps={getRowProps}
                    filters={filters}
                    paginationFromFE={false}
                />
            </VStack>
        </>
    );
};

export default React.memo(AbsenseReasonsTable);
