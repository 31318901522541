import { Outlet, useParams } from "react-router-dom";
import GlobalConfig from "configs/Global";

import PageWrapper from "pages/PageWrapper";

import useAccessGroupApi from "pages/admin/access-groups/single/useAccessGroup";

const AccessGroupLayout = () => {
    const { groupId } = useParams();
    const { useAccessGroup } = useAccessGroupApi();
    const groupData = useAccessGroup(groupId);

    return (
        <PageWrapper
            title={groupData?.data?.name ?? "Error"}
            description={groupData?.data?.description}
            menuOptions={[
                { children: "Admin", to: GlobalConfig.routes.adminLandingPage },
                {
                    children: "Access Groups",
                    to: GlobalConfig.routes.adminAccessGroupsLanding,
                },
            ]}
            tabs={[
                { label: "Members", icon: "user", to: "members" },
                { label: "Page Access", icon: "file", to: "pageaccess" },
                { label: "Landing", icon: "arrow-down", to: "landing" },
            ]}
            loading={groupData.isLoading}
            customAuthPath={GlobalConfig.routes.adminAccessGroupsLanding}
        >
            <Outlet />
        </PageWrapper>
    );
};

export default AccessGroupLayout;
