import { useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/styles";
import NoteIcon from "components/Icon/Note";
import AddIcon from "components/Icon/Add";
import { allocationsGridModes, formModes } from "resources/Enums";
import Note from "components/Modal/Allocations/Note";
import useAllocationsNoteUpdate from "hooks/Allocations/useAllocationsNoteUpdate";
import GenericModal from "components/Modal/Common/GenericModal";
import Tooltip from "components/design-system/ui/Tooltip";
import Dotdotdot from "react-dotdotdot";
import { addWeeksToDate } from "utilities/Dates";
import moment from "moment";

const StyledButton = styled.button`
    border: none;
    padding: 0;
    background-color: inherit;
`;

const StyledTooltip = withStyles({
    tooltip: {
        width: "160px",
        height: "54px",
    },
})(Tooltip);

export const NoteMenu = styled.div`
    z-Index: 100; 
    margin-top: 0px; 
    margin-left: -220px;
    position: relative; 
    width: 200px; 
    left: 100%;
    border: 1px solid black;
    font-size: 12px;
    text-align: left;
    padding: 10px;
`;

export const NoteMenuItem = styled.div`
    &:hover {
        background-color: #f8f9fa;
    }
`;

const NoteLayer = ({
    mode,
    date,
    hasNotes,
    noteText,
    readOnly = false,
    onAddRemoveNote,
    employee,
    project,
    tabIndex,
    cellCount,
    onUpdateNote,
    ...props
}) => {

    const [modalState, setModalState] = useState();
    let lastCellIndex = cellCount - 1;

    const { saveNote, deleteNote } = useAllocationsNoteUpdate({
        employeeId: employee.userId,
        jobId: project.jobId,
        titleId: employee.titleId,
        officeId: employee.officeId,
        date,
        mode: allocationsGridModes.weekly,
        isJobScope: project.isJobScope,
        isNewNote: !hasNotes,
    });

    const closeModal = () => {
        setModalState(null);
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (hasNotes){
            const divNote = document.getElementById("div_note_menu_" + employee.assignmentId + "_" + employee.userId + "_" + project.jobId + "_" + tabIndex);
            if (divNote){
                if (divNote.style.display == "none"){
                    divNote.style.display = "";
                    divNote.style.backgroundColor = "white";
                } else{
                    divNote.style.display = "none";
                    divNote.style.backgroundColor = "";
                }
            }
        } else {
            setModalState(formModes.ADD);
        }
    };

    const editNote = (event) => {
        event.stopPropagation();
        setModalState(formModes.DETAIL);
        const divNote = document.getElementById("div_note_menu_" + employee.assignmentId + "_" + employee.userId + "_" + project.jobId + "_" + tabIndex);
        if (divNote){
            divNote.style.display = "none";
            divNote.style.backgroundColor = "";
        }
    }

    const copyNote = (event, allWeeks = false,  withHours = false) => {
        event.stopPropagation();
        updateNote(saveNote, "copy", allWeeks, withHours);
        const divNote = document.getElementById("div_note_menu_" + employee.assignmentId + "_" + employee.userId + "_" + project.jobId + "_" + tabIndex);
        if (divNote){
            divNote.style.display = "none";
            divNote.style.backgroundColor = "";
        }
    }

    const deleteCurrentNote = (event) => {
        event.stopPropagation();
        updateNote(deleteNote, "delete");
        const divNote = document.getElementById("div_note_menu_" + employee.assignmentId + "_"+ employee.userId + "_" + project.jobId + "_" + tabIndex);
        if (divNote){
            divNote.style.display = "none";
            divNote.style.backgroundColor = "";
        }
    }

    const updateNote = (updateFunc, event, allWeeks = false, withHours = false) => {
        const newDate = addWeeksToDate(moment(date).toDate(), 1); 
        onUpdateNote({
            date: event == "delete" ? moment(date).format("YYYY-MM-DD").toString() : moment(newDate).format("YYYY-MM-DD").toString(), 
            userId: employee.userId, 
            jobId : project.jobId,
            tabIndex: event == "delete" ? tabIndex : tabIndex + 1,
            note: noteText,
            withHours: withHours,
            allWeeks: allWeeks,
            updateFunc: updateFunc
        }, event);
        const divNote = document.getElementById("div_note_menu_" + employee.assignmentId + "_"+ employee.userId + "_" + project.jobId + "_" + tabIndex);
        if (divNote){
            divNote.style.display = "none";
            divNote.style.backgroundColor = "";
        }
    }

    return (
        <>
            <NoteMenu id={"div_note_menu_" + employee.assignmentId + "_" + employee.userId + "_" + project.jobId + "_" + tabIndex} style={{display: "none"}}>
                <NoteMenuItem onClick={editNote}>- Edit</NoteMenuItem>
                <NoteMenuItem onClick={deleteCurrentNote}>- Delete</NoteMenuItem>
                <NoteMenuItem onClick={(e)=> copyNote(e, false, false)}>- Copy Note to Next</NoteMenuItem>
                <NoteMenuItem onClick={(e)=> copyNote(e, false, true)}>- Copy Note to Next w/Hours</NoteMenuItem>
                <NoteMenuItem onClick={(e)=> copyNote(e, true, false)}>- Copy Note to All Weeks</NoteMenuItem>
                <NoteMenuItem onClick={(e)=> copyNote(e, true, true)}>- Copy Note to All Weeks w/hours</NoteMenuItem>
            </NoteMenu>
            <div className={props.className}>
                <StyledTooltip
                    placement={tabIndex === lastCellIndex ? "top-end" : "top-start"}
                    title={<Dotdotdot clamp={3}>Note: {noteText}</Dotdotdot>}
                    text={<Dotdotdot clamp={3}>Note: {noteText}</Dotdotdot>}
                    enabled={hasNotes}
                    enterDelay={0}
                >
                    <StyledButton onClick={handleClick}>
                        {
                            hasNotes ? 
                            (
                                <NoteIcon size="12" hover />
                            ) 
                            : 
                            (
                                !readOnly && (<AddIcon size={"12"} color="{global.config.colors.gray2}" hover/>)
                            )
                        }
                    </StyledButton>
                </StyledTooltip>
            </div>

            {(modalState === formModes.ADD ||
                modalState === formModes.DETAIL) && (
                <GenericModal
                    onHide={closeModal}
                    onClose={closeModal}
                    content={
                        <Note
                            title={ modalState === formModes.ADD ? "Add a Note" : "Note"}
                            mode={mode}
                            isNewNote={modalState === formModes.ADD}
                            employee={employee}
                            project={project}
                            date={date}
                            onAddRemoveNote={onAddRemoveNote}
                            requestHide={closeModal}
                            readOnly={readOnly}
                        />
                    }
                    parent="divPageModal"
                />
            )}
        </>
    );
};

NoteLayer.propTypes = {};

export default NoteLayer;
