import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = (props) => {
    return (
        <CircularProgress
            style={{
                color: props.color,
                height: props.height,
                width: props.width,
            }}
        />
    );
};

Loader.defaultProps = {
    height: "50px",
    width: "50px",
    color: "gray",
};

export default Loader;
