import { useMemo } from "react";
import ReactDOM from "react-dom";
import ApiError from "components/Modal/ErrorModal";
import CustomErrors from "resources/CustomErrors";
import { getTransactionId } from "utilities/Misc";
import GenericModal from "components/Modal/Common/GenericModal";
import { useInsigths } from "utilities/Insights/InsightsLogs";

export const useErrors = () => {
    const { insightsException } = useInsigths();

    const removeError = () => {
        ReactDOM.render(null, document.getElementById("divAppErrorModal"));
    };

    const addError = (error) => {
        const modalContent = <ApiError error={error} popup />;
        const modal = (
            <GenericModal
                content={modalContent}
                parent="divAppErrorModal"
                closable={error.recoverable}
                width={520}
            />
        );
        ReactDOM.render(modal, document.getElementById("divAppErrorModal"));
    };

    const redirect = (error) => {
        const modalContent = <ApiError error={error} />;
        const modal = (
            <GenericModal content={modalContent} parent="divAppErrorModal" />
        );
        ReactDOM.render(modal, document.getElementById("divAppErrorModal"));
        window.location.assign(error.errorPageURL);
    };

    const handleError = (error, redirect, recoverable) => {
        insightsException(error);

        if (error?.status) {
            switch (error.status) {
                case 400:
                    handleError400(error);
                    break;
                case 401:
                    handleError401(error, redirect);
                    break;
                case 403:
                    handleError403(error, redirect);
                    break;
                case 404:
                    handleError404(error);
                    break;
                case 408:
                    handleError408(error, redirect);
                    break;
                case 500:
                    handleError500(error, redirect);
                    break;
                case 501:
                    handleError501(error, redirect);
                    break;
                case 502:
                    handleError502(error, redirect);
                    break;
                case 503:
                    handleError503(error, redirect);
                    break;
                case 504:
                    handleError504(error, redirect);
                    break;
                default:
                    handleErrorDefault(error, redirect);
                    break;
            }
        } else {
            handleUnknownError(error, redirect, recoverable);
        }
    };

    const methods = useMemo(() => {
        return { handleError };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buildError = (
        error,
        errorDetail,
        errorPageURL,
        reloadPageURL,
        showErrorDetail,
        recoverable
    ) => {

        if (error.reason) {
            errorDetail.title = "Unhandled code error";
            errorDetail.subTitle = "Exception Details:";
            errorDetail.message = error.reason.message;
            errorDetail.stack = error.reason.stack;
        }

        if (error.url){
            if (error.reason && error.reason.message) {
                errorDetail.message = error.reason.message + ". Can't connect to: " + error.url;
            } else {
                errorDetail.message = "There is a network issue. Can't connect to: " + error.url;
            }
        }

        const customError = {
            detail: errorDetail,
            status: error?.status,
            date: error?.date,
            correlationId: getTransactionId(error?.correlationId),
            action: removeError,
            errorPageURL: errorPageURL,
            reloadPageURL: reloadPageURL,
            showDetail: showErrorDetail,
            rawError: error,
            recoverable,
            title: errorDetail?.title,
            subTitle: errorDetail?.subTitle,
            message: errorDetail?.message,
        };

        return customError;
    };

    /**Unknown error */
    const handleUnknownError = (error, forceRedirect, recoverable) => {
        let customError = null;

        if (error && error.message) {
            if (error.message.includes("Timeout")) {
                customError = buildError(
                    error,
                    CustomErrors.apiErrors.errorTimeout,
                    global.config.routes.error,
                    null,
                    false,
                    true
                );
            } else if (
                error.message.includes("NetworkError") ||
                error.message.includes("Failed to fetch")
            ) {
                customError = buildError(
                    error,
                    CustomErrors.apiErrors.errorNetwork,
                    global.config.routes.error,
                    null,
                    false,
                    true
                );
            } else {
                customError = buildError(
                    error,
                    CustomErrors.apiErrors.errorUnknown,
                    global.config.routes.error,
                    null,
                    false,
                    recoverable
                );
            }
            forceRedirect ? redirect(customError) : addError(customError);
        }
    };

    /**Default error */
    const handleErrorDefault = (error, forceRedirect) => {
        console.error("handleErrorDefault", error, forceRedirect);
        const customError = buildError(
            error,
            CustomErrors.apiErrors.errorDefault,
            global.config.routes.error,
            null, ///location.pathname,
            false
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };

    /**Empty page errors*/
    const handleError400 = (error) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error404,
            global.config.routes.error,
            null, ///location.pathname,
            false
        );

        redirect(customError);
    };

    const handleError404 = (error) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error404,
            global.config.routes.error,
            global.config.routes.root,
            false
        );

        redirect(customError);
    };

    /**Modal errors*/
    const handleError401 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error401,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };

    const handleError403 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error403,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError408 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error408,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError500 = (error, forceRedirect) => {
        let errorDetails = error.body
            ? {
                  title: error.body.title,
                  message: error.body.detail,
                  actionMessage: CustomErrors.apiErrors.error500.actionMessage,
              }
            : null;
        const customError = buildError(
            error,
            errorDetails ? errorDetails : CustomErrors.apiErrors.error500,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError501 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error501,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError502 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error502,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError503 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error503,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };
    const handleError504 = (error, forceRedirect) => {
        const customError = buildError(
            error,
            CustomErrors.apiErrors.error504,
            global.config.routes.error,
            null, ///location.pathname,
            true
        );

        forceRedirect ? redirect(customError) : addError(customError);
    };

    return methods;
};
