import { useContext } from "react";
import { matchPath, useLocation } from "react-router-dom";

import SessionContext from "context/app/SessionContext";

const usePageActionPermission = (actionCode, customPathname) => {
    const { permissions = [] } = useContext(SessionContext);
    const { pathname } = useLocation();
    const pathnameToCheck = customPathname || pathname;

    const hasPermission = permissions.some(
        (permission) =>
            matchPath(`${permission.pageRoute}/*`, pathnameToCheck) &&
            permission.code === actionCode
    );
    return hasPermission;
};

export default usePageActionPermission;
