import { useMemo, useContext } from "react";
import FilterContext from "context/Allocations/FilterContext";
import moment from "moment";
import { allocationsGridModes } from "resources/Enums";
import { useLocation, useSearchParams } from "react-router-dom";
import { isSameDay, startOfWeek } from "date-fns";
import { useApi } from "utilities/API/Helpers/useApi";
import { useCrypto } from "hooks/Crypto/useCrypto";
import { FILTER_DEFAULTS } from "hooks/Allocations/useAllocationsPermission";

export const useAllocationsFilters = () => {
    const filterConsumer = useContext(FilterContext);
    const location = useLocation();
    let query = {};
    let [searchParams, setSearchParams] = useSearchParams(location.search);
    const { getDataAsync } = useApi();
    const { encrypt, decrypt } = useCrypto();

    const buildQuery = (headerSelection, filters) => {
        let params = {};
        const dateFrom = moment(headerSelection.startDate).format("YYYY-MM-DD");
        const dateTo = moment(headerSelection.endDate).format("YYYY-MM-DD");
        const mode = allocationsGridModes.weekly;
        const page = headerSelection.page;
        const pageSize = global.config.pagination.pageSize;
        const sortBy = "Lastname";
        const sortOrder = "asc";
        const userId = headerSelection.userId ? headerSelection.userId.join(",") : null;
        const customGroupId = headerSelection.customGroupId ? headerSelection.customGroupId : null;
        const sd = moment(headerSelection.startDate).toDate();
        const td = startOfWeek(moment().toDate(), {weekStartsOn: 1,});

        if (!isSameDay(sd, td)) {
            params.dateFrom = dateFrom;
        }

        if (userId) {
            params.userId = userId;
        }

        if (!filters?.fromUrl) {
            const agencyId = filters?.agency?.value ?? null;
            const locations = filters?.locations;
            const regionId = filters?.regions?.map((region) => region.value).join(",") ?? null;
            const officeId = filters?.offices?.map((office) => office.value).join(",") ?? null;
            const groupId = filters?.groups?.map((group) => group.value).join(",") ?? null;
            const departmentId = filters?.departments?.map((department) => department.value).join(",") ?? null;
            const titleId = filters?.titles?.map((title) => title.value).join(",") ?? null;
            const clientFamilyId = filters?.clientFamily?.map((x) => x.value).join(",") ?? null;
            const clientId = filters?.client?.map((x) => x.value).join(",") ?? null;
            const retainerId = filters?.retainer?.value ?? null;
            const jobId = filters?.project?.value ?? null;
            const display = filters?.display?.join(",") ?? null;

            query = {
                dateFrom,
                dateTo,
                mode,
                page,
                pageSize,
                sortBy,
                sortOrder,
                //groupBy,
                locations,
                agencyId,
                regionId,
                officeId,
                groupId,
                departmentId,
                titleId,
                clientFamilyId,
                clientId,
                retainerId,
                jobId,
                display,
                userId,
                customGroupId,
            };
        } else {
            query = {
                dateFrom,
                dateTo,
                mode,
                page,
                pageSize,
                sortBy,
                sortOrder,
                //groupBy,
                ...filters,
            };
        }

        filterConsumer.summaryQuery = query;
        const filterParams = buildURLParams(query);
        if (query.customGroupId) {
            params.customGroupId = customGroupId;
        } else if (filterParams) {
            params.filters = encrypt(filterParams);
        }

        setSearchParams(params);
        if (query.agencyId === null) {
            query.agencyId = FILTER_DEFAULTS.agencyId;
        }
        return query;
    };

    const buildQueryEstimatedResults = (headerSelection, filters) => {
        const dateFrom = moment(headerSelection.startDate).format("YYYY-MM-DD");
        const dateTo = moment(headerSelection.endDate).format("YYYY-MM-DD");
        const mode = allocationsGridModes.weekly;

        const groupBy = headerSelection.groupBy
            ? headerSelection.groupBy
            : null;

        const agencyId = filters?.agency
            ? filters.agency?.value
            : FILTER_DEFAULTS.agencyId; // todo: replace 1 with user login info
        const regionId = filters?.regions
            ? filters.regions.map((region) => region.value).join(",")
            : null;
        const officeId = filters?.offices
            ? filters.offices.map((office) => office.value).join(",")
            : null;
        const groupId = filters?.groups
            ? filters.groups.map((group) => group.value).join(",")
            : null;
        const departmentId = filters?.departments
            ? filters.departments
                  .map((department) => department.value)
                  .join(",")
            : null;
        const titleId = filters?.titles
            ? filters.titles.map((title) => title.value).join(",")
            : null;
        const clientFamilyId = filters?.clientFamily
            ? filters.clientFamily.map((x) => x.value).join(",")
            : null;
        const clientId = filters?.client
            ? filters.client.map((x) => x.value).join(",")
            : null;
        const retainerId = filters?.retainer ? filters.retainer.value : null;
        const jobId = filters?.project ? filters.project.value : null;
        const display = filters?.display ? filters.display.join(",") : null;
        query = {
            dateFrom,
            dateTo,
            mode,
            groupBy,
            agencyId,
            regionId,
            officeId,
            groupId,
            departmentId,
            titleId,
            clientFamilyId,
            clientId,
            retainerId,
            jobId,
            display,
        };
        return query;
    };

    const buildURLParams = (filters) => {
        if (filters == null) return;
        const {
            dateFrom,
            dateTo,
            sortOrder,
            sortBy,
            groupBy,
            groupById,
            userId,
            customGroupId,
            mode,
            page,
            pageSize,
            fromUrl,
            locations,
            ...p
        } = filters;

        let params = Object.entries(p)
            .filter(([_, value]) => value != null && value !== "")
            .map(([key, val]) => key + "=" + val);

        if (params.length === 0) return;

        const locationsParam = Array.isArray(filters?.locations)
            ? filters.locations.join("_")
            : filters.locations;

        if (filters?.locations?.length) {
            params.push("locations=" + locationsParam);
        }

        const urlParams = params.join(";");
        return urlParams;
    };

    const getFilterDescription = async (params) => {
        return getDataAsync("filterDescription", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const buildQueryFromParams = (selection) => {
        const p = Object.fromEntries([...searchParams]);
        const { filters, ...result } = p;
        let filtersParams = {};
        if (searchParams.has("filters")) {
            filtersParams = Object.fromEntries(
                decrypt(p.filters)
                    .split(";")
                    .map((param) => [param.split("=")[0], param.split("=")[1]])
            );
            filtersParams.fromUrl = true;
        }

        selection.page = 1;
        if (searchParams.has("sortBy")) {
            selection.sortBy = result.sortBy + "," + result.sortOrder;
        }

        if (searchParams.has("dateFrom")) {
            selection.startDate = moment(result.dateFrom).format("YYYY-MM-DD");
        }
        return filtersParams;
    };

    const methods = useMemo(() => {
        return {
            buildQuery,
            buildQueryEstimatedResults,
            buildURLParams,
            getFilterDescription,
            buildQueryFromParams,
        };
    }, []);

    return methods;
};
