import usePageActionPermission from "hooks/Access/usePageActionPermission";

const ActionPermissionGuard = ({
    code,
    customPath,
    children = <></>,
    fallback = <></>,
}) => {
    const hasPermission = usePageActionPermission(code, customPath);

    return hasPermission ? children : fallback;
};

export default ActionPermissionGuard;
