import { useMemo } from "react";

export const useLocalStorage = () => {
    const setWithExpiry = (key, data, min) => {
        const now = new Date();
        const item = {
            expiry: new Date(now.getTime() + min * 60000),
            value: data,
        };

        set(key, item);
    };

    const set = (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    };

    const get = (key) => {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }
        return JSON.parse(itemStr);
    };

    const getWithExpiry = (key) => {
        const item = get(key);

        // if the item doesn't exist, return null
        if (!item) {
            return null;
        }

        const now = new Date();
        const expirity = new Date(item.expiry);

        // compare the expiry time of the item with the current time
        if (now.getTime() > expirity.getTime()) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    };

    const methods = useMemo(() => {
        return { setWithExpiry, getWithExpiry, set, get };
    }, []);

    return methods;
};
