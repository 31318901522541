import PropTypes from "prop-types";

const Logout = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.7003 9.59757H5.40683V8.39797H15.7003L12.8869 5.58453V5.58449L13.7351 4.73633L17.9966 8.99775L17.9965 8.99779H17.9966L13.7351 13.2592L12.8869 12.411V12.411L15.7003 9.59757Z"
                fill="black"
            />
            <path
                d="M12.5952 0V1.19956H1.19944V16.7938H12.5952V17.9933H-0.000120163V0H12.5952Z"
                fill={color}
            />
        </svg>
    );
};

Logout.defaultProps = {
    size: "20",
    color: global.config.colors.black,
};

Logout.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Logout;
