import PropTypes from "prop-types";
import styled from "styled-components";
import Dotdotdot from "react-dotdotdot";

import ProgressStatusCircle from "components/design-system/controls/ProgressStatusCircle/ProgressStatusCircle";
import HStack from "components/design-system/layout/HStack";

const Container = styled.div`
    display: table-cell;
    vertical-align: middle;
    padding: 0 0.5rem;
    height: 40px;
`;

const Name = styled.label`
    margin: 0;
    font-size: 11px;
    font-family: ${global.config.fonts.families.regular};
    color: ${global.config.colors.gray2};
    font-weight: 400;
    ${(props) =>
        props.link &&
        `
        cursor: pointer;
    `}

    ${(props) =>
        (props.SOWValue >= 60 ||
            props.SOWValue === 0 ||
            props.SOWValue === null ||
            props.projectName === "Placeholder Job" ||
            props.SOWTitle === "N/A" ||
            props.SOWTitle === "None") &&
        `
            color: ${global.config.colors.black};
    `}
`;

const StyledProjectRow = styled(HStack)`
    gap: 8px;
    ${(props) =>
        (props.SOWValue >= 60 ||
            props.SOWValue === 0 ||
            props.SOWValue === null ||
            props.projectName === "Placeholder Job" ||
            props.SOWTitle === "N/A" ||
            props.SOWTitle === "None") &&
        `
             gap:0;
    `}
`;

const ProjectDetail = ({ ...props }) => {
    return (
        <Container className={props.className}>
            <StyledProjectRow
                SOWValue={props.sowValue}
                projectName={props.name}
                SOWTitle={props.sowStatus}
            >
                <ProgressStatusCircle
                    value={props.sowValue}
                    title={props.sowStatus}
                />
                <Dotdotdot clamp={1}>
                    <Name
                        SOWValue={props.sowValue}
                        projectName={props.name}
                        SOWTitle={props.sowStatus}
                        {...props}
                    >
                        {props.name}
                    </Name>
                </Dotdotdot>
            </StyledProjectRow>
        </Container>
    );
};

ProjectDetail.defaultProps = {
    name: "project name",
};

ProjectDetail.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ProjectDetail;
