import { useEffect, useState, forwardRef, useImperativeHandle, useRef, useContext} from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Button from "../../UI/Button";
import Link from "../../UI/Link";
import Text from "../../UI/Text";
import Right from "../../Icon/Right";
import Alert from "../../Icon/Alert";
import { useStylesForText } from "../../../styles/MakeStyles";
import { useAllocations } from "../../../utilities/API/Allocations";
import { truncateText } from "utilities/TruncateText";
import Tooltip from "components/UI/Tooltip";
import {
    ModalControl,
    TagContainer,
    LeftColumn,
    LeftTop,
    LeftBottom,
    Title,
    MenuItem,
    Subtitle,
    ResultRow,
    TotalColumn,
    Error,
    RightColumn,
    MenuContent,
    SelectedFilters,
} from "./Filter-Styles";
import Locations from "./Filter/Locations";
import DepartmentsAndTitles from "./Filter/DepartmentsAndTitles";
import ClientsAndProjects from "./Filter/ClientsAndProjects";
import Display from "./Filter/Display";
import SavedFilters from "./Filter/SavedFilters";
import Loader from "components/UI/Loader";
import { useAllocationsFilters } from "hooks/Allocations/useAllocationsFilters";
import IconButton from "components/UI/IconButton";
import Close from "components/Icon/Close";
import CustomGroupContext from "context/Allocations/CustomGroupContext";

const tabs = {
    location: 0,
    title: 1,
    client: 2,
};

const DEFAULT_DISPLAYS = ["Staff", "Freelancer", "TBD"];
const DEFAULT_ESTIMATED_RESULTS = "+500";

const Filter = forwardRef((props, ref) => {
    const { title, onApply, clearEmployeesSelection, headerSelection } = props;
    const { handleSubmit } = useForm();
    const [show, setShow] = useState(false);
    const [tab, setTab] = useState(tabs.location);
    const [initialized, setInitialized] = useState(false);
    const [agency, setAgency] = useState(null);
    const [savedAgency, setSavedAgency] = useState(null);
    const [regions, setRegions] = useState([]);
    const [offices, setOffices] = useState([]);
    const [groups, setGroups] = useState([]);
    const [locations, setLocations] = useState([]);
    const [savedLocations, setSavedLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [savedDepartments, setSavedDepartments] = useState([]);
    const [titles, setTitles] = useState([]);
    const [savedTitles, setSavedTitles] = useState([]);
    const [savedClientAndProjectSelection, setSavedClientAndProjectSelection] = useState();
    const [clientFamily, setClientFamily] = useState([]);
    const [client, setClient] = useState([]);
    const [retainer, setRetainer] = useState(null);
    const [project, setProject] = useState(null);
    const [display, setDisplay] = useState(DEFAULT_DISPLAYS);
    const [savedDisplay, setSavedDisplay] = useState(DEFAULT_DISPLAYS);
    const [customFilter, setCustomFilter] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [selectedFiltersDesc, setSelectedFiltersDesc] = useState(null);
    const [selectedFiltersTotal, setSelectedFiltersTotal] = useState(0);
    const [estimatedResults, setEstimatedResults] = useState(DEFAULT_ESTIMATED_RESULTS);
    const [loadingEstimatedResults, setLoadingEstimatedResults] = useState(false);
    const [locationsTabTotalSelected, setLocationsTabTotalSelected] = useState(0);
    const [departmentAndTitleTabTotalSelected, setDepartmentAndTitleTabTotalSelected] = useState(0);
    const [clientAndProjectTabTotalSelected, setClientAndProjectTabTotalSelected] = useState(0);
    const [clearAllDisabled, setClearAllDisabled] = useState(true);
    const [savedFilter, setSavedFilter] = useState(null);
    const [initialDataOnFilterModalLoad, setInitialDataOnFilterModalLoad] = useState({ customFilter: null, savedFilter: null });
    const classesText = useStylesForText();
    const { getAllocationsEstimation } = useAllocations();
    const { buildQueryEstimatedResults, getFilterDescription } = useAllocationsFilters();
    const customGroupConsumer = useContext(CustomGroupContext);
    const [prevQuery, setPrevQuery] = useState(null);

    useEffect(() => {
        async function init() {
            !initialized && show && setInitialized(true);
        }
        init();
    }, [initialized, show]);

    useEffect(() => {
        show &&
            setInitialDataOnFilterModalLoad({
                customFilter,
                savedFilter,
            });
    }, [show]);

    const firstUpdateSavedFilter = useRef(true);
    useEffect(() => {
        if (firstUpdateSavedFilter.current) {
            firstUpdateSavedFilter.current = false;
            return;
        }
        if (savedFilter?.agency) {
            setSavedAgency(savedFilter.agency);
            setAgency(savedFilter.agency);
        }
        if (savedFilter) {
            savedFilter?.locations && setSavedLocations(savedFilter.locations);

            savedFilter?.titles && setSavedTitles(savedFilter.titles);
            savedFilter?.departments &&
                setSavedDepartments(savedFilter.departments);

            setSavedClientAndProjectSelection({
                clientFamily: savedFilter?.clientFamily,
                client: savedFilter?.client,
                retainer: savedFilter?.retainer,
                project: savedFilter?.project,
            });

            if (savedFilter?.display) {
                setDisplay(savedFilter.display);
                setSavedDisplay(savedFilter.display);
            }
        }
    }, [savedFilter]);

    useEffect(() => {
        const total =
            locationsTabTotalSelected +
            departmentAndTitleTabTotalSelected +
            clientAndProjectTabTotalSelected;
        const disabled = total === 0;

        setClearAllDisabled(disabled);
    }, [
        locationsTabTotalSelected,
        departmentAndTitleTabTotalSelected,
        clientAndProjectTabTotalSelected,
    ]);

    useImperativeHandle(ref, () => ({
        updateFilterSelection(filter) {
            getFilterDescription(filter).then((r) => {
                const selection = {
                    agency: r.agency,
                    regions: r.regions,
                    offices: r.offices,
                    groups: r.groups,
                    locations: r.locations,
                    departments: r.departments,
                    titles: r.titles,
                    clientFamily: r.clientFamily,
                    client: r.client,
                    retainer: r.retainer,
                    project: r.project,
                    display: r.displays,
                };

                setAgency(r.agency);
                setLocations(r.locations);
                setRegions(r.regions);
                setOffices(r.offices);
                setGroups(r.groups);
                setDepartments(r.departments);
                setTitles(r.titles);
                setClientFamily(r.clientFamily);
                setClient(r.client);
                setRetainer(r.retainer);
                setProject(r.project);

                setSelectedFilters(selection);
                resolveSelectedFiltersSummary(selection);
                setSavedFilter(selection);
                getEstimatedResult(selection);
            });
        },
        updateSelectedProject(project) {
            setProject(project);

            setSavedClientAndProjectSelection({
                clientFamily: null,
                client: null,
                retainer: null,
                project: project,
            });

            const selection = {
                agency: agency,
                regions: regions,
                offices: offices,
                groups: groups,
                locations: locations,
                departments: departments,
                titles: titles,
                clientFamily: clientFamily,
                client: client,
                retainer: retainer,
                project: project,
                display: display,
            };
            calculateClientAndProjectTabTotalSelected(
                clientFamily,
                client,
                retainer,
                project
            );

            setSelectedFilters(selection);
            resolveSelectedFiltersSummary(selection);
            getEstimatedResult(selection);
        },
        showModal() {
            handleShow();
        },
        reset() {
            handleReset();
        },
    }));

    const calculateLocationsTabTotalSelected = (
        agencyElement = agency,
        regions = [],
        offices = [],
        groups = []
    ) => {
        let selectedLocations = 0;
        if (agencyElement) selectedLocations += 1;
        selectedLocations =
            selectedLocations + regions.length + offices.length + groups.length;
        setLocationsTabTotalSelected(selectedLocations);
    };

    const calculatDepartmentAndTitleTabTotalSelected = (
        departments = [],
        titles = []
    ) => {
        let selectedDeparmentsAndTitles = 0;
        selectedDeparmentsAndTitles =
            selectedDeparmentsAndTitles + departments.length + titles.length;
        setDepartmentAndTitleTabTotalSelected(selectedDeparmentsAndTitles);
    };

    const calculateCountClientProjectTab = (
        clientFamily,
        client,
        retainer,
        project
    ) => {
        return (
            (clientFamily?.length ?? 0) +
            (client?.length ?? 0) +
            (retainer ? 1 : 0) +
            (project ? 1 : 0)
        );
    };

    const calculateClientAndProjectTabTotalSelected = (
        clientFamily = [],
        client = [],
        retainer,
        project
    ) => {
        setClientAndProjectTabTotalSelected(
            calculateCountClientProjectTab(
                clientFamily,
                client,
                retainer,
                project
            )
        );
    };

    const handleSavedFilterChangedWarning = (value) => {
        if (value) {
            setCustomFilter(null);
            setSelectedFilters(null);
            setSelectedFiltersDesc(null);
            setSelectedFiltersTotal(0);
        }
    };

    const handleLoadAgencyAndLocations = (agencyData, selections) => {
        setAgency(agencyData);

        const selection = {
            agency: agencyData,
            regions: selections.regions,
            offices: selections.offices,
            groups: selections.groups,
            locations: [],
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };
        calculateLocationsTabTotalSelected(
            agencyData,
            selections.regions,
            selections.offices,
            selections.groups
        );
        getEstimatedResult(selection);
    };

    const handleAgencyChange = (agency) => {
        setAgency(agency);
        setRegions([]);
        setOffices([]);
        setGroups([]);
        setLocations([]);

        const selection = {
            agency: agency,
            regions: [],
            offices: [],
            groups: [],
            locations: [],
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };
        calculateLocationsTabTotalSelected(agency, [], [], []);
        getEstimatedResult(selection);
    };

    const handleLocationsChange = (
        regions,
        offices,
        groups,
        locations,
        reloadResults
    ) => {
        setRegions(regions);
        setOffices(offices);
        setGroups(groups);
        setLocations(locations);
        calculateLocationsTabTotalSelected(regions, offices, groups);

        const selection = {
            agency: agency,
            regions: regions,
            offices: offices,
            groups: groups,
            locations: locations,
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };
        setSelectedFilters(selection);
        reloadResults && getEstimatedResult(selection);
    };

    const handleDepartmentAndTitleChange = async (departments, titles) => {
        setDepartments(departments);
        setTitles(titles);
        const selection = {
            agency: agency,
            regions: regions,
            offices: offices,
            groups: groups,
            locations: locations,
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };
        calculatDepartmentAndTitleTabTotalSelected(departments, titles);

        setSelectedFilters(selection);
        getEstimatedResult(selection);
    };

    const handleClientAndProjectChange = (
        clientFamily,
        client,
        retainer,
        project
    ) => {
        setClientFamily(clientFamily);
        setClient(client);
        setRetainer(retainer);
        setProject(project);
        const selection = {
            agency: agency,
            regions: regions,
            offices: offices,
            groups: groups,
            locations: locations,
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };
        calculateClientAndProjectTabTotalSelected(
            clientFamily,
            client,
            retainer,
            project
        );

        setSelectedFilters(selection);
        getEstimatedResult(selection);
    };

    const handleDisplayChange = async (display) => {
        setDisplay(display);
        const selection = {
            agency: agency,
            regions: regions,
            offices: offices,
            groups: groups,
            locations: locations,
            departments: departments,
            titles: titles,
            clientFamily: clientFamily,
            client: client,
            retainer: retainer,
            project: project,
            display: display,
        };

        setSelectedFilters(selection);
        getEstimatedResult(selection);
    };

    const handleOnCustomFilterCreated = (filter) => {
        setCustomFilter({ id: filter });
    };

    const handleSavedFilterChange = (filter) => {
        if (filter != null && filter.data != null){
            setCustomFilter(filter);

            setSavedFilter(filter?.data);
    
            setAgency(filter?.data.agency);
            setLocations(filter?.data.locations);
            setRegions(filter?.data.regions);
            setOffices(filter?.data.offices);
            setGroups(filter?.data.groups);
            setDepartments(filter?.data.departments);
            setTitles(filter?.data.titles);
            setClientFamily(filter?.data.clientFamily);
            setClient(filter?.data.client);
            setRetainer(filter?.data.retainer);
            setProject(filter?.data.project);
    
            setSelectedFilters(filter?.data);
            getEstimatedResult(filter?.data);
    
            calculateLocationsTabTotalSelected(
                filter?.data?.regions,
                filter?.data?.offices,
                filter?.data?.groups
            );
            calculatDepartmentAndTitleTabTotalSelected(
                filter?.data?.departments,
                filter?.data?.titles
            );
            calculateClientAndProjectTabTotalSelected(
                filter?.data?.clientFamily,
                filter?.data?.client,
                filter?.data?.retainer,
                filter?.data?.project
            );    
        }
    };

    const handleSavedFilterClear = () => {
        handleReset();
    };

    const handleClose = async (valCalledOnButton = null) => {
        switch (valCalledOnButton) {
            case "onSubmit":
                setEstimatedResults(DEFAULT_ESTIMATED_RESULTS);
                setShow(false);
                break;
            default:
                setShow(false);
                break;
        }
    };

    const handleShow = async () => {
        clearEmployeesSelection && clearEmployeesSelection();
        setShow(true);
    };

    const resetDepartments = () => {
        setDepartments([]);
        setSavedDepartments([]);
    };

    const resetTitles = () => {
        setTitles([]);
        setSavedTitles([]);
    };

    const resetClientAndProjectSelection = (valCF = [], valC = [], valR = null, valP = null) => {
        setSavedClientAndProjectSelection({
            clientFamily: valCF,
            client: valC,
            retainer: valR,
            project: valP,
        });

        setClientFamily(valCF);
        setClient(valC);
        setRetainer(valR);
        setProject(valP);
    };

    const handleReset = async () => {
        setAgency(agency);
        setSavedAgency(null);
        setRegions([]);
        setOffices([]);
        setGroups([]);
        setLocations([]);
        setSavedLocations([]);

        resetDepartments();
        resetTitles();
        resetClientAndProjectSelection();

        setDisplay(DEFAULT_DISPLAYS);
        setSavedDisplay(DEFAULT_DISPLAYS);

        setSelectedFilters(null);
        setSelectedFiltersDesc(null);

        setSelectedFiltersTotal(0);
        setLocationsTabTotalSelected(0);
        setDepartmentAndTitleTabTotalSelected(0);
        setClientAndProjectTabTotalSelected(0);
        setClearAllDisabled(true);

        setEstimatedResults(DEFAULT_ESTIMATED_RESULTS);
        setCustomFilter(null);
    };

    const handleTab = async (value) => {
        setTab(value);
    };

    const resolveSelectedFiltersSummary = (filters) => {
        const separator = ", ";

        let descriptions = [];

        filters.agency && descriptions.push(filters?.agency?.label);

        filters?.regions?.length > 0 &&
            filters?.regions.forEach((item) => descriptions.push(item.label));

        filters?.offices?.length > 0 &&
            filters?.offices.forEach((item) => descriptions.push(item.label));

        filters?.groups?.length > 0 &&
            filters?.groups.forEach((item) => descriptions.push(item.label));

        const selectedLocations =
            (agency ? 1 : 0) +
            filters?.regions.length +
            filters?.offices.length +
            filters?.groups.length;

        filters?.departments?.length > 0 &&
            filters?.departments.forEach((item) =>
                descriptions.push(item.label)
            );

        filters?.titles?.length > 0 &&
            filters?.titles.forEach((item) => descriptions.push(item.label));

        const selectedDeparmentsAndTitles =
            filters?.departments.length + filters?.titles.length;

        filters?.clientFamily?.length > 0 &&
            filters?.clientFamily.forEach((item) =>
                descriptions.push(item.label)
            );
        filters?.client?.length > 0 &&
            filters?.client.forEach((item) => descriptions.push(item.label));
        filters?.retainer && descriptions.push(filters?.retainer.label);
        filters?.project && descriptions.push(filters?.project.label);

        filters?.display?.length > 0 &&
            filters?.display.forEach((item) => descriptions.push(item));

        let description = descriptions && descriptions.join(separator);

        setLocationsTabTotalSelected(selectedLocations);
        setDepartmentAndTitleTabTotalSelected(selectedDeparmentsAndTitles);
        setClientAndProjectTabTotalSelected(
            calculateCountClientProjectTab(
                filters?.clientFamily,
                filters?.client,
                filters?.retainer,
                filters?.project
            )
        );
        setSelectedFiltersTotal(descriptions?.length);
        setSelectedFiltersDesc(description);
    };

    const onSubmit = async function () {
        customGroupConsumer.setSelectedCustomGroup(null);
        if (customFilter?.data) {
            setSavedFilter(customFilter?.data);
            setSelectedFilters(customFilter?.data);
            resolveSelectedFiltersSummary(customFilter?.data);
            onApply(customFilter?.data);
        } else {
            const selection = {
                agency: agency,
                regions: regions,
                offices: offices,
                groups: groups,
                locations: locations,
                departments: departments,
                titles: titles,
                clientFamily: clientFamily,
                client: client,
                retainer: retainer,
                project: project,
                display: display,
                page: 1,
            };
            setSavedFilter(selection);
            resolveSelectedFiltersSummary(selection);
            onApply(selection);
        }
        handleClose("onSubmit");
    };

    const getEstimatedResult = async (selection) => {
        if (headerSelection?.startDate && headerSelection?.endDate) {
            const query = buildQueryEstimatedResults(headerSelection, selection);
            /* here, added fetchResult with functionality to prevent endpoint call for similar parameters */
            let fetchResult = false;
            const prevQueryLen = prevQuery ? Object.keys(prevQuery)?.length : 0;
            const queryLen = query ? Object.keys(query)?.length : 0;
            if (prevQuery === null) {
                /* initial call */
                fetchResult = true;
            } else if (queryLen > 0 && prevQueryLen > 0) {
                const prevQueryArr = Object.keys(prevQuery);
                const newFields = Object.keys(query).filter(f => !prevQueryArr.some(q => q === f));
                /* newFields not present in prevQuery and which have some value then call endpoint */
                newFields.map(k => {
                    if (query[k]?.length > 0) {
                        fetchResult = true;
                    }
                });
                /* different values of same fields/keys; in prev and current query then call endpoint */
                Object.keys(query).filter(f => prevQueryArr.some(q => q === f)).forEach((key) => {
                    if (prevQuery.hasOwnProperty(key) && query[key] !== prevQuery[key]) {
                        fetchResult = true;
                    }
                });
            }
            setPrevQuery(query);
            if (fetchResult) {
                setLoadingEstimatedResults(true);
                getAllocationsEstimation(query).then((payload) => {
                    const result = payload.toString();
                    let convertedResult = undefined;
                    convertedResult = parseInt(result);
                    if (convertedResult > 0) setEstimatedResults(convertedResult);
                    else setEstimatedResults(undefined);
                    setLoadingEstimatedResults(false);
                });
            }
        }
    };

    const handleOnClick = (event) => {
        const showConfirmation = props.showCustomGroupConfirmation();
        if (!showConfirmation) {
            ReactDOM.render(null, document.getElementById("divPageSidebar"));
            handleShow();
        }
    };

    const EstimatedResults = ({ results }) => {
        return (
            <>
                {results ? (
                    <Text variant="xsmall" className={classesText.gray2}>Results: {results}</Text>
                ) : (
                    <Text variant="xsmall" className={classesText.gray2}><Alert size="20" viewBox="20" /> No results</Text>
                )}
            </>
        );
    };

    return (
        <>
            <ModalControl onClick={(event) => handleOnClick(event)}>
                {props.children}
                {selectedFiltersTotal > 0 && (
                    <Tooltip placement="bottom" title={selectedFiltersDesc}>
                        <SelectedFilters>
                            <TagContainer>{selectedFiltersTotal}</TagContainer>
                            <Text variant="smallLight">{truncateText(selectedFiltersDesc, 50)}</Text>
                        </SelectedFilters>
                    </Tooltip>
                )}
            </ModalControl>
            <Modal show={show} onHide={() => { handleClose();}} backdrop={global.config.modal.static} keyboard={global.config.modal.keyboard} centered animation={false}>
                <Modal.Header className="fullscreen">
                    <IconButton icon={<Close />} onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container fluid>
                            <Row style={{pointerEvents: loadingEstimatedResults ? "none" : "", cursor: loadingEstimatedResults ? "not-allowed" : "", opacity: loadingEstimatedResults ? "0.25" : ""}}>
                                <LeftColumn sm={4} lg={3} className="filterScrollbars">
                                    <LeftTop>
                                        <Title><Text variant="xlarge">{title}</Text></Title>
                                        <MenuItem selected={tab === tabs.location} onClick={() => handleTab(tabs.location)}>
                                            <Text variant="smedium">Agency &amp; Location</Text>
                                            <TagContainer circle>{locationsTabTotalSelected}</TagContainer>
                                            <Right circle={false} />
                                        </MenuItem>
                                        <MenuItem selected={tab === tabs.title} onClick={() => handleTab(tabs.title)}>
                                            <Text variant="smedium">Department &amp; Title</Text>
                                            <TagContainer circle>{departmentAndTitleTabTotalSelected}</TagContainer>
                                            <Right circle={false} />
                                        </MenuItem>
                                        <MenuItem selected={tab === tabs.client} onClick={() => handleTab(tabs.client)} borderBottom>
                                            <Text variant="smedium">Client &amp; Project</Text>
                                            <TagContainer circle>{clientAndProjectTabTotalSelected}</TagContainer>
                                            <Right circle={false} />
                                        </MenuItem>
                                    </LeftTop>
                                    <LeftBottom>
                                        <Subtitle><Text variant="xsmall">Display</Text></Subtitle>
                                        <Display onChange={(display) => handleDisplayChange(display)} savedDisplay={savedDisplay} filterSelected={customFilter?.id} handleSavedFilterChangedWarning={(value) => handleSavedFilterChangedWarning(value)}/>
                                        <SavedFilters selectedCustomFilter={customFilter?.id} selectedFilters={selectedFilters} onChange={(selection) =>handleSavedFilterChange(selection)} onClear={() => { handleSavedFilterClear();}} onFilterCreated={(filter) => handleOnCustomFilterCreated(filter)} />
                                        <ResultRow>
                                            <Col><Link onClick={() => handleReset()} disabled={clearAllDisabled}>Clear all</Link></Col>
                                            <TotalColumn>
                                                {loadingEstimatedResults ? (
                                                    <Loader height=".8rem" width=".8rem" />
                                                ) : (
                                                    <EstimatedResults results={estimatedResults}/>
                                                )}
                                            </TotalColumn>
                                        </ResultRow>
                                        <Button style={{ width: "100%" }} onClick={() => handleSubmit(onSubmit)} disabled={loadingEstimatedResults || !estimatedResults || estimatedResults > 250} >Apply</Button>
                                        {!estimatedResults && (
                                            <Error>
                                                <Text variant="xxsmallLight" className={classesText.red1}>
                                                    Filter selections must yield
                                                    more than 0 results 
                                                </Text>
                                            </Error>
                                        )}
                                        {
                                            estimatedResults > 250 && 
                                            <Error>
                                                <Text variant="xxsmallLight" className={classesText.red1}>
                                                    Filter selections must yield
                                                    less than 250 results 
                                                </Text>
                                            </Error>
                                        }
                                    </LeftBottom>
                                </LeftColumn>
                                <RightColumn id="divFilterRightColumn" className="filterScrollbars">
                                    <MenuContent selected={tab === tabs.location}>
                                        <Locations
                                            onLoadAgencyAndLocations={(agencyData,selections) =>
                                                handleLoadAgencyAndLocations(agencyData, selections)}
                                            onChangeAgency={(agency) => handleAgencyChange(agency)}
                                            onChangeLocations={(regions, offices, groups, locations, reloadResults) => handleLocationsChange(regions, offices, groups, locations, reloadResults)}
                                            handleSavedFilterChangedWarning={(value) => handleSavedFilterChangedWarning(value)}
                                            savedAgency={savedAgency}
                                            savedLocations={savedLocations}
                                            filterSelected={customFilter?.id}
                                        ></Locations>
                                    </MenuContent>
                                    <MenuContent selected={tab === tabs.title}>
                                        <DepartmentsAndTitles
                                            onChange={(departments, titles) => handleDepartmentAndTitleChange(departments,titles)}
                                            savedTitles={savedTitles}
                                            savedDepartments={savedDepartments}
                                            filterSelected={customFilter?.id}
                                            savedSelection={savedDepartments}
                                            handleSavedFilterChangedWarning={(value) => handleSavedFilterChangedWarning(value)}
                                        ></DepartmentsAndTitles>
                                    </MenuContent>
                                    <MenuContent selected={tab === tabs.client}>
                                        <ClientsAndProjects
                                            onChange={(clientFamily,client,retainer,project) => handleClientAndProjectChange(clientFamily,client,retainer,project)}
                                            filterSelected={customFilter?.id}
                                            savedSelection={savedClientAndProjectSelection}
                                            handleSavedFilterChangedWarning={(value) => handleSavedFilterChangedWarning(value)}
                                        ></ClientsAndProjects>
                                    </MenuContent>
                                </RightColumn>
                            </Row>
                        </Container>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default Filter;
