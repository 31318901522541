import PropTypes from "prop-types";

const Logo = ({ width, height }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={"0 0 " + width + " " + height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M39.8742 10.4984L40.137 11.8127H41.7471V5.58598H36.9004V7.45893H39.447C39.2991 9.05257 38.3462 9.95619 36.7033 9.95619C34.4196 9.95619 33.5653 8.01752 33.5653 6.09529C33.5653 4.07448 34.4196 2.13582 36.7033 2.13582C37.9026 2.13582 38.8555 2.79299 39.1513 4.07448H41.5664C41.2871 1.46221 39.0691 0 36.7033 0C33.1381 0 31.0352 2.66156 31.0352 6.09529C31.0352 9.43045 33.1381 12.092 36.7033 12.092C37.8205 12.1084 38.987 11.6484 39.8742 10.4984Z"
                fill="#000000"
            />
            <path
                d="M48.5161 3.12157H48.549L49.9948 7.36035H47.0046L48.5161 3.12157ZM47.2511 0.279297L42.8809 11.8127H45.4438L46.3475 9.24972H50.6684L51.5391 11.8127H54.1678L49.8469 0.279297H47.2511Z"
                fill="#000000"
            />
            <path
                d="M29.4082 0.294922L25.1201 11.7955H26.9602L31.2483 0.294922H29.4082Z"
                fill="#000000"
            />
            <path
                d="M11.517 0.294922H0V11.8119H11.517V0.294922Z"
                fill="#E51837"
            />
            <path
                d="M21.0785 3.84527C21.0785 5.01175 20.4706 5.48821 19.337 5.48821H16.5604V2.23519H19.337C20.4706 2.25162 21.0785 2.7445 21.0785 3.84527ZM23.625 3.45097C23.625 1.70945 22.3107 0.263672 20.257 0.263672H14.0303V11.8135H16.5604V7.31187H19.0905C20.372 7.31187 20.832 7.8376 21.0127 9.05338C21.1442 9.97342 21.1113 11.0906 21.4235 11.8135H23.9536C23.4936 11.1728 23.51 9.80913 23.4771 9.08624C23.395 7.91975 23.0336 6.72041 21.8014 6.39182V6.35896C23.0664 5.83322 23.625 4.8146 23.625 3.45097Z"
                fill="#000000"
            />
        </svg>
    );
};

Logo.defaultProps = {
    width: "55",
    height: "15",
};

Logo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

export default Logo;
