import { FormProvider } from "react-hook-form";
import React, {  useEffect } from "react";

// This component is similar to Form.js. The difference is that in this one we received via props the methods from the Hook useForm, so then we can use that hook from the parent component. That hook is provided in react-hook-form library and allow us to do several things https://react-hook-form.com/api/useform/ 

const Form = ({
    onSubmit,
    defaultValues = {},
    mode = "onBlur",
    children,
    methods,
    ...rest
}) => {

    useEffect(() => {
        if (defaultValues)
            methods.reset(defaultValues);

    }, []);

    return (
        <FormProvider {...methods} >
            <form
                noValidate
                {...rest}
                onSubmit={methods.handleSubmit(onSubmit)}
                onReset={() => {
                    methods.reset();
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default Form;
