import React, { useState, useEffect, useRef } from "react";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import useSelectorProjectsAPI from "./useSelectorProjectsAPI";
import { useTopNavHeight } from "pages/projects/useTopNavHeight";
import { HeaderPageStyled } from "pages/projects/StyledElements";
import PageWrapper from "pages/PageWrapper";
import GlobalConfig from "configs/Global";
import useProjectPlannerAPI from "./useProjectPlannerAPI";
import moment from "moment";
import Loading from "components/UI/Loading";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';                    
import 'primeicons/primeicons.css';                              
import 'primeflex/primeflex.css';
import './planner.css';   
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';       
import { InputNumber } from 'primereact/inputnumber';        
import { Dropdown } from 'primereact/dropdown';
import { ContextMenu } from 'primereact/contextmenu';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';

const ProjectPlanner = () => {
    const topNavHeight = useTopNavHeight();
    const [projectId, setProjectId] = useState(0);
    const [project, setProject] = useState(null);
    const [gridData, setGridData] = useState(null);
    const [gridFCColumns, setGridFCColumns] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const fixedColumns = [
        { field: 'Office', header: 'Office', frozen: true, sortable: true, editor: "selector", width: 20 },
        { field: 'JobTitle', header: 'Title', frozen: true, sortable: true, editor: "selector", width: 150 },
        { field: 'UserName', header: 'Employee', frozen: true, sortable: true, editor: "selector", width: 200 },
        { field: 'RateCard', header: 'RateCard', frozen: true, sortable: true, editor: "selector", width: 50, className: "ratecard"  },
        { field: 'CurrentRate', header: 'Rate', frozen: true, sortable: true, width: 50, className: "rate", format: "numeric2" },
        { field: 'BHrs', header: 'Bl Hrs', frozen: true, sortable: true, editor: "numeric", width: 100, className: "baselineHrs", totalInHeader: true, format: "numeric2" },
        { field: 'BFee', header: 'Bl Fee', frozen: true, sortable: true, width: 100, className: "baselineFee", totalInHeader: true, format: "calculated" },
        { field: 'EACHrs', header: 'EAC Hrs', frozen: true, sortable: true, width: 100, className: "eacHrs", totalInHeader: true, format: "calculated" },
        { field: 'EACCost', header: 'EAC Cost', frozen: true, sortable: true, width: 100, className: "eacCost", totalInHeader: true, format: "calculated" },
        { field: 'VarHrs', header: 'Var Hrs', frozen: true, sortable: true, width: 100, className: "varianceHrs", totalInHeader: true, format: "calculated" },
        { field: 'VarCost', header: 'Var Cost', frozen: true, sortable: true, width: 100, className: "varianceCost", totalInHeader: true, format: "calculated" },
    ];
    const [visibleColumns, setVisibleColumns] = useState(fixedColumns);
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedCMItem, setSelectedCMItem] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const { selectorProjects } = useSelectorProjectsAPI();
    const projectsOptions = getDropdownOptionsNoDefaultVal(selectorProjects?.data);
    const { getProjectPlannerApiCall, getProjectPlannerDataApiCall } = useProjectPlannerAPI();
    const dt = useRef(null);
    const cm = useRef(null);

    const handleOnChange = (id) => {
        if (id && id > 0)
        { 
            setProjectId(id);
        }
    };

    useEffect(()=> {
        if (projectId && projectId > 0){
            getProjectPlannerApiCall(projectId).then((p) => {
                    setProject(p);
                    setDataLoading(true);
                    getProjectPlannerDataApiCall(projectId).then((result)=> {
                        result.forEach((r, index)=> {
                            r.Id = index + 1;
                        })
                    setGridData(result);
                    setGridFCColumns(getFCColumns(p.ProjStartDate, p.CurrentEndDate));
                    setExpandedRows(result);
                    initFilters();
                }).then(()=> setDataLoading(false)).then(() => scrollAtForecast());
            });
        }
    }, [projectId]);

    const headerItemStyleSelected = {
        paddingBottom: "30px",
        paddingLeft: "20px", 
        paddingRight: "20px",   
        display: project ? "inline-block" : "none"
    }

    const headerItemStyle = {
        display: "inline-block",
        paddingRight: "20px",
        fontSize: "11px",
    }

    const scrollAtForecast = () => {
        const el = document.getElementById("fColumn");
        if (el){
            el.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
    } 

    const getFCColumns = (projStartDate, projEndDate) => {
        let columns = [];
        let currDate = moment(projStartDate).startOf('week').add(1, "days");
        const currMonday = moment().startOf('week').add(1, "days");
        const projEnd = moment(projEndDate).startOf('week').add(1, "days");
        while (currDate.toDate() < projEnd.toDate()){
            columns.push(currDate.toDate());
            // if (currDate.toDate() < currMonday.toDate()){
            //     columns.push({Date: currDate.toDate(), Type: "A" });
            // }
            currDate = currDate.add(1, "weeks");
        }
        return columns;
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = fixedColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setVisibleColumns(orderedSelectedColumns);
    };

    const clearFilter = () => {
        initFilters();
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportColumns = fixedColumns.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, gridData);
                doc.save('planner.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(gridData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const savePlanner = () => {
        alert("save!");
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Department: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            UserName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            JobTitle: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            Office: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            RateCard: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            CurrentRate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        });
        setGlobalFilterValue('');
    };

    const header =  (
        <div className="flex justify-content-between plannerHeader" style={{display: "inline"}}>
            <MultiSelect value={visibleColumns} options={fixedColumns} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-15rem" display="comma" placeholder="Select visible columns" maxSelectedLabels={3} />
            <div style={{display: "inline", position: "absolute", right: "300px", width: "400px"}}>
                <span className="p-input-icon-left" style={{paddingRight: "10px"}}>
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" style={{width: "300px", padding: "5px 5px 5px 30px"}} />
                </span>
                <Button type="button" icon="pi pi-filter-slash" outlined onClick={clearFilter} />
            </div>
            <div style={{display: "inline", position: "absolute", right: "200px"}}>
                <Button type="button" icon="pi pi-save" onClick={() => savePlanner()} data-pr-tooltip="Save" style={{backgroundColor: "transparent"}} />
            </div>
            <div style={{display: "inline", position: "absolute", right: "10px"}}>
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} data-pr-tooltip="CSV" style={{backgroundColor: "transparent"}} />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} data-pr-tooltip="XLS" style={{backgroundColor: "transparent"}}/>
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} data-pr-tooltip="PDF" style={{backgroundColor: "transparent"}}/>
            </div>
        </div>
    )

    const onCalculationFieldChange = (field, value) => {

    }

    const editor = (type, options, collection, placeholder, field) => {
        let rv = null;
        switch(type){
            case "selector":
                rv = (
                    <Dropdown
                        filter 
                        value={options.value.Id}
                        options={collection}
                        optionLabel="Label"
                        onChange={(e) => {
                            options.editorCallback(e.value.Label);
                        }}
                        placeholder={placeholder}
                    />
                );
                break;
            case "numeric":
                rv = (
                    <InputNumber className="numericInput" value={options.value} onValueChange={(e) => {
                        options.editorCallback(e.value);
                        onCalculationFieldChange(field, e.value);
                    }} />
                );
                break;
            default:
                break;
        }
        return rv;
    };

    const getHeaderTotalValue = (data, field) => {
        let val = 0;
        switch(field){
            case "BHrs":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += data.BHrs;
                        }
                    }
                }
                break;
            case "BFee":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += (data.BHrs * data.CurrentRate);
                        }
                    }
                }
                break;
            case "EACHrs":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += getForecastHours(data);
                        }
                    }
                }
                break;
            case "EACCost":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += (getForecastHours(data) * data.CurrentRate);
                        }
                    }
                }
                break;
            case "VarHrs":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += (data.BHrs - getForecastHours(data));
                        }
                    }
                }
                break;
            case "VarCost":
                if (gridData) {
                    for (let d of gridData) {
                        if (d.Department === data.Department) {
                            val += ((data.BHrs - getForecastHours(data)) * data.CurrentRate);
                        }
                    }
                }
                break;
            default:
                break;
        }
        return val.toFixed(2);
    }

    const getNewRowId = () => {
        return  gridData.length + 1;
    }

    const addEmployee = (deptId, department) => {
        const plannerAgencyId = 1; //getcurrent agency
        const assignmentEnd = null; 
        const assignmentStart = new Date().toISOString();
        const currentCurrencySymbol = "$"; //set the correct one 
        const rowId = getNewRowId(); 
        let forecast = [];
        let FCCols = getFCColumns(project.ProjStartDate, project.CurrentEndDate);
        FCCols.forEach((c) => {
            forecast.push({
                ActualHrs: 0,
                EacActualHrs: 0,
                ForecastHrs: 0,
                IsEacActHrs: 0,
                Rate: 0,
                SowProjHrsId: null,
                TimesheetApprove: null,
                TimesheetComplete: null,
                TimesheetRejected: null,
                UpdatedFromAllocation: null,
                WeekStart: c.toISOString()
            });
        });
        let collection = [...gridData];
        const newEmp = {
            Id: rowId,
            AHrs: 0,
            AgencyId: plannerAgencyId,
            AssignmentEnd: assignmentEnd,
            AssignmentId: null,
            AssignmentStart: assignmentStart,
            BHrs: 0,
            CurrencyMark: currentCurrencySymbol,
            CurrentRate: 0,
            CurrentTitle: 0,
            Department: department,
            DeptId: deptId,
            EmploymentType: null,
            Forecast: forecast,
            JobTitle: "",
            Office: "",
            OfficeRegionId: 0,
            TitleEndDate: null,
            TitleId: 0,
            TitleStartDate: null,
            UserId: null,
            UserName: ""
        };
        const depts = collection.map(el => el.DeptId);
        const pos = depts.lastIndexOf(deptId) + 1;
        collection.splice(pos, 0, newEmp);
        setGridData(collection);
    }

    const rowHeaderTemplate = (data) => {
        let left = 0;
        return [
            (
                <tr>
                    <td colSpan={visibleColumns.length}>
                        <span style={{paddingLeft: "10px", position: "sticky", left: "10px"}} className="font-bold">{data.Department}</span>
                        <div style={{paddingLeft: "10px", display: "inline-block"}}>
                            <Button type="button" icon="pi pi-plus" onClick={() => addEmployee(data.DeptId, data.Department)} data-pr-tooltip="Add empployee" style={{backgroundColor: "transparent", height: "10px", width: "10px", color: "white"}} />
                        </div>
                    </td>
                </tr>
            ),
            (
                <tr>
                    {
                        visibleColumns && visibleColumns.map((vc) => 
                        {
                            left += vc.width;
                            return  vc.totalInHeader ?
                                (
                                    <td style={{minWidth: (vc.width) + "px", textAlign: "right", position: vc.frozen ? "sticky" : "", left: left + "px"}}>
                                        {getHeaderTotalValue(data, vc.field)}
                                    </td>
                                ) 
                                :
                                (
                                    <td style={{minWidth: (vc.width + 15) + "px", position: "sticky", left: left + "px"}}>
                                    </td>
                                )
                        }
                        )
                    }
                </tr>
            )
        ];
    };

    const onRowGroupToggle = (e) => {
        setExpandedRows(e.data);
    }

    const footer = () => {
        return (
            <>Total:</>
        )
    }

    const forecastBodyTemplate = (row, date) => {
        const field = "f_changed_" + moment(date).format("YYYYMMDD");
        const colItem = row.Forecast.filter((f) => moment(f.WeekStart).format("YYY-MM-DD") == moment(date).format("YYY-MM-DD"));
        const exists = colItem && colItem[0] && colItem[0].ForecastHrs;
        let val = 0;
        if (row[field]){
            val = row[field];
            if (exists){
                colItem[0].ForecastHrs = val;
            } else {
                row.Forecast.push({
                    ForecastHrs: val,
                    WeekStart: date
                });
            }
        } else {
            if (exists) {
                val = colItem[0].ForecastHrs;
            }
        }
        const thisWeek = moment().startOf('week').add(1, "days").toDate();
        return (
            (date < thisWeek) ?
                (
                    <div className="forecastPast">
                        <span className="forecastPastValue">{colItem && colItem[0] ? colItem[0].ForecastHrs.toFixed(2) : "0.00"}</span>
                        <span className="actualPastValue">{colItem && colItem[0] ? colItem[0].ActualHrs.toFixed(2) : "0.00"}</span> 
                    </div>
                )
                : 
                (
                    <div className="forecastPast">
                        <span className="forecastValue">{val.toFixed(2)}</span>
                    </div>
                )
        )
    }

    const onForecastCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        if (!isNaN(newValue) && newValue > 0) {
            rowData.Forecast[0].ForecastHrs = newValue;
        }
        else {
            event.preventDefault();
        }

        refreshFCFormulas(e);
    }

    const refreshFCFormulas = (e) => {
        const fcHrs = getForecastHours(e.newRowData);
        e.newRowData.EACHrs = fcHrs;
        e.newRowData.EACCost = (fcHrs * e.rowData.CurrentRate);
        e.newRowData.VarHrs = (e.value - fcHrs);
        e.newRowData.VarCost = ((e.value - fcHrs) * e.rowData.CurrentRate);
        let tempData = [...gridData];
        let rowIndex = tempData.findIndex((a)=> a.AssignmentId == e.newRowData.AssignmentId);
        if (rowIndex > -1){
            tempData[rowIndex] = e.newRowData;
        }
        setGridData(tempData);
    }
    
    const onForecastCellEditInit = (e) => {
        
    }

    const getForecastHours = (row) => {
        let rv = 0 ;
        const thisWeek = moment().startOf('week').add(1, "days").toDate();
        row.Forecast.forEach((f) => {
            if (moment(f.WeekStart).toDate() >= thisWeek){
                rv += f.ForecastHrs;
            }
        });
        return rv;
    }

    const columnTemplate = (row, item) => {
        let rv = null;
        switch(item.format){
            case "numeric2":
                rv = row[item.field].toFixed(2);
                break;  
            case "calculated":
                switch(item.field){
                    case "BFee":
                        rv = (row.BHrs * row.CurrentRate).toFixed(2);
                        break;
                    case "EACHrs":
                        rv = (getForecastHours(row)).toFixed(2);
                        break;
                    case "EACCost":
                        rv = (getForecastHours(row) * row.CurrentRate).toFixed(2);
                        break;
                    case "VarHrs":
                        rv = (row.BHrs - getForecastHours(row)).toFixed(2);
                        break;
                    case "VarCost":
                        rv = ((row.BHrs - getForecastHours(row)) * row.CurrentRate).toFixed(2);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return rv;
    }

    const getSelectionList = (field) => {
        //return the right list from the endpoints
        let rv = [];
        switch(field){
            case "Office":
                rv = [{Id: 1, Label: "US"}, {Id: 2, Label: "AR"}, {Id: 3, Label: "BR"}];
                break;
            case "JobTitle":
                rv = [{Id: 1, Label: "Developer"}, {Id: 2, Label: "Designer"}, {Id: 3, Label: "Manager"}];
                break;
            case "UserName":
                rv = [{Id: 1, Label: "John Due"}, {Id: 2, Label: "Peter Toe"}, {Id: 3, Label: "Mary Lopez"}];
                break;
            default:
                break;
        }
        return rv;
    } 

    const afterEditBHrs = (e, item) => {
        e.newRowData.BFee = (e.value * e.rowData.CurrentRate); 
        refreshFCFormulas(e);
    }

    const getGrid = () => {
        const thisWeek = moment().startOf('week').add(1, "days").toDate();
        return (
            <DataTable ref={dt} value={gridData} header={header} tableStyle={{ minWidth: '2000px', minHeight: "200px", height: "200px" }} scrollable scrollHeight="70vh" showGridlines={true} sortMode="multiple" sortField="Department" sortOrder={-1} removableSort emptyMessage="No Employees" cellSelection selectionMode="multiple" rowGroupMode="subheader" dataKey="Id" groupRowsBy="Department" rowGroupHeaderTemplate={rowHeaderTemplate} expandableRowGroups expandedRows={expandedRows} onRowToggle={onRowGroupToggle} size="small" footer={footer} className="planner" columnResizeMode="expand" 
            onContextMenu={(e) => cm.current.show(e.originalEvent)} contextMenuSelection={selectedCMItem} onContextMenuSelectionChange={(e) => setSelectedCMItem(e.value)}  filters={filters} globalFilterFields={['Department', 'Office', 'JobTitle', 'UserName', 'RateCard', 'CurrentRate']}
            >
                {
                    visibleColumns.map((item) => {
                        return (
                            <Column key={item.field} editor={item.editor == null ? null : (options) => editor(item.editor, options, getSelectionList(item.field), "Select " + item.header, item.field)} field={item.field} header={item.header} frozen={item.frozen} sortable={item.sortable} style={{minWidth: item.width + "px", width: item.width + "px"}} className={item.className == null ? null : item.className} body={item.format == null ? null : (row) => columnTemplate(row, item)} onCellEditComplete={ item.field == "BHrs" ? (e) => afterEditBHrs(e, item) : null}></Column>
                        )
                    })
                }
                {
                    gridFCColumns.map((item) => {
                        return (
                            <Column header={moment(item).format("MMM DD")} style={{minWidth: "100px", maxWidth: "100px", textAlign: "center"}} body={(row) => forecastBodyTemplate(row, item)} editor={moment(item).toDate() < thisWeek ? null : (options) => editor("numeric", options, null, "", item.field)} onCellEditComplete={onForecastCellEditComplete} onCellEditInit={onForecastCellEditInit} field={"f_changed_" + moment(item).format("YYYYMMDD") }></Column>
                        )
                    })
                }
            </DataTable>
        )
    }

    const cmModel = [
        { label: 'Change Assigment End Date', command: () => { alert("Change date")} },
        { label: 'Remove Employee', command: () => { alert("Remove employee")} },
    ];

    const planner =
        selectorProjects && (
            selectorProjects.isLoading ? 
                <Loading text="Loading page..." />
            :
                (
                    <>
                        <div className="selector-nav">
                            <HeaderPageStyled
                                top={topNavHeight}
                            />
                        </div>
                        <PageWrapper
                            minHeight="500px"
                            displayFooter={true}
                            paddingBottom="10px"
                            breadcrumbMarginBottom={0}
                            customAuthPath={GlobalConfig.routes.projectPlanner}
                        >
                            <span style={headerItemStyle}>My Projects:</span>
                            <DropdownSingleSelectWithoutSearch
                                className="dashboard-selector myprojectsDDL"
                                isSmallSize={true}
                                options={projectsOptions}
                                value={projectId}
                                onChange={handleOnChange}
                                disabled={
                                    selectorProjects.isLoading ||
                                    projectsOptions?.length === 0
                                }
                                width="600px"
                                style={{zIndex: "1500"}}
                            />
                            <div style={headerItemStyleSelected}>
                                <span style={headerItemStyle}>Start date:</span>
                                <span style={headerItemStyle}>{moment(project?.ProjStartDate).format('MM/DD/YYYY')}</span>
                                <span style={headerItemStyle}>End date:</span>
                                <span style={headerItemStyle}><Calendar className="endDateCalendar" value={moment(project?.CurrentEndDate).toDate()} dateFormat="mm/dd/yy" onChange={(e) => project.CurrentEndDate = e.value} /></span>
                                <span style={headerItemStyle}>Total Fee:</span>
                                <span style={headerItemStyle}>{"0.00"}</span>
                            </div>
                            {
                                dataLoading ? 
                                <Loading text="Loading..." /> :
                                gridData != null ? getGrid() : ""
                            }
                            <ContextMenu model={cmModel} ref={cm} onHide={() => setSelectedCMItem(null)} />
                        </PageWrapper>
                    </>
                )
        )

    return (
        <>
            {planner}
        </>
    );
}

export default ProjectPlanner;