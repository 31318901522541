import VStack from "components/design-system/layout/VStack";
import TextInput from "components/design-system/forms/TextInput";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useAgencies from "./useAgencies";
import { AGENCY_TYPES, getTypeOption } from "./utils";

const attributesOptions = [
    { label: "Active", value: "Active" },
    { label: "Billable", value: "CanHoldClient" },
    { label: "Groups", value: "HasGroup" },
    { label: "Hires", value: "CanHire" },
];

function transformAgencyDataToFormData(agencyData = {}) {
    let parent;

    switch (agencyData.ParentId) {
        case null:
            parent = "none";
            break;
        case agencyData.Id:
            parent = "";
            break;
        default:
            parent = agencyData.ParentId;
            break;
    }

    return {
        name: agencyData.Name,
        type: getTypeOption(agencyData.TypeId)?.value,
        parent,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) => agencyData[item])
            .join(","),
    };
}

function transformFormDataToAgencyData(formData = {}, agencyData = {}) {
    const newAgencyData = { ...agencyData };

    newAgencyData.Name = formData.name;
    newAgencyData.Type = formData.type;
    newAgencyData.TypeId = formData.type;
    newAgencyData.ParentId =
        formData.parent === "none" ? null : formData.parent;

    attributesOptions.forEach((attribute) => {
        newAgencyData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });

    return newAgencyData;
}

function getParentAgencyOptions(agencies = [], currentAgencyId = null) {
    return agencies?.reduce(
        (acc, agency) => {
            return currentAgencyId === agency.Id
                ? acc
                : [
                      ...acc,
                      {
                          value: agency.Id,
                          label: agency.Name,
                      },
                  ];
        },
        [
            {
                value: "none",
                label: "None",
            },
        ]
    );
}

const AgencyForm = ({ agencyIdToEdit, onClose }) => {
    const { agencies, createAgency, updateAgency } = useAgencies();

    if (agencies.isLoading) {
        return <div>Loading...</div>;
    }

    if (agencies.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof agencyIdToEdit !== "undefined";
    const modifier = isEditing ? updateAgency : createAgency;

    const currentAgencyData = agencies?.data?.Items?.find(
        (agency) => agency.Id === agencyIdToEdit
    );

    if (isEditing && !currentAgencyData) {
        return <div>Could not find agency</div>;
    }

    const onSubmit = (formData) => {
        const agencyData = transformFormDataToAgencyData(
            formData,
            currentAgencyData
        );

        modifier.mutateAsync(agencyData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={isEditing ? currentAgencyData.Name : agencyData.Name}
                />
            );
        });
    };

    const parentAgencyDropdownOptions = getParentAgencyOptions(
        agencies?.data?.Items,
        currentAgencyData?.Id
    );

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformAgencyDataToFormData(currentAgencyData)}
        >
            <VStack spacing="Three">
                <VStack>
                    <TextInput
                        name="name"
                        label="Name"
                        validations={{
                            required: true,
                        }}
                        placeholder="Agency Name"
                    />
                    <Dropdown
                        name="type"
                        validations={{
                            required: true,
                        }}
                        label="Type"
                        options={AGENCY_TYPES}
                    />
                    <Dropdown
                        name="parent"
                        validations={{
                            required: true,
                        }}
                        label="Parent Agency"
                        options={parentAgencyDropdownOptions}
                    />
                    <Options
                        name="attributes"
                        label="Attributes"
                        options={attributesOptions}
                    />
                </VStack>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: isEditing ? "Save changes" : "Add Agency",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        onClick: onClose,
                    }}
                    as="fieldset"
                    disabled={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default AgencyForm;
