import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";

export const useRetainers = () => {
    const { getDataAsync } = useApi();

    const getRetainersFilters = async (clientfamiliesIds, clientIds) => {
        const params = {
            clientFamilies: clientfamiliesIds,
            clientIds: clientIds,
        };
        return getDataAsync("retainersFilters", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getRetainersFiltersByClientFamily = async (clientFamilyId) => {
        const urlParams = [{ name: "$clientFamilyId", value: clientFamilyId }];
        return getDataAsync("retainersFiltersByClientFamily", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json();
        });
    };

    const getRetainersFiltersByClient = async (clientId) => {
        const urlParams = [{ name: "$clientId", value: clientId }];
        return getDataAsync("retainersFiltersByClient", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json();
        });
    };

    return useMemo(() => {
        return {
            getRetainersFilters,
            getRetainersFiltersByClientFamily,
            getRetainersFiltersByClient,
        };
    }, []);
};
