import React from "react";
import PropTypes from "prop-types";
import Project from "../Project/Project";

const MemoizedProject = React.memo(Project);

const ClientDetail = ({ data, employee }) => {
    return (
        <>
            {data.map((project, index) => (
                <MemoizedProject
                     key={employee.userId + "_" + project.jobId}
                     project={project}
                     employee={employee}
                     index={index}
                />
            ))}
        </>
    );
};

ClientDetail.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ClientDetail;