import ApplicationsConfig from "configs/Applications";
import { useApiRequest } from "utilities/API/Helpers/ApiRequest";
import { useUser } from "utilities/API/Users";

export const useSkillSearch = () => {
    const { callSkillSearch } = useApiRequest();
    const getProfile = async (email) => {
        return callSkillSearch("/api/user/email/" + email, {
            method: "GET",
        });
    };
    return getProfile;
};

export const useSkillSearchProfileUrlByEmployeeEmailGetter = () => {
    const getProfile = useSkillSearch();

    const getter = async (email) => {
        try {
            const profile = await getProfile(email);

            if (profile.data?.length) {
                return `${ApplicationsConfig.SkillSearch.frontend}/#/employee/${profile.data[0].id}`;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    return getter;
};

export const useSkillSearchProfileUrlByEmployeeIdGetter = () => {
    const { getUserDetail } = useUser();
    const getUrlByEmail = useSkillSearchProfileUrlByEmployeeEmailGetter();

    const getter = async (employeeId) => {
        try {
            const userDetail = await getUserDetail([
                { name: "$userId", value: employeeId },
            ]);

            const url = getUrlByEmail(userDetail.Email);

            return url;
        } catch (error) {
            return null;
        }
    };

    return getter;
};
