import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ToggleButton from "components/design-system/controls/toggleButton/ToggleButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import BlockHead from "components/design-system/ui/BlockHead";
import SectionHead from "components/design-system/ui/SectionHead";
import { sendNotification } from "utilities/Notification";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";

import useAccessGroupPageAccessApi from "pages/admin/access-groups/single/useAccessGroupPageAccess";
import useAccessGroupApi from "pages/admin/access-groups/single/useAccessGroup";
import AccessGroupPageAccessManagePermissions from "./AccessGroupPageAccessManagePermissions";

const StyledList = styled(VStack)`
    padding: 8px 24px;
    width: 621px;
    min-height: 436px;
    background: ${COLOR_VALUES.White};
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
`;

const ListWrapper = styled(HStack)`
    padding: 16px 0;
    gap: 10px;
    label {
        align-items: inherit;
    }
`;

const ChildListWrapper = styled(VStack)`
    padding: 0 0 0 32px;
`;

const ListContent = styled(VStack)`
    padding: 0;
    gap: 16px;
    flex-grow: 1;
    align-items: start;
`;

const StyledToggleButton = styled(ToggleButton)`
    gap: 48px;
    font-size: revert;
`;

const StyledDivider = styled.div`
    border: 1px solid ${COLOR_VALUES.Gray_6};
`;

const FormPresenter = () => {
    const { groupId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { useAccessGroupPageAccess } = useAccessGroupPageAccessApi();

    const pageAccessData = useAccessGroupPageAccess(groupId);

    const action = searchParams.get("action");
    const pageId = searchParams.get("pageId");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "manageActionPermissions") {
        return (
            <Modal
                width={512}
                title="Manage Action Permissions"
                description={
                    pageAccessData.data &&
                    pageAccessData.data.find((a) => a.pageId == pageId).pageName
                }
                onRequestClose={onClose}
            >
                <AccessGroupPageAccessManagePermissions
                    groupId={groupId}
                    pageId={pageId}
                />
            </Modal>
        );
    }

    return <></>;
};

const ListContentCard = ({
    pageName,
    pageDescription,
    enabled,
    hasActions,
    pageId,
}) => {
    return (
        <ListContent>
            <BlockHead title={pageName} description={pageDescription} />
            {enabled && hasActions && (
                <>
                    <TertiaryButton
                        leadingIcon="filter"
                        label="Manage Action Permission"
                        to={`?action=manageActionPermissions&pageId=${pageId}`}
                        replace={true}
                    />
                </>
            )}
        </ListContent>
    );
};

const AccessGroupPageAccessPage = () => {
    const { groupId } = useParams();

    const { useAccessGroup } = useAccessGroupApi();
    const {
        useAccessGroupPageAccess,
        createAccessGroupPage,
        deleteAccessGroupPage,
    } = useAccessGroupPageAccessApi();

    const pageAccessData = useAccessGroupPageAccess(groupId);

    const groupData = useAccessGroup(groupId);

    const handleChange = (pageId, enabled) => {
        const modifier = enabled
            ? deleteAccessGroupPage
            : createAccessGroupPage;

        const allData = { groupId: groupId, pageId: pageId };

        modifier.mutateAsync(allData).then(() => {
            sendNotification(
                undefined,
                <>
                    Page Access Permission for{" "}
                    <strong> {groupData.data.name} </strong> have been updated
                </>
            );
        });
    };

    return (
        <>
            <FormPresenter />
            {pageAccessData.isLoading ? (
                <>Loading...</>
            ) : pageAccessData.error ? (
                <>Error</>
            ) : (
                <VStack spacing="Two">
                    <SectionHead
                        title="Page Access permissions"
                        description="Manage the pages this Access Group has permission to access"
                    />
                    <HStack spacing="Two">
                        <StyledList>
                            {pageAccessData.data
                                .filter(
                                    (parentData) =>
                                        parentData.pageParentId === null
                                )
                                .map((data, index) => (
                                    <VStack
                                        spacing="Zero"
                                        key={index}
                                        style={{ padding: 0 }}
                                    >
                                        <ListWrapper>
                                            <ListContentCard {...data} />
                                            <StyledToggleButton
                                                defaultChecked={data.enabled}
                                                onChange={() =>
                                                    handleChange(
                                                        data.pageId,
                                                        data.enabled
                                                    )
                                                }
                                                label={["Off", "On"]}
                                            />
                                        </ListWrapper>
                                        <StyledDivider />
                                        {pageAccessData.data
                                            .filter(
                                                (childData) =>
                                                    childData.pageParentId ===
                                                    data.pageId
                                            )
                                            .map((data, index) => (
                                                <ChildListWrapper key={index}>
                                                    <ListWrapper>
                                                        <ListContentCard
                                                            {...data}
                                                        />
                                                        <StyledToggleButton
                                                            defaultChecked={
                                                                data.enabled
                                                            }
                                                            onChange={() =>
                                                                handleChange(
                                                                    data.pageId,
                                                                    data.enabled
                                                                )
                                                            }
                                                            label={[
                                                                "Off",
                                                                "On",
                                                            ]}
                                                        />
                                                    </ListWrapper>
                                                    <StyledDivider />
                                                </ChildListWrapper>
                                            ))}
                                    </VStack>
                                ))}
                        </StyledList>
                    </HStack>
                </VStack>
            )}
        </>
    );
};

export default AccessGroupPageAccessPage;
