import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCombobox } from "downshift";

import TextInput from "components/design-system/controls/input/TextInput";
import { DropdownWrapper } from "components/design-system/controls/dropdown/DropdownStyledComponents";
import DropdownList from "components/design-system/controls/dropdown/DropdownList";
import useDropdownListOptions from "components/design-system/controls/dropdown/useDropdownListOptions";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";

const TextAutocomplete = React.forwardRef(
    (
        {
            items = [],
            value,
            placeholder = "Select",
            onChange = () => void 0,
            itemsAreLoading = false,
            disabled = false,
            feedbackType,
            clearDataOnUnmount = false,
            disabledOptionsList = [],
            position
        },
        ref
    ) => {
        const {
            filteredOptions,
            filterValue,
            setFilterValue,
            internalFilterUIShown,
        } = useDropdownListOptions(items, {
            usesExternalFilterUI: true,
            unfilteredResultListShowsEmpty: true,
        });

        const selectedItem = items.find((item) => item.value === value) ?? null;

        useEffect(() => {
            if (clearDataOnUnmount) {
                return () => {
                    onChange("");
                };
            }
        }, []);

        const {
            isOpen,
            getMenuProps,
            getInputProps,
            getComboboxProps,
            highlightedIndex,
            getItemProps,
        } = useCombobox({
            inputValue: filterValue,
            items: filteredOptions,
            itemToString: (item) => (item ? String(item.label) : ""),
            selectedItem,
            onInputValueChange: ({ inputValue }) => {
                setFilterValue(inputValue);
                !inputValue && onChange("");
            },
            onSelectedItemChange: (item) => {
                onChange(item.selectedItem?.value ?? "");
            },
            stateReducer: (state, actionAndChanges) => {
                const { type, changes } = actionAndChanges;

                switch (type) {
                    // ensures that if you leave the field with a text value that doesn't match the selected item's value, the selected item is cleared
                    // ex: if you type "Piz" and then choose "Pizza" from the options, "Pizza" becomes the selected item. If you then delete some characters and leave the field with "Piz", "Piz" doesn't match "Pizza" and the selected item is cleared to ensure form data is submitted accurately
                    case useCombobox.stateChangeTypes.InputBlur:
                        return state.inputValue !== state.selectedItem?.label
                            ? {
                                  ...changes,
                                  selectedItem: null,
                              }
                            : changes;
                    default:
                        return changes;
                }
            },
        });

        return (
            <DropdownWrapper>
                <div {...getComboboxProps()}>
                    <TextInput
                        type="search"
                        {...getInputProps({
                            disabled,
                            ref,
                        })}
                        feedbackType={feedbackType}
                        placeholder={placeholder}
                        showClearIcon={true}
                    />
                </div>
                <DropdownList
                    isOpen={isOpen}
                    options={filteredOptions}
                    optionsAreLoading={itemsAreLoading}
                    selectedValue={selectedItem?.value}
                    highlightedIndex={highlightedIndex}
                    getMenuProps={getMenuProps}
                    getItemProps={getItemProps}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    internalFilterUIShown={internalFilterUIShown}
                    disabledOptionsList={disabledOptionsList}
                    position={position}
                />
            </DropdownWrapper>
        );
    }
);

TextAutocomplete.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    itemsAreLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    feedbackType: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
};

export default TextAutocomplete;
