import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import GlobalConfig from "configs/Global";
import SessionContext from "context/app/SessionContext";
import useIncompleteTimesheetApi from "pages/timesheet/incomplete-timesheet/useIncompleteTimesheet";
import PageWrapper from "pages/PageWrapper";
import useManageUserTimeOff from "pages/timesheet/manage-time-off/useManageUserTimeOff";
import ManageTimeOffContext from "context/ManageTimeOff/ManageTimeOffContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const TimesheetMainPage = () => {
    const session = useContext(SessionContext);
    const location = useLocation();
    const { insightsTrace } = useInsigths();
    const { useIncompleteTimesheet } = useIncompleteTimesheetApi();
    const { useManageUserTimeOffApi } = useManageUserTimeOff();
    const incompleteTimesheetData = useIncompleteTimesheet(session.legacyId);
    const userTimeOffRequests = useManageUserTimeOffApi(session.legacyId);
    const {
        upcomingPendingTimeOffRequestCount,
        setUpcomingPendingTimeOffRequestCount,
        setManageTimeOffRequestsData,
    } = useContext(ManageTimeOffContext);
    const currentDate = new Date().getTime();

    const now = new Date();
    insightsTrace("VIEW", "TimesheetMainPage", {
        userId: session.id,
        email: session.email,
        user: session.fullName,
        localDate: now.toString(),
        UTCDate: now.toUTCString(),
    });

    useEffect(() => {
        if (!userTimeOffRequests.isLoading) {
            setManageTimeOffRequestsData(userTimeOffRequests?.data);
        }
    }, [userTimeOffRequests]);

    useEffect(() => {
        if (!userTimeOffRequests.isLoading) {
            const pendingTimeOffCount = userTimeOffRequests?.data?.filter(
                (element) =>
                    (currentDate < new Date(element.firstDayOut).getTime() ||
                        new Date().toDateString() ===
                            new Date(element.firstDayOut).toDateString()) &&
                    element.status === "Pending"
            );

            setUpcomingPendingTimeOffRequestCount(pendingTimeOffCount?.length);
        }
    }, [userTimeOffRequests]);

    if (incompleteTimesheetData.isLoading || userTimeOffRequests.isLoading) {
        return <>Loading...</>;
    }

    if (incompleteTimesheetData.isError || userTimeOffRequests.isError) {
        return <>Error</>;
    }

    const count = incompleteTimesheetData.data?.length;
    const description = `${session.currentTitle}, ${
        session.currentDepartment
    } ${session.freelancer ? "(Freelancer)" : ""} ${
        session.contractor ? "(Contractor)" : ""
    }`;

    let tabs = [];

    if (!session.freelancer && !session.contractor) {
        let item = {
            label: "Time Off",
            to: GlobalConfig.routes.timeOff,
        };
        tabs.splice(0, 0, item);
        item = {
            label: `Manage Time Off Requests ${
                upcomingPendingTimeOffRequestCount > 0
                    ? `(${upcomingPendingTimeOffRequestCount})`
                    : ""
            }`,
            to: GlobalConfig.routes.manageTimeOff,
        };
        tabs.splice(0, 0, item);
    }

    if (session.timesheetRequired) {
        let item = {
            label: count > 0 && `Incomplete Timesheet (${count})`,
            to: GlobalConfig.routes.timesheetIncomplete,
        };
        tabs.splice(0, 0, item);
        item = {
            label: "Timesheet",
            to: GlobalConfig.routes.timesheetGrid,
        };
        tabs.splice(0, 0, item);
    }

    tabs.push({
        label: "Holidays",
        to: GlobalConfig.routes.timesheetHolidays,
    });

    const isholidaysTab =
        location.pathname ===
        `${GlobalConfig.routes.timesheetLandingPage}/${GlobalConfig.routes.timesheetHolidays}`;

    return (
        <>
            <PageWrapper
                {...(isholidaysTab && {
                    customAuthPath: `${GlobalConfig.routes.timesheetLandingPage}/${GlobalConfig.routes.timesheetGrid}`,
                })}
                title={session.fullName}
                menuOptions={[]}
                description={description}
                listOptions={[
                    {
                        name: `${session.currentPayOffice}`,
                        icon: "office-pay",
                        text: "Pay Office",
                    },
                    {
                        name: `${session.currentSeattingOffice}`,
                        icon: "office-seat",
                        text: "Seat Office",
                    },
                ]}
                tabs={tabs}
            >
                <Outlet />
            </PageWrapper>
        </>
    );
};

export default TimesheetMainPage;
