import { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";
import ControlledCheckbox from "components/design-system/controls/checkbox/BaseCheckbox";
import Tooltip from "../../ui/Tooltip";

const Wrapper = styled.div`
    width: max-content;
    height: auto;
    font-size: 0;
    display: flex;
    align-items: center;

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: not-allowed;
        `};
`;

const useCheckboxState = (
    defaultChecked = false,
    defaultIndeterminate = false,
    defaultOnChange = () => void 0
) => {
    const [internalChecked, setInternalChecked] = useState(
        defaultIndeterminate ? false : defaultChecked
    );
    const [internalIndeterminate, setInternalIndeterminate] =
        useState(defaultIndeterminate);

    const internalOnChange = (event) => {
        setInternalChecked(event.target.checked);
        setInternalIndeterminate(false);
        defaultOnChange(event);
    };

    return [internalChecked, internalIndeterminate, internalOnChange];
};

export const StyledLabel = styled(HStack).attrs({ forwardedAs: "label" })`
    position: relative;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  color: ${COLOR_VALUES.Gray_4};
                  pointer-events: none;
                  user-select: none;
              `
            : css`
                  &:hover {
                      color: ${COLOR_VALUES.Gray_2};
                  }
              `}
`;

const Checkbox = forwardRef(
    (
        {
            label,
            checked,
            indeterminate,
            defaultChecked,
            defaultIndeterminate,
            onChange,
            disabled,
            title,
            ...rest
        },
        ref
    ) => {
        const isControlled = typeof checked === "boolean";
        const [internalChecked, internalIndeterminate, internalOnChange] =
            useCheckboxState(defaultChecked, defaultIndeterminate, onChange);

        const actualChecked = isControlled ? checked : internalChecked;
        const actualIndeterminate = isControlled
            ? indeterminate
            : internalIndeterminate;
        const actualOnChange = isControlled ? onChange : internalOnChange;

        const CheckboxControl = (
            <ControlledCheckbox
                checked={actualChecked}
                indeterminate={actualIndeterminate}
                onChange={actualOnChange}
                disabled={disabled}
                $expandHitArea={label}
                {...rest}
            />
        );

        return (
            <Tooltip text={title}>
                <Wrapper $disabled={disabled} ref={ref}>
                    {label ? (
                        <StyledLabel
                            spacing="Zero_5"
                            align="center"
                            $disabled={disabled}
                        >
                            {CheckboxControl}
                            <Text
                                variant={
                                    actualChecked ? "Body_2_2" : "Body_2_1"
                                }
                            >
                                {label}
                            </Text>
                        </StyledLabel>
                    ) : (
                        CheckboxControl
                    )}
                </Wrapper>
            </Tooltip>
        );
    }
);

Checkbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    defaultIndeterminate: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Checkbox;
