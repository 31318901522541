import styled from "styled-components";

import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

const Container = styled(VStack)`
    margin-top: 1.5rem;
    text-align: left;
    padding: 0px;
    gap: 8px;
`;

const ButtonWrapper = styled(VStack)`
    align-items: start;
    padding: 0px;
    gap: 4px;
`;

const LinkGroup = ({ linkData, label }) => {
    const data = Object.values(linkData)?.every((e) => !e?.name);

    return data ? (
        <></>
    ) : (
        <>
            <Container>
                {label && (
                    <Text variant="Descriptive_1_1" color="Gray_2">
                        {label}
                    </Text>
                )}
                {linkData.map(
                    (data, index) =>
                        data.name && (
                            <ButtonWrapper key={index}>
                                <TertiaryButton
                                    trailingIcon="caret-right"
                                    label={data.name}
                                    href={data.to}
                                    key={index}
                                    target="_blank"
                                />
                            </ButtonWrapper>
                        )
                )}
            </Container>
        </>
    );
};

export default LinkGroup;
