import PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled.svg`
    path {
        fill: ${(props) => props.color};
    }

    &:hover {
        path {
            fill: ${(props) => (props.hover ? props.hoverColor : props.color)};
        }
    }
`;

const Add = ({ size, color, _viewBox, hover, hoverColor }) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={size}
            height={size}
            color={color}
            hover={hover}
            hoverColor={hoverColor}
        >
            <path d="M11.0827 7.4165H8.58268V4.9165H7.41602V7.4165H4.91602V8.58317H7.41602V11.054H8.58268V8.58317H11.0827V7.4165Z" />
            <path d="M8 15C4.13833 15 1 11.8617 1 8C1 4.13833 4.13833 1 8 1C11.8617 1 15 4.13833 15 8C15 11.8617 11.8617 15 8 15ZM8 2.16667C4.78583 2.16667 2.16667 4.78583 2.16667 8C2.16667 11.2142 4.78583 13.8333 8 13.8333C11.2142 13.8333 13.8333 11.2142 13.8333 8C13.8333 4.78583 11.2142 2.16667 8 2.16667Z" />
        </Icon>
    );
};

Add.defaultProps = {
    size: "18",
    color: global.config.colors.gray2,
    hover: false,
    hoverColor: global.config.colors.black,
};

Add.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    hover: PropTypes.bool,
    hoverColor: PropTypes.string,
};

export default Add;
