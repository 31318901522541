import { useRef } from "react";
import styled from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";

const svgWrapper = (svg) => `url('data:image/svg+xml;utf8,${svg}')`;

const getCircle = (xCoordinate) =>
    svgWrapper(
        `<svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="${xCoordinate}" cy="8" r="6" fill="white"/>
        </svg>`
    );

const StyledToggleButton = styled.input`
    width: 32px;
    height: 16px;
    appearance: none;
    border-radius: 8px;
    cursor: pointer;

    ::after {
        width: 32px;
        height: 16px;
        border-radius: 8px;
        display: block;
    }

    &:disabled {
        pointer-events: none;
        user-select: none;
    }

    &:not(:checked) {
        box-shadow: 0px 0px 0px 1px inset ${COLOR_VALUES.Gray_5};
        background: ${COLOR_VALUES.Gray_5};
        content: ${getCircle(8)};
        &:not(:disabled) {
            &:hover {
                box-shadow: 0px 0px 0px 1px inset ${COLOR_VALUES.Gray_3};
            }

            &:active {
                box-shadow: 0px 0px 0px 1px inset ${COLOR_VALUES.BLACK};
            }
        }
    }

    &:checked {
        background: ${COLOR_VALUES.Gray_3};

        &:not(:disabled) {
            background: ${COLOR_VALUES.Black};

            &:hover {
                background: ${COLOR_VALUES.Gray_2};
            }

            &:active {
                background: ${COLOR_VALUES.Gray_2};
                box-shadow: 0px 0px 0px 1px inset ${COLOR_VALUES.BLACK};
            }
        }
    }

    &:checked {
        content: ${getCircle(24)};
    }
    
    &:before {
        position: absolute;
        inset: 0;
        content: "";
        display: ${(props) => (props.$expandHitArea ? "block" : "none")};
    }
}
`;

const ControlledToggleButton = ({ checked, onChange, ...props }) => {
    const elRef = useRef();
    const internalOnChange = (event) => {
        if (typeof onChange === "function") {
            onChange(event);
        }
    };

    return (
        <StyledToggleButton
            {...props}
            checked={checked}
            onChange={internalOnChange}
            type="checkbox"
            ref={elRef}
        />
    );
};

export default ControlledToggleButton;
