import React, { useState, useMemo } from "react";

const DrawerContext = React.createContext();

export const DrawerContextProvider = (props) => {
    const [displaySearch, setDisplaySearch] = useState(true);
    const [show, setShow] = useState(false);
    const [isCTAPrimaryAction, setIsCTAPrimaryAction] = useState(true);

    const handleCloseMasterDrawer = () => {
        setShow(false);
        setDisplaySearch(true);
        setIsCTAPrimaryAction(true);
    };

    const handleSearchEmployeeClick = () => {
        setShow(true);
    };

    const contextValue = useMemo(() => {
        return {
            show,
            setShow,
            displaySearch,
            setDisplaySearch,
            isCTAPrimaryAction,
            setIsCTAPrimaryAction,
            handleCloseMasterDrawer,
            handleSearchEmployeeClick,
        };
    }, [
        show,
        setShow,
        displaySearch,
        setDisplaySearch,
        isCTAPrimaryAction,
        setIsCTAPrimaryAction,
        handleCloseMasterDrawer,
        handleSearchEmployeeClick,
    ]);

    return (
        <DrawerContext.Provider value={contextValue}>
            {props.children}
        </DrawerContext.Provider>
    );
};

export default DrawerContext;
