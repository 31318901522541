import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import Text from "../UI/Text";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuList from "@material-ui/core/MenuList";
import { Input } from "@material-ui/core";
import Button from "../UI/Button";
import DownCaret from "components/Icon/DownCaret";
import IconButton from "@material-ui/core/IconButton";
import { truncateText } from "utilities/TruncateText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useStylesForMenu, useStylesForText } from "../../styles/MakeStyles";
import CustomMenuItem from "./CustomMenuItem";
import Tooltip from "components/UI/Tooltip";
import Loader from "components/UI/Loader";

const CustomListMenu = styled.div`
    svg {
        font-size: ${global.config.fonts.sizes.xsmall};
    }
`;

const InfoLabel = styled.div`
    padding: 1rem 0rem 0rem 1rem;
`;

const SelectedLabel = styled.span`
    font-size: ${global.config.fonts.sizes.small};
    > span {
        color: ${global.config.colors.gray2};
    }
`;

const Label = styled.span`
    font-size: ${global.config.fonts.sizes.small};
`;

const CustomMenuButton = styled.div`
    padding: 0.36rem 0.5625rem;
    border-radius: 0.25rem;
    min-height: 2rem;
    max-height: 2rem;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    &:hover {
        background-color: ${global.config.colors.gray7};
    }
`;

const MenuTab = styled(Tab)({
    textTransform: "none",
    minWidth: "5rem",
    fontSize: global.config.fonts.sizes.xsmall,
    fontFamily: global.config.fonts.families.light,
    width: "8.75rem",
    borderRadius: global.config.sizes.borderRadius,
    borderBottom: global.config.border.smallGray7,
});

const SearchInput = styled(Input)({
    fontSize: global.config.fonts.sizes.small,
    width: "100%",
    height: "3rem",
    paddingLeft: "1rem",
});

const MenuButton = styled.div`
    height: 2.5rem;
    border-top: ${global.config.border.small};
    > button.MuiButton-root {
        width: 100%;
        color: ${global.config.colors.black};
        background-color: ${global.config.colors.white};
        font-size: ${global.config.fonts.sizes.small};
        border-radius: 0;
        height: 2.375rem;
        > .MuiButton-label {
            justify-content: start;
        }
        :hover {
            background-color: ${global.config.colors.gray8} !important;
        }
    }
    .MuiButton-contained {
        box-shadow: initial;
    }
    svg {
        margin: 0.188rem 0.688rem;
        margin-left: 0;
    }
`;

const LoaderWrapper = styled.div`
    text-align: center;
    margin: 2rem 0;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const theme = useTheme();

    const StyledBox = styled.div`
        padding: 0rem;
        > ul {
            max-height: 12rem;
            overflow: scroll;
            margin-top: 0.5rem;
            padding-top: 0;
            padding-bottom: 0;

            li {
                font-size: ${theme.typography.smallLight.fontSize};
                font-family: ${theme.typography.smallLight.fontFamily};
                padding-right: 0.5rem;
                line-height: 1.5rem;
            }
        }
    `;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <StyledBox p={3}>{children}</StyledBox>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function PopperPopupState(props) {
    const {
        menuLabel,
        tabs,
        itemsLabel,
        action,
        itemAction,
        moreMenuOptions,
        getItemsFirstTab,
        getItemsSecondTab,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStylesForMenu();
    const [value, setValue] = useState(0);
    const [filter, setSearchFilter] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemsFirstTab, setItemsFirstTab] = useState(null);
    const [itemsSecondTab, setItemsSecondTab] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const classesText = useStylesForText();

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoupClose = () => {
        if (anchorEl !== null) {
            setAnchorEl(null);
        }
    };

    const handleChangeTab = (_event, newValue) => {
        setValue(newValue);
    };

    const handleChangeFilter = (event) => {
        setSearchFilter(event.target.value);
    };

    const handleAction = (event) => {
        action(event);
        handlePopoupClose();
    };

    const clearSelection = (e) => {
        e.stopPropagation();
        setSelectedItem(null);
        itemAction(e, null);
        if (props.onSelectItem) props.onSelectItem(null);
    };

    const loadData = () => {
        setIsLoadingData(true);
        if (!open) {
            getItemsFirstTab().then((r) => {
                setItemsFirstTab(r);
                setIsLoadingData(false);
            });
            getItemsSecondTab().then((r) => {
                setItemsSecondTab(r);
                setIsLoadingData(false);
            });
        }
    };

    const firstTabItems =
        itemsFirstTab &&
        itemsFirstTab
            .filter(
                (item) =>
                    filter === "" ||
                    item.Name.toLowerCase().includes(filter.toLowerCase())
            )
            .map((item, index) => (
                <CustomMenuItem
                    key={index}
                    value={item.Id}
                    name={item.Name}
                    owned={item.Own !== undefined ? item.Own : true}
                    moreMenuOptions={moreMenuOptions}
                    selectedItem={selectedItem}
                    onClickItem={function (event) {
                        setSelectedItem(item);
                        itemAction(event, item);
                        if (props.onSelectItem) props.onSelectItem(item);
                        handlePopoupClose();
                    }}
                    closeMenu={handlePopoupClose}
                />
            ));

    const secondTabItems =
        itemsSecondTab &&
        itemsSecondTab
            .filter(
                (item) =>
                    filter === "" ||
                    item.Name.toLowerCase().includes(filter.toLowerCase())
            )
            .map((item, index) => (
                <CustomMenuItem
                    key={index}
                    value={item.Id}
                    name={item.Name}
                    owned={item.Own !== undefined ? item.Own : true}
                    moreMenuOptions={moreMenuOptions}
                    selectedItem={selectedItem}
                    onClickItem={function (event) {
                        setSelectedItem(item);
                        itemAction(event, item);
                        if (props.onSelectItem) props.onSelectItem(item);
                        handlePopoupClose();
                    }}
                    closeMenu={handlePopoupClose}
                />
            ));

    const MenuTabItems = tabs.map((itemLabel, index) => (
        <MenuTab
            style={{
                fontSize: "0.7rem",
                textTransform: "none",
                minWidth: "8.75rem",
            }}
            key={index.toString()}
            label={itemLabel}
            {...a11yProps(index)}
        />
    ));

    useEffect(() => {
        setSelectedItem(props.value);
    }, [props.value]);

    return (
        <ClickAwayListener onClickAway={handlePopoupClose}>
            <CustomListMenu>
                <CustomMenuButton
                    className={open ? classes.openMenu : ""}
                    onClick={(e) => {
                        loadData();
                        handleClick(e);
                    }}
                >
                    <Label>
                        {menuLabel}
                        {selectedItem != null && (
                            <SelectedLabel>
                                <Tooltip
                                    placement="bottom"
                                    title={selectedItem.Name}
                                >
                                    : {truncateText(selectedItem.Name, 120)}
                                </Tooltip>
                                <IconButton
                                    aria-label="clear"
                                    onClick={clearSelection}
                                    size="small"
                                    className={classes.clearButton}
                                >
                                    <span style={{fontSize: "10px", position: "absolute", paddingLeft: "2px", fontWeight: "bold"}}>X</span>
                                </IconButton>
                            </SelectedLabel>
                        )}
                    </Label>
                    <DownCaret size="16"></DownCaret>
                </CustomMenuButton>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoupClose}
                    placement="bottom-start"
                    className={classes.customMenu}
                    disablePortal={true}
                >
                    <Box className={classes.root} variant="smallLight">
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            aria-label="custom groups tabs"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            {MenuTabItems}
                        </Tabs>
                        <SearchInput
                            placeholder="Search"
                            value={filter}
                            onChange={handleChangeFilter}
                            className={classes.searchInput}
                            style={{
                                fontSize: "0.7rem",
                                textTransform: "none",
                            }}
                        ></SearchInput>
                        {isLoadingData ? (
                            <LoaderWrapper>
                                <Loader height="1rem" width="1rem" />
                            </LoaderWrapper>
                        ) : (
                            <>
                                <InfoLabel>
                                    <Text
                                        variant="xxsmallLight"
                                        className={classesText.gray2}
                                    >
                                        {itemsLabel}
                                        {value === 0
                                            ? firstTabItems &&
                                              " (" + firstTabItems.length + ")"
                                            : secondTabItems &&
                                              " (" +
                                                  secondTabItems.length +
                                                  ")"}
                                    </Text>
                                </InfoLabel>
                                <TabPanel value={value} index={0}>
                                    <MenuList>{firstTabItems}</MenuList>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <MenuList>{secondTabItems}</MenuList>
                                </TabPanel>
                            </>
                        )}
                        <MenuButton>
                            <Button onClick={handleAction}>
                                {props.children}
                            </Button>
                        </MenuButton>
                    </Box>
                </Popper>
            </CustomListMenu>
        </ClickAwayListener>
    );
}

PopperPopupState.propTypes = {
    menuLabel: PropTypes.any.isRequired,
    tabs: PropTypes.any.isRequired,
    moreMenuOptions: PropTypes.node,
    getItemsFirstTab: PropTypes.any.isRequired,
    getItemsSecondTab: PropTypes.any.isRequired,
};
