import env from "react-dotenv";

const domain = window.location.protocol + "//" + window.location.host;

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        authority: env.BESTWORK_REACT_AZUREAD_AUTHORITY,
        clientId: env.BESTWORK_REACT_AZUREAD_CLIENT_ID,
        redirectUri: domain,
        postLogoutRedirectUri: domain,
        clientSecret: env.BESTWORK_REACT_AZUREAD_CLIENT_SECRET,
    },
    cache: {
        cacheLocation: "localStorage",
    },
};

export const msalPowerBIConfig = {
    auth: {
        authority: env.BESTWORK_REACT_AZUREAD_BI_AUTHORITY,
        clientId: env.BESTWORK_REACT_AZUREAD_BI_CLIENT_ID,
        postLogoutRedirectUri: domain,
        redirectUri: domain + "/businessIntelligence",
        clientSecret: env.BESTWORK_REACT_AZUREAD_BI_CLIENT_SECRET,
    },
    cache: {
        cacheLocation: "localStorage",
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
