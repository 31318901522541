import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";
import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

const useClientFamilyCorp = () => {
    const { getDataAsync } = useApi();

    const getClientFamilyCorpApiCall = useCallback(async ({
        pageSize = PAGER_INITIAL_PAGE_SIZE,
        sortBy = "",
        pageIndex = 0,
        active = "",
        agencyId = null,
        familyId = null,
        corporateId = null,
        clientId = null,
    }) => {
        const response = await getDataAsync("getClientFamilyCorp", {
            urlParams: [
                { name: "$sortBy", value: sortBy },
                { name: "$pageSize", value: pageSize },
                { name: "$pageNumber", value: pageIndex + 1 },
                { name: "$active", value: active },
                { name: "$agencyId", value: agencyId },
                { name: "$familyId", value: familyId },
                { name: "$corporateId", value: corporateId },
                { name: "$clientId", value: clientId },
            ],
        });
        return response.status != 204 ? response.json() : null;
    },
        [getDataAsync]
    );

    return useMemo(() => {
        const useClientFamilyCorpList = (dataObj) =>
            useQuery(
                ["clientFamilyCorpsList", dataObj],
                () => getClientFamilyCorpApiCall(dataObj),
                { keepPreviousData: true, staleTime: Infinity }
            );
        return {
            useClientFamilyCorpList
        };
    }, [getClientFamilyCorpApiCall]);
}

export default useClientFamilyCorp;
