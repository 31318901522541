import React, { useContext } from "react";
import SessionContext from "context/app/SessionContext";
import GlobalConfig from "configs/Global";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const TimesheetsPage = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const now = new Date();

    insightsTrace("VIEW", "TimesheetPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    if (!session.timesheetRequired && (session.freelancer || session.contractor)) {
        return <>You do not require to enter timesheets</>;
    }
    else if (session.timesheetRequired) {
        window.location.replace("/timesheets/employee/" + GlobalConfig.routes.timesheetGrid);
    }
    else {
        window.location.replace("/timesheets/employee/" + global.config.routes.timeOff);
    }
    return <></>;
};

export default TimesheetsPage;
