import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Alert from "../Icon/Alert";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    * > svg {
        position: absolute;
        pointer-events: none;
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        line-height: 1;
        right: 1rem;
        top: 1rem;
    }
    &:focus-within {
        > label {
            transform: translate(0, 10px) scale(0.75);
            color: ${global.config.colors.black};
        }
    }
`;
const Label = styled.label`
    position: absolute;
    pointer-events: none;
    transform: translate(0, 17px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: ${global.config.colors.gray2};
    font-size: ${global.config.fonts.sizes.small};
    line-height: 1;
    left: 1rem;
    ${(props) =>
        props.filled &&
        `
        transform: translate(0, 10px) scale(0.75);
    `}
`;
const TextField = styled.input`
    height: 3rem;
    border-radius: ${global.config.sizes.borderRadius};
    border: ${global.config.border.small3};
    padding: 1.5rem 2.5rem 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:focus {
        border: ${global.config.border.smallBlack};
    }
    ${(props) =>
        props.error &&
        `
        border: solid 0.01rem ${global.config.colors.red1};
        &:focus {
            box-shadow: 0 0 0 0.01rem ${global.config.colors.red1};
        }
    `}
`;
const Error = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${global.config.border.white};
    width: 3rem;
    height: 3rem;
    border-radius: 0.3rem;
`;

const TextInput = (props) => {
    const { label, type, defaultValue } = props;

    const [value, setValue] = useState(defaultValue);

    function handleChange(e) {
        setValue(e.target.value);
        props.onChange(e);
    }

    return (
        <Container>
            <TextField
                type={type}
                value={value}
                onChange={handleChange}
                error={
                    props.className && props.className.includes("error") > 0
                        ? true
                        : false
                }
            />
            <Label filled={value && value.length > 0 ? true : false}>
                {label}
            </Label>
            {props.className && props.className.includes("error") && (
                <Error>
                    <Alert />
                </Error>
            )}
        </Container>
    );
};

TextInput.defaultProps = {
    variant: "text",
    defaultValue: "",
};

TextInput.propTypes = {
    label: PropTypes.string,
};

export default TextInput;
