import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";

export const useTimeOff = () => {
    const { getDataAsync } = useApi();

    const getTimeOff = async (params) => {
        return getDataAsync("timeOff", { params: params }).then((payload) => {
            return payload.json();
        });
    };

    return useMemo(() => {
        return { getTimeOff };
    }, []);
};
