import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import BaseButton from "components/design-system/controls/button/BaseButton";
import Tooltip from "components/design-system/ui/Tooltip";

const Wrapper = styled.div`
    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed;
        `}
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `}
`;

const StyledSecondaryButton = styled(BaseButton)`
    display: inline-flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 0 0px 1px ${COLOR_VALUES.Black} inset;
    color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    padding: ${({ $reducePadding }) =>
        $reducePadding ? "14px 16px" : "14px 48px"};
    min-height: 48px;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "inherit")};

    ${(props) =>
        props.disabled
            ? css`
                  pointer-events: none;
                  color: ${COLOR_VALUES.Gray_3};
                  box-shadow: 0 0 0px 1px ${COLOR_VALUES.Gray_3} inset;
              `
            : css`
                  &:hover {
                      box-shadow: 0 0 0px 2px ${COLOR_VALUES.Gray_6} inset;
                  }

                  &:active {
                      box-shadow: 0 0 0px 2px ${COLOR_VALUES.Black} inset;
                  }
              `}
`;

const SecondaryButton = ({
    label,
    leadingIcon,
    trailingIcon,
    disabled = false,
    fullWidth = false,
    ...rest
}) => {
    const ariaLabel = rest["aria-label"];

    if (!label && !ariaLabel) {
        console.error("Icon-only button missing aria-label");
    }

    return (
        <Wrapper fullWidth={fullWidth} disabled={disabled}>
            <Tooltip text={ariaLabel} enabled={!label}>
                <StyledSecondaryButton
                    $reducePadding={!label}
                    disabled={disabled}
                    $fullWidth={fullWidth}
                    {...rest}
                >
                    {leadingIcon && <Icon name={leadingIcon} />}
                    {label && <Text variant="Headline_5">{label}</Text>}
                    {trailingIcon && <Icon name={trailingIcon} />}
                </StyledSecondaryButton>
            </Tooltip>
        </Wrapper>
    );
};

SecondaryButton.propTypes = {
    label: PropTypes.string,
    leadingIcon: PropTypes.oneOf(ICON_NAMES),
    trailingIcon: PropTypes.oneOf(ICON_NAMES),
    "aria-label": PropTypes.string,
    disabled: PropTypes.bool,
};

export default SecondaryButton;
