import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loading from "components/UI/Loading";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import Icon from "components/design-system/ui/Icon";
import SPACING from "components/design-system/config/spacing";
import VStack from "components/design-system/layout/VStack";
import PageWrapper, { LoadingWrapper } from "pages/PageWrapper";
import useAllPages from "pages/useAllPages";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import SessionContext from "context/app/SessionContext";

export const StyledLink = styled(Link)`
    flex: 0 0 31%;
    max-width: 31%;

    &:hover {
        color: unset;
        text-decoration: none;
    }
`;

export const AccessGroupCard = styled(VStack)`
    min-width: 10rem;
    width: 100%;
    min-height: 120px;
    background: ${COLOR_VALUES.White};
    padding: 16px 16px 8px 16px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
`;

export const Card = ({ title, description, to }) => (
    <StyledLink to={to}>
        <AccessGroupCard>
            <HStack
                align="center"
                justify="space-between"
                spacing={SPACING.One}
            >
                <Text variant="Headline_4" color="Black">
                    {title}
                </Text>
                <Icon
                    style={{ flexShrink: 0 }}
                    size={13}
                    name="caret-right"
                    color="Black"
                />
            </HStack>
            <Text variant="Body_2_1" color="Gray_2">
                {description}
            </Text>
        </AccessGroupCard>
    </StyledLink>
);

const AdminMainPage = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const { pages } = useAllPages();

    const now = new Date();
    insightsTrace("VIEW", "AdminMainPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    if (pages.isLoading) {
        return (
            <LoadingWrapper>
                <Loading text="Loading Page" />
            </LoadingWrapper>
        );
    }

    if (pages.isError) {
        return <>Error</>;
    }

    const adminModuleElements = pages.data.filter((d) => d.module === "Admin");
    const adminSections = adminModuleElements.filter(
        (element, index, arr) =>
            arr.findIndex(
                (d) => d.section !== "Admin" && element.section == d.section
            ) === index
    );

    return (
        <PageWrapper title="Admin">
            <VStack spacing="Two" style={{ paddingTop: "32px" }}>
                {adminSections.map((sectionData, sectionIndex) => (
                    <VStack
                        key={sectionIndex}
                        spacing="One"
                        style={{ marginBottom: "1rem" }}
                    >
                        <Text variant="Headline_4" color="Gray_1">
                            {sectionData.section}
                        </Text>
                        <HStack wrap={true}>
                            {adminModuleElements
                                .filter(
                                    (m) => m.section === sectionData.section
                                )
                                .map((data, index) => (
                                    <Card
                                        key={index}
                                        title={data.name}
                                        description={data.description}
                                        to={data.route}
                                    />
                                ))}
                        </HStack>
                    </VStack>
                ))}
            </VStack>
        </PageWrapper>
    );
};

export default AdminMainPage;
