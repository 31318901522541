import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelect } from "downshift";
import "styled-components/macro";

import {
    DropdownWrapper,
    DropdownButtonWrapper,
    DropdownButton,
    DropdownButtonText,
    DropdownButtonIcon,
    DropdownButtonTextRow,
    StyledDropdownIcon,
} from "components/design-system/controls/dropdown/DropdownStyledComponents";
import DropdownList from "components/design-system/controls/dropdown/DropdownList";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import useDropdownListOptions from "components/design-system/controls/dropdown/useDropdownListOptions";
import { AccordionTitle } from "components/design-system/controls/accordion/AccordionStyledComponent";
import { AccordionDropdownMultiSelectList } from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelect";
import Tag from "components/design-system/controls/accordion/Tag";

const DropdownSingleSelectWithoutSearch = React.forwardRef(
    (
        {
            disabled,
            feedbackType,
            placeholder,
            value,
            displayValueOnButton = false,
            onChange = () => void 0,
            options = [],
            clearDataOnUnmount = false,
            itemsAreLoading = false,
            disabledOptionsList = [],
            disabledOptionTooltipText = "",
            isSmallSize,
            accExpandModeMulti = false,
            accExpandModeMultiInitialVal = false,
            isAccordion = false,
            showIcon = true,
            showIconOnHover = false,
            hideDropDownSearch = false,
            optionVariant = null,
            isShowArrow = false,
            maxHeightOfOptionsList = 215,
            width,
            feedbackMessage,
            position,
            title,
            ...rest
        },
        ref
    ) => {
        const {
            filteredOptions,
            filterValue,
            setFilterValue,
            internalFilterUIShown,
        } = useDropdownListOptions(options);

        const [accToggle, setAccToggle] = useState(
            accExpandModeMultiInitialVal
        );

        useEffect(() => {
            if (clearDataOnUnmount) {
                return () => {
                    onChange("");
                };
            }
        }, []);

        let showDropDownSearch = internalFilterUIShown;
        if (hideDropDownSearch) {
            showDropDownSearch = false;
        }

        const {
            isOpen,
            selectedItem,
            getToggleButtonProps,
            getMenuProps,
            highlightedIndex,
            getItemProps,
            openMenu,
        } = useSelect({
            items: filteredOptions,
            selectedItem:
                options?.find((option) => option?.value === value) ?? null,
            onSelectedItemChange: (props) => onChange(props.selectedItem.value),
        });

        useEffect(() => {
            if (disabled && isOpen) {
                openMenu();
            }
        }, [disabled, isOpen, openMenu]);

        useEffect(() => {
            if (selectedItem && !accExpandModeMulti && isShowArrow) {
                openMenu();
            }
        }, [selectedItem, accExpandModeMulti, isShowArrow, openMenu]);

        const accMultiModeExpandProps = {
            onClick: () => setAccToggle(!accToggle),
        };

        return (
            <DropdownWrapper
                ref={ref}
                isAccordion={isAccordion}
                width={width}
                isAccExpandMode={isAccordion && accExpandModeMulti}
            >
                <DropdownButtonWrapper
                    isDisabled={disabled}
                    className="dropdown-wrapper"
                >
                    <DropdownButton
                        type="button"
                        {...getToggleButtonProps()}
                        {...rest}
                        {...(accExpandModeMulti && accMultiModeExpandProps)}
                        isDisabled={disabled}
                        feedbackType={
                            !isOpen && feedbackMessage
                                ? feedbackType
                                : FEEDBACK_TYPES.None
                        }
                        $hasSelection={!!selectedItem}
                        isAccordion={isAccordion}
                        isSmallSize={isSmallSize}
                    >
                        <DropdownButtonTextRow
                            justify="space-between"
                            isShowArrow={isShowArrow}
                        >
                            <DropdownButtonText isSmallSize={isSmallSize}>
                                {selectedItem &&
                                    displayValueOnButton &&
                                    !isAccordion &&
                                    selectedItem.value}
                                {selectedItem &&
                                    !displayValueOnButton &&
                                    !isAccordion &&
                                    selectedItem.label}
                                {!selectedItem && !isAccordion && placeholder}
                                {isAccordion && (
                                    <AccordionTitle
                                        variant="Headline_5"
                                        show={isOpen}
                                    >
                                        {title}
                                    </AccordionTitle>
                                )}
                            </DropdownButtonText>
                            {isAccordion && selectedItem && (
                                <Tag
                                    label={selectedItem?.value}
                                    handleButtonOnClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )}
                            {isShowArrow && selectedItem && (
                                <StyledDropdownIcon name="arrow-down" size={20} />
                            )}
                            {showIcon && (
                                <DropdownButtonIcon
                                    name="caret-down"
                                    color="Gray_2"
                                    style={{ flexShrink: 0 }}
                                />
                            )}
                            {showIconOnHover && (
                                <DropdownButtonIcon
                                    name={isOpen ? "caret-up" : "caret-down"}
                                    color="Gray_2"
                                    style={{ flexShrink: 0 }}
                                />
                            )}
                        </DropdownButtonTextRow>
                    </DropdownButton>
                </DropdownButtonWrapper>
                {isAccordion && <AccordionDropdownMultiSelectList />}
                <DropdownList
                    isBorder={!isAccordion}
                    isOpen={!accExpandModeMulti ? isOpen : accToggle}
                    options={filteredOptions}
                    optionsAreLoading={itemsAreLoading}
                    selectedValue={selectedItem?.value}
                    highlightedIndex={highlightedIndex}
                    getMenuProps={getMenuProps}
                    getItemProps={getItemProps}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    internalFilterUIShown={showDropDownSearch}
                    disabledOptionsList={disabledOptionsList}
                    disabledOptionTooltipText={disabledOptionTooltipText}
                    position={position}
                    optionVariant={optionVariant}
                    maxHeightOfOptionsList={maxHeightOfOptionsList}
                />
            </DropdownWrapper>
        );
    }
);

DropdownSingleSelectWithoutSearch.defaultProps = {
    disabled: false,
    feedbackType: FEEDBACK_TYPES.None,
    isSmallSize: false,
    placeholder: "Select",
    width: "100%",
};

DropdownSingleSelectWithoutSearch.propTypes = {
    disabled: PropTypes.bool,
    feedbackType: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
    isSmallSize: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default DropdownSingleSelectWithoutSearch;
