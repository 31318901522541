import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { useApi } from "utilities/API/Helpers/useApi";

export const useProjectByCampaignsIdApi = () => {
    const { getDataAsync } = useApi();
    const { clientId } = useParams();

    const getAllProjectsByCampaignsApiCall = useCallback(
        async (campaignId) => {
            const response = await getDataAsync("projectsFilterByCampaignsId", {
                urlParams: [
                    { name: "$clientId", value: clientId },
                    { name: "$campaignId", value: campaignId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useProjectByCampaignsId = (campaignId) =>
            useQuery(["projectsByCampaignsIdList", campaignId], () =>
                getAllProjectsByCampaignsApiCall(campaignId)
            );
        return {
            useProjectByCampaignsId,
        };
    }, [getAllProjectsByCampaignsApiCall]);
};

const useClientCampaigns = () => {
    const { getDataAsync, putData, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { clientId } = useParams();

    const getClientCampaignsByClientIdApiCall = useCallback(async () => {
        const response = await getDataAsync("clientCampaignsByClientId", {
            urlParams: [{ name: "$clientId", value: clientId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientCampaignsByClientId = useQuery(
        "clientCampaignsByIdList",
        getClientCampaignsByClientIdApiCall
    );

    const createClientCampaignApiCall = useCallback(
        ({ clientCampaignId, body }) => {
            return postData("clientCampaigns", {
                urlParams: [{ name: "$campaignId", value: clientCampaignId }],
                body,
            });
        },
        [postData]
    );

    const updateClientCampaignApiCall = useCallback(
        ({ clientCampaignId, body }) => {
            return putData("clientCampaigns", {
                urlParams: [{ name: "$campaignId", value: clientCampaignId }],
                body,
            });
        },
        [putData]
    );

    const deleteClientCampaignApiCall = useCallback(
        ({ clientCampaignId }) => {
            return deleteData("deleteClientCampaign", {
                urlParams: [{ name: "$id", value: clientCampaignId }],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientCampaignsByIdList");
            },
        };
    }, [queryClient]);

    const createClientCampaign = useMutation(
        createClientCampaignApiCall,
        mutationOptions
    );
    const updateClientCampaign = useMutation(
        updateClientCampaignApiCall,
        mutationOptions
    );
    const deleteClientCampaign = useMutation(
        deleteClientCampaignApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            clientCampaignsByClientId,
            createClientCampaign,
            updateClientCampaign,
            deleteClientCampaign,
        };
    }, [
        clientCampaignsByClientId,
        createClientCampaign,
        updateClientCampaign,
        deleteClientCampaign,
    ]);
};

export default useClientCampaigns;
