import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "../ui/Link";
import Icon from "../ui/Icon";
import Text from "../ui/Text";

const BreadcrumbArrowIcon = styled(Icon)`
    margin-right: 2px;
    margin-left: 2px;
`;

const BreadcrumbUL = styled.ul`
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;

    li:last-child {
        ${BreadcrumbArrowIcon} {
            display: none;
        }
    }
`;

const BreadcrumbLI = styled.li`
    display: flex;
`;

const Breadcrumb = ({ menuOptions }) => {
    return (
        <BreadcrumbUL>
            {menuOptions.map((data, index) => (
                <BreadcrumbLI key={index}>
                    {data.to || data.href || data.disabled ? (
                        <Link {...data} />
                    ) : (
                        <Text color="Gray_2" variant="Body_2_2" {...data} />
                    )}
                    <BreadcrumbArrowIcon name="caret-right" color="Gray_4" />
                </BreadcrumbLI>
            ))}
        </BreadcrumbUL>
    );
};

Breadcrumb.propTypes = {
    menuOptions: PropTypes.array,
};

export default Breadcrumb;
