import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAllPages = () => {
    const { getDataAsync } = useApi();

    const getAllPagesApiCall = useCallback(async () => {
        const response = await getDataAsync("pages");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const pages = useQuery("pagesList", getAllPagesApiCall);

    return useMemo(() => {
        return {
            pages,
        };
    }, [pages]);
};

export default useAllPages;
