import PropTypes from "prop-types";

const Slack = ({ size, _color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="18" height="18" rx="2" stroke="#E5E8EC" />
            <g clipPath="url(#clip0)">
                <path
                    d="M6.34743 11.6624C6.34743 12.3909 5.75231 12.986 5.02381 12.986C4.29531 12.986 3.7002 12.3909 3.7002 11.6624C3.7002 10.9339 4.29531 10.3387 5.02381 10.3387H6.34743V11.6624Z"
                    fill="#E01E5A"
                />
                <path
                    d="M7.01465 11.6624C7.01465 10.9339 7.60976 10.3387 8.33826 10.3387C9.06677 10.3387 9.66188 10.9339 9.66188 11.6624V14.9765C9.66188 15.705 9.06677 16.3001 8.33826 16.3001C7.60976 16.3001 7.01465 15.705 7.01465 14.9765V11.6624Z"
                    fill="#E01E5A"
                />
                <path
                    d="M8.33826 6.34743C7.60976 6.34743 7.01465 5.75231 7.01465 5.02381C7.01465 4.29531 7.60976 3.7002 8.33826 3.7002C9.06677 3.7002 9.66188 4.29531 9.66188 5.02381V6.34743H8.33826Z"
                    fill="#36C5F0"
                />
                <path
                    d="M8.33798 7.01453C9.06648 7.01453 9.6616 7.60964 9.6616 8.33814C9.6616 9.06664 9.06648 9.66176 8.33798 9.66176H5.02381C4.29531 9.66176 3.7002 9.06664 3.7002 8.33814C3.7002 7.60964 4.29531 7.01453 5.02381 7.01453C5.02381 7.01453 8.33798 7.01453 8.33798 7.01453Z"
                    fill="#36C5F0"
                />
                <path
                    d="M13.6528 8.33814C13.6528 7.60964 14.2479 7.01453 14.9764 7.01453C15.7049 7.01453 16.3001 7.60964 16.3001 8.33814C16.3001 9.06664 15.7049 9.66176 14.9764 9.66176H13.6528V8.33814Z"
                    fill="#2EB67D"
                />
                <path
                    d="M12.9856 8.33798C12.9856 9.06648 12.3905 9.6616 11.662 9.6616C10.9335 9.6616 10.3384 9.06648 10.3384 8.33798V5.02381C10.3384 4.29531 10.9335 3.7002 11.662 3.7002C12.3905 3.7002 12.9856 4.29531 12.9856 5.02381V8.33798Z"
                    fill="#2EB67D"
                />
                <path
                    d="M11.662 13.6531C12.3905 13.6531 12.9856 14.2482 12.9856 14.9767C12.9856 15.7052 12.3905 16.3003 11.662 16.3003C10.9335 16.3003 10.3384 15.7052 10.3384 14.9767V13.6531H11.662Z"
                    fill="#ECB22E"
                />
                <path
                    d="M11.662 12.986C10.9335 12.986 10.3384 12.3909 10.3384 11.6624C10.3384 10.9339 10.9335 10.3387 11.662 10.3387H14.9762C15.7047 10.3387 16.2998 10.9339 16.2998 11.6624C16.2998 12.3909 15.7047 12.986 14.9762 12.986H11.662Z"
                    fill="#ECB22E"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="12.6"
                        height="12.6"
                        fill="white"
                        transform="translate(3.7002 3.7002)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Slack.defaultProps = {
    size: "20",
    color: global.config.colors.black,
};

Slack.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Slack;
