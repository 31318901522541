import styled from "styled-components";
import Text from "./Text";
import Close from "../Icon/Close";

const Container = styled.div`
    cursor: pointer;
    display: inline-flex;
    vertical-align: top;
    margin-right: 0.5rem;
    * > svg {
        margin: 0 0 0 0.2rem;
        width: 0.7rem;
    }
`;
const StyledLink = styled.a`
    background-color: ${global.config.colors.gray7};
    padding: 0.1rem 0.5rem;
`;

const Breadcrumb = (props) => {
    return (
        <Container>
            <StyledLink {...props}>
                <Text variant="smallLight">
                    {props.children}
                    <Close />
                </Text>
            </StyledLink>
        </Container>
    );
};

export default Breadcrumb;
