import React from "react";
import styled from "styled-components";

import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Photo from "components/design-system/ui/Photo";
import { COLOR_VALUES } from "components/design-system/config/colors";
import More from "components/Allocations/Grid/Employee/More";

const StyledPhoto = styled(Photo)`
    width: 17rem;
    height: 17rem;
    border-radius: 10rem;
    margin: 0 auto;
`;

const StyledTextLabel = styled(Text)`
    background: ${COLOR_VALUES.Gray_1};
    text-align: center;
    text-transform: uppercase;
    background: ${COLOR_VALUES.Gray_7};
    border-radius: 2px;
    padding: 4px;
    width: max-content;
`;

const MoreMenuContainer = styled.div`
    padding: 0.5rem 0 0 0.5rem;
`;

const Subject = ({
    photoUrl = "",
    user,
    profileLink,
    subjectName,
    codeNumber,
    label,
    showPhoto = true,
    isMoreIcon = true,
}) => {
    return (
        <VStack>
            {showPhoto && <StyledPhoto url={photoUrl} alt={subjectName} />}
            <VStack spacing="Zero_5">
                <HStack
                    spacing="Zero_25"
                    align="flex-start"
                    justify="space-between"
                >
                    {subjectName && (
                        <Text variant="Headline_2" color="Black">
                            {subjectName}
                        </Text>
                    )}
                    {isMoreIcon && (
                        <MoreMenuContainer>
                            <More
                                visible
                                disableProfile
                                employee={user}
                                skillSearchLink={profileLink}
                                direction="left"
                                portal={true}
                            />
                        </MoreMenuContainer>
                    )}
                </HStack>
                {codeNumber && (
                    <Text
                        variant="Descriptive_1_1"
                        color="Gray_2"
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {codeNumber}
                    </Text>
                )}
                {label && (
                    <StyledTextLabel variant="Descriptive_2_2" color="Gray_2">
                        {label}
                    </StyledTextLabel>
                )}
            </VStack>
        </VStack>
    );
};

export default Subject;
