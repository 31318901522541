import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";

export const useAgencies = () => {
    const { getDataAsync } = useApi();

    const getAgenciesFilter = async (params) => {
        return getDataAsync("agenciesFilter", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    return useMemo(() => {
        return { getAgenciesFilter };
    }, []);
};
