import { useState } from "react";
import styled from "styled-components";
import Alert from "components/Icon/Alert";
import Close from "components/Icon/Close";
import Button from "components/UI/Button";
import IconButton from "components/UI/IconButton";
import Link from "components/UI/Link";
import { useAllocations as allocationServiceLayer } from "utilities/API/Allocations";
import Loading from "components/UI/Loading";
import env from "react-dotenv";
import { useEmailError } from "hooks/Errors/useEmailError";
import { useTestConnection } from "hooks/useTestConnection";
import { getTransactionId } from "utilities/Misc";
import moment from "moment";
import { useInsigths } from "utilities/Insights/InsightsLogs";

let renderCount = 1;

const TRY_AGAIN_CODE_RESPONSES = JSON.parse(
    env.BESTWORK_REACT_ALLOCATIONS_TRYAGAIN_SAVE_CODES
);

const Icon = styled.div`
    height: fit-content;
`;

const Content = styled.div`
    padding-top: 0.5rem;
    font-size: ${global.config.fonts.sizes.small};
    font-family: ${global.config.fonts.families.light};
    height: fit-content;
`;

const ErrorDetail = styled.div`
    color: ${global.config.colors.grey2};
    font-size: ${global.config.fonts.sizes.small};
    height: fit-content;
    p {
        margin: 0;
    }
`;
const Header = styled.div`
    padding-top: 0.5rem;
    font-size: ${global.config.fonts.sizes.xlarge};
    font-family: ${global.config.fonts.families.medium};
    height: fit-content;
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Footer = styled.div`
    padding-top: 0.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4rem;
    position: relative;

    min-width: 28.25rem;
    width: 28.25rem;
    max-width: "28.25rem";

    min-height: 35.25rem;
    height: 35.25rem;
    max-height: 35.25rem;

    text-align: left;

    ${Header} {
        text-align: left;
    }
    ${Body} {
        text-align: left;
    }
    ${Footer} {
        text-align: left;
        justify-content: flex-end;
    }
`;

const StyledButton = styled.button`
    background-color: inherit;
    border: none;
    padding: 0;
    width: fit-content;
    position: absolute;
    top: 1rem;
    right: 1rem;
    &:hover {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:active {
        background-color: ${global.config.colors.orange1};
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:focus {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
        outline: none;
    }
`;

const SaveHoursErrorModal = ({
    projectName,
    dates,
    initialError,
    onCancel,
    parameters,
    resetCells,
    cells,
    onClose,
}) => {
    const { saveAllocationsHours } = allocationServiceLayer();
    const [error, setError] = useState(initialError);
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        onCancel();
    };

    const { sendEmail } = useEmailError();
    const { testConnection } = useTestConnection();

    const { insightsException } = useInsigths();

    const handleClick = () => {
        sendEmail(initialError);
    };

    const handleTestConnection = () => {
        testConnection().then((rv) => {
            alert(rv);
        });
    };

    const handleTryAgain = () => {
        setLoading(true);
        saveAllocationsHours(parameters)
            .then(() => {
                resetCells(cells);
                onClose();
            })
            .catch((e) => {
                insightsException(e);
                setError(e);
                setLoading(false);
            });
    };

    return (
        <>
            <Wrapper>
                <IconButton></IconButton>
                <Icon>
                    <Alert size="25" viewBox="18" />
                </Icon>
                <Header>Something Went Wrong</Header>
                <Body>
                    <Content>
                        <p>
                            Sorry, we couldn’t save the changes to{" "}
                            <b>{projectName}</b> for the week(s) of{" "}
                            <b>{dates}</b>. Please try again.
                        </p>
                        <p>
                            If the problem persists please report this error to{" "}
                            <b>{global.config.mails.support}</b>. Please include
                            the details below, and a description of the query
                        </p>
                        <ErrorDetail>
                            <p>
                                Date:{" "}
                                {error.date
                                    ? moment(error.date).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                      )
                                    : "Unknown"}
                            </p>
                            <p>
                                Error code:{" "}
                                {!loading ? error.status : "Unknown"}
                            </p>
                            <p>
                                Transaction ID:{" "}
                                {!loading
                                    ? getTransactionId(error.correlationId)
                                    : "Unknown"}
                            </p>
                        </ErrorDetail>
                    </Content>
                </Body>
                <Footer>
                    <Buttons>
                        {TRY_AGAIN_CODE_RESPONSES.find(
                            (code) => code === error?.status
                        ) ? (
                            <>
                                <Link
                                    variant="secondary"
                                    onClick={() => handleClose()}
                                    style={{ alignSelf: "center" }}
                                >
                                    Cancel
                                </Link>

                                <Button
                                    style={{
                                        width: "11rem",
                                        height: "2.8rem",
                                        alignSelf: "center",
                                    }}
                                    onClick={() => handleTryAgain()}
                                >
                                    {loading ? (
                                        <Loading
                                            background={
                                                global.config.colors.black
                                            }
                                            color={global.config.colors.white}
                                        />
                                    ) : (
                                        <>Try Again</>
                                    )}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    style={{ width: "11rem" }}
                                    onClick={handleClick}
                                >
                                    Email Support
                                </Button>
                                <Button
                                    style={{ width: "11.75rem" }}
                                    onClick={handleTestConnection}
                                >
                                    Test Connection
                                </Button>
                            </>
                        )}
                    </Buttons>
                </Footer>
            </Wrapper>
        </>
    );
};

const Buttons = styled.div`
    display: flex;
    margin-top: auto;
    gap: 1rem;
`;

SaveHoursErrorModal.propTypes = {};

export default SaveHoursErrorModal;
