import React from "react";

import Text from "components/design-system/ui/Text";
import VStack from "components/design-system/layout/VStack";
import {
    GroupVStack,
    WrapperVStack,
    StyledHR,
} from "components/design-system/ui/drawer/DataGroup";

const DataGroup = ({ groupData, isPadding = true }) => {
    const listLength = groupData[0]?.list?.length;

    return (
        listLength !== 0 && (
            <WrapperVStack isPadding={isPadding}>
                {groupData.map(
                    (data, index) =>
                        data.list?.length > 0 && (
                            <GroupVStack key={index}>
                                <VStack spacing="Zero_25">
                                    <Text
                                        variant="Descriptive_1_1"
                                        color="Gray_2"
                                    >
                                        {data.label}
                                    </Text>

                                    {data.list.map((listData, i) => (
                                        <Text
                                            key={i}
                                            variant="Body_2_1"
                                            color="Black"
                                        >
                                            {listData}
                                        </Text>
                                    ))}
                                </VStack>
                                {data.isHr && <StyledHR />}
                            </GroupVStack>
                        )
                )}
            </WrapperVStack>
        )
    );
};

export default DataGroup;
