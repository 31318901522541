import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useApi } from "utilities/API/Helpers/useApi";

const useProjectsDashboardData = (endpointPathText) => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getDataLayoutApiCall = useCallback(async () => {
        const response = await getDataAsync("projectsDashboardDataLayout", {
            urlParams: [
                { name: "$projectId", value: projectId },
                { name: "$endpointPathText", value: endpointPathText },
            ],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const list = useQuery(`${endpointPathText}List`, getDataLayoutApiCall);

    return useMemo(() => {
        return {
            list,
        };
    }, [list]);
};

export default useProjectsDashboardData;

export const useProjectDashboardCurrency = () => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getCurrencyApiCall = useCallback(async () => {
        const response = await getDataAsync("getProjectsDashboardCurrency", {
            urlParams: [{ name: "$projectId", value: projectId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const currency = useQuery("projectDashboardCurrency", getCurrencyApiCall);

    return useMemo(() => {
        return {
            currency,
        };
    }, [currency]);
};


export const useProjectsDashboardRelatedData = () => {
    const { getDataAsync } = useApi();
    const { projectId } = useParams();
    
    const getRelatedProjectsDashboardApiCall = useCallback(
        async (endpointSuffix) => {
            const response = await getDataAsync(
                "projectsDashboardRelatedProjects",
                {
                    urlParams: [
                        { name: "$projectId", value: projectId },
                        { name: "$endpointSuffix", value: endpointSuffix },
                    ],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useRelatedProjectsDashboard = (endpointSuffix) =>
            useQuery([`${endpointSuffix}List`, endpointSuffix], () =>
                getRelatedProjectsDashboardApiCall(endpointSuffix)
            );

        return {
            useRelatedProjectsDashboard,
        };
    }, [getRelatedProjectsDashboardApiCall]);
};


export const useProjectById = () => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getProjectById = useCallback(async () => {
        const response = await getDataAsync("getProjectById", {
            urlParams: [{ name: "$projectId", value: projectId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const project = useQuery("getProjectById", getProjectById);

    return useMemo(() => {
        return {
            project,
        };
    }, [project]);
};
