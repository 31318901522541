import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";

import HStack from "components/design-system/layout/HStack";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { SPACING_VALUES } from "components/design-system/config/spacing";
import Icon from "components/design-system/ui/Icon";
import Spinner from "components/design-system/ui/Spinner";

import {
    AccordionButtonStyle,
    AccordionWrapperStyle,
} from "components/design-system/controls/accordion/AccordionStyledComponent";

export const DropdownWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: ${(props) => props.width};
    ${(props) =>
        props.isAccordion &&
        css`
            ${AccordionWrapperStyle}
            display: flex;
            flex-direction: column;
        `};

    ${(props) =>
        props.isAccordion &&
        css`
            padding: 16px 0;
            gap: ${SPACING_VALUES.One};
        `};

    ${(props) =>
        props.isAccExpandMode &&
        css`
            padding: 0;
            gap: 0;
            .dropdown-wrapper button {
                padding: 16px 0;
            }
        `};
`;

export const DropdownButtonWrapper = styled.div`
    width: 100%;

    ${(props) =>
        props.isDisabled &&
        css`
            cursor: not-allowed;
        `};
`;

export const DropdownStylesSuccess = ` 
    border-color: ${COLOR_VALUES.Gray_4};

    &:active {
        border-color: ${COLOR_VALUES.Gray_4};
    }

    &:hover,
    &:focus,
    &:focus-visible {
        border-color: ${COLOR_VALUES.Gray_4};
    }
`;

export const DropdownStylesWarning = `
    border-color: ${COLOR_VALUES.Yellow};

    &:active {
        border-color: ${COLOR_VALUES.Yellow};
    }

    &:hover,
    &:focus,
    &:focus-visible {
        border-color: ${COLOR_VALUES.Yellow};
    }
`;

export const DropdownStylesError = `
    border-color: ${COLOR_VALUES.Orange_1};

    &:active {
        border-color: ${COLOR_VALUES.Orange_1};
    }

    &:hover,
    &:focus,
    &:focus-visible {
        border-color: ${COLOR_VALUES.Orange_1};
    }
`;

export const DropdownButton = styled.button`
    display: block;
    width: 100%;
    background: ${COLOR_VALUES.White};
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    color: ${({ $hasSelection }) =>
        $hasSelection ? COLOR_VALUES.Gray_2 : COLOR_VALUES.Gray_4};

    ${(props) =>
        props.isAccordion &&
        css`
            ${AccordionButtonStyle}
        `};

    ${(props) =>
        props.isSmallSize &&
        props.isAccordion &&
        css`
            min-height: 0;
            padding: 0;
        `}

    ${(props) =>
        props.isSmallSize &&
        !props.isAccordion &&
        css`
            min-height: 25px !important;
            padding: 5px 5px 5px 12px !important;
        `}   
            
         ${(props) =>
        !props.isSmallSize &&
        !props.isAccordion &&
        css`
            min-height: 48px !important;
            padding: 12px 8px 12px 12px !important;
        `}  
            
          ${(props) =>
        !props.isSmallSize &&
        props.isAccordion &&
        css`
            min-height: 0;
            padding: 0;
        `} 
        
        ${(props) =>
        props.isWeekNavTimesheet &&
        css`
            padding: 0 2px !important;
            min-height: 24px !important;
        `}
    
    &:active {
        border-color: ${COLOR_VALUES.Black};
    }

    &:hover,
    &:focus,
    &:focus-visible {
        border-color: ${COLOR_VALUES.Gray_4};
    }

    &:focus,
    &:focus-visible {
        div,
        svg {
            color: ${COLOR_VALUES.Black};
        }
    }

    &:focus-visible {
        outline: none;
    }

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Success &&
        css`
            ${DropdownStylesSuccess}
        `}

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Warning &&
        css`
            ${DropdownStylesWarning}
        `}
        
     ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Error &&
        css`
            ${DropdownStylesError}
        `}
        
    ${(props) =>
        props.isDisabled &&
        css`
            background: ${COLOR_VALUES.Gray_9};
            border-color: ${COLOR_VALUES.Gray_6};
            pointer-events: none;
            user-select: none;

            div {
                color: ${COLOR_VALUES.Gray_4};
            }
        `}
`;

export const DropdownButtonTextRow = styled(HStack)`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const DropdownButtonText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    ${(props) =>
        props.isSmallSize
            ? css`
                  ${TEXT_VARIANT_STYLES.Body_2_1};
              `
            : css`
                  ${TEXT_VARIANT_STYLES.Body_1_1};
              `}
`;

export const DropdownButtonIcon = styled(Icon)`
    flex-shrink: 0;

    &:hover {
        color: ${COLOR_VALUES.Black};
    }
`;

export const DropdownListContainer = styled.div`
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    z-index: 1500;

    &:focus-visible {
        outline: none;
    }

    ${(props) =>
        css`
            position: ${props.position};
        `}

    ${(props) =>
        props.isDisplay &&
        css`
            display: none;
        `}
`;

export const DropdownListContainerUL = styled.ul`
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    background-color: ${COLOR_VALUES.White};
    border: 1px solid ${COLOR_VALUES.Gray_5};
    border-radius: 4px;
    padding: 2px;
    padding: 0;
    overflow: hidden;

    &:focus,
    &:focus-within,
    &:hover {
        border-color: ${COLOR_VALUES.Gray_1};
    }

    &:focus-visible {
        outline: none;
    }

    li:last-child {
        ${(props) =>
            !props.isVariant &&
            css`
                border-bottom-color: transparent;
            `}
    }
`;

export const DropdownListContainerLIWrapper = styled.div`
    max-height: ${(props) => (props.maxHeightOfOptionsList >= 0 ? `${props.maxHeightOfOptionsList}px` : "215px")};
    min-height: 35px;
    overflow-y: auto;
    z-index: 1500;
    position: relative;

    &::-webkit-scrollbar {
        width: 8px;
        border-left: 1px solid ${COLOR_VALUES.Gray_6};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${COLOR_VALUES.Gray_5};
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 999px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${COLOR_VALUES.Gray_6};
    }
`;

export const DropdownListItem = styled.li`
    border-bottom: 1px solid ${COLOR_VALUES.Gray_5};
    padding: 8px 8px 7px 8px;
    word-break: break-all;

    ${TEXT_VARIANT_STYLES.Body_2_1};
    color: ${COLOR_VALUES.Gray_1};
`;

export const DropdownListContainerLI = styled(DropdownListItem)`
    cursor: pointer;
    z-index: 1500;

    ${(props) =>
        props.isBorder &&
        css`
            border-bottom: none;
        `}

    &:active {
        border-bottom-color: ${COLOR_VALUES.Gray_3} !important;
    }

    &:hover {
        background-color: ${COLOR_VALUES.Gray_8};
    }

    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed;
            user-select: none;
            background-color: ${COLOR_VALUES.Gray_9};
            border-bottom-color: ${COLOR_VALUES.Gray_6} !important;
            ${TEXT_VARIANT_STYLES.Body_2_1};
            color: ${COLOR_VALUES.Gray_3};

            &:hover {
                background-color: ${COLOR_VALUES.Gray_9};
            }

            &:active {
                border-bottom-color: ${COLOR_VALUES.Gray_6} !important;
            }
        `}

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${COLOR_VALUES.White};
            border-bottom-color: ${COLOR_VALUES.Gray_3} !important;
            ${TEXT_VARIANT_STYLES.Body_2_2};
            color: ${COLOR_VALUES.Black};
        `}

    ${(props) =>
        props.isTraversed &&
        css`
            background-color: ${COLOR_VALUES.Gray_8};
        `}
`;

const DropdownListLoaderEl = styled.div`
    background-color: ${COLOR_VALUES.White};
    border: 1px solid ${COLOR_VALUES.Gray_5};
    border-radius: 4px;
    height: 120px;
    display: grid;
    place-content: center;
`;

export const StyledDropdownIcon = styled(Icon)`
    color: ${COLOR_VALUES.Gray_1};
`;

export const DropdownListLoader = (props) => (
    <DropdownListLoaderEl {...props}>
        <Spinner />
    </DropdownListLoaderEl>
);
