import React, { Suspense } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Image = styled.img`
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    border-radius: 50%;
    object-fit: cover;
    min-width: 3rem;
    min-height: 3rem;
    cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
`;

const Photo = ({ url, alt, clickable, ...props }) => {
    const onImageError = (event) => {
        event.target.src = "/assets/images/default-profile.svg";
    };

    return (
        <Suspense
            fallback={
                <Image
                    src={"/assets/images/default-profile.svg"}
                    clickable={clickable}
                />
            }
        >
            <Image
                key={url}
                src={url}
                alt={alt}
                clickable={clickable}
                onError={(event) => onImageError(event)}
                className={props.className}
            />
        </Suspense>
    );
};

Photo.defaultProps = {
    url: "/assets/images/default-profile.svg",
    alt: "",
};

Photo.propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string,
};

export default Photo;
