import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import PickersDay from "@mui/lab/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import Calendar from "components/Icon/Calendar";
import Tooltip from "./Tooltip";
import { makeStyles } from "@material-ui/core";
import AngleCirculeRight from "components/Icon/AngleCirculeRight";
import AngleCirculeLeft from "components/Icon/AngleCirculeLeft";
import DownCaret from "components/Icon/DownCaret";
import locale from "date-fns/locale/en-US";
import React, { useEffect, useState } from "react";
import moment from "moment";

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== "selectedWeekProps" && prop !== "currentWeekProps",
})(({ selectedWeekProps, currentWeekProps }) => ({
    ...((selectedWeekProps.dayIsBetween || currentWeekProps.dayIsBetween) && {
        backgroundColor: selectedWeekProps.dayIsBetween
            ? global.config.colors.black + "!important"
            : global.config.colors.gray7 + "!important",
        borderRadius: 0,
        color: selectedWeekProps.dayIsBetween
            ? global.config.colors.white + "!important"
            : global.config.colors.black + "!important",
        "&:hover, &:focus": {
            backgroundColor: selectedWeekProps.dayIsBetween
                ? global.config.colors.black
                : global.config.colors.gray7,
        },
        "&.Mui-disabled": {
            color: selectedWeekProps.dayIsBetween
                ? global.config.colors.white
                : global.config.colors.black,
        },
        "& .MuiPickersDay-today": {
            backgroundColor: selectedWeekProps.dayIsBetween
                ? global.config.colors.black
                : global.config.colors.gray7,
            color: selectedWeekProps.dayIsBetween
                ? global.config.colors.white
                : global.config.colors.black,
            border: "0!important",
        },
    }),
    ...((selectedWeekProps.isFirstDay || currentWeekProps.isFirstDay) && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    }),
    ...((selectedWeekProps.isLastDay || currentWeekProps.isLastDay) && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    }),
}));

const DateLabel = styled("h3")({
    fontSize: global.config.fonts.sizes.large,
    fontFamily: global.config.fonts.families.bold,
    marginBottom: 0,
});

const theme = createTheme({
    palette: {
        primary: {
            main: global.config.colors.black,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: global.config.fonts.sizes.small,
                    fontFamily: global.config.fonts.families.regular,
                    "&:hover": {
                        backgroundColor: global.config.colors.white,
                    },
                    "&.Mui-disabled": {
                        borderRadius: 0,
                        margin: "-2px",
                        width: "3rem",
                        color: global.config.colors.gray4,
                    },
                    "&.Mui-selected": {
                        backgroundColor: global.config.colors.white,
                        color: global.config.colors.black,
                        "&:hover": {
                            backgroundColor: global.config.colors.white,
                        },
                        "&:focus": {
                            backgroundColor: global.config.colors.white,
                        },
                    },
                    "&.MuiPickersDay-dayOutsideMonth": {
                        color: global.config.colors.gray4,
                    },
                    "&:focus": {
                        backgroundColor: global.config.colors.gray8,
                    },
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    "& div[role=presentation]": {
                        "& .PrivatePickersFadeTransitionGroup-root": {
                            "& > div": {
                                fontSize: global.config.fonts.sizes.smedium,
                                fontFamily: global.config.fonts.families.medium,
                            },
                        },
                    },
                    "& .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer":
                        {
                            "& span.MuiTypography-root": {
                                fontSize: global.config.fonts.sizes.small,
                                fontFamily:
                                    global.config.fonts.families.regular,
                                color: global.config.colors.gray3,
                            },
                            height: "19rem",
                        },
                    "& button.PrivatePickersYear-yearButton": {
                        fontSize: global.config.fonts.sizes.small,
                        fontFamily: global.config.fonts.families.regular,
                        border: "0.063rem solid",
                        borderColor: global.config.colors.white,
                        "&:hover": {
                            borderRadius: "3rem",
                            borderColor: global.config.colors.black,
                            backgroundColor: "transparent",
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: global.config.colors.black,
                        },
                    },
                    "& button.PrivatePickersMonth-root": {
                        fontSize: global.config.fonts.sizes.small,
                        fontFamily: global.config.fonts.families.regular,
                        border: "0.063rem solid",
                        borderColor: global.config.colors.white,
                        "&:hover": {
                            borderRadius: "3rem",
                            borderColor: global.config.colors.black,
                            backgroundColor: "transparent",
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: global.config.colors.black,
                        },
                    },
                    "& .MuiPickersDay-today": {
                        border: "0!important",
                    },
                    "& div[role='row']": {
                        margin: "0.063rem 1.2rem",
                        padding: "0.063rem",
                        border: "0.063rem solid",
                        borderColor: global.config.colors.white,
                        "&:hover": {
                            borderRadius: "3rem",
                            borderColor: global.config.colors.black,
                        },
                    },
                    "& .MuiButtonBase-root.Mui-selected": {
                        backgroundColor: global.config.colors.white,
                        color: global.config.colors.black,
                        "&:hover": {
                            backgroundColor: global.config.colors.white,
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "& .Mui-disabled": {
                        backgroundColor: global.config.colors.gray7,
                    },
                },
                edgeStart: {
                    height: "1.125rem",
                    marginTop: "0.313rem",
                    padding: 0,
                    marginRight: "1.3rem",
                },
                edgeEnd: {
                    height: "1.125rem",
                    marginTop: "0.313rem",
                    padding: 0,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginLeft: "-2.15rem",
                    marginTop: "0.5rem",
                    boxShadow: "none",
                    border: "0.063rem solid",
                    borderColor: global.config.colors.gray6,
                    borderRadius: global.config.sizes.borderRadius,
                },
            },
        },
    },
});

const useStyles = makeStyles(() => ({
    button: {
        cursor: "pointer",
        padding: "0.25rem 0.5rem",
        "& .MuiInputAdornment-positionEnd > button.MuiIconButton-edgeEnd": {
            margin: "0.5rem",
            marginLeft: 0,
            borderRadius: 0,
            "&:hover": {
                borderRadius: 0,
                backgroundColor: "transparent",
            },
        },
        "& .MuiInputAdornment-positionEnd": {
            margin: 0,
        },
        "&:hover": {
            backgroundColor: global.config.colors.gray7,
            borderRadius: "2px",
        },
    },
}));

export default function WeekPicker({
    selectedValue,
    dateFull,
    dateLabel,
    onChangeWeek,
    minDate,
    maxDate,
}) {
    const [value, setValue] = useState(moment().toDate());
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const classes = useStyles();
    const weekStartOnMonday = { weekStartsOn: 1 };
    let monthYearPickerOpen = false;

    if (locale && locale.options) {
        locale.options.weekStartsOn = 1;
    }

    useEffect(() => {
        if (moment(selectedValue).isValid()) {
            setValue(selectedValue);
        }
    }, [selectedValue]);

    const renderWeekPickerDay = (date, _selectedDates, pickersDayProps) => {
        if (!value) {
            return <PickersDay {...pickersDayProps} />;
        }

        const selectedWeekProps = getSelectedWeek(date);
        const currentWeekProps = getCurrentWeek(date);

        return (
            <ThemeProvider theme={theme}>
                <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                    selectedWeekProps={selectedWeekProps}
                    currentWeekProps={currentWeekProps}
                    sx={{ width: "2.5rem" }}
                />
            </ThemeProvider>
        );

        function getSelectedWeek(selectedWeekDate) {
            const start = startOfWeek(value, weekStartOnMonday);
            const end = endOfWeek(value, weekStartOnMonday);

            return getWeekProps(selectedWeekDate, start, end);
        }

        function getCurrentWeek(currentWeekDate) {
            const start = startOfWeek(moment().toDate(), weekStartOnMonday);
            const end = endOfWeek(moment().toDate(), weekStartOnMonday);

            return getWeekProps(currentWeekDate, start, end);
        }

        function getWeekProps(weekDate, start, end) {
            const dayIsBetween = isWithinInterval(weekDate, { start, end });
            const isFirstDay = isSameDay(weekDate, start);
            const isLastDay = isSameDay(weekDate, end);

            return { dayIsBetween, isFirstDay, isLastDay };
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <DatePicker
                    displayStaticWrapperAs="desktop"
                    label="Week picker"
                    views={["year", "month", "day"]}
                    value={value}
                    onChange={(newValue) => {
                        if (monthYearPickerOpen) {
                            return;
                        }
                        setValue(startOfWeek(newValue, weekStartOnMonday));
                        onChangeWeek(startOfWeek(newValue, weekStartOnMonday));
                    }}
                    renderDay={renderWeekPickerDay}
                    onClick={() => setIsPickerOpen(true)}
                    open={isPickerOpen}
                    onClose={() => {
                        setIsPickerOpen(false);
                    }}
                    PopperProps={{ placement: "bottom-start" }}
                    renderInput={({ inputRef, _inputProps, InputProps }) => (
                        <Tooltip placement="bottom" title={dateFull}>
                            <Box
                                sx={{ display: "flex", alignItems: "center" }}
                                className={classes.button}
                                onClick={() => setIsPickerOpen(true)}
                            >
                                <DateLabel ref={inputRef} style={{fontSize: "0.9rem"}}>
                                    {" "}
                                    {dateLabel}{" "}
                                </DateLabel>
                            </Box>
                        </Tooltip>
                    )}
                    inputFormat="MMM dd"
                    components={{
                        OpenPickerIcon: Calendar,
                        RightArrowIcon: AngleCirculeRight,
                        LeftArrowIcon: AngleCirculeLeft,
                        SwitchViewIcon: DownCaret,
                    }}
                    onMonthChange={(_x) => setValue(value)}
                    onYearChange={(_x) => setValue(value)}
                    onViewChange={(x) => { monthYearPickerOpen = x === "year" || x === "month";}}
                    minDate={minDate}
                    maxDate={maxDate}
                    showDaysOutsideCurrentMonth={true}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
