import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "components/design-system/ui/Icon";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import Tooltip from "components/design-system/ui/Tooltip";

const StyledProgressStatusCircle = styled(HStack)`
    display: inline-flex;
    align-items: center;
    padding: 0px;
    gap: 8px;
`;

const StyledText = styled(Text)`
    ${TEXT_VARIANT_STYLES.Body_2_1};
    white-space: nowrap;
`;

const StyledIcon = styled(Icon)`
    margin-top: 2px;
`;

const ProgressStatusCircle = ({ value, title, showMessage }) => {
    const text = value === 0 && title ? `${title}` : `${value}% ${title}`;
    return (
        <>
            <Tooltip enterDelay={0} text={text} enabled={!showMessage}>
                <StyledProgressStatusCircle>
                    {value !== 0 &&
                        value &&
                        title !== "N/A" &&
                        title !== "None" &&
                        (value > 0 && value <= 39 ? (
                            <StyledIcon name="progress-status-circle-1" />
                        ) : (
                            value >= 40 &&
                            value <= 59 && (
                                <StyledIcon name="progress-status-circle-2" />
                            )
                        ))}
                    {title !== "N/A" && title !== "None" && showMessage && (
                        <StyledText>{text}</StyledText>
                    )}
                </StyledProgressStatusCircle>
            </Tooltip>
        </>
    );
};

ProgressStatusCircle.propTypes = {
    value: PropTypes.number,
    title: PropTypes.string,
    showMessage: PropTypes.bool,
};

export default ProgressStatusCircle;
