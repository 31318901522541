export default function updateColumnsShowAttribute(
    columns,
    multiSelectDropownOptionsArr,
    newObj,
    selectedValues
) {
    let displayOptionsList = [];
    selectedValues.map((v) => {
        if (v?.children) {
            v.children.map((vc) => displayOptionsList.push(vc.value));
        } else if (v?.id) {
            displayOptionsList.push(v.value);
        }
    });

    let allShowHideColumnsList = [];
    multiSelectDropownOptionsArr?.map((md) => {
        if (md?.children) allShowHideColumnsList.push(...md?.children);
        else if (md?.parent) allShowHideColumnsList.push(md?.parent);
    });

    allShowHideColumnsList.map((columnName) => {
        const indexOfColumn = columns.findIndex(
            (fi) => fi.headCellProps.text === columnName
        );
        newObj[indexOfColumn].show = displayOptionsList.includes(columnName);
    });
}
