import { elementLoadedInDOM } from "common-methods/elementLoadedInDOM";
import { useEffect, useState } from "react";

export const defaultFixedHeaderHeight = 170;

export const useScroll = () => {
    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(
        defaultFixedHeaderHeight
    );

    const handleScroll = () => {
        const top =
            fixedHeaderHeight -
            (document.querySelector(".footer")?.offsetHeight +
                document.querySelector(".dashboard-selector")?.offsetHeight);
        const sideNav = document.querySelector(".sideNav");
        const linkTab = document.querySelector(".linkTabs");
        
        if (sideNav && linkTab) {
            if (window.scrollY >= top) {
                sideNav.style.position = "fixed";
                sideNav.style.top = `${fixedHeaderHeight}px`;
                linkTab.style.marginLeft =
                    "calc(" +
                    sideNav?.offsetWidth +
                    "px" +
                    " + " +
                    "2rem" +
                    ")";
            } else {
                sideNav.style.position = "relative";
                sideNav.style.top = "0";
                linkTab.style.marginLeft = "0";
            }
        }
    };

    useEffect(() => {
        elementLoadedInDOM(".dashboard-selector").then(() => {
            setFixedHeaderHeight(
                document.getElementById("top-nav").offsetHeight +
                    document.querySelector(".selector-nav").offsetHeight
            );
        });
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return [fixedHeaderHeight];
};
