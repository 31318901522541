import PropTypes from "prop-types";
import EmployeeHeader from "./EmployeeHeader";
import EmployeeDetail from "./EmployeeDetail";
import { EmployeeProvider } from "context/Allocations/EmployeeContext";
import getEmployeeKey from "./utils/getEmployeeKey";
import { EmployeeRowCellsProvider } from "context/Allocations/EmployeeRowCellsContext";

const Employee = ({ data }) => {
    return (
        <>
            <EmployeeProvider>
                <EmployeeRowCellsProvider>
                    <div id={"div_" + getEmployeeKey(data)} style={{borderTop: "1px solid black"}}>
                        <EmployeeHeader
                            id={"empheader_" + getEmployeeKey(data)}
                            key={"header_" + getEmployeeKey(data)}
                            initialData={data}
                        />
                        <EmployeeDetail
                            id={"empdetail_" + getEmployeeKey(data)}
                            details={data?.projects}
                            employee={data}
                        />
                    </div>
                </EmployeeRowCellsProvider>
            </EmployeeProvider>
        </>
    );
};

Employee.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Employee;
