import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Modal from "components/design-system/ui/modal/MasterModal";
import HStack from "components/design-system/layout/HStack";
import ClientGroupsLOBTable from "pages/admin/clients/single/ClientGroupsLOBTable";
import ClientCampaignTable from "pages/admin/clients/single/ClientCampaignTable";
import ClientBizGroupsTable from "pages/admin/clients/single/ClientBizGroupsTable";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";

import ClientCampaignForm from "./ClientCampaignForm";
import ClientBizGroupsForm from "./ClientBizGroupForm";
import ClientGroupsLOBForm from "./ClientGroupsLOBForm";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    const parsedId = parseInt(id);

    switch (action) {
        case "addCampaign":
            return (
                <Modal title="Add Campaign" onRequestClose={onClose}>
                    <ClientCampaignForm key="new" onClose={onClose} />
                </Modal>
            );

        case "editCampaign":
            return isNaN(parsedId) ? (
                <></>
            ) : (
                <Modal title="Edit Campaign" onRequestClose={onClose}>
                    <ClientCampaignForm
                        campaignIdToEdit={parsedId}
                        key={parsedId}
                        onClose={onClose}
                    />
                </Modal>
            );

        case "addLinkedBusinessGroup":
            return (
                <Modal title="Add Business Group Link" onRequestClose={onClose}>
                    <ClientBizGroupsForm key="new" onClose={onClose} />
                </Modal>
            );

        case "editBusinessGroupLink":
            return isNaN(parsedId) ? (
                <></>
            ) : (
                <Modal
                    title="Edit Business Group Link"
                    onRequestClose={onClose}
                >
                    <ClientBizGroupsForm
                        bizGroupIdToEdit={parsedId}
                        key={parsedId}
                        onClose={onClose}
                    />
                </Modal>
            );

        case "addLineOfBusiness":
            return (
                <Modal title="Add Line of Business" onRequestClose={onClose}>
                    <ClientGroupsLOBForm key="new" onClose={onClose} />
                </Modal>
            );

        case "editLineOfBusiness":
            return isNaN(parsedId) ? (
                <></>
            ) : (
                <Modal title="Edit Line of Business" onRequestClose={onClose}>
                    <ClientGroupsLOBForm
                        clientLOBIdToEdit={parsedId}
                        key={parsedId}
                        onClose={onClose}
                    />
                </Modal>
            );

        default:
            return <></>;
    }
};

const ClientGroups = () => {
    return (
        <>
            <FormPresenter />
            <HStack
                spacing="Two"
                justify="space-between"
                style={{ overflow: "auto" }}
            >
                <VStack style={{ width: "33%" }}>
                    <Text variant="Headline_2" color="Gray_1">
                        Lines of Business
                    </Text>
                    <ClientGroupsLOBTable />
                </VStack>

                <VStack style={{ width: "33%" }}>
                    <Text variant="Headline_2" color="Gray_1">
                        Campaigns
                    </Text>
                    <ClientCampaignTable />
                </VStack>

                <VStack style={{ width: "33%" }}>
                    <Text variant="Headline_2" color="Gray_1">
                        Linked Business Groups
                    </Text>
                    <ClientBizGroupsTable />
                </VStack>
            </HStack>
        </>
    );
};

export default ClientGroups;
