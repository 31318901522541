import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useReducer,
    useRef,
} from "react";
import { addMinutes, differenceInMinutes } from "date-fns";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useLocationState } from "react-router-use-location-state";

import GlobalConfig from "configs/Global";

import SessionContext from "context/app/SessionContext";

import useProjectsDashboardNotes from "pages/projects/project-dashboard/single/useProjectsDashboardNotes";

export const TIME_INTERVAL_IN_MIN = 5;

function reducer(oldState, action) {
    const { projectID, dateTime, notes } = action.payload;
    let projectsAndNotesData = [];
    oldState.map((d) => projectsAndNotesData.push(d));

    const setProjectsNotesIcon = (projectIdIndex = null) => {
        let newNotesData = [...notes];
        const projectLastNoteVisitedLocalDateTime = projectIdIndex
            ? projectsAndNotesData[projectIdIndex]?.dateTime
            : dateTime;

        newNotesData.forEach((d, i) => {
            const dateDiffInMin = differenceInMinutes(
                addMinutes(
                    projectLastNoteVisitedLocalDateTime,
                    new Date(d.Date).getTimezoneOffset()
                    /* Adding timezone offset here as local and server time is different */
                ),
                new Date(d.Date)
                /* Server time when note has been created */
            );

            d.isNoteVisited =
                projectsAndNotesData[projectIdIndex]?.notesData[
                    i
                ]?.isNoteVisited;

            d.showNotificationIcon =
                dateDiffInMin < TIME_INTERVAL_IN_MIN &&
                dateDiffInMin >= 0 &&
                !projectsAndNotesData[projectIdIndex]?.notesData[i]
                    ?.isNoteVisited
                    ? true
                    : false;
        });

        return newNotesData;
    };

    if (action.type === "setProjectNoteData") {
        const projectIdIndex = projectsAndNotesData.findIndex(
            (f) => f.id === projectID
        );
        if (projectIdIndex === -1) {
            const newNotesData = setProjectsNotesIcon();

            projectsAndNotesData.push({
                id: projectID,
                projectDateTime: dateTime,
                notesData: setProjectsNotesIcon(),
                showNotificationIconForNotesTab: newNotesData?.some(
                    (s) => s.showNotificationIcon
                ),
                isNoteVisited: false,
            });
        } else if (projectIdIndex > -1) {
            projectsAndNotesData[projectIdIndex].id = projectID;
            projectsAndNotesData[projectIdIndex].projectDateTime = dateTime;

            const newNotesData = setProjectsNotesIcon(projectIdIndex);

            projectsAndNotesData[projectIdIndex].notesData = newNotesData;

            projectsAndNotesData[
                projectIdIndex
            ].showNotificationIconForNotesTab = newNotesData?.some(
                (s) => s.showNotificationIcon
            );
        }
        return projectsAndNotesData;
    } else if (action.type === "setAllNotesDataIconToFalse") {
        const projectIdIndex = projectsAndNotesData.findIndex(
            (f) => f.id === projectID
        );
        projectsAndNotesData[projectIdIndex].notesData.forEach((d) => {
            d.showNotificationIcon = false;
            d.isNoteVisited = true;
        });
        projectsAndNotesData[
            projectIdIndex
        ].showNotificationIconForNotesTab = false;

        return projectsAndNotesData;
    } else {
        throw new Error("Invalid Action Type");
    }
}

const ProjectsDashboardLayoutContext = React.createContext({
    setProjectNoteVistitedDateTime: () => void 0,
    setAllNotesDataIconToFalse: () => void 0,
});

export const ProjectsDashboardLayoutContextProvider = (props) => {
    const { state } = useLocation();

    const { projectId } = useParams();
    const sessionData = useContext(SessionContext);
    const location = useLocation();

    const [filters, setFilters] = useState();
    const cuurentProjectId = useRef(projectId);

    // To reset note type filter when retainer selector changes
    useEffect(() => {
        setFilters([]);
        cuurentProjectId.current = projectId;
    }, [projectId]);

    const filterVal = filters?.find((f) => f.id === "TypeId")?.value ?? "";
    const noteTypeIdVal =
        cuurentProjectId.current !== projectId ? "" : filterVal;

    const { useProjectsNotesList } = useProjectsDashboardNotes();
    const notesList = useProjectsNotesList({
        projectIdFE: projectId,
        noteTypeId: noteTypeIdVal,
    });

    const pathNameIncludesNotes = location.pathname.includes(
        `/${GlobalConfig.routes.projectsProjectsDashboardNotesTab}`
    );

    const [initialState] = useState(() => {
        const initialStateData = [];
        let stateType = Array.isArray(state);
        if (state?.length !== 0 && stateType) {
            state?.map((data) => {
                initialStateData.push(data.key);
            });
        }
        return initialStateData;
    });

    const [projectNoteTabVisitState, setProjectNoteTabVisitState] =
        useLocationState("projectsAndNotesState", initialState);

    const [newReducerState, dispatch] = useReducer(
        reducer,
        projectNoteTabVisitState
    );

    const [flagToLoad, setFlagToLoad] = useState(true);

    const setProjectNoteData = useCallback((projectID, dateTime, notes) => {
        dispatch({
            type: "setProjectNoteData",
            payload: { projectID, dateTime, notes },
        });
    }, []);

    const setAllNotesDataIconToFalse = useCallback((projectID) => {
        dispatch({
            type: "setAllNotesDataIconToFalse",
            payload: { projectID },
        });
    }, []);

    const getNoteVisitedDateTimeByProjectId = useCallback(
        (projectID) =>
            newReducerState.filter((f) => f.id === projectID)[0]?.value,
        [newReducerState]
    );

    const getNotesListLengthByProjectId = useCallback(
        (projectID) =>
            newReducerState.filter((f) => f.id === projectID)[0]?.notesData
                ?.length,
        [newReducerState]
    );

    const getNotesTabIndicator = useCallback(
        (projectID) =>
            newReducerState.filter((f) => f.id === projectID)[0]
                ?.showNotificationIconForNotesTab,
        [newReducerState]
    );

    const getNotesData = useCallback(
        (projectID) =>
            newReducerState.filter((f) => f.id === projectID)[0]?.notesData,
        [newReducerState]
    );

    const checkIfNotesDataContainsIcon = useCallback(
        (projectID) =>
            newReducerState
                .filter((f) => f.id === projectID)[0]
                ?.notesData?.some((s) => s.showNotificationIcon),
        [newReducerState]
    );

    const [updatedNotesData, setUpdatedNotesData] = useState(
        getNotesData(projectId) ?? []
    );
    useEffect(() => {
        const notesD = getNotesData(projectId);
        setUpdatedNotesData(notesD);
    }, [projectId, newReducerState]);

    useEffect(() => {
        if (!notesList.isFetching) {
            // To display edit/view icon require isCreatorOfNote
            notesList?.data.forEach((d) => {
                d.isCreatorOfNote =
                    d.Creator === sessionData.fullName ? true : false;
            });

            // To store projects related data
            const dateDiffInMin = differenceInMinutes(
                new Date(),
                getNoteVisitedDateTimeByProjectId(projectId)
            );

            if (
                notesList?.data?.length >= 0 &&
                notesList?.data?.length !==
                    getNotesListLengthByProjectId(projectId)
            ) {
                setFlagToLoad(true);
            }

            if (isNaN(dateDiffInMin) && flagToLoad) {
                setFlagToLoad(false);
                setProjectNoteData(
                    projectId,
                    getNoteVisitedDateTimeByProjectId(projectId) ?? new Date().toISOString(),
                    notesList?.data
                );
            }
        }
    }, [notesList]);

    // When filter selected; to update notes data updating flag state here
    useEffect(() => {
        if (filters?.length > 0 && !flagToLoad) {
            setFlagToLoad(true);
        }
    }, [filters]);

    // Remove indicator from table row
    useEffect(() => {
        let timer = null;
        if (checkIfNotesDataContainsIcon(projectId) && pathNameIncludesNotes) {
            timer = setTimeout(() => {
                setAllNotesDataIconToFalse(projectId);
            }, 30000); // 30000ms i.e. 30s
        }

        return () => {
            clearTimeout(timer);
        };
    }, [checkIfNotesDataContainsIcon(projectId), pathNameIncludesNotes]);

    useEffect(() => {
        setProjectNoteTabVisitState(newReducerState);
    }, [newReducerState, setProjectNoteTabVisitState]);

    const contextValue = useMemo(() => {
        return {
            getNotesTabIndicator,
            updatedNotesData,
            newReducerState,
            notesList,
            setFilters,
            setFlagToLoad,
        };
    }, [
        getNotesTabIndicator,
        updatedNotesData,
        newReducerState,
        notesList,
        setFilters,
        setFlagToLoad,
    ]);

    return (
        <>
            <ProjectsDashboardLayoutContext.Provider value={contextValue}>
                {props.children}
            </ProjectsDashboardLayoutContext.Provider>
        </>
    );
};

export default ProjectsDashboardLayoutContext;
