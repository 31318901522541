import { useContext, useEffect, useState } from "react";
import Pill from "../../../UI/Pill";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import EmployeeContext from "context/Allocations/EmployeeContext";

const PillCell = styled(Col)`
    border-right: ${global.config.border.small};
    display: flex;
    padding: 0px;
    &:last-child {
        border-right: none;
    }
`;

const Pills = ({ initialData }) => {
    const [summaryInfo, setSummaryInfo] = useState([]);
    const {summaryData, employeeData, setSummaryData } = useContext(EmployeeContext);

    setSummaryData(summaryData != null && summaryData.length !== 0 ? summaryData : initialData.weeks);
    let employeeInfo = employeeData != null && employeeData.length !== 0 ? employeeData : initialData;

    useEffect(() => {
        setSummaryInfo(summaryData != null && summaryData.length !== 0 ? summaryData : initialData.weeks);
    }, [summaryData]);

    return (
        <>
            {summaryInfo &&
                summaryInfo.map((item, index) =>
                    (
                        <PillCell key={index}>
                            <Pill
                                employeeData={employeeInfo}
                                allocatedHours={item.allocatedHours < 0 ? 0 : item.allocatedHours}
                                availableHours={item.availableHours}
                                date={item.date}
                                timeOffReasonsAmount={item.timeOffReasonsAmount}
                            />
                        </PillCell>
                    ) 
                )}
        </>
    );
};

export default Pills;
