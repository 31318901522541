import React from "react";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Text from "components/design-system/ui/Text";

import { sendNotification } from "utilities/Notification";
import useAccessGroupMembersApi from "pages/admin/access-groups/single/useAccessGroupMembers";

const AccessGroupMemberDeleteModal = ({
    accessGroupId,
    accessGroupName,
    accessGroupMemberIdToDelete,
    onClose,
}) => {
    const { useAccessGroupMembers, deleteAccessGroupMember } =
        useAccessGroupMembersApi();
    const memberElement = useAccessGroupMembers(accessGroupId);

    if (memberElement.isLoading) {
        return <div>Loading...</div>;
    }

    if (memberElement.error) {
        return <div>Error</div>;
    }

    const modifier = deleteAccessGroupMember;

    const currentMemberData = memberElement.data.find(
        (member) => member.memberId === accessGroupMemberIdToDelete
    );
    if (!currentMemberData) {
        return <div>Could not find member</div>;
    }

    const onSubmit = () => {
        const memberData = {
            memberId: accessGroupMemberIdToDelete,
            type: currentMemberData.type,
        };

        const allData = { groupId: accessGroupId, body: memberData };

        modifier.mutateAsync(allData).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>
                    <b>{currentMemberData.memberName}</b> has been removed from{" "}
                    <b>{accessGroupName}</b>.
                </>
            );
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <VStack spacing="Three">
                <VStack>
                    <Text variant="Body_1_1" color="Gray_2">
                        Are you sure you&apos;d like to remove{" "}
                        <span style={{ fontWeight: "700" }}>
                            {currentMemberData.memberName}
                        </span>{" "}
                        from this Access Group:{" "}
                        <span style={{ fontWeight: "700" }}>
                            {accessGroupName}
                        </span>
                        ?
                    </Text>
                </VStack>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: "Yes, Remove",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        onClick: onClose,
                    }}
                    as="fieldset"
                    disabled={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default AccessGroupMemberDeleteModal;
