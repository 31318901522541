import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import TextInput from "components/design-system/controls/input/TextInput";
import QuaternaryButton from "components/design-system/controls/button/QuaternaryButton";

const Wrapper = styled(HStack)`
    width: 100%;
`;

const StyledQuaternaryButton = styled(QuaternaryButton)`
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;
    }
`;

const StyledTextInput = styled(TextInput)`
    width: 48px;
    height: 36px;

    input {
        width: 48px;
        height: 36px;
    }
`;

export const PAGER_INITIAL_PAGE_SIZE = 10;

export const pagerOptions = [
    { label: "All", value: "All" },
    { label: "100", value: 100 },
    { label: "50", value: 50 },
    { label: "25", value: 25 },
    { label: "10", value: 10 },
];


const Pager = ({
    totalEntries,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pagerDisabledOptionsList = [],
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
}) => {
    const [inputVal, setInputVal] = useState(1);
    const backspaceKeyDownRef = useRef(false);

    const handleOnDropdownChange = (val) => {
        if (val === "All") {
            setPageSize(totalEntries);
        } else {
            setPageSize(val);
        }
    };

    const handleOnKeyDown = (e) => {
        if (e.key === "Backspace") {
            backspaceKeyDownRef.current = true;
        } else {
            backspaceKeyDownRef.current = false;
        }
    };

    const handleOnInputChange = (e) => {
        let checkCondition =
            e.target.value.match(/^[0-9\b]+$/)?.length === 1 &&
            e.target.value.toString().charAt(0) !== "0";

        if (checkCondition || backspaceKeyDownRef.current) {
            const inputValue = checkCondition
                ? e.target.value
                : backspaceKeyDownRef.current && "";

            const pageNum =
                inputValue !== ""
                    ? inputValue > pageOptions.length
                        ? Number(pageOptions.length) - 1
                        : Number(inputValue) - 1
                    : "";

            gotoPage(pageNum);
            setInputVal(pageNum);
        }
    };

    useEffect(() => {
        setInputVal(pageIndex);
    }, [pageIndex]);

    return (
        <>
            <Wrapper align="center" spacing="One" justify="space-between">
                <HStack align="center" spacing="One">
                    <HStack spacing="Zero_25">
                        <Text variant="Body_2_1" color="Black">
                            Results:
                        </Text>
                        <Text variant="Body_2_1" color="Gray_2">
                            {totalEntries}
                        </Text>
                    </HStack>

                    <DropdownSingleSelectWithoutSearch
                        options={totalEntries <= 150 ? pagerOptions : pagerOptions.slice(1)}
                        disabledOptionsList={pagerDisabledOptionsList}
                        onChange={(val) => handleOnDropdownChange(val)}
                        value={pageSize === totalEntries ? "All" : pageSize}
                        clearDataOnUnmount={false}
                        displayExtraDropdownText="View"
                        dropdownButtonTextVariant="Descriptive_1_1"
                        dropdownButtonTextStyles={{
                            color: "Black",
                            padding: "4px 10px",
                            background: COLOR_VALUES.Gray_7,
                        }}
                        dropdownButtonStyles={{
                            padding: "5px 11px",
                            minHeight: "36px",
                        }}
                        ref={undefined}
                        isSmallSize={true}
                    />
                </HStack>
                {pageSize < totalEntries && (
                    <HStack align="center" spacing="One">
                        <StyledQuaternaryButton
                            leadingIcon="caret-left"
                            {...{ "aria-label": "Prev" }}
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        />

                        <HStack align="center">
                            <Text variant="Body_2_2" color="Gray_1">
                                Page
                            </Text>
                            <StyledTextInput
                                value={
                                    inputVal !== ""
                                        ? Number(inputVal) === 0
                                            ? 1
                                            : Number(pageIndex) + 1 >
                                              pageOptions.length
                                            ? pageOptions.length
                                            : Number(pageIndex) + 1
                                        : ""
                                }
                                type={"text"}
                                onChange={handleOnInputChange}
                                onKeyDown={handleOnKeyDown}
                                ref={backspaceKeyDownRef}
                            />
                            <Text variant="Body_2_1" color="Gray_2">
                                of {pageOptions.length}
                            </Text>
                        </HStack>
                        <StyledQuaternaryButton
                            leadingIcon="caret-right"
                            {...{ "aria-label": "Next" }}
                            onClick={nextPage}
                            disabled={!canNextPage}
                        />
                    </HStack>
                )}
            </Wrapper>
        </>
    );
};

export default Pager;
