import { useEffect, useRef } from "react";
import { format } from "date-fns";
import { CalendarDate } from "@internationalized/date";

import VStack from "components/design-system/layout/VStack";
import TextInput from "components/design-system/forms/TextInput";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import DatePicker from "components/design-system/forms/DatePicker";
import { sendNotification } from "utilities/Notification";

import useOffices, {
    useOfficesCountries,
    useOfficesCurrencies,
    useOfficesTimezones,
} from "pages/admin/offices/useOffices";
import Text from "components/design-system/ui/Text";

import useRegions from "pages/admin/regions/useRegions";
import { OFFICE_TYPES, getTypeOption } from "pages/admin/offices/utils";

export const getCalendarDateFromNewDate = (newDateFormat) => {
    let newCalendarDate = new CalendarDate(
        "AD",
        format(newDateFormat, "yyyy"),
        format(newDateFormat, "MM"),
        format(newDateFormat, "dd")
    );
    return newCalendarDate;
};

const attributesOptions = [
    { label: "Clients", value: "HasClients" },
    { label: "Groups", value: "HasGroups" },
    { label: "in SAP", value: "InSAPDirectly" },
    { label: "Is Offsite", value: "IsOffSite" },
];

const attributesOption = [{ label: "Active", value: "Active" }];

function transformOfficeDataToFormData(officeData = {}) {
    return {
        name: officeData.Name,
        shortName: officeData.ShortName,
        isActive: attributesOption
            .map((attribute) => attribute.value)
            .filter((item) => officeData[item] ?? true)
            .join(","),
        region: officeData.RegionId,
        country: officeData.CountryName,
        type: getTypeOption(officeData.RegionTypeId)?.value,
        holidayOffice: officeData.HolidayRegionId,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) => officeData[item])
            .join(","),
        opened: officeData.OpenDate
            ? format(new Date(officeData.OpenDate), "yyyy-MM-dd")
            : null,
        currency: officeData.RegionDefaultCurrencyId ?? 0,
        timezone: officeData.TimeZoneId ?? 0,
        addressLine: officeData.Street,
        city: officeData.CityName ?? "",
        state: officeData.StateName ?? "",
        zipCode: officeData.PostCode,
    };
}

function transformFormDataToOfficeData(
    formData = {},
    officeData = {},
    regionName = "",
    holidayRegionName = ""
) {
    const newOfficeData = { ...officeData };
    newOfficeData.Name = formData.name;
    newOfficeData.ShortName = formData.shortName;
    attributesOption.forEach((attribute) => {
        newOfficeData[attribute.value] = formData.isActive
            .split(",")
            .includes(attribute.value);
    });
    newOfficeData.RegionId = formData.region;
    newOfficeData.RegionName = regionName;
    newOfficeData.CountryName = formData.country;
    newOfficeData.RegionTypeId = formData.type;
    newOfficeData.RegionTypeName = getTypeOption(formData.type)?.label;
    newOfficeData.HolidayRegionId = formData.holidayOffice;
    newOfficeData.HolidayRegionName = holidayRegionName;
    attributesOptions.forEach((attribute) => {
        newOfficeData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });
    newOfficeData.OpenDate = formData.opened;
    newOfficeData.RegionDefaultCurrencyId = formData.currency ?? 0;
    newOfficeData.TimeZoneId = formData.timezone ?? 0;
    newOfficeData.Street = formData.addressLine ?? "";
    newOfficeData.CityName = formData.city ?? "";
    newOfficeData.StateName = formData.state ?? "";
    newOfficeData.PostCode = formData.zipCode ?? "";

    return newOfficeData;
}

export const getDropdownOptions = (
    optionDetails = [],
    Id = "Id",
    Name = "Name",
    defaultValue = "",
    defaultLabel = "None"
) => {
    return optionDetails.reduce(
        (acc, val) => {
            return [
                ...acc,
                {
                    value: val[Id],
                    label: val[Name],
                },
            ];
        },
        [
            {
                value: defaultValue,
                label: defaultLabel,
            },
        ]
    );
};

const RenderDate = ({ officeDataOpenDate, isEditing }) => {
    const currentOpenedDate = officeDataOpenDate
        ? getCalendarDateFromNewDate(new Date(officeDataOpenDate))
        : null;

    return (
        <DatePicker
            defaultDateValue={currentOpenedDate}
            name="opened"
            label="Opened"
            validations={{
                required: true,
            }}
            disabled={isEditing}
        />
    );
};

const OfficesForm = ({ officeIdToEdit, onClose }) => {
    const { offices, createOffice, updateOffice } = useOffices();
    const { regions } = useRegions();
    const { countries } = useOfficesCountries();
    const { currencies } = useOfficesCurrencies();
    const { timezones } = useOfficesTimezones();
    const holidayOfficesData = useRef([]);

    useEffect(() => {
        if (!offices?.isLoading) {
            holidayOfficesData.current = [];
            const officesUnique = offices.data.filter(
                (element, index, arr) =>
                    arr.findIndex((d) => element.ShortName == d.ShortName) ===
                    index
            );
            holidayOfficesData.current = officesUnique;
        }
    }, [offices]);

    if (offices.isLoading) {
        return <div>Loading...</div>;
    }

    if (offices.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof officeIdToEdit !== "undefined";
    const modifier = isEditing ? updateOffice : createOffice;

    const currentOfficeData = offices.data.find(
        (office) => office.OfficeRegionId === officeIdToEdit
    );

    if (isEditing && !currentOfficeData) {
        return <div>Could not find office</div>;
    }

    const regionDropdownOptions = !regions.isLoading
        ? getDropdownOptions(regions?.data, "RegionId")
        : [];

    const countryDropdownOptions = !countries.isLoading
        ? getDropdownOptions(countries?.data, "Name")
        : [];

    const currenciesDropdownOptions = !currencies.isLoading
        ? getDropdownOptions(currencies?.data)
        : [];

    const timezonesDropdownOptions = !timezones.isLoading
        ? getDropdownOptions(timezones?.data)
        : [];

    const holidayOfficeDropdownOptions =
        holidayOfficesData?.current?.length > 0
            ? getDropdownOptions(
                  holidayOfficesData.current,
                  "OfficeRegionId",
                  "Name"
              )
            : [];

    const onSubmit = (formData) => {
        const officeData = transformFormDataToOfficeData(
            formData,
            currentOfficeData,
            regionDropdownOptions?.find((f) => f.value === formData.region)
                ?.label,
            holidayOfficeDropdownOptions?.find(
                (f) => f.value === formData.holidayOffice
            )?.label
        );

        modifier.mutateAsync(officeData).then(() => {
            onClose();
            sendNotification(
                undefined,
                isEditing
                    ? `${officeData.Name} has been updated`
                    : `${officeData.Name} has been added`
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformOfficeDataToFormData(currentOfficeData)}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <VStack>
                        <TextInput
                            name="name"
                            label="Name"
                            validations={{
                                required: true,
                            }}
                            placeholder="Office Name"
                        />
                        <TextInput
                            name="shortName"
                            label="Short Name"
                            description="Using only 2 Characters"
                            maxLength={2}
                            validations={{
                                required: true,
                            }}
                            placeholder="e.g. NY"
                        />
                        <Options name="isActive" options={attributesOption} />
                    </VStack>

                    <VStack>
                        <Dropdown
                            name="region"
                            validations={{
                                required: true,
                            }}
                            label="Region"
                            options={regionDropdownOptions}
                            itemsAreLoading={regions.isLoading}
                        />
                        <Dropdown
                            name="country"
                            validations={{
                                required: true,
                            }}
                            label="Country"
                            options={countryDropdownOptions}
                            itemsAreLoading={countries.isLoading}
                        />
                        <Dropdown
                            name="type"
                            validations={{
                                required: true,
                            }}
                            label="Type"
                            options={OFFICE_TYPES}
                        />
                        <Dropdown
                            name="holidayOffice"
                            validations={{
                                required: true,
                            }}
                            label="Holiday office"
                            options={holidayOfficeDropdownOptions}
                            itemsAreLoading={offices.isLoading}
                        />

                        <Options
                            name="attributes"
                            label="Attributes"
                            options={attributesOptions}
                        />

                        <RenderDate
                            officeDataOpenDate={currentOfficeData?.OpenDate}
                            isEditing={isEditing}
                        />
                    </VStack>

                    <VStack>
                        <VStack spacing="Zero_25">
                            <Text variant="Headline_4" color="Gray_1">
                                Optional
                            </Text>
                            <Text variant="Body_2_1" color="Gray_2">
                                The fields below are not required
                            </Text>
                        </VStack>

                        <TextInput
                            name="addressLine"
                            label="Address line"
                            placeholder="e.g. 123 Street Name"
                        />
                        <TextInput
                            name="city"
                            label="City"
                            placeholder="e.g. Beijing"
                        />
                        <TextInput
                            name="state"
                            label="State"
                            placeholder="e.g. FL"
                        />
                        <TextInput
                            name="zipCode"
                            label="ZIP Code"
                            placeholder="e.g. 1000"
                        />
                        <Dropdown
                            name="currency"
                            label="Currency"
                            options={currenciesDropdownOptions}
                            itemsAreLoading={currencies.isLoading}
                        />

                        <Dropdown
                            name="timezone"
                            label="Time Zone"
                            options={timezonesDropdownOptions}
                            itemsAreLoading={timezones.isLoading}
                        />
                    </VStack>
                </VStack>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: isEditing ? "Save changes" : "Add Office",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        onClick: onClose,
                    }}
                    as="fieldset"
                    disabled={
                        modifier.isLoading ||
                        regions.isLoading ||
                        countries.isLoading ||
                        currencies.isLoading ||
                        timezones.isLoading
                    }
                />
            </VStack>
        </Form>
    );
};

export default OfficesForm;
