import { useEffect, useState } from "react";

export const useTopNavHeight = () => {
    const [topNavHeight, setTopNavHeight] = useState(0);

    useEffect(() => {
        document.getElementById("top-nav") &&
            setTopNavHeight(document.getElementById("top-nav").offsetHeight);
    }, [document.getElementById("top-nav")]);

    return topNavHeight;
}

