import { useMemo, useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";
import SessionContext from "context/app/SessionContext";

const useSelectorProjects = () => {
    const { getDataAsync } = useApi();
    const session = useContext(SessionContext);

    const getSelectorProjectsApiCall = useCallback(async () => {
        const response = await getDataAsync("selectorProjects", {
            urlParams: [
                { name: "$userId", value: session.id },
                { name: "$isAdmin", value: session.isLegacyAdminUser },
            ],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const selectorProjects = useQuery(
        "selectorProjectsList",
        getSelectorProjectsApiCall
    );

    return useMemo(() => {
        return {
            selectorProjects,
        };
    }, [selectorProjects]);
};

export default useSelectorProjects;
