import PropTypes from "prop-types";

const Profile = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.63677 12.0287H12.1611L13.6403 14.0345H14.8889L12.6676 11.0259H3.13533L0.888916 14.0345H2.1425L3.63677 12.0287Z"
                fill={color}
            />
            <path
                d="M7.89891 10.0229C10.1102 10.0229 11.9104 8.22278 11.9104 6.01146C11.9104 3.80014 10.1102 2 7.89891 2C5.68759 2 3.88745 3.80014 3.88745 6.01146C3.88745 8.22278 5.68759 10.0229 7.89891 10.0229ZM7.89891 3.00287C9.55865 3.00287 10.9075 4.35172 10.9075 6.01146C10.9075 7.6712 9.55865 9.02006 7.89891 9.02006C6.23917 9.02006 4.89032 7.6712 4.89032 6.01146C4.89032 4.35172 6.23917 3.00287 7.89891 3.00287Z"
                fill={color}
            />
        </svg>
    );
};

Profile.defaultProps = {
    size: "16",
    viewBox: "16",
    color: global.config.colors.black,
};

Profile.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default Profile;
