import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MoreMenu from "../../../UI/MoreMenu";
import { MenuItem } from "@szhsin/react-menu";
import ProjectDrawer from "./ProjectDrawer";
import styled from "styled-components";
import env from "react-dotenv";
import Folder from "components/Icon/Folder";
import Clock from "components/Icon/Clock";
import External from "components/Icon/External";
import GenericSidebarPortal from "components/Modal/Common/GenericSidebarPortal";
import { useCrypto } from "hooks/Crypto/useCrypto";
import usePageActionPermission from "../../../../hooks/Access/usePageActionPermission";

const useStyles = makeStyles(() => ({
    root: {
        display: "none",
        "& .MuiIconButton-root": {
            padding: 0,
            margin: 0,
        },
    },
    moreMenu: {
        maxHeight: "15rem",
        border: global.config.border.medium,
        borderRadius: "0.18rem",
        backgroundColor: global.config.colors.white,
    },
    itemLabel: {
        paddingRight: "16px",
    },
}));

const ButtonLink = styled.a`
    span {
        text-decoration: none;
        color: ${global.config.colors.black};
    }
    &:hover {
        text-decoration: none;
    }
    button {
        margin-bottom: 0;
    }
    margin-bottom: 0;
    padding: 0.375rem 0.9rem;
`;

export default function More(props) {
    const classes = useStyles();
    const { encrypt } = useCrypto();
    const [sidebarProjectId, setSidebarProjectId] = useState(null);
    const hasProjectActionsPermission = usePageActionPermission(
        "AC_VIEW_PROJECT_ACTION"
    );
    const { project } = props;

    const handleCloseDrawer = () => {
        setSidebarProjectId(null);
    };

    const handleViewProjectClick = (projectId) => {
        if (parseInt(projectId) > 1) {
            setSidebarProjectId(projectId);
        }
    };

    const menuOptions = [
        <MenuItem
            key={0}
            onClick={() => handleViewProjectClick(project.jobId)}
            disabled={props.infoDisabled}
        >
            <Folder size="16" />
            <span className={classes.itemLabel}>View Project Information</span>
        </MenuItem>,
        <MenuItem
            key={1}
            className="noPadding"
            hideItem={!hasProjectActionsPermission}
        >
            <ButtonLink
                href={`${global.config.routes.allocation}?filters=${encrypt(
                    "jobId=" + project.jobId
                )}`}
                target="_blank"
                rel="noreferrer"
            >
                <Clock size="16" />
                <span className={classes.itemLabel}>
                    View Project Allocations
                </span>
            </ButtonLink>
        </MenuItem>,
        <MenuItem
            key={2}
            className="noPadding"
            hideItem={!hasProjectActionsPermission}
        >
            <ButtonLink
                href={
                    "/redirectto?url=" +
                            env.BESTWORK_REACT_REPORTER10_LINK +
                            "job/JobReport.aspx?JobID=" +
                            project.jobId + 
                            "&env=" + env.ENVIRONMENT_NAME + "&vpn=true"
                }
                target="_blank"
                rel="noreferrer"
            >
                <External size="16" />
                <span className={classes.itemLabel}>
                    View Project Dashboard
                </span>
            </ButtonLink>
        </MenuItem>,
    ];

    return (
        <>
            {sidebarProjectId && (
                <GenericSidebarPortal
                    open={true}
                    content={<ProjectDrawer projectId={sidebarProjectId} />}
                    onClose={handleCloseDrawer}
                />
            )}
            <MoreMenu
                alwaysVisible={props.visible}
                direction={props.direction}
                portal={false}
            >
                {menuOptions}
            </MoreMenu>
        </>
    );
}

More.propTypes = {};
