import { useMsal } from "@azure/msal-react";
import MenuItem from "@material-ui/core/MenuItem";
import Text from "./Text";
import LogoutIcon from "components/Icon/Logout";
import styled from "styled-components";
import { clearToken } from "configs/Endpoints";

const TextWrapper = styled.div`
    margin: auto 0.5rem;
    height: 1.75rem;
    padding-top: 0.1rem;
`;

export const MenuProfileSignOutItem = () => {
    const { instance } = useMsal();
    const handleLogout = () => {
        instance.logout();
        clearToken();
    };
    return (
        <MenuItem onClick={handleLogout}>
            <LogoutIcon />
            <TextWrapper>
                <Text variant="medium">Log out</Text>
            </TextWrapper>
        </MenuItem>
    );
};
