export const getMoneyColumnVal = (symbol = "", val = 0) => {
    const money = `${symbol}${val.toFixed(2)}`;
    return val < 0 ? `(${money})` : money;
};

export const getMinPrevDateForDateRangePicker = (monthAgo = 2) => {
    const minDate = new Date(
        new Date().setMonth(new Date().getMonth() - monthAgo)
    );
    return {
        year: minDate.getFullYear(),
        month: minDate.getMonth() + 1,
    };
};

export const getMinutesToMilliseconds = (min) => {
    return min * 60000;
};

export const getCurrencyToLocaleString = (amount) => {
    return Number(amount).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getNumStrWithTwoDecimals = (val) => {
    return parseFloat(val).toFixed(2);
};