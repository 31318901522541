import { useApi } from "utilities/API/Helpers/useApi";

export const useNavigationLinks = () => {
    const { getDataAsync } = useApi();

    const getNavigation = async () => {
        return getDataAsync("navigationModules").then((payload) => {
            return payload.json();
        });
    };

    return {
        getNavigation,
    };
};
