import { useState } from "react";
import { useFormState } from "react-hook-form";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import TextInput from "components/design-system/forms/TextInput";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import useRegions from "./useRegions";
import Options from "components/design-system/forms/Options";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

const attributesOptions = [{ label: "Active", value: "Active" }];

const ModalCTA = ({ isEditing, isLoading, onClose }) => {
    const { isValid } = useFormState();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save Changes" : "Add Region",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformRegionDataToFormData(regionData = {}) {
    return {
        name: regionData.Name,
        shortName: regionData.ShortName,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) => regionData[item] ?? true)
            .join(","),
    };
}

function transformFormDataToRegionData(formData = {}, RegionData = {}) {
    const newRegionData = { ...RegionData };
    newRegionData.Id = RegionData.RegionId;
    newRegionData.Name = formData.name;
    newRegionData.ShortName = formData.shortName;
    attributesOptions.forEach((attribute) => {
        newRegionData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });
    return newRegionData;
}

const RegionsForm = ({ regionIdToEdit, onClose }) => {
    const { regions, createRegion, updateRegion } = useRegions();
    const [inProgress, setInProgress] = useState(false);

    if (regions.isLoading) {
        return <>Loading...</>;
    }

    if (regions.isError) {
        return <>Error</>;
    }

    const isEditing = typeof regionIdToEdit !== "undefined";
    const modifier = isEditing ? updateRegion : createRegion;

    const currentRegionData = regions.data.find(
        (region) => region.RegionId === regionIdToEdit
    );

    if (isEditing && !currentRegionData) {
        return <div>Could not find region</div>;
    }

    const regionData = isEditing
        ? regions.data.filter((region) => region.RegionId !== regionIdToEdit)
        : regions.data;

    const onSubmit = (formData) => {
        const data = transformFormDataToRegionData(formData, currentRegionData);

        modifier.mutateAsync(data).then((response) => {
            onClose();
            setInProgress(false);
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={isEditing ? currentRegionData.Name : data.Name}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformRegionDataToFormData(currentRegionData)}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack>
                    <TextInput
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Region Name"
                        validations={{
                            required: true,
                            validate: (value) =>
                                regionData.filter(
                                    (region) =>
                                        region.Name.toLowerCase() ===
                                        value.toLowerCase()
                                ).length === 0 || "Name is already exist",
                        }}
                    />
                    <TextInput
                        type="text"
                        name="shortName"
                        label="Short Name"
                        placeholder="eg. LATAM"
                        validations={{
                            required: true,
                            validate: (value) =>
                                value.length < 7 ||
                                "Type a Short Name that has 6 characters or less",
                        }}
                    />
                    <Options name="attributes" options={attributesOptions} />
                </VStack>

                <ModalCTA
                    isEditing={isEditing}
                    regionIdToEdit={regionIdToEdit}
                    onClose={onClose}
                    isLoading={modifier.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default RegionsForm;
