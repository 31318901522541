import React, { useContext, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import Loading from "components/UI/Loading";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TableFilters from "components/design-system/tables/TableFilters";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";

import { useProjectsNoteTypes } from "pages/projects/project-dashboard/single/useProjectsDashboardNotes";
import ProjectsDashboardLayoutContext from "context/Projects/ProjectsDashboardLayoutContext";

const tableTotalWidth = 1088;

const columns = [
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(42, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            icon:
                cell.value && !cell.row.original.isNoteVisited
                    ? "indicator"
                    : null,
            iconColor: COLORS.Yellow,
        }),
        accessor: "showNotificationIcon",
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(160, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? format(new Date(cell.value), "MMMM dd, yyyy")
                : "-",
            description: cell.row.original.Creator,
        }),
        accessor: "Date",
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(160, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "TypeName",
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(672, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            ellipsisAtLine: 3,
            cellTextOnMouseEnter: () =>
                cell.row.toggleRowExpanded(!cell.row.isExpanded),
            cellTextOnTextMouseLeave: () =>
                cell.row.toggleRowExpanded(!cell.row.isExpanded),
        }),
        enableExpanding: true,
        accessor: "Text",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(32, tableTotalWidth),
        },
        accessor: "Active",
        disableSortBy: true,

        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIconWidth: 20,
            actionIcon: cell.row.original.isCreatorOfNote
                ? "edit"
                : "cursor-default",
            actionProps: {
                to: cell.row.original.isCreatorOfNote
                    ? `?action=edit&id=${cell.row.original.Id}`
                    : `?action=view&id`,
                replace: true,
                "aria-label": cell.row.original.isCreatorOfNote
                    ? "Edit"
                    : "View",
            },
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add a Note",
    to: "?action=add",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const sortBy = [
    {
        id: "Date",
        desc: true,
    },
];

const dropdownNames = {
    type: "noteType",
};

const FiltersConfig = [
    {
        name: dropdownNames.type,
        columnAccessor: "TypeId",
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: "Note Type",
    },
];

const ProjectsDashboardNotesTable = () => {
    const { projectsNoteTypes } = useProjectsNoteTypes();
    const { updatedNotesData, notesList, setFilters } = useContext(
        ProjectsDashboardLayoutContext
    );
    const [filtersConfigVal, setFiltersConfigVal] = useState(FiltersConfig);

    // When tab changes or component loads set initial state
    useEffect(() => {
        setFilters([]);
    }, []);

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const typeIndex = newObj.findIndex(
            (f) => f.name === dropdownNames.type
        );
        newObj[typeIndex].options = !projectsNoteTypes.isLoading
            ? getDropdownOptions(
                  projectsNoteTypes?.data,
                  "Id",
                  "Name",
                  "all",
                  "All"
              )
            : [];

        !projectsNoteTypes.isLoading &&
            newObj[typeIndex].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    }, [projectsNoteTypes]);

    const memoizedTable = useMemo(
        () => (
            <>
                {!updatedNotesData ? (
                    <Loading />
                ) : (
                    <Table
                        noContentInHeaderRow={true}
                        dataIsFetching={notesList?.isFetching}
                        showTableBorder={false}
                        columns={columns}
                        data={updatedNotesData}
                        emptyMessageTableTitle={"Notes"}
                        getRowProps={getRowProps}
                        expandedAllRows={false}
                        sortBy={sortBy}
                    />
                )}
            </>
        ),
        [updatedNotesData, notesList]
    );

    if (projectsNoteTypes.isLoading) {
        return <Loading />;
    }

    if (notesList?.isError || projectsNoteTypes.isError) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="end" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            filtersConfig={filtersConfigVal}
                            onChange={setFilters}
                        />
                    </HStack>
                    <SecondaryButton {...tableCTAProps} />
                </HStack>

                {memoizedTable}
            </VStack>
        </>
    );
};

export default React.memo(ProjectsDashboardNotesTable);
