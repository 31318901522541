import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { useAllocationsMessages } from "hooks/Allocations/useAllocationsMesages";
import { allocationsGridModes } from "resources/Enums";
import { sendNotification } from "utilities/Notification";
import Link from "components/UI/Link";
import SessionContext from "context/app/SessionContext";
import AllocationSignalRContext from "context/Allocations/SignalRContext";

const Context = React.createContext();

export const AllocationsProvider = ({ mode, ...props }) => {
    const [event, setEvent] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [gridParams, setGridParams] = useState({});
    const [notificationDisplayed, setNotificationDisplayed] = useState(false);
    const [userQueryAccess, setUserQueryAccess] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState({loading: false, page: 1, message: "", type: ""});
    const [columnsView, setColumnsView] = useState(4);
    const [groupBy, setGroupBy] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [openFilters, setOpenFilters] = useState(() => {});
    const [totalAmountOfResults, setTotalAmountOfResults] = useState(0);
    const [query, setQuery] = useState(null);

    let messagesHub = null;
    let allocationHubConnectionId = null;
    const session = useContext(SessionContext);
    const signalRsession = useContext(AllocationSignalRContext);
    const messagesRef = useRef([]);
    const employeesRef = useRef(employees);
    const { processMessagesQueue } = useAllocationsMessages();

    useEffect(() => {
        if (event) {
            messagesHub = signalRsession.messagesHub;
            allocationHubConnectionId =
                signalRsession.allocationHubConnectionId;

            console.info(
                "SignalR - Allocations - Connection state",
                messagesHub.connection.connectionState
            );
        }
        if (event && messagesHub?.connection.connectionState !== "Connected") {
            console.error(
                "SignalR - Allocations - Can't connect to SignalR hub"
            );
        } else if (event) {
            console.info("SignalR - Allocations - Subscribe to events...");
            subscribeToEvent(messagesHub, event, session);
        }
    }, [event]);

    const subscribeToEventAddMessage = (message) => {
        console.info("SignalR - Allocations - New message", message);
        addMessage(JSON.parse(message));
    };

    const subscribeToEvent = (hub, srEvent, srSession) => {
        console.info("SignalR - Allocations - Subscribe to events", srEvent);
        hub.off(srEvent);
        srSession &&
            srEvent &&
            hub.on(srEvent, (message) => {
                console.info(
                    "SignalR - Allocations - Start subscription",
                    srEvent
                );
                srSession && message && subscribeToEventAddMessage(message);
            });
    };

    const addMessage = (message) => {
        let validMessage = false;

        console.info("SignalR - Allocations - Process new message");

        const employee = employeesRef.current
            .flat()
            .find(
                (employee) =>
                    employee.id === message.UserId &&
                    employee.titleId === message.TitleId &&
                    employee.locationId === message.OfficeId &&
                    employee.summary?.some(
                        (allocation) => allocation.date === message.Date
                    )
            );

        validMessage =
            employee &&
            (message.UpdatedBy !== session.userID ||
                allocationHubConnectionId !== message.ClientId);

        if (validMessage) {
            console.info("SignalR - Allocations - Show update alert");
            messagesRef.current.push(message);
            !notificationDisplayed && setNotificationDisplayed(true);
        } else {
            console.info("SignalR - Allocations - Ignore message");
        }
    };

    useEffect(() => {
        setEvent(getNotificationsEventName(mode));
    }, [mode]);

    useEffect(() => {
        employeesRef.current = employees;
    }, [employees]);

    useEffect(() => {
        messagesRef.current.length > 0 &&
            !notificationDisplayed &&
            setNotificationDisplayed(true);
    }, [messagesRef.current]);

    useEffect(() => {
        notificationDisplayed && showNotification();
    }, [notificationDisplayed]);

    const getNotificationsEventName = (mode) => {
        console.info("SignalR - Allocations - Message mode", mode);
        switch (mode) {
            case allocationsGridModes.daily:
                return "newAllocationHour_Daily";
            case allocationsGridModes.weekly:
                return "newAllocationHour_Weekly";
            case allocationsGridModes.monthly:
                return "newAllocationHour_Monthly";
            default:
                throw new Error(`Invalid grid mode ${mode}`);
        }
    };

    const handleUpdate = () => {
        const stringCopy = JSON.stringify([...employeesRef.current]);
        const employeesCopy = JSON.parse(stringCopy);
        const updatedEmployees = processMessagesQueue(
            employeesCopy,
            messagesRef.current
        );
        setEmployees(updatedEmployees);
        messagesRef.current = [];
    };

    const handleOnRemoval = () => {
        setNotificationDisplayed(false);
    };

    const showNotification = () => {
        sendNotification(
            undefined,
            updateEmployeesMessage(),
            0,
            "SignalR_Allocations",
            handleOnRemoval
        );
    };

    const updateEmployeesMessage = () => {
        return (
            <>
                {`Allocations in this view have been updated.`}
                <Link
                    variant="secondary"
                    color={global.config.colors.white}
                    href={void 0}
                    onClick={handleUpdate}
                >
                    Update
                </Link>
            </>
        );
    };

    const contextValue = useMemo(() => {
        return {
            employees,
            setEmployees,
            gridParams,
            setGridParams,
            isLoadingMore,
            setIsLoadingMore,
            columnsView,
            setColumnsView,
            groupBy,
            setGroupBy,
            sortBy,
            setSortBy,
            openFilters,
            setOpenFilters,
            userQueryAccess,
            setUserQueryAccess,
            totalAmountOfResults,
            setTotalAmountOfResults,
            query, 
            setQuery
        };
    }, [
        employees,
        gridParams,
        isLoadingMore,
        columnsView,
        groupBy,
        sortBy,
        openFilters,
        userQueryAccess,
        totalAmountOfResults,
        query
    ]);

    return mode !== null ? (
        <Context.Provider value={contextValue}>
            {props.children}
        </Context.Provider>
    ) : (
        <div>Loading...</div>
    );
};

export default Context;

AllocationsProvider.defaultProps = {
    mode: allocationsGridModes.weekly,
};
