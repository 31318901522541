import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useStylesForText } from "styles/MakeStyles";
import Theme from "styles/Theme";

const Text = (props) => {
    const classes = useStylesForText();
    let customVariant = Theme.typography[props.variant];
    let style = customVariant;
    if (style !== undefined) {
        if (props.style) {
            style = { ...props.style, ...customVariant };
        }
    } else {
        style = props.style;
    }

    return (
        <Typography
            classes={{
                root: props.className
                    ? props.className + " " + classes.root
                    : classes.root,
            }}
            style={style}
            title={props.title ? props.title : undefined}
        >
            {props.children}
        </Typography>
    );
};

Text.defaultProps = {
    variant: "default",
    style: undefined,
    title: undefined,
};

Text.propTypes = {
    variant: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

export default Text;
