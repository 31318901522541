import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAgencies = () => {
    const { getDataAsync, putData, postData } = useApi();
    const queryClient = useQueryClient();

    const getAgenciesApiCall = useCallback(async () => {
        const response = await getDataAsync("agencies");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const agencies = useQuery("agencyList", getAgenciesApiCall);

    const createAgencyApiCall = useCallback(
        (body) => {
            return postData("agencies", {
                body,
            });
        },
        [postData]
    );

    const updateAgencyApiCall = useCallback(
        (body) => {
            return putData("agencies", {
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("agencyList");
            },
        };
    }, [queryClient]);

    const createAgency = useMutation(createAgencyApiCall, mutationOptions);
    const updateAgency = useMutation(updateAgencyApiCall, mutationOptions);

    return useMemo(() => {
        return {
            agencies,
            createAgency,
            updateAgency,
        };
    }, [agencies, createAgency, updateAgency]);
};

export default useAgencies;
