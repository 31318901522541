import PropTypes from "prop-types";

const AngleCirculeLeft = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle r="8.5" transform="matrix(-1 0 0 1 9 9)" stroke="#E5E8EC" />
            <path d="M10.125 12.375L6.75 9L10.125 5.625" stroke={color} />
        </svg>
    );
};

AngleCirculeLeft.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

AngleCirculeLeft.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default AngleCirculeLeft;
