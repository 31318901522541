import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { clearToken, getToken, setEndpoints } from "configs/Endpoints";

// Material-UI imports
import { ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Theme from "styles/Theme";

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "configs/Authentication";
import { ErrorComponent } from "components/UI/ErrorComponent";

// app imports
import { PageLayout } from "components/UI/PageLayout";
import {
    ErrorTestPage,
    AllocationsPage,
    Unauthorized,
    Error,
    SessionExpired,
    BusinessIntelligencePage,
    TimesheetMainPage,
    TimesheetsPage,
    Home,
    RedirectToPage,
    ProjectsMainPage,
} from "pages";
import Agencies from "pages/admin/agencies/Agencies";
import AccessGroupLayout from "pages/admin/access-groups/single/AccessGroupLayout";
import AccessGroupMembers from "pages/admin/access-groups/single/AccessGroupMembers";
import TimesheetGrid from "pages/timesheet/timesheet-grid/TimesheetGrid";
import { DrawerContextProvider } from "context/Drawer/DrawerContext";
import AdminMainPage from "pages/AdminMainPage";
import AccessGroups from "pages/admin/access-groups/AccessGroups";
import AccessGroupPageAccessPage from "pages/admin/access-groups/single/AccessGroupPageAccessPage";
import Regions from "pages/admin/regions/Regions";
import Offices from "pages/admin/offices/Offices";
import IncompleteTimesheet from "pages/timesheet/incomplete-timesheet/IncompleteTimesheet";
import { WeekNavigatorContextProvider } from "context/WeekNavigator/WeekNavigatorContext";
import { TimesheetGridContextProvider } from "context/Timesheet/TimesheetGridContext";
import Clients from "pages/admin/clients/Clients";
import Holidays from "pages/admin/holidays/Holidays";
import ClientGroups from "pages/admin/clients/single/ClientGroups";
import ClientContactsAndRateCards from "pages/admin/clients/single/ClientContactsAndRateCards";
import ClientsLayout from "pages/admin/clients/single/ClientsLayout";
import Projects from "pages/admin/projects/Projects";
import ProjectsLinked from "pages/admin/projects/single/ProjectsLinked";
import ProjectsLayout from "pages/admin/projects/single/ProjectsLayout";
import UserTimeOff from "pages/timesheet/time-off/UserTimeOff";
import AbsenseReasons from "pages/admin/absense-reasons/AbsenseReasons";
import ClientFamilyCorp from "./pages/admin/client-family/ClientFamilyCorp";
import RetainersDashboardLayout from "pages/projects/retainers-dashboard/single/RetainersDashboardLayout";
import RetainersDashboardOverview from "pages/projects/retainers-dashboard/single/RetainersDashboardOverview";
import RetainersDashboardDetails from "pages/projects/retainers-dashboard/single/RetainersDashboardDetails";
import RetainersDashboardNotes from "pages/projects/retainers-dashboard/single/RetainersDashboardNotes";
import RetainersDashboard from "pages/projects/retainers-dashboard/RetainersDashboard";
import { RetainersDashboardLayoutContextProvider } from "context/Projects/RetainersDashboardLayoutContext";
import { ProjectsDashboardLayoutContextProvider } from "context/Projects/ProjectsDashboardLayoutContext";
import { TSHolidays } from "pages/timesheet/holidays/TSHolidays";

// Styles
import GlobalStyle from "styles/Global";
import HelveticaNow from "components/design-system/ui/HelveticaNow";

// global config
import "configs/Global";

//app context
import { SessionProvider } from "context/app/SessionContext";
import { AppErrorProvider } from "context/app/AppErrorContext";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

//insigths
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "utilities/Insights/AppInsigths";

//boundaries
import AppErrorBoundary from "boundaries/AppErrorBoundary";

//version app
import Build from "configs/Build";

import ManageTimeOff from "pages/timesheet/manage-time-off/ManageTimeOff";
import { ManageTimeOffContextProvider } from "context/ManageTimeOff/ManageTimeOffContext";
import Retainers from "pages/admin/retainers/Retainers";

import env from "react-dotenv";

import { AllocationsPageContextProvider } from "context/Allocations/AllocationsPageContext";
import ProjectsDashboard from "pages/projects/project-dashboard/ProjectsDashboard";
import ProjectsDashboardLayout from "pages/projects/project-dashboard/single/ProjectsDashboardLayout";
import ProjectsDashboardOverview from "pages/projects/project-dashboard/single/ProjectsDashboardOverview";
import ProjectsDashboardDetails from "pages/projects/project-dashboard/single/ProjectsDashboardDetails";
import ProjectsDashboardNotes from "pages/projects/project-dashboard/single/ProjectsDashboardNotes";
import AccessGroupLanding from "pages/admin/access-groups/single/AccessGroupLanding";
import ProjectPlanner from "pages/projects/planner/ProjectPlanner";
import { useErrors } from "hooks/Errors/useErrors";
import { useInsigths } from "utilities/Insights/InsightsLogs";


async function initializeEndpoints() {
    return setEndpoints().then((r) => {
        return r ? true : false;
    });
}

function App({ pca }) {
    const [loading, setLoading] = useState(true);
    const authRequest = {
        ...loginRequest,
    };
    const { handleError } = useErrors();
    const { insightsTrace } = useInsigths();

    //global setup, it's not necessary to set refetchOnWindowFocus in each call
    //if in some cases we need it as true, set in the query, not here
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                cacheTime: 0,
            },
        },
        queryCache: new QueryCache({
            onError: (_error, query) => {
                if (query?.state?.error?.body) {
                    handleError(query.state.error.body);
                }
            },
        }),
    });

    const logout = () => {
        pca.logout();
        clearToken();
    };

    useEffect(() => {
        insightsTrace("SESSION", "Starts...");
        initializeEndpoints().then(() => {
            getToken(pca).then(() => {
                setLoading(false);
                const failmsg = "The app can't reload the page";
                Build.get()
                    .then((feVersion) => {
                        let localVersion = localStorage.getItem("app-version");

                        if (!localVersion && feVersion?.version) {
                            localVersion = feVersion.version;
                            localStorage.setItem("app-version", localVersion);
                        }
                        if (localVersion !== feVersion?.version) {
                            localStorage.setItem(
                                "app-version",
                                feVersion.version
                            );
                            logout();
                        }
                        if (!feVersion) {
                            console.log(failmsg);
                        }
                    })
                    .catch(() => {
                        console.log(failmsg);
                    });
            });
        });
    }, []);

    const layout = (
        <>
            <AppInsightsContext.Provider value={reactPlugin}>
                <AppErrorBoundary>
                    <AppErrorProvider>
                        <QueryClientProvider client={queryClient}>
                            <BrowserRouter>
                                <ThemeProvider theme={Theme}>
                                    <MsalProvider instance={pca}>
                                        <MsalAuthenticationTemplate
                                            interactionType={
                                                InteractionType.Redirect
                                            }
                                            authenticationRequest={authRequest}
                                            errorComponent={ErrorComponent}
                                        >
                                            <GlobalStyle />
                                            <HelveticaNow />
                                            <ReactNotifications></ReactNotifications>
                                            <SessionProvider>
                                                <PageLayout>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Pages />
                                                    </Grid>
                                                </PageLayout>
                                            </SessionProvider>
                                        </MsalAuthenticationTemplate>
                                    </MsalProvider>
                                </ThemeProvider>
                            </BrowserRouter>
                        </QueryClientProvider>
                    </AppErrorProvider>
                </AppErrorBoundary>
            </AppInsightsContext.Provider>
            <div id="divAppErrorModal"></div>
        </>
    );

    const maintenancePage = (
        <>
            <div
                style={{
                    position: "absolute",
                    left: "30%",
                    top: "30%",
                    fontSize: "22px",
                }}
            >
                The site is under maintenance. It will be available in some
                minutes.
            </div>
            <img
                src="/assets/images/maintenance.png"
                width="90%"
                height="90%"
                style={{ padding: 0, margin: 0 }}
            ></img>
        </>
    );

    return env.UNDER_MAINTENANCE == "true"
        ? maintenancePage
        : !loading && layout;
}

function Pages() {
    return (
        <>
            <Routes>
                <Route path="test/error" element={<ErrorTestPage />}></Route>
                <Route
                    path={global.config.routes.sessionExpired}
                    element={<SessionExpired />}
                ></Route>
                <Route
                    path={global.config.routes.allocation}
                    element={
                        <AllocationsPageContextProvider>
                            <AllocationsPage />
                        </AllocationsPageContextProvider>
                    }
                ></Route>
                <Route
                    path={global.config.routes.unauthorized}
                    element={<Unauthorized />}
                ></Route>
                <Route
                    path={global.config.routes.error}
                    element={<Error />}
                ></Route>
                <Route path="/" element={<Home />}></Route>
                <Route
                    path={global.config.routes.businessIntelligenceLandingPage}
                    element={<BusinessIntelligencePage />}
                ></Route>
                <Route
                    path={global.config.routes.adminLandingPage}
                    element={<AdminMainPage />}
                ></Route>
                <Route
                    path={global.config.routes.adminAgenciesLanding}
                    element={<Agencies />}
                />
                <Route
                    path={global.config.routes.adminAbsenseReasons}
                    element={<AbsenseReasons />}
                />
                <Route
                    path={global.config.routes.adminClientFamilyCorp}
                    element={<ClientFamilyCorp />}
                />

                <Route
                    path={global.config.routes.adminAccessGroupsLanding}
                    element={
                        <DrawerContextProvider>
                            <AccessGroups />
                        </DrawerContextProvider>
                    }
                />
                <Route
                    path="/admin/accessgroups/:groupId"
                    element={<AccessGroupLayout />}
                >
                    <Route
                        index
                        element={<Navigate to="members" replace={true} />}
                    />
                    <Route path="members" element={<AccessGroupMembers />} />
                    <Route
                        index
                        element={<Navigate to="pageaccess" replace={true} />}
                    />
                    <Route
                        path="pageaccess"
                        element={<AccessGroupPageAccessPage />}
                    />
                    <Route
                        index
                        element={<Navigate to="landing" replace={true} />}
                    />
                    <Route path="landing" element={<AccessGroupLanding />} />
                </Route>

                <Route
                    path={global.config.routes.adminRegionsLanding}
                    element={<Regions />}
                />
                <Route
                    path={global.config.routes.adminOfficesLanding}
                    element={<Offices />}
                />

                <Route
                    path={global.config.routes.adminClientsLanding}
                    element={<Clients />}
                />
                <Route
                    path={`${global.config.routes.adminClientsLanding}/:clientId`}
                    element={<ClientsLayout />}
                >
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.adminClientsGroups}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.adminClientGroups}
                        element={<ClientGroups />}
                    />
                    <Route
                        index
                        element={
                            <Navigate
                                to={
                                    global.config.routes
                                        .adminClientsContactsAndCards
                                }
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.adminClientContactsAndCards}
                        element={<ClientContactsAndRateCards />}
                    />
                </Route>
                <Route
                    path={global.config.routes.adminHolidaysLanding}
                    element={<Holidays />}
                />
                <Route
                    path={global.config.routes.adminProjectsLanding}
                    element={<Projects />}
                />

                <Route
                    path={`${global.config.routes.adminProjectsLanding}/:projectId`}
                    element={<ProjectsLayout />}
                >
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.adminProjectsLinked}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.adminProjectsLinked}
                        element={<ProjectsLinked />}
                    />
                </Route>

                <Route
                    path={global.config.routes.adminRetainersLanding}
                    element={<Retainers />}
                />

                <Route path="/timesheets" element={<TimesheetsPage />}></Route>

                <Route
                    path={global.config.routes.timesheetLandingPage}
                    element={
                        <WeekNavigatorContextProvider>
                            <TimesheetGridContextProvider>
                                <ManageTimeOffContextProvider>
                                    <TimesheetMainPage />
                                </ManageTimeOffContextProvider>
                            </TimesheetGridContextProvider>
                        </WeekNavigatorContextProvider>
                    }
                >
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.timesheetGrid}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.timesheetGrid}
                        element={<TimesheetGrid />}
                    />
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.timesheetIncomplete}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.timesheetIncomplete}
                        element={<IncompleteTimesheet />}
                    />
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.timeOff}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.timeOff}
                        element={<UserTimeOff />}
                    />
                    <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.manageTimeOff}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.manageTimeOff}
                        element={<ManageTimeOff />}
                    />
                     <Route
                        index
                        element={
                            <Navigate
                                to={global.config.routes.timesheetHolidays}
                                replace={true}
                            />
                        }
                    />
                    <Route
                        path={global.config.routes.timesheetHolidays}
                        element={<TSHolidays />}
                    />
                </Route>

                <Route
                    path={global.config.routes.projectLanding}
                    element={<ProjectsMainPage />}
                ></Route>

                <Route
                    path={
                        global.config.routes.projectsRetainersDashboardLanding
                    }
                    element={<RetainersDashboard />}
                />
                <Route
                    path={`${global.config.routes.projectsRetainersDashboardLanding}/:retainerId`}
                    element={
                        <RetainersDashboardLayoutContextProvider>
                            <RetainersDashboardLayout />
                        </RetainersDashboardLayoutContextProvider>
                    }
                >
                    <Route
                        index
                        element={<Navigate to="overview" replace={true} />}
                    />
                    <Route
                        path="overview"
                        element={<RetainersDashboardOverview />}
                    />
                    <Route
                        index
                        element={<Navigate to="details" replace={true} />}
                    />
                    <Route
                        path="details"
                        element={<RetainersDashboardDetails />}
                    />
                    <Route
                        index
                        element={<Navigate to="notes" replace={true} />}
                    />
                    <Route path="notes" element={<RetainersDashboardNotes />} />                    
                </Route>

                <Route
                    path={global.config.routes.projectsDashboardLanding}
                    element={<ProjectsDashboard />}
                />
                <Route
                    path={`${global.config.routes.projectsDashboardLanding}/:projectId`}                    
                    element={
                        <ProjectsDashboardLayoutContextProvider>
                            <ProjectsDashboardLayout />
                        </ProjectsDashboardLayoutContextProvider>
                    }

                >
                    <Route
                        index
                        element={<Navigate to="overview" replace={true} />}
                    />
                    <Route
                        path="overview"
                        element={<ProjectsDashboardOverview />}
                    />
                    <Route
                        index
                        element={<Navigate to="details" replace={true} />}
                    />
                    <Route
                        path="details"
                        element={<ProjectsDashboardDetails />}
                    />
                    <Route
                        index
                        element={<Navigate to="notes" replace={true} />}
                    />
                    <Route path="notes" element={<ProjectsDashboardNotes />} />
                </Route>

                <Route path="/redirectto" element={<RedirectToPage />}></Route>

                <Route
                    path={global.config.routes.projectPlanner}
                    element={<ProjectPlanner />}
                />
            </Routes>
        </>
    );
}

export default App;
