import PropTypes from "prop-types";
import styled from "styled-components";

const Circle = styled.circle`
    stroke: ${(props) => props.color};
    fill: ${(props) => props.fill};
`;

const Path = styled.path`
    stroke: ${(props) => props.color};
`;

const Icon = styled.svg`
    fill: none;
    &:hover {
        ${Circle} {
            fill: ${(props) =>
                props.hover && !props.disabled
                    ? props.circleFillHoverColor
                    : props.circleFillColor};
            stroke: ${(props) =>
                props.hover && !props.disabled
                    ? props.circleStrokeHoverColor
                    : props.circleStrokeColor};
        }
        ${Path} {
            stroke: ${(props) =>
                props.hover && !props.disabled
                    ? props.arrowStrokeHoverColor
                    : props.arrowStrokeColor};
        }
    }
    ${Path} {
        stroke: ${(props) => props.disabled && global.config.colors.gray4};
            
}
cursor:${(props) => (props.disabled ? "auto" : "pointer")};,
`;

const arrow = (strokeColor, direction) => (
    <Path color={strokeColor} d={direction} strokeWidth="1.17" />
);

const DEFAULT_DRECTION = "M10 17L15 12L10 7";
const DIRECTIONS = {
    up: "M13 11L9 7L5 11",
    right: "M10 17L15 12L10 7",
    down: "M13 7L9 11L5 7",
    left: "M14 17L9 12L14 7",
};

const Arrow = ({
    direction,
    size,
    viewBox,
    circle,
    hover,
    arrowStrokeColor,
    circleStrokeColor,
    circleFillColor,
    arrowStrokeHoverColor,
    circleStrokeHoverColor,
    circleFillHoverColor,
    disabled,
}) => {
    return (
        <Icon
            width={size}
            height={size}
            viewBox={`0 0 ${viewBox} ${viewBox}`}
            xmlns="http://www.w3.org/2000/svg"
            hover={hover}
            arrowStrokeHoverColor={arrowStrokeHoverColor}
            circleStrokeHoverColor={circleStrokeHoverColor}
            circleFillHoverColor={circleFillHoverColor}
            disabled={disabled}
        >
            {circle && (
                <Circle
                    color={circleStrokeColor}
                    fill={circleFillColor}
                    r="11.5"
                    transform="matrix(-1 0 0 1 12 12)"
                    strokeWidth="1.17"
                />
            )}
            {arrow(arrowStrokeColor, DIRECTIONS[direction] || DEFAULT_DRECTION)}
        </Icon>
    );
};

Arrow.defaultProps = {
    size: "24",
    viewBox: "24",
    circle: true,
    hover: false,
    arrowStrokeColor: global.config.colors.black,
    arrowStrokeHoverColor: global.config.colors.black,
    circleStrokeColor: global.config.colors.gray6,
    circleStrokeHoverColor: global.config.colors.gray6,
    circleFillColor: "transparent",
    circleFillHoverColor: global.config.colors.gray7,
    disabled: false,
};

Arrow.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    circle: PropTypes.bool,
    hover: PropTypes.bool,
    arrowStrokeColor: PropTypes.string,
    arrowStrokeHoverColor: PropTypes.string,
    circleStrokeColor: PropTypes.string,
    circleStrokeHoverColor: PropTypes.string,
    circleFillColor: PropTypes.string,
    circleFillHoverColor: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Arrow;
