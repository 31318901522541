export class ValidationError extends Error {
    constructor(message, status, correlationId, body, date) {
        super(message);
        this.status = status;
        this.correlationId = correlationId;
        this.body = body;
        this.date = date ? date : Date.now();
        this.name = "ValidationError";
    }
}

export class APIError extends Error {
    constructor(message, url, params, date) {
        super(message);
        this.date = date ? date : Date.now();
        this.url = url;
        this.params = params;
        this.name = "APIError";
    }
}

export class ServerError extends Error {
    constructor(message, status, correlationId, body, url, date) {
        super(message);
        this.status = status;
        this.correlationId = correlationId;
        this.body = body;
        this.date = date ? date : Date.now();
        this.url = url;
        this.name = "ServerError";
        this.title = body ? body.title : "Unknown";
        this.detail = body ? body.detail : "Unknown";
    }
}

export class FrontendError extends Error {
    constructor(message, date) {
        super(message);
        this.date = date ? date : Date.now();
        this.name = "FrontendError";
    }
}
