import { useMemo, useCallback, useContext } from "react";
import { useQuery } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";
import SessionContext from "context/app/SessionContext";

const useSelector = (endpointName) => {
    const session = useContext(SessionContext);
    const { getDataAsync } = useApi();

    const getList = useCallback(async () => {
        return await getDataAsync(endpointName, {
            urlParams: [
                { name: "$active", value: "" },
                { name: "$userId", value: session.id },
                { name: "$agencyId", value: session.agencyId },
                { name: "$isAdmin", value: session.isLegacyAdminUser },
            ],
        }).then((response) => {
            return response.status != 204 ? response.json() : null;
        });
    }, [getDataAsync]);


    const list = useQuery(`${endpointName}List`, getList);

    return useMemo(() => {
        return {
            list
        };
    }, [list]);
}

export default useSelector;
