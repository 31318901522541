import React, { useEffect, useState } from "react";

import HStack from "components/design-system/layout/HStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

import useClientCampaigns, {
    useProjectByCampaignsIdApi,
} from "pages/admin/clients/single/useClientCampaigns";

const Table_Width = 416;

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(72, Table_Width),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows?.filter((row) => {
                return (
                    (filterValue === "active" && row.values.Active === true) ||
                    (filterValue === "inactive" && row.values.Active === false)
                );
            });
        },
        accessor: "ClientCampaignId",
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(192, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "CampaignName",
    },
    {
        headCellProps: {
            text: "Projects",
            width: getCellWidthInPercent(120, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "projects",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(100),
        },
        accessor: "Active",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=editCampaign&id=${cell.row.original.ClientCampaignId}`,
                replace: true,
                "aria-label": "Edit",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "ClientCampaignId",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add",
    to: "?action=addCampaign",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const ClientCampaignTable = () => {
    const [filters, setFilters] = useState();
    const { clientCampaignsByClientId } = useClientCampaigns();

    const allCampaignsId = !clientCampaignsByClientId.isLoading
        ? clientCampaignsByClientId.data
              .map((d) => d.ClientCampaignId)
              .join(",")
        : "";
    const { useProjectByCampaignsId } = useProjectByCampaignsIdApi();
    const allProjectsByCampaigns = useProjectByCampaignsId(allCampaignsId);

    const [tableData, setTableData] = useState([]);
    const [tableUpdated, setTableUpdated] = useState(false);

    useEffect(() => {
        if (!clientCampaignsByClientId.isLoading) {
            setTableData(clientCampaignsByClientId.data);
        }
    }, [clientCampaignsByClientId]);

    // get projects count resp to campaign id
    const countOfProjectsById =
        !allProjectsByCampaigns.isLoading &&
        allProjectsByCampaigns?.data?.length > 0
            ? allProjectsByCampaigns.data.reduce((acc, current) => {
                  return (
                      (acc[current.ClientCampaignId] =
                          acc[current.ClientCampaignId] + 1 || 1),
                      acc
                  );
              }, {})
            : {};

    // add projects count in table data
    useEffect(() => {
        if (Object.keys(countOfProjectsById).length > 0 && !tableUpdated) {
            const newData = [...tableData];
            Object.entries(countOfProjectsById).forEach(([key, value]) => {
                const index = tableData?.findIndex(
                    (f) => f.ClientCampaignId == key
                );
                newData[index] = { ...newData[index], projects: value };
            });
            setTableData(newData);
            setTableUpdated(true);
        }
    }, [countOfProjectsById]);

    if (clientCampaignsByClientId.isLoading) {
        return <>Loading...</>;
    }

    if (clientCampaignsByClientId.isError || allProjectsByCampaigns.isError) {
        return <>Error</>;
    }

    return (
        <>
            <HStack align="end" justify="space-between">
                <TableFilters
                    filtersConfig={FiltersConfig}
                    onChange={setFilters}
                />
                <TertiaryButton {...tableCTAProps} />
            </HStack>
            <Table
                columns={columns}
                data={tableData}
                filters={filters}
                emptyActionProps={tableCTAProps}
                emptyMessageTableTitle={"Campaigns"}
                getRowProps={getRowProps}
            />
        </>
    );
};

export default React.memo(ClientCampaignTable);
