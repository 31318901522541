import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import env from "react-dotenv";
import React, { useState, useEffect, useContext } from "react";
import {
    getPowerBIToken,
    GetPowerBIAllDashboards,
    GetPowerBIDashboardsByUserEmail,
} from "configs/PowerBI";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import Icon from "components/design-system/ui/Icon";
import SPACING, { SPACING_VALUES } from "components/design-system/config/spacing";
import VStack from "components/design-system/layout/VStack";
import PageWrapper, { LoadingWrapper } from "pages/PageWrapper";
import Tabs from "../components/design-system/ui/Tabs";
import SessionContext from "context/app/SessionContext";
import QuaternaryButton from "components/design-system/controls/button/QuaternaryButton";
import ProvideFeedback from "components/UI/ProvideFeedback";
import PowerBIReport from "../pages/businessIntelligence/PowerBIReport";
import { groupBy } from "lodash";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Footer from "components/design-system/ui/Footer";
import Tooltip from "components/design-system/ui/Tooltip";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import Loading from "components/UI/Loading";
import { useErrors } from "hooks/Errors/useErrors";

const StyledLinked = styled(Link)`
    flex: 0 0 31%;
    max-width: 426px;

    &:hover {
        color: unset;
        text-decoration: none;
    }
`;

const BIDashBoardCard = styled(VStack)`
    min-width: 10rem;
    width: 100%;
    height: 100%;
    min-height: 120px;
    background: ${COLOR_VALUES.White};
    padding: 16px 8px 24px 16px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
    &:hover {
        border-color: ${COLOR_VALUES.Gray_4};
    }
`;

const Wrapper = styled(VStack)`
    width:100%;
    border: 1px dashed ${COLOR_VALUES.Gray_5};
    border-radius: 4px;
    padding: ${SPACING_VALUES.Two};
`;

const StyledBlock = styled.div`
    display: inline-block;
`
const StyledLinkText = styled(Text)`
    cursor:pointer;
`

const Card = ({ title, description, to, children, ...rest }) => (
    <StyledLinked to={to} {...rest}>
        <BIDashBoardCard>
            <VStack spacing={SPACING.Zero_25}>
                <HStack
                    align="center"
                    justify="space-between"
                    spacing={SPACING.One}
                >
                    <Text variant="Headline_3" color="Black">
                        {title}
                    </Text>
                    <Icon
                        style={{ flexShrink: 0, margin: "0px 0px 16px 0px" }}
                        width={16}
                        height={16}
                        name="link"
                        color="Black"
                    />
                </HStack>
                <Text variant="Descriptive_1_1" color="Gray_2">
                    {description}
                </Text>
            </VStack>
            {children}
        </BIDashBoardCard>
    </StyledLinked>
);

const BIEmptyDashboardWrapper = () => {
    const [copy, setCopy] = useState("Copy email");

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(env.POWERBI_EMAIL);
            setCopy("Copied to clipboard");
        }
    };
    return (
        <>
            <Wrapper>
                <VStack spacing="One">
                    <HStack spacing="Zero_25">
                        <Icon size={16} name="error" color="Orange_1" />
                        <Text color="Black" variant="Body_2_2">
                            You do not have permission to view any dashboards.
                        </Text>
                    </HStack>
                    <Text variant="Body_2_1" color="Gray_2">
                        If you believe this is an error or if you have any
                        questions, please email the Business Intelligence
                        team
                        <Tooltip text={copy} enterDelay={0}>
                            <StyledBlock>
                                <StyledLinkText
                                    variant="Body_2_1"
                                    color="Black"
                                    onClick={() => {
                                        copyToClipboard();
                                    }}
                                >
                                    &nbsp;{env.POWERBI_EMAIL}
                                </StyledLinkText>
                            </StyledBlock>
                        </Tooltip>
                    </Text>
                </VStack>
            </Wrapper>
        </>
    );
};

const BusinessIntelligencePage = () => {
    const { insightsTrace } = useInsigths();
    const BITitle = "Business Intelligence";
    const BIDescription =
        "Dashboards and reports to help you make informed data-driven decisions";
    const initialReportData = {
        token: "",
        dashboardId: "",
        reportId: "",
        pageName: "",
    };
    const session = useContext(SessionContext);
    const [reportData, setReportData] = useState(initialReportData);
    const [isAuthorize, setAuthorize] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [pbiToken, setPbiToken] = useState(null);
    const [userDashboards, setUserDashboards] = useState([]);
    const [userTabs, setUserTabs] = useState([]);
    const [dashboardsByTab, setDashboardsByTab] = useState([]);
    const [tabSelected, setTabSelected] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [inProgress, setInProgress] = useState();
    const { handleError } = useErrors();
    const now = new Date();
    insightsTrace("VIEW", "BIPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });
    const getBIToken = async () => {
        return getPowerBIToken().then(
            (token) => {
                return token;
            }
        );
    }

    const getBIAPIData = async () => {
        const userDashboards = Object.values(
            await GetPowerBIDashboardsByUserEmail(
                session.email.toLowerCase()
            )
        );
        const userDashboardsIds = userDashboards.map((x) => x.dashboard_id);

        if (userDashboards[0] === "-1") {
            setAuthorize(false);
            handleError({
                status: userDashboards[1],
                statusText: userDashboards[2]
            })
        }       

        const allDashboards = Object.values(
            await GetPowerBIAllDashboards()
        );
        const dashboardsToShow = allDashboards.filter((x) => {
            return userDashboardsIds.find((y) => y === x.dashboard_id);
        });

        dashboardsToShow.sort((a, b) => a.dashboard_name.localeCompare(b.dashboard_name));

        const groupedDashboards = groupBy(
            dashboardsToShow,
            "dashboard_group"
        );
        
        const mapTabs = Object.entries(groupedDashboards).map(
            ([key, value]) => {
                return { label: key, Icon: "", to: "" };
            }
        );

        if (mapTabs.length > 1) {
            mapTabs.push({ label: "All", Icon: "", to: "" });
        }

        const defaultTab = mapTabs[0]?.label;
        setUserTabs(mapTabs);
        setUserDashboards(dashboardsToShow);
        setDashboardsByTab(
            dashboardsToShow?.filter(
                (x) => x.dashboard_group === defaultTab
            )
        );
        setTabSelected(defaultTab);
        setInProgress(false);
    }

    useEffect(() => {
        var rId = searchParams.get("rId");
        var gId = searchParams.get("gId");
        if (rId && gId) {
            sessionStorage.setItem("BIrId", rId);
            sessionStorage.setItem("BIgId", gId);
        }
        setInProgress(true);
        getBIToken().then(
            (pbiToken) => {
                setPbiToken(pbiToken);
                if (pbiToken) {
                    rId = sessionStorage.getItem("BIrId");
                    gId = sessionStorage.getItem("BIgId");
                    if (rId && gId) {
                        setReportData({
                            token: pbiToken,
                            dashboardId: gId,
                            reportId: rId,
                        });
                        setAuthorize(true);
                        setShowReport(true);
                        sessionStorage.removeItem("BIrId");
                        sessionStorage.removeItem("BIgId");
                        setInProgress(false);
                    }
                    else {
                        getBIAPIData();
                    }
                }
            }
        );
    }, []);

    const clickTabAction = (data) => {
        let dashboardsByTab = userDashboards;
        if (data.label !== "All") {
            dashboardsByTab = userDashboards.filter((x) => x.dashboard_group === data.label);
        }
        setDashboardsByTab(dashboardsByTab);
        setTabSelected(data.label);
    };

    const dashboardClicked = (data, event) => {
        if (event.defaultPrevented) return;
        setReportData({
            token: pbiToken,
            dashboardId: data?.dashboard_id,
            reportId: data?.Reports[0]?.report_id,
        });
        setShowReport(true);
    };

    const pageClicked = (event, data, pageId) => {
        setReportData({
            token: pbiToken,
            dashboardId: data?.dashboard_id,
            reportId: data?.Reports[0]?.report_id,
            pageName: pageId,
        });
        event.preventDefault();
        setShowReport(true);
    };

    const showDashboards = () => {
        getBIAPIData().then(() => {
            setAuthorize(true);
            setShowReport(false);
        });
    };

    if (inProgress && isAuthorize) {
        return (
            <LoadingWrapper>
                <Loading text="Loading Page" />
            </LoadingWrapper>
        )
    }

    return !showReport ? (
        <PageWrapper
            isAlreadyAuthorize={isAuthorize}
            description={BIDescription}
            title={BITitle}
        >
            <VStack spacing="One" style={{ paddingTop: "32px" }}>
                <Text variant="Headline_2" color="Gray_1">
                    Dashboards
                </Text>
                {userTabs.length > 0 && (
                    <Tabs
                        clickTab={clickTabAction}
                        selected={tabSelected}
                        tabs={userTabs}
                    />
                )}
                <HStack wrap={true}>
                    {dashboardsByTab.length > 0
                        ? dashboardsByTab.map((dashboard, index) => (
                            <Card
                                key={index}
                                title={dashboard.dashboard_name}
                                description={dashboard.dashboard_description}
                                onClick={(event) =>
                                    dashboardClicked(dashboard, event)
                                }
                            >
                                <HStack spacing={SPACING.Zero_5} wrap={true}>
                                    {dashboard.Reports.map((report) =>
                                        report.Pages.map((page) => (
                                            <QuaternaryButton
                                                label={page.page_name}
                                                trailingIcon="caret-right"
                                                labelColor="Gray_2"
                                                onClick={(event) =>
                                                    pageClicked(
                                                        event,
                                                        dashboard,
                                                        page.page_id
                                                    )
                                                }
                                            />
                                        ))
                                    )}
                                </HStack>
                            </Card>
                        ))
                        : <BIEmptyDashboardWrapper />}                        
                </HStack>
                <VStack spacing="One" style={{ paddingTop: "64px" }}>
                    <HStack wrap={true}>
                        <Text variant="Headline_4" color="Gray_1">
                            {"Want to know more about dashboards?"}
                        </Text>
                    </HStack>
                    <HStack wrap={true}>
                        <TertiaryButton
                            trailingIcon="link"
                            label="Go to guide"
                            to={`https://docs.microsoft.com/en-us/learn/modules/explore-power-bi-service/2-find-content`}
                        />
                    </HStack>
                </VStack>
                <ProvideFeedback
                    title="Is This Helpful?"
                    description="Help us improve by telling what you think about the information available"
                    label={env.POWERBI_EMAIL}
                />
            </VStack>
        </PageWrapper>
    ) : (
        <>
            <VStack
                spacing="One"
                style={{ padding: "60px 0px 10px 64px", width: "100%" }}
            >
                <HStack>
                    <TertiaryButton
                        label="Back to Business intelligence"
                        leadingIcon="caret-left"
                        onClick={showDashboards}
                    />
                </HStack>
            </VStack>
            <PowerBIReport reportData={reportData} />
            <Footer />
        </>
    );
};

export default BusinessIntelligencePage;
