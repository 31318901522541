import React, { useEffect, useState, useContext } from "react";

import VStack from "components/design-system/layout/VStack";
import DropdownMultiSelect from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelect";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Subject from "components/design-system/ui/drawer/Subject";
import { getDropdownMultiSelectOptions } from "components/design-system/forms/DropdownMultiSelectForm";

import { useClientsFilterApi } from "./single/useProjectsLinked";
import useAgencies from "pages/admin/agencies/useAgencies";
import useFilters from "pages/admin/projects/useFilters";

import DrawerContext from "context/Drawer/DrawerContext";

export const apiStatusOptions = [
    {
        id: 0,
        value: 0,
        label: "undefined",
        check: false,
        indeterminate: false,
    },
    {
        id: 1,
        value: 1,
        label: "To be extracted",
        check: false,
        indeterminate: false,
    },
    {
        id: 2,
        value: 2,
        label: "Extracted",
        check: false,
        indeterminate: false,
    },
    { id: 3, value: 3, label: "Success", check: false, indeterminate: false },
    { id: 4, value: 4, label: "Error", check: false, indeterminate: false },
];

export const filterDropdownNames = {
    agency: "ProjStartDate",
    client: "ClientName",
    rateCard: "RateCardTableName",
    leadProducer: "LeadProducerFullName",
    businessUnit: "BizUnitName",
    API: "SAP_API_Status_Id",
};

const FilterDrawer = ({ setFiltersData, initialData }) => {
    const { agencies } = useAgencies();
    const { rateCards, bizUnits, leadProducers } = useFilters();
    const { clientsFilter } = useClientsFilterApi();

    const { setShow } = useContext(DrawerContext);

    const [filtersAll, setFiltersAll] = useState([]);
    const [isClearAllSelectedData, setIsClearAllSelectedData] = useState(false);

    const [bizUnitsDropdownOptions, setBizUnitsDropdownOptions] = useState([]);
    const [agencyDropdownOptions, setAgencyDropdownOptions] = useState([]);
    const [rateCardsDropdownOptions, setRateCardsDropdownOptions] = useState(
        []
    );
    const [clientDropdownOptions, setclientDropdownOptions] = useState([]);
    const [leadProducersDropdownOptions, setLeadProducersDropdownOptions] =
        useState([]);

    const [agencyInitialData, setAgencyInitialData] = useState([]);
    const [leadProducerInitialData, setLeadProducerInitialData] = useState([]);
    const [clientInitialData, setClientInitialData] = useState([]);
    const [rateCardInitialData, setRateCardInitialData] = useState([]);
    const [APIInitialData, setAPIInitialData] = useState([]);
    const [bizUnitInitialData, setBizUnitInitialData] = useState([]);

    useEffect(() => {
        !agencies.isLoading &&
            agencyDropdownOptions?.length === 0 &&
            setAgencyDropdownOptions(
                getDropdownMultiSelectOptions(agencies?.data?.Items, "Id")
            );
    }, [agencies]);

    useEffect(() => {
        !clientsFilter.isLoading &&
            clientDropdownOptions?.length === 0 &&
            setclientDropdownOptions(
                getDropdownMultiSelectOptions(
                    clientsFilter?.data,
                    "ClientId",
                    "Name",
                    "ClientId"
                )
            );
    }, [clientsFilter]);

    useEffect(() => {
        !leadProducers.isLoading &&
            leadProducersDropdownOptions?.length === 0 &&
            setLeadProducersDropdownOptions(
                getDropdownMultiSelectOptions(
                    leadProducers?.data,
                    "id",
                    "fullName",
                    "id"
                )
            );
    }, [leadProducers]);

    useEffect(() => {
        !rateCards.isLoading &&
            rateCardsDropdownOptions?.length === 0 &&
            setRateCardsDropdownOptions(
                getDropdownMultiSelectOptions(
                    rateCards?.data,
                    "RateCardTableId",
                    "RateCardTableName",
                    "RateCardTableId"
                )
            );
    }, [rateCards]);

    useEffect(() => {
        !bizUnits.isLoading &&
            bizUnitsDropdownOptions?.length === 0 &&
            setBizUnitsDropdownOptions(
                getDropdownMultiSelectOptions(
                    bizUnits?.data,
                    "BizUnitID",
                    "BUName",
                    "BizUnitID"
                )
            );
    }, [bizUnits]);

    useEffect(() => {
        if (
            agencyDropdownOptions.length > 0 &&
            agencyInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.agency &&
                    setAgencyInitialData(
                        filterData?.value?.map((agencyData) => {
                            return agencyData;
                        })
                    );
            });
        }
    }, [agencyDropdownOptions]);

    useEffect(() => {
        if (
            clientDropdownOptions.length > 0 &&
            clientInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.client &&
                    setClientInitialData(
                        filterData?.value?.map((clientData) => {
                            return clientData;
                        })
                    );
            });
        }
    }, [clientDropdownOptions]);

    useEffect(() => {
        if (
            leadProducersDropdownOptions.length > 0 &&
            leadProducerInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.leadProducer &&
                    setLeadProducerInitialData(
                        filterData?.value?.map((leadProducerDtaa) => {
                            return leadProducerDtaa;
                        })
                    );
            });
        }
    }, [leadProducersDropdownOptions]);

    useEffect(() => {
        if (
            bizUnitsDropdownOptions.length > 0 &&
            bizUnitInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.businessUnit &&
                    setBizUnitInitialData(
                        filterData?.value?.map((bizUnitsData) => {
                            return bizUnitsData;
                        })
                    );
            });
        }
    }, [bizUnitsDropdownOptions]);

    useEffect(() => {
        initialData?.map((filterData) => {
            filterData?.id === filterDropdownNames.API &&
                setAPIInitialData(
                    filterData?.value?.map((apiData) => {
                        return apiData;
                    })
                );
        });
    }, [apiStatusOptions]);

    useEffect(() => {
        if (
            rateCardsDropdownOptions.length > 0 &&
            rateCardInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.rateCard &&
                    setRateCardInitialData(
                        filterData?.value?.map((rateCardData) => {
                            return rateCardData;
                        })
                    );
            });
        }
    }, [rateCardsDropdownOptions]);

    const handleOnChangeDropdown = (val, type) => {
        setIsClearAllSelectedData(false);
        let filtersNew = [...filtersAll];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== type);
        }
        if (val.length > 0) {
            for (const value in filterDropdownNames) {
                if (type === filterDropdownNames[value]) {
                    filtersNew.push({
                        id: type,
                        value: val,
                    });
                }
            }
        }

        setFiltersAll(filtersNew);
    };

    useEffect(() => {
        let filtersNew = [...filtersAll];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== "ID");
        }

        setFiltersAll(filtersNew);
    }, []);

    useEffect(() => {
        let filtersNew = [...initialData];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== "ID");
        }
        setFiltersAll(filtersNew);
    }, [initialData]);

    const handleClick = () => {
        setFiltersData(filtersAll);
        setShow(false);
    };

    const handleClearAll = () => {
        setFiltersAll([]);
        setIsClearAllSelectedData(true);
    };

    return (
        <>
            <VStack spacing="Two">
                <Subject
                    subjectName="Filter"
                    showPhoto={false}
                    isMoreIcon={false}
                />

                {filtersAll?.length !== 0 && (
                    <VStack align="flex-end" spacing="Zero">
                        <TertiaryButton
                            label="Clear All"
                            leadingIcon="clear"
                            onClick={handleClearAll}
                        />
                    </VStack>
                )}

                <VStack>
                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={agencyInitialData}
                        itemsAreLoading={
                            agencies.isLoading ||
                            agencyDropdownOptions?.length === 0
                        }
                        optionsData={agencyDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.agency
                            )
                        }
                        isAccordion={true}
                        title="Agency"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />
                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={clientInitialData}
                        itemsAreLoading={
                            clientsFilter.isLoading ||
                            clientDropdownOptions?.length === 0
                        }
                        optionsData={clientDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.client
                            )
                        }
                        isAccordion={true}
                        title="Client"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />

                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={rateCardInitialData}
                        itemsAreLoading={
                            rateCards.isLoading ||
                            rateCardsDropdownOptions?.length === 0
                        }
                        optionsData={rateCardsDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.rateCard
                            )
                        }
                        isAccordion={true}
                        title="Rate Card"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />

                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={leadProducerInitialData}
                        itemsAreLoading={leadProducers.isLoading}
                        optionsData={leadProducersDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.leadProducer
                            )
                        }
                        isAccordion={true}
                        title="Lead Producer"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />
                    <DropdownMultiSelect
                        initialData={bizUnitInitialData}
                        itemsAreLoading={
                            bizUnits.isLoading ||
                            bizUnitsDropdownOptions?.length === 0
                        }
                        optionsData={bizUnitsDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.businessUnit
                            )
                        }
                        isAccordion={true}
                        title="Business Unit"
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />
                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={APIInitialData}
                        optionsData={apiStatusOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(val, filterDropdownNames.API)
                        }
                        isAccordion={true}
                        title="API"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />
                </VStack>
                <DrawerActions
                    primaryAction={{
                        label: "Apply",
                        onClick: () => handleClick(),
                    }}
                />
            </VStack>
        </>
    );
};

export default React.memo(FilterDrawer);
