import PropTypes from "prop-types";
import styled from "styled-components";

const Rect = styled.rect`
    stroke: ${(props) => props.color};
`;
const Circle = styled.circle`
    stroke: ${(props) => props.color};
    fill: ${(props) => props.fill};
`;

const Icon = styled.svg`
    fill: none;
    &:hover {
        ${Circle} {
            fill: ${(props) =>
                props.hover && !props.disabled
                    ? props.circleFillHoverColor
                    : props.circleFillColor};
            stroke: ${(props) =>
                props.hover && !props.disabled
                    ? props.circleStrokeHoverColor
                    : props.circleStrokeColor};
        }
        ${Rect} {
            stroke: ${(props) =>
                props.hover && !props.disabled
                    ? props.rectStrokeHoverColor
                    : props.rectStrokeColor};
                    stroke: ${(props) =>
                        props.disabled && global.config.colors.gray4};
        }
    }
    ${Rect} {
                stroke: ${(props) =>
                    props.disabled && global.config.colors.gray4};
                    
    }
    cursor:${(props) => (props.disabled ? "auto" : "pointer")};,
`;

const Today = ({
    size,
    hover,
    disabled,
    rectStrokeColor,
    circleStrokeColor,
    circleFillColor,
    rectStrokeHoverColor,
    circleStrokeHoverColor,
    circleFillHoverColor,
}) => {
    return (
        <Icon
            width={size}
            height={size}
            viewBox={"0 0 18 18"}
            xmlns="http://www.w3.org/2000/svg"
            hover={hover}
            disabled={disabled}
            rectStrokeHoverColor={rectStrokeHoverColor}
            circleStrokeHoverColor={circleStrokeHoverColor}
            circleFillHoverColor={circleFillHoverColor}
        >
            <Circle
                color={circleStrokeColor}
                fill={circleFillColor}
                r="8.415"
                transform="matrix(-1 0 0 1 9 9)"
                strokeWidth="1.17"
            />
            <Rect
                x="9"
                y="5.82719"
                width="4.83"
                height="4.83"
                transform="rotate(45 9 5.82719)"
                color={rectStrokeColor}
                strokeWidth="1.17"
            />
        </Icon>
    );
};

Today.defaultProps = {
    size: "18",
    hover: false,
    rectStrokeColor: global.config.colors.black,
    rectStrokeHoverColor: global.config.colors.black,
    circleStrokeColor: global.config.colors.gray6,
    circleStrokeHoverColor: global.config.colors.gray6,
    circleFillColor: "transparent",
    circleFillHoverColor: global.config.colors.gray7,
    disabled: false,
};

Today.propTypes = {
    size: PropTypes.string,
    hover: PropTypes.bool,
    arrowStrokeColor: PropTypes.string,
    arrowStrokeHoverColor: PropTypes.string,
    circleStrokeColor: PropTypes.string,
    circleStrokeHoverColor: PropTypes.string,
    circleFillColor: PropTypes.string,
    circleFillHoverColor: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Today;
