import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";

import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";

export const GlobalStyles = createGlobalStyle`
    .notification__item {
        width: max-content;
    }
    .rnc__notification {
        width: max-content !important;
        min-width:max-content;
    }
`;

export const StyledSnackbar = styled(HStack)`
    padding: 10px 16px;
    width: max-content;
    height: 44px;
    background: ${COLOR_VALUES.Gray_1};
    border-radius: 2px;
`;

export const StyledIcon = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
`;

const StyledTertiaryButton = styled(TertiaryButton)`
    background: ${COLOR_VALUES.Gray_2};
    div{
        color: ${COLOR_VALUES.White};
    }

    &:not(:disabled) {
      &:hover {
            background: ${COLOR_VALUES.Gray_2};
        }
    }
}
`;

const Snackbar = ({
    message,
    close,
    action,
    actionProps,
    icon,
    feedbackIcon,
    onClose,
}) => {
    const Message = (
        <HStack spacing="Zero_5" align="center">
            {icon && <Icon name={feedbackIcon} color="White" size={18} />}
            <Text variant="Body_2_1" color="White">
                {message}
            </Text>
        </HStack>
    );

    return (
        <>
            <GlobalStyles />
            <StyledSnackbar spacing="One">
                {Message}
                {action && <StyledTertiaryButton {...actionProps} />}
                {close && (
                    <StyledIcon
                        type="button"
                        onClick={onClose}
                        aria-label="Close Snackbar"
                    >
                        <Icon name="close" color="White" size={16} />
                    </StyledIcon>
                )}
            </StyledSnackbar>
        </>
    );
};

Snackbar.defaultProps = {
    action: false,
    close: true,
    icon: false,
};

Snackbar.propTypes = {
    action: PropTypes.bool,
    close: PropTypes.bool,
    icon: PropTypes.bool,
    feedbackIcon: PropTypes.oneOf(ICON_NAMES),
};

export default Snackbar;
