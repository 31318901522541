import React, { useEffect, useState, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import FilterModal from "components/Modal/Allocations/Filter";
import Dropdown from "components/UI/Dropdown";
import Breadcrumb from "components/UI/Breadcrumb";
import Tooltip from "components/UI/Tooltip";
import Filter from "components/Icon/Filter";
import Arrow from "components/Icon/Arrow";
import CustomMenu from "components/UI/CustomMenu";
import Add from "components/Icon/Add";
import Detail from "components/Icon/Detail";
import GenericModal from "components/Modal/Common/GenericModal";
import {
    ControlContainer,
    BlackSpan,
    DateWrapper,
    ArrowWrapper,
    LeftArrow,
    RightArrow,
    StyledIconButton,
    StyledMagnifier,
    ControlRow,
    ResultsLabel,
    CustomGroupWrapper,
    FilterControl,
    LeftWrapper,
    ControlsWrapper,
    ControlsColumn,
    FilterButton,
    RightWrapper,
    SelectedText,
    TodayButton,
    SyncLabelIcos,
    SyncLabelText,
} from "./styles/Allocations-Styles";

//components
import SearchEmployee from "./Grid/Employee/SearchEmployee";
import CustomGroupModal from "components/Modal/Allocations/CustomGroup";
import Confirmation from "components/Modal/Common/Confirmation";
import { groupBy as lodashGroupBy, isEmpty, toArray } from "lodash";
import { MenuItem } from "@szhsin/react-menu";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";

//api
import { useCustomGroups } from "utilities/API/CustomGroups";
import { useAllocations as allocationServiceLayer } from "utilities/API/Allocations";
import { useUser } from "utilities/API/Users";

//hooks
import { useAllocationsFilters } from "hooks/Allocations/useAllocationsFilters";
import { FILTER_DEFAULTS, useAllocationsQueryPermissionTester } from "hooks/Allocations/useAllocationsPermission";

//contexts
import SessionContext from "context/app/SessionContext";
import Context from "context/Allocations/Context";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import EmployeeGridSelectionContext from "context/Allocations/EmployeeGridSelectionContext";

//resources
import List from "resources/List";

//utilities
import Objects from "utilities/Objects";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import GenericSidebarPortal from "components/Modal/Common/GenericSidebarPortal";
import WeekPicker from "components/UI/WeekPicker";
import Today from "components/Icon/Today";
import { addDays, addWeeks, addYears, startOfWeek } from "date-fns";
import isSameDay from "date-fns/isSameDay";
import usePageActionPermission from "hooks/Access/usePageActionPermission";
import QuaternaryButton from "components/design-system/controls/button/QuaternaryButton";
import { CircularProgress } from "@material-ui/core";
import { clearCacheItems } from "configs/Endpoints";
import { addWeeksToDate, getWeeksBetweenDates, getMondayDate, addDaysToDate } from "utilities/Dates";

const MAX_COLS = 12;
const DEFAULT_COLS = 12;

let selection = {
    startDate: null,
    endDate: null,
    groupById: null,
    groupBy: null,
    sortBy: null,
    page: 1,
    total: 0,
    customGroupId: null,
    userId: null,
};

let filters = {};
let summaryData = [];
let queryStringEmployees = [];
let columns = DEFAULT_COLS;
let dateLabelStart;
let dateLabelEnd;

const initDates = (date, dateTo = null) => {
    if (date == null || date < new Date()){
        date = new Date();
    }
    if (date != null && dateTo != null && date > dateTo){
        dateTo = null;
    }
    const currentDate = moment(moment(date).startOf("isoWeek"));
    let currentEndDate = dateTo == null ? moment(addWeeksToDate(addDaysToDate(currentDate.toDate(),  - 1), DEFAULT_COLS)) : moment(dateTo);
    if (dateTo != null){
        const weeks = getWeeksBetweenDates(moment(currentDate).toDate(), moment(dateTo).toDate());
        if (weeks > MAX_COLS){
            columns = MAX_COLS;
            currentEndDate = moment(addWeeksToDate(addDaysToDate(currentDate.toDate(),  - 1), MAX_COLS));
        } else {
            columns = weeks;
        }
    }
    selection.startDate =  currentDate;
    selection.endDate = currentEndDate;
    dateLabelStart = labelDateFormat(currentDate);
    dateLabelEnd = labelDateFormat(currentEndDate);    
}

const labelDateFormat = (date) => {
    return `${date.format("MMM D")}`;
};

initDates(new Date());

let lastSyncDate = new Date();
let lastSyncDateValue = "";

let lastSync = setInterval(function() {
        console.log("Sync - last check", new Date());
        if (lastSyncDateValue != "1H+"){
            const start = moment(lastSyncDate);
            const end = moment(new Date());
            const duration = moment.duration(end.diff(start));
            const hours = duration.asMinutes();
            if (hours > 59.9){
                lastSyncDateValue = "1H+";
                lastSyncDate = end;
                const lbl = document.getElementById("lastSyncLabel");
                if (lbl) {
                    lbl.innerText = "Last Sync";
                }
                const value = document.getElementById("lastSyncLabelValue");
                if (value) {
                    value.innerText = lastSyncDateValue;
                }
                console.log("Sync - 1H+", new Date());
                clearInterval(lastSync);
            }
        }
    }, 600000);

const HeaderPanel = () => {
    const location = useLocation();
    let [searchParams] = useSearchParams(location.search);
    const weekStartOnMonday = { weekStartsOn: 1 };
    const { buildQuery, buildQueryFromParams } = useAllocationsFilters();
    const getQueryPermission = useAllocationsQueryPermissionTester();
    const userCanViewOthers = usePageActionPermission("AC_VIEW_OTHER_EMP_ALLOCATION");
    const { getAllocationsSummary } = allocationServiceLayer();
    const { getCustomGroup, getMyCustomGroups, getAllCustomGroups } = useCustomGroups();
    const { insightsException } = useInsigths();
    const sessionData = useContext(SessionContext);
    const {
        employees,
        setEmployees,
        setGridParams,
        isLoadingMore,
        setIsLoadingMore,
        setColumnsView,
        groupBy,
        setGroupBy,
        sortBy,
        setSortBy,
        setOpenFilters,
        setUserQueryAccess,
        totalAmountOfResults,
        setTotalAmountOfResults,
        setQuery
    } = useContext(Context);
    const customGroupConsumer = useContext(CustomGroupContext);
    const employeeGridSelection = useContext(EmployeeGridSelectionContext);
    const customGroupModalRef = useRef();
    const filterModalRef = useRef();
    const [searchDrawerIsOpen, setSearchDrawerIsOpen] = useState(false);
    const { getUserDetail } = useUser();
    const [selectedFilters, setSelectedFilters] = useState(null);
    const selectedFiltersRef = useRef();
    const [groupBySelected, setGroupBySelected] = useState(null);
    const [sortBySelected, setSortBySelected] = useState(null);

    useEffect(() => {
        selectedFiltersRef.current = selectedFilters;
    }, [selectedFilters]);

    useEffect(() => {
        setGroupBySelected(setGroupByPH());
    }, [groupBy]);

    useEffect(() => {
        setSortBySelected(setSortByPH());
    }, [sortBy]);

    useEffect(() => {
        if (Array.from(searchParams).length > 0 && (searchParams.has("startDate") || searchParams.has("endDate") || searchParams.has("userId") || searchParams.has("customGroupId")) || searchParams.has("filters") || searchParams.has("groupById") || searchParams.has("sortByKey")){            
            if (searchParams.has("startDate") && searchParams.has("endDate")) {
                initDates(new Date(searchParams.get("startDate")), new Date(searchParams.get("endDate")));
            }
            if (searchParams.has("startDate") && !searchParams.has("endDate")) {
                initDates(new Date(searchParams.get("startDate")));
            }
            if (!searchParams.has("startDate") && searchParams.has("endDate")) {
                initDates(new Date(), new Date(searchParams.get("endDate")));
            }
            if (!userCanViewOthers && !searchParams.has("userId")) {
                appendURLparam("userId", sessionData?.legacyId);
            }
            
            const query = handleQueryStrings(searchParams);
            applySelection(query);
            appendURLparam("startDate", selection.startDate);
            appendURLparam("endDate", selection.endDate);
        }

        const handlePopstate = (_event) => window.location.reload();

        window.addEventListener("popstate", handlePopstate);
        customGroupConsumer.setSelectCustomGroupOption(
            () => onCustomGroupApply
        );
        setOpenFilters(() => openFilter);

        setColumnsView(columns);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, []);

    const handleQueryStrings = (searchParam) => {
        const queryStrings = Object.fromEntries([...searchParam]);

        let applied = false;
        if (searchParams.has("userId")) {
            selection.userId = queryStrings.userId.split(",");
            filters.userId = queryStrings.userId.split(",");
            queryStringEmployees = filters.userId.map((userId) => {
                return {
                    userId: userId,
                    fullName: "",
                };
            });
            queryStringEmployees.forEach((user) => {
                getUserDetail([{ name: "$userId", value: user.userId }])
                    .then((userDetail) => {
                        user.fullName = userDetail.FullName
                            ? userDetail.FullName
                            : "";
                    })
                    .catch(() => {
                        /* */
                    });
            });
            applied = true;
        }
        let queryStringFilters = buildQueryFromParams(selection);

        if (searchParams.has("customGroupId")) {
            selection.customGroupId = queryStrings.customGroupId;
            customGroupConsumer.setSelectedCustomGroup({Id: selection.customGroupId, Name: "Loading..."})
            getCustomGroupDetail(selection.customGroupId)
                .then((payload) => {
                    payload?.Id &&
                        customGroupConsumer.setSelectedCustomGroup(payload);
                });
            applied = true;
        }
        if (!applied) selection.agencyId = FILTER_DEFAULTS.agencyId; //if no querystring parameters applied, set agency to default

        if (!isEmpty(queryStringFilters)) {
            if (!queryStringFilters.agencyId) {
                queryStringFilters.agencyId = FILTER_DEFAULTS.agencyId;
            }
            const { fromUrl, ...p } = queryStringFilters;

            if (filterModalRef.current) {
                filterModalRef.current.updateFilterSelection(p);
            }

            setSelectedFilters(queryStringFilters);
        }

        getDate();

        const query = buildQuery(selection, queryStringFilters);
        List.allocations.sortBy.forEach((option) => {
            option.checked = selection.sortBy === option.value;
        });

        List.allocations.groupBy.forEach((option) => {
            option.checked = selection.groupBy === option.label;
        });
        
        return query;
    };

    const resetSummary = () => {
        selection.page = 1;
        selection.total = 0;
        summaryData = [];
        setEmployees([]);
    };

    const renderAfterChangeDate = () => {
        getDate();
        let date = new Date(selection.startDate);
        const currentDate = moment(moment(date).startOf("isoWeek")).toDate();
        selection.startDate = currentDate;
        date = currentDate;
        
        let dateTo = new Date(selection.endDate);
        
        if (date == null || date < new Date()){
            date = moment(moment(new Date()).startOf("isoWeek")).toDate();
            selection.startDate = date;
        }
        if (date != null && dateTo != null && date > dateTo){
            dateTo = null;
        }
        
        let currentEndDate = moment(dateTo);
        if (dateTo == null){
            currentEndDate = moment(addWeeksToDate(new Date(currentDate - 1), DEFAULT_COLS));
            columns = DEFAULT_COLS;
        } else {
            const weeks = getWeeksBetweenDates(currentDate, moment(dateTo).toDate());
            if (weeks > MAX_COLS){
                columns = MAX_COLS;
                currentEndDate = moment(addWeeksToDate(new Date(currentDate - 1), MAX_COLS));
            } else {
                columns = weeks;
            }
        }

        selection.endDate = currentEndDate.toDate();     
        dateLabelEnd = labelDateFormat(currentEndDate);
        appendURLparam("startDate", selection.startDate);
        appendURLparam("endDate", selection.endDate);
        setColumnsView(columns);
        resetSummary();
        const query = buildQuery(selection, selectedFiltersRef.current);
        applySelection(query);
    }

    const onChangeWeekStart = (date) => {
        selection.startDate = date;
        renderAfterChangeDate();
    };

    const onChangeWeekEnd = (date) => {
        selection.endDate = moment(date).add(6, 'd');
        renderAfterChangeDate();
    };

    const onPreviousDate = () => {
        if (disablePreviousDate()) {
            return;
        }
        selection.startDate = moment(selection.startDate).subtract(7, "d");
        selection.endDate = moment(selection.endDate).subtract(7, "d");
        renderAfterChangeDate();
    };

    const onPreviousDatePreview = () => {
        const startDate = moment(selection.startDate).subtract(7, "d");
        const endDate = moment(selection.endDate).subtract(7, "d");
        return `${moment(startDate).format("MMM D")} - ${moment(endDate).format("MMM D, yyyy")}`;
    };

    const onNextDate = () => {
        selection.startDate = moment(selection.startDate).add(7, "d");
        selection.endDate = moment(selection.endDate).add(7, "d");
        renderAfterChangeDate();
    };

    const onNextDatePreview = () => {
        const startDate = moment(selection.startDate).add(7, "d");
        const endDate = moment(selection.endDate).add(7, "d");
        return `${moment(startDate).format("MMM D")} - ${moment(endDate).format("MMM D, yyyy")}`;
    };

    const onToday = () => {
        selection.startDate = moment(moment().startOf("isoWeek"));
        const next = moment(addWeeksToDate(addDaysToDate(selection.startDate.toDate(), -1), columns));
        selection.endDate = next;
        renderAfterChangeDate();
    }

    const getDate = () => {
        selection.startDate = moment(selection.startDate).startOf("isoWeek").format("MM/DD/YY");
        selection.endDate = moment(selection.endDate).format("MM/DD/YY");
        dateLabelStart = `${moment(selection.startDate).format("MMM D")}`; 
        dateLabelEnd = `${moment(selection.endDate).format("MMM D")}`; 
    };

    const onCustomGroupCreate = (_event) => {
        customGroupModalRef.current.add();
    };

    const onCustomGroupApply = (_event, option) => {        
        (async ()=> {
            selection.customGroupId = option ? option.Id : null;
            resetSummary();
            setIsLoadingMore({
                loading: true,
                page: 1,
                message: "Fetching custom group allocations...",
                type: "load"
            });
            if (selection.customGroupId > 0) {
                window.history.replaceState(null, null, location.pathname + "?customGroupId=" + selection.customGroupId);
                getCustomGroupDetail(selection.customGroupId)
                    .then((payload) => {
                        if (payload?.Id) {
                            customGroupConsumer.setSelectedCustomGroup(payload);
                            handleCloseSidebar();
                            filterModalRef.current.reset();
                            resetFilters();   
                            cleanUpResults();             
                        }
                    });
            } else {
                searchParams.delete("customGroupId");
                customGroupConsumer.setSelectedCustomGroup(null);
                handleCloseSidebar();
                filterModalRef.current.reset();
                cleanUpResults();
                //resetFilters();
            }
        })().then(() => {
            setIsLoadingMore({
                loading: false,
                page: 1,
                type: "",
                message: ""
            });
        });
    };

    const getCustomGroupDetail = async function (id) {
        return getCustomGroup([{ name: "$id", value: id }]).catch(() => {
            /* */
        });
    };

    const getCustomGroups = async () => {
        return getMyCustomGroups().catch(() => {
            /* */
        });
    };

    const getAllCustomGroupsItems = async () => {
        return getAllCustomGroups().catch(() => {
            /* */
        });
    };

    const showCustomGroupConfirmation = () => {
        if (customGroupConsumer.selectedCustomGroup) {
            const modalContent = (
                <Confirmation
                    title="Open Filter?"
                    question=""
                    onConfirm={openFilter}
                    buttonLabel="Yes, Open Filters"
                >
                    <div>
                        If you apply a filter selection, you will be leaving your
                        Custom Group{" "}
                        <BlackSpan>
                            {" "}
                            {customGroupConsumer.selectedCustomGroup.Name}
                        </BlackSpan>{" "}<br/>
                        (but if you close the filter without applying it, you can keep current results)
                    </div>
                </Confirmation>
            );
            const modal = (
                <GenericModal content={modalContent} parent="divPageModal" />
            );
            ReactDOM.render(modal, document.getElementById("divPageModal"));
        }
        return customGroupConsumer.selectedCustomGroup != null;
    };

    const resetCustomGroup = () => {
        customGroupConsumer.setSelectedCustomGroup(null);
        selection.customGroupId = null;
        searchParams.delete("customGroupId");
        //resetFilters();
    }

    const openFilter = () => {
        //customGroupConsumer.setSelectedCustomGroup(null);
        //selection.customGroupId = null;
        //window.history.replaceState(null, null, location.pathname);
        //resetFilters();
        handleCloseSidebar();
        filterModalRef.current.showModal();
    };

    const appendURLparam = (name, value) => {
        searchParams.set(name, value);
        let params = Array.from(searchParams);
        let queryString = "";
        if (params.length > 0){
            params.forEach((p) => {
                if (p[0] == name){
                    p[1] = value;
                }
                if (queryString.length == 0){
                    queryString += "?";
                }
                queryString += p[0] + "=" + p[1] + "&";  
            }); 
        }
        window.history.replaceState(null, null, location.pathname + queryString);            
    }

    const onChangeGroupBy = async (value) => {
        setIsLoadingMore({
            loading: true,
            page: isLoadingMore.page,
            message: "Grouping employees...",
            type: "load"
        });
        await new Promise(r => setTimeout(r, 600));
        appendURLparam("groupById", value);
        setGroupBy(value);
    };

    const setGroupByPH = () => {
        if (groupBy == null){
            return "Group By";
        } else 
        {
            const groupByIdIndex = List.allocations.groupBy.findIndex((g) => g.value == groupBy);
            return List.allocations.groupBy[groupByIdIndex].tagLabel;
        }
    }

    const setSortByPH = () => {
        if (sortBy == null){
            return "Sort By";
        } else 
        {
            const sortByIdIndex = List.allocations.sortBy.findIndex((g) => g.value == sortBy);
            return List.allocations.sortBy[sortByIdIndex].tagLabel;
        }
    }

    const onChangeSortBy = async (_currentNode, selectedNodes) => {
        setIsLoadingMore({
            loading: true,
            page: isLoadingMore.page,
            message: "Sorting employees...",
            type: "load"
        });
        await new Promise(r => setTimeout(r, 600));
        handleCloseSidebar();
        selection.sortBy = selectedNodes.length > 0 ? selectedNodes[0].value : "Lastname,asc";
        List.allocations.sortBy.forEach((option) => { option.checked = selection.sortBy === option.value ? true :false;});
        const sortByData = selection.sortBy.split(",");
        appendURLparam("sortByKey", sortByData[0]);
        appendURLparam("sortByOrder", sortByData[1]);
        setSortBy(selection.sortBy);
    };

    const cleanUpResults = () => {
        filters = {};
        setSelectedFilters(undefined);
        resetSummary();
    }

    const resetFilters = () => {
        filters = {};
        setSelectedFilters(undefined);
        resetSummary();
        const query = buildQuery(selection, selectedFiltersRef.current);
        setTimeout(() => { applySelection(query);}, 100);
    };

    const removeUserId = (userId) => {
        const index = filters.userId.indexOf(userId);
        if (index !== -1) {
            filters.userId.splice(index, 1);
            queryStringEmployees = queryStringEmployees.filter(
                (employee) => employee.userId !== userId
            );
        }
        selection.userId = filters.userId?.length > 0 ? filters.userId : null;
        //const query = buildQuery(selection, selectedFiltersRef.current);
        //applySelection(query);
        cleanUpResults();
    };

    const applySelection = async (query) => {
        setQuery(query);
        return getSummary(query);
    };

    const applyFilter = (dataFilters) => {
        console.log("APPLY FILTER");
        if (dataFilters != null){
            resetCustomGroup();
            selection.page = 1;
            setSelectedFilters(dataFilters);
            const query = buildQuery(selection, dataFilters);
            resetSummary();
            getSummary(query);
            selection.page = 1;
        }
    };

    const getSummary = async (query) => {

        const queryPermission = getQueryPermission(query);

        setUserQueryAccess(queryPermission);

        if (!queryPermission.hasAccess) {
            setTotalAmountOfResults(0);
            setEmployees([]);
            setIsLoadingMore({
                loading: false,
                page: 0,
                message: "",
                type: "load"
            });

            return;
        }

        setGridParams(() => {
            return query;
        });

        setIsLoadingMore({
            loading: true,
            page: selection.page,
            message: "Fetching filter allocations...",
            type: "load"
        });

        return getAllocationsSummary(query).then(async (summaryResponse) => 
        {
            setIsLoadingMore({
                loading: true,
                page: selection.page,
                message: "Render employees allocations..."
            });
            await new Promise(r => setTimeout(r, 500));
            if (summaryResponse.totalCount && summaryResponse.items) {
                selection.total = summaryResponse.totalCount;
                setTotalAmountOfResults(summaryResponse.totalCount);

                let merged = summaryResponse.items;
                
                let grouped = selection.groupById ? lodashGroupBy(merged, (item) => item[selection.groupById]) : [merged];
                summaryData = toArray(Object.values(Objects.sortByKey(grouped)));
                
                setEmployees(summaryData);
                setGroupBy(selection.groupById);

                for (const employee of summaryResponse.items) {
                    employeeGridSelection.addToEmployeeNameCache(
                        employee.userId,
                        employee.fullName
                    );
                }
            } 
            else if (selection.page <= 1) {
                setTotalAmountOfResults(0);
                setEmployees([]);
            }
        }).then(()=> {
            if (searchParams.has("groupById")){
                const groupByIdIndex = List.allocations.groupBy.findIndex((g) => g.value == searchParams.get("groupById"));
                if (groupByIdIndex > -1){
                    setGroupBy(List.allocations.groupBy[groupByIdIndex].value);
                }
            }
            if (searchParams.has("sortByKey")){
                const sortByValues = searchParams.get("sortByKey") + "," + searchParams.get("sortByOrder");
                setSortBy(sortByValues);                
            }
        }).catch((error) => {
            insightsException(error);
            setTotalAmountOfResults(0);
            setEmployees([]);
        }).then(() => {
            setIsLoadingMore((oldLoadingInfo) => ({...oldLoadingInfo, loading: false, message: "", type: "" }));
        });    
    };

    const handleCloseSidebar = () => {
        setSearchDrawerIsOpen(false);
    };

    const handleSearchEmployeeClick = () => {
        setSearchDrawerIsOpen(true);
    };

    const disableCurrentWeek = () => {
        const currentStartDate = startOfWeek(moment().toDate(), {
            weekStartsOn: 1,
        });
        const selectionStartDate = moment(
            selection.startDate,
            "MM/DD/YY"
        ).toDate();
        return isSameDay(currentStartDate, selectionStartDate);
    };

    const disablePreviousDate = () => {
        const today = moment(moment().startOf("isoWeek"));
        return moment(selection.startDate) <= today;
    };

    const realoadFilter = () => {
        (async () => {
            clearCacheItems();
        })().then(() => window.location.reload(true));
    }

    const headerPane = 
    <>
        <div id="headerPanelAllocations" style={{pointerEvents: isLoadingMore.loading ? "none" : "", cursor: isLoadingMore.loading ? "not-allowed" : "", opacity: isLoadingMore.loading ? "0.25" : ""}}>
            <CustomGroupModal
                    selected={customGroupConsumer.selectedCustomGroup}
                    ref={customGroupModalRef}
                />
                <ControlRow>
                    <ControlsColumn xs={12}>
                        <ControlsWrapper>
                            <div style={{display: "flex", pointerEvents: employees.length == 0 ? "none" : "", cursor: employees.length == 0 ? "not-allowed" : "", opacity: employees.length == 0 ? "0.25" : ""}}>
                                <ArrowWrapper>
                                    <Tooltip placement="bottom" title={onPreviousDatePreview()}>
                                        <LeftArrow onClick={onPreviousDate}>
                                            <Arrow
                                                size={"18"}
                                                direction="left"
                                                hover
                                                disabled={disablePreviousDate()}
                                            />
                                        </LeftArrow>
                                    </Tooltip>
                                    <Tooltip
                                        placement="bottom"
                                        title={"Return to Current Week"}
                                    >
                                        <TodayButton
                                            onClick={onToday}
                                        >
                                            <Today hover disabled={disableCurrentWeek()} />
                                        </TodayButton>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={onNextDatePreview()}>
                                        <RightArrow onClick={onNextDate}>
                                            <Arrow size={"18"} direction="right" hover />
                                        </RightArrow>
                                    </Tooltip>
                                </ArrowWrapper>
                                <DateWrapper>
                                    <WeekPicker
                                        minDate={startOfWeek(moment().toDate(), weekStartOnMonday)}
                                        maxDate={addWeeksToDate(moment().toDate(), 400)}
                                        onChangeWeek={onChangeWeekStart}
                                        dateLabel={dateLabelStart}
                                        selectedValue={moment(selection.startDate, "MM/DD/YY").toDate()}
                                    />
                                    -
                                    <WeekPicker
                                        minDate={startOfWeek(moment().toDate(), weekStartOnMonday)}
                                        maxDate={addWeeksToDate(moment().toDate(), 400)}
                                        onChangeWeek={onChangeWeekEnd}
                                        dateLabel={dateLabelEnd}
                                        selectedValue={moment(selection.endDate, "MM/DD/YY").toDate()}
                                    />
                                </DateWrapper>
                            </div>
                            <LeftWrapper>
                                {queryStringEmployees.length > 0 ? (
                                    <>
                                        <ActionPermissionGuard code="AC_VIEW_OTHER_EMP_ALLOCATION">
                                            {queryStringEmployees.map(
                                                (user, index) => (
                                                    <Breadcrumb key={index} onClick={() => removeUserId(user.userId)}
                                                    >
                                                        {user.fullName}
                                                    </Breadcrumb>
                                                )
                                            )}
                                        </ActionPermissionGuard>
                                    </>
                                ) : (
                                    <>
                                        <ActionPermissionGuard code="AC_ADD_EDIT_VIEW_CUSTOM_GROUP">
                                            <CustomGroupWrapper>
                                                <CustomMenu
                                                    menuLabel="Custom Groups"
                                                    tabs={[
                                                        "My Groups",
                                                        "All Groups",
                                                    ]}
                                                    itemsLabel="Groups"
                                                    action={onCustomGroupCreate}
                                                    itemAction={onCustomGroupApply}
                                                    moreMenuOptions={[
                                                        <MenuItem
                                                            key={0}
                                                            onClick={(e) => {
                                                                handleCloseSidebar();
                                                                customGroupModalRef.current.detail(
                                                                    e.value.id
                                                                );
                                                            }}
                                                        >
                                                            <Detail size="16" />
                                                            View Details
                                                        </MenuItem>,
                                                    ]}
                                                    getItemsFirstTab={getCustomGroups}
                                                    getItemsSecondTab={getAllCustomGroupsItems}
                                                    value={customGroupConsumer.selectedCustomGroup}
                                                >
                                                    <Add
                                                        color={
                                                            global.config.colors
                                                                .black
                                                        }
                                                    />
                                                    Create Custom Group
                                                </CustomMenu>
                                            </CustomGroupWrapper>
                                        </ActionPermissionGuard>
                                        <ActionPermissionGuard code="AC_VIEW_FILTER">
                                            <FilterControl>
                                                <FilterModal
                                                    onApply={applyFilter}
                                                    title="Filters"
                                                    ref={filterModalRef}
                                                    showCustomGroupConfirmation={showCustomGroupConfirmation}
                                                    headerSelection={selection}
                                                >
                                                    <FilterButton>
                                                        <Filter />
                                                        <span>Filters</span>
                                                    </FilterButton>
                                                </FilterModal>
                                            </FilterControl>
                                        </ActionPermissionGuard>
                                    </>
                                )}
                            </LeftWrapper>
                            <RightWrapper style={{display: "flex", pointerEvents: employees.length == 0 ? "none" : "", cursor: employees.length == 0 ? "not-allowed" : "", opacity: employees.length == 0 ? "0.25" : ""}}>
                                <div>
                                    <SyncLabelText variant="Descriptive_1_1" color="Gray_2" style={{fontWeight: 450}}>
                                        <span id="lastSyncLabel"></span>
                                    </SyncLabelText>
                                    <SyncLabelText variant="Descriptive_1_2" color="Gray_1" style={{fontWeight: 650}}>
                                        <span id="lastSyncLabelValue"></span>
                                    </SyncLabelText>
                                    {
                                        isLoadingMore.loading ? 
                                        <SyncLabelIcos>
                                            <CircularProgress style={{height: "12px", width: "12px"}}/>
                                        </SyncLabelIcos>
                                        : 
                                        <SyncLabelIcos>
                                            <QuaternaryButton  
                                                aria-label="Sync"
                                                trailingIcon="sync"
                                                tooltip="Sync"
                                                onClick={realoadFilter} />
                                        </SyncLabelIcos>
                                    }
                                </div>
                                <ActionPermissionGuard code="AC_VIEW_OTHER_EMP_ALLOCATION">
                                    <Dropdown
                                        data={List.allocations.groupBy}
                                        placeholder={groupBySelected}
                                        mode="simpleSelect"
                                        className="dropdown-custom-nav small disable-search groupbydd"
                                        inlineSearch={true}
                                        onChange={(a) => onChangeGroupBy(a == null ? null : a.value) }
                                    />
                                    <Dropdown
                                        data={List.allocations.sortBy}
                                        placeholder={sortBySelected}
                                        mode="simpleSelect"
                                        className="dropdown-custom-nav small disable-search required sortbydd"
                                        inlineSearch={true}
                                        onChange={onChangeSortBy}
                                    />
                                    <ResultsLabel>
                                        <SelectedText>
                                            {`Results: ${totalAmountOfResults}`}
                                        </SelectedText>
                                    </ResultsLabel>
                                </ActionPermissionGuard>
                            </RightWrapper>
                            <div style={{display: "flex", width: "70px", paddingLeft: "20px"}}>
                                <ActionPermissionGuard code="AC_VIEW_OTHER_EMP_ALLOCATION">
                                    <StyledIconButton 
                                        icon={<StyledMagnifier size="20" viewBox="20" />}
                                        onClick={handleSearchEmployeeClick}
                                    />
                                </ActionPermissionGuard>
                            </div>
                        </ControlsWrapper>
                    </ControlsColumn>
                </ControlRow>
                {searchDrawerIsOpen && (
                    <Context.Provider value={{ employees, setEmployees }}>
                        <CustomGroupContext.Provider value={customGroupConsumer}>
                            <GenericSidebarPortal
                                open={true}
                                content={<SearchEmployee />}
                                onClose={handleCloseSidebar}
                            />
                        </CustomGroupContext.Provider>
                    </Context.Provider>
                )}
        </div>
    </>;

    return (
        headerPane
    );
};

const MemoizedHeaderPanel = React.memo(HeaderPanel);

const HeaderPanelWrapper = (props) => {
    return (
        <ControlContainer fluid style={{position: "fixed", top: "50px"}}>
            <MemoizedHeaderPanel {...props} />
        </ControlContainer>
    );
};

export default HeaderPanelWrapper; 
