import React, { useState, useMemo } from "react";

const WeekNavigatorContext = React.createContext();

export const WeekNavigatorContextProvider = (props) => {
    const [incompleteWeekStartDateList, setIncompleteWeekStartDateList] =
        useState([]);
    const [fromDateToUpdateList, setFromDateToUpdateList] = useState(null);
    const [updateList, setUpdateList] = useState(false);
    const [selectedTimesheetStartDate, setSelectedTimesheetStartDate] =
        useState(null);
    const [pendingTimesheetCalendarDate, setPendingTimesheetCalendarDate] =
        useState(null);
    const [pendingTimesheetFlag, setPendingTimesheetFlag] = useState(false);
    const [isWeekChange, setIsWeekChange] = useState(false);

    const contextValue = useMemo(() => {
        return {
            incompleteWeekStartDateList,
            setIncompleteWeekStartDateList,
            fromDateToUpdateList,
            setFromDateToUpdateList,
            updateList,
            setUpdateList,
            selectedTimesheetStartDate,
            setSelectedTimesheetStartDate,
            pendingTimesheetCalendarDate,
            setPendingTimesheetCalendarDate,
            pendingTimesheetFlag,
            setPendingTimesheetFlag, 
            isWeekChange,
            setIsWeekChange,
        };
    }, [
        incompleteWeekStartDateList,
        setIncompleteWeekStartDateList,
        fromDateToUpdateList,
        setFromDateToUpdateList,
        updateList,
        setUpdateList,
        selectedTimesheetStartDate,
        setSelectedTimesheetStartDate,
        pendingTimesheetCalendarDate,
        setPendingTimesheetCalendarDate,
        pendingTimesheetFlag,
        setPendingTimesheetFlag,
        isWeekChange,
        setIsWeekChange,
    ]);

    return (
        <WeekNavigatorContext.Provider value={contextValue}>
            {props.children}
        </WeekNavigatorContext.Provider>
    );
};

export default WeekNavigatorContext;
