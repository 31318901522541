import React, { useEffect, useMemo } from "react";
import * as signalR from "@microsoft/signalr";
import env from "react-dotenv";

const SignalRContext = React.createContext();
let allocationHubConnectionId = null;

const messagesHub = new signalR.HubConnectionBuilder()
    .withUrl(env.BESTWORK_REACT_SIGNALR_HUB)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Trace)
    .build();

messagesHub.onreconnecting((error) => {
    console.assert(
        messagesHub.state === signalR.HubConnectionState.Reconnecting
    );
    console.info("SignalR - Allocations - Reconnecting...", messagesHub.state);
    console.info("SignalR - Allocations - Current error", error);
});

messagesHub.onreconnected((connectionId) => {
    console.assert(messagesHub.state === signalR.HubConnectionState.Connected);
    console.info(
        "SignalR - Allocations - Reconnected",
        messagesHub.state,
        connectionId
    );
});

messagesHub.onclose((error) => {
    console.assert(
        messagesHub.state === signalR.HubConnectionState.Disconnected
    );
    console.info(
        "SignalR - Allocations - Disconnected",
        messagesHub.state,
        error
    );
});

const startAllocationsHub = async () => {
    try {
        console.info(
            "SignalR - Allocations - Current connection state",
            messagesHub.state
        );
        messagesHub
            .start({ jsonp: true })
            .then(() => {
                console.info(
                    "SignalR - Allocations - Current connection ID",
                    messagesHub.connectionId
                );
                allocationHubConnectionId = messagesHub.connectionId;
                sendTestAllocationHubMessage();
            })
            .catch((e) => {
                console.error("SignalR - Allocations error", e);
            });
    } catch (e) {
        console.error("SignalR - Allocations error", e);
    }
};

const sendTestAllocationHubMessage = async () => {
    console.info("SignalR - Allocations - Test connection");
    if (messagesHub) await messagesHub.send("Test");
};

export const AllocationsSignalRProvider = (props) => {
    useEffect(() => {
        startAllocationsHub();
    }, []);

    const contextValue = useMemo(() => {
        return { messagesHub, allocationHubConnectionId };
    }, [allocationHubConnectionId]);

    return (
        <SignalRContext.Provider value={contextValue}>
            {props.children}
        </SignalRContext.Provider>
    );
};

export const getContextSignalRClientId = () => {
    return allocationHubConnectionId;
};

export default SignalRContext;
