import React from "react";
import PropTypes from "prop-types";
import { Warning } from "../Filter-Styles";
import Alert from "components/Icon/Alert";

const WarningBox = ({ inline, ...props }) => {
    return (
        <Warning inline={inline}>
            <Alert size="16" viewBox="16" /> {props.children}
        </Warning>
    );
};

Warning.propTypes = {};

export default WarningBox;
