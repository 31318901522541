import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAccessGroupApi = () => {
    const { getDataAsync } = useApi();

    const getAccessGroupApiCall = useCallback(
        async (groupId) => {
            const response = await getDataAsync("group", {
                urlParams: [{ name: "$groupId", value: groupId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useAccessGroup = (groupId) =>
            useQuery(["accessGroup", groupId], () =>
                getAccessGroupApiCall(groupId)
            );

        return {
            useAccessGroup,
        };
    }, [getAccessGroupApiCall]);
};

export default useAccessGroupApi;
