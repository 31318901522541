import { useParams, useSearchParams } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAccessGroupPageAccessManagePermissions = () => {
    const { getDataAsync, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { groupId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const pageId = searchParams.get("pageId");

    const getPageAccessPageActionDetailsApiCall = useCallback(
        async () => {
            const response = await getDataAsync(
                "accessGroupAccessPageActionDetails",
                {
                    urlParams: [
                        { name: "$groupId", value: groupId },
                        { name: "$pageId", value: pageId },
                    ],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createAccessGroupPageAccessPageActionApiCall = useCallback(
        ({ code }) => {
            return postData("accessGroupAccessPageActionManage", {
                urlParams: [
                    { name: "$code", value: code },
                    { name: "$groupId", value: groupId },
                    { name: "$pageId", value: pageId },
                ],
            });
        },
        [postData]
    );

    const deleteAccessGroupPageAccessPageActionApiCall = useCallback(
        ({ code }) => {
            return deleteData("accessGroupAccessPageActionManage", {
                urlParams: [
                    { name: "$code", value: code },
                    { name: "$groupId", value: groupId },
                    { name: "$pageId", value: pageId },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "accessGroupsPageAccessPageActionDetails",
                    groupId,
                    pageId,
                ]);
            },
        };
    }, [queryClient]);

    const createAccessGroupPageAccessPageAction = useMutation(
        createAccessGroupPageAccessPageActionApiCall,
        mutationOptions
    );

    const deleteAccessGroupPageAccessPageAction = useMutation(
        deleteAccessGroupPageAccessPageActionApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useAccessGroupPageAccessPageActionDetails = () =>
            useQuery(
                ["accessGroupsPageAccessPageActionDetails", groupId, pageId],
                () => getPageAccessPageActionDetailsApiCall({ groupId, pageId })
            );

        return {
            useAccessGroupPageAccessPageActionDetails,
            createAccessGroupPageAccessPageAction,
            deleteAccessGroupPageAccessPageAction,
        };
    }, [
        getPageAccessPageActionDetailsApiCall,
        createAccessGroupPageAccessPageAction,
        deleteAccessGroupPageAccessPageAction,
    ]);
};

export default useAccessGroupPageAccessManagePermissions;
