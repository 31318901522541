import React, { useMemo, useCallback, useReducer } from "react";

import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

const PAGE_INDEX_UPDATE = "PAGE_INDEX_UPDATE";
const PAGE_SIZE_UPDATE = "PAGE_SIZE_UPDATE";
const TOTAL_ITEMS_UPDATE = "TOTAL_ITEMS_UPDATE";
const SORT_BY_UPDATE = "SORT_BY_UPDATE";

function reducer(currentState, action) {
    switch (action.type) {
        case PAGE_INDEX_UPDATE:
            return {
                ...currentState,
                queryPageIndex: action.payload,
            };
        case PAGE_SIZE_UPDATE:
            return {
                ...currentState,
                queryPageSize: action.payload,
            };
        case TOTAL_ITEMS_UPDATE:
            return {
                ...currentState,
                totalItems: action.payload,
            };
        case SORT_BY_UPDATE:
            return {
                ...currentState,
                querySortBy: action.payload,
            };
        default:
            throw new Error("Invalid Action Type");
    }
}

export const ServerSidePaginationContext = React.createContext({
    queryPageIndexUpdate: () => void 0,
    queryPageSizeUpdate: () => void 0,
    totalItemsUpdate: () => void 0,
    querySortUpdate: () => void 0,
});

const initialState = {
    queryPageIndex: 0,
    queryPageSize: PAGER_INITIAL_PAGE_SIZE,
    totalItems: null,
    querySortBy: "",
};

export const ServerSidePaginationContextProvider = (props) => {
    const [
        { queryPageIndex, queryPageSize, totalItems, querySortBy },
        dispatch,
    ] = useReducer(reducer, initialState);

    const queryPageIndexUpdate = useCallback((updatedPageIndex) => {
        dispatch({
            type: PAGE_INDEX_UPDATE,
            payload: updatedPageIndex,
        });
    }, []);

    const queryPageSizeUpdate = useCallback((updatedPageSize) => {
        dispatch({
            type: PAGE_SIZE_UPDATE,
            payload: updatedPageSize,
        });
    }, []);

    const totalItemsUpdate = useCallback((updatedTotalItems) => {
        dispatch({
            type: TOTAL_ITEMS_UPDATE,
            payload: updatedTotalItems,
        });
    }, []);

    const querySortUpdate = useCallback((updatedSortBy) => {
        dispatch({
            type: SORT_BY_UPDATE,
            payload: updatedSortBy,
        });
    }, []);

    const contextValue = useMemo(
        () => ({
            queryPageIndex,
            queryPageSize,
            totalItems,
            querySortBy,
            queryPageIndexUpdate,
            queryPageSizeUpdate,
            totalItemsUpdate,
            querySortUpdate,
        }),
        [
            queryPageIndex,
            queryPageSize,
            totalItems,
            querySortBy,
            queryPageIndexUpdate,
            queryPageSizeUpdate,
            totalItemsUpdate,
            querySortUpdate,
        ]
    );

    return (
        <ServerSidePaginationContext.Provider value={contextValue}>
            {props.children}
        </ServerSidePaginationContext.Provider>
    );
};
