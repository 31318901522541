import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TableFilters from "components/design-system/tables/TableFilters";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";

import useClientFamilyCorp from "./useClientFamilyCorp";
import useSelector from "gateway/common/selector/Selector";


const columnNamesAccessor = {
    ID: "ClientFamilyId",
    Client: "ClientName",
    Agency: "AgencyName",
    Corporate: "ClientCorpEntityName",
    Family: "ClientFamilyName",
};

const columns = [
    {
        headCellProps: {
            text: "Family Id",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: columnNamesAccessor.ID,
        show: true,
    },
    {
        headCellProps: {
            text: "Family Name",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: columnNamesAccessor.Family,
        show: true,
    },
    {
        headCellProps: {
            text: "Family Active",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "ClientFamilyActive",
        show: true,
    },
    {
        headCellProps: {
            text: "Family Code",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "ClientFamilyCode",
        show: true,
    },
    {
        headCellProps: {
            text: "Corp Id",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "ClientCorpEntityID",
        show: true,
    },
    {
        headCellProps: {
            text: "Corp Name",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: columnNamesAccessor.Corporate,
        show: true,
    },
    {
        headCellProps: {
            text: "Corp Active",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "ClientCorpActive",
        show: true,
    },
    {
        headCellProps: {
            text: "Corp Code",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "ClientCorpCode",
        show: true,
    },
    {
        headCellProps: {
            text: "Client Id",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "ClientId",
        show: true,
    },
    {
        headCellProps: {
            text: "Client Name",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: columnNamesAccessor.Client,
        show: true,
    },
    {
        headCellProps: {
            text: "Client Active",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "ClientActive",
        show: true,
    },
    {
        headCellProps: {
            text: "Client Code",
            width: getCellWidthInPercent(50),
            isNextLine: true,
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "ClientCode",
        show: true,
    },
    {
        headCellProps: {
            text: "Agency",
            width: getCellWidthInPercent(50),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Agency,
    },
];

const DropdownNames = {
    agency: "Agency",
    family: "Family",
    client: "Client",
    corporate: "Corporate",
};

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: columnNamesAccessor.ID,
        unfilteredValue: "active",
        isSegmentedControl: true,
        options: [
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
    {
        name: DropdownNames.agency,
        columnAccessor: columnNamesAccessor.Agency,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.agency,
    },
    {
        name: DropdownNames.family,
        columnAccessor: columnNamesAccessor.Family,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.family,
    },
    {
        name: DropdownNames.client,
        columnAccessor: columnNamesAccessor.Client,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.client,
    },
    {
        name: DropdownNames.corporate,
        columnAccessor: columnNamesAccessor.Corporate,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.corporate,
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});


const ClientFamilyCorpTable = () => {
    const { queryPageIndex, queryPageSize, querySortBy, totalItemsUpdate } =
        useContext(ServerSidePaginationContext);
    const { useClientFamilyCorpList } = useClientFamilyCorp();
    const [filters, setFilters] = useState();
    const [columnsNew, setColumnsNew] = useState(columns);
    const showFilterVal = useRef();
    const [filtersConfigVal, setFiltersConfigVal] = useState(FiltersConfig);

    const agencySelector = useSelector("core-agencies-selector");
    const familySelector = useSelector("projects-clientfamilies-selector");
    const clientSelector = useSelector("projects-clients-selector");
    const corporateSelector = useSelector("projects-corporate-selector");

    const filterActiveVal = filters?.find((f) => f.id === columnNamesAccessor.ID)?.value;

    switch (filterActiveVal) {
        case undefined:
            showFilterVal.current = "";
            break;
        case "active":
            showFilterVal.current = true;
            break;
        case "inactive":
            showFilterVal.current = false;
            break;
        default:
            showFilterVal.current = true;
            break;
    }

    const clientFamilyCorpDataApi = useClientFamilyCorpList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: showFilterVal.current,
        agencyId:
            filters?.find((f) => f.id === columnNamesAccessor.Agency)?.value ??
            "",
        familyId:
            filters?.find((f) => f.id === columnNamesAccessor.Family)?.value ??
            "",
        clientId:
            filters?.find((f) => f.id === columnNamesAccessor.Client)?.value ??
            "",
        corporateId:
            filters?.find((f) => f.id === columnNamesAccessor.Corporate)?.value ??
            "",
    });

    useEffect(() => {
        if (!clientFamilyCorpDataApi.isLoading) {
            totalItemsUpdate(clientFamilyCorpDataApi?.data?.TotalItems);
        }
    }, [clientFamilyCorpDataApi?.data?.TotalItems]);


    const selectorUpdate = async (selector, type) => {
        let newObj = [...filtersConfigVal];
        const index = newObj.findIndex(
            (f) => f.name === type
        );
        newObj[index].options = !selector.isLoading
            ? getDropdownOptions(
                selector?.data,
                "Id",
                "Name",
                "all",
                "All"
            )
            : [];

        !selector.isLoading &&
            newObj[index].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    };

    useEffect(() => {
        selectorUpdate(agencySelector.list, DropdownNames.agency);
    }, [agencySelector.list]);

    useEffect(() => {
        selectorUpdate(clientSelector.list, DropdownNames.client);
    }, [clientSelector.list]);

    useEffect(() => {
        selectorUpdate(corporateSelector.list, DropdownNames.corporate);
    }, [corporateSelector.list]);

    useEffect(() => {
        selectorUpdate(familySelector.list, DropdownNames.family);
    }, [familySelector.list]);


    const handleOnChangeDropdownColumn = () => {
        let newObj = [...columnsNew];
        setColumnsNew(newObj);
    };

    const memoizedTable = useMemo(
        () => (
            <>
                <Table
                    dataIsFetching={clientFamilyCorpDataApi.isFetching}
                    columns={columnsNew}
                    data={clientFamilyCorpDataApi?.data?.Items}
                    getRowProps={getRowProps}
                    paginationFromFE={false}
                    emptyMessageTableTitle={"Client Families"}
                />
            </>
        ),
        [clientFamilyCorpDataApi, columnsNew]
    );

    if (
        clientFamilyCorpDataApi.isLoading ||
        agencySelector.list.isLoading ||
        clientSelector.list.isLoading ||
        corporateSelector.list.isLoading ||
        familySelector.list.isLoading
    ) {
        return <>Loading...</>;
    }

    if (
        clientFamilyCorpDataApi.isError ||
        agencySelector.list.isError ||
        clientSelector.list.isError ||
        corporateSelector.list.isError ||
        familySelector.list.isError
    ) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="center" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            initialFilterData={[
                                { name: "active", value: "active" },
                            ]}
                            filtersConfig={filtersConfigVal}
                            onChange={setFilters}
                            handleOnChangeDropdownColumn={
                                handleOnChangeDropdownColumn
                            }
                        />
                    </HStack>
                </HStack>
                {memoizedTable}
            </VStack>
        </>
    );
};

export default React.memo(ClientFamilyCorpTable);
