import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { SPACING_VALUES } from "components/design-system/config/spacing";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import VStack from "components/design-system/layout/VStack";

const SegmentedWrapper = styled.div`
    display: inline-flex;
    gap: 1px;
    background: ${COLOR_VALUES.Gray_6};
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
    height: fit-content;
    width: fit-content;
    overflow: hidden;
`;

const SegmentedButton = styled.button`
    border: 0;
    margin: 0;
    padding: ${SPACING_VALUES.Zero_5} ${SPACING_VALUES.One};

    &[aria-pressed="false"] {
        background: ${COLOR_VALUES.Gray_9};
        color: ${COLOR_VALUES.Gray_2};
        ${TEXT_VARIANT_STYLES.Body_2_1};

        &:not(:disabled) {
            cursor: pointer;

            &:hover {
                color: ${COLOR_VALUES.Gray_4};
            }

            &:active {
                background: ${COLOR_VALUES.White};
                color: ${COLOR_VALUES.Black};
            }
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

    &[aria-pressed="true"] {
        background: ${COLOR_VALUES.White};
        box-shadow: 0 0 0 1px ${COLOR_VALUES.Gray_5};
        ${TEXT_VARIANT_STYLES.Body_2_2};

        &:not(:disabled) {
            cursor: auto;
            color: ${COLOR_VALUES.Black};
        }

        &:disabled {
            cursor: not-allowed;
            color: ${COLOR_VALUES.Gray_2};
        }
    }
`;

const StyledText = styled(Text)`
    color: ${COLOR_VALUES.Gray_1};
    ${(props) =>
        props.isDisabled &&
        css`
            color: ${COLOR_VALUES.Gray_3};
        `}
`;

const SegmentedControl = ({
    value,
    options = [],
    onChange = () => void 0,
    disabled = false,
    isDisabled = false,
    filterLabel,
    segmentedButtonType = "submit",
    ...rest
}) => {
    return (
        <VStack spacing="Zero_25">
            {filterLabel && (
                <StyledText variant="Headline_5" disabled={isDisabled}>
                    {filterLabel}
                </StyledText>
            )}
            <SegmentedWrapper role="group" {...rest}>
                {options.map(({ value: optionValue, label, icon }) => (
                    <SegmentedButton
                        type={segmentedButtonType}
                        key={optionValue}
                        aria-pressed={value === optionValue}
                        onClick={() => onChange(optionValue)}
                        disabled={value !== optionValue && disabled}
                    >
                        <HStack spacing="Zero_25" align="center">
                            {icon && <Icon name={icon} />}
                            {label}
                        </HStack>
                    </SegmentedButton>
                ))}
            </SegmentedWrapper>
        </VStack>
    );
};

SegmentedControl.defaultProps = {
    filterLabel: "Show",
};

SegmentedControl.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.oneOf(ICON_NAMES),
        })
    ),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    filterLabel: PropTypes.string,
    segmentedButtonType: PropTypes.string,
};

export default SegmentedControl;
