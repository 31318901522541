import { parseDate } from "@internationalized/date";

import DatePickerMain from "components/design-system/controls/datepicker/DatePickerMain";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import { useFormInput } from "components/design-system/forms/utils";

const DatePicker = ({
    defaultDateValue = null,
    name,
    validations,
    label = "",
    description,
    helpText,
    className,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    const internalValue = inputProps.value ? parseDate(inputProps.value) : null;

    const internalOnChange = (dateValue) => {
        inputProps.onChange(dateValue ? dateValue.toLocaleString() : null);
    };

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className }}
            {...wrapperProps}
        >
            <DatePickerMain
                {...rest}
                defaultValue={defaultDateValue}
                label={label}
                feedbackType={inputProps.feedbackType}
                dateOnChange={internalValue}
                onChange={internalOnChange}
            />
        </FormInputWrapper>
    );
};

export default DatePicker;
