import { useParams } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

export const useAccessGroupMembersApiEmployees = () => {
    const { getDataAsync } = useApi();

    const getEmployeesForSearchApiCall = useCallback(async () => {
        const response = await getDataAsync("getEmployeesForSearch");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const employees = useQuery("employeesList", getEmployeesForSearchApiCall);

    return useMemo(() => {
        return {
            employees,
        };
    }, [employees]);
};

export const useAccessGroupMembersApiDepartments = () => {
    const { getDataAsync } = useApi();

    const getDepartmentsApiCall = useCallback(async () => {
        const response = await getDataAsync("departments");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const departments = useQuery("departmentsList", getDepartmentsApiCall);

    return useMemo(() => {
        return {
            departments,
        };
    }, [departments]);
};

const useAccessGroupMembersApi = () => {
    const { getDataAsync, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { id } = useParams();

    const getAccessGroupMemberApiCall = useCallback(
        async (groupId) => {
            const gmresponse = await getDataAsync("groupMembers", {
                urlParams: [{ name: "$groupId", value: groupId }],
            });
            const gms = await gmresponse.status != 204 ? gmresponse.json() : null;

            if (gms != null && gms.length > 0){
                const deptresponse = await getDataAsync("departments");
                const depts = await deptresponse.status != 204 ? gmresponse.json() : null;
    
                if (depts != null && depts.length > 0){
                    gms.map((g)=> {
                        if (g.type == 'U'){
                            g.departmentName = depts.find((d) => d.Id == g.departmentId)?.Name; 
                        }
                    });
                }
            }
            
            return gms;
        },
        [getDataAsync]
    );

    const createAccessGroupMemberApiCall = useCallback(
        ({ groupId, body }) => {
            return postData("groupMembers", {
                urlParams: [{ name: "$groupId", value: groupId }],
                body,
            });
        },
        [postData]
    );

    const deleteAccessGroupMemberApiCall = useCallback(
        ({ groupId, body }) => {
            return deleteData("groupMembers", {
                urlParams: [{ name: "$groupId", value: groupId }],
                body,
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries(["accessGroupsMembers", id]);
            },
        };
    }, [queryClient]);

    const createAccessGroupMember = useMutation(
        createAccessGroupMemberApiCall,
        mutationOptions
    );
    const deleteAccessGroupMember = useMutation(
        deleteAccessGroupMemberApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useAccessGroupMembers = (groupId) =>
            useQuery(["accessGroupsMembers", id], () =>
                getAccessGroupMemberApiCall(groupId)
            );

        return {
            useAccessGroupMembers,
            createAccessGroupMember,
            deleteAccessGroupMember,
        };
    }, [
        getAccessGroupMemberApiCall,
        createAccessGroupMember,
        deleteAccessGroupMember,
    ]);
};

export default useAccessGroupMembersApi;
