import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { useApi } from "utilities/API/Helpers/useApi";

const useClientRateCards = () => {
    const { getDataAsync, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { clientId } = useParams();

    const getClientRateCardsApiCall = useCallback(async () => {
        const response = await getDataAsync("getRateCardsByClientId", {
            urlParams: [{ name: "$clientId", value: clientId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientRateCards = useQuery(
        "clientRateCardsList",
        getClientRateCardsApiCall
    );

    const createClientRateCardApiCall = useCallback(
        ({ clientRateCardId, useAsDefault }) => {
            return postData("addRateCardByClientId", {
                urlParams: [
                    { name: "$clientId", value: clientId },
                    { name: "$rateCardId", value: clientRateCardId },
                    { name: "$useAsDefault", value: useAsDefault },
                ],
            });
        },
        [postData]
    );

    const deleteClientRateCardApiCall = useCallback(
        ({ clientRateCardId }) => {
            return deleteData("deleteRateCardByClientId", {
                urlParams: [
                    { name: "$clientId", value: clientId },
                    { name: "$rateCardId", value: clientRateCardId },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientRateCardsList");
            },
        };
    }, [queryClient]);

    const createClientRateCard = useMutation(
        createClientRateCardApiCall,
        mutationOptions
    );
    const deleteClientRateCard = useMutation(
        deleteClientRateCardApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            clientRateCards,
            createClientRateCard,
            deleteClientRateCard,
        };
    }, [clientRateCards, createClientRateCard, deleteClientRateCard]);
};

export default useClientRateCards;
