import React, { useEffect, useState, useContext } from "react";

import VStack from "components/design-system/layout/VStack";
import DropdownMultiSelect from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelect";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Subject from "components/design-system/ui/drawer/Subject";
import { getDropdownMultiSelectOptions } from "components/design-system/forms/DropdownMultiSelectForm";

import { useClientsFilterApi } from "pages/admin/projects/single/useProjectsLinked";

import DrawerContext from "context/Drawer/DrawerContext";

export const clientStatusOptions = [
    {
        id: 1,
        value: "",
        label: "All",
        check: false,
        indeterminate: false,
        children: [
            {
                parentId: 1,
                id: 2,
                check: false,
                label: "Active",
                value: true,
            },
            {
                parentId: 1,
                id: 3,
                check: false,
                label: "Inactive",
                value: false,
            },
        ],
    },
];

export const apiStatusOptions = [
    {
        id: 1,
        value: "",
        label: "All Status Values",
        check: false,
        indeterminate: false,
        children: [
            {
                parentId: 1,
                id: 2,
                check: false,
                label: "Undefined",
                value: 0,
            },
            {
                parentId: 1,
                id: 3,
                check: false,
                label: "To be extracted",
                value: 1,
            },
            {
                parentId: 1,
                id: 4,
                check: false,
                label: "Extracted",
                value: 2,
            },
            {
                parentId: 1,
                id: 5,
                check: false,
                label: "Success",
                value: 3,
            },
            {
                parentId: 1,
                id: 6,
                check: false,
                label: "Error",
                value: 4,
            },
        ],
    },
];

export const filterDropdownNames = {
    client: "ClientName",
    clientStatus: "ClientStatus",
    API: "SAP_API_Status_Id",
};

const FilterDrawer = ({ setFiltersData, initialData }) => {
    const { clientsFilter } = useClientsFilterApi();

    const { setShow } = useContext(DrawerContext);

    const [filtersAll, setFiltersAll] = useState([]);
    const [isClearAllSelectedData, setIsClearAllSelectedData] = useState(false);

    const [clientDropdownOptions, setclientDropdownOptions] = useState([]);

    const [clientInitialData, setClientInitialData] = useState([]);
    const [clientStatusInitialData, setClientStatusInitialData] = useState([]);
    const [APIInitialData, setAPIInitialData] = useState([]);

    useEffect(() => {
        !clientsFilter.isLoading &&
            clientDropdownOptions?.length === 0 &&
            setclientDropdownOptions(
                getDropdownMultiSelectOptions(
                    clientsFilter?.data,
                    "ClientId",
                    "Name",
                    "ClientId"
                )
            );
    }, [clientsFilter]);

    useEffect(() => {
        if (
            clientDropdownOptions.length > 0 &&
            clientInitialData.length === 0
        ) {
            initialData?.map((filterData) => {
                filterData?.id === filterDropdownNames.client &&
                    setClientInitialData(
                        filterData?.value?.map((clientData) => {
                            return clientData;
                        })
                    );
            });
        }
    }, [clientDropdownOptions]);

    useEffect(() => {
        initialData?.map((filterData) => {
            filterData?.id === filterDropdownNames.clientStatus &&
                setClientStatusInitialData(
                    filterData?.value?.map((status) => {
                        return status;
                    })
                );
        });
    }, [clientStatusOptions]);

    useEffect(() => {
        initialData?.map((filterData) => {
            filterData?.id === filterDropdownNames.API &&
                setAPIInitialData(
                    filterData?.value?.map((apiData) => {
                        return apiData;
                    })
                );
        });
    }, [apiStatusOptions]);

    const handleOnChangeDropdown = (val, type) => {
        setIsClearAllSelectedData(false);
        let filtersNew = [...filtersAll];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== type);
        }
        if (val.length > 0) {
            for (const value in filterDropdownNames) {
                if (type === filterDropdownNames[value]) {
                    filtersNew.push({
                        id: type,
                        value: val,
                    });
                }
            }
        }

        setFiltersAll(filtersNew);
    };

    useEffect(() => {
        let filtersNew = [...filtersAll];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== "ID");
        }

        setFiltersAll(filtersNew);
    }, []);

    useEffect(() => {
        let filtersNew = [...initialData];

        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== "ID");
        }
        setFiltersAll(filtersNew);
    }, [initialData]);

    const handleClick = () => {
        setFiltersData(filtersAll);
        setShow(false);
    };

    const handleClearAll = () => {
        setFiltersAll([]);
        setIsClearAllSelectedData(true);
    };

    return (
        <>
            <VStack spacing="Two">
                <Subject
                    subjectName="Filter"
                    showPhoto={false}
                    isMoreIcon={false}
                />

                {filtersAll?.length !== 0 && (
                    <VStack align="flex-end" spacing="Zero">
                        <TertiaryButton
                            label="Clear All"
                            leadingIcon="clear"
                            onClick={handleClearAll}
                        />
                    </VStack>
                )}

                <VStack>
                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={clientInitialData}
                        itemsAreLoading={
                            clientsFilter.isLoading ||
                            clientDropdownOptions?.length === 0
                        }
                        optionsData={clientDropdownOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.client
                            )
                        }
                        isAccordion={true}
                        title="Client"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />

                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={clientStatusInitialData}
                        optionsData={clientStatusOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(
                                val,
                                filterDropdownNames.clientStatus
                            )
                        }
                        isAccordion={true}
                        title="Client Status"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />

                    <DropdownMultiSelect
                        isClearAllSelectedValue={
                            isClearAllSelectedData || !filtersAll?.length
                        }
                        initialData={APIInitialData}
                        optionsData={apiStatusOptions}
                        onChangeDropdown={(val) =>
                            handleOnChangeDropdown(val, filterDropdownNames.API)
                        }
                        isAccordion={true}
                        title="Extraction Status"
                        isSmallSize={true}
                        showCountWithLabel={false}
                    />
                </VStack>
                <DrawerActions
                    primaryAction={{
                        label: "Apply",
                        onClick: () => handleClick(),
                    }}
                />
            </VStack>
        </>
    );
};

export default React.memo(FilterDrawer);
