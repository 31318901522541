import env from "react-dotenv";

export const getURLInsideVPN = (url) => {
    return (
        "/redirectto?url=" +
        url +
        "&env=" +
        env.ENVIRONMENT_NAME +
        "&vpn=true"
    );
};

export const getReporterUrl = (url) => {
    return getURLInsideVPN(env.BESTWORK_REACT_REPORTER10_LINK + url);
};