import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";
import EmployeeRow from "./Row";
import Context from "context/Allocations/Context";
import styled from "styled-components";
import Text from "components/UI/Text";
import Button from "components/UI/Button";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";
import CustomGroupContext from "context/Allocations/CustomGroupContext";

const Message = styled.div``;
const Actions = styled.div`
    margin-top: 1em;
`;

const MessageWrapper = styled.div`
    text-align: center;
    padding: 6em 0;
    display: grid;
    justify-content: center;
    align-content: center;
    ${Message} {
    }
    ${Actions} {
    }
`;

const MemoizedEmployeeRow = React.memo(EmployeeRow);

const Grid = ({
    selection,
    allSelected,
    onEmployeeSelection,
    onEnableRowsSelection,
    onSelectAll,
    rowsLength,
    selectedLength,
    reset,
    onResize,
    _id,
}) => {
    let summaryError = null;

    const {
        employees,
        setEmployees,
        gridParams,
        columnsView,
        groupBy,
        sortBy,
        isLoadingMore,
        setIsLoadingMore,
        openFilters,
    } = useContext(Context);

    const { selectedCustomGroup } = useContext(CustomGroupContext);

    const [emps, setEmps] = useState(employees);

    const gridMessage = (title, message, actions) => {
        return (
            <MessageWrapper>
                {title && (
                    <Message>
                        <Text variant="largeBold">{title}</Text>
                        <Text variant="smallLight">{message}</Text>
                    </Message>
                )}
                {actions && <Actions>{actions.map((item) => item)}</Actions>}
            </MessageWrapper>
        );
    };

    const employeesChanged = function (currentList, newList) {
        if (currentList.length !== newList.length) return true;
        for (let i = 0; i < currentList.length; i++) {
            if (currentList[i] !== newList[i]) return true;
        }
        return false;
    };

    let currentEmployeesList = [];

    const updateLastEmployees = (last) => {
        if (employeesChanged(currentEmployeesList, last) || (last.length === 0 && !isLoadingMore.loading)) {
            currentEmployeesList = last;
            return true;
        }
        return false;
    };

    //Render conditions
    const renderNoResults = (
        <ActionPermissionGuard
            code="AC_VIEW_FILTER"
            fallback={gridMessage("No Employees Found")}
        >
            {gridMessage(
                "Allocations",
                <label style={{ width: "290px" }}>
                    To start working with and viewing employee allocations, please apply a filter or load a saved one from
                    the filters panel, select a custom group or select and employee in the search tool on the right side of the header bar.
                </label>
            )}
            <div
                style={{
                    textAlign: "center",
                    padding: "0",
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <Button
                    style={{
                        width: "11rem",
                        height: "2.8rem",
                        alignSelf: "center",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                        fontWeigth: "bold",
                    }}
                    onClick={openFilters}
                >
                    Open Filters
                </Button>
            </div>
        </ActionPermissionGuard>
    );

    const renderErrorResults = gridMessage(
        "Oops! something went wrong fetching the data",
        "Data is not accessible, please try again later"
    );

    const renderEmployees = emps.map((item, index) => 
        {
        return (
        <EmployeeRow
            index={index}
            key={index}
            onResize={onResize}
            employees={item}
            columnsView={columnsView}
            groupBy={groupBy}
            params={gridParams}
            selection={selection}
            allSelected={allSelected}
            onEmployeeSelection={onEmployeeSelection}
        />)}
    );

    let renderEmployeesResult = null;
    if (emps && emps[0] && emps[0].length > 0 && emps[0].length <= 250){
        renderEmployeesResult = renderEmployees;
    } else if(emps && emps[0] && emps[0].length > 250){
        renderEmployeesResult = gridMessage(
            "You are trying to fetch more data than allowed",
            "Please, apply a new filter"
        );
    } else {
        if (selectedCustomGroup && selectedCustomGroup.Id && selectedCustomGroup.Id > 0){
            renderEmployeesResult = gridMessage(
                "The selected custom group is empty",
                "Please, apply a new filter and then select employees to add to the custom group"
            );  
        } else {
            renderEmployeesResult = renderNoResults;
        }
    }

    const renderResult = !summaryError ? renderEmployeesResult : renderErrorResults;

    const totalCapacity = (weeks) => {
        let total = 0;
        weeks.forEach((w) => {
            total += w.allocatedHours;
        });
        return total;
    } 

    const onlySortByEmployees = (selectedValue) => {
        (async () => {
            const values = selectedValue.split(',');
            const field =  values[0];
            const order = values[1];
            let collection = [...employees];
            if (collection && collection[0]){
                let sorted = null;
                switch(field){
                    case "TBD":
                        if (collection[0].findIndex((t) => t["lastName"] == "TBD") > -1){
                            sorted = order == "desc" ? collection[0].sort((a, b) => a["lastName"] == "TBD"  ? 1 : -1) : collection[0].sort((a, b) => a["lastName"] == "TBD"  ? -1 : 1);
                        } else {
                            sorted = collection[0];
                        }
                        break;
                    case "Capacity":
                        sorted = order == "desc" ? collection[0].sort((a, b) => totalCapacity(a.weeks) > totalCapacity(b.weeks) ? 1 : -1) : collection[0].sort((a, b) => totalCapacity(a.weeks) < totalCapacity(b.weeks) ? 1 : -1);
                        break;
                    default:
                        sorted = order == "desc" ? collection[0].sort((a, b) => a[field] > b[field] ? 1 : -1) : collection[0].sort((a, b) => a[field] < b[field] ? 1 : -1);
                        break;
                } 
                collection[0] = sorted;
                setEmployees(collection);
            }
        })().then(() => {
            setIsLoadingMore({
                loading: false,
                page: isLoadingMore.page,
                message: "",
                type: ""
            });
        });
    }

    const onlyGroupByEmployees = (selectedValue) => {
        (async () => {
            let collection = [...employees];
            if (collection && collection[0]){
                const sorted = collection[0].sort((a, b) => a[selectedValue] > b[selectedValue] ? 1 : -1);
                collection[0] = sorted;
                setEmployees(collection);
            }
        })().then(() => {
            setIsLoadingMore({
                loading: false,
                page: isLoadingMore.page,
                message: "",
                type: ""
            });
        });
    }

    const groupAndSortEmployees = (sortValue, groupValue) => {
        (async () => {
            const values = sortValue.split(',');
            const field =  values[0];
            const order = values[1];
            let collection = [...employees];
            if (collection && collection[0]){
                let sorted = null;
                switch(field){
                    case "TBD":
                        if (collection[0].findIndex((t) => t["lastName"] == "TBD") > -1){
                            sorted = 
                            order == "desc" ? 
                            collection[0].sort((a, b) => 
                                {
                                    if (a[groupValue] === b[groupValue]){
                                        return a["lastName"] == "TBD"  ? 1 : -1;
                                    } else {
                                        return a[groupValue] > b[groupValue] ? 1 : -1;
                                    }
                                }
                            ) : 
                            collection[0].sort((a, b) => 
                                {
                                    if (a[groupValue] === b[groupValue]){
                                        return a["lastName"] == "TBD"  ? -1 : 1;
                                    } else {
                                        return a[groupValue] > b[groupValue] ? 1 : -1;
                                    }
                                }
                            );
                        } else {
                            sorted = collection[0];
                        }
                        break;
                    case "Capacity":
                        sorted = 
                        order == "desc" ? 
                        collection[0].sort((a, b) => 
                            {
                                if (a[groupValue] === b[groupValue]){
                                    return totalCapacity(a.weeks) > totalCapacity(b.weeks) ? 1 : -1
                                } else {
                                    return a[groupValue] > b[groupValue] ? 1 : -1;
                                }
                            }
                        ) : 
                        collection[0].sort((a, b) => 
                            {
                                if (a[groupValue] === b[groupValue]){
                                    return totalCapacity(a.weeks) < totalCapacity(b.weeks) ? 1 : -1
                                } else {
                                    return a[groupValue] > b[groupValue] ? 1 : -1;
                                }
                            }
                        );
                        break;
                    default:
                        sorted = 
                        order == "desc" ? 
                        collection[0].sort((a, b) => 
                            {
                                if (a[groupValue] === b[groupValue]){
                                    return a[field] > b[field] ? 1 : -1;    
                                } else {
                                    return a[groupValue] > b[groupValue] ? 1 : -1;
                                }
                            }
                        ) : 
                        collection[0].sort((a, b) => 
                            {
                                if (a[groupValue] === b[groupValue]){
                                    return a[field] < b[field] ? 1 : -1;    
                                } else {
                                    return a[groupValue] > b[groupValue] ? 1 : -1;
                                }
                            }
                        );
                        break;
                } 
                collection[0] = sorted;
                setEmployees(collection);
            }
        })().then(() => {
            setIsLoadingMore({
                loading: false,
                page: isLoadingMore.page,
                message: "",
                type: ""
            });
        });
    }

    const groupAndSort = () => {
        if (groupBy != null && sortBy != null){
            groupAndSortEmployees(sortBy, groupBy);
        } else if (groupBy != null){
            onlyGroupByEmployees(groupBy);
        } else if (sortBy != null){
            onlySortByEmployees(sortBy);
        }
    }

    useEffect(()=> {
        if (employees && employees.length > 0)
        {
            groupAndSort();
        }
    }, [groupBy])

    useEffect(()=> {
        if (employees && employees.length > 0)
        {
            groupAndSort();
        }
    }, [sortBy])

    useEffect(()=> {
        setEmps(employees);
    }, [employees])

return (
        <>
            <Context.Consumer>
                {(_context) => (
                    <>
                        {(!isLoadingMore.loading || (isLoadingMore.loading && isLoadingMore.type == "save")) && emps ? (
                            <Container fluid style={{ paddingTop: "100px", paddingLeft: "0px", paddingRight: "0px" }}>
                                <div id="allocationsColumnsHeader" style={{pointerEvents: emps.length == 0 ? "none" : "", cursor: emps.length == 0 ? "not-allowed" : "", opacity: emps.length == 0 ? "0.25" : ""}}>
                                    <Header
                                        onEnableRowsSelection={(enable) => onEnableRowsSelection(enable)}
                                        onSelectAll={(event) => onSelectAll(event)}
                                        {
                                            ...
                                            {
                                                rowsLength: rowsLength,
                                                selectedLength: selectedLength,
                                                reset: reset,
                                            }
                                        }
                                    />
                                </div>
                                <div style={{paddingTop: "50px", minHeight:"calc(200vh - 150px)"}}>{renderResult}</div>
                            </Container>
                        ) : null
                        }
                    </>
                )}
            </Context.Consumer>
        </>
    );
};

Grid.propTypes = {};

export default Grid;
