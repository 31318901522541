import Modal from "react-bootstrap/Modal";
import Text from "../UI/Text";
import styled from "styled-components";
import { useStylesForSessionExpiredDialog } from "../../styles/MakeStyles";
import { Container } from "react-bootstrap";
import {
    BodyWrapper,
    ButtonWrapper,
    LineWrapper,
    Title,
} from "./styles/SessionExpiredModal-Styles";
import { useEffect, useState } from "react";
import Button from "components/UI/Button";
import { useStylesForButton } from "styles/MakeStyles";
import Alert from "components/Icon/Alert";
import { useNavigate } from "react-router-dom";
const IconWrapper = styled.div`
    position: absolute;
    padding: 1.5rem 0 0 3.5rem;
`;

const SessionExpiredModal = (props) => {
    const classes = useStylesForSessionExpiredDialog();
    const buttonClasses = useStylesForButton();
    const navigate = useNavigate();
    useEffect(async () => {
        /* */
    });

    const fontVariant = "small";
    const titleStyle = {
        color: global.config.colors.gray3,
    };

    const handleClose = () => {
        let url = new URL(window.location);
        let redirectTo = url.searchParams.get("redirectTo");
        const newLocation = JSON.parse(decodeURIComponent(redirectTo));

        navigate(newLocation, {
            replace: true,
            state: newLocation.state,
        });
    };

    return (
        <Modal
            onHide={handleClose}
            backdrop={global.config.modal.static}
            keyboard={global.config.modal.keyboard}
            centered
            size="m"
            show={props.show}
            className={classes.root}
            animation={false}
        >
            <Modal.Header>
                <IconWrapper>
                    <Alert size="25" viewBox="18" />
                </IconWrapper>
                <Modal.Title>
                    <Title>
                        <Text variant="xlargeBold">You’ve Been Logged Out</Text>
                    </Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <BodyWrapper>
                        <LineWrapper>
                            <Text variant={fontVariant} style={titleStyle}>
                                You have been logged out because your session
                                has expired.
                            </Text>
                        </LineWrapper>
                        <LineWrapper>
                            <Text variant={fontVariant} style={titleStyle}>
                                Please login to view this page.
                            </Text>
                        </LineWrapper>
                    </BodyWrapper>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <ButtonWrapper>
                    <Button
                        className={[
                            buttonClasses.button,
                            buttonClasses.primary,
                            buttonClasses.noMargin,
                        ]}
                        onClick={() => handleClose()}
                    >
                        Login
                    </Button>
                </ButtonWrapper>
            </Modal.Footer>
        </Modal>
    );
};

export default SessionExpiredModal;
