import { useEffect, useState } from "react";
import { format } from "date-fns";

import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import DashboardDataItem from "components/design-system/ui/dashboard/DashboardDataItem";

import { useBizUnits } from "pages/admin/retainers/useRetainers";
import useRetainersDashboardData from "pages/projects/retainers-dashboard/single/useRetainersDashboardData";
import { useClientsApi } from "pages/admin/clients/useClients";
import { useRateCard } from "pages/projects/retainers-dashboard/single/useRetainersDashboardUserDetails";

const RetainersDashboardDetailsData = ({ retainerData }) => {
    const { bizUnits } = useBizUnits();
    const [bizUnitName, setBizUnitName] = useState();
    const currencyDL = useRetainersDashboardData("currency");
    const retainerInfo = retainerData?.data;
    const { useClientById } = useClientsApi();
    const clientData = useClientById(retainerInfo?.ClientID);
    const { useRateCardById } = useRateCard();
    const rateCardData = useRateCardById(retainerInfo?.RateCardTableID);

    useEffect(() => {
        if (!bizUnits.isLoading) {
            const BUName = bizUnits?.data?.filter((f) => {
                return f?.BizUnitID === retainerInfo?.BizUnitID;
            });

            setBizUnitName(BUName[0]?.BUName);
        }
    }, [bizUnits]);

    let dataObj = [
        {
            borderBottom: false,
            data: [
                {
                    title: "Client",
                    placeholder: "-",
                    res: clientData?.data?.Name,
                },
                {
                    title: "Currency",
                    placeholder: "-",
                    res: currencyDL?.list?.data?.Name,
                },
                {
                    title: "Retainer Start Date",
                    placeholder: "-",
                    res: format(
                        new Date(retainerInfo?.RetStartDate),
                        "MMM dd, yyyy"
                    ),
                },
                {
                    title: "SOW Signed",
                    placeholder: "-",
                    res: retainerInfo?.SOWSigned ? "Yes" : "No",
                },
            ],
        },
        {
            borderBottom: false,
            data: [
                {
                    title: "Lead Business Unit",
                    placeholder: "-",
                    res: bizUnitName,
                },
                {
                    title: "Rate Card",
                    placeholder: "-",
                    res: rateCardData?.data?.RateCardTableName,
                },
                {
                    title: "Retainer End Date",
                    placeholder: "-",
                    res: format(
                        new Date(retainerInfo?.RetEndDate),
                        "MMM dd, yyyy"
                    ),
                },
            ],
        },
    ];

    if (
        bizUnits.isLoading ||
        currencyDL.list.isLoading ||
        clientData.isLoading ||
        rateCardData.isLoading
    ) {
        return <>Loading...</>;
    }

    if (
        bizUnits.isError ||
        currencyDL.list.isError ||
        clientData.isError ||
        rateCardData.isError
    ) {
        return <>Error</>;
    }

    return (
        <>
            <VStack spacing="Two">
                {dataObj.map((row, rowIndex) => (
                    <HStack
                        key={`retainers-data-layout-${rowIndex + 1}`}
                        borderBottom={row.borderBottom}
                        spacing="Two"
                    >
                        {row.data.map((item, itemIndex) => {
                            return (
                                <DashboardDataItem
                                    key={`retainers-data-item-${itemIndex + 1}`}
                                    title={item.title}
                                    placeholder={item.placeholder}
                                    info={item.res}
                                    isLoading={retainerData?.isFetching}
                                />
                            );
                        })}
                    </HStack>
                ))}
            </VStack>
        </>
    );
};

export default RetainersDashboardDetailsData;
