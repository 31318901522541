import { useApi } from "utilities/API/Helpers/useApi";
import { useCallback, useMemo } from "react";

const usePermissionsByEmployeeApi = () => {
    const { getDataAsync } = useApi();
    const getPermissionsByEmployee = useCallback(
        async (employeeId, departmentId) => {
            const response = await getDataAsync("getPermissionsByEmployee", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$departmentId", value: departmentId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        }
        ,
        [getDataAsync]);
    const getAccessesByEmployee = useCallback(
        async (employeeId, departmentId) => {
            const response = await getDataAsync("getAccessesByEmployee", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$departmentId", value: departmentId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        }
        ,
        [getDataAsync]);

    return useMemo(() => {
         return {
            getPermissionsByEmployee,
            getAccessesByEmployee
        };
    }, [getPermissionsByEmployee, getAccessesByEmployee]);
};

export default usePermissionsByEmployeeApi;
