import React from "react";
import { useWatch } from "react-hook-form";
import styled from "styled-components";

import Dropdown from "components/design-system/forms/Dropdown";
import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";
import { COLOR_VALUES } from "components/design-system/config/colors";
import TextAutocomplete from "components/design-system/forms/TextAutocomplete";

import { useAccessGroupMembersApiEmployees } from "pages/admin/access-groups/single/useAccessGroupMembers";
import Photo from "components/design-system/ui/Photo";

const StyledImg = styled(Photo)`
    width: 44px;
    height: 44px;
    object-fit: cover;
`;

const EmployeeCard = styled.div`
    padding: 16px;
    border: 1px solid ${COLOR_VALUES.Gray_7};
    border-radius: 4px;
`;

const SelectedEmployeeCard = () => {
    const { employees } = useAccessGroupMembersApiEmployees();
    const employeeId = useWatch({ name: "employee" });

    if (employeeId && employees.isLoading) {
        return <div>Loading...</div>;
    }

    if (employeeId && employees.isError) {
        return <div>Error</div>;
    }

    const selectedEmployee = employees.data?.find(
        (employee) => employee.NewId === employeeId
    );

    return selectedEmployee ? (
        <VStack spacing="Zero_5">
            <Text variant="Headline_5" color="Gray_1">
                Selection
            </Text>
            <EmployeeCard>
                <HStack align="center" spacing="Zero_75">
                    <StyledImg
                        alt={selectedEmployee.FullName}
                        url={`https://reporter.ny.rga.com/${selectedEmployee.PhotoURL}`}
                    />
                    <div>
                        <Text variant="Headline_5" color="Black">
                            {selectedEmployee.FullName}
                        </Text>
                        <Text variant="Descriptive_1_1" color="Gray_2">
                            {selectedEmployee.Title}
                        </Text>
                        <Text variant="Descriptive_1_1" color="Gray_3">
                            {selectedEmployee.Office}
                        </Text>
                    </div>
                </HStack>
            </EmployeeCard>
        </VStack>
    ) : (
        <></>
    );
};

const AccessGroupMemberFormShowDropdown = ({
    employeesDataLoading,
    departmentsDataLoading,
    employeesDropdownOptions,
    departmentsDropdownOptions,
    existingEmployeesList = [],
    existingDepartmentsList = [],
}) => {
    const selectedRadioButton = useWatch({
        name: "memberTypeName",
    });

    switch (selectedRadioButton) {
        case "Employee":
            return (
                <>
                    <TextAutocomplete
                        name="employee"
                        label="Select an Employee"
                        validations={{
                            required: true,
                        }}
                        placeholder="Search by Name"
                        items={employeesDropdownOptions}
                        itemsAreLoading={employeesDataLoading}
                        showClearIcon={true}
                        clearDataOnUnmount={true}
                        disabledOptionsList={existingEmployeesList}
                        disabledOptionTooltipText="Already in this Access Group"
                        position="relative"
                    />
                    <SelectedEmployeeCard />
                </>
            );
        case "Department":
            return (
                <>
                    <Dropdown
                        name="department"
                        label="Select a Department"
                        validations={{
                            required: true,
                        }}
                        placeholder="Departments"
                        options={departmentsDropdownOptions}
                        clearDataOnUnmount={true}
                        itemsAreLoading={departmentsDataLoading}
                        disabledOptionsList={existingDepartmentsList}
                        disabledOptionTooltipText="Already in this Access Group"
                    />
                </>
            );

        default:
            return <></>;
    }
};

export default AccessGroupMemberFormShowDropdown;
