import React from "react";
import styled from "styled-components";
import { Row as BootstrapRow, Col } from "react-bootstrap";
import Employee from "./Employee/Employee";
import ToggleButton from "components/UI/ToggleButton";
import DownArrow from "components/Icon/DownArrow";
import Loader from "components/UI/Loader";
import AngleCirculeRight from "components/Icon/AngleCirculeRight";

const MemoizedEmployee = React.memo(Employee);

const GroupLabel = styled.label`
    margin: auto 0.5rem;
    font-size: ${global.config.fonts.sizes.xsmall};
    font-family: ${global.config.fonts.families.medium};
`;

const Wrapper = styled(BootstrapRow)`
    border-bottom: ${global.config.border.small};
    background: ${(props) =>
        props.selected ? global.config.colors.gray9 : "transparent"};
    margin: 0;

    &:hover {
        background: ${(props) => !props.selected && global.config.colors.gray9};
    }
    .rightArrow {
        path,
        circle {
            stroke: ${global.config.colors.gray3};
        }
    }
`;

const Column = styled(Col)`
    border-right: ${global.config.border.small};
    padding: 0;
    &:last-child {
        border-right: none;
    }

    &:hover {
        #moreButton {
            margin-left: 0px;
            display: flex;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: "center";
            justify-content: "center";
        }
    }
`;

const WrapperGroup = styled.div`
    display: flex;
    padding: 0.3rem 0.5rem 0.3rem 0;
`;

const WrapperAction = styled.div`
    display: flex;
    min-width: 1.25rem;
    width: 1.25rem;
    max-width: 1.25rem;
`;

const Row = ({ employees, groupBy, params }) => {
    const handleToggle = async (groupId) => {
        const key = "divGroup_" + groupId;
        const selector = "[id^='" + key + "']";
        let groupDiv = document.querySelectorAll(selector);
        if (groupDiv) {
            groupDiv.forEach((div) => {
                if (div.style.display == ""){
                    div.style.display = "none";
                } else {
                    div.style.display = "";
                }
            });
        }
    };

    let groupByIdField = groupBy + "Id";

    return (
        <>
            {
                employees.map((employee, i, employees) => 
                {
                    let element = groupBy && (
                        <Wrapper style={{backgroundColor: "#fff7f3"}}>
                            <Column>
                                <WrapperGroup>
                                    <WrapperAction>
                                        <ToggleButton
                                            Icon1={<DownArrow color={global.config.colors.black}/>}
                                            Icon2={<AngleCirculeRight />}
                                            initialValue={true}
                                            LoadingIcon={<Loader height="1rem" width="1rem" />}
                                            onToggle={async () => {handleToggle(employee[groupByIdField]);}}
                                        />
                                    </WrapperAction>
                                    <GroupLabel>
                                        {employee[groupBy] ?? "No-Group"}
                                    </GroupLabel>
                                </WrapperGroup>
                            </Column>
                        </Wrapper>
                    );
                    let showGroupHeader = false;
                    let lastEmployee = null;
                    if (i > 0){
                        lastEmployee = employees[i-1];
                    }
                    if (groupBy){
                        if (lastEmployee == null || (lastEmployee[groupBy] != employee[groupBy])){
                            showGroupHeader = true; 
                        }
                    } 
                    return (
                        <>
                            {showGroupHeader ? element : ""} 
                            <div id={`divGroup_${employee[groupByIdField]}_${employee.userId}`}>
                                <MemoizedEmployee
                                    key={`row_${employee.userId}`}
                                    data={employee}
                                    params={params}
                                />
                            </div>
                        </>
                    );
                })
            }
        </>
    );
};

export default Row;
