import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useRetainersDashboardOverviewBudget = () => {
    const { getDataAsync } = useApi();

    const getBudgetSummaryApiCall = useCallback(
        async ({ endpointSuffix, retainerId, startDate, endDate }) => {
            const response = await getDataAsync(
                "retainersDashboardBudgetSummary",
                {
                    urlParams: [
                        { name: "$endpointSuffix", value: endpointSuffix },
                        { name: "$retainerId", value: retainerId },
                        { name: "$startDate", value: startDate },
                        { name: "$endDate", value: endDate },
                    ],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useBudgetSummary = (dataObj) =>
            useQuery(
                [`budgetSummary${dataObj.endpointSuffix}`, dataObj],
                () => getBudgetSummaryApiCall(dataObj)
            );
        return {
            useBudgetSummary,
        };
    }, [getBudgetSummaryApiCall]);
};

export default useRetainersDashboardOverviewBudget;
