import { useEffect, useRef, useState } from "react";
import { ResizableBox } from "react-resizable";
import styled from "styled-components";
import useWindowSize from "hooks/WindowSize";

const StyledContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
`;

const HandleIcon = styled.div`
    position: absolute;
    top: -11px;
    left: ${(props) => (props.position ? props.position.x + "px" : "0px")};
    z-index: 2;
    display: ${(props) => (props.show ? "block" : "none")};
`;

const Handle = styled.div`
    position: relative;
    width: 100%;
    height: ${(props) => props.height + "px"};
    background-color: ${(props) =>
        props.show ? global.config.colors.blue2 : "transparent"};
    &:hover {
        background-color: ${global.config.colors.blue2};
        cursor: row-resize;

        ${HandleIcon} {
            display: block;
        }
    }
`;

const StyledResizableBox = styled(ResizableBox)`
    position: relative;
    background-color: ${(props) =>
        props.showFill
            ? props.value > 0
                ? global.config.colors.blue3
                : global.config.colors.gray6
            : "transparent"};
`;

const Resize = ({
    value,
    maxValue,
    onAdjustStart,
    onAdjustEnd,
    onAdjusting,
    dragged,
    resize,
    showFill,
    handleHeight,
    stepValue,
    readOnly,
    ..._props
}) => {
    const windowSize = useWindowSize();

    const containerRef = useRef();
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [cellHeight, setCellHeight] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setContainerSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    }, [containerRef]);

    useEffect(() => {
        if (containerRef && containerRef.current) {
            setContainerSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    }, []);

    useEffect(() => {
        !resize && setCellHeight(calculateHeight(value));
    }, [containerSize, value, maxValue, resize, handleHeight, stepValue]);

    const calculateHeight = (v) => {
        let height = undefined;
        if (v > maxValue) height = containerSize.height;
        else height = v > stepValue ? (containerSize.height / maxValue) * v : 0;
        height = height > 0 ? height - handleHeight : height - 1;
        return height;
    };

    const handleResizeStart = (event) => {
        onAdjustStart(cellHeight, containerSize.height);
        event.stopPropagation();
    };
    const handleResizeStop = (_event) => {
        onAdjustEnd(cellHeight, containerSize.height);
    };
    const handleResize = (event, data) => {
        const height = data.size.height - handleHeight;
        setCellHeight(height);
        onAdjusting(height, containerSize.height);
        event.stopPropagation();
    };

    useEffect(() => {
        setContainerSize({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
        });
    }, [windowSize]);

    const mouseOver = () => {
        if (containerRef && containerRef.current && containerRef.current.offsetWidth && containerRef.current.offsetHeight){
            setContainerSize({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    }

    return (
        <>
            <StyledContainer ref={containerRef} onMouseOver={mouseOver}>
                <StyledResizableBox
                    width={containerSize.width}
                    height={handleHeight + cellHeight}
                    minConstraints={[containerSize.width, handleHeight]}
                    maxConstraints={[containerSize.width, containerSize.height]}
                    axis="y"
                    resizeHandles={["n"]}
                    handle={
                        readOnly ? (
                            <></>
                        ) : (
                            <Handle
                                show={dragged}
                                onClick={(event) => event.stopPropagation()}
                                height={handleHeight}
                            />
                        )
                    }
                    onResizeStart={handleResizeStart}
                    onResizeStop={(event) => handleResizeStop(event)}
                    onResize={handleResize}
                    draggableOpts={{ scale: 1 }}
                    value={value}
                    showFill={showFill}
                />
            </StyledContainer>
        </>
    );
};

export default Resize;
