import styled from "styled-components";

const StyledLink = styled.a`
    display: inline-flex;
    text-decoration: underline;
    cursor: pointer;
    color: ${global.config.colors.black};
    :hover {
        color: ${global.config.colors.black};
    }
    &:not([href]) {
        text-decoration: underline;
        color: ${global.config.colors.black};
        :hover {
            text-decoration: underline;
            color: ${global.config.colors.black};
        }
    }

    ${(props) =>
        props.disabled &&
        `
        cursor: default;
        color: ${global.config.colors.gray4};
        :hover {
            color: ${global.config.colors.gray4};
        }
        &:not([href]) {
            cursor: default;
            color: ${global.config.colors.gray4};
            :hover {
                color: ${global.config.colors.gray4};
            }
        }
    `}

    ${(props) =>
        props.noUnderline &&
        `
        text-decoration: none;
        :hover {
            text-decoration: none;
        }
        &:not([href]) {
            text-decoration: none;
            :hover {
                text-decoration: none;
            }
        }
    `}

    ${(props) =>
        props.noHover &&
        `
        :hover {
            color: ${global.config.colors.black};
        }
        &:not([href]) {
            :hover {
                color: ${global.config.colors.black};
            }
        }
    `}

    
    ${(props) =>
        props.color &&
        `
        color: ${props.color};
        &:not([href]) {
            color: ${props.color};
        }
    `}

    font-size: 0.75rem;
    font-family: HelveticaNeueW02-55Roma;
`;

const Link = (props) => {
    return (
        <>
            <StyledLink
                {...props}
                href={props.href ? props.href : void 0}
                onClick={
                    props.onClick && !props.disabled ? props.onClick : void 0
                }
            >
                {props.children}
            </StyledLink>
        </>
    );
};

export default Link;
