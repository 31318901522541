import PropTypes from "prop-types";

const DownCaret = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4 6L8 10L12 6" stroke={color} strokeWidth="1.17" />
        </svg>
    );
};

DownCaret.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

DownCaret.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default DownCaret;
