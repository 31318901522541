import React, { useRef } from "react";
import { useLocale } from "@react-aria/i18n";
import { createCalendar } from "@internationalized/date";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useRangeCalendar } from "@react-aria/calendar";
import { CalendarGrid } from "./CalendarGrid";
import { CalendarHeader } from "./CalendarHeader";
import styled from "styled-components";

const CalendarWrapper = styled(HStack)`
    padding: 0px 15px;
    height: 168px;
`;

const RangeCalendarWrapper = styled(VStack)`
    width: 402px;
`;

const CalendarFooter = styled(HStack)`
    padding: 0px 20px 10px 20px;
`;

export function RangeCalendar({
    disableDays,
    enableDaysFromDate,
    enableDaysUntilDate,
    minDate,
    onClickCancel,
    onClickDone,
    ...props
}) {
    let { locale } = useLocale();
    let ref = useRef();

    let state = useRangeCalendarState({
        ...props,
        visibleDuration: { months: 2 },
        locale,
        createCalendar,
    });

    let { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
        props,
        state,
        ref
    );

    return (
        <RangeCalendarWrapper>
            <CalendarHeader
                state={state}
                calendarProps={calendarProps}
                prevButtonProps={prevButtonProps}
                nextButtonProps={nextButtonProps}
                minDate={minDate}
            />
            <VStack spacing="One">
                <CalendarWrapper spacing="One" ref={ref}>
                    <CalendarGrid
                        enableDaysFromDate={enableDaysFromDate}
                        enableDaysUntilDate={enableDaysUntilDate}
                        disableDays={disableDays}
                        state={state}
                    />
                    <CalendarGrid
                        enableDaysFromDate={enableDaysFromDate}
                        enableDaysUntilDate={enableDaysUntilDate}
                        disableDays={disableDays}
                        state={state}
                        offset={{ months: 1 }}
                    />
                </CalendarWrapper>
                <CalendarFooter justify="space-between">
                    <TertiaryButton label="Cancel" onClick={onClickCancel} />
                    <TertiaryButton label="Done" onClick={onClickDone} />
                </CalendarFooter>
            </VStack>
        </RangeCalendarWrapper>
    );
}
