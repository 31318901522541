import React from "react";
import ReactDOM from "react-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import IconButton from "components/UI/IconButton";
import Close from "components/Icon/Close";
import { useStylesForModal } from "../../../styles/MakeStyles";

const GenericModal = ({
    parent,
    show,
    content,
    onHide,
    onClose,
    backdrop,
    keyboard,
    title,
    width,
}) => {
    const classes = useStylesForModal();

    const handleClose = () => {
        if (onClose) {
            onClose();
        } else {
            ReactDOM.render(null, document.getElementById(parent));
        }
        document.body.style.removeProperty('style');
    };

    return (
        <Modal
            stickyAction={false}
            onRequestClose={handleClose}
            title={title}
            showFooter={false}
            width={width}
        >
            {content}
        </Modal>
    );
};

GenericModal.defaultProps = {
    show: true,
    content: null,
    onHide: null,
    onClose: null,
    backdrop: global.config.modal.backdrop,
    keyboard: global.config.modal.keyboard,
    title: "",
};

export default GenericModal;
