import TextAutocompleteField from "../controls/textAutocomplete/TextAutocomplete";
import FormInputWrapper from "../ui/FormInputWrapper";
import { useFormInput } from "./utils";

const TextAutocomplete = ({
    name,
    validations,
    label,
    description,
    helpText,
    className,
    position,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className }}
            {...wrapperProps}
        >
            <TextAutocompleteField {...rest} {...inputProps} position={position} />
        </FormInputWrapper>
    );
};

export default TextAutocomplete;
