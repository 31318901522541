import PropTypes from "prop-types";
import MaterialButton from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useStylesForButton } from "styles/MakeStyles";

const Button = (props) => {
    const { to, onClick, disabled, type } = props;
    const classes = useStylesForButton();

    const DEFAULT_TYPE = classes.primaryButton;
    const TYPES = {
        primary: classes.primaryButton,
        secondary: classes.secondaryButton,
        link: classes.primaryLinkButton,
    };

    return (
        <>
            {to ? (
                <MaterialButton
                    component={Link}
                    to={to}
                    variant="contained"
                    className={classes.button}
                    disabled={disabled}
                    {...props}
                >
                    {props.children}
                </MaterialButton>
            ) : (
                <MaterialButton
                    onClick={onClick}
                    variant="contained"
                    className={TYPES[type] || DEFAULT_TYPE}
                    disabled={disabled}
                    {...props}
                >
                    {props.children}
                </MaterialButton>
            )}
        </>
    );
};

Button.defaultProps = {
    type: "primary",
    to: null,
    onClick: () => void 0,
    disabled: false,
};

Button.propTypes = {
    type: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Button;
