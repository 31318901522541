import unfetch from "isomorphic-unfetch";
import ApplicationsConfig from "../../../configs/Applications";
import { getToken, getEndpoints } from "configs/Endpoints";
import { APIError } from "utilities/Errors/ErrorsTypes";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import { useErrors } from "hooks/Errors/useErrors";

export const useApiRequest = () => {
    const { insightsException } = useInsigths();
    const { handleError } = useErrors();

    const callSkillSearch = async (resource, options) => {
        return fetch(ApplicationsConfig.SkillSearch.backend + resource, options)
            .then((response) => response.json())
            .catch((error) => {
                insightsException(error);
            });
    };

    const getSecurityToken = async (accessToken) => {
        return getEndpoints("membershipBase").then(
            async (endpoint)=>{

                let rv = sessionStorage.getItem("securityToken");

                if (rv == null)
                {
                    let url = `${endpoint.api}/api/v1/securityToken`;
                    const headers = {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Cache-Control": "no-cache",
                        Pragma: "no-cache",
                        Expires: "0",
                    };
    
                    const fetchOptions = {
                        method:"GET",
                        headers,
                    };
            
                    return unfetch(url, fetchOptions)
                        .then(async (payload) => {
                            let securityToken = await payload.json(); 
                            if (securityToken){
                                sessionStorage.setItem("securityToken", securityToken);
                            }
                            return securityToken;
                        })
                        .catch((error) => {
                            insightsException(error);
                            handleError(new APIError(error, url, fetchOptions));
                        });
                } 
                else
                {
                    return rv;
                }
            } 
        );
    }

    const callBackend = async (api, apiversion, resource, options) => {
        return getToken().then(async (accessToken) => {
            
            if (accessToken === null) {
                console.info("ApiRequest - Get Token Fail");
                handleError(
                    new APIError(
                        new Error("ApiRequest - Get Token Fail"),
                        null,
                        null
                    )
                );
            }

            return getSecurityToken(accessToken).then(async (securityToken) => 
                {
                    const params = { ...options.params };
                    const headers = {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Cache-Control": "no-cache",
                        Pragma: "no-cache",
                        Expires: "0",
                        "X-BW-Security-Token": securityToken,
                    };

                    let url = [
                        `${api}/api/${apiversion}/${resource}`,
                        ...Object.keys(params).map((key, index) => {
                            if (index === 0) return `?${key}=${params[key]}`;
                            return `&${key}=${params[key]}`;
                        }),
                    ].join("");
        
                    if (options.filters) url = `${url}&${options.filters.join("&")}`;
                    const fetchOptions = {
                        method: options.method ? options.method : "GET",
                        body: options.body ? JSON.stringify(options.body) : null,
                        headers,
                    };
                    return unfetch(url, fetchOptions)
                        .then((payload) => {
                            return payload;
                        })
                        .catch((error) => {
                            insightsException(error);
                            handleError(new APIError(error, url, fetchOptions));
                        });
                }
            )
        });
    };

    return { callSkillSearch, callBackend };
};
