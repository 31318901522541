import PropTypes from "prop-types";

const CaretRight = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.99994 12L9.99994 8L5.99994 4"
                stroke={color}
                strokeWidth="1.17"
            />
        </svg>
    );
};

CaretRight.defaultProps = {
    size: "16",
    color: global.config.colors.black,
};

CaretRight.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default CaretRight;
