import { useState, useEffect, useContext } from "react";
import env from "react-dotenv";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MenuProfileSignOutItem } from "./MenuProfileSignOutItem";
import { AboutMenuProfileItem } from "./AboutMenuProfileItem";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import { useApi } from "../../utilities/API/Helpers/useApi";
import { useStylesForAvatar } from "styles/MakeStyles";
import styled from "styled-components";
import Loader from "components/UI/Loader";
import SessionContext from "context/app/SessionContext";
import { clearToken } from "configs/Endpoints";
import MenuItem from "@material-ui/core/MenuItem";
import Text from "./Text";
import CleanUpSessionIcon from "components/Icon/Delete";

const useStyles = makeStyles(() => ({
    customStyles: {
        "&.MuiIconButton-root": {
            padding: 0,
            marginTop: "-0.5rem",
        },
    },
    avatarWrapperWithBorder: {
        border: "1px solid " + global.config.colors.black,
        padding: "0.2rem",
    },
    avatarWrapperWithoutBorder: {
        border: "none",
        padding: "0.2rem",
    },
}));

const TextWrapper = styled.div`
    margin: auto 0.5rem;
    height: 1.75rem;
    padding-top: 0.1rem;
`;

const UserWrapper = styled.div`
    display: flex;
`;

const UserWrapperCol1 = styled.div`
    width: 1rem;
    margin: 0.7rem 0.1rem;
`;

const UserWrapperCol2 = styled.div`
    margin: 0.3rem 2.2rem;
    height: 4rem;
`;

const AvatarWrapper = styled.div`
    margin: auto 1rem;
    float: left;
`;

const UserFullNameWrapper = styled.div`
    margin: auto 1rem;
    color: ${global.config.colors.black};
    font-family: ${global.config.fonts.families.medium};
    font-size: ${global.config.fonts.sizes.small};
`;

const UserTitleWrapper = styled.div`
    margin: auto 1rem;
    color: ${global.config.colors.gray2};
    font-family: ${global.config.fonts.families.light};
    font-size: ${global.config.fonts.sizes.xsmall};
`;

const UserOfficeWrapper = styled.div`
    margin: auto 1rem;
    color: ${global.config.colors.gray2};
    font-family: ${global.config.fonts.families.light};
    font-size: ${global.config.fonts.sizes.xsmall};
`;

const MainAvatarWrapper = styled.div`
    border-radius: 50%;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.2rem 0 0 0.2rem;
`;

const UserProfileMenu = (props) => {
    const session = useContext(SessionContext);

    const createDynamicMenuAnchor = () => {
        let anchor = document.createElement("div");
        anchor.style.position = "absolute";
        anchor.style.top = "2rem";
        anchor.style.right = "5rem";
        anchor.style.height = "25px";
        document.body.appendChild(anchor);
        return anchor;
    };

    let anchorEl = createDynamicMenuAnchor();
    let avatarURL = null;
    const isAuthenticated = useIsAuthenticated();
    const [open, setOpen] = useState(false);

    const { getDataAsync } = useApi();
    const [mainProfileAvatarClass, setMainProfileAvatarClass] = useState(null);

    const classes = useStyles();
    const avatarClass = useStylesForAvatar();

    useEffect(async () => {
        isAuthenticated && loadAvatar();
    }, [isAuthenticated]);

    const loadAvatar = async () => {
        setMainProfileAvatarClass(classes.avatarWrapperWithoutBorder);
        if (session) {
            getPhoto(session.id).then(async (photo) => {
                if (photo?.SmallPhotoUrl) {
                    avatarURL = `${env.BESTWORK_REACT_ASSET_DOMAIN}/${photo.SmallPhotoFileName}`;
                }
            });
        }
    };

    const getPhoto = async (employeeId) => {
        return getDataAsync("employeePhoto", {
            urlParams: [{ name: "$employeeId", value: employeeId }],
        })
            .then(async (payload) => {
                return payload.json();
            })
            .catch(() => {
                /* */
            });
    };

    const setAvatarBorder = (showBorder) => {
        if (showBorder) {
            setMainProfileAvatarClass(classes.avatarWrapperWithBorder);
        } else {
            setMainProfileAvatarClass(classes.avatarWrapperWithoutBorder);
        }
    };

    const handleClick = (_event) => {
        setOpen(!open);
        setAvatarBorder(!open);
    };

    const handleAboutClick = () => {
        props.onAboutItemClick();
        setOpen(false);
    };

    const { instanceMsal } = useMsal();
    const handleClearCache = () => {
        clearToken().then(() => instanceMsal.logout());
    }

    const handleMouseOverMenu = () => {
        let divUserWrapper = document.querySelector(".MuiPaper-root");
        if (divUserWrapper) {
            divUserWrapper.style.border = "1px solid black";
        }
    };

    const handleMouseOutMenu = () => {
        let divUserWrapper = document.querySelector(".MuiPaper-root");
        if (divUserWrapper) {
            divUserWrapper.style.border = "1px solid #DADBDE";
        }
    };

    if (isAuthenticated) {
        return session !== null && session !== undefined ? (
            <>
                <IconButton
                    onClick={(event) => handleClick(event)}
                    color="inherit"
                    className={classes.customStyles}
                >
                    <MainAvatarWrapper className={mainProfileAvatarClass}>
                        <Avatar src={avatarURL} className={avatarClass.root} />
                    </MainAvatarWrapper>
                </IconButton>
                <Menu
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={(event) => handleClick(event)}
                    PaperProps={{
                        style: {
                            border: "1px solid #DADBDE",
                            width: "17.25rem",
                            height: "13.8rem",
                            top: "1rem",
                        },
                        elevation: 0,
                    }}
                >
                    <div
                        onMouseOver={() => {
                            handleMouseOverMenu();
                        }}
                        onMouseOut={() => {
                            handleMouseOutMenu();
                        }}
                    >
                        <UserWrapper>
                            <UserWrapperCol1>
                                <AvatarWrapper>
                                    <Avatar alt="" src={avatarURL} />
                                </AvatarWrapper>
                            </UserWrapperCol1>
                            <UserWrapperCol2>
                                <UserFullNameWrapper>
                                    {session.fullName}
                                </UserFullNameWrapper>
                                <UserTitleWrapper>
                                    {session.currentTitle}
                                </UserTitleWrapper>
                                <UserOfficeWrapper>
                                    {session.currentOffice}
                                </UserOfficeWrapper>
                            </UserWrapperCol2>
                        </UserWrapper>
                        <MenuItem onClick={handleClearCache}>
                            <CleanUpSessionIcon></CleanUpSessionIcon>
                            <TextWrapper>
                                <Text variant="medium">Clean up session</Text>
                            </TextWrapper>
                        </MenuItem>
                        <AboutMenuProfileItem onClick={handleAboutClick} />
                        <MenuProfileSignOutItem />
                    </div>
                </Menu>
            </>
        ) : (
            <Loader height="1.5rem" width="1.5rem" />
        );
    }
};

export default UserProfileMenu;
