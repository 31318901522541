import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";

export const DateButton = styled.button`
    ${TEXT_VARIANT_STYLES.Body_2_1}
    width: 100%;
    height: auto;
    border-radius: 0;
    color: ${COLOR_VALUES.Gray_1};
    border: none;
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    background: transparent;
    padding: 7px 6px;
    margin-bottom: 0;
    text-align: left;

    &:hover {
        border-bottom: 1px solid ${COLOR_VALUES.Gray_5};
    }
    &:disabled {
        color: ${COLOR_VALUES.Gray_4};
        cursor: not-allowed;
    }

    &:focus-visible {
        outline: none;
    }

    ${(props) =>
        props.isSelected &&
        css`
            color: ${COLOR_VALUES.Gray_1};
            font-weight: 500;
            border-bottom: 1px solid ${COLOR_VALUES.Gray_1};
        `};

    ${(props) =>
        props.isCurrent &&
        css`
            color: ${COLOR_VALUES.Black};
            font-weight: 500;
        `};

    ${(props) =>
        (props.isDisabled || props.isUnavailable) &&
        css`
            color: ${COLOR_VALUES.Gray_4};
            cursor: not-allowed;

            &:hover {
                cursor: not-allowed;
            }
        `};
`;
