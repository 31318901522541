import React from "react";

import HStack from "components/design-system/layout/HStack";
import Table from "components/design-system/tables/Table";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

import useClientContactLeads from "pages/admin/clients/single/useClientContactLeads";

const Table_Width = 640;

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(72, Table_Width),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "userId",
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(288),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "fullName",
    },
    {
        headCellProps: {
            text: "Department",
            width: getCellWidthInPercent(188, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "department",
    },
    {
        headCellProps: {
            text: "Office",
            width: getCellWidthInPercent(60, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "officeAcronymous",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(100),
        },
        accessor: "Active",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=editContactLead&id=${cell.row.original.userId}`,
                replace: true,
                "aria-label": "Edit",
            },
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add",
    to: "?action=addContactLead",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const ClientContactLeadsTable = () => {
    const { clientContactLeads } = useClientContactLeads();

    if (clientContactLeads.isLoading) {
        return <>Loading...</>;
    }

    if (clientContactLeads.isError) {
        return <>Error</>;
    }

    return (
        <>
            <HStack align="end" justify="end">
                <TertiaryButton {...tableCTAProps} />
            </HStack>
            <Table
                columns={columns}
                data={clientContactLeads.data}
                emptyActionProps={tableCTAProps}
                emptyMessageTableTitle={"Contact Leads"}
                getRowProps={getRowProps}
            />
        </>
    );
};

export default React.memo(ClientContactLeadsTable);
