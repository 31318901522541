import { useContext, useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Dropdown from "components/design-system/forms/Dropdown";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";
import TextArea from "components/design-system/forms/TextArea";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";

import RetainersDashboardLayoutContext from "context/Projects/RetainersDashboardLayoutContext";

import useRetainersDashboardNotes, {
    useRetainersNoteTypes,
} from "pages/projects/retainers-dashboard/single/useRetainersDashboardNotes";
import useRetainers from "pages/admin/retainers/useRetainers";


const ModalCTA = ({
    isEditing,
    handleOnClickDeleteNote,
    isLoading,
    onClose,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add Note",
                leadingIcon: isEditing ? "save" : null,
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteNote(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformRetainersNoteDataToFormData(noteData = {}) {
    return {
        noteType: noteData.TypeId,
        note: noteData.Text,
    };
}

function transformFormDataToRetainersNoteData(
    formData = {},
    RetainersNoteData = {}
) {
    const newRetainersNoteData = { ...RetainersNoteData };
    newRetainersNoteData.TypeId = formData.noteType;
    newRetainersNoteData.Content = formData.note;

    return newRetainersNoteData;
}

const RetainersDashboardNotesForm = ({ noteIdToEdit, onClose }) => {
    const {
        useRetainersNotesList,
        createRetainerDashboardNote,
        updateRetainerDashboardNote,
        deleteRetainerDashboardNote,
    } = useRetainersDashboardNotes();
    const { retainerId } = useParams();
    const { setFlagToLoad } = useContext(RetainersDashboardLayoutContext);
    const notesList = useRetainersNotesList({
        retainerIdFE: retainerId,
        noteTypeId: "",
    });
    const { retainersNoteTypes } = useRetainersNoteTypes();
    const [inProgress, setInProgress] = useState(false);

    const currentRetainersNoteData = notesList?.data?.find(
        (note) => note.Id === noteIdToEdit
    );

    const { useRetainerById } = useRetainers();
    const retainerData = useRetainerById(retainerId);

    const isEditing = typeof noteIdToEdit !== "undefined";
    const modifier = isEditing
        ? updateRetainerDashboardNote
        : createRetainerDashboardNote;

    if (isEditing && !currentRetainersNoteData) {
        return <>Could not find note</>;
    }

    const getNameOfNotification = (res) => {
        return res ? `A note on ${retainerData?.data?.RetainerName}` : "note";
    };

    const handleOnClickDeleteNote = (prevData) => {
        deleteRetainerDashboardNote
            .mutateAsync({ noteId: noteIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={getNameOfNotification(response?.status === 200)}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    response?.status === 200,
                    response?.status === 200
                        ? {
                            label: "Undo",
                            onClick: () =>
                                onSubmit({
                                    ...prevData,
                                    isNoteRemoved: true,
                                }),
                        }
                        : null
                );
            });
    };

    const onSubmit = (formData) => {
        const data = transformFormDataToRetainersNoteData(
            formData,
            currentRetainersNoteData
        );
        const newModifier = formData.isNoteRemoved
            ? createRetainerDashboardNote
            : modifier;
        const allData = {
            noteId: noteIdToEdit,
            body: data,
        };
        newModifier.mutateAsync(allData).then((response) => {
            onClose();
            setInProgress(false);
            isEditing && setFlagToLoad(true);
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={formData.isNoteRemoved ? false : isEditing}
                    name={getNameOfNotification(response?.status === 200)}
                />
            );
        });
    };

    const noteTypesOptions = !retainersNoteTypes.isLoading
        ? getDropdownOptionsNoDefaultVal(retainersNoteTypes?.data)
        : [];

    if (notesList.isLoading || retainersNoteTypes.isLoading) {
        return <>Loading...</>;
    }

    if (
        notesList.isError ||
        retainersNoteTypes.isError ||
        retainerData.isError
    ) {
        return <>Error</>;
    }

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformRetainersNoteDataToFormData(
                currentRetainersNoteData
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack>
                    <Dropdown
                        name="noteType"
                        validations={{
                            required: true,
                        }}
                        label="Note Type"
                        itemsAreLoading={retainersNoteTypes.isLoading}
                        options={noteTypesOptions}
                        maxHeightOfOptionsList={100}
                    />
                    <TextArea
                        name="note"
                        showClearIcon={true}
                        characterCounter={true}
                        label="Note"
                        placeholder="Add a note to the Retainer"
                        helpText="Character limit: 256"
                        validations={{
                            required: true,
                            maxLength: {
                                value: 256,
                                message: "Please use fewer characters",
                            },
                        }}
                    />
                </VStack>

                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteNote={handleOnClickDeleteNote}
                    onClose={onClose}
                    isLoading={modifier?.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default RetainersDashboardNotesForm;
