import { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import PageWrapper from "pages/PageWrapper";
import HolidaysTable from "pages/admin/holidays/HolidaysTable";
import HolidayForm from "pages/admin/holidays/HolidayForm";
import HolidayDeleteForm from "pages/admin/holidays/HolidayDeleteForm";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const FormPresenter = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const now = new Date();
    insightsTrace("VIEW", "AdminHolidaysMainPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    const action = searchParams.get("action");
    const date = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal
                title="Add Holiday"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <HolidayForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    if (action === "edit") {
        return (
            <Modal
                title="Edit Holiday"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <HolidayForm
                    holidayIdToEdit={date}
                    key={date}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "delete") {
        return (
            <Modal
                title="Delete Holiday"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <HolidayDeleteForm
                    holidayIdToDelete={date}
                    key={date}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const Holidays = () => {
    return (
        <>
            <PageWrapper
                title="Holidays"
                menuOptions={[{ children: "Admin", to: "/admin" }]}
            >
                <FormPresenter />
                <HolidaysTable />
            </PageWrapper>
        </>
    );
};

export default Holidays;
