import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Text from "components/design-system/ui/Text";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";

const Wrapper = styled.div`
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
`;

const TabsUL = styled.ul`
    display: flex;
    margin: 0 0 -1px;
    padding: 0;
`;

const TabsLI = styled.li`
    list-style: none;
`;

const TabWrapper = styled.div`
    cursor: pointer;
    display: inline-flex;
    text-decoration: none;
    padding: 16px;
    gap: 4px;
    align-items: center;
    background: ${COLOR_VALUES.White};
    color: ${COLOR_VALUES.Gray_4} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */

    &:hover {
        background: ${COLOR_VALUES.Gray_8};
        border-bottom: 2px solid ${COLOR_VALUES.Gray_6};
    }

    ${(props) =>
        props.isActive &&
        css`
            color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
            border-bottom: 2px solid ${COLOR_VALUES.Black};
        `}
`;

const Tab = ({ label, icon, to, selected, ...rest }) => {
    return (
        <TabsLI>
            {label && (
                <TabWrapper isActive={selected} {...rest} to={to}>
                    {icon && <Icon name={icon} />}
                    <Text variant="Headline_5">{label}</Text>
                </TabWrapper>
            )}
        </TabsLI>
    );
};

const Tabs = ({ tabs, clickTab, selected, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <TabsUL>
                {tabs.map((data, index) => (
                    <Tab
                        onClick={() => clickTab(data)}
                        selected={selected === data.label}
                        {...data}
                        key={index}
                    />
                ))}
            </TabsUL>
        </Wrapper>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
            icon: PropTypes.oneOf(ICON_NAMES),
        })
    ),
    selected: PropTypes.string,
    clickTab: PropTypes.func,
};

export default Tabs;
