import { useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import styled from "styled-components";
import { format } from "date-fns";

import VStack from "components/design-system/layout/VStack";
import TextInput from "components/design-system/forms/TextInput";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import DatePicker from "components/design-system/forms/DatePicker";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";

import {
    useAltJobCodesApi,
    useClientCampaignsApi,
    useClientGroupLOBApi,
    useProjectClassificationsApi,
    useProjectsApi,
    useRateCardsByClientIdApi,
    useRetainersApi,
    useTaxCodesApi,
    useVantageLOBApi,
} from "pages/admin/projects/useProjects";
import SegmentControlFormElement from "components/design-system/forms/SegmentControlFormElement";
import useOffices from "pages/admin/offices/useOffices";
import { COLOR_VALUES } from "components/design-system/config/colors";

import {
    vantageStatusOptions,
    apiStatusOptions,
    ptoUtilizationOption,
    allotmentTypeOption,
    attributesActiveCancelledOptions,
    classificationSegmentOptions,
    attributesRequestOptions,
    attributesEACOptions,
    attributesCostOptions,
} from "pages/admin/projects/projectsFormOptions";
import {
    getCalendarDateFromNewDate,
    getDropdownOptions,
} from "pages/admin/offices/OfficesForm";

import { useClientsFilterApi } from "pages/admin/projects/single/useProjectsLinked";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

const StyledDiv = styled.div`
    border-top: 1px solid ${COLOR_VALUES.Gray_6};
    padding-top: 16px;
`;

let rateCardOptions = [];
const ADMIN_CLIENT_NAME = "Administrative R/GA";

const ModalCTA = ({ isEditing, isLoading, onClose }) => {
    const { isValid } = useFormState();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save Changes" : "Add Project",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformProjectDataToFormData(projectData = {}) {
    const getAttributes = (attributeOptions) => {
        return attributeOptions
            .map((attribute) => attribute.value)
            .filter((item) => projectData[item])
            .join(",");
    };

    return {
        ptoUtilization: getAttributes(ptoUtilizationOption),
        attributesActiveCancelled: attributesActiveCancelledOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "Active"
                    ? projectData[item] ?? true
                    : projectData[item]
            )
            .join(","),

        classificationSegment: projectData.IsNonBillable
            ? classificationSegmentOptions[1].value
            : classificationSegmentOptions[0].value,

        attributesRequest: getAttributes(attributesRequestOptions),

        attributesEAC: attributesEACOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "SendToVantage"
                    ? projectData[item] ?? true
                    : projectData[item]
            )
            .join(","),
        attributesCost: getAttributes(attributesCostOptions),

        projectName: projectData.Name ?? "",
        jobCode: projectData.JobCode ?? "",
        clientFamilyId: projectData.ClientFamilyId ?? "",
        clientId: projectData.ClientId,
        allotmentTypeId: projectData.QuotaAllotmentTypeID,
        classification: projectData.ClassificationId,
        rateCardId: projectData.RateCardTableId,
        leadOffice: projectData.OfficeRegionId,
        vantageStatus: projectData.SAP_Job_Status_Id,
        apiStatus: projectData.SAP_API_Status_Id,
        taxType: projectData.TypeForTaxId,
        vantageLOB: projectData.SAPLOBID,
        startDate: projectData.ProjStartDate
            ? format(new Date(projectData.ProjStartDate), "yyyy-MM-dd")
            : null,
        retainer: projectData.RetainerId,
        altJobCode: projectData.OldJobCode ?? "",
        clientGroupLOB: projectData.ClientGroupId,
        clientCampaign: projectData.ClientCampaignId,
        hrsVal: projectData.SOWTotalCalc ?? "",
        oopEstimate: projectData.OOPExpEstimate ?? "",
        agencyFeeEstimate: projectData.AgencyFeeEstimate ?? "",
    };
}

const decimalValue = (num) => {
    const newNum = Number(Number.parseFloat(num).toFixed(2));
    if (isNaN(newNum)) {
        return num;
    }
    return newNum;
};

function transformFormDataToProjectData(
    formData = {},
    projectData = {},
    clientName = "",
    rateCardTableName = ""
) {
    const newProjectData = { ...projectData };

    const attributeUpdate = (attributesOptions, attributeName) => {
        attributesOptions?.forEach((attribute) => {
            newProjectData[attribute.value] = attributeName
                .split(",")
                .includes(attribute.value);
        });
    };

    newProjectData.IsNonBillable =
        formData.classificationSegment === classificationSegmentOptions[1].value
            ? true
            : false;
    projectData.RateCardTableId = formData.rateCardId;

    attributeUpdate(ptoUtilizationOption, formData.ptoUtilization);
    attributeUpdate(
        attributesActiveCancelledOptions,
        formData.attributesActiveCancelled
    );
    attributeUpdate(attributesRequestOptions, formData.attributesRequest);
    attributeUpdate(attributesEACOptions, formData.attributesEAC);
    attributeUpdate(attributesCostOptions, formData.attributesCost);

    newProjectData.ClientName = clientName;
    newProjectData.RateCardTableName = rateCardTableName;

    newProjectData.Name = formData.projectName;
    newProjectData.JobCode = formData.jobCode;
    newProjectData.ClientId = formData.clientId;
    newProjectData.QuotaAllotmentTypeID = formData.allotmentTypeId;
    newProjectData.ClassificationId = formData.classification;
    newProjectData.leadOffice = projectData.OfficeRegionId;
    newProjectData.SAP_Job_Status_Id = formData.vantageStatus;
    newProjectData.SAP_API_Status_Id = formData.apiStatus;
    newProjectData.TypeForTaxId = formData.taxType;
    newProjectData.SAPLOBID = formData.vantageLOB;
    newProjectData.ProjStartDate = formData.startDate;
    newProjectData.RetainerId = formData.retainer;
    newProjectData.OldJobCode = formData.altJobCode;
    newProjectData.ClientGroupId =
        formData.clientGroupLOB === "" ? 0 : formData.clientGroupLOB;
    newProjectData.ClientCampaignId =
        formData.clientCampaign === "" ? 0 : formData.clientCampaign;
    newProjectData.SOWTotalCalc = decimalValue(formData.hrsVal);
    newProjectData.OOPExpEstimate = decimalValue(formData.oopEstimate);
    newProjectData.AgencyFeeEstimate = decimalValue(formData.agencyFeeEstimate);

    return newProjectData;
}

const RenderPTO = ({ adminClientId }) => {
    const currentClientId = useWatch({ name: "clientId" });

    return adminClientId && currentClientId === adminClientId ? (
        <VStack>
            <Options
                name="ptoUtilization"
                label="Payed Time Off Utilization"
                options={ptoUtilizationOption}
            />
            <Dropdown
                name="allotmentTypeId"
                validations={{
                    required: true,
                }}
                label="Allotment Type"
                options={allotmentTypeOption}
            />
        </VStack>
    ) : (
        <></>
    );
};

const RenderStartDate = ({ startDate }) => {
    const currentStartDate = startDate
        ? getCalendarDateFromNewDate(new Date(startDate))
        : null;

    return (
        <DatePicker
            defaultDateValue={currentStartDate}
            name="startDate"
            label="Start Date"
            validations={{
                required: true,
            }}
        />
    );
};

const RenderRateCardDropdown = () => {
    const currentClientId = useWatch({ name: "clientId" });
    const { useRateCardsByClientId } = useRateCardsByClientIdApi();
    const rateCardsByClientId = useRateCardsByClientId({
        clientId: currentClientId ?? "",
    });
    rateCardOptions = !rateCardsByClientId?.isLoading
        ? getDropdownOptions(
              rateCardsByClientId?.data,
              "RateCardTableId",
              "RateCardTableName"
          )
        : [];
    return (
        <Dropdown
            name="rateCardId"
            validations={{
                required: true,
            }}
            label="Rate Card"
            options={rateCardOptions}
        />
    );
};

const ProjectsForm = ({ projectIdToEdit, onClose }) => {
    const { useProjectById, createProject, updateProject } = useProjectsApi();
    const [inProgress, setInProgress] = useState(false);

    const { altJobCodes } = useAltJobCodesApi();
    const { projectClassifications } = useProjectClassificationsApi();
    const { offices } = useOffices();
    const { taxCodes } = useTaxCodesApi();
    const { vantageLOB } = useVantageLOBApi();
    const { clientGroupLOB } = useClientGroupLOBApi();
    const { clientCampaigns } = useClientCampaignsApi();
    const { retainers } = useRetainersApi();
    const { clientsFilter } = useClientsFilterApi();

    const isEditing = typeof projectIdToEdit !== "undefined";
    const modifier = isEditing ? updateProject : createProject;

    const projectData = useProjectById(projectIdToEdit);
    const currentProjectData = projectData?.data;

    const clientsFilterDropdownOptions = !clientsFilter?.isLoading
        ? getDropdownOptions(clientsFilter?.data, "ClientId")
        : [];

    const adminClientId = clientsFilterDropdownOptions?.find(
        (f) => f.label == ADMIN_CLIENT_NAME
    )?.value;

    if (projectData?.isLoading) {
        return <>Loading...</>;
    }

    if (projectData?.error) {
        return <>Error</>;
    }

    if (isEditing && !currentProjectData) {
        return <div>Could not find project</div>;
    }

    const altJobCodesOptions = !altJobCodes.isLoading
        ? getDropdownOptions(altJobCodes?.data)
        : [];

    const projectClassificationsOptions = !projectClassifications.isLoading
        ? getDropdownOptions(projectClassifications?.data)
        : [];

    const leadOfficeOptions = !offices.isLoading
        ? getDropdownOptions(offices?.data, "OfficeRegionId")
        : [];

    const taxTypesOptions = !taxCodes.isLoading
        ? getDropdownOptions(taxCodes?.data, "typeForTaxID", "JobTaxTypeName")
        : [];

    const vantageLOBOptions = !vantageLOB.isLoading
        ? getDropdownOptions(vantageLOB?.data, "SAPLOBID", "SAPLOBName")
        : [];

    const clientGroupLOBOptions = !clientGroupLOB.isLoading
        ? getDropdownOptions(clientGroupLOB?.data, "clientGroupID", "GroupName")
        : [];

    const clientCampaignOptions = !clientCampaigns.isLoading
        ? getDropdownOptions(
              clientCampaigns?.data,
              "ClientCampaignId",
              "CampaignName",
              0
          )
        : [];

    const retainerOptions = !retainers.isLoading
        ? getDropdownOptions(
              retainers?.data?.Items,
              "RetainerID",
              "RetainerName"
          )
        : [];

    const onSubmit = (formData) => {
        const data = transformFormDataToProjectData(
            formData,
            currentProjectData,
            clientsFilterDropdownOptions?.find(
                (f) => f.value === formData.clientId
            )?.label,
            rateCardOptions?.find((f) => f.value === formData.rateCardId)?.label
        );

        modifier.mutateAsync(data).then((response) => {
            onClose();
            setInProgress(false);
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={isEditing ? currentProjectData.Name : data.Name}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformProjectDataToFormData(currentProjectData)}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <VStack>
                        <TextInput
                            name="projectName"
                            label="Name"
                            validations={{
                                required: true,
                            }}
                            placeholder="Project Name"
                        />
                        <TextInput
                            name="jobCode"
                            label="Number"
                            validations={{
                                required: true,
                            }}
                            placeholder="e.g. 16161616"
                        />
                        <Dropdown
                            name="clientId"
                            validations={{
                                required: true,
                            }}
                            label="Client"
                            options={clientsFilterDropdownOptions}
                        />
                    </VStack>

                    <RenderPTO adminClientId={adminClientId} />

                    <VStack>
                        <Options
                            name="attributesActiveCancelled"
                            options={attributesActiveCancelledOptions}
                        />
                    </VStack>

                    <VStack>
                        <Dropdown
                            name="classification"
                            validations={{
                                required: true,
                            }}
                            label="Classification"
                            options={projectClassificationsOptions}
                        />
                        <SegmentControlFormElement
                            filterLabel={null}
                            name="classificationSegment"
                            options={classificationSegmentOptions}
                        />
                        <RenderRateCardDropdown />
                        <Dropdown
                            name="leadOffice"
                            validations={{
                                required: true,
                            }}
                            label="Lead Office"
                            options={leadOfficeOptions}
                        />
                        <Options
                            name="attributesRequest"
                            options={attributesRequestOptions}
                        />
                    </VStack>

                    <VStack>
                        <Dropdown
                            name="vantageStatus"
                            validations={{
                                required: true,
                            }}
                            label="Vantage Status"
                            options={vantageStatusOptions}
                        />
                        <Dropdown
                            name="apiStatus"
                            validations={{
                                required: true,
                            }}
                            label="API Status"
                            options={apiStatusOptions}
                        />
                        <Dropdown
                            name="taxType"
                            validations={{
                                required: true,
                            }}
                            label="Type for Tax"
                            options={taxTypesOptions}
                        />
                        <Dropdown
                            name="vantageLOB"
                            validations={{
                                required: true,
                            }}
                            label="Vantage LOB"
                            options={vantageLOBOptions}
                        />
                    </VStack>

                    <VStack>
                        <RenderStartDate
                            startDate={currentProjectData?.ProjStartDate}
                        />
                        <Dropdown
                            name="retainer"
                            validations={{
                                required: true,
                            }}
                            label="Retainer"
                            options={retainerOptions}
                        />
                    </VStack>

                    <VStack>
                        <StyledDiv>
                            <Text variant="Headline_4" color="Gray_1">
                                Optional
                            </Text>
                            <Text variant="Body_2_1" color="Gray_2">
                                The fields below are not required
                            </Text>
                        </StyledDiv>
                        <Dropdown
                            name="altJobCode"
                            label="Alternative Job Code"
                            options={altJobCodesOptions}
                        />
                        <Dropdown
                            name="clientGroupLOB"
                            label="Client Group (LOB)"
                            options={clientGroupLOBOptions}
                        />
                        <Dropdown
                            name="clientCampaign"
                            label="Client Campaign"
                            options={clientCampaignOptions}
                        />
                        <TextInput
                            type="number"
                            name="hrsVal"
                            label="Total $ HRs Value"
                            placeholder="0.00"
                        />
                        <TextInput
                            type="number"
                            name="oopEstimate"
                            label="OOP Estimate"
                            placeholder="0.00"
                        />
                        <TextInput
                            type="number"
                            name="agencyFeeEstimate"
                            label="Agency Fee Estimate"
                            placeholder="0.00"
                        />
                        <Options
                            name="attributesEAC"
                            options={attributesEACOptions}
                        />
                        <Options
                            name="attributesCost"
                            label="Attributes"
                            options={attributesCostOptions}
                        />
                    </VStack>
                </VStack>

                <ModalCTA
                    isEditing={isEditing}
                    projectIdToEdit={projectIdToEdit}
                    onClose={onClose}
                    isLoading={modifier.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default ProjectsForm;
