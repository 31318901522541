import { createTheme, responsiveFontSizes } from "@material-ui/core";
import "configs/Global";

let Theme = createTheme({
    typography: {
        default: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.small,
        },
        xxsmallBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.xxsmall,
        },
        xxsmallLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.xxsmall,
        },
        xxsmallMedium: {
            fontFamily: global.config.fonts.families.medium,
            fontSize: global.config.fonts.sizes.xxsmall,
        },
        xsmall: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.xsmall,
        },
        xsmallBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.xsmall,
        },
        xsmallLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.xsmall,
        },
        small: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.small,
        },
        smallLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.small,
        },
        smallBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.small,
        },
        smedium: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.smedium,
        },
        medium: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.medium,
        },
        mediumBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.medium,
        },
        mediumLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.medium,
        },
        large: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.large,
        },
        largeBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.large,
        },
        largeLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.large,
        },
        xlarge: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.xlarge,
        },
        xlargeBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.xlarge,
        },
        xlargeLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.xlarge,
        },
        xxlarge: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.xxlarge,
        },
        xxlargeBold: {
            fontFamily: global.config.fonts.families.bold,
            fontSize: global.config.fonts.sizes.xxlarge,
        },
        xxlargeLight: {
            fontFamily: global.config.fonts.families.light,
            fontSize: global.config.fonts.sizes.xxlarge,
        },
        xxxlarge: {
            fontFamily: global.config.fonts.families.regular,
            fontSize: global.config.fonts.sizes.xxxlarge,
        },
        htmlFontSize: 12,
    },

    palette: {
        primary: {
            light: global.config.colors.gray5,
            main: global.config.colors.black,
            dark: global.config.colors.black,
            contrastText: global.config.colors.white,
        },
        secondary: {
            light: global.config.colors.white,
            main: global.config.colors.white,
            dark: global.config.colors.gray2,
            contrastText: global.config.colors.black,
        },
    },
});

Theme = responsiveFontSizes(Theme);

export default Theme;
