import { useContext, useEffect, useState } from "react";
import env from "react-dotenv";
import { format } from "date-fns";

import Loader from "components/UI/Loader";
import Subject from "components/design-system/ui/drawer/Subject";
import DataGroup from "components/design-system/ui/drawer/DataGroup";
import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import DataItem from "components/design-system/ui/drawer/DataItem";

import useRetainers, { useBizUnits } from "pages/admin/retainers/useRetainers";

import DrawerContext from "context/Drawer/DrawerContext";

const RetainerDrawer = ({ retainerId }) => {
    const { useRetainerById } = useRetainers();
    const { bizUnits } = useBizUnits();

    const retainerData = useRetainerById(retainerId);
    const retainerInfo = retainerData?.data;
    const { setShow } = useContext(DrawerContext);
    const [bizUnitName, setBizUnitName] = useState();

    const handleDrawerClose = () => {
        setShow(false);
        setBizUnitName("");
    };

    useEffect(() => {
        if (retainerData.isError) {
            handleDrawerClose();
        }
    }, [retainerData]);

    useEffect(() => {
        if (!bizUnits.isLoading) {
            const BUName = bizUnits?.data?.filter((f) => {
                return f?.BizUnitID === retainerInfo?.BizUnitID;
            });

            setBizUnitName(BUName[0]?.BUName);
        }
    }, [bizUnits]);

    const getAgencyFeeEstimate = () => {
        if (retainerInfo.AgencyFeeEstimate && retainerInfo.SOWSigned) {
            return `${retainerInfo.AgencyFeeEstimate} \n SOW Signed `;
        } else if (retainerInfo.AgencyFeeEstimate) {
            return retainerInfo.AgencyFeeEstimate;
        } else if (retainerInfo.SOWSigned) {
            return "SOW Signed";
        } else return "";
    };

    const getCodeDetails = () => {
        if (retainerInfo.JobCode && retainerInfo.AltRetainerCodeSystemID) {
            return `Number: ${retainerInfo.JobCode} \n Alt Number: ${retainerInfo.AltRetainerCodeSystemID}`;
        } else if (retainerInfo.JobCode) {
            return `Number: ${retainerInfo.JobCode}`;
        } else if (retainerInfo.AltRetainerCodeSystemID) {
            return `Alt Number: ${retainerInfo.AltRetainerCodeSystemID}`;
        } else return "";
    };

    const getVantageLob = () => {
        return `${
            retainerInfo.SAPLOBName ? retainerInfo.SAPLOBName + "\n" : ""
        } ${retainerInfo.SendToVantage ? "Send EAC/PTD to Vantage \n" : ""} ${
            retainerInfo.OverwriteEACPTD ? "Overwrite EAC/PTD to Vantage" : ""
        }`;
    };

    if (retainerData.isLoading || bizUnits.isLoading) {
        return <>Loading...</>;
    }

    if (retainerData.isError || bizUnits.isError) {
        return <>Error</>;
    }

    return (
        <>
            {retainerId >= 0 ? (
                <>
                    <Subject
                        subjectName={retainerInfo?.RetainerName}
                        codeNumber={getCodeDetails()}
                        showPhoto={false}
                        isMoreIcon={false}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Client Family",
                                value: retainerInfo.ClientFamilyName,
                            },
                            {
                                label: "Corporate Entity",
                                value: retainerInfo.ClientCorpEntityName,
                            },
                            {
                                label: "Client",
                                value: retainerInfo.ClientName,
                                isHr: true,
                            },
                        ]}
                    />

                    <DataItem
                        label="Status"
                        iconName={retainerInfo.Active ? "success" : "error"}
                        title={retainerInfo.Active ? "Active" : "Canceled"}
                    />

                    <DataGroup
                        isPadding={false}
                        groupData={[
                            {
                                label: "Bill",
                                value: retainerInfo.IsNonBillable
                                    ? "Non-Billable"
                                    : "Billable",
                            },
                            {
                                label: "Start Date",
                                value: format(
                                    new Date(retainerInfo.RetStartDate),
                                    "yyyy-MM-dd"
                                ),
                            },
                            {
                                label: "End Date",
                                value: format(
                                    new Date(retainerInfo.RetEndDate),
                                    "yyyy-MM-dd"
                                ),

                                isHr: true,
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Rate Card",
                                value: retainerInfo.RateCardTableName,
                            },
                            {
                                label: "Retainer $ Amount",
                                value: retainerInfo.AmountUnAllocated,
                                isHr: true,
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "Lead Office",
                                value: retainerInfo.LeadOfficeName,
                            },
                            {
                                label: "Lead Producer",
                                value: retainerInfo.LeadProducerName,
                                isHr: true,
                            },
                        ]}
                    />
                    <DataGroup
                        groupData={[
                            {
                                label: "Vantage Business Unit",
                                value: bizUnitName,
                            },
                            {
                                label: "Vantage Status",
                                value: retainerInfo.SAP_Job_Status_Name,
                            },
                            {
                                label: "API Status",
                                value: retainerInfo.SAP_API_status_Name,
                            },
                            {
                                label: "Type for Tax",
                                value: retainerInfo.TypeOfTaxName,
                            },
                            {
                                label: "Vantage LOB",
                                value: getVantageLob(),
                                isNextLine: true,
                                isHr: true,
                            },
                        ]}
                    />

                    <DataGroup
                        groupData={[
                            {
                                label: "OOP Estimate",
                                value: retainerInfo.OOPExpEstimate
                                    ? retainerInfo.OOPExpEstimate
                                    : "",
                            },
                            {
                                label: "Agency Fee Estimate",
                                value: getAgencyFeeEstimate(),
                                isNextLine: true,
                                isHr: true,
                            },
                        ]}
                    />
                    {retainerInfo.Active ? (
                        <DrawerActions
                            primaryAction={{
                                label: "Edit",
                                leadingIcon: "edit",
                                to: `?action=edit&id=${retainerInfo.RetainerID}`,
                                onClick: handleDrawerClose,
                            }}
                            tertiaryAction={{
                                label: "Dashboard",
                                trailingIcon: "link",
                                isInternalUrlNewTab: true,
                                href:
                                    "/redirectto?url=" +
                                    env.BESTWORK_REACT_REPORTER10_LINK +
                                    "client/retainers/RetainerReport.aspx?retainerID=" +
                                    retainerInfo.RetainerID +
                                    "&env=" +
                                    env.ENVIRONMENT_NAME +
                                    "&vpn=true",
                                target: "_blank",
                                rel: "noreferrer",
                            }}
                        />
                    ) : (
                        <DrawerActions
                            primaryAction={{
                                label: "Edit",
                                leadingIcon: "edit",
                                to: `?action=edit&id=${retainerInfo.RetainerID}`,
                                onClick: handleDrawerClose,
                            }}
                        />
                    )}
                </>
            ) : (
                <Loader height="5rem" width="5rem" />
            )}
        </>
    );
};

export default RetainerDrawer;
