const COLOR_VALUES = {
    Black: "#000",
    White: "#fff",
    Veil_1: "rgba(63, 67, 77, 0.5)",
    Veil_2: "rgba(63, 67, 77, 0.25)",
    Gray_1: "#3F434D",
    Gray_2: "#5F636D",
    Gray_3: "#8C8F97",
    Gray_4: "#A3A6AB",
    Gray_5: "#DADBDE",
    Gray_6: "#E5E8EC",
    Gray_7: "#EDEFF2",
    Gray_8: "#F5F6F8",
    Gray_9: "#FAFAFB",
    Green: "#1FD323",
    Blue_1: "#0066F5",
    Blue_2: "#70ACFF",
    Blue_3: "#A6CCFF",
    Blue_4: "#E1EEFF",
    Orange_1: "#FF3D00",
    Orange_2: "#FFBEAD",
    Orange_3: "#FFDED6",
    Yellow: "#FFCD00",
};

const COLORS = {
    Black: "Black",
    White: "White",
    Veil_1: "Veil_1",
    Veil_2: "Veil_2",
    Gray_1: "Gray_1",
    Gray_2: "Gray_2",
    Gray_3: "Gray_3",
    Gray_4: "Gray_4",
    Gray_5: "Gray_5",
    Gray_6: "Gray_6",
    Gray_7: "Gray_7",
    Gray_8: "Gray_8",
    Gray_9: "Gray_9",
    Green: "Green",
    Blue_1: "Blue_1",
    Blue_2: "Blue_2",
    Blue_3: "Blue_3",
    Blue_4: "Blue_4",
    Orange_1: "Orange_1",
    Orange_2: "Orange_2",
    Orange_3: "Orange_3",
    Yellow: "Yellow",
};
export default COLORS;
export { COLOR_VALUES };
