import PropTypes from "prop-types";

const Alert = ({ size, color, viewBox }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M15.3337 14.6667L7.98337 2L0.666992 14.6667H15.3337Z"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M8.05795 13.4474C8.43244 13.4474 8.73602 13.1439 8.73602 12.7694C8.73602 12.3949 8.43244 12.0913 8.05795 12.0913C7.68347 12.0913 7.37988 12.3949 7.37988 12.7694C7.37988 13.1439 7.68347 13.4474 8.05795 13.4474Z"
                    fill={color}
                />
                <path
                    d="M8.05762 6.66675L8.05762 11.0742"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

Alert.defaultProps = {
    size: "18",
    color: global.config.colors.black,
    viewBox: "16",
};

Alert.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    viewBox: PropTypes.string,
};

export default Alert;
