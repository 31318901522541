import React, { useState, useMemo, useEffect } from "react";

const EmployeeContext = React.createContext();

export const EmployeeProvider = (props) => {
    const [employeeData, setEmployeeData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [summaryDetailsData, setSummaryDetailsData] = useState([]);
    const [detailsData, setDetailsData] = useState([]);

    const contextValue = useMemo(() => {
        return {
            employeeData,
            summaryData,
            summaryDetailsData,
            detailsData,
            setEmployeeData,
            setSummaryData,
            setSummaryDetailsData,
            setDetailsData,
        };
    }, [
        employeeData,
        summaryData,
        summaryDetailsData,
        detailsData,
        setEmployeeData,
        setSummaryData,
        setSummaryDetailsData,
        setDetailsData,
    ]);

    return (
        <EmployeeContext.Provider value={contextValue}>
            {props.children}
        </EmployeeContext.Provider>
    );
};

export default EmployeeContext;
