import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon from "components/design-system/ui/Icon";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";

const TagWrapper = styled(HStack)`
    word-break: break-all;
    align-items: center;
    border-radius: 2px;
    width: max-content;
    color: ${COLOR_VALUES.Gray_1};

    ${(props) =>
        props.isSelected
            ? css`
                  background: ${COLOR_VALUES.Gray_7};
              `
            : css`
                  background: ${COLOR_VALUES.White};
                  border: 1px solid ${COLOR_VALUES.Gray_6};
              `}

    ${(props) =>
        props.size.toLowerCase() === "small" &&
        css`
            padding: 2px 6px;
            gap: 2px;
            height: 16px;
            font-weight: 400;
            ${TEXT_VARIANT_STYLES.Descriptive_2_1};
        `}       

    ${(props) =>
        props.size.toLowerCase() === "medium" &&
        css`
            padding: 4px 8px;
            gap: 4px;
            height: 24px;
            ${TEXT_VARIANT_STYLES.Descriptive_1_1};
        `}

    ${(props) =>
        props.size.toLowerCase() === "large" &&
        css`
            padding: 6px 12px;
            gap: 4px;
            height: 32px;
            ${TEXT_VARIANT_STYLES.Body_2_1};
        `}

    ${(props) =>
        props.disabled
            ? css`
                  background: ${COLOR_VALUES.Gray_7};
                  color: ${COLOR_VALUES.Gray_3};
                  cursor: not-allowed;
              `
            : css`
                  &:hover {
                      background: ${COLOR_VALUES.Gray_9};
                      cursor: pointer;
                  }

                  &:active {
                      border: 1px solid ${COLOR_VALUES.Black};
                      background: ${COLOR_VALUES.White};
                      cursor: pointer;
                  }
              `}
`;

const TagButtonIconWrapper = styled.div`
    padding: 0;
    border: none;
    background: transparent;
    margin-left: 10px;
    cursor: pointer;
`;

const TagButtonIcon = styled(Icon)`
    flex-shrink: 0;
`;

const Tag = React.forwardRef(
    (
        {
            label,
            handleButtonOnClick = void 0,
            onClick = void 0,
            size,
            isSelected,
            disabled,
            ..._rest
        },
        ref
    ) => {
        return (
            <>
                <TagWrapper
                    onClick={onClick}
                    size={size}
                    isSelected={isSelected}
                    disabled={disabled}
                    rest={_rest}
                    ref={ref}
                >
                    {label && <Text>{label}</Text>}
                    {isSelected && (
                        <TagButtonIconWrapper onClick={handleButtonOnClick}>
                            <TagButtonIcon name="close" />
                        </TagButtonIconWrapper>
                    )}
                </TagWrapper>
            </>
        );
    }
);

Tag.defaultProps = {
    size: "medium",
    isSelected: true,
    disabled: false,
};

Tag.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleButtonOnClick: PropTypes.func,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Tag;
