import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import GlobalConfig from "configs/Global";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import updateColumnsShowAttribute from "components/design-system/tables/updateColumnsShowAttribute";
import { pagerOptions } from "components/design-system/tables/Pager";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import ClientDrawer from "pages/admin/clients/ClientDrawer";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";
import DrawerContext from "context/Drawer/DrawerContext";
import { DropdownMultiSelectContext } from "context/DropdownMultiSelect/DropdownMultiSelectContext";
import ClientContext from "context/Admin/ClientContext";

import useAgencies from "pages/admin/agencies/useAgencies";
import {
    useClientsApi,
    useClientsFamilies,
} from "pages/admin/clients/useClients";
import useOffices from "pages/admin/offices/useOffices";

export const getCellWidthInPercent = (pxVal, tableWidth = 1312) => {
    let percentVal = (100 / tableWidth) * pxVal;
    percentVal = percentVal.toFixed(2);
    return `${percentVal}%`;
};

const columnNamesAccessor = {
    ID: "ClientId",
    Client: "Name",
    Short: "ShortName",
    ExtCode: "ExtAccountingCode",
    Agency: "LeadAgencyName",
    Family: "ClientFamily",
    Entity: "ClientCorporate",
    Office: "OfficeName",
    Internal: "ExcludeInternalOH",
    Admin: "IsAdmin",
    Publish: "Active",
};

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(80),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: columnNamesAccessor.ID,
    },
    {
        headCellProps: {
            text: "Name",
            minWidth: "189.33px",
        },

        accessor: columnNamesAccessor.Client,
    },
    {
        headCellProps: {
            text: "Short",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Short,
    },
    {
        headCellProps: {
            text: "Ext. Code",
            width: getCellWidthInPercent(110),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.ExtCode,
    },
    {
        headCellProps: {
            text: "Agency",
            width: getCellWidthInPercent(100),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Agency,
    },
    {
        headCellProps: {
            text: "Family",
            sbe: "Admin",
            minWidth: "189.33px",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Family,
        show: true,
    },
    {
        headCellProps: {
            text: "Entity",
            minWidth: "189.33px",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Entity,
        show: true,
    },
    {
        headCellProps: {
            text: "Office",
            width: getCellWidthInPercent(120),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Office,
        show: true,
    },
    {
        headCellProps: {
            text: "Internal",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: columnNamesAccessor.Internal,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Admin",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: columnNamesAccessor.Admin,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Publish",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: columnNamesAccessor.Publish,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(80),
        },
        accessor: "Edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            moreMenuActionProps: [
                {
                    text: "View Projects",
                    actionIcon: "link",
                    actionProps: {
                        to: `?action=viewProjects&id=${cell.row.original.ClientId}`,
                        replace: true,
                        "aria-label": "View Project",
                    },
                },
                {
                    text: "Manage",
                    actionIcon: "caret-right",
                    actionProps: {
                        to: `${GlobalConfig.routes.adminClientsLanding}/${cell.row.original.ClientId}/${GlobalConfig.routes.adminClientGroups}`,
                        replace: true,
                        "aria-label": "Manage Client",
                    },
                },
                {
                    text: "Edit",
                    actionIcon: "edit",
                    actionProps: {
                        to: `?action=edit&id=${cell.row.original.ClientId}`,
                        replace: true,
                        "aria-label": "Edit Client",
                    },
                },
            ],
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add Client",
    to: "?action=add",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const DropdownNames = {
    agency: "Agency",
    family: "Family",
    office: "Office",
};

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: columnNamesAccessor.ID,
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
    {
        showColumnFilter: true,
        name: "Columns",
    },
    {
        name: DropdownNames.agency,
        columnAccessor: columnNamesAccessor.Agency,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.agency,
    },
    {
        name: DropdownNames.family,
        columnAccessor: columnNamesAccessor.Family,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.family,
    },
    {
        name: DropdownNames.office,
        columnAccessor: columnNamesAccessor.Office,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.office,
    },
];

const multiSelectDropownOptionsArr = [
    { parent: "Main", children: ["Family", "Entity", "Office"] },
    { parent: "Attributes", children: ["Internal", "Admin", "Publish"] },
];

const ClientsTable = () => {
    const { queryPageIndex, queryPageSize, querySortBy, totalItemsUpdate } =
        useContext(ServerSidePaginationContext);

    const { setActiveInactiveState } = useContext(ClientContext);

    const { multiSelectDropdownOptionsList, setMultiSelectDropdownOptions } =
        useContext(DropdownMultiSelectContext);

    const [filters, setFilters] = useState();
    const [filtersConfigVal, setFiltersConfigVal] = useState(FiltersConfig);

    const [drawerClientId, setDrawerUserId] = useState();
    const { setShow } = useContext(DrawerContext);
    const [columnsNew, setColumnsNew] = useState(columns);

    const { agencies } = useAgencies();
    const { clientFamilies } = useClientsFamilies();
    const { offices } = useOffices();
    const [loadingFamilyFilterOptions, setLoadingFamilyFilterOptions] =
        useState(true);

    const { useClientsList } = useClientsApi();
    const showFilterVal = useRef();

    switch (filters?.find((f) => f.id === columnNamesAccessor.ID)?.value) {
        case undefined:
            showFilterVal.current = "";
            break;
        case "active":
            showFilterVal.current = true;
            break;
        case "inactive":
            showFilterVal.current = false;
            break;
        default:
            showFilterVal.current = true;
            break;
    }

    const clientsDataApi = useClientsList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: showFilterVal.current,
        officeRegionId:
            filters?.find((f) => f.id === columnNamesAccessor.Office)?.value ??
            "",
        clientFamilyId:
            filters?.find((f) => f.id === columnNamesAccessor.Family)?.value ??
            "",
        leadAgencyId:
            filters?.find((f) => f.id === columnNamesAccessor.Agency)?.value ??
            "",
    });

    useEffect(() => {
        if (!clientsDataApi.isLoading) {
            totalItemsUpdate(clientsDataApi?.data?.TotalItems);
        }
    }, [clientsDataApi?.data]);

    useEffect(() => {
        if (!clientsDataApi.isLoading) {
            setActiveInactiveState(showFilterVal.current);
        }
    }, [clientsDataApi?.data]);

    useEffect(() => {
        setMultiSelectDropdownOptions(multiSelectDropownOptionsArr);
    }, []);

    useEffect(() => {
        const i = columns.findIndex((f) => f.headCellProps.text === "Name");
        let newColArr = [...columnsNew];
        newColArr[i].getBodyCellProps = (cell) => ({
            text: cell.value ?? "-",
            actionIcon: "caret-right",
            actionProps: {
                onClick: () => handleDrawerClick(cell.row.original.ClientId),
            },
        });

        setColumnsNew(newColArr);
    }, []);

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const agencyIndex = newObj.findIndex(
            (f) => f.name === DropdownNames.agency
        );
        newObj[agencyIndex].options = !agencies.isLoading
            ? getDropdownOptions(
                agencies?.data?.Items,
                "Id",
                "Name",
                "all",
                "All"
            )
            : [];

        !agencies.isLoading &&
            newObj[agencyIndex].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    }, [agencies]);

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const familyIndex = newObj.findIndex(
            (f) => f.name === DropdownNames.family
        );
        newObj[familyIndex].options = !clientFamilies.isLoading
            ? getDropdownOptions(
                clientFamilies.data,
                "clientFamilyID",
                "Name",
                "all",
                "All"
            )
            : [];

        if (
            !clientFamilies.isLoading &&
            (clientFamilies?.data?.length <= 0 ||
                newObj[familyIndex]?.options?.length > 0)
        ) {
            setLoadingFamilyFilterOptions(false);
        }

        !clientFamilies.isLoading &&
            newObj[familyIndex]?.options?.length < 0 &&
            setFiltersConfigVal(newObj);
    }, [clientFamilies]);

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const officeIndex = newObj.findIndex(
            (f) => f.name === DropdownNames.office
        );
        newObj[officeIndex].options = !offices.isLoading
            ? getDropdownOptions(
                offices.data,
                "OfficeRegionId",
                "Name",
                "all",
                "All"
            )
            : [];
        !offices.isLoading &&
            newObj[officeIndex].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    }, [offices]);

    const handleDrawerClick = (clientId) => {
        setShow(true);
        setDrawerUserId(clientId);
    };

    const handleDrawerClose = () => {
        setShow(false);
        setDrawerUserId(null);
    };

    const handleOnChangeDropdownColumn = (selectedValues) => {
        let newObj = [...columnsNew];

        updateColumnsShowAttribute(
            columns,
            multiSelectDropownOptionsArr,
            newObj,
            selectedValues
        );

        setColumnsNew(newObj);
    };

    const memoizedTable = useMemo(
        () => (
            <>
                <Table
                    dataIsFetching={clientsDataApi.isFetching}
                    showTableBorder={true}
                    columns={columnsNew}
                    data={clientsDataApi?.data?.Items}
                    getRowProps={getRowProps}
                    paginationFromFE={false}
                    emptyActionProps={tableCTAProps}
                    pagerDisabledOptionsList={[pagerOptions[0]]}
                />
            </>
        ),
        [clientsDataApi, columnsNew]
    );

    if (
        clientsDataApi.isLoading ||
        agencies.isLoading ||
        clientFamilies.isLoading ||
        offices.isLoading ||
        loadingFamilyFilterOptions
    ) {
        return <>Loading...</>;
    }

    if (
        clientsDataApi.isError ||
        agencies.isError ||
        clientFamilies.isError ||
        offices.isError
    ) {
        return <>Error</>;
    }

    return (
        <>
            {drawerClientId && (
                <MasterDrawer
                    stickyAction={true}
                    onRequestClose={handleDrawerClose}
                >
                    <ClientDrawer clientId={drawerClientId} />
                </MasterDrawer>
            )}

            <VStack>
                <HStack align="end" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            initialFilterData={[
                                { name: "active", value: "active" },
                            ]}
                            filtersConfig={filtersConfigVal}
                            onChange={setFilters}
                            multiSelectDropdownOptionsList={
                                multiSelectDropdownOptionsList
                            }
                            handleOnChangeDropdownColumn={
                                handleOnChangeDropdownColumn
                            }
                        />
                    </HStack>
                    <SecondaryButton {...tableCTAProps} />
                </HStack>

                {memoizedTable}
            </VStack>
        </>
    );
};

export default React.memo(ClientsTable);
