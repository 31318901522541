import React, { useContext } from "react";
import { useDateFormatter } from "@react-aria/i18n";
import styled from "styled-components";
import { differenceInCalendarYears, getYear } from "date-fns";

import SessionContext from "context/app/SessionContext";
import useIncompleteTimesheetApi from "pages/timesheet/incomplete-timesheet/useIncompleteTimesheet";

import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import { DateButton } from "components/design-system/controls/datepicker/StyledElements";
import VStack from "components/design-system/layout/VStack";
import { SPACING_VALUES } from "components/design-system/config/spacing";

const StyledHStack = styled(HStack)`
    width: 100%;
    flex-wrap: wrap;
    gap: 3px;
    padding-left: 16px;
`;

const StyledTertiaryButton = styled(DateButton)`
    border-radius: 160px;
    width: 53px;
    height: 24px;
    marginright: 4px;
`;

const YearMonthVStack = styled(VStack)`
    gap: ${SPACING_VALUES.Zero_5};
`;

const WrapperVStack = styled(VStack)`
    gap: 24px;
    padding-top: 9px;
`;

const DatePickerDisplayMonthAndYear = ({
    state,
    setShowMonthAndYear,
    isWeekNavigator = false,
}) => {
    let months = [];
    let years = [];
    let maxYearDate = new Date();

    let dateInit = new Date();
    let minYearDate = isWeekNavigator
        ? dateInit.setFullYear(dateInit.getFullYear() - 1)
        : new Date(1995, 1, 1, 19, 0, 52);

    const session = useContext(SessionContext);
    const { useIncompleteTimesheet } = useIncompleteTimesheetApi();
    const incompleteTimesheetData = useIncompleteTimesheet(session.legacyId);

    const getIncompleteTimesheetDate = () => {
        let incompleteTimesheetsDays = [];
        if (!incompleteTimesheetData?.isLoading) {
            let stopDate = new Date();
            let firstDayOfCurrentMonth = new Date(
                stopDate.getFullYear(),
                stopDate.getMonth(),
                1
            );
            let minDate = new Date(
                firstDayOfCurrentMonth.setMonth(
                    firstDayOfCurrentMonth.getMonth() - 2
                )
            );
            const incompleteDays = incompleteTimesheetData?.data.map((x) =>
                new Date(x.date).getTime()
            );
            while (minDate <= stopDate) {
                let daysToAdd = 1;
                if (incompleteDays.some((x) => x === minDate.getTime())) {
                    daysToAdd = 7;
                } else {
                    let newMinDate = new Date(minDate);
                    incompleteTimesheetsDays.push(
                        newMinDate.setFullYear(newMinDate.getFullYear())
                    );
                }
                minDate.setDate(minDate.getDate() + daysToAdd);
            }
        }
        return incompleteTimesheetsDays;
    };

    const getIncompleteTimesheetYear = () => {
        const getMin = (a, b) => Math.min(a, b);
        const minYearIncompleteTimesheets =
            getIncompleteTimesheetDate().reduce(getMin);
        return minYearIncompleteTimesheets;
    };

    if (isWeekNavigator) {
        if (minYearDate > getIncompleteTimesheetYear()) {
            minYearDate = getIncompleteTimesheetYear();
        }
    }

    const formatter = useDateFormatter({
        month: "short",
        timeZone: state.timeZone,
    });

    const numMonths = state.focusedDate.calendar.getMonthsInYear(
        state.focusedDate
    );

    const updateYearArray = (i) => {
        let date = state.focusedDate.add({ years: i });

        years.push({
            value: date,
            formatted: formatter.format(date.toDate(state.timeZone)),
        });
    };

    if (
        getYear(new Date(state.focusedDate)) <= getYear(maxYearDate) &&
        getYear(new Date(state.focusedDate)) >= getYear(minYearDate)
    ) {
        let limit =
            differenceInCalendarYears(minYearDate, maxYearDate) +
            differenceInCalendarYears(maxYearDate, new Date(state.focusedDate));

        for (
            let i = differenceInCalendarYears(
                maxYearDate,
                new Date(state.focusedDate)
            );
            i >= limit;
            i--
        ) {
            updateYearArray(i);
        }
    } else if (getYear(new Date(state.focusedDate)) > getYear(maxYearDate)) {
        for (
            let i = 0;
            i >=
            differenceInCalendarYears(minYearDate, new Date(state.focusedDate));
            i--
        ) {
            updateYearArray(i);
        }
    } else if (getYear(new Date(state.focusedDate)) < getYear(minYearDate)) {
        for (
            let i = differenceInCalendarYears(
                maxYearDate,
                new Date(state.focusedDate)
            );
            i >= 0;
            i--
        ) {
            updateYearArray(i);
        }
    }

    for (let i = 1; i <= numMonths; i++) {
        let date = state.focusedDate.set({ month: i });
        months.push(formatter.format(date.toDate(state.timeZone)));
    }

    const onChange = (monthVal, yearVal) => {
        const date = state.focusedDate.set({
            month: Number(monthVal),
            year: Number(yearVal),
        });
        state.setFocusedDate(date);
        setShowMonthAndYear(false);
    };

    return (
        <WrapperVStack>
            {years.map((year, yearIndex) => (
                <YearMonthVStack key={yearIndex}>
                    <Text
                        variant="Descriptive_1_1"
                        color="Gray_3"
                        style={{ padding: "0 16px" }}
                    >
                        {year.value.year}
                    </Text>

                    <StyledHStack justify="space-between">
                        {months.map((month, i) => (
                            <StyledTertiaryButton
                                key={i}
                                isSelected={
                                    state?.focusedDate?.month === i + 1 &&
                                    state?.focusedDate?.year === year.value.year
                                }
                                isCurrent={
                                    state?.value?.month === i + 1 &&
                                    state?.value?.year === year.value.year
                                }
                                onClick={() => onChange(i + 1, year.value.year)}
                            >
                                {month}
                            </StyledTertiaryButton>
                        ))}
                    </StyledHStack>
                </YearMonthVStack>
            ))}
        </WrapperVStack>
    );
};

export default DatePickerDisplayMonthAndYear;
