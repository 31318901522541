import { FormProvider, useForm } from "react-hook-form";

const Form = ({
    onSubmit,
    defaultValues = {},
    mode = "onBlur",
    children,
    ...rest
}) => {
    const methods = useForm({
        defaultValues,
        mode,
    });

    return (
        <FormProvider {...methods}>
            <form
                noValidate
                {...rest}
                onSubmit={methods.handleSubmit(onSubmit)}
                onReset={() => {
                    methods.reset();
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default Form;
