import { unset } from "lodash";
import moment from "moment";
import env from "react-dotenv";

function getRepresentationsOfError(error) {
    const errorRepresentations = [];

    if (typeof error === "object") {
        unset(error, "params.headers.Authorization");
    }

    if (typeof error === "object" && error.reason) {
        errorRepresentations.push({
            name: "Reason",
            value: error.reason,
        });
    }

    if (typeof error === "object" && error.message) {
        errorRepresentations.push({
            name: "Message",
            value: error.message,
        });
    }

    if (typeof error === "object" && error.stack) {
        errorRepresentations.push({
            name: "Stack",
            value: error.stack,
        });
    }

    try {
        errorRepresentations.push({
            name: "As JSON",
            value: JSON.stringify(error),
        });
    } catch {}

    errorRepresentations.push({
        name: "As String",
        value: error.toString(),
    });

    return errorRepresentations;
}

export const useEmailError = () => {
    const sendEmail = (error) => {
        if (error) {
            const regexLink = /https?:\/\//;
            const linkReplace = "";

            let formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a Z");
            if (error.date) {
                formattedDate =
                    error.date &&
                    moment(error.date).format("MMMM Do YYYY, h:mm:ss a Z");
            }

            const mailAddress = env.SUPPORT_CONTACT;
            const mailSubject = `[${env.APPLICATION_NAME}][${env.ENVIRONMENT_NAME}][Error] - New user error`;
            const mailBody = `Please add a description below of the action you were doing when the error occured (please indicate the steps taken before the error):
            %0D%0A
            %0D%0A
            %0D%0A
            %0D%0A
            %0D%0A
            %0D%0A
            %0D%0A
            %0D%0A ********* NOT DELETE THIS INFORMATION *********
            %0D%0A Error code: ${
                error.status ? error.status : "No server code error"
            }
            %0D%0ATransaction ID: ${
                error.correlationId
                    ? error.correlationId
                    : "No server transaction"
            }
            %0D%0ADate: ${formattedDate}
            %0D%0A
            %0D%0ASource: ${env.BESTWORK_REACT_DOMAIN.replace(
                regexLink,
                linkReplace
            )}
            %0D%0AError: ${getRepresentationsOfError(error)
                .map(
                    (representation) =>
                        `%0D%0A-%20${representation.name}: ${representation.value}`
                )
                .join("")}
            %0D%0A ********* NOT DELETE THIS INFORMATION *********
            `;
            const mail = `mailto:${mailAddress}?subject=${mailSubject}&body=${mailBody}`;
            window.location.href = mail;
        }
    };
    return { sendEmail };
};
