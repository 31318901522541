import styled, { css } from "styled-components";
import { COLOR_VALUES } from "components/design-system/config/colors";
import VStack from "components/design-system/layout/VStack";
import Modal from "react-modal";
import "styles/MasterReactModal.css"

Modal.setAppElement("#root");

export const CustomStyles = {
    overlay: {
        background: "rgba(63, 67, 77, 0.5)",
        overflowY: "auto",
        position: "fixed",
        zIndex: 3000,
    },
};

export const StyledIcon = styled.button`
    position: absolute;
    border: none;
    background: transparent;
    top: 24px;
    right: 32px;
    padding: 0;
`;

export const StyledHeader = styled(VStack)`
    padding: 24px 32px 32px;
    height: auto;
    top: 0;
    background: ${COLOR_VALUES.Gray_8};
    padding-top: 48px;
    > div {
        word-break: break-all;
        order: 1;
    }
`;

export const StyledForm = styled(VStack)`
    background: ${COLOR_VALUES.White};
    padding: 32px;
    overflow-y: auto;
    flex: 1;
`;

export const StyledModal = styled(Modal)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${(props) => props.width}px;
    left: calc(50% - ${(props) => props.width}px / 2);
    top: 10vh;
    box-shadow: 0px 0px 32px rgba(63, 67, 77, 0.1);
    background: ${COLOR_VALUES.White};
    max-height: 80vh;
    z-index: ${(props) => props.zIndex};

    ${StyledHeader} {
        > div {
            width: ${(props) => props.width - 64}px;
        }
    }

    ${(props) =>
        props.stickyAction &&
        css`
            ${StyledAction} {
                box-shadow: 0px -6px 24px rgba(63, 67, 77, 0.1);
                background: white;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 16px 32px 32px;
            }

            ${StyledForm} {
                margin-bottom: 112px;
            }
        `}
`;

export const StyledAction = styled.div``;
