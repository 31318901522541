import PropTypes from "prop-types";

const Filter = ({ size, _color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5714 4.92871H0V6.21443H11.5714V4.92871Z"
                fill="black"
            />
            <path
                d="M17.9999 11.3572H6.42847V12.6429H17.9999V11.3572Z"
                fill="black"
            />
            <path
                d="M15.4278 4.28571C16.1221 4.28571 16.6813 4.85786 16.6813 5.55857C16.6813 6.25929 16.1221 6.83143 15.4278 6.83143C14.7399 6.83143 14.1742 6.25929 14.1742 5.55857C14.1742 4.85786 14.7335 4.28571 15.4278 4.28571ZM15.4278 3C14.0263 3 12.8885 4.14429 12.8885 5.55857C12.8885 6.96643 14.0263 8.11714 15.4278 8.11714C16.8292 8.11714 17.9671 6.97286 17.9671 5.55857C17.9671 4.14429 16.8292 3 15.4278 3Z"
                fill="black"
            />
            <path
                d="M2.5709 10.7276C3.26519 10.7276 3.82447 11.2998 3.82447 12.0005C3.82447 12.7012 3.26519 13.2733 2.5709 13.2733C1.88304 13.2733 1.31733 12.7012 1.31733 12.0005C1.31733 11.2998 1.87662 10.7276 2.5709 10.7276ZM2.5709 9.44189C1.16947 9.44189 0.0316162 10.5862 0.0316162 12.0005C0.0316162 13.4083 1.16947 14.559 2.5709 14.559C3.97233 14.559 5.11019 13.4148 5.11019 12.0005C5.11019 10.5926 3.97233 9.44189 2.5709 9.44189Z"
                fill="black"
            />
        </svg>
    );
};

Filter.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

Filter.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Filter;
