import { useFormContext, useController } from "react-hook-form";
import { useUID } from "react-uid";

import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import { useEffect } from "react";

const defaultFeedbackMessages = {
    required: "This field is required.",
};

const getFeedbackProps = (fieldErrors) => {
    const feedbackType = fieldErrors
        ? FEEDBACK_TYPES.Error
        : FEEDBACK_TYPES.None;

    const feedbackMessage =
        fieldErrors &&
        (fieldErrors.message || defaultFeedbackMessages[fieldErrors.type]);

    return {
        feedbackType,
        feedbackMessage,
    };
};

const useFormInput = ({ name, validations, isControlled = false }) => {
    const {
        register,
        formState: { errors },
        setFocus,
    } = useFormContext();

    const { field } = useController({
        name,
        rules: validations,
        defaultValue: "",
        shouldUnregister: true,
    });

    const id = useUID();
    const labelId = id + "_label";
    const fieldId = id + "_field";

    const fieldErrors = errors[name];
    const { feedbackType, feedbackMessage } = getFeedbackProps(fieldErrors);

    useEffect(() => {
        const firstError = Object.keys(errors).reduce((fields, a) => {
            return !!errors[fields] ? fields : a;
        }, null);
        if (firstError) {
            if (typeof firstError.focus === "function") {
                setFocus(firstError);
            } else {
                
            }
        }
    }, [errors, setFocus]);

    return {
        wrapperProps: {
            feedbackType,
            feedbackMessage,
            id: labelId,
            htmlFor: fieldId,
        },
        inputProps: {
            ...(isControlled ? field : register(name, validations)),
            feedbackType,
            id: fieldId,
            "aria-labelledby": labelId,
        },
    };
};

export { useFormInput, getFeedbackProps };
