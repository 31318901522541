import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router";

import Options from "components/design-system/forms/Options";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import TextInput from "components/design-system/forms/TextInput";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useClientLOBs from "pages/admin/clients/single/useClientLOBs";

const attributesOptions = [{ label: "Active", value: "Active" }];

const ModalCTA = ({ isEditing, isLoading, onClose, handleOnClickDeleteLOB }) => {
    const { isValid } = useFormState();
    const formData = useWatch();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteLOB(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformLOBDataToFormData(clientLOBData = {}) {
    return {
        name: clientLOBData.GroupName,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "Active"
                    ? clientLOBData[item] ?? true
                    : clientLOBData[item]
            )
            .join(","),
    };
}

function transformFormDataToLOBData(
    formData = {},
    LOBData = {},
    clientId = null,
    ClientLOBId = undefined
) {
    const newLOBData = { ...LOBData };
    newLOBData.Id = ClientLOBId;
    // newLOBData.ClientLOBId = ClientLOBId;

    newLOBData.Name = formData.name;
    // newLOBData.GroupName = formData.name;

    attributesOptions.forEach((attribute) => {
        newLOBData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });

    newLOBData.ParentId = clientId;
    // newLOBData.LOBParentId = clientId;

    newLOBData.ClientId = clientId;

    return newLOBData;
}

const ClientGroupsLOBForm = ({ clientLOBIdToEdit, onClose }) => {
    const { clientId } = useParams();

    const { clientLOBs, createClientLOB, updateClientLOB, deleteClientLOB } = useClientLOBs();

    if (clientLOBs.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientLOBs.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof clientLOBIdToEdit !== "undefined";
    const modifier = isEditing ? updateClientLOB : createClientLOB;

    const currentClientLOBData = isEditing
        ? clientLOBs?.data.find(
              (clientLOB) => clientLOB.clientGroupID === clientLOBIdToEdit
          )
        : "";

    const clientLOBsDataExceptCurrent = clientLOBs?.data.filter(
        (clientLOB) => clientLOB.clientGroupID !== clientLOBIdToEdit
    );

    if (isEditing && !currentClientLOBData) {
        return <div>Could not find line of business</div>;
    }

    const handleOnClickDeleteLOB = (prevData) => {
        // keeping active true for undo
        const restoreData = {
            ...prevData,
            attributes: attributesOptions[0].value,
        };

        deleteClientLOB
            .mutateAsync({ clientLOBId: clientLOBIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={currentClientLOBData.GroupName}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    true,
                    {
                        label: "Undo",
                        onClick: () => onSubmit(restoreData),
                    }
                );
            });
    };

    const onSubmit = (formData) => {
        const clientLOBsData = transformFormDataToLOBData(
            formData,
            currentClientLOBData,
            clientId,
            clientLOBIdToEdit
        );

        const allData = {
            clientLOBId: clientLOBIdToEdit,
            body: clientLOBsData,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={
                        isEditing
                            ? currentClientLOBData.GroupName
                            : clientLOBsData.Name
                    }
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformLOBDataToFormData(currentClientLOBData)}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <TextInput
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="LOB Name"
                        validations={{
                            required: true,
                            validate: (value) =>
                                clientLOBsDataExceptCurrent?.filter(
                                    (clientLOB) =>
                                        clientLOB?.GroupName?.toLowerCase() ===
                                        value.toLowerCase()
                                ).length === 0 || "Name is already exist",
                        }}
                    />
                    <Options name="attributes" options={attributesOptions} />
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteLOB={handleOnClickDeleteLOB}
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default ClientGroupsLOBForm;
