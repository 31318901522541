import { useMemo, useCallback, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";

const useAllocationsNoteUpdate = ({
    employeeId,
    jobId,
    titleId,
    officeId,
    date,
    mode,
    isJobScope,
    isNewNote = true,
}) => {
    const { postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const noteExists = useRef(!isNewNote);

    const queryKey = useMemo(
        () => [
            "allocationsNote",
            employeeId,
            jobId,
            titleId,
            officeId,
            date,
            mode,
            isJobScope,
        ],
        [date, employeeId, isJobScope, mode, officeId, jobId, titleId]
    );

    const saveNoteApiCall = useCallback(
        ({ note = "", days = [], newDate = null  }) => {
            const body = {
                date: newDate == null ? date : newDate,
                mode,
                note,
                days: Array.isArray(days) ? days : [],
                isJobScope,
            };

            const urlParams = [
                { name: "$userid", value: employeeId },
                { name: "$jobid", value: jobId },
                { name: "$titleid", value: titleId },
                { name: "$officeid", value: officeId },
            ];

            return postData("saveNote", {
                body: body,
                urlParams: urlParams,
            });
        },
        [
            date,
            employeeId,
            isJobScope,
            mode,
            officeId,
            postData,
            jobId,
            titleId,
        ]
    );

    const deleteNoteApiCall = useCallback(() => {
        const urlParams = [
            { name: "$userid", value: employeeId },
            { name: "$jobid", value: jobId },
            { name: "$titleid", value: titleId },
            { name: "$officeid", value: officeId },
        ];

        const queryParams = {
            dateFor: date,
            mode,
            isJobScope,
        };

        return deleteData("deleteNote", {
            urlParams: urlParams,
            params: queryParams,
        });
    }, [
        date,
        deleteData,
        employeeId,
        isJobScope,
        mode,
        officeId,
        jobId,
        titleId,
    ]);

    const saveNote = useMutation(saveNoteApiCall, {
        onSuccess: () => {
            noteExists.current = true;
            queryClient.invalidateQueries(queryKey);
        },
    });
    const deleteNote = useMutation(deleteNoteApiCall, {
        onSuccess: () => {
            noteExists.current = false;
            queryClient.invalidateQueries(queryKey);
        },
    });

    return useMemo(() => {
        return {
            saveNote,
            deleteNote,
        };
    }, [deleteNote, saveNote]);
};

export default useAllocationsNoteUpdate;
