import { useEffect, useState, useRef } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import Text from "components/UI/Text";
import SelectMenu from "components/UI/SelectMenu";
import CustomFilterModal from "components/Modal/Allocations/CustomFilter";
import Transform from "utilities/Transform";
import Disk from "components/Icon/Disk";

import { useCustomFilters } from "utilities/API/CustomFilters";
import { MenuItem as MoreMenuItem } from "@szhsin/react-menu";
import {
    Subtitle,
    SavedContainer,
    SaveWrapper,
    SelectWrapper,
} from "../Filter-Styles";
import IconButton from "components/UI/IconButton";
import Delete from "components/Icon/Delete";
import File from "components/Icon/File";
import { useStylesForMenu } from "styles/MakeStyles";
import Objects from "utilities/Objects";
import Icon from "components/design-system/ui/Icon";

let renderCount = 1;

const SavedFilters = ({
    selectedFilters,
    onChange,
    onClear,
    selectedCustomFilter,
    onFilterCreated,
}) => {
    const customFilterRef = useRef();
    const classes = useStylesForMenu();
    const [savedFilters, setSavedFilters] = useState([]);
    const { getCustomFilter } = useCustomFilters();

    const [reloadSavedFilters, setReloadSavedFilters] = useState(false);

    useEffect(() => {
        async function init() {
            initFilters(selectedCustomFilter);
        }

        init();
    }, [selectedCustomFilter]);

    const initFilters = async (filterId) => {
        if (savedFilters && savedFilters.length > 0) {
            let filtersCopy = [...savedFilters].map((item) => ({
                ...item,
                default: item.id == filterId,
            }));

            setSavedFilters(filtersCopy);
        } else {
            getSavedFilters(filterId);
        }
    };

    const getSavedFilters = async (filterId = null) => {
        getCustomFilter().then((payload) => {
            const trasnformedData = Transform.customFilters(payload, filterId);
            const data = changeClientFilterToArray(trasnformedData);
            setSavedFilters(data);
        });
    };

    const changeClientFilterToArray = (data) => {
        data.forEach((filter) => {
            if (
                filter.data?.clientFamily &&
                !Array.isArray(filter.data.clientFamily)
            ) {
                filter.data.clientFamily = [filter.data.clientFamily];
            }
            if (filter.data?.client && !Array.isArray(filter.data.client)) {
                filter.data.client = [filter.data.client];
            }
        });
        return data;
    };

    const handleCustomFilterCreated = (filter) => {
        let filtersCopy = [...savedFilters].map((item) => ({
            ...item,
            default: false,
        }));

        const customFilter = {
            id: filter.Id,
            name: filter.Name,
            data: Objects.parseJson(filter.Value),
            default: true,
        };

        filtersCopy.push(customFilter);
        setSavedFilters(filtersCopy);
        onFilterCreated(customFilter.id);
    };

    const handleCustomFilterUpdated = (filter) => {
        const index = savedFilters.findIndex((item) => item.id === filter.id);
        if (index !== -1) {
            let filtersCopy = [...savedFilters];
            filtersCopy[index].name = filter.name;
            setSavedFilters(filtersCopy);
        }
    };

    const handleCustomFilterDeleted = (filterId) => {
        let filtersCopy = [...savedFilters].filter(
            (item) => item.id !== filterId
        );
        setSavedFilters(filtersCopy);
    };

    const onClearSavedFilter = async () => {
        setReloadSavedFilters(true);
        onClear();
    };

    const onChangeSavedFilter = async (filter) => {
        onChange(filter);
    };

    const showSaveFilterSection = (event) => {
        customFilterRef.current.add(selectedFilters);
    };

    return (
        <>
            <Subtitle>
                <Text variant="xsmall">Saved Filters</Text>
            </Subtitle>
            <SavedContainer>
                <SelectWrapper lg={10}>
                    <SelectMenu
                        key={0}
                        label="Select Saved Filters"
                        items={savedFilters}
                        itemAction={(filter) => onChangeSavedFilter(filter)}
                        clearAction={() => onClearSavedFilter()}
                        reload={reloadSavedFilters}
                        border
                        triggerLength="100%"
                        moreMenuOptions={[
                            <MoreMenuItem
                                key={0}
                                onClick={(e) =>
                                    customFilterRef.current.rename(
                                        e.value.id,
                                        e.value.name
                                    )
                                }
                            >
                                <Icon name="edit" />
                                Rename
                            </MoreMenuItem>,
                            <MoreMenuItem
                                key={1}
                                onClick={(e) =>
                                    customFilterRef.current.delete(
                                        e.value.id,
                                        e.value.name
                                    )
                                }
                            >
                                <Delete size="16" />
                                Delete
                            </MoreMenuItem>,
                        ]}
                    />
                </SelectWrapper>
                <CustomFilterModal
                    ref={customFilterRef}
                    onCreate={(filter) => handleCustomFilterCreated(filter)}
                    onUpdate={(filter) => handleCustomFilterUpdated(filter)}
                    onDelete={(filterId) => handleCustomFilterDeleted(filterId)}
                />
                <SaveWrapper>
                    <IconButton
                        icon={<Disk />}
                        onClick={(event) => showSaveFilterSection(event)}
                        type="button"
                        className={classes.diskButton}
                    />
                </SaveWrapper>
            </SavedContainer>
        </>
    );
};
SavedFilters.propTypes = {};

export default SavedFilters;
