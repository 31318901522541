import { useMemo, useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";
import SessionContext from "context/app/SessionContext";

const useSelectorRetainers = () => {
    const { getDataAsync } = useApi();
    const session = useContext(SessionContext);

    const getSelectorRetainersApiCall = useCallback(async () => {
        const response = await getDataAsync("selectorRetainers", {
            urlParams: [
                { name: "$userId", value: session.id },
                { name: "$isAdmin", value: session.isLegacyAdminUser },
                { name: "$agencyId", value: session.agencyId },
            ],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const selectorRetainers = useQuery(
        "selectorRetainersList",
        getSelectorRetainersApiCall
    );

    return useMemo(() => {
        return {
            selectorRetainers,
        };
    }, [selectorRetainers]);
};

export default useSelectorRetainers;
