import Text from "components/UI/Text";
import styled from "styled-components";
import { useStylesForText } from "styles/MakeStyles";
import moment from "moment";
let renderCount = 1;

const DateWrapper = styled.div`
    min-width: 3.5rem;
    padding-left: 0;
    display: inline-block;
`;

const DayWrapper = styled.div`
    min-width: 2.5rem;
    display: inline-block;
`;

const ReasonWrapper = styled.div`
    display: inline-block;
`;

const AbsenceWrapper = styled.div`
    padding-top: 1.243rem;
`;

const medium_sizeMediumStyle = {
    fontFamily: global.config.fonts.families.medium,
    fontSize: global.config.fonts.sizes.medium,
};

const medium_sizeSmediumStyle = {
    fontFamily: global.config.fonts.families.medium,
    fontSize: global.config.fonts.sizes.smedium,
};

const regular_sizeSmediumStyle = {
    fontFamily: global.config.fonts.families.regular,
    fontSize: global.config.fonts.sizes.smedium,
};

const TimeOffOverlay = (props) => {
    const { week, timeOffData, employeeData, allocatedHours, availableHours } =
        props;

    const classesText = useStylesForText();

    const timeOffDescription = week.map((d, index) => (
        <div key={index}>
            <DateWrapper>
                <Text
                    style={regular_sizeSmediumStyle}
                    variant
                    className={classesText.white}
                >
                    {moment(d).format("MMM") + " " + moment(d).format("D")}
                </Text>
            </DateWrapper>
            <DayWrapper>
                <Text
                    style={medium_sizeSmediumStyle}
                    variant
                    className={classesText.white}
                >
                    {moment(d).format("ddd")}
                </Text>
            </DayWrapper>
            <ReasonWrapper>
                <Text
                    style={regular_sizeSmediumStyle}
                    variant
                    className={classesText.white}
                >
                    {timeOffData &&
                    timeOffData.length &&
                    timeOffData.find(
                        (t) => moment(t.date).format("YYYY-MM-DD") === d
                    ) ? (
                        timeOffData.find(
                            (t) => moment(t.date).format("YYYY-MM-DD") === d
                        ).reason
                    ) : (
                        <span>&mdash;</span>
                    )}
                </Text>
            </ReasonWrapper>
        </div>
    ));

    return (
        <>
            <Text
                style={medium_sizeMediumStyle}
                variant
                className={classesText.white}
            >
                {" "}
                {employeeData.name}
            </Text>
            <Text
                style={medium_sizeSmediumStyle}
                variant
                className={classesText.white}
            >
                {" "}
                Hrs Allocated: {allocatedHours} |
                <span
                    style={regular_sizeSmediumStyle}
                    className={classesText.white}
                >
                    {" "}
                    Hrs Available: {availableHours}{" "}
                </span>
            </Text>

            <AbsenceWrapper>{timeOffDescription}</AbsenceWrapper>
        </>
    );
};

export default TimeOffOverlay;
