import React from "react";
import Text from "./Text";
import styled from "styled-components";
import Loader from "components/UI/Loader";

const TextWrapper = styled.div`
    padding: 0.5rem;
`;

const LoaderWrapper = styled.div`
    padding: 1rem;
    text-align: center;
`;

const Wrapper = styled.div`
    display: grid;
    justify-content: center;
`;

const Loading = ({ text }) => {
    return (
        <Wrapper>
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
            {text && (
                <TextWrapper>
                    <Text variant="smallLight">{text}</Text>
                </TextWrapper>
            )}
        </Wrapper>
    );
};

export default Loading;
