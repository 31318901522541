import PropTypes from "prop-types";
import styled from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import BaseButton from "components/design-system/controls/button/BaseButton";
import Tooltip from "components/design-system/ui/Tooltip";

const StyledMenuItemButton = styled(BaseButton)`
    color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

const MenuItemButton = ({ label, leadingIcon, trailingIcon, ...rest }) => {
    const ariaLabel = rest["aria-label"];

    if (!label && !ariaLabel) {
        console.error("Icon-only button missing aria-label");
    }

    return (
        <Tooltip text={ariaLabel} enabled={!label}>
            <StyledMenuItemButton $reducePadding={!label} {...rest}>
                {leadingIcon && <Icon name={leadingIcon} />}
                {label && <Text variant="Body_2_2">{label}</Text>}
                {trailingIcon && <Icon name={trailingIcon} />}
            </StyledMenuItemButton>
        </Tooltip>
    );
};

MenuItemButton.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    leadingIcon: PropTypes.oneOf(ICON_NAMES),
    trailingIcon: PropTypes.oneOf(ICON_NAMES),
    "aria-label": PropTypes.string,
    disabled: PropTypes.bool,
};

export default MenuItemButton;
