import { getMinutesToMilliseconds } from "common-methods/commonMethods";
import { TIME_INTERVAL_IN_MIN } from "context/Projects/RetainersDashboardLayoutContext";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useApi } from "utilities/API/Helpers/useApi";

export const useRetainersNoteTypes = () => {
    const { getDataAsync } = useApi();

    const getRetainersNoteTypesApiCall = useCallback(async () => {
        const response = await getDataAsync("retainersNoteTypes");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const retainersNoteTypes = useQuery(
        "retainersNoteTypesList",
        getRetainersNoteTypesApiCall
    );

    return useMemo(() => {
        return {
            retainersNoteTypes,
        };
    }, [retainersNoteTypes]);
};

const useRetainersDashboardNotes = () => {
    const { getDataAsync, postData, putData, deleteData } = useApi();
    const { retainerId } = useParams();
    const queryClient = useQueryClient();
    const refetchIntervalValue = getMinutesToMilliseconds(TIME_INTERVAL_IN_MIN); // 5min i.e. 300000ms

    const getRetainersApiCall = useCallback(
        async ({ retainerIdFE = "", noteTypeId = "" }) => {
            const response = await getDataAsync("retainersDashboardNotesList", {
                urlParams: [
                    { name: "$retainerId", value: retainerIdFE },
                    { name: "$noteTypeId", value: noteTypeId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createRetainerDashboardNoteApiCall = useCallback(
        ({ body }) => {
            return postData("postRetainersDashboardNote", {
                urlParams: [{ name: "$retainerId", value: retainerId }],
                body,
            });
        },
        [postData]
    );

    const updateRetainerDashboardNoteApiCall = useCallback(
        ({ noteId, body }) => {
            return putData("updateRetainersDashboardNote", {
                urlParams: [
                    {
                        name: "$retainerId",
                        value: retainerId,
                    },
                    {
                        name: "$noteId",
                        value: noteId,
                    },
                ],
                body,
            });
        },
        [putData]
    );

    const deleteRetainerDashboardNoteApiCall = useCallback(
        ({ noteId }) => {
            return deleteData("updateRetainersDashboardNote", {
                urlParams: [
                    {
                        name: "$retainerId",
                        value: retainerId,
                    },
                    {
                        name: "$noteId",
                        value: noteId,
                    },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("retainersNotesList");
            },
        };
    }, [queryClient]);

    const createRetainerDashboardNote = useMutation(
        createRetainerDashboardNoteApiCall,
        mutationOptions
    );
    const updateRetainerDashboardNote = useMutation(
        updateRetainerDashboardNoteApiCall,
        mutationOptions
    );
    const deleteRetainerDashboardNote = useMutation(
        deleteRetainerDashboardNoteApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useRetainersNotesList = (dataObj) =>
            useQuery(
                ["retainersNotesList", dataObj],
                () => getRetainersApiCall(dataObj),
                {
                    refetchInterval: refetchIntervalValue,
                }
            );

        return {
            useRetainersNotesList,
            createRetainerDashboardNote,
            updateRetainerDashboardNote,
            deleteRetainerDashboardNote,
        };
    }, [
        getRetainersApiCall,
        createRetainerDashboardNote,
        updateRetainerDashboardNote,
        deleteRetainerDashboardNote,
    ]);
};

export default useRetainersDashboardNotes;
