import React from "react";

import GlobalConfig from "configs/Global";
import Text from "components/design-system/ui/Text";
import Table from "components/design-system/tables/Table";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import { endpointPathText } from "pages/projects/retainers-dashboard/single/RetainersDashboardOverviewData";
import Loading from "components/UI/Loading";
import { getMoneyColumnVal } from "common-methods/commonMethods";

import useRetainersDashboardData from "pages/projects/retainers-dashboard/single/useRetainersDashboardData";
import { useFixedRowsMoney } from "hooks/Projects/useFixedRows";

const columnNamesAccessor = {
    Num: "JobCode",
    Name: "JobName",
    TotalFee: "TotalFee",
    Baseline: "Baseline",
    AtCompletion: "AtCompletion",
    Used: "Used",
    Variance: "Variance",
};

const tableTotalWidth = 1088;
const columns = [
    {
        headCellProps: {
            text: "Num.",
            width: getCellWidthInPercent(94, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: columnNamesAccessor.Num,
    },
    {
        headCellProps: {
            text: "Project",
            width: getCellWidthInPercent(280, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            actionIcon: cell.value ? "caret-right" : null,
            actionProps: {
                to: `${GlobalConfig.routes.projectsDashboardLanding}/${cell.row.original.JobId}/overview`,
                "aria-label": "Project",
            },
        }),
        accessor: columnNamesAccessor.Name,
    },
    {
        headCellProps: {
            text: "Total Fee",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(cell.row.original.Currency, cell.value)
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.TotalFee,
    },
    {
        headCellProps: {
            text: "Budget Baseline",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(cell.row.original.Currency, cell.value)
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Baseline,
    },
    {
        headCellProps: {
            text: `Est. at \nCompletion`,
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(cell.row.original.Currency, cell.value)
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.AtCompletion,
    },
    {
        headCellProps: {
            text: "Used to Date",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(cell.row.original.Currency, cell.value)
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Used,
    },
    {
        headCellProps: {
            text: "Variance",
            alignedRight: true,
            width: getCellWidthInPercent(136, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: cell.value
                ? getMoneyColumnVal(cell.row.original.Currency, cell.value)
                : "-",
            textColor: cell.value < 0 ? COLOR_VALUES.Orange_1 : null,
            justifyContent: "end",
        }),
        accessor: columnNamesAccessor.Variance,
    },
    {
        headCellProps: {
            text: "",
            width: getCellWidthInPercent(32, tableTotalWidth),
        },
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            text: "",
        }),
        accessor: "JobActive",
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.JobActive === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const totalsStrColmAtStart = 2;

const totalOneColumns = [
    columnNamesAccessor.TotalFee,
    columnNamesAccessor.Baseline,
    columnNamesAccessor.AtCompletion,
    columnNamesAccessor.Used,
    columnNamesAccessor.Variance,
];

const getInitialDataForTotalRow = () => {
    return [
        { column: "JobCode", text: "" },
        { column: "JobName", text: "" },
        { column: totalOneColumns[0], text: 0, alignedRight: true },
        { column: totalOneColumns[1], text: 0, alignedRight: true },
        { column: totalOneColumns[2], text: 0, alignedRight: true },
        { column: totalOneColumns[3], text: 0, alignedRight: true },
        { column: totalOneColumns[4], text: 0, alignedRight: true },
        { column: "None", text: "" },
    ];
};

const RetainersDashboardOverviewProject = () => {
    const subJobsListDL = useRetainersDashboardData("subjobs/list");
    const currencyDL = useRetainersDashboardData(endpointPathText.currency);

    const [totalMoney] = useFixedRowsMoney({
        initialData: getInitialDataForTotalRow(),
        summary: subJobsListDL?.list,
        currency: currencyDL?.list,
        totalsStrColmAtStart: totalsStrColmAtStart,
        totalColumns: totalOneColumns,
    });

    if (subJobsListDL.list.isError || currencyDL?.list?.isError) {
        return <>Error</>;
    }

    return (
        <>
            <Text
                variant={"Headline_4"}
                color={"Gray_1"}
                style={{ paddingBottom: "16px" }}
            >                
                Retainer Sub&#45;Jobs
            </Text>

            {subJobsListDL.list.isFetching || currencyDL?.list?.isFetching ? (
                <Loading />
            ) : (
                <Table
                    grayBgHeader={true}
                    showTableBorder={false}
                    columns={columns}
                    fixedHeadingRows={[
                        {
                            bgColor: COLOR_VALUES.Gray_7,
                            variant: "Headline_5",
                            borderBottom: false,
                            data: totalMoney,
                        },
                    ]}
                    data={subJobsListDL.list.data}
                    getRowProps={getRowProps}
                />
            )}
        </>
    );
};

export default RetainersDashboardOverviewProject;
