import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";
import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

export const useClientsFamilies = () => {
    const { getDataAsync } = useApi();

    const getClientsFamilyApiCall = useCallback(async () => {
        const response = await getDataAsync("getCompanies");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientFamilies = useQuery(
        "clientFamiliesList",
        getClientsFamilyApiCall
    );

    return useMemo(() => {
        return {
            clientFamilies,
        };
    }, [clientFamilies]);
};

export const useClientsApi = () => {
    const { getDataAsync, postData, putData } = useApi();
    const queryClient = useQueryClient();

    const getClientsApiCall = useCallback(
        async ({
            sortBy = "",
            pageSize = PAGER_INITIAL_PAGE_SIZE,
            pageIndex = 0,
            active = "",
            officeRegionId = "",
            clientFamilyId = "",
            leadAgencyId = "",
        }) => {
            const response = await getDataAsync("clients", {
                urlParams: [
                    { name: "$active", value: active },
                    { name: "$sortBy", value: sortBy },
                    { name: "$pageSize", value: pageSize },
                    { name: "$pageNumber", value: pageIndex + 1 },
                    { name: "$officeRegionId", value: officeRegionId },
                    { name: "$clientFamilyId", value: clientFamilyId },
                    { name: "$leadAgencyId", value: leadAgencyId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getClientsByIdApiCall = useCallback(
        async (clientId) => {
            const response = await getDataAsync("getClientsById", {
                urlParams: [{ name: "$clientId", value: clientId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createClientApiCall = useCallback(
        (body) => {
            return postData("createClients", {
                body,
            });
        },
        [postData]
    );

    const updateClientApiCall = useCallback(
        (body) => {
            return putData("updateClients", {
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientsList");
            },
        };
    }, [queryClient]);

    const createClient = useMutation(createClientApiCall, mutationOptions);
    const updateClient = useMutation(updateClientApiCall, mutationOptions);

    return useMemo(() => {
        const useClientsList = (dataObj) =>
            useQuery(
                ["clientsList", dataObj],
                () => getClientsApiCall(dataObj),
                { keepPreviousData: true, staleTime: Infinity }
            );

        const useClientById = (clientId) =>
            useQuery(["getClientsById", clientId], () =>
                getClientsByIdApiCall(clientId)
            );

        return {
            useClientsList,
            useClientById,
            createClient,
            updateClient,
        };
    }, [getClientsApiCall, getClientsByIdApiCall, createClient, updateClient]);
};

export const useCorporates = () => {
    const { getDataAsync } = useApi();

    const getCorporatesApiCall = useCallback(async () => {
        const response = await getDataAsync("corporates");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const corporates = useQuery("corporatesList", getCorporatesApiCall);

    return useMemo(() => {
        return {
            corporates,
        };
    }, [corporates]);
};

export const useCorporateByFamilyIdApi = () => {
    const { getDataAsync } = useApi();

    const getCorporateByFamilyIdApiCall = useCallback(
        async ({ familyId }) => {
            const response = await getDataAsync(
                isNaN(familyId) || familyId == ""
                    ? "corporates"
                    : "getCorporateByFamilyId",
                {
                    urlParams: [{ name: "$familyId", value: familyId }],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useCorporateByFamilyId = (dataObj) =>
            useQuery(["corporatesList", dataObj], () =>
            getCorporateByFamilyIdApiCall(dataObj)
            );
        return {
            useCorporateByFamilyId,
        };
    }, [getCorporateByFamilyIdApiCall]);
};
