import React, { useState } from "react";

import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import useRegions from "pages/admin/regions/useRegions";

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: "6.4%",
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
            tooltip: `Legacy ID: ${cell.row.original.LegacyId}`,
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows?.filter((row) => {
                return (
                    (filterValue === "active" && row.values.Active === true) ||
                    (filterValue === "inactive" && row.values.Active === false)
                );
            });
        },
        accessor: "RegionId",
    },
    {
        headCellProps: {
            text: "Name",
            width: "48%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "Name",
    },
    {
        headCellProps: {
            text: "Short Name",
            width: "48%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "ShortName",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(80),
        },
        accessor: "Active",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=edit&id=${cell.row.original.RegionId}`,
                replace: true,
                "aria-label": "Edit Region",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "RegionId",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add Region",
    to: "?action=add",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const RegionsTable = () => {
    const { regions } = useRegions();

    const [filters, setFilters] = useState();

    if (regions.isLoading) {
        return <>Loading...</>;
    }

    if (regions.isError) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="end" justify="space-between">
                    <TableFilters
                        filtersConfig={FiltersConfig}
                        onChange={setFilters}
                    />
                    <SecondaryButton {...tableCTAProps} />
                </HStack>
                <Table
                    columns={columns}
                    data={regions.data}
                    filters={filters}
                    emptyActionProps={tableCTAProps}
                    getRowProps={getRowProps}
                />
            </VStack>
        </>
    );
};

export default React.memo(RegionsTable);
