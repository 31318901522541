import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import env from "react-dotenv";
import Text from "./Text";
import Link from "./Link";
import Slack from "../Icon/Slack";
import Email from "../Icon/Email";
import Photo from "./Photo";
import { useStylesForText } from "styles/MakeStyles";

const Container = styled.div`
    margin-top: 1.5rem;
    text-align: left;
`;
const Content = styled(Row)`
    margin: 0.5rem 0 0;
    border: ${global.config.border.small};
    border-radius: 0.3rem;
    padding: 0.5rem;
`;
const Column = styled(Col)`
    padding: 0;
`;
const PictureColumn = styled(Col)`
    min-width: 3.5rem;
    padding: 0;
    margin: 0;
`;
const EmployeePicture = styled(Photo)`
    width: 3rem;
    height: 3rem;
`;
const Contact = styled.div`
    margin-top: 1rem;
    margin-left: -0.5rem;
`;
const ExternalLink = styled.a`
    color: ${global.config.colors.black};
    text-decoration: none;
    display: inline-flex;
    &:hover {
        text-decoration: none;
        color: ${global.config.colors.black};
    }
`;
const EmailLink = styled(Link)`
    color: ${global.config.colors.black};
    display: inline-flex;
`;
const CopyContainer = styled.div`
    padding: 0 0.5rem 0.25rem;
    border-radius: 0.25rem;
    margin: 0.25rem 0;
    &:hover {
        color: ${global.config.colors.black};
        background-color: ${global.config.colors.gray8};
        div {
            visibility: visible;
        }
    }
`;
const Tip = styled.div`
    margin-left: 0.5rem;
    text-align: right;
    display: inline-flex;
    visibility: hidden;
    > svg {
        margin-right: 0.25rem;
    }
`;

const Card = ({ label, photo, name, title, department, slack, email }) => {
    const [copy, setCopy] = useState("Copy");
    const copyToClipboard = () => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(email);
            setCopy("Copied");
        }
    };
    const classesText = useStylesForText();

    return (
        <>
            {name && (
                <Container>
                    <Text variant="xsmallLight" className={classesText.gray2}>
                        {label}
                    </Text>
                    <Content>
                        <PictureColumn xs={1}>
                            <EmployeePicture url={photo} />
                        </PictureColumn>
                        <Column>
                            <Text variant="xsmall">{name}</Text>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                {title}, {department}
                            </Text>
                            <Contact>
                                {slack && (
                                    <CopyContainer>
                                        <ExternalLink
                                            href={`slack://user?team=${env.BESTWORK_REACT_SLACK_TEAM_ID}&id=U0AGBSQTY`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Text variant="xsmall">
                                                {slack}
                                            </Text>
                                            <Tip>
                                                <Slack />
                                                <Text
                                                    variant="xsmallLight"
                                                    className={
                                                        classesText.gray2
                                                    }
                                                >
                                                    Message
                                                </Text>
                                            </Tip>
                                        </ExternalLink>
                                    </CopyContainer>
                                )}
                                {email && (
                                    <CopyContainer>
                                        <EmailLink
                                            noUnderline
                                            noHover
                                            onClick={() => {
                                                copyToClipboard();
                                            }}
                                        >
                                            <Text variant="xsmall">
                                                {email}
                                            </Text>
                                            <Tip>
                                                <Email />
                                                <Text
                                                    variant="xsmallLight"
                                                    className={
                                                        classesText.gray2
                                                    }
                                                >
                                                    {copy}
                                                </Text>
                                            </Tip>
                                        </EmailLink>
                                    </CopyContainer>
                                )}
                            </Contact>
                        </Column>
                    </Content>
                </Container>
            )}
        </>
    );
};

Card.defaultProps = {
    label: "",
    photo: "",
    name: "",
    title: "",
    department: "",
    slack: "",
    email: "",
};

Card.propTypes = {
    label: PropTypes.string.isRequired,
    photo: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    slack: PropTypes.string,
    email: PropTypes.string,
};

export default Card;
