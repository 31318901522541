import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { useApi } from "utilities/API/Helpers/useApi";

export const useBizGroups = () => {
    const { getDataAsync } = useApi();

    const getBizGroupsApiCall = useCallback(async () => {
        const response = await getDataAsync("bizGroups");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const bizGroups = useQuery("bizGroupsList", getBizGroupsApiCall);

    return useMemo(() => {
        return {
            bizGroups,
        };
    }, [bizGroups]);
};

const useClientBizGroups = () => {
    const { getDataAsync, putData, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { clientId } = useParams();

    const getClientBizGroupsApiCall = useCallback(async () => {
        const response = await getDataAsync("getBizGroupsByClientId", {
            urlParams: [{ name: "$clientId", value: clientId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientBizGroups = useQuery(
        "clientBizGroupsList",
        getClientBizGroupsApiCall
    );

    const createClientBizGroupApiCall = useCallback(
        ({ body }) => {
            return postData("updateBizGroup", {
                body,
            });
        },
        [postData]
    );

    const updateClientBizGroupApiCall = useCallback(
        ({ body }) => {
            return putData("updateBizGroup", {
                body,
            });
        },
        [putData]
    );

    const deleteClientBizGroupApiCall = useCallback(
        ({ clientBizGroupId }) => {
            return deleteData("deleteClientBizGroup", {
                urlParams: [{ name: "$id", value: clientBizGroupId }],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientBizGroupsList");
            },
        };
    }, [queryClient]);

    const createClientBizGroup = useMutation(
        createClientBizGroupApiCall,
        mutationOptions
    );
    const updateClientBizGroup = useMutation(
        updateClientBizGroupApiCall,
        mutationOptions
    );
    const deleteClientBizGroup = useMutation(
        deleteClientBizGroupApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            clientBizGroups,
            createClientBizGroup,
            updateClientBizGroup,
            deleteClientBizGroup,
        };
    }, [
        clientBizGroups,
        createClientBizGroup,
        updateClientBizGroup,
        deleteClientBizGroup,
    ]);
};

export default useClientBizGroups;
