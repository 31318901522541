import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

export const useOfficesCountries = () => {
    const { getDataAsync } = useApi();

    const getCountriesApiCall = useCallback(async () => {
        const response = await getDataAsync("getCountries");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const countries = useQuery("countriesList", getCountriesApiCall);

    return useMemo(() => {
        return {
            countries,
        };
    }, [countries]);
};

export const useOfficesCurrencies = () => {
    const { getDataAsync } = useApi();

    const getCurrenciesApiCall = useCallback(async () => {
        const response = await getDataAsync("getCurrencies");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const currencies = useQuery("currenciesList", getCurrenciesApiCall);

    return useMemo(() => {
        return {
            currencies,
        };
    }, [currencies]);
};

export const useOfficesTimezones = () => {
    const { getDataAsync } = useApi();

    const getTimezonesApiCall = useCallback(async () => {
        const response = await getDataAsync("getTimezones");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const timezones = useQuery("timezonesList", getTimezonesApiCall);

    return useMemo(() => {
        return {
            timezones,
        };
    }, [timezones]);
};

const useOffices = () => {
    const { getDataAsync, postData, putData } = useApi();
    const queryClient = useQueryClient();

    const getOfficesApiCall = useCallback(async () => {
        const response = await getDataAsync("offices");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const getOfficeByIdApiCall = useCallback(
        async ({ officeId }) => {
            const response = await getDataAsync("getOfficeById", {
                urlParams: [{ name: "$id", value: officeId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const offices = useQuery("officesList", getOfficesApiCall);

    const createOfficeApiCall = useCallback(
        (body) => {
            return postData("offices", {
                body,
            });
        },
        [postData]
    );

    const updateOfficeApiCall = useCallback(
        (body) => {
            return putData("offices", {
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("officesList");
            },
        };
    }, [queryClient]);

    const createOffice = useMutation(createOfficeApiCall, mutationOptions);
    const updateOffice = useMutation(updateOfficeApiCall, mutationOptions);

    return useMemo(() => {
        const useGetOfficeById = (dataObj) =>
            useQuery(["getOfficeByIdApiCall", dataObj], () =>
                getOfficeByIdApiCall(dataObj)
            );

        return {
            useGetOfficeById,
            offices,
            createOffice,
            updateOffice,
        };
    }, [offices, createOffice, updateOffice]);
};

export default useOffices;
