import { useState, useEffect } from "react";

export const getColumns = (width) => {
    switch (true) {
        case width >= 3040:
            return 16;
        case width >= 2880:
            return 15;
        case width >= 2720:
            return 14;
        case width >= 2560:
            return 13;
        case width >= 2400:
            return 12;
        case width >= 2240:
            return 11;
        case width >= 2080:
            return 10;
        case width >= 1920:
            return 9;
        case width >= 1760:
            return 8;
        case width >= 1600:
            return 7;
        case width >= 1440:
            return 6;
        case width >= 1280:
            return 6;
        case width >= 1120:
            return 5;
        case width >= 1024:
            return 4;
        case width >= 768:
            return 3;
        case width >= 576:
            return 2;
        default:
            return 1;
    }
};

export default function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        columns: undefined,
    });

    useEffect(() => {

        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            let columns = getColumns(window.innerWidth);
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                columns: columns,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}
