import PropTypes from "prop-types";
import styled from "styled-components";

import { COLOR_VALUES } from "../../config/colors";
import Icon, { ICON_NAMES } from "../../ui/Icon";
import Text from "../../ui/Text";
import BaseButton from "./BaseButton";
import Tooltip from "../../ui/Tooltip";

const StyledPrimaryButton = styled(BaseButton)`
    display: inline-flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    background: ${COLOR_VALUES.Black};
    border-radius: 4px;
    color: ${COLOR_VALUES.White} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    padding: ${({ $reducePadding }) =>
        $reducePadding ? "14px 16px" : "14px 48px"};
    min-height: 48px;

    &:not(:disabled) {
        &:hover {
            background: ${COLOR_VALUES.Gray_1};
        }

        &:active {
            box-shadow: 0 0 0px 2px ${COLOR_VALUES.Black} inset;
        }
    }

    &:disabled {
        background: ${COLOR_VALUES.Gray_3};
    }
`;

const PrimaryButton = ({ label, leadingIcon, trailingIcon, ...rest }) => {
    const ariaLabel = rest["aria-label"];

    if (!label && !ariaLabel) {
        console.error("Icon-only button missing aria-label");
    }

    return (
        <Tooltip text={ariaLabel} enabled={!label}>
            <StyledPrimaryButton $reducePadding={!label} {...rest}>
                {leadingIcon && <Icon name={leadingIcon} />}
                {label && <Text variant="Headline_5">{label}</Text>}
                {trailingIcon && <Icon name={trailingIcon} />}
            </StyledPrimaryButton>
        </Tooltip>
    );
};

PrimaryButton.propTypes = {
    label: PropTypes.string,
    leadingIcon: PropTypes.oneOf(ICON_NAMES),
    trailingIcon: PropTypes.oneOf(ICON_NAMES),
    "aria-label": PropTypes.string,
    disabled: PropTypes.bool,
};

export default PrimaryButton;
