import { useFormState } from "react-hook-form";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import TextArea from "components/design-system/forms/TextArea";
import TextInput from "components/design-system/forms/TextInput";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import useAccessGroups from "./useAccessGroups";

const ModalCTA = ({ isEditing, accessGroupIdToEdit, isLoading, onClose }) => {
    const { isValid } = useFormState();
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    label: "Delete",
                    leadingIcon: "delete",
                    to: isEditing && "?action=delete&id=" + accessGroupIdToEdit,
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformAccessGroupDataToFormData(accessGroupData = {}) {
    return {
        name: accessGroupData.name,
        description: accessGroupData.description,
    };
}

function transformFormDataToAccessGroupData(
    formData = {},
    AccessGroupData = {}
) {
    const newAccessGroupData = { ...AccessGroupData };
    newAccessGroupData.Name = formData.name;
    newAccessGroupData.description = formData.description;
    newAccessGroupData.legacyCode = AccessGroupData.legacyCode ?? "";
    return newAccessGroupData;
}

const AccessGroupForm = ({ accessGroupIdToEdit, onClose }) => {
    const { accessGroups, createAccessGroup, updateAccessGroup } =
        useAccessGroups();

    if (accessGroups.isLoading) {
        return <div>Loading...</div>;
    }

    if (accessGroups.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof accessGroupIdToEdit !== "undefined";
    const modifier = isEditing ? updateAccessGroup : createAccessGroup;

    const currentAccessGroupData = accessGroups.data.find(
        (accessGroup) => accessGroup.id === accessGroupIdToEdit
    );

    if (isEditing && !currentAccessGroupData) {
        return <div>Could not find access group</div>;
    }

    const accessGroupData = isEditing
        ? accessGroups.data.filter((group) => group.id !== accessGroupIdToEdit)
        : accessGroups.data;

    const onSubmit = (formData) => {
        const accessGroupsData = transformFormDataToAccessGroupData(
            formData,
            currentAccessGroupData
        );

        const allData = {
            groupId: accessGroupIdToEdit,
            body: accessGroupsData,
        };

        modifier.mutateAsync(allData).then(() => {
            onClose();
            sendNotification(
                undefined,
                isEditing ? (
                    <>
                        Changes to <strong>{accessGroupsData.Name}</strong> have
                        been saved.
                    </>
                ) : (
                    <>
                        <strong>{accessGroupsData.Name}</strong> added.
                    </>
                )
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformAccessGroupDataToFormData(
                currentAccessGroupData
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <TextInput
                        type="text"
                        name="name"
                        label="Access Group Name"
                        validations={{
                            required: true,
                            validate: (value) =>
                                accessGroupData.filter(
                                    (group) =>
                                        group.name.toLowerCase() ===
                                        value.toLowerCase()
                                ).length === 0 ||
                                "Access Group Name is already exist",
                        }}
                    />
                    <TextArea
                        name="description"
                        showClearIcon={true}
                        characterCounter={true}
                        label="Description"
                        description="Optional"
                        helpText="Character limit: 120"
                        validations={{
                            maxLength: {
                                value: 120,
                                message: "Please use fewer characters",
                            },
                        }}
                    />
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    accessGroupIdToEdit={accessGroupIdToEdit}
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default AccessGroupForm;
