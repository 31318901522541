import styled from "styled-components";

const ErrorMessageContainer = styled.div`
    font-size: ${global.config.fonts.sizes.xxsmall};
    color: ${global.config.colors.red1};
    padding-left: 1.063rem;
    padding-top: 0.5rem;
    position: absolute;
`;

export default ErrorMessageContainer;
