import { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const ToggleButtonControlled = ({
    Icon1,
    Icon2,
    LoadingIcon,
    value,
    isLoading,
    onClick,
    ...rest
}) => {

    const Icon = (isLoading) ? LoadingIcon : (value) ? Icon1 : Icon2;

    const click = (isLoading) ? null : onClick;

    return (
        <Button isLoading variant="link" size="sm" className="toggle-button transition m-0 p-0" disabled={isLoading} {...rest} onClick={click}>
            {Icon}
        </Button>
    );
};

const ToggleButton = ({ onToggle, initialValue = false, ...rest }) => {
    const [toggleStatus, setToggleStatus] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = useCallback(() => {
        if (!isLoading)
        {
            if (typeof onToggle === "function") {
                const result = onToggle();

                if (
                    typeof result.then === "function" &&
                    typeof result.finally === "function"
                ) {
                    setIsLoading(true);

                    result
                        .then(() => {
                            setToggleStatus((toggled) => !toggled);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    setToggleStatus((isToggled) => !isToggled);
                }
            }
        }
    }, [onToggle]);

    return (
        <ToggleButtonControlled
            {...rest}
            value={toggleStatus}
            isLoading={isLoading}
            onClick={handleClick}
        />
    );
};

ToggleButton.propTypes = {
    Icon1: PropTypes.element.isRequired,
    Icon2: PropTypes.element.isRequired,
    onToggle: PropTypes.func,
};

export default ToggleButton;
export { ToggleButtonControlled };
