import { useApi } from "./Helpers/useApi";
import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

export const useTitles = () => {
    const { getDataAsync, } = useApi();

    const getTitlesFilter = async (params) => {
        return getDataAsync("titlesFilter", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getUserTitlesApiCall = useCallback(
        async ({ userId }) => {
            const response = await getDataAsync("userTitles", {
                urlParams: [
                    { name: "$userId", value: userId }
                ]
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useGetUserTitles = (dataObj) =>
            useQuery({
                queryKey: ["userTitles", dataObj],
                queryFn: () => getUserTitlesApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });
        return {
            getTitlesFilter,
            useGetUserTitles
        };
    }, [getTitlesFilter, getUserTitlesApiCall]);
};
