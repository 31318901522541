import { useFormState, useWatch } from "react-hook-form";

import Dropdown from "components/design-system/forms/Dropdown";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import ToggleInputForm from "components/design-system/forms/ToggleInputForm";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useClientRateCards from "pages/admin/clients/single/useClientRateCards";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import { useRateCardsApi } from "pages/admin/projects/useProjects";

const ModalCTA = ({
    isEditing,
    isLoading,
    onClose,
    handleOnClickDeleteRateCard,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteRateCard(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformRateCardDataToFormData(rateCardData = {}) {
    return {
        rateCardDropdown: rateCardData.RateCardTableId,
        default: rateCardData?.UseAsDefault ?? false,
    };
}

function transformFormDataToRateCardData(formData = {}, rateCardData = {}) {
    const newRateCardData = { ...rateCardData };
    newRateCardData.RateCardTableId = formData.rateCardDropdown;
    newRateCardData.UseAsDefault = formData.default;

    return newRateCardData;
}

const ClientRateCardForm = ({ rateCardIdToEdit, onClose }) => {
    const { clientRateCards, createClientRateCard, deleteClientRateCard } =
        useClientRateCards();

    const { rateCards } = useRateCardsApi();

    if (clientRateCards.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientRateCards.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof rateCardIdToEdit !== "undefined";
    const modifier = createClientRateCard;

    const currentClientRateCardData = clientRateCards?.data?.find(
        (rateCard) => rateCard.RateCardTableId === rateCardIdToEdit
    );

    if (isEditing && !currentClientRateCardData) {
        return <div>Could not find rate card</div>;
    }

    const handleOnClickDeleteRateCard = (prevData) => {
        deleteClientRateCard
            .mutateAsync({ clientRateCardId: rateCardIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={currentClientRateCardData.RateCardTableName}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    true,
                    {
                        label: "Undo",
                        onClick: () => onSubmit(prevData),
                    }
                );
            });
    };

    const rateCardOptions = !rateCards.isLoading
        ? getDropdownOptions(
              rateCards?.data,
              "RateCardTableId",
              "RateCardTableName"
          )
        : [];

    const onSubmit = (formData) => {
        const clientRateCardsData = transformFormDataToRateCardData(
            formData,
            currentClientRateCardData
        );

        const allData = {
            clientRateCardId: clientRateCardsData.RateCardTableId,
            useAsDefault: clientRateCardsData.UseAsDefault,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={
                        isEditing
                            ? clientRateCardsData.RateCardTableName
                            : rateCardOptions?.find(
                                  (f) => f.value === formData.rateCardDropdown
                              )?.label
                    }
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformRateCardDataToFormData(
                currentClientRateCardData
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <Dropdown
                        name="rateCardDropdown"
                        validations={{
                            required: true,
                        }}
                        label="Rate Card"
                        itemsAreLoading={rateCards.isLoading}
                        disabledOptionsList={
                            !clientRateCards.isLoading
                                ? clientRateCards.data.map((data) => ({
                                      value: data.RateCardTableId,
                                  }))
                                : []
                        }
                        disabledOptionTooltipText="Already in this Rate Card"
                        options={rateCardOptions}
                    />
                    <ToggleInputForm
                        defaultSelection={
                            currentClientRateCardData?.UseAsDefault ?? false
                        }
                        name="default"
                        label={["Default", "Default"]}
                    />
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteRateCard={handleOnClickDeleteRateCard}
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default ClientRateCardForm;
