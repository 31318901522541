import { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import PageWrapper from "pages/PageWrapper";
import ClientsTable from "pages/admin/clients/ClientsTable";
import ClientForm from "pages/admin/clients/ClientForm";
import { DrawerContextProvider } from "context/Drawer/DrawerContext";
import { ServerSidePaginationContextProvider } from "context/TablePagination/ServerSidePaginationContext";
import { DropdownMultiSelectContextProvider } from "context/DropdownMultiSelect/DropdownMultiSelectContext";
import { ClientContextProvider } from "context/Admin/ClientContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const FormPresenter = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const now = new Date();
    insightsTrace("VIEW", "AdminClientPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal
                title="Add Client"
                stickyAction={true}
                onRequestClose={onClose}
            >
                <ClientForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal
                title="Edit Client"
                stickyAction={true}
                onRequestClose={onClose}
            >
                <ClientForm
                    clientIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const Clients = () => {
    return (
        <>
            <DrawerContextProvider>
                <PageWrapper
                    title="Clients"
                    menuOptions={[{ children: "Admin", to: "/admin" }]}
                >
                    <ServerSidePaginationContextProvider>
                        <DropdownMultiSelectContextProvider>
                            <ClientContextProvider>
                                <FormPresenter />
                                <ClientsTable />
                            </ClientContextProvider>
                        </DropdownMultiSelectContextProvider>
                    </ServerSidePaginationContextProvider>
                </PageWrapper>
            </DrawerContextProvider>
        </>
    );
};

export default Clients;
