import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SegmentedControl from "components/design-system/controls/segmentedControl/SegmentedControl";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import DropdownMultiSelect from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelect";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { DropdownMultiSelectContext } from "context/DropdownMultiSelect/DropdownMultiSelectContext";
import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";

const StyledDropdown = styled(DropdownSingleSelectWithoutSearch)`
    width: 160px;
`;

const StyledFormInputWrapper = styled(FormInputWrapper)`
    ${TEXT_VARIANT_STYLES.Headline_5};
`;

const TableFilters = ({
    initialFilterData = [],
    filtersConfig = [],
    autoUpdateFilterData = [],
    onChange = () => void 0,
    handleOnChangeDropdownColumn,
}) => {
    const { multiSelectDropdownOptionsList } = useContext(
        DropdownMultiSelectContext
    );
    const [count, setCount] = useState(0);
    const { queryPageIndexUpdate } = useContext(ServerSidePaginationContext);
    const internalFiltersState = useRef(
        new Map(
            filtersConfig?.map((filter) => [
                filter.name,
                filter.defaultValue ?? filter.unfilteredValue,
            ])
        )
    );

    const externalStateUpdater = useCallback(() => {
        const filterList = [];
        internalFiltersState?.current?.forEach((value, name) => {
            const filter = filtersConfig?.find(
                (filterName) => filterName.name === name
            );

            if (filter && value !== filter.unfilteredValue) {
                if (
                    (filter.hasOwnProperty("show") && filter.show) ||
                    !filter.hasOwnProperty("show")
                ) {
                    filterList.push({
                        id: filter.columnAccessor,
                        value,
                    });
                }
            }
        });

        onChange(filterList);
        queryPageIndexUpdate(0);
    }, [filtersConfig, onChange]);

    useEffect(() => {
        setCount(count + 1);
    }, [internalFiltersState.current]);

    useEffect(() => {
        initialFilterData?.map((initialFilter) => {
            internalFiltersState.current.set(
                initialFilter.name,
                initialFilter.value
            );
            externalStateUpdater();
        });
    }, [count]);

    useEffect(() => {
        if (autoUpdateFilterData?.length > 0) {
            autoUpdateFilterData?.map((x) => {
                internalFiltersState.current.set(x.name, x.value);
                externalStateUpdater();
            });
        }
    }, [autoUpdateFilterData?.length]);

    return filtersConfig.map((filter) => {
        const onChangeInternal = (newValue) => {
            internalFiltersState.current.set(filter.name, newValue);
            externalStateUpdater();
        };

        let displayDropdownControl = filter.isDropdownControl;

        if (filter?.hasOwnProperty("show")) {
            displayDropdownControl = filter.show;
        }

        return (
            <Fragment key={filter.name}>
                {filter.isSegmentedControl && (
                    <SegmentedControl
                        name={filter.name}
                        value={internalFiltersState.current.get(filter.name)}
                        options={filter.options}
                        onChange={onChangeInternal}
                        filterLabel={filter.filterLabel}
                        disabled={filter.disabled}
                    />
                )}
                {filter.showColumnFilter && (
                    <FormInputWrapper
                        isSmallSize={true}
                        label={filter.name}
                        style={{
                            width: filter.formInputWrapperWidth ?? "160px",
                        }}
                    >
                        <DropdownMultiSelect
                            isSmallSize={true}
                            isVariantToggle={true}
                            initialData={multiSelectDropdownOptionsList}
                            optionsData={multiSelectDropdownOptionsList}
                            onChangeDropdown={handleOnChangeDropdownColumn}
                        />
                    </FormInputWrapper>
                )}
                {displayDropdownControl && (
                    <StyledFormInputWrapper
                        label={filter.dropdownFilterLabel}
                        isSmallSize={true}
                        className={filter.formInputWrapperClassName}
                    >
                        <StyledDropdown
                            options={filter.options}
                            value={internalFiltersState.current.get(
                                filter.name
                            )}
                            onChange={onChangeInternal}
                            isSmallSize={true}
                        />
                    </StyledFormInputWrapper>
                )}
            </Fragment>
        );
    });
};

TableFilters.propTypes = {
    filtersConfig: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            // columnAccessor: PropTypes.string.isRequired,
            isSegmentedControl: PropTypes.bool,
            isDropdownControl: PropTypes.bool,
            formInputWrapperClassName: PropTypes.string,
            unfilteredValue: PropTypes.string,
            defaultValue: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]).isRequired,
                    label: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number,
                    ]).isRequired,
                })
            ),
            dropdownFilterLabel: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    isSegmentedControl: PropTypes.bool,
    isDropdownControl: PropTypes.bool,
};

export default TableFilters;
