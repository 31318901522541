import React, { useState, useContext, useEffect } from "react";

import { COLOR_VALUES } from "components/design-system/config/colors";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import useTimeOffApi from "./useTimeOff";
import SessionContext from "context/app/SessionContext";
import { format } from "date-fns";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import Loading from "components/UI/Loading";
import styled from "styled-components";

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`;

const columns = [
    {
        headCellProps: {
            text: "Start Date",
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            width: "15%",
        }),
        filter: (rows, _columnIds, filterValue) => {
            if (filterValue === "All") {
                return rows;
            } else {
                return rows.filter((row) => {
                    return (
                        filterValue ===
                        new Date(row.values.firstDayOut).getFullYear()
                    );
                });
            }
        },
        accessor: "firstDayOut",
    },
    {
        headCellProps: {
            text: "End Date",
            width: "15%",
        },

        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            width: "15%",
        }),
        accessor: "lastDayOut",
    },
    {
        headCellProps: {
            text: "Duration",
            width: "15%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
            tooltip: getDurationTootip(cell.row.original),
            tooltipEnterDelay: 0,
            placement: "bottom-start",
        }),
        accessor: "amountOfDaysOut",
    },
    {
        headCellProps: {
            text: "Reason",
            width: "15%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            width: "15%",
        }),
        accessor: "reasonName",
    },
    {
        headCellProps: {
            text: "Submited",
            width: "18%",
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
            width: "15%",
        }),
        accessor: "createdDate",
    },
    {
        headCellProps: {
            text: "Status",
            width: "18%",
        },
        getBodyCellProps: (cell) => ({
            text: getStatusLabel(cell.row.original),
            icon: getIcon(cell.value),
            iconColor: getIconColor(cell.value),
            padding:
                cell.value === "Approved" || cell.value === "Rejected"
                    ? ""
                    : "0px 0px 0px 35px",
        }),
        accessor: "status",
        filter: (rows, _columnIds, filterValue) => {
            if (filterValue === "All") {
                return rows;
            } else {
                return rows.filter((row) => {
                    return filterValue === row.values.status;
                });
            }
        },
    },
    {
        headCellProps: {
            width: "9%",
        },
        accessor: "edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "file",
            tooltipEnterDelay: 0,
            tooltip: getHoverMassageTooltip(cell.row.original),
            actionProps: {
                to: `?action=edit&id=${cell.row.original.id}`,
                replace: true,
                "aria-label": getHoverMassageTooltip(cell.row.original),
            },
        }),
    },
];

const getHoverMassageTooltip = (value) => {
    let tooltipHoverMessage = "";
    const today = new Date();
    const isPastFirstDate = new Date(value?.firstDayOut) < today;
    const isPastEndDate = new Date(value?.lastDayOut) <= today;

    if (
        value.rejected ||
        (value.approved && isPastFirstDate) ||
        (value.pending && isPastEndDate)
    ) {
        tooltipHoverMessage = "View Request";
    } else if (
        (value.approved && !isPastFirstDate) ||
        (value.pending && !isPastEndDate)
    ) {
        tooltipHoverMessage = "View or Edit Request";
    }
    return tooltipHoverMessage;
};

const getDurationTootip = (value) => {
    let tooltipFirstDay = "";
    if (value.firstDayOutMorning && !value.firstDayOutAfternoon) {
        tooltipFirstDay = "Morning Out";
    } else if (value.firstDayOutAfternoon && !value.firstDayOutMorning) {
        tooltipFirstDay = "Afternoon Out";
    }

    const isOneDay = value.firstDayOut === value.lastDayOut;
    const startDatePrefix = isOneDay || tooltipFirstDay === ""
        ? ""
        : "Start Date: ";

    let tooltipLastDay = "";
    if (!isOneDay) {
        if (value.lastDayOutMorning && !value.lastDayOutAfternoon) {
            tooltipLastDay = "End Date: Morning Out";
        } else if (value.lastDayOutAfternoon && !value.lastDayOutMorning) {
            tooltipLastDay = "End Date: Afternoon Out";
        }
    }
    const lineBreak = tooltipFirstDay && tooltipLastDay ? "\n" : "";
    return `${startDatePrefix}${tooltipFirstDay}${lineBreak}${tooltipLastDay}`;
};

const getIcon = (value) => {
    if (value === "Approved") {
        return "success";
    } else if (value === "Rejected") {
        return "error";
    } else return null;
};

const getStatusLabel = (value) => {
    if (value.pending && value.deparmentApproved) {
        return "Pending HR Approval";
    }
    return value.status;
};

const getIconColor = (value) => {
    if (value === "Approved") {
        return "Green";
    } else if (value === "Rejected") {
        return "Orange_1";
    } else return null;
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const getYears = (earliestYear) => {
    let years = [{ value: "All", label: "All" }];
    let currentYear = new Date().getFullYear() + 1;
    earliestYear = earliestYear ? earliestYear : 1978;
    while (currentYear >= earliestYear) {
        years.push({ value: currentYear, label: currentYear });
        currentYear -= 1;
    }
    return years;
};

const TimeOffTable = (refresh) => {
    const currentYear = new Date().getFullYear();
    const tableCTAProps = {
        leadingIcon: "expand",
        label: "Request Time Off",
        to: "?action=add",
        replace: true,
    };

    const FiltersConfig = [
        {
            name: "status",
            columnAccessor: "status",
            unfilteredValue: "All",
            isSegmentedControl: true,
            options: [
                {
                    value: "All",
                    label: "All",
                },
                {
                    value: "Pending",
                    label: "Pending",
                },
                {
                    value: "Approved",
                    label: "Approved",
                },
                {
                    value: "Rejected",
                    label: "Rejected",
                },
            ],
        },
    ];

    const session = useContext(SessionContext);
    const yearsToShow = getYears(new Date(session.startDate).getFullYear());
    const timeOffRequestsData = {
        employeeId: session.legacyId,
    };
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [timeOffRequests, setTimeOffRequests] = useState(null);
    const { getTimeOffRequetsApiCall } = useTimeOffApi();
    const [filters, setFilters] = useState();
    const [filtersAll, setFiltersAll] = useState([
        {
            id: "firstDayOut",
            value: currentYear,
        },
    ]);

    const handleOnChangeDropdown = (val, type) => {
        setSelectedYear(val);
        let filtersNew = [...filtersAll];
        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((x) => x.id !== type);
        }
        filtersNew.push({
            id: "firstDayOut",
            value: val,
        });
        setFiltersAll(filtersNew);
    };

    const applyFilter = () => {
        let filtersNew = [...filtersAll];
        if (filtersNew?.length > 0) {
            filtersNew = filtersNew.filter((f) => f.id !== "status");
        }
        if (filters?.length > 0) {
            filtersNew.push(filters[0]);
        }
        setFiltersAll(filtersNew);
    }

    const cleanUpTable = async () => {
        setTimeOffRequests(null);
    }

    const renderData = (data) => {
        setTimeOffRequests(data);
        applyFilter();
    }

    const semaphore = () => {
        return true;
    }

    const waitUpdate = async () => {
        setTimeout(semaphore, 2000); //to wait any update in the page from API, otherwise is getting the same data without new updates
    }

    useEffect(() => {
        cleanUpTable().then(
            waitUpdate().then(
                getTimeOffRequetsApiCall(timeOffRequestsData).then((data) => {
                    renderData(data);
                })
            )
        );
    }, []);

    useEffect(() => {
        applyFilter();
    }, [filters]);

    useEffect(() => {
        if (refresh.refresh > 0) {
            cleanUpTable().then(
                waitUpdate().then(
                    getTimeOffRequetsApiCall(timeOffRequestsData).then((data) => {
                        renderData(data);
                    })
                )
            );
        }
    }, [refresh.refresh]);

    return (
        <>
            <VStack>
                <HStack align="center" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            filtersConfig={FiltersConfig}
                            onChange={setFilters}
                        />
                        <FormInputWrapper
                            className="max-width-190"
                            label="Year"
                            isSmallSize={true}
                        >
                            <DropdownSingleSelectWithoutSearch
                                isSmallSize={true}
                                options={yearsToShow}
                                value={selectedYear}
                                hideDropDownSearch={true}
                                onChange={(val) =>
                                    handleOnChangeDropdown(
                                        val,
                                        "firstDayOut"
                                    )
                                }
                                position="absolute"
                            />
                        </FormInputWrapper>
                    </HStack>
                    <SecondaryButton {...tableCTAProps} />
                </HStack>
                {
                    (timeOffRequests == null) ?
                        (
                            <LoadingWrapper>
                                <Loading text="Loading requests..." />
                            </LoadingWrapper>
                        )
                        :
                        (
                            <Table
                                columns={columns}
                                data={timeOffRequests}
                                getRowProps={getRowProps}
                                filters={filtersAll}
                                pageSizeInitially={10}
                            />
                        )
                }
            </VStack>
        </>
    );
};

export default React.memo(TimeOffTable);
