import React, { useState, useMemo } from "react";

const SubmitTimesheetContext = React.createContext();

export const SubmitTimesheetContextProvider = (props) => {
    const [selectedDateTimesheetStatus, setSelectedDateTimesheetStatus] =
        useState();
    const [hoursUpdateState, setHoursUpdateState] = useState(false);
    const [hoursUpdateInProgress, setHoursUpdateInProgress] = useState(false)

    const contextValue = useMemo(() => {
        return {
            selectedDateTimesheetStatus,
            setSelectedDateTimesheetStatus,
            hoursUpdateState,
            setHoursUpdateState,
            hoursUpdateInProgress,
            setHoursUpdateInProgress,
        };
    }, [
        selectedDateTimesheetStatus,
        setSelectedDateTimesheetStatus,
        hoursUpdateState,
        setHoursUpdateState,
        hoursUpdateInProgress,
        setHoursUpdateInProgress
    ]);

    return (
        <SubmitTimesheetContext.Provider value={contextValue}>
            {props.children}
        </SubmitTimesheetContext.Provider>
    );
};

export default SubmitTimesheetContext;
