import Text from "../UI/Text";
import { Container } from "react-bootstrap";
import {
    BodyWrapper,
    LineWrapper,
} from "./styles/AboutModal-Styles";
import { useEffect, useState } from "react";
import Build from "configs/Build";
import { useApi } from "../../utilities/API/Helpers/useApi";
import LoadingIcon from "../UI/LoadingIcon";

const AboutModal = (props) => {
    const { getDataAsync } = useApi();
    const [aclVersion, setACLVersion] = useState("");
    const [allocationVersion, setAllocationVersion] = useState("");
    const [coreVersion, setCoreVersion] = useState("");
    const [membershipVersion, setMembershipVersion] = useState("");
    const [frontendVersion, setFrontendVersion] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        if (!loading) {
            setLoading(true);
            loadVersions();
        }
    });

    const fontVariant = "small";
    const fontVersionVariant = "smallBold";
    const titleStyle = {
        color: global.config.colors.gray3,
    };

    const loadVersions = async () => {
        Build.get()
            .then((feVersion) => {
                if (feVersion) {
                    setFrontendVersion(
                        `${feVersion.version} - ${feVersion.date}`
                    );
                } else {
                    setFrontendVersion(`Can't get frontend version`);
                }
            })
            .catch(() => {
                setFrontendVersion(`Can't get frontend version`);
            });
        getVersion("membershipMSVersion")
            .then(async (mv) => {
                if (mv) {
                    setMembershipVersion(`${mv.version} - ${mv.date}`);
                } else {
                    setMembershipVersion(
                        `Can't get membership service version`
                    );
                }
            })
            .catch(() => {
                setMembershipVersion(`Can't get membership service version`);
            });
        getVersion("coreMSVersion")
            .then(async (cv) => {
                if (cv) {
                    setCoreVersion(`${cv.Version} - ${cv.Date}`);
                } else {
                    setCoreVersion(`Can't get core service version`);
                }
            })
            .catch(() => {
                setCoreVersion(`Can't get core service version`);
            });
        getVersion("allocationMSVersion")
            .then(async (av) => {
                if (av) {
                    setAllocationVersion(`${av.version} - ${av.date}`);
                } else {
                    setAllocationVersion(
                        `Can't get allocation service version`
                    );
                }
            })
            .catch(() => {
                setAllocationVersion(`Can't get allocation service version`);
            });
        getVersion("aclMSVersion")
            .then(async (aclv) => {
                if (aclv) {
                    setACLVersion(`${aclv.Version} - ${aclv.Date}`);
                } else {
                    setACLVersion(`Can't get ACL service version`);
                }
            })
            .catch(() => {
                setACLVersion(`Can't get ACL service version`);
            });
    };

    const getVersion = async (name) => {
        return getDataAsync(name).then(async (payload) => {
            return payload.json();
        });
    };

    return (
            <Container fluid>
                <BodyWrapper>
                    <LineWrapper>
                        <Text variant={fontVariant} style={titleStyle}>
                            Frontend
                        </Text>
                        <Text variant={fontVersionVariant}>
                            {frontendVersion !== "" ? (
                                frontendVersion
                            ) : (
                                <LoadingIcon />
                            )}
                        </Text>
                    </LineWrapper>
                    <LineWrapper>
                        <Text variant={fontVariant} style={titleStyle}>
                            Backend Membership
                        </Text>
                        <Text variant={fontVersionVariant}>
                            {membershipVersion !== "" ? (
                                membershipVersion
                            ) : (
                                <LoadingIcon />
                            )}
                        </Text>
                    </LineWrapper>
                    <LineWrapper>
                        <Text variant={fontVariant} style={titleStyle}>
                            Backend Allocation
                        </Text>
                        <Text variant={fontVersionVariant}>
                            {allocationVersion !== "" ? (
                                allocationVersion
                            ) : (
                                <LoadingIcon />
                            )}
                        </Text>
                    </LineWrapper>
                    <LineWrapper>
                        <Text variant={fontVariant} style={titleStyle}>
                            Backend Core
                        </Text>
                        <Text variant={fontVersionVariant}>
                            {coreVersion !== "" ? (
                                coreVersion
                            ) : (
                                <LoadingIcon />
                            )}
                        </Text>
                    </LineWrapper>
                    <LineWrapper>
                        <Text variant={fontVariant} style={titleStyle}>
                            Backend ACL
                        </Text>
                        <Text variant={fontVersionVariant}>
                            {aclVersion !== "" ? (
                                aclVersion
                            ) : (
                                <LoadingIcon />
                            )}
                        </Text>
                    </LineWrapper>
                </BodyWrapper>
            </Container>
    );
};

export default AboutModal;
