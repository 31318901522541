import React, { useState, useMemo, useCallback, useRef } from "react";
import { EDGES } from "resources/Enums";
import getEmployeeKey from "../../components/Allocations/Grid/Employee/utils/getEmployeeKey";

const useRegistrationList = () => {
    const list = useRef(new Set());
    const uid = useRef(1);

    function getUid() {
        return uid.current++;
    }

    const register = useCallback((_uid = getUid()) => {
        list.current.add(_uid);

        return () => {
            list.current.delete(_uid);
        };
    }, []);

    return [list, register];
};

const safeAreaIntruderElRefs = new Map();

const registerSafeAreaIntruderElRef = (ref, edge) => {
    safeAreaIntruderElRefs.set(ref, edge);
};

const deregisterSafeAreaIntruderElRef = (ref) => {
    safeAreaIntruderElRefs.delete(ref);
};

const getSafeArea = () => {
    const safeArea = {
        top: 0,
        left: 0,
        bottom: window.innerHeight,
        right: window.innerWidth,
    };

    safeAreaIntruderElRefs.forEach((edge, ref) => {
        if (ref.current) {
            const elRect = ref.current.getBoundingClientRect();

            switch (edge) {
                case EDGES.TOP:
                    safeArea.top = Math.max(safeArea.top, elRect.bottom);
                    break;
                case EDGES.LEFT:
                    safeArea.left = Math.max(safeArea.left, elRect.right);
                    break;
                case EDGES.BOTTOM:
                    safeArea.bottom = Math.min(safeArea.bottom, elRect.top);
                    break;
                case EDGES.RIGHT:
                    safeArea.right = Math.min(safeArea.right, elRect.left);
                    break;

                default:
                    break;
            }
        }
    });

    return safeArea;
};

export const GridUIContextActions = React.createContext({
    registerSafeAreaIntruderElRef,
    deregisterSafeAreaIntruderElRef,
    getSafeArea,
    toggleTooltip: () => {
        /**/
    },
});

export const GridUIContextValue = React.createContext(false);

export const GridUIContextProvider = (props) => {
    const [
        headerScrollReactionPauseRequests,
        requestHeaderScrollReactionPause,
    ] = useRegistrationList();
    const [tooltipRequest, setTooltipRequest] = useState(false);

    const toggleTooltip = useCallback((newTooltipRequest) => {
        setTooltipRequest((oldTooltipRequest) => {
            if (!oldTooltipRequest) {
                return newTooltipRequest;
            } else if (
                !newTooltipRequest ||
                (getEmployeeKey(oldTooltipRequest.employeeData) ===
                    getEmployeeKey(newTooltipRequest.employeeData) &&
                    oldTooltipRequest.date === newTooltipRequest.date)
            ) {
                return false;
            } else {
                return newTooltipRequest;
            }
        });
    }, []);

    const contextActions = useMemo(
        () => ({
            registerSafeAreaIntruderElRef,
            deregisterSafeAreaIntruderElRef,
            getSafeArea,
            toggleTooltip,
            requestHeaderScrollReactionPause,
        }),
        [requestHeaderScrollReactionPause, toggleTooltip]
    );

    const contextValue = useMemo(
        () => ({
            tooltipRequest,
            headerScrollReactionPauseRequests,
        }),
        [headerScrollReactionPauseRequests, tooltipRequest]
    );

    return (
        <GridUIContextActions.Provider value={contextActions}>
            <GridUIContextValue.Provider value={contextValue}>
                {props.children}
            </GridUIContextValue.Provider>
        </GridUIContextActions.Provider>
    );
};
