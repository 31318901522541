import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Link from "../../UI/Link";
import Alert from "../../Icon/Alert";
import { useStylesForText } from "styles/MakeStyles";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        "& .modal-title": {
            width: "100%",
            margin: "2rem 1.5rem 1rem 3.438rem",
        },
    },
}));

const Action = styled.div`
    margin: 2.5rem 4.375rem;
    text-align: right;
    > button.MuiButton-root {
        margin-left: 2rem;
        margin-right: 0;
        min-width: 8.75rem;
        height: 2.625rem;
    }
    > button.MuiButton-root:hover {
        opacity: 0.75;
        box-shadow: none;
        background-color: ${global.config.colors.black};
    }

    position: absolute;
    bottom: 0;
    right: 0;
`;
const Content = styled.div`
    margin: auto 3.438rem;
    text-align: left;
    > p.MuiTypography-body1,
    button {
        font-weight: 700;
    }
    margin-bottom: 7.625rem;
`;

const QuestionWrapper = styled.div`
    margin-top: 1.25rem;
    margin-bottom: 4rem;
    > p.MuiTypography-body1,
    button {
        font-weight: 700;
    }
`;

const ConfirmationModal = (props) => {
    const { title, question, onConfirm, onCancel, buttonLabel, onHide } = props;
    const classesText = useStylesForText();
    const classes = useStyles();
    const handleClose = () => {
        ReactDOM.render(null, document.getElementById("divPageModal"));
    };

    return (
        <>
            <Modal.Header
                className={classes.title}
                onHide={(e) => {
                    if (onHide) onHide(e);
                }}
            >
                <Modal.Title>
                    <Alert size="24" />
                    <Text variant="xlarge"> {title}</Text>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Content>
                        {props.children && (
                            <Text
                                variant="medium"
                                className={classesText.gray2}
                            >
                                {props.children}
                            </Text>
                        )}
                        {question && (
                            <QuestionWrapper>
                                <Text
                                    variant="medium"
                                    className={classesText.gray2}
                                >
                                    {question}
                                </Text>
                            </QuestionWrapper>
                        )}
                    </Content>
                </Container>
            </Modal.Body>
            <Action>
                <Link
                    variant="secondary"
                    onClick={(e) => {
                        if (onCancel) {
                            onCancel(e);
                        }
                        handleClose();
                    }}
                >
                    Cancel
                </Link>
                <Button
                    onClick={(e) => {
                        if (onConfirm) {
                            onConfirm(e);
                        }
                        handleClose();
                    }}
                >
                    {buttonLabel}
                </Button>
            </Action>
        </>
    );
};

ConfirmationModal.defaultProps = {
    question: "Do you wish to continue?",
};

export default ConfirmationModal;
