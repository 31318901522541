import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";

import GlobalConfig from "configs/Global";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { useProjectsApi } from "pages/admin/projects/useProjects";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import ProjectDrawer from "pages/admin/projects/ProjectDrawer";
import EmptyState from "components/design-system/tables/EmptyState";
import FilterDrawer, {
    filterDropdownNames,
} from "pages/admin/projects/FilterDrawer";

import DrawerContext from "context/Drawer/DrawerContext";
import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";
import { DropdownMultiSelectContext } from "context/DropdownMultiSelect/DropdownMultiSelectContext";

import updateColumnsShowAttribute from "components/design-system/tables/updateColumnsShowAttribute";
import { pagerOptions } from "components/design-system/tables/Pager";

const columnNamesAccessor = {
    Num: "JobCode",
    Project: "Name",
    Client: "ClientName",
    RateCard: "RateCardTableName",
    LeadProducer: "LeadProducerFullName",
    BizUnit: "BizUnitName",
    Start: "ProjStartDate",
    End: "CurrentEndDate",
    Budget: "BudgetLastUpdBy",
    Bill: "IsNonBillable",
    API: "SAP_API_Status_Id",
};

const columns = [
    {
        headCellProps: {
            text: "Num.",
            width: getCellWidthInPercent(96),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            actionProps: {
                "aria-label": "job id",
                onClick: () => void 0,
            },
        }),
        accessor: columnNamesAccessor.Num,
    },
    {
        headCellProps: {
            text: "Name",
        },
        accessor: columnNamesAccessor.Project,
    },
    {
        headCellProps: {
            text: "Client",
            width: getCellWidthInPercent(128),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            tooltip: cell.value,
        }),
        accessor: columnNamesAccessor.Client,
    },
    {
        headCellProps: {
            text: "Rate Card",
            width: getCellWidthInPercent(128),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            tooltip: cell.value,
        }),
        accessor: columnNamesAccessor.RateCard,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Lead Producer",
            width: getCellWidthInPercent(128),
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value && !cell.row.original.Active ? "error" : null,
            iconColor: "Orange_1",
            color:
                cell.value?.length <= 0 || !cell.value ? "Orange_1" : "Black",
            text: cell.value?.length > 1 ? cell.value : "Not Assigned!",
            tooltip:
                cell.value && !cell.row.original.Active
                    ? `${cell.value} is inactive`
                    : cell.value,
        }),
        accessor: columnNamesAccessor.LeadProducer,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Biz Unit",
            width: getCellWidthInPercent(112),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
            tooltip: cell.value,
        }),
        accessor: columnNamesAccessor.BizUnit,
        disableSortBy: true,
        show: true,
    },
    {
        headCellProps: {
            text: "Start",
            width: getCellWidthInPercent(112),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "dd/MM/yyyy"),
        }),
        accessor: columnNamesAccessor.Start,
    },
    {
        headCellProps: {
            text: "End",
            width: getCellWidthInPercent(112),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "dd/MM/yyyy"),
        }),
        accessor: columnNamesAccessor.End,
    },
    {
        headCellProps: {
            text: "Budget",
            centered: true,
            width: getCellWidthInPercent(128),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ? `$${cell.value}` : "-",
            justifyContent: cell.value ? "end" : "center",
        }),
        accessor: columnNamesAccessor.Budget,
    },
    {
        headCellProps: {
            text: "Bill",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            justifyContent: "center",
            icon: cell.value ? "success" : "error",
            tooltip: cell.value ? "True" : "False",
        }),
        accessor: columnNamesAccessor.Bill,
        show: true,
    },
    {
        headCellProps: {
            text: "API",
            centered: true,
            width: getCellWidthInPercent(80),
        },
        getBodyCellProps: (cell) => ({
            justifyContent: "center",
            icon: cell.value ? "success" : "error",
            tooltip: cell.value ? "Success" : "Error",
        }),

        accessor: columnNamesAccessor.API,
        show: true,
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(80),
        },
        accessor: "Edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            moreMenuActionProps: [
                {
                    text: "Edit",
                    actionIcon: "edit",
                    actionProps: {
                        to: `?action=edit&id=${cell.row.original.JobId}`,
                        replace: true,
                        "aria-label": "Edit Project",
                    },
                },
                {
                    text: "Linked Projects",
                    actionIcon: "caret-right",
                    actionProps: {
                        to: `${cell.row.original.JobId}/${GlobalConfig.routes.adminProjectsLinked}`,
                        replace: true,
                        "aria-label": "Linked Project",
                    },
                },
            ],
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add",
    to: "?action=add",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: columnNamesAccessor.Num,
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
    {
        showColumnFilter: true,
        name: "Columns",
        formInputWrapperWidth: "192px",
    },
];

const multiSelectDropownOptionsArr = [
    { parent: "Main", children: ["Rate Card", "Lead Producer", "Biz Unit"] },
    { parent: "Attributes", children: ["Bill", "API"] },
];

const ProjectsTable = () => {
    const { queryPageIndex, queryPageSize, querySortBy, totalItemsUpdate } =
        useContext(ServerSidePaginationContext);

    const { useProjectsList } = useProjectsApi();

    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState([]);
    const [filtersAll, setFiltersAll] = useState([]);

    const showFilterVal = useRef();

    switch (
    activeFilter?.find((f) => f.id === columnNamesAccessor.Num)?.value
    ) {
        case undefined:
            showFilterVal.current = "";
            break;
        case "active":
            showFilterVal.current = true;
            break;
        case "inactive":
            showFilterVal.current = false;
            break;
        default:
            showFilterVal.current = true;
            break;
    }

    useEffect(() => {
        setFiltersAll([...filters, ...activeFilter]);
    }, [filters, activeFilter]);

    const projectsDataApi = useProjectsList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: showFilterVal.current,
        leadAgencyIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.agency)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        clientIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.client)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        rateCardTableIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.rateCard)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        leadProducerIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.leadProducer)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        bizUnitIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.businessUnit)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
        apiStatusIds:
            filtersAll
                ?.find((f) => f.id === filterDropdownNames.API)
                ?.value?.map((e) => e.value)
                .join(",") ?? [],
    });

    const { multiSelectDropdownOptionsList, setMultiSelectDropdownOptions } =
        useContext(DropdownMultiSelectContext);
    const [drawerProjectId, setDrawerProjectId] = useState();
    const { show, setShow } = useContext(DrawerContext);
    const filtersConfigRef = useRef(FiltersConfig);
    const columnsRef = useRef(columns);
    const [columnsNew, setColumnsNew] = useState(columns);

    useEffect(() => {
        if (!projectsDataApi.isLoading) {
            totalItemsUpdate(projectsDataApi?.data?.TotalItems);
        }
    }, [projectsDataApi?.data?.TotalItems]);

    const handleDrawerClick = (projectId) => {
        setShow(true);
        setDrawerProjectId(projectId);
    };

    const handleDrawerClose = () => {
        setShow(false);
        setDrawerProjectId(null);
    };

    useEffect(() => {
        const i = columns.findIndex((f) => f.headCellProps.text === "Name");

        columnsRef.current[i].getBodyCellProps = (cell) => ({
            text: cell.value ?? "-",
            actionIcon: "caret-right",
            actionProps: {
                "aria-label": "project name",
                onClick: () => handleDrawerClick(cell.row.original.JobId),
            },
        });
    }, []);

    useEffect(() => {
        setMultiSelectDropdownOptions(multiSelectDropownOptionsArr);
    }, []);

    const handleOnChangeDropdownColumn = (selectedValues) => {
        let newObj = [...columnsNew];
        updateColumnsShowAttribute(
            columns,
            multiSelectDropownOptionsArr,
            newObj,
            selectedValues
        );

        setColumnsNew(newObj);
    };

    const handleClick = () => {
        setShow(true);
        setDrawerProjectId(null);
    };

    const memoizedTable = useMemo(
        () => (
            <>
                <Table
                    dataIsFetching={projectsDataApi.isFetching}
                    showTableBorder={true}
                    columns={columnsNew}
                    data={projectsDataApi?.data?.Items}
                    getRowProps={getRowProps}
                    paginationFromFE={false}
                    emptyMessage={
                        "There are no Projects that match the filters applied."
                    }
                    emptyActionProps={tableCTAProps}
                    pagerDisabledOptionsList={[pagerOptions[0]]}
                />
            </>
        ),
        [projectsDataApi, columnsNew]
    );

    if (projectsDataApi.isLoading) {
        return <>Loading...</>;
    }

    if (projectsDataApi.isError) {
        return <>Error</>;
    }

    const CTAProps = {
        leadingIcon: "filter",
        label: "Filter",
        onClick: handleClick,
    };

    return (
        <>
            {drawerProjectId && show && (
                <MasterDrawer
                    stickyAction={true}
                    onRequestClose={handleDrawerClose}
                    isCTAAllActions={true}
                >
                    <ProjectDrawer projectId={drawerProjectId} />
                </MasterDrawer>
            )}
            {show && !drawerProjectId && (
                <>
                    <MasterDrawer
                        stickyAction={true}
                        onRequestClose={() => setShow(false)}
                    >
                        <FilterDrawer
                            setFiltersData={setFilters}
                            initialData={filtersAll}
                        />
                    </MasterDrawer>
                </>
            )}
            {filters?.length === 0 ? (
                <>
                    <HStack
                        justify="flex-end"
                        style={{ paddingBottom: "16px" }}
                    >
                        <SecondaryButton {...tableCTAProps} />
                    </HStack>
                    <EmptyState
                        message={
                            show
                                ? "Create a list by applying your filters"
                                : "No results to show. Start by applying filters"
                        }
                        actionPropsForPrimary={CTAProps}
                    />
                </>
            ) : (
                <VStack>
                    <HStack align="end" justify="space-between">
                        <HStack align="flex-end">
                            <TableFilters
                                initialFilterData={[
                                    { name: "active", value: "active" },
                                ]}
                                filtersConfig={filtersConfigRef.current}
                                onChange={setActiveFilter}
                                multiSelectDropdownOptionsList={
                                    multiSelectDropdownOptionsList
                                }
                                handleOnChangeDropdownColumn={
                                    handleOnChangeDropdownColumn
                                }
                            />
                            <TertiaryButton
                                leadingIcon="filter"
                                label="Filter"
                                onClick={handleClick}
                                style={{ marginBottom: "5px" }}
                            />
                        </HStack>
                        <SecondaryButton {...tableCTAProps} />
                    </HStack>
                    {memoizedTable}
                </VStack>
            )}
        </>
    );
};

export default React.memo(ProjectsTable);
