import PropTypes from "prop-types";

const Disk = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M15.9114 6.36676V15.6078H2.39062V2.39087H13.6703L15.9114 6.36676Z"
                    stroke="black"
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M5.44141 15.6086V9.16211H12.8667V15.6086"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M11.2267 2.60669V4.85452H7.08203V2.60669"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="14.3105"
                        height="14"
                        fill="white"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Disk.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

Disk.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Disk;
