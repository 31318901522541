import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router";

import Options from "components/design-system/forms/Options";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import TextInput from "components/design-system/forms/TextInput";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useClientCampaigns from "pages/admin/clients/single/useClientCampaigns";
import { useClientCampaignsApi } from "pages/admin/projects/useProjects";

const attributesOptions = [{ label: "Active", value: "Active" }];

const ModalCTA = ({
    isEditing,
    isLoading,
    onClose,
    handleOnClickDeleteCampaign,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteCampaign(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformCampaignDataToFormData(campaignData = {}) {
    return {
        name: campaignData.CampaignName,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "Active"
                    ? campaignData[item] ?? true
                    : campaignData[item]
            )
            .join(","),
    };
}

function transformFormDataToCampaignData(
    formData = {},
    CampaignData = {},
    clientId = null,
    ClientCampaignId = undefined
) {
    const newCampaignData = { ...CampaignData };
    newCampaignData.Id = ClientCampaignId;
    // newCampaignData.ClientCampaignId = ClientCampaignId;

    newCampaignData.Name = formData.name;
    // newCampaignData.CampaignName = formData.name;

    attributesOptions.forEach((attribute) => {
        newCampaignData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });

    newCampaignData.ParentId = clientId;
    // newCampaignData.CampaignParentId = clientId;

    newCampaignData.ClientId = clientId;

    return newCampaignData;
}

const ClientCampaignForm = ({ campaignIdToEdit, onClose }) => {
    const { clientId } = useParams();
    const { clientCampaigns } = useClientCampaignsApi();
    const {
        clientCampaignsByClientId,
        createClientCampaign,
        updateClientCampaign,
        deleteClientCampaign,
    } = useClientCampaigns();

    if (clientCampaignsByClientId?.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientCampaignsByClientId?.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof campaignIdToEdit !== "undefined";
    const modifier = isEditing ? updateClientCampaign : createClientCampaign;

    const currentClientCampaignData = clientCampaignsByClientId?.data.find(
        (campaign) => campaign.ClientCampaignId === campaignIdToEdit
    );

    const campaignsDataExceptCurrent = isEditing
        ? clientCampaigns?.data?.filter(
              (c) => c.ClientCampaignId !== campaignIdToEdit
          )
        : clientCampaigns?.data;

    if (isEditing && !currentClientCampaignData) {
        return <div>Could not find client campaign</div>;
    }

    const handleOnClickDeleteCampaign = (prevData) => {
        // keeping active true for undo
        const restoreData = {
            ...prevData,
            attributes: attributesOptions[0].value,
        };

        deleteClientCampaign
            .mutateAsync({ clientCampaignId: campaignIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={currentClientCampaignData.CampaignName}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    true,
                    {
                        label: "Undo",
                        onClick: () => onSubmit(restoreData),
                    }
                );
            });
    };

    const onSubmit = (formData) => {
        const clientCampaignsData = transformFormDataToCampaignData(
            formData,
            currentClientCampaignData,
            clientId,
            campaignIdToEdit
        );

        const allData = {
            clientCampaignId: campaignIdToEdit,
            body: clientCampaignsData,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={clientCampaignsData.Name}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformCampaignDataToFormData(
                currentClientCampaignData
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <TextInput
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Campaign Name"
                        validations={{
                            required: true,
                            validate: (value) =>
                                campaignsDataExceptCurrent?.filter(
                                    (c) =>
                                        c?.CampaignName?.toLowerCase() ===
                                        value.toLowerCase()
                                ).length === 0 || "Name is already exist",
                        }}
                    />
                    <Options name="attributes" options={attributesOptions} />
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteCampaign={handleOnClickDeleteCampaign}
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default ClientCampaignForm;
