import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import VStack from "components/design-system/layout/VStack";
import BlockHead from "components/design-system/ui/BlockHead";
import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import { OPTION_VARIANTS } from "components/design-system/config/optionVariants";
import { sendNotification } from "utilities/Notification";

import useAccessGroupLanding, {
    useLandingDefaultPage,
} from "pages/admin/access-groups/single/useAccessGroupLanding";
import useAccessGroupApi from "pages/admin/access-groups/single/useAccessGroup";

const AccessGroupLanding = () => {
    const { groupId } = useParams();
    const { usePageDirectory } = useAccessGroupLanding();
    const { useAccessGroup } = useAccessGroupApi();
    const groupData = useAccessGroup(groupId);

    const { useDefaultPage, saveDefaultPage } = useLandingDefaultPage();
    const pagesDirectory = usePageDirectory();
    const defaultPage = useDefaultPage(groupId);
    const [value, setValue] = useState(defaultPage?.data);

    const handleOnChange = (val) => {
        setValue(val);

        const modifier = saveDefaultPage;
        const allData = { groupId: groupId, pageId: val };
        modifier.mutateAsync(allData).then(() => {
            pagesDirectory?.data?.modules?.map((data) => {
                data?.pages?.map((page) => {
                    if (page.id === val) {
                        sendNotification(
                            undefined,
                            <>
                                <strong>{page.name}</strong> set as Landing Page
                                for <strong>{groupData?.data?.name}</strong>
                            </>
                        );
                    }
                });
            });
        });
    };

    useEffect(() => {
        setValue(defaultPage?.data);
    }, [defaultPage?.data]);

    if (
        pagesDirectory.isLoading ||
        defaultPage.isLoading ||
        groupData.isLoading
    ) {
        return <>Loading...</>;
    }

    if (pagesDirectory.isError || defaultPage.isError) {
        return <>Error...</>;
    }

    return (
        <>
            <VStack>
                <BlockHead
                    title="Landing Page"
                    description="Select the Page this Access Group will land on when entering to BestWork"
                />

                <VStack spacing="Zero">
                    {pagesDirectory?.data?.modules?.map((d, i) => (
                        <DropdownSingleSelectWithoutSearch
                            key={d.name}
                            value={value}
                            onChange={handleOnChange}
                            options={getDropdownOptions(
                                d.pages,
                                "id",
                                "name",
                                "",
                                ""
                            )}
                            accExpandModeMulti={true}
                            accExpandModeMultiInitialVal={d.pages.some(
                                (s) => s.id == value
                            )}
                            optionVariant={OPTION_VARIANTS.Toggle}
                            isAccordion={true}
                            title={d.name}
                            showIconOnHover={true}
                            showIcon={false}
                            isShowArrow={true}
                            width="18rem"
                        />
                    ))}
                </VStack>
            </VStack>
        </>
    );
};

export default AccessGroupLanding;
