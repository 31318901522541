import env from "react-dotenv";
import { orderBy, groupBy } from "lodash";
import Objects from "./Objects";

const Transform = {
    locations: function (source, region) {
        const target = source
            .filter((item) => item.RegionId === region)
            .map((item) => {
                return {
                    region: item.RegionId,
                    label: item.Region,
                    value: `Region-${item.RegionId}`,
                    className: "locationGroup",
                    children: item.Offices
                        ? item.Offices.map((item2) => {
                              return {
                                  label: item2.Office,
                                  value: `Office-${item2.OfficeId}`,
                                  className: "locationItem",
                                  children: item2.Groups
                                      ? item2.Groups.map((item3) => {
                                            return {
                                                label: item3.GroupName,
                                                value: `Group-${item3.GroupId}`,
                                            };
                                        })
                                      : null,
                              };
                          })
                        : null,
                };
            });
        let expanded = [];
        target.forEach(function (item) {
            if (item.children) {
                expanded.push(item.value);
                item.children.forEach(function (item2) {
                    if (item2.children) {
                        expanded.push(item2.value);
                        item2.children.forEach(function (item3) {
                            if (item3.children) {
                                expanded.push(item3.value);
                            }
                        });
                    }
                });
            }
        });
        return { target, expanded };
    },

    locationsChecked: function (source, region, regionId, officeId, groupId) {
        let checked = [];
        source
            .filter((item) => item["Region"] === region)
            .forEach(function (region) {
                if (regionId.includes(region.RegionId.toString())) {
                    checked.push(`Region-${region.RegionId}`);
                }
                if (region.Offices) {
                    region.Offices.forEach(function (office) {
                        if (officeId.includes(office.OfficeId.toString())) {
                            checked.push(`Office-${office.OfficeId}`);
                        }
                        if (office.Groups) {
                            office.Groups.forEach(function (group) {
                                if (
                                    groupId.includes(group.GroupId.toString())
                                ) {
                                    checked.push(`Group-${group.GroupId}`);
                                }
                            });
                        }
                    });
                }
            });
        return checked;
    },

    projects: function (source) {
        const target = source.map((item) => {
            return {
                label: `${item["JobName"]} (${item["JobCode"]})`,
                value: item["JobId"],
                checked: false,
                data: {
                    ...item,
                },
            };
        });
        return target;
    },

    titles: function (source) {
        const departments = orderBy(source, ["Department", "asc"]);
        const target = departments.map((item) => {
            return {
                label: `${item.Department} (${item.Titles.length} Titles)`,
                tagLabel: item.Department,
                value: item.DepartmentId,
                checked: false,
                expanded: true,
                disabled: false,
                data: {
                    ...item,
                },
                children: item.Titles.map((item2) => {
                    return {
                        label: item2.Title,
                        tagLabel: `${item.Department}: ${item2.Title}`,
                        value: item2.TitleId,
                        checked: false,
                        expanded: true,
                        disabled: false,
                        data: {
                            ...item2,
                        },
                    };
                }),
            };
        });
        return target;
    },

    dropdown: function (source, label, value) {
        const target = source
            .map((item) => {
                return {
                    label: item[label],
                    value: item[value],
                    checked: false,
                    data: {
                        ...item,
                    },
                };
            })
            .filter(
                (item) =>
                    item["label"] &&
                    item["label"].trim() !== "" &&
                    item["value"]
            );
        return target;
    },

    allocationSummary: function (source, weeks) {
        const fulltime = ["Staff", "TBD"];
        const target = source.map((item) => {
            return {
                id: item.userId,
                name: item.fullName,
                image:
                    item.smallPhoto &&
                    `${env.BESTWORK_REACT_ASSET_DOMAIN}/${item.smallPhoto}`,
                department: item.department,
                departmentId: item.departmentId,
                freelance: !(fulltime.indexOf(item.employeeStatus) > -1),
                group: item.group ? item.group : "Non-group",
                groupId: item.groupId,
                location: item.office,
                locationId: item.officeId,
                region: item.region,
                regionId: item.regionId,
                title: item.title,
                titleId: item.titleId,
                departureDate: item.departureDate,
                summary: item.allocationSummaryHours
                    ? item.allocationSummaryHours
                          .filter(
                              (weekSummaryData, index, weekSummaryDataList) =>
                                  weekSummaryDataList.findIndex(
                                      (otherWeekSummaryData) =>
                                          otherWeekSummaryData.date ===
                                          weekSummaryData.date
                                  ) === index
                          )
                          .sort(function (x, y) {
                              let a = new Date(x.date),
                                  b = new Date(y.date);
                              return a - b;
                          })
                          .slice(0, weeks)
                          .map((item2) => {
                              return {
                                  date: item2.date,
                                  availableHours: item2.availableHours,
                                  allocatedHours: item2.allocatedHours,
                                  timeOffReasonsAmount:
                                      item2.timeOffReasonsAmount,
                              };
                          })
                    : null,
            };
        });
        return target;
    },

    customFilters: function (source, filterId = null) {
        const target = source.map((item) => {
            return {
                id: item.Id,
                name: item.Name,
                data: Objects.parseJson(item.Value),
                default: filterId && filterId === item.Id,
            };
        });
        return target;
    },
};

export const buildEmployeeDetail = (detail, colums) => {
    let employeeDetail = undefined;
    const clientProp = "client";
    if (
        detail &&
        Array.isArray(detail) &&
        detail.every((item) => item.hasOwnProperty(clientProp))
    ) {
        employeeDetail = [];
        const groupedProjects = Object.values(groupBy(detail, clientProp));
        const clients = groupedProjects.map((client) => ({
            name: client[0]?.client,
            code: client[0]?.clientId,
            projects: orderClientProjects(client, colums),
        }));
        return clients;
    }
    return employeeDetail;
};

const orderClientProjects = (projects, columns) => {
    let orderedProjects = [];
    if (projects) {
        orderedProjects = orderBy(projects, ["jobName", "asc"]);
        orderedProjects = orderedProjects?.map((project) => ({
            name: project.jobName,
            jobId: project.jobId,
            officeId: project.officeId,
            assignmentId: project.assignmentId,
            assignmentStart: project.assignmentStart,
            assignmentEnd: project.assignmentEnd,
            jobStart: project.jobStart,
            isJobScope: project.isJobScope,
            sowStatusLikelihood: project.sowStatusLikelihood,
            sowStatusName: project.sowStatusName,
            allocations: project.allocationSummaryHours
                ? project.allocationSummaryHours
                      .sort(function (x, y) {
                          let a = new Date(x.date),
                              b = new Date(y.date);
                          return a - b;
                      })
                      .slice(0, columns)
                      .map((allocation, index) => {
                          return {
                              id: index,
                              value: allocation.allocatedHours,
                              date: allocation.date,
                              hasNotes: allocation.hasNotes,
                          };
                      })
                : null,
        }));
    }
    return orderedProjects;
};

export default Transform;
