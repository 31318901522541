import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";
import { customFilterTypes } from "../../resources/Enums";

export const useCustomFilters = () => {
    const { getDataAsync, postData, patchData, deleteData } = useApi();

    const getCustomFilter = async () => {
        return getDataAsync("getCustomFilter", {
            params: { typeId: customFilterTypes.ALLOCATION },
        }).then((payload) => {
            return payload.json();
        });
    };

    const createCustomFilter = async (body) => {
        return postData("createCustomFilter", { body: body }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const updateCustomFilter = async (body) => {
        return patchData("updateCustomFilter", { body: body });
    };

    const deleteCustomFilter = async (urlParams) => {
        return deleteData("deleteCustomFilter", {
            urlParams: urlParams,
        });
    };

    return useMemo(() => {
        return {
            getCustomFilter,
            createCustomFilter,
            updateCustomFilter,
            deleteCustomFilter,
        };
    }, []);
};
