import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Text from "./Text";
import env from "react-dotenv";
import UserProfileMenu from "./UserProfileMenu";
import AboutModal from "../Modal/AboutModal";
import Logo from "../Icon/Logo";
import {
    NavBarStyles,
    NavWrapper,
    NavRow,
    NavContainer,
    IndentColumn,
    LogoColumn,
    NavColumn,
    ExternalLink,
    UserProfileMenuWrapper,
    EnvNameWrapper,
} from "./styles/NavBar-Styles";
import { useStylesForText } from "styles/MakeStyles";
import { useNavigationLinks } from "hooks/Access/useNavigationLinks";
import ReactDOM from "react-dom";
import GenericModal from "components/Modal/Common/GenericModal";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
    const [hideProfileMenu, setHideProfileMenu] = useState(false);
    const classes = NavBarStyles();
    const classesText = useStylesForText();
    const { getNavigation } = useNavigationLinks();
    const [loading, setLoading] = useState(true);
    const [navData, setNavData] = useState([]);
    const [nav, setNav] = useState([]);
    const navigate = useNavigate();

    const handleAboutShow = async () => {
        const modalContent = <AboutModal></AboutModal>;
        const modal = (
            <GenericModal
                content={modalContent}
                parent="divPageModal"
                title="Version Information"
            />
        );
        ReactDOM.render(modal, document.getElementById("divPageModal"));
        setHideProfileMenu(true);
    };

    const getNavigationItems = () => {
        getNavigation().then((r) => {
            setNavData(r);
        });
    };

    useEffect(() => {
        getNavigationItems();
    }, []);

    useEffect(() => {
        setNav(navData);
    }, [navData]);

    useEffect(() => {
        setLoading(false);
    }, [nav]);

    return (
        <NavWrapper id="top-nav">
            <NavContainer fluid>
                <NavRow>
                    {!loading && (
                        <>
                            <IndentColumn xs={1} />
                            <LogoColumn xs={2}>
                                <Link
                                    component="button"
                                    className={classes.logo}
                                    onClick={() => {
                                        window.location.href = `${
                                            window.location.href.split("?")[0]
                                        }`;
                                    }}
                                >
                                    <Logo />
                                </Link>

                                {env.ENVIRONMENT_NAME && (
                                    <EnvNameWrapper>
                                        {" "}
                                        {env.ENVIRONMENT_NAME}
                                    </EnvNameWrapper>
                                )}
                            </LogoColumn>
                            <NavColumn>
                                {nav.map((item, index) => {
                                    return (
                                        item.route != "/" && (
                                            <Link
                                                key={index}
                                                component="button"
                                                className={classes.navItem}
                                                style={{ marginLeft: "2em" }}
                                                onClick={() => {
                                                    navigate(item.route);
                                                }}
                                            >
                                                <Text
                                                    variant="smallLight"
                                                    style={{
                                                        borderBottom:
                                                            window.location
                                                                .pathname ===
                                                            item.route
                                                                ? global.config
                                                                      .border
                                                                      .large
                                                                : "",
                                                        width: "fit-content",
                                                    }}
                                                    className={
                                                        classesText.borderBottom
                                                    }
                                                >
                                                    {item.name}
                                                </Text>
                                            </Link>
                                        )
                                    );
                                })}
                                <ExternalLink
                                    href={
                                        "/redirectto?url=" +
                                        env.BESTWORK_REACT_REPORTER10_LINK +
                                        "&env=" +
                                        env.ENVIRONMENT_NAME +
                                        "&vpn=true"
                                    }
                                    rel="noreferrer"
                                >
                                    <Text
                                        variant="smallLight"
                                        style={{
                                            width: "fit-content",
                                        }}
                                        className={classesText.borderBottom}
                                    >
                                        Reporter
                                    </Text>
                                </ExternalLink>
                                <ExternalLink
                                    href={
                                        "/redirectto?url=" +
                                        env.BESTWORK_REACT_FEEDBACK_FORM_URL +
                                        "&env=" +
                                        env.ENVIRONMENT_NAME
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Text
                                        variant="smallLight"
                                        className={classesText.borderBottom}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        Provide Feedback
                                    </Text>
                                </ExternalLink>
                                <UserProfileMenuWrapper>
                                    <UserProfileMenu
                                        hide={hideProfileMenu}
                                        onAboutItemClick={handleAboutShow}
                                    />
                                </UserProfileMenuWrapper>
                            </NavColumn>
                            <IndentColumn xs={1} />
                        </>
                    )}
                </NavRow>
            </NavContainer>
        </NavWrapper>
    );
};

export default NavBar;
