import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useContext, useMemo, useRef } from "react";
import styled from "styled-components";
import Tooltip from "components/UI/Tooltip";
import {
    GridUIContextActions,
    GridUIContextValue,
} from "../../context/Allocations/GridUIContext";

import moment from "moment";
import { isBefore } from "date-fns";
import getEmployeeKey from "../Allocations/Grid/Employee/utils/getEmployeeKey";

const NULL_CELL_VALUE = "--";

const Wrapper = styled.div`
    background-color: ${(props) => (props.color ? props.color : "transparent")};
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: ${global.config.colors.black};
    font-size: ${global.config.fonts.sizes.small};
`;

const PillBorder = styled.button`
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    color: ${global.config.colors.black};
    font-size: ${global.config.fonts.sizes.small};
    cursor: pointer;
    border: 1px solid;
    border-color: ${(props) =>
        props.selected
            ? `${global.config.colors.black} !important`
            : global.config.colors.white};
    &:hover {
        border-color: ${global.config.colors.gray5};
    }
`;

const Pipe = styled.div`
    width: 0.05rem;
    height: 0.45rem;
    background-color: ${global.config.colors.black};
`;

const AllocatedHours = styled.div`
    font-family: ${global.config.fonts.families.bold};
    text-align: right;
    padding: 0.325rem;
    width: 50%;
    height: 100%;
`;

const AvailableHours = styled.div`
    text-align: left;
    padding: 0.325rem;
    width: 50%;
    height: 100%;
    font-family: ${global.config.fonts.families.light};
`;

const NotAvailableAllocation = styled.div`
    font-family: ${global.config.fonts.families.bold};
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0.325rem;
`;

const AVAILABLE_HOURS_BREAKPOINT = 20;
const HOURS_GAP = 4;

const calculateColor = (allocatedHours, availableHours) => {
    let hourGap = undefined;
    let check = !(allocatedHours === 0 && availableHours === 0);

    if (allocatedHours === 0 && availableHours === 0) {
        return global.config.colors.blue5;
    }

    hourGap =
        check && availableHours <= AVAILABLE_HOURS_BREAKPOINT
            ? HOURS_GAP
            : HOURS_GAP * 2;

    if (check && allocatedHours === 0) return global.config.colors.blue6;

    if (check && allocatedHours < availableHours)
        return global.config.colors.blue4;

    if (check && allocatedHours === availableHours)
        return global.config.colors.blue5;

    if (
        check &&
        allocatedHours > availableHours &&
        allocatedHours <= availableHours + hourGap
    )
        return global.config.colors.orange3;

    if (check && allocatedHours >= availableHours + (hourGap + 1))
        return global.config.colors.orange2;
};

const PillContent = React.memo(
    ({
        allocatedHours,
        availableHours,
        date,
        employeeData,
        timeOffReasonsAmount,
    }) => {
        const pillColor = useMemo(
            () => calculateColor(allocatedHours, availableHours),
            [allocatedHours, availableHours]
        );

        const buildUnassignedUnallocatedTooltipLabel = () => {
            const dateFrom = moment(date).toDate();
            const departureDate = moment(employeeData.departureDate).toDate();

            switch (true) {
                case timeOffReasonsAmount > 1:
                    return "Statuses: " + timeOffReasonsAmount;
                case !isBefore(departureDate, dateFrom):
                    return "Time off";
                case isBefore(departureDate, dateFrom) &&
                    employeeData.freelance:
                    return "Freelancer Not Working";
                case isBefore(departureDate, dateFrom) &&
                    !employeeData.freelance:
                    return "Employee Departed";
                default:
                    return "";
            }
        };

        const buildAvailableHoursTooltipLabel = () => {
            const dateFrom = moment(date).toDate();
            const departureDate = moment(employeeData.departureDate).toDate();
            const check =
                !isBefore(departureDate, dateFrom) && availableHours !== 0;
            switch (true) {
                case timeOffReasonsAmount > 1:
                    return "Statuses: " + timeOffReasonsAmount;
                case check:
                    return "Hours Available";
                case !check && employeeData.freelance:
                    return "Freelancer Not Working";
                case !check && !employeeData.freelance:
                    return "Employee Departed";
                default:
                    return "";
            }
        };

        return (
            <Wrapper color={pillColor}>
                {allocatedHours !== 0 || availableHours !== 0 ? (
                    <>
                        <Tooltip placement="bottom" title="Hours Allocated">
                            <AllocatedHours>{allocatedHours}</AllocatedHours>
                        </Tooltip>

                        <Pipe />

                        <Tooltip
                            placement="bottom"
                            title={buildAvailableHoursTooltipLabel()}
                        >
                            <AvailableHours>
                                {availableHours === 0
                                    ? NULL_CELL_VALUE
                                    : availableHours}
                            </AvailableHours>
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip
                        placement="bottom"
                        title={buildUnassignedUnallocatedTooltipLabel()}
                    >
                        <NotAvailableAllocation>
                            {NULL_CELL_VALUE}
                        </NotAvailableAllocation>
                    </Tooltip>
                )}
            </Wrapper>
        );
    }
);

const Pill = ({
    allocatedHours,
    availableHours,
    date,
    employeeData,
    timeOffReasonsAmount,
}) => {
    const { toggleTooltip } = useContext(GridUIContextActions);
    const { tooltipRequest } = useContext(GridUIContextValue);

    const pillElRef = useRef();

    const onClick = useCallback(
        (event) => {
            event.stopPropagation();

            toggleTooltip({
                employeeData,
                date,
                allocatedHours,
                availableHours,
                pillElRef,
            });
        },
        [allocatedHours, availableHours, date, employeeData, toggleTooltip]
    );

    const isSelected = tooltipRequest
        ? getEmployeeKey(tooltipRequest.employeeData) ===
              getEmployeeKey(employeeData) && tooltipRequest.date === date
        : false;

    return useMemo(
        () => (
            <PillBorder onClick={onClick} selected={isSelected} ref={pillElRef}>
                <PillContent
                    allocatedHours={allocatedHours}
                    availableHours={availableHours}
                    timeOffReasonsAmount={timeOffReasonsAmount}
                    date={date}
                    employeeData={employeeData}
                />
            </PillBorder>
        ),
        [allocatedHours, availableHours, isSelected, onClick]
    );
};

Pill.defaultProps = {
    allocatedHours: 0,
    availableHours: 0,
};

Pill.propTypes = {
    allocatedHours: PropTypes.number,
    availableHours: PropTypes.number,
    date: PropTypes.any,
    employeeData: PropTypes.object,
};

export default Pill;
