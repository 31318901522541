import PropTypes from "prop-types";

const Folder = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M6.39319 5.11384L4.73695 3.82031H0.320312V13.1791H14.6744V5.11384H6.39319Z"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="15"
                        height="10"
                        fill="white"
                        transform="translate(0 3.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Folder.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

Folder.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default Folder;
