import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useApi } from "utilities/API/Helpers/useApi";

const useRetainersDashboardData = (endpointPathText) => {
    const { getDataAsync } = useApi();

    const { retainerId } = useParams();

    const getDataLayoutApiCall = useCallback(async () => {
        const response = await getDataAsync("retainersDashboardDataLayout", {
            urlParams: [
                { name: "$retainerId", value: retainerId },
                { name: "$endpointPathText", value: endpointPathText },
            ],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const list = useQuery(`${endpointPathText}List`, getDataLayoutApiCall);

    return useMemo(() => {
        return {
            list,
        };
    }, [list]);
};

export default useRetainersDashboardData;

export const useRetainerSubJobCount = () => {
    const { getDataAsync } = useApi();

    const getRetainerSubjobCountApiCall = useCallback(
        async (retainerId) => {
            const response = await getDataAsync("getRetainerSubjobCount", {
                urlParams: [{ name: "$retainerId", value: retainerId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const useSubjobCount = (retainerId) =>
        useQuery(["getSubJobCount", retainerId], () =>
            getRetainerSubjobCountApiCall(retainerId)
        );

    return useMemo(() => {
        return {
            useSubjobCount,
        };
    }, [useSubjobCount]);
};
