import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import IconButton from "components/UI/IconButton";
import Close from "components/Icon/Close";
import "../../styles/Dropdown.css";

const Wrapper = styled.div`
    ${(props) =>
        props.isDisplaySearchCloseIcon
            ? css`
                  position: relative;
                  width: 100%;
              `
            : css`
                  display: contents;
              `}
`;

const Dropdown = ({
    placeholder,
    searchLabel,
    mode,
    data,
    className,
    disabled,
    inlineSearch,
    showPartial,
    displaySearchCloseIcon,
    handleOnClickClearSearchFieldData,
    onChange,
    onAction,
    onToggle,
    onBlur,
    onFocus,
    ...props
}) => {
    return (
        <Wrapper isDisplaySearchCloseIcon={displaySearchCloseIcon}>
            {displaySearchCloseIcon && (
                <IconButton icon={<Close size="10" />} className="dropdown-custom-search-close-icon" onClick={handleOnClickClearSearchFieldData}/>
            )}
            <DropdownTreeSelect
                texts={{placeholder: placeholder, inlineSearchPlaceholder: searchLabel}}
                mode={mode}
                data={data}
                className={className}
                disabled={disabled}
                inlineSearchInput={inlineSearch}
                showPartiallySelected={showPartial}
                keepOpenOnSelect={false}
                keepTreeOnSearch={true}
                onChange={onChange}
                onAction={onAction}
                onNodeToggle={onToggle}
                onBlur={onBlur}
                onFocus={onFocus}
                {...props}
            />
        </Wrapper>
    );
};

Dropdown.defaultProps = {
    placeholder: "Select",
    searchLabel: "Search...",
    mode: "multiSelect",
    data: null,
    className: "",
    disabled: false,
    inlineSearch: false,
    showPartial: false,
    displaySearchCloseIcon: false,
    handleOnClickClearSearchFieldData: () => void 0,
    onChange: () => void 0,
    onAction: () => void 0,
    onToggle: () => void 0,
    onBlur: () => void 0,
    onFocus: () => void 0,
};

Dropdown.propTypes = {
    placeholder: PropTypes.string,
    searchLabel: PropTypes.string,
    mode: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inlineSearch: PropTypes.bool,
    showPartial: PropTypes.bool,
    displaySearchCloseIcon: PropTypes.bool,
    handleOnClickClearSearchFieldData: PropTypes.func,
    onChange: PropTypes.func,
    onAction: PropTypes.func,
    onToggle: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default Dropdown;
