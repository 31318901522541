import React, { useMemo, useCallback, useReducer } from "react";

const SET_OPTIONS_LIST = "SET_OPTIONS_LIST";

function reducer(currentState, action) {
    if (action.type === SET_OPTIONS_LIST) {
        const optionsData = [];
        action.payload.map((p, parentIndex) => {
            let newObjP = {
                id: `${parentIndex + 1}`,
                check: false,
                indeterminate: false,
                label: p.parent,
                value: p.parent,
            };

            if (p.hasOwnProperty("children")) {
                newObjP.children = [];
            }

            optionsData.push(newObjP);

            p?.children?.map((childName, childIndex) => {
                const newObj = {
                    parentId: `${parentIndex + 1}`,
                    id: `${parentIndex + 1}${childIndex}`,
                    check: false,
                    label: childName,
                    value: childName,
                };

                optionsData[parentIndex].children.push(newObj);
            });
        });

        return {
            ...currentState,
            multiSelectDropdownOptionsList: optionsData,
        };
    }
}

export const DropdownMultiSelectContext = React.createContext({
    setMultiSelectDropdownOptions: () => void 0,
});

const initialState = {
    multiSelectDropdownOptionsList: [],
};
export const DropdownMultiSelectContextProvider = (props) => {
    const [{ multiSelectDropdownOptionsList }, dispatch] = useReducer(
        reducer,
        initialState
    );

    const setMultiSelectDropdownOptions = useCallback((parentChildData) => {
        dispatch({
            type: SET_OPTIONS_LIST,
            payload: parentChildData,
        });
    }, []);

    const contextValue = useMemo(
        () => ({
            multiSelectDropdownOptionsList,

            setMultiSelectDropdownOptions,
        }),
        [multiSelectDropdownOptionsList, setMultiSelectDropdownOptions]
    );

    return (
        <DropdownMultiSelectContext.Provider value={contextValue}>
            {props.children}
        </DropdownMultiSelectContext.Provider>
    );
};
