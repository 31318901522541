import { createGlobalStyle } from "styled-components";

import HelveticaNow_Display_Regular from "../assets/fonts/HelveticaNowDisplay-Regular.otf";
import HelveticaNow_Display_Medium from "../assets/fonts/HelveticaNowDisplay-Medium.otf";
import HelveticaNow_Text_Regular from "../assets/fonts/HelveticaNowText-Regular.otf";
import HelveticaNow_Text_Medium from "../assets/fonts/HelveticaNowText-Medium.otf";
import HelveticaNow_Text_Bold from "../assets/fonts/HelveticaNowText-Bold.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'HelveticaNowDisplay';
        src: url(${HelveticaNow_Display_Regular}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'HelveticaNowDisplay';
        src: url(${HelveticaNow_Display_Medium}) format('opentype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'HelveticaNowText';
        src: url(${HelveticaNow_Text_Regular}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'HelveticaNowText';
        src: url(${HelveticaNow_Text_Medium}) format('opentype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'HelveticaNowText';
        src: url(${HelveticaNow_Text_Bold}) format('opentype');
        font-weight: 700;
        font-style: normal;
    }
`;
