import PropTypes from "prop-types";
import styled from "styled-components";

const Path = styled.path`
    fill: ${(props) => props.color};
`;

const Icon = styled.svg`
    fill: none;

    ${Path} {
        fill: ${(props) => props.color};
    }

    &:hover {
        ${Path} {
            fill: ${(props) => (props.hover ? props.hoverColor : props.color)};
        }
    }
`;

const Down = ({ size, color, hover, hoverColor }) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={"0 0 " + size + " " + size}
            width={size}
            height={size}
            color={color}
            hover={hover}
            hoverColor={hoverColor}
        >
            <Path
                d="M15 8.96932L9.00236 14.9717L3 8.96932L3.66588 8.30344L8.53011 13.1629V3.00001H9.47462V13.1629L14.3341 8.30344L15 8.96932Z"
                color={color}
            />
        </Icon>
    );
};

Down.defaultProps = {
    size: "18",
    color: global.config.colors.gray2,
    hover: false,
    hoverColor: global.config.colors.black,
};

Down.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    hover: PropTypes.bool,
    hoverColor: PropTypes.string,
};

export default Down;
