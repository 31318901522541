import Close from "../../Icon/Close";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../../../styles/Sidebar.css";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "../../../styles/Theme";

const GenericSidebar = ({
    open,
    content,
    onClose,
    title,
    includeTheme = true,
    ...props
}) => {
    const handleRequestClose = () => {
        onClose();
    };

    const outerContent = (
        <div className={props.className} id="sidebarGenericContent">
            {props.children}
            <SlidingPane
                className="sliding-pane-custom"
                overlayClassName="no-overlay"
                isOpen={open}
                title={title}
                subtitle=""
                closeIcon={
                    <>
                        <Close color={global.config.colors.gray2} size="16" />
                    </>
                }
                width="100%"
                s
                onRequestClose={handleRequestClose}
                children={content}
            ></SlidingPane>
        </div>
    );

    return includeTheme ? (
        <ThemeProvider theme={Theme}>{outerContent}</ThemeProvider>
    ) : (
        outerContent
    );
};

export default GenericSidebar;
