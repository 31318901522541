import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
`;
const Error = (_props) => {
    void 0;
};

Error.propTypes = {};

export default Error;
