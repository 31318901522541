import { useEffect, useState, useRef } from "react";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useTitles } from "utilities/API/Titles";
import Transform from "utilities/Transform";
import Text from "components/UI/Text";
import Dropdown from "components/UI/Dropdown";
import Link from "components/UI/Link";
import { Subtitle } from "../Filter-Styles";
import WarningBox from "./WarningBox";

const warningMessage =
    "You have adjusted filters. Saved Filters have been cleared.";

let _selectedDepartments = [];
let _selectedTitles = [];

const DepartmentsAndTitles = ({
    onChange,
    savedTitles,
    savedDepartments,
    filterSelected,
    handleSavedFilterChangedWarning,
}) => {
    const [departmentsAndTitles, setDepartmentsAndTitles] = useState([]);
    const [displaySearchCloseIcon, setDisplaySearchCloseIcon] = useState(false);
    const [savedSelection, setSavedSelection] = useState();
    const [showWarning, setShowWarning] = useState(false);
    const { getTitlesFilter } = useTitles();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setSavedSelection({
            departments: savedDepartments,
            titles: savedTitles,
        });
        _selectedDepartments = savedDepartments;
        _selectedTitles = savedTitles;
    }, [savedDepartments, savedTitles]);

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            loadDepartmentsAndTitles(
                savedSelection?.departments,
                savedSelection?.titles
            );
        }
    }, [savedSelection]);

    const getDepartmentsAndTitles = async () => {
        const data = await getTitles();
        if (data) return Transform.titles(data);
        else return [];
    };

    const handleDepartmentAndTitleChange = async (
        currentNode,
        selectedNodes
    ) => {
        _selectedDepartments = selectedNodes
            .filter((item) => item._depth === 0)
            .map((item) => {
                return { value: item.value, label: item.tagLabel };
            });
        _selectedTitles = selectedNodes
            .filter((item) => item._depth === 1)
            .map((item) => {
                return { value: item.value, label: item.tagLabel };
            });

        if (!currentNode.checked) {
            loadDepartmentsAndTitles(_selectedDepartments, _selectedTitles);
            onChange(_selectedDepartments, _selectedTitles);
        }
    };

    const handleDepartmentAndTitleBlur = async () => {
        setDisplaySearchCloseIcon(false);
        loadDepartmentsAndTitles(_selectedDepartments, _selectedTitles);
        onChange(_selectedDepartments, _selectedTitles);
    };

    const handleDepartmentAndTitleFocus = () => {
        setDisplaySearchCloseIcon(true);
    };

    const handleDepartmentAndTitleClearSearch = (e) => {
        e.preventDefault();
        let element = document.querySelector(".search");
        if (element) {
            element.value = "";
        }
    };

    const handleClear = async () => {
        _selectedDepartments = [];
        _selectedTitles = [];
        loadDepartmentsAndTitles(_selectedDepartments, _selectedTitles);
        onChange(_selectedDepartments, _selectedTitles);
    };

    const loadDepartmentsAndTitles = async (
        selectedDepartments,
        selectedTitles
    ) => {
        let data = departmentsAndTitles;
        if (!departmentsAndTitles || departmentsAndTitles?.length === 0)
            data = await getDepartmentsAndTitles();

        const departmentsAndTitlesCopy = data.map((department) => {
            const departmentChecked =
                selectedDepartments &&
                selectedDepartments.find(
                    (item) => item.value === department.value
                );
            return {
                ...department,
                checked: departmentChecked,
                children:
                    department.children &&
                    department.children.map((title) => ({
                        ...title,
                        checked:
                            departmentChecked ||
                            (selectedTitles &&
                                selectedTitles.find(
                                    (item) => item.value === title.value
                                )),
                    })),
            };
        });

        if (filterSelected) {
            const validation =
                JSON.stringify(
                    savedSelection?.departments.map(
                        (department) => department.value
                    )
                ) !==
                    JSON.stringify(
                        selectedDepartments.map(
                            (department) => department.value
                        )
                    ) ||
                JSON.stringify(
                    savedSelection?.titles.map((department) => department.value)
                ) !==
                    JSON.stringify(
                        selectedTitles.map((department) => department.value)
                    );

            setShowWarning(filterSelected && validation);
        } else {
            setShowWarning(false);
        }

        setDepartmentsAndTitles(departmentsAndTitlesCopy);
    };

    const getTitles = async (departmentIds = null) => {
        return getTitlesFilter({
            departmentid: departmentIds,
        }).then((payload) => {
            setIsLoading(false);
            if (payload && !payload.error) {
                return payload;
            }
        });
    };

    useEffect(() => {
        if (showWarning) {
            handleSavedFilterChangedWarning(true);
        }
    }, [showWarning]);

    return (
        <>
            <Subtitle>
                <Text variant="medium">Department or Title</Text>

                <Link
                    onClick={() => handleClear()}
                    style={{ marginLeft: "1rem", paddingTop: "4px" }}
                    disabled={
                        isLoading ||
                        (_selectedDepartments.length === 0 &&
                            _selectedTitles.length === 0)
                    }
                >
                    Clear
                </Link>
            </Subtitle>

            <Dropdown
                data={departmentsAndTitles}
                placeholder="Search for a Department or Title"
                searchLabel="Search for a Department or Title"
                mode="multiSelect"
                inlineSearch={true}
                showPartial={true}
                displaySearchCloseIcon={displaySearchCloseIcon}
                handleOnClickClearSearchFieldData={
                    handleDepartmentAndTitleClearSearch
                }
                onChange={(currentNode, selectedNodes) =>
                    handleDepartmentAndTitleChange(currentNode, selectedNodes)
                }
                onBlur={() => handleDepartmentAndTitleBlur()}
                onFocus={() => handleDepartmentAndTitleFocus()}
                disabled={
                    !departmentsAndTitles || departmentsAndTitles.length === 0
                }
                className="dropdown-custom-content departmenttitledd"
            />

            {showWarning && <WarningBox>{warningMessage}</WarningBox>}
        </>
    );
};
DepartmentsAndTitles.propTypes = {};

export default DepartmentsAndTitles;
