import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import IconButton from "components/UI/IconButton";
import Magnifier from "components/Icon/Magnifier";
import Text from "components/design-system/ui/Text";

export const ControlContainer = styled(Container)`
    background-color: ${global.config.colors.gray8};
    margin: 0;
    padding: 0;
    z-index: 101;
`;

export const IndentColumn = styled(Col)`
    flex: 0 0 10px;
`;
export const ArrowRow = styled(Row)`
    padding: 2rem 0 0.5rem 0;
    margin: 0;
`;
export const DateRow = styled(Row)`
    padding: 0;
    padding-bottom: 1.3rem;
    margin: 0;
`;
export const DateColumn = styled(Col)`
    white-space: nowrap;
    padding: 0;
`;

export const SyncColumnLabel = styled(Col)`
    white-space: nowrap;
    text-align: left;
    line-height: 16px;
    letter-spacing: 0em;
    height: 30px;
    padding-top: 7.25px;
    display: inherit;
`;

export const DateWrapper = styled.div`
    display: flex;
`;

export const Date = styled.h3`
    font-size: ${global.config.fonts.sizes.large};
    font-family: ${global.config.fonts.families.bold};
    margin-bottom: 0;
`;
export const ArrowWrapper = styled.div`
    display: inline-block;
    padding-top: 5px;
    padding-left: 0.5rem;
    * > svg {
        margin-top: -0.25em;
    }
`;
export const LeftArrow = styled.a`
    cursor: pointer;
`;
export const RightArrow = styled.a`
    cursor: pointer;
`;
export const TodayButton = styled.a`
    margin: 0.5em;
`;

export const ControlRow = styled(Row)`
    padding: 0.5em 0.5em 0.5em 0.5em;
    margin: 0;
`;

export const ControlsColumn = styled(Col)`
    padding: 0.1em 0.1em 0.1em 0.1em;
`;

export const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: 10px;
`;

export const RightWrapper = styled.div`
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2rem;
`;

export const SelectedText = styled.span`
    color: ${global.config.colors.gray2};
`;
export const SearchColumn = styled(Col)`
    text-align: right;
    padding-right: 0;
    > button {
        padding-right: 0;
    }
`;
export const CustomGroupWrapper = styled.div`
    cursor: pointer;
    display: inline-flex;
    vertical-align: top;
    margin-top: 0.1rem;
    > svg {
        margin-right: 0.5em;
    }
`;
export const FilterControl = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    padding: 0.36rem 0.5625rem;
    gap: 0.5625rem;
    font-size: ${global.config.fonts.sizes.small};
    > span {
        font-size: ${global.config.fonts.sizes.small};
    }
    &:hover {
        background-color: ${global.config.colors.gray7};
    }
`;

export const FilterButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5625rem;
`;

export const ResultsLabel = styled.div`
    font-size: ${global.config.fonts.sizes.small};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Message = styled.div``;
export const Actions = styled.div`
    margin-top: 1em;
`;

export const MessageWrapper = styled.div`
    text-align: center;
    padding: 6em 0;
    display: grid;
    justify-content: center;
    align-content: center;
    ${Message} {
    }
    ${Actions} {
    }
`;

export const BlackSpan = styled.span`
    color: ${global.config.colors.black};
`;

export const StyledIconButton = styled(IconButton)`
    border-radius: 0.125rem;
    padding: 0.2rem 0.2rem 0.1rem 0.15rem;
    min-width: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
    min-height: 1.5rem;
    height: 1.5rem;
    max-height: 1.5rem;
`;

export const StyledMagnifier = styled(Magnifier)`
    margin-left: -0.3rem;
    margin-top: -0.3rem;
`;

export const SyncLabelIcos = styled.div`
    padding-left: 5px;
    display: inline-block; 
    vertical-align: top; 
    padding-top: 5px;"
`;

export const SyncLabelText = styled(Text)`
    padding-left: 3px; 
    display: inline-block; 
    vertical-align: top; 
    padding-top: 8px;
`;
