import React, { useContext } from "react";
import styled from "styled-components/macro";

import Loading from "components/UI/Loading";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import { AllotmentTypes } from "resources/Enums";
import EmptyState from "components/design-system/tables/EmptyState";
import SPACING from "components/design-system/config/spacing";
import COLORS from "components/design-system/config/colors";
import SummaryTimeOffCard from "pages/timesheet/time-off/SummaryTimeOffCard";

import SessionContext from "context/app/SessionContext";

import useTimeOffApi from "pages/timesheet/time-off/useTimeOff";

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`;

const StyledHStack = styled(HStack)`
    gap: 0.8rem;
`;

const getYear = (isNext = false) => {
    return isNext ? new Date().getFullYear() + 1 : new Date().getFullYear();
};

const SummaryTimeOff = () => {
    const title = "Usage";
    const session = useContext(SessionContext);

    const { useTimeOffSummaryCards } = useTimeOffApi();

    const timeOffSummary = useTimeOffSummaryCards({
        employeeId: session.legacyId,
        year: getYear(),
    });

    const nextYearsTimeOffSummary = useTimeOffSummaryCards({
        employeeId: session.legacyId,
        year: getYear(true),
    });

    if (timeOffSummary.isError || nextYearsTimeOffSummary.isError) {
        return "Error";
    }

    const nextYearSummaryExist =
        nextYearsTimeOffSummary?.data?.length > 0 &&
        !nextYearsTimeOffSummary?.data?.every((s) => s.allotment == null);

    const renderSummaryCards = (data) => {
        return (
            <StyledHStack wrap={true}>
                {data.map((timeOff) => (
                    <SummaryTimeOffCard
                        isSmallSize={nextYearSummaryExist}
                        remainingTime={timeOff.daysRemaining}
                        totalTime={timeOff.allotment}
                        title={timeOff.reasonName}
                        key={timeOff.reasonName}
                    />
                ))}
            </StyledHStack>
        );
    };

    return (
        <>
            <VStack>
                <Text variant={"Headline_4"} color={COLORS.Gray_1}>
                    {title}
                </Text>
            </VStack>
            <VStack>
                {timeOffSummary.isLoading ||
                nextYearsTimeOffSummary.isLoading ? (
                    <LoadingWrapper>
                        <Loading text="Loading summary..." />
                    </LoadingWrapper>
                ) : !timeOffSummary?.data ||
                  timeOffSummary?.data?.length === 0 ? (
                    <EmptyState message="There are not allotments loaded for current year" />
                ) : (
                    <VStack spacing={SPACING.Zero_5}>
                        {nextYearSummaryExist && (
                            <Text variant={"Headline_5"} color={COLORS.Gray_1}>
                                {getYear()}
                            </Text>
                        )}
                        {renderSummaryCards(timeOffSummary.data)}
                    </VStack>
                )}

                {!timeOffSummary.isLoading &&
                    !nextYearsTimeOffSummary.isLoading &&
                    nextYearSummaryExist && (
                        <VStack spacing={SPACING.Zero_5}>
                            <Text variant={"Headline_5"} color={COLORS.Gray_1}>
                                {getYear(true)}
                            </Text>
                            {renderSummaryCards(nextYearsTimeOffSummary.data)}
                        </VStack>
                    )}
            </VStack>
        </>
    );
};

export default SummaryTimeOff;
