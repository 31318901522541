import React from "react";
import { useErrors } from "hooks/Errors/useErrors";

const ErrorHandler = ({ error }) => {
    const { handleError } = useErrors();
    console.log("ERROR", error);
    handleError(error, null, false);
    return <></>;
};

class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error: error, info: info });
    }

    componentDidMount() {
        window.onunhandledrejection = (error) => {
            this.setState({ hasError: true, error: error });
        };
    }

    render() {
        return this.state.hasError ? (
            <ErrorHandler error={this.state.error} />
        ) : (
            <React.Fragment>{this.props.children}</React.Fragment>
        );
    }
}

export default AppErrorBoundary;
