import styled, { keyframes } from "styled-components";

import { COLOR_VALUES } from "../config/colors";

const rotate1 = keyframes`
  0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
`;

const rotate2 = keyframes`
  0% {
    transform: scaleY(1) rotate(0deg);
 }

 49.99% {
    transform: scaleY(1) rotate(135deg);
 }

 50% {
    transform: scaleY(-1) rotate(0deg);
 }

 100% {
    transform: scaleY(-1) rotate(-135deg);
 }
`;

const Spinner = styled.div`
    width: 20px;
    height: 20px;
    position: relative;
    /* border-radius: 50%;
    border: 4px solid red; */

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
    }

    &:before {
        border-color: ${COLOR_VALUES.Gray_7};
    }

    &:after {
        border-color: ${COLOR_VALUES.Gray_1};
        animation: ${rotate1} 0.8s infinite linear alternate,
            ${rotate2} 1.6s infinite linear;
    }
`;

export default Spinner;
