import React from "react";
import styled from "styled-components/macro";
import { useFormContext, useController } from "react-hook-form";

import VStack from "components/design-system/layout/VStack";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import { getFeedbackProps } from "components/design-system/forms/utils";
import ToggleButton, {
    StyledLabel,
    WrapperToggleButton,
} from "components/design-system/controls/toggleButton/ToggleButton";
import Text from "components/design-system/ui/Text";

const Wrapper = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;

    &:not(:first-child) {
    }

    &:not(:last-child) {
    }
`;

const ToggleInputForm = ({
    name,
    label,
    defaultSelection,
    validations = {},
    disabled,
}) => {
    const {
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        name,
        rules: validations,
        defaultValue: defaultSelection ? defaultSelection : false,
    });

    const { value, onChange } = field;

    const fieldErrors = errors[name];
    const feedbackProps = getFeedbackProps(fieldErrors);

    return (
        <Wrapper>
            <VStack>
                <WrapperToggleButton $disabled={disabled}>
                    {label && (
                        <StyledLabel
                            spacing="Zero_5"
                            align="center"
                            $disabled={disabled}
                            $checked={value}
                        >
                            <ToggleButton
                                disabled={disabled}
                                checked={value}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                }}
                            />
                            <Text variant={value ? "Body_2_2" : "Body_2_1"}>
                                {value ? label[1] : label[0]}
                            </Text>
                        </StyledLabel>
                    )}
                </WrapperToggleButton>
                {feedbackProps.feedbackMessage && (
                    <FormInputWrapper {...feedbackProps} />
                )}
            </VStack>
        </Wrapper>
    );
};

export default ToggleInputForm;
