import styled, { css } from "styled-components";
import { COLOR_VALUES } from "../../config/colors";
import Text from "../../ui/Text";
import HStack from "../../layout/HStack";
import { FEEDBACK_TYPES } from "../../config/feedbackTypes";
import { TEXT_VARIANT_STYLES } from "../../ui/Text";

export const TextareaWrapper = styled.div`
    position: relative;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const StyledTextarea = styled.textarea`
    ${TEXT_VARIANT_STYLES.Body_1_1};
    display: block;
    width: 100%;
    min-height: 64px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    background: ${COLOR_VALUES.White};
    color: ${COLOR_VALUES.Gray_2};
    border-radius: 4px;
    cursor: pointer;
    padding: 11px 47px 0 11px;

    &::placeholder {
        color: ${COLOR_VALUES.Gray_4};
    }

    &:hover,
    &:visited {
        border-color: ${COLOR_VALUES.Gray_4};
    }

    &:active {
        border-color: ${COLOR_VALUES.Black};
    }

    &:focus {
        border-color: ${COLOR_VALUES.Black};
        outline: none;
        color: ${COLOR_VALUES.Black};
    }

    &:disabled {
        pointer-events: none;
        background: ${COLOR_VALUES.Gray_9};
        border-color: ${COLOR_VALUES.Gray_6};
    }

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Success
            ? css`
                  border-color: ${COLOR_VALUES.Gray_4};

                  &:active {
                      border-color: ${COLOR_VALUES.Gray_4};
                  }

                  &:hover,
                  &:focus,
                  &:focus-visible {
                      border-color: ${COLOR_VALUES.Gray_4};
                  }
              `
            : props.feedbackType === FEEDBACK_TYPES.Warning
            ? css`
                  border-color: ${COLOR_VALUES.Yellow};

                  &:active {
                      border-color: ${COLOR_VALUES.Yellow};
                  }

                  &:hover,
                  &:focus,
                  &:focus-visible {
                      border-color: ${COLOR_VALUES.Yellow};
                  }
              `
            : props.feedbackType === FEEDBACK_TYPES.Error &&
              css`
                  border-color: ${COLOR_VALUES.Orange_1};

                  &:active {
                      border-color: ${COLOR_VALUES.Orange_1};
                  }

                  &:hover,
                  &:focus,
                  &:focus-visible {
                      border-color: ${COLOR_VALUES.Orange_1};
                  }
              `}
`;
export const InputTextareaRow = styled(HStack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 8px;
`;

export const StyledTextareaClearIcon = styled.button`
    background: transparent;
    position: absolute;
    width: 16px;
    height: 16px;
    right: 12px;
    top: 16px;
    border: none;
`;
export const StyledCharacterCounter = styled(Text)`
    background: transparent;
    position: absolute;
    width: 16px;
    height: 16px;
    border: none;
    top: 16px;
    right: 26px;
`;
