import Snackbar from "components/design-system/ui/Snackbar";
import { Store } from "react-notifications-component";

export function responseErrorNotification(
    correlationID,
    message,
    title,
    code = 0
) {
    switch (code) {
        case null:
            //ERR_CONNECTION_REFUSED
            errorNotification(
                "Cannot connect to the system",
                "Check your VPN or Internet connection."
            );
            break;
        case 401:
            errorNotification(
                correlationID,
                "Unauthorized. You cannot perform this action."
            );
            break;
        case 500:
            errorNotification("Server Error: " + correlationID, message);
            break;

        default:
            errorNotification(title + ": " + correlationID, message);
            break;
    }
}

export function errorNotification(title, message = "Something went wrong") {
    const type = "danger";

    sendNotification(title, message, type);
}

export async function sendNotification(
    _title,
    message,
    duration = global.config.notification.duration,
    id = "",
    onRemoval = void 0,
    close,
    action,
    actionProps,
    icon,
    feedbackIcon
) {
    const notificationId = Store.addNotification({
        id: id,
        content: (
            <Snackbar
                message={message}
                close={close}
                action={action}
                actionProps={actionProps}
                icon={icon}
                feedbackIcon={feedbackIcon}
                onClose={() => {
                    Store.removeNotification(notificationId);
                }}
            />
        ),
        insert: "top",
        container: "bottom-left",
        onRemoval: onRemoval,
        dismiss: {
            duration: duration,
            pauseOnHover: true,
            click: false,
            touch: false,
        },
    });
}
export function removeNotifications(notifications) {
    notifications.map((item) => Store.removeNotification(item));
}
