import { useState, useEffect } from "react";
import { usePageAuthorization } from "utilities/API/Authorization/PageAuthorization";

export const usePageAccess = (pageRoute) => {
    const { getPageAuthorization } = usePageAuthorization();
    const [authorized, setAuthorized] = useState(null);

    const getAuthorization = async (route) => {
        if (route) {
            await getPageAuthorization(route).then((response) => {
                setAuthorized(response);
            });
        }
    };

    useEffect(async () => {
        await getAuthorization(pageRoute);
    }, [pageRoute]);

    return { authorized };
};
