import React from "react";
import styled from "styled-components";

import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";

export const DEFAULT_MESSAGE_1 = "Business sensitive information";
export const DEFAULT_MESSAGE_2 = "for internal use only.";

const StyledFooter = styled.footer`
    width: 100%;
`;

const StyledWrapper = styled(HStack)`
    align-items: flex-start;
    justify-content: flex-end;
    padding: 32px 64px;
    gap: 4px;
    height: 5rem;
`;

const Footer = ({
    message_1 = DEFAULT_MESSAGE_1,
    message_2 = DEFAULT_MESSAGE_2,
    children,
}) => {
    return (
        <>
            <StyledFooter className="footer">
                {children ?? (
                    <StyledWrapper>
                        <Text variant="Descriptive_1_1" color="Gray_3">
                            {message_1}
                        </Text>
                        <Text variant="Descriptive_1_1" color="Gray_2">
                            {message_2}
                        </Text>
                    </StyledWrapper>
                )}
            </StyledFooter>
        </>
    );
};

export default Footer;
