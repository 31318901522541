import { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import AgencyForm from "./AgencyForm";
import AgencyTable from "./AgencyTable";
import PageWrapper from "../../PageWrapper";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal title="Add new agency" onRequestClose={onClose}>
                <AgencyForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal title="Edit agency" onRequestClose={onClose}>
                <AgencyForm
                    agencyIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const Agencies = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const now = new Date();

    insightsTrace("VIEW", "AdminAgenciesPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    return (
        <PageWrapper
            title="Agencies"
            menuOptions={[{ children: "Admin", to: "/admin" }]}
        >
            <FormPresenter />
            <AgencyTable />
        </PageWrapper>
    );
};

export default Agencies;
