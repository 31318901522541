import { useContext } from "react";
import { isEqual } from "lodash";

import usePageActionPermission from "hooks/Access/usePageActionPermission";
import SessionContext from "context/app/SessionContext";

const FILTER_KEYS = [
    "locations",
    "agencyId",
    "regionId",
    "officeId",
    "groupId",
    "departmentId",
    "titleId",
    "clientFamilyId",
    "clientId",
    "retainerId",
    "jobId",
    "display",
    "customGroupId",
];

export const FILTER_DEFAULTS = {
    agencyId: 1,
    locations: [],
};

const queryHasFiltersApplied = (query) =>
    FILTER_KEYS.some(
        (key) => query[key] && !isEqual(query[key], FILTER_DEFAULTS[key])
    );

export const useAllocationsVisibilityOfUser = (userId) => {
    const sessionData = useContext(SessionContext);
    const canViewOthersAllocations = usePageActionPermission(
        "AC_VIEW_OTHER_EMP_ALLOCATION"
    );
    const canViewThisAllocation =
        sessionData?.legacyId === userId || canViewOthersAllocations;

    return canViewThisAllocation;
};

export const useAllocationsQueryPermissionTester = () => {
    const sessionData = useContext(SessionContext);
    const canViewCustomGroups = usePageActionPermission(
        "AC_ADD_EDIT_VIEW_CUSTOM_GROUP"
    );
    const canViewFilters = usePageActionPermission("AC_VIEW_FILTER");
    const canViewOthersAllocations = usePageActionPermission(
        "AC_VIEW_OTHER_EMP_ALLOCATION"
    );

    const tester = (query) => {
        if (query.customGroupId) {
            return {
                hasAccess: canViewCustomGroups,
                actionCode: "AC_ADD_EDIT_VIEW_CUSTOM_GROUP",
            };
        } else if (queryHasFiltersApplied(query)) {
            return {
                hasAccess: canViewFilters,
                actionCode: "AC_VIEW_FILTER",
            };
        } else if (query.userId) {
            return {
                hasAccess:
                    sessionData?.legacyId === parseInt(query.userId) ||
                    canViewOthersAllocations,
                actionCode: "AC_VIEW_OTHER_EMP_ALLOCATION",
            };
        } else {
            return {
                hasAccess: canViewOthersAllocations,
                actionCode: "AC_VIEW_OTHER_EMP_ALLOCATION",
            };
        }
    };

    return tester;
};
