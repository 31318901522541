import Arrow from "components/Icon/Arrow";

const CopyNextHoursButtonLayer = ({
    onClick,
    color,
    ...props
}) => {

    const handleClick = (e) => {
        onClick(e);
    };

    return (
        <div onClick={handleClick}  {...props}>
            <Arrow size={"12"} direction="right" hover style={{stroke: {color}}} />
        </div>
    );
};

export default CopyNextHoursButtonLayer;
