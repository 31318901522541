import { Controller } from "react-hook-form";
import * as React from "react";
import TextAreaInput from "./TextAreaInput";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMessageContainer from "./ErrorMessageContainer";
import Validation from "resources/Validation";

const TextAreaInputWithValidation = React.forwardRef((props, forwardedRef) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue || ""}
            render={({
                field: { onChange, onBlur, value, name: fieldName, ref },
            }) => (
                <>
                    <TextAreaInput
                        label={props.label}
                        {...props}
                        onChange={(e) => {
                            onChange(e);

                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                        value={value}
                        onBlur={onBlur}
                        name={fieldName}
                        className={
                            !props.className &&
                            props.errors[fieldName] &&
                            "error"
                        }
                        defaultValue={props.defaultValue || ""}
                        ref={(e) => {
                            ref(e);
                            forwardedRef.current = e;
                        }}
                    />
                    <ErrorMessage
                        errors={props.errors}
                        name={fieldName}
                        as={<ErrorMessageContainer />}
                    />
                </>
            )}
            rules={
                !props.rules && {
                    required:
                        props.required && Validation.required + props.name,
                    maxLength: props.maxLength && {
                        value: props.maxLength,
                        message: Validation.maxLength + props.maxLength,
                    },
                }
            }
        />
    );
});

export default TextAreaInputWithValidation;
