import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Alert from "../Icon/Alert";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    * > svg {
        position: absolute;
        pointer-events: none;
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        line-height: 1;
        right: 1rem;
        top: 1rem;
    }
    &:focus-within {
        > label {
            transform: translate(0, 10px) scale(0.75);
            color: ${global.config.colors.black};
        }
    }
`;

const TextArea = styled.textarea`
    border-radius: ${global.config.sizes.borderRadius};
    border: ${global.config.border.small};
    padding: 1.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    resize: none;
    &:focus {
        border: ${global.config.border.smallBlack};
    }
    input::-webkit-input-placeholder,
    ::placeholder {
        color: ${global.config.colors.gray2};
        opacity: 1;
        font-size: ${global.config.fonts.sizes.smedium};
        font-family: ${global.config.fonts.families.light};
    }
    ${(props) =>
        props.error &&
        `
        border: solid 0.01rem ${global.config.colors.red1};
        &:focus {
            box-shadow: 0 0 0 0.01rem ${global.config.colors.red1};
        }
    `}
`;
const Error = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${global.config.border.white};
    width: 3rem;
    height: 3rem;
    border-radius: 0.3rem;
`;

const TextAreaInput = React.forwardRef((props, ref) => {
    const { label, value } = props;

    function handleChange(e) {
        props.onChange(e);
    }

    return (
        <Container>
            <TextArea
                value={value}
                onChange={handleChange}
                error={
                    props.className && props.className.includes("error") > 0
                        ? true
                        : false
                }
                disabled={props.disabled}
                placeholder={label}
                ref={ref}
            />
            {props.className && props.className.includes("error") && (
                <Error>
                    <Alert />
                </Error>
            )}
        </Container>
    );
});

TextAreaInput.defaultProps = {
    variant: "text",
    defaultValue: "",
};

TextAreaInput.propTypes = {
    label: PropTypes.string,
};

export default TextAreaInput;
