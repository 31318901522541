import React, { useContext, useEffect } from "react";
import { CalendarDate, today, getLocalTimeZone } from "@internationalized/date";
import styled from "styled-components";
import { startOfWeek, format } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";

import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import Icon from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import PrimaryButton from "components/design-system/controls/button/PrimaryButton";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Tooltip from "components/design-system/ui/Tooltip";

import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import SessionContext from "context/app/SessionContext";
import SubmitTimesheetContext from "context/Timesheet/SubmitTimesheetContext";
import TimesheetGridContext from "context/Timesheet/TimesheetGridContext";
import { useWeeksTimeEntriesApi } from "pages/timesheet/timesheet-grid/useTimesheetGrid";

const StyledTimesheetControl = styled(HStack)`
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 32px;
`;

const now = new Date();
export const currentWeekStartDate = format(
    startOfWeek(now, { weekStartsOn: 1 }),
    "yyyy-M-d"
);

export const previousWeekStartDate = format(
    startOfWeek(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
        { weekStartsOn: 1 }
    ),
    "yyyy-M-d"
);

const SubmitTimesheet = () => {
    const session = useContext(SessionContext);
    const { selectedTimesheetStartDate, pendingTimesheetCalendarDate } =
        useContext(WeekNavigatorContext);

    const {
        selectedDateTimesheetStatus,
        setSelectedDateTimesheetStatus,
        hoursUpdateInProgress,
        hoursUpdateState,
        setHoursUpdateState,
    } = useContext(SubmitTimesheetContext);
    const { setPendingTimesheetFlag } = useContext(WeekNavigatorContext);
    const { disableSubmitTimesheetButton, totalOfWeekHours } =
        useContext(TimesheetGridContext);

    const { useWeeksTimeEntries, submitTimesheet, reopenTimesheet } =
        useWeeksTimeEntriesApi();

    let fromDateVal = selectedTimesheetStartDate ?? today(getLocalTimeZone());
    let formatFromDateVal = `${fromDateVal.year}-${fromDateVal.month}-${fromDateVal.day}`;

    const requestData = {
        employeeId: session.legacyId,
        fromDate: formatFromDateVal,
    };

    const userData = useWeeksTimeEntries(requestData);

    useEffect(() => {
        if (hoursUpdateState) {
            setHoursUpdateState(false);
        }
    }, [selectedTimesheetStartDate]);

    useEffect(() => {
        let formatPendingTImesheetDate = `${pendingTimesheetCalendarDate?.year}-${pendingTimesheetCalendarDate?.month}-${pendingTimesheetCalendarDate?.day}`;
        if (formatPendingTImesheetDate === formatFromDateVal) {
            setPendingTimesheetFlag(true);
        } else {
            setPendingTimesheetFlag(false);
        }
    }, [selectedTimesheetStartDate]);

    useEffect(() => {
        if (selectedTimesheetStartDate) {
            const completed = userData?.data
                ?.filter((data) => {
                    let filterDate = new CalendarDate(
                        "AD",
                        format(new Date(data.date), "yyyy"),
                        format(new Date(data.date), "M"),
                        format(new Date(data.date), "d")
                    );
                    let formatFilterDate = `${filterDate.year}-${filterDate.month}-${filterDate.day}`;

                    return formatFromDateVal === formatFilterDate;
                })
                .some((filterData) => filterData?.completed);
            setSelectedDateTimesheetStatus(completed);
        }
    }, [userData, setSelectedDateTimesheetStatus]);

    const modifier = !selectedDateTimesheetStatus
        ? submitTimesheet
        : reopenTimesheet;

    const handleSubmit = () => {
        const postData = {
            employeeId: session.legacyId,
            weekDate: fromDateVal,
        };

        modifier.mutateAsync(postData).then(() => {});
    };

    return (
        <>
            <VStack spacing="Two" align="flex-end">
                <StyledTimesheetControl>
                    <HStack spacing="Zero_25" align="center">
                        {hoursUpdateState && (
                            <>
                                {hoursUpdateInProgress ? (
                                    <Tooltip text="Saving..." enterDelay={0}>
                                        <div>
                                            <CircularProgress
                                                style={{
                                                    color: COLOR_VALUES.Gray_4,
                                                    height: "16px",
                                                    width: "16px",
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        text="Changes Saved"
                                        enterDelay={0}
                                    >
                                        <div>
                                            <Icon
                                                name="success"
                                                color="Gray_2"
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        <Text variant="Body_1_1" color="Gray_2">
                            Total for this Week:
                        </Text>
                        <Text variant="Body_1_1" color="Black">
                            {totalOfWeekHours} Hours
                        </Text>
                    </HStack>
                    {!selectedDateTimesheetStatus ? (
                        <PrimaryButton
                            label="Submit Timesheet"
                            onClick={handleSubmit}
                            disabled={
                                totalOfWeekHours === 0 ||
                                disableSubmitTimesheetButton
                            }
                        />
                    ) : (
                        (previousWeekStartDate === formatFromDateVal ||
                            currentWeekStartDate === formatFromDateVal ||
                            new Date(currentWeekStartDate) <
                                new Date(formatFromDateVal)) && (
                            <SecondaryButton
                                label="Re-Open Timesheet"
                                leadingIcon="edit"
                                onClick={handleSubmit}
                            />
                        )
                    )}
                </StyledTimesheetControl>
            </VStack>
        </>
    );
};

export default SubmitTimesheet;
