import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";
import { AllotmentTypes } from "resources/Enums";

const useTimeOffApi = () => {
    const { getDataAsync, postData, putData, deleteData } = useApi();
    const queryClient = useQueryClient();

    const getTimeOffByReasonsApiCall = useCallback(
        async ({ employeeId, year }) => {
            const response = await getDataAsync("timeOffByReasons", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$year", value: year }
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getTimeOffRequetsApiCall = useCallback(
        async ({ employeeId }) => {
            const response = await getDataAsync("getTimeOffRequests", {
                urlParams: [{ name: "$employeeId", value: employeeId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getTimeOffByIdApiCall = useCallback(
        async ({ id }) => {
            const response = await getDataAsync("getTimeOffById", {
                urlParams: [{ name: "$id", value: id }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getTimeOffReasonsForNewRequestApiCall = useCallback(
        async ({ employeeId }) => {
            const response = await getDataAsync(
                "getTimeOffReasonsForNewRequest",
                {
                    urlParams: [{ name: "$employeeId", value: employeeId }],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getEditableReasonsForTimesheetApiCall = useCallback(
        async ({ employeeId, year }) => {
            const response = await getDataAsync("getEditableReasonsForTimesheet", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$year", value: year },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getUsedAllotmentByYearApiCall = useCallback(
        async ({ userId, year }) => {
            const response = await getDataAsync("getUsedAllotmentByYear", {
                urlParams: [
                    { name: "$userId", value: userId },
                    { name: "$year", value: year },
                ],
            });
            return response.status !== 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getGetTimeOffApproverApiCall = useCallback(
        async ({ employeeId }) => {
            if (!employeeId || employeeId <= 0) {
                return null;
            }
            const response = await getDataAsync("userDetail", {
                urlParams: [{ name: "$userId", value: employeeId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getOfficeHolidaysApiCall = useCallback(
        async ({ officeId }) => {
            const response = await getDataAsync("getHolidaysByOffice", {
                urlParams: [{ name: "$officeId", value: officeId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getPendingHRTimeOffByDateApiCall = useCallback(
        async ({ from, until }) => {
            const response = await getDataAsync("getPendingHRTimeOffByDate", {
                urlParams: [
                    { name: "$from", value: from },
                    { name: "$until", value: until }
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getApprovedTimeOffByDateApiCall = useCallback(
        async ({ from, until }) => {
            const response = await getDataAsync("getApprovedTimeOffByDate", {
                urlParams: [
                    { name: "$from", value: from },
                    { name: "$until", value: until }
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createTimeOffRequestApiCall = useCallback(
        (body) => {
            return postData("createTimeOffRequest", {
                body,
            });
        },
        [postData]
    );

    const editTimeOffRequestApiCall = useCallback(
        (body) => {
            return putData("editTimeOffRequest", {
                body,
            });
        },
        [putData]
    );

    const deleteTimeOffRequestApiCall = useCallback(
        ({ timeOffRequestId }) => {
            return deleteData("deleteTimeOffRequest", {
                urlParams: [
                    { name: "$timeOffRequestId", value: timeOffRequestId },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("getTimeOffRequests");
                queryClient.invalidateQueries("useTimeOffSummaryCards");
            },
        };
    }, [queryClient]);

    const createTimeOffRequest = useMutation(
        createTimeOffRequestApiCall,
        mutationOptions
    );

    const editTimeOffRequest = useMutation(
        editTimeOffRequestApiCall,
        mutationOptions
    );

    const deleteTimeOffRequest = useMutation(
        deleteTimeOffRequestApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useTimeOff = (dataObj) =>
            useQuery(["timeOffByReasons", dataObj], () =>
                getTimeOffByReasonsApiCall(dataObj)
            );

        const useTimeOffSummaryCards = (dataObj) =>
            useQuery(["useTimeOffSummaryCards", dataObj], () =>
                getTimeOffByReasonsApiCall(dataObj)
                    .then((to) => {
                        return to?.filter(x =>
                            x.allotment !== 0
                            && x.allotmentTypeId != AllotmentTypes.GENERALREST
                            && x.allotmentTypeId != AllotmentTypes.OTHERS)
                            ?? null;
                    })
            );

        const useGetTimeOffRequests = (dataObj) =>
            useQuery({
                queryKey: ["getTimeOffRequests", dataObj],
                queryFn: () => getTimeOffRequetsApiCall(dataObj),
            });

        const useGetTimeOffRequest = (dataObj) =>
            useQuery({
                queryKey: ["getTimeOffById", dataObj],
                queryFn: () => getTimeOffByIdApiCall(dataObj),
            });

        const useGetTimeOffReasonsForNewRequest = (dataObj) =>
            useQuery({
                queryKey: ["getTimeOffReasonsForNewRequest", dataObj],
                queryFn: () => getTimeOffReasonsForNewRequestApiCall(dataObj),
            });

        const useGetTimeOffApprover = (dataObj) =>
            useQuery(["getGetTimeOffApprover", dataObj], () =>
                getGetTimeOffApproverApiCall(dataObj)
            );

        const useOfficeHolidays = (dataObj) =>
            useQuery(["getOfficeHolidays", dataObj], () =>
                getOfficeHolidaysApiCall(dataObj)
            );

        const useGetPendingHRTimeOffByDate = (dataObj) =>
            useQuery({
                queryKey: ["getPendingHRTimeOffByDate", dataObj],
                queryFn: () => getPendingHRTimeOffByDateApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });

        const useGetApprovedTimeOffByDate = (dataObj) =>
            useQuery({
                queryKey: ["getApprovedTimeOffByDate", dataObj],
                queryFn: () => getApprovedTimeOffByDateApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });

        const useGetEditableReasonsForTimesheet = (dataObj) =>
            useQuery({
                queryKey: ["getEditableReasonsForTimesheet", dataObj],
                queryFn: () => getEditableReasonsForTimesheetApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: true,
            });

        const useGetUsedAllotmentByYear = (dataObj) =>
            useQuery({
                queryKey: ["getUsedAllotmentByYear", dataObj],
                queryFn: () => getUsedAllotmentByYearApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });

        return {
            useTimeOff,
            useTimeOffSummaryCards,
            useGetTimeOffRequests,
            useGetTimeOffRequest,
            useGetTimeOffReasonsForNewRequest,
            useGetTimeOffApprover,
            useOfficeHolidays,
            createTimeOffRequest,
            editTimeOffRequest,
            deleteTimeOffRequest,
            getTimeOffRequetsApiCall,
            useGetPendingHRTimeOffByDate,
            useGetApprovedTimeOffByDate,
            useGetEditableReasonsForTimesheet,
            useGetUsedAllotmentByYear
        };
    }, [createTimeOffRequest, editTimeOffRequest, deleteTimeOffRequest, getTimeOffRequetsApiCall, getTimeOffByReasonsApiCall, getTimeOffByIdApiCall, getTimeOffReasonsForNewRequestApiCall, getGetTimeOffApproverApiCall, getOfficeHolidaysApiCall, getPendingHRTimeOffByDateApiCall, getApprovedTimeOffByDateApiCall, getEditableReasonsForTimesheetApiCall]);
};

export default useTimeOffApi;
