import React from "react";
import { useDateFieldState } from "@react-stately/datepicker";
import { useDateField, useDateSegment } from "@react-aria/datepicker";
import { createCalendar } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import styled, { css } from "styled-components";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";

const StyledField = styled.div`
    display: inline-flex;
    user-select: none;
`;

const StyledSegment = styled.div`
    ${TEXT_VARIANT_STYLES.Body_1_1}

    ${(props) =>
        props.isSmallSize &&
        css`
            ${TEXT_VARIANT_STYLES.Body_2_1}
        `}

    text-align: center;

    &:focus {
        color: ${COLOR_VALUES.Black};
        background: ${COLOR_VALUES.Gray_5};
        outline: none;
        border-radius: 2px;
    }

    ${(props) =>
        props.isPlaceholder || props.disabled
            ? css`
                  color: ${COLOR_VALUES.Gray_4};
              `
            : css`
                  color: ${COLOR_VALUES.Black};
              `}

    ${(props) =>
        props.disabled &&
        css`
            user-select: none;
        `}
`;

function DateSegment({ segment, state, isSmallSize = false }) {
    const ref = React.useRef();
    const { segmentProps } = useDateSegment(segment, state, ref);

    return (
        <>
            <StyledSegment
                {...segmentProps}
                ref={ref}
                isPlaceholder={segment.isPlaceholder || state.value === null}
                isSmallSize={isSmallSize}
            >
                {segment.text}
            </StyledSegment>
        </>
    );
}

export function DateRangeField({ disabled, isSmallSize, ...props }) {
    const { locale } = useLocale();
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = React.useRef();
    const { fieldProps } = useDateField(props, state, ref);

    return (
        <StyledField {...fieldProps} ref={ref}>
            {state.segments.map((segment, i) =>
                disabled ? (
                    <StyledSegment
                        key={i}
                        disabled={disabled}
                        isPlaceholder={segment.isPlaceholder}
                        isSmallSize={isSmallSize}
                    >
                        {segment.text}
                    </StyledSegment>
                ) : (
                    <DateSegment
                        key={i}
                        segment={segment}
                        state={state}
                        isSmallSize={isSmallSize}
                    />
                )
            )}
        </StyledField>
    );
}
