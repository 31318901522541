import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import moment from "moment";

import Text from "components/design-system/ui/Text";
import DashboardDataItem from "components/design-system/ui/dashboard/DashboardDataItem";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Loading from "components/UI/Loading";

import { getCurrencyToLocaleString, getNumStrWithTwoDecimals } from "common-methods/commonMethods";

import useProjectsDashboardOverviewBudget, {
    useProjectByIdProjectsDashboard,
    useProjectTimesheetSummaryData,
} from "pages/projects/project-dashboard/single/useProjectsDashboardOverviewBudget";
import useProjectsDashboardData, {
    useProjectById,
    useProjectDashboardCurrency,
} from "pages/projects/project-dashboard/single/useProjectsDashboardData";
import { getReporterUrl } from "pages/projects/useExternalUrl";

const StyledHStack = styled(HStack)`
    ${(props) =>
        props.borderBottom &&
        css`
            border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
            padding-bottom: 40px;
        `}
    gap: 2rem;
`;

export const endpointPathText = {
    pcnFee: "pcnfee",
    totalBaseline: "totalbaseline",
    preAdjustedFee: "preadjustedfee",
    totalFee: "totalfee",
    discountInvestment: "adjustments",
    sowFee: "sowFee",
    percentComplete: "percentcomplete",
    estimateCompletion: "estimateatcompletion",
};

const getProjectDashCalculatedPercent = (data) => {
    if (
        typeof data.Used !== "number" ||
        typeof data.AtCompletion !== "number"
    ) {
        return "-";
    } else if (data.AtCompletion === 0) {
        return 0;
    } else {
        return (data.Used * 100) / data.AtCompletion;
    }
};

const BASELINE_WARNING_AMOUNT = 100;

const ProjectsDashboardOverviewData = () => {
    const { project } = useProjectById();
    const { projectId } = useParams();

    const { currency } = useProjectDashboardCurrency();
    const pcnFeeDL = useProjectsDashboardData(endpointPathText.pcnFee);
    const totalBaselineDL = useProjectsDashboardData(
        endpointPathText.totalBaseline
    );
    const preAdjustedFeeDL = useProjectsDashboardData(
        endpointPathText.preAdjustedFee
    );
    const totalFeeDL = useProjectsDashboardData(endpointPathText.totalFee);
    const discountInvestmentDL = useProjectsDashboardData(
        endpointPathText.discountInvestment
    );
    const sowFeeDL = useProjectsDashboardData(endpointPathText.sowFee);
    const percentCompleteDL = useProjectsDashboardData(
        endpointPathText.percentComplete
    );
    const estimateCompletionDL = useProjectsDashboardData(
        endpointPathText.estimateCompletion
    );

    // calculate percent complete start
    const { projectData } = useProjectByIdProjectsDashboard();
    const { useBudgetSummary } = useProjectsDashboardOverviewBudget();
    const currentDate = useRef(format(new Date(), "yyyy-MM-dd"));

    const startDateVal =
        projectData?.data?.ProjStartDate ?? currentDate.current;
    const endDateVal = projectData?.data?.CurrentEndDate ?? currentDate.current;

    const summaryMoney = useBudgetSummary({
        endpointSuffix: "money",
        startDate: startDateVal,
        endDate: endDateVal,
    });
    const [calculatedPercent, setCalculatedPercent] = useState(null);
    // calculate percent complete end

    useEffect(() => {
        if (!summaryMoney.isFetching && !projectData.isFetching) {
            const percentCalc = getProjectDashCalculatedPercent(summaryMoney?.data);
            if (calculatedPercent !== percentCalc) {
                setCalculatedPercent(percentCalc);
            }
        }
    }, [summaryMoney, projectData])

    //get Timesheet data
    const { projectTimesheetSummaryData } = useProjectTimesheetSummaryData();
    const timesheetSummaryData = projectTimesheetSummaryData?.data;        
    let approvedHours =
        (timesheetSummaryData?.approved * 100) /
        timesheetSummaryData?.completed;
    approvedHours = approvedHours ? Math.round(approvedHours) : 0;

    const ampersand = encodeURIComponent("&");

    const budgetBaseline = totalBaselineDL?.list?.data;
    const preAdjustedFee = preAdjustedFeeDL?.list?.data;
    const amount = preAdjustedFee > budgetBaseline  ? preAdjustedFee - budgetBaseline : budgetBaseline - preAdjustedFee;
    const displayBaselineWarning = amount >= BASELINE_WARNING_AMOUNT;

    const dataObj = [
        {
            borderBottom: false,
            data: [
                {
                    title: "Total Fee",
                    placeholder: "-",
                    res: endpointPathText.totalFee,
                },
            ],
        },
        {
            borderBottom: true,
            data: [
                {
                    title: "Pre-Adjusted Fee",
                    placeholder: "-",
                    res: endpointPathText.preAdjustedFee,
                },
                {
                    title: "Discounts & Investments",
                    placeholder: "-",
                    res: endpointPathText.discountInvestment,
                },
                {
                    title: "SOW Fee",
                    placeholder: project?.data?.IsNonBillable
                        ? "Not Defined"
                        : `${currency?.data?.Symbol}0.00`,
                    res: endpointPathText.sowFee,
                },
                {
                    title: "PCN Fee",
                    placeholder: "-",
                    res: endpointPathText.pcnFee,
                },
            ],
        },
        {
            borderBottom: true,
            data: [
                {
                    title: "Budget Baseline",
                    placeholder: "-",
                    res: endpointPathText.totalBaseline,
                    icon: "warning",
                    color: "Yellow",
                    iconTooltipText: `${currency?.data?.Symbol}${getCurrencyToLocaleString(amount)} difference to Total Pre-Adjusted Fee`,
                },
                {
                    title: "EAC (Input)",
                    placeholder: "-",
                    res: endpointPathText.estimateCompletion,
                    tooltipText: "Input from Health",
                },
                {
                    title: "Percent Complete (Input)",
                    placeholder:
                        typeof percentCompleteDL.list?.data === "number"
                            ? "0.00%"
                            : " ",
                    res: endpointPathText.percentComplete,
                    count: null,
                    isCurrency: false,
                    tooltipText: "Input from Health",
                },
                {
                    title: "Percent Complete (Calculated)",
                    placeholder:
                        typeof calculatedPercent === "number" ? "0.00%" : "-",
                    isCurrency: false,
                    isFetchingData: summaryMoney.isFetching || projectData.isFetching,
                    count: calculatedPercent,
                    tooltipText: "Calculated by Planner",
                },
            ],
        },
    ];

    const timesheetRow = [
        {
            title: "Incomplete / Complete",
            linkData: `${getNumStrWithTwoDecimals(timesheetSummaryData?.incompleted)} / ${getNumStrWithTwoDecimals(timesheetSummaryData?.completed)}`,
            description: `${getNumStrWithTwoDecimals(timesheetSummaryData?.total)} Hours Total`,
            feedbackMessage:
                timesheetSummaryData?.incompleted !== 0 &&
                "Unsubmitted timesheets",
            feedbackIcon: "warning",
            feedbackIconColor: "Yellow",
            href:
                timesheetSummaryData?.incompleted !== 0 &&
                getReporterUrl(
                    `TimeTNu/summary.aspx?dt=${moment(
                        timesheetSummaryData?.incompletedDate
                    ).format("MM-DD-YYYY")}${ampersand}job=${projectId}`
                ),
            isInternalUrlNewTab: true,
        },
        {
            title: "Hours Approved",
            linkData: `${approvedHours}% of ${getNumStrWithTwoDecimals(timesheetSummaryData?.completed)}`,
            description: "Completed Hours",
            feedbackMessage:
                timesheetSummaryData?.approved > 0 && approvedHours < 100 && "Review pending hours",
            feedbackIcon: "warning",
            feedbackIconColor: "Yellow",
            href:
                timesheetSummaryData?.approved > 0 && approvedHours < 100 &&
                getReporterUrl(
                    `TimeTNu/summary.aspx?dt=${moment(
                        timesheetSummaryData?.unsubmittedDate
                    ).format("MM-DD-YYYY")}${ampersand}job=${projectId}`
                ),
            isInternalUrlNewTab: true,
        },
    ];

    if (currency.isLoading || project.isLoading) {
        return <Loading />;
    }

    if (
        project.isError ||
        currency.isError ||
        summaryMoney.isError ||
        pcnFeeDL?.list?.isError ||
        totalBaselineDL?.list.isError ||
        preAdjustedFeeDL?.list.isError ||
        totalFeeDL?.list.isError ||
        discountInvestmentDL?.list.isError ||
        sowFeeDL?.list.isError ||
        percentCompleteDL.list.isError ||
        projectTimesheetSummaryData.isError ||
        estimateCompletionDL?.list?.isError
    ) {
        return <>Error</>;
    }

    const listMap = new Map([
        [endpointPathText.pcnFee, pcnFeeDL],
        [endpointPathText.totalBaseline, totalBaselineDL],
        [endpointPathText.preAdjustedFee, preAdjustedFeeDL],
        [endpointPathText.totalFee, totalFeeDL],
        [endpointPathText.discountInvestment, discountInvestmentDL],
        [endpointPathText.sowFee, sowFeeDL],
        [endpointPathText.percentComplete, percentCompleteDL],
        [endpointPathText.estimateCompletion, estimateCompletionDL],
    ]);

    const getDataDetail = (dataItem) => {
        return listMap.get(dataItem) || null;
    };

    return (
        <>
            {dataObj.map((row, rowIndex) => (
                <StyledHStack
                    key={`project-data-layout-${rowIndex + 1}`}
                    borderBottom={row.borderBottom}
                >
                    {row.data.map((item, itemIndex) => {
                        const dataItem = item.res
                            ? getDataDetail(item.res)
                            : [];

                        const count =
                            ((project?.data?.IsNonBillable &&
                                dataItem?.list?.data > 0) ||
                                item.res == "totalbaseline" ||
                                !project?.data?.IsNonBillable) &&
                            dataItem?.list?.data;

                        return item.res ? (
                            <DashboardDataItem
                                key={`projects-data-layout-item-${
                                    itemIndex + 1
                                }`}
                                title={item.title}
                                placeholder={
                                    project?.data?.IsNonBillable &&
                                    dataItem?.list?.data <= 0
                                        ? "N/A"
                                        : item.placeholder
                                }
                                count={count}
                                isLoading={currency?.isFetching || dataItem?.list?.isFetching}
                                currencySymbol={currency?.data?.Symbol}
                                isCurrency={item.isCurrency}
                                tooltipText={item.tooltipText}
                                iconTooltipText={item.iconTooltipText}
                                titleActionIcon={displayBaselineWarning && item.icon}
                                titleActionIconColor={item.color}
                            />
                        ) : (
                            <DashboardDataItem
                                key={`projects-data-layout-dummy-item-${
                                    itemIndex + 1
                                }`}
                                title={item.title}
                                placeholder={
                                    project?.data?.IsNonBillable &&
                                    item.count <= 0
                                        ? "N/A"
                                        : item.placeholder
                                }
                                count={item.count}
                                isLoading={currency?.isFetching || item.isFetchingData}
                                currencySymbol={currency?.data?.Symbol}
                                isCurrency={item.isCurrency}
                                tooltipText={item.tooltipText}
                                iconTooltipText={item.iconTooltipText}
                                titleActionIcon={amount >= BASELINE_WARNING_AMOUNT && item.icon}
                                titleActionIconColor={item.color}
                            />
                        );
                    })}
                </StyledHStack>
            ))}

            <div>
                <Text
                    variant={"Headline_4"}
                    color={"Gray_1"}
                    style={{ paddingBottom: "24px" }}
                >
                    Timesheets
                </Text>
                <StyledHStack borderBottom={true}>
                    {timesheetRow.map((d, i) => (
                        <DashboardDataItem
                            key={i}
                            title={d.title}
                            linkData={d.linkData}
                            description={d.description}
                            isLoading={projectTimesheetSummaryData?.isFetching}
                            feedbackMessage={d.feedbackMessage}
                            feedbackIcon={d.feedbackIcon}
                            feedbackIconColor={d.feedbackIconColor}
                            href={d.href}
                            isInternalUrlNewTab={d.isInternalUrlNewTab}
                        />
                    ))}
                </StyledHStack>
            </div>
        </>
    );
};

export default ProjectsDashboardOverviewData;
