import { msalPowerBIConfig } from "configs/Authentication";
import { getEndpoints, getToken } from "configs/Endpoints";
import unfetch from "isomorphic-unfetch";
import env from "react-dotenv";

const redirect = () =>
{
    const redirectURL = `https://login.windows.net/common/oauth2/v2.0/authorize?client_id=${msalPowerBIConfig.auth.clientId}&client_secret=${msalPowerBIConfig.auth.clientSecret}&redirect_uri=${msalPowerBIConfig.auth.redirectUri}&response_type=code&scope=User.Read offline_access`;
    window.location.href = redirectURL;
}

export async function getPowerBIToken() {
    const urlQS = window.location.search;
    if (urlQS){
        const params = new URLSearchParams(urlQS);
        if (params.has("code")) {
            const accessCode = params.get("code");
            return getTokenByCode(accessCode).then((tokenResponse) => {
                return tokenResponse;
            });
        } else{
            redirect();
        }
    }
    else{
        redirect();
    }
}

export async function getTokenByCode(code) {
    const params = `grant_type=authorization_code&client_id=${msalPowerBIConfig.auth.clientId}&client_secret=${msalPowerBIConfig.auth.clientSecret}&redirect_uri=${msalPowerBIConfig.auth.redirectUri}&resource=https://analysis.windows.net/powerbi/api&code=${code}`;

    return getToken().then((accessToken) => {
        return getEndpoints("powerBIAuthorization").then((config) => {
            const url = `${config.api}/api/${config.version}/${config.resource}`;
            return unfetch(url, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-BW-Security-Token": sessionStorage.getItem("securityToken"),
                }),
                body: params,
            })
                .then((response) => {
                    return response.status != 204 ? response.json() : null;
                })
                .then((data) => {
                    return data.token;
                })
                .catch((error) => {
                    console.error(error);
                    return null;
                });
        });
    });
}

// By passing in the user's email address, you'll receive a response with all dashboards that user has access to
export async function GetPowerBIDashboardsByUserEmail(email) {
    const url =
        env.POWERBI_NAVIGATION_DASHBOARD_URL +
        env.POWERBI_NAVIGATION_DASHBOARD_ENDPOINT +
        email;

    const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
            "x-functions-key": env.POWERBI_NAVIGATION_X_FUNCTIONS_KEY,
            "Content-Type": "application/json",
        }),
    });
    if (!response.ok) {
        return {
            res: "-1",
            status: response.status,
            statusText: response.statusText
        };
    }
    return response.status != 204 ? response.json() : null;
}

// Returns all dashboards, reports and pages currently available in BI
export async function GetPowerBIAllDashboards() {
    const url =
        env.POWERBI_NAVIGATION_DASHBOARD_URL +
        env.POWERBI_NAVIGATION_ALL_DASHBOARDS_ENDPOINT;

    const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
            "x-functions-key": env.POWERBI_NAVIGATION_X_FUNCTIONS_KEY,
            "Content-Type": "application/json",
        }),
    });
    if (!response.ok) {
        throw new Error("Failed to fetch data");
    }
    return response.status != 204 ? response.json() : null;
}
