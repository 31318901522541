import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAccessGroups = () => {
    const { getDataAsync, putData, postData, deleteData } = useApi();
    const queryClient = useQueryClient();

    const getAccessGroupsApiCall = useCallback(async () => {
        const response = await getDataAsync("groups");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const accessGroups = useQuery("accessGroupsList", getAccessGroupsApiCall);

    const createAccessGroupApiCall = useCallback(
        ({ groupId, body }) => {
            return postData("groups", {
                urlParams: [{ name: "$groupId", value: groupId }],
                body,
            });
        },
        [postData]
    );

    const updateAccessGroupApiCall = useCallback(
        ({ groupId, body }) => {
            return putData("updateGroups", {
                urlParams: [{ name: "$groupId", value: groupId }],
                body,
            });
        },
        [putData]
    );

    const deleteAccessGroupApiCall = useCallback(
        ({ groupId }) => {
            return deleteData("deleteGroups", {
                urlParams: [{ name: "$id", value: groupId }],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("accessGroupsList");
            },
        };
    }, [queryClient]);

    const createAccessGroup = useMutation(
        createAccessGroupApiCall,
        mutationOptions
    );
    const updateAccessGroup = useMutation(
        updateAccessGroupApiCall,
        mutationOptions
    );

    const deleteAccessGroup = useMutation(
        deleteAccessGroupApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            accessGroups,
            createAccessGroup,
            updateAccessGroup,
            deleteAccessGroup,
        };
    }, [accessGroups, createAccessGroup, updateAccessGroup, deleteAccessGroup]);
};

export default useAccessGroups;
