import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";

const useTimesheetGridTimeOff = () => {
    const { getDataAsync } = useApi();

    const getHolidaysTimesheetApiCall = useCallback(
        async ({ officeId, fromDate, weeksCount = 1 }) => {
            if (fromDate) {
                const response = await getDataAsync("getHolidaysTimesheet", {
                    urlParams: [
                        { name: "$officeId", value: officeId },
                        { name: "$date", value: fromDate },
                        { name: "$weeksCount", value: weeksCount },
                    ],
                });
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    const getOooReasonsTimesheetApiCall = useCallback(
        async ({ fromDate, weeksCount = 1, agencyId }) => {
            if (fromDate) {
                const response = await getDataAsync("getOooReasonsTimesheets", {
                    urlParams: [
                        { name: "$fromDate", value: fromDate },
                        { name: "$weeksCount", value: weeksCount },
                        { name: "$agencyId", value: agencyId }
                    ],
                });
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useHolidaysTimesheet = (dataObj) =>
            useQuery(["holidays", dataObj], () =>
                getHolidaysTimesheetApiCall(dataObj)
            );

        const useOooReasonsTimesheet = (dataObj) =>
            useQuery(["oooReasons", dataObj], () =>
                getOooReasonsTimesheetApiCall(dataObj)
            );

        return {
            useHolidaysTimesheet,
            useOooReasonsTimesheet,
        };
    }, [getHolidaysTimesheetApiCall, getOooReasonsTimesheetApiCall]);
};

export default useTimesheetGridTimeOff;
