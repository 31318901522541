import React, { useContext, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import MoreMenu from "components/UI/MoreMenu";
import Add from "components/Icon/Add";
import Profile from "components/Icon/Profile";
import Clock from "components/Icon/Clock";
import External from "components/Icon/External";
import CustomGroupModal from "components/Modal/Allocations/CustomGroup";
import EmployeeDrawer from "./EmployeeDrawer";
import { sendNotification } from "utilities/Notification";
import Link from "components/UI/Link";
import Confirmation from "components/Modal/Common/Confirmation";
import { useCustomGroups } from "utilities/API/CustomGroups";
import {
    FocusableItem,
    MenuDivider,
    MenuItem,
    SubMenu,
    MenuGroup,
} from "@szhsin/react-menu";
import Text from "components/UI/Text";
import { Button } from "react-bootstrap";
import Tooltip from "components/UI/Tooltip";
import { truncateText } from "utilities/TruncateText";
import { batchOperation } from "resources/Enums";
import Delete from "components/Icon/Delete";
import { useErrors } from "hooks/Errors/useErrors";
import { useSkillSearch } from "utilities/API/SkillSearch";
import { useSkillSearchProfileUrlByEmployeeIdGetter } from "utilities/API/SkillSearch";
import { useUser } from "utilities/API/Users";
import ApplicationsConfig from "configs/Applications";
import { useStylesForButton, useStylesForMenu } from "styles/MakeStyles";
import styled from "styled-components";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import GenericSidebarPortal from "components/Modal/Common/GenericSidebarPortal";
import GenericModal from "components/Modal/Common/GenericModal";
import EmployeeGridSelectionContext from "context/Allocations/EmployeeGridSelectionContext";
import FilterContext from "context/Allocations/FilterContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Input } from "@material-ui/core";
import "@szhsin/react-menu/dist/index.css";
import Loader from "components/UI/Loader";
import CaretRight from "components/Icon/CaretRight";
import Context from "context/Allocations/Context";
import usePageActionPermission from "hooks/Access/usePageActionPermission";
import { useAllocationsVisibilityOfUser } from "hooks/Allocations/useAllocationsPermission";
import { without } from "lodash";

let renderCount = 1;

const useStyles = makeStyles(() => ({
    root: {
        display: "none",
        "& .MuiIconButton-root": {
            padding: 0,
            margin: 0,
        },
    },
    moreMenu: {
        maxHeight: "15rem",
        border: global.config.border.medium,
        borderRadius: "0.18rem",
        backgroundColor: global.config.colors.white,
    },
    itemLabel: {
        paddingRight: "16px",
    },
    noPadding: { padding: "0!important" },
    subMenu: {
        "&.rc-menu": {
            border: global.config.border.medium,
            minWidth: "fit-content",
            padding: 0,
            zIndex: 1070,
            paddingTop: "0",
            paddingBottom: "0.5rem",
        },
        "& > .rc-menu__group": {
            overflow: "auto",
            maxHeight: "10.625rem",
        },
    },
}));

const BlackSpan = styled.span`
    color: ${global.config.colors.black};
`;
const MenuTab = styled(Tab)({
    textTransform: "none",
    minWidth: "5rem",
    fontSize: global.config.fonts.sizes.xsmall,
    fontFamily: global.config.fonts.families.light,
    width: "7.031rem",
    borderRadius: global.config.sizes.borderRadius,
    borderBottom: global.config.border.smallGray7,
});

const SearchInput = styled(Input)({
    fontSize: global.config.fonts.sizes.small,
    width: "100%",
    height: "3rem",
    paddingLeft: "1rem",
});

const LoaderWrapper = styled.div`
    margin: 1rem 5.6rem;
`;

const ArrowWrapper = styled.div`
    > svg {
        display: none;
    }
    width: 1rem;
    position: absolute;
    right: 1rem;
`;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function More(props) {
    const {
        addUserToCustomGroup,
        removeUserFromCustomGroup,
        getAllCustomGroups,
    } = useCustomGroups();
    const classesMenu = useStylesForMenu();
    const [customGroups, setCustomGroups] = useState(null);
    const classes = useStyles();
    const customGroupModalRef = useRef();
    const [skillSearchProfileLink, setSkillSearchProfileLink] = useState(null);
    const buttonClasses = useStylesForButton();
    const customGroupConsumer = useContext(CustomGroupContext);
    const employeeGridSelectionConsumer = useContext(
        EmployeeGridSelectionContext
    );
    const filterConsumer = useContext(FilterContext);
    const getSkillSearchUrl = useSkillSearchProfileUrlByEmployeeIdGetter();
    const { handleError } = useErrors();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const contextConsumer = useContext(Context);
    const hasCustomGroupPermission = usePageActionPermission(
        "AC_ADD_EDIT_VIEW_CUSTOM_GROUP"
    );
    const [drawerUserId, setDrawerUserId] = useState();
    const canViewAllocations = useAllocationsVisibilityOfUser(props.employee.userId);

    const getSkillSearchProfileUrl = () => {
        if (!props.employee.userId) {
            return false;
        }

        if (!props.skillSearchLink) {
            getSkillSearchUrl(props.employee.userId).then((url) => {
                setSkillSearchProfileLink(url);
            });
        } else {
            setSkillSearchProfileLink(props.skillSearchLink);
        }
    };

    const openAddCustomGroupModal = () => {
        customGroupModalRef.current.add(props.employee);
    };

    const skipDuplicates = (currentCustomGroup, duplicatedUsers) => {
        const employeeIds = getIdsFromNonDuplicatedEmployees(duplicatedUsers);

        addUsersToCustomGroup(currentCustomGroup, employeeIds, true);
    };

    const getCustomGroups = async () => {
        setIsLoadingData(true);
        await getAllCustomGroups()
            .then((r) => {
                setCustomGroups(r);
            }).then(()=> setIsLoadingData(false));
    };

    const addingUserNotification = (customGroup, submitedEmployeeIds) =>
        messageNotification(
            customGroup,
            submitedEmployeeIds,
            batchOperation.add
        );

    const messageNotification = (
        customGroup,
        submitedEmployeeIds,
        operation
    ) => {
        const allSelected = employeeGridSelectionConsumer.allSelected;
        const employeeNames = [
            props.employee.name,
            ...employeeGridSelectionConsumer.getNamesFromSelectedIDs({
                idToExclude: props.employee.userId,
            }),
        ];

        const listLength = submitedEmployeeIds.length;

        return (
            <>
                <b>
                    {employeeNames[0]}&nbsp;
                    {listLength > 1 && (
                        <>
                            <span>and </span>
                            <Tooltip
                                snackbar={true}
                                placement="top"
                                title={employeeNames
                                    .slice(1)
                                    .map((name, idx) => (
                                        <Text key={idx} variant="xsmall">
                                            {name}
                                        </Text>
                                    ))}
                            >
                                <Button
                                    variant="link"
                                    className={buttonClasses.tooltipLink}
                                    style={{
                                        color: global.config.colors.white,
                                        fontSize:
                                            global.config.fonts.sizes.small,
                                    }}
                                >
                                    {allSelected
                                        ? "all filter result employees"
                                        : listLength -
                                          1 +
                                          (listLength > 2
                                              ? " others"
                                              : " other")}
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </b>{" "}
                {listLength === 1 ? "has" : "have"} been
                {operation === batchOperation.add
                    ? " added to "
                    : " removed from "}
                <b>{customGroup.Name}</b>.&nbsp;&nbsp;
                {operation === batchOperation.add && (
                    <Link
                        variant="secondary"
                        color={global.config.colors.white}
                        href={void 0}
                        onClick={(event) => {
                            customGroupConsumer.selectCustomGroupOption(
                                event,
                                customGroup
                            );
                            handleDrawerClose();
                        }}
                    >
                        <b>View Group</b>
                    </Link>
                )}
            </>
        );
    };

    const addUsersToCustomGroup = async (customGroup, employeeIds, skip) => {
        if (employeeIds.length) {
            addUserToCustomGroup(customGroup.Id, employeeIds, skip)
                .then(() => {
                    sendNotification(
                        undefined,
                        addingUserNotification(customGroup, employeeIds)
                    );
                })
                .then(async () => {
                    employeeGridSelectionConsumer.setSelectionIsEnabled(false);
                    employeeGridSelectionConsumer.deselectAllEmployeeIDs();
                })
                .then(() => {
                    if (
                        customGroupConsumer?.selectedCustomGroup?.Id ===
                        customGroup.Id
                    ) {
                        UpdateCustomGroup(employeeIds, batchOperation.add);
                    }
                })
                .catch((e) => {
                    if (e.status === 400 && e.body.Data?.["Duplicated users"]) {
                        showDuplicatedUsersModal(
                            customGroup,
                            e.body.Data["Duplicated users"]
                        );
                        return;
                    }
                    handleError(e);
                });
        }
    };

    const UpdateCustomGroup = (employeeIds, operation) => {
        const selectedGroup = { ...customGroupConsumer.selectedCustomGroup };
        selectedGroup.LastUpdateDateTime = new Date().toLocaleString();
        if (operation === batchOperation.add) {
            employeeIds.map((id) => selectedGroup.EmployeeIds.push(id));
            customGroupConsumer.selectCustomGroupOption(null, selectedGroup);
        } else {
            selectedGroup.EmployeeIds =
                customGroupConsumer.selectedCustomGroup.EmployeeIds.filter(
                    (e) => !employeeIds.includes(e)
                );

            let currentEmployees = { ...contextConsumer.employees };
            const updatedEmployees = Object.values(currentEmployees)
                .map((result) => {
                    return result.filter(
                        (employee) => !employeeIds.includes(employee.userId)
                    );
                })
                .filter((r) => r.length > 0);

            contextConsumer.setEmployees(updatedEmployees);
        }

        customGroupConsumer.setSelectedCustomGroup(selectedGroup);
    };

    const removeFromCustomGroup = async (_selectedAll, _summaryQuery) => {
        const employeeIds =
            employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0
                ? employeeGridSelectionConsumer.selectedEmployeeIDs
                : [props.employee.userId];

        let customGroup = { ...customGroupConsumer.selectedCustomGroup };

        if (employeeIds.length) {
            removeUserFromCustomGroup(customGroup.Id, employeeIds)
                .then(() => {
                    sendNotification(
                        undefined,
                        removeUserNotification(customGroup, employeeIds)
                    );
                    handleDrawerClose();
                    UpdateCustomGroup(employeeIds, batchOperation.remove);

                    employeeGridSelectionConsumer.setSelectionIsEnabled(false);
                    employeeGridSelectionConsumer.deselectAllEmployeeIDs();
                })
                .catch(() => {
                    /**/
                });
        }
    };

    const addCustomGroup = (
        <>
            <Add size="16" color={global.config.colors.black} />
            <span className={classes.itemLabel}>Add To Custom Group</span>
            <ArrowWrapper className="sub_menu_arrow">
                <CaretRight />
            </ArrowWrapper>
        </>
    );

    const removeUserNotification = (customGroup, submitedEmployeeIds) =>
        messageNotification(
            customGroup,
            submitedEmployeeIds,
            batchOperation.remove
        );

    function getIdsFromNonDuplicatedEmployees(duplicatedUsers) {
        let deduplicatedSelectedIds = [];
        const duplicatedIds = duplicatedUsers.map((u) => parseInt(u.Id));

        if (employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0) {
            deduplicatedSelectedIds = without(
                employeeGridSelectionConsumer.selectedEmployeeIDs,
                duplicatedIds
            );
        } else if (duplicatedIds.includes(parseInt(props.employee.userId))) {
            deduplicatedSelectedIds = [props.employee.userId];
        }

        return deduplicatedSelectedIds;
    }

    const addToCustomGroup = async (currentCustomGroup) => {
        if (employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0) {
            addUsersToCustomGroup(
                currentCustomGroup,
                employeeGridSelectionConsumer.selectedEmployeeIDs
            );
        } else {
            const id = props.employee.id !== undefined ? props.employee.id : props.employee.userId;
            addUsersToCustomGroup(currentCustomGroup, [id]);
        }
    };

    const handleDrawerClose = () => {
        setDrawerUserId(null);
    };

    const handleViewProfile = () => {
        if (!props.disableProfile) {
            setDrawerUserId(props.employee.userId);
        }
    };

    const showDuplicatedUsersModal = (currentCustomGroup, duplicatedUsers) => {
        const modalContent = (
            <Confirmation
                title="Duplicate Employee"
                onConfirm={() =>
                    skipDuplicates(currentCustomGroup, duplicatedUsers)
                }
                buttonLabel="Yes, Skip Duplicates"
            >
                {currentCustomGroup && duplicatedUsers.length && (
                    <>
                        <BlackSpan>
                            {duplicatedUsers[0]?.Name}&nbsp;
                            {duplicatedUsers.length > 1 && (
                                <>
                                    <span>and </span>
                                    <Tooltip
                                        placement="top"
                                        title={duplicatedUsers
                                            .filter((index) => index !== 0)
                                            .map((u, idx) => (
                                                <Text
                                                    key={idx}
                                                    variant="xsmall"
                                                >
                                                    {u.Name}
                                                </Text>
                                            ))}
                                    >
                                        <Button
                                            variant="link"
                                            className={[
                                                buttonClasses.tooltipLink,
                                                buttonClasses.medium,
                                            ]}
                                        >
                                            {duplicatedUsers.length - 1}{" "}
                                            {duplicatedUsers.length - 1 === 1
                                                ? "other"
                                                : "others"}
                                        </Button>
                                    </Tooltip>
                                    &nbsp;
                                </>
                            )}
                        </BlackSpan>
                        {duplicatedUsers.length === 1 ? "is" : "are"} already in
                        the Custom Group:{" "}
                        <BlackSpan> {currentCustomGroup.Name}</BlackSpan>
                    </>
                )}
            </Confirmation>
        );

        const modal = (
            <GenericModal content={modalContent} parent="divPageModal" />
        );
        ReactDOM.render(modal, document.getElementById("divPageModal"));
    };

    const removeItemVisibility = () => {
        return (
            !!!customGroupConsumer?.selectedCustomGroup?.EmployeeIds ||
            Object.values(
                customGroupConsumer?.selectedCustomGroup?.EmployeeIds
            ).filter((id) => id === props.employee.userId).length === 0
        );
    };
    const tabs = ["My Groups", "All Groups"];

    const MenuTabItems = tabs.map((itemLabel, index) => (
        <MenuTab
            style={{
                fontSize: "0.75rem",
                textTransform: "none",
                minWidth: "7.031rem",
            }}
            key={index.toString()}
            label={itemLabel}
            {...a11yProps(index)}
        />
    ));

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const [tabValue, setTabValue] = useState(0);
    const [filter, setFilter] = useState("");

    const menuOptions = [
        <MenuItem
            key={0}
            hideItem={
                employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0 ||
                !props.employee.userId
            }
            onClick={() => {
                handleViewProfile();
            }}
            disabled={props.disableProfile}
        >
            <Profile
                color={
                    props.disableProfile
                        ? global.config.colors.gray2
                        : global.config.colors.black
                }
            />
            View Profile
        </MenuItem>,
        <MenuItem
            key={1}
            hideItem={
                employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0 ||
                !props.employee.userId ||
                !canViewAllocations
            }
        >
            <Link
                href={`${global.config.routes.allocation}?userId=${props.employee.userId}`}
                target="_blank"
                rel="noreferrer"
                noHover
                noUnderline
            >
                <Clock />
                View Employee Allocations
            </Link>
        </MenuItem>,
        <MenuItem
            key={2}
            hideItem={
                employeeGridSelectionConsumer?.selectedEmployeeIDs.length > 0 ||
                !props.employee.userId
            }
            disabled={!skillSearchProfileLink}
        >
            <Link
                href={skillSearchProfileLink}
                target="_blank"
                rel="noreferrer"
                noHover
                noUnderline
                disabled={!skillSearchProfileLink}
            >
                <External
                    color={
                        !skillSearchProfileLink
                            ? global.config.colors.gray2
                            : global.config.colors.black
                    }
                />
                View Skill Search Profile
            </Link>
        </MenuItem>,
        <SubMenu
            key={3}
            label={addCustomGroup}
            offsetY={-3}
            offsetX={3}
            overflow="auto"
            position="anchor"
            className={classes.subMenu}
            hideItem={!hasCustomGroupPermission}
        >
            <MenuItem
                onClick={(e) => (e.keepOpen = true)}
                className={classes.noPadding}
            >
                <Box
                    className={classesMenu.root}
                    variant="smallLight"
                    style={{ width: "225px" }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {MenuTabItems}
                    </Tabs>
                </Box>
            </MenuItem>
            <FocusableItem className={classes.noPadding}>
                {({ ref }) => (
                    <SearchInput
                        ref={ref}
                        type="text"
                        placeholder="Search"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className={classesMenu.searchInput}
                        style={{
                            fontSize: "0.7rem",
                            textTransform: "none",
                        }}
                    />
                )}
            </FocusableItem>
            {isLoadingData ? (
                <MenuItem onClick={(e) => (e.keepOpen = true)}>
                    <LoaderWrapper>
                        <Loader height="1rem" width="1rem" />
                    </LoaderWrapper>
                </MenuItem>
            ) : (
                <MenuGroup takeOverflow>
                    {customGroups &&
                        customGroups
                            .filter(
                                (f) =>
                                    f.Name.toUpperCase().includes(
                                        filter.trim().toUpperCase()
                                    ) &&
                                    ((tabValue === 0 && f.Own) ||
                                        (tabValue === 1 && (f.Own || !f.Own)))
                            )
                            .map((item, index) => (
                                <MenuItem
                                    key={"submenu" + index}
                                    onClick={() => {
                                        addToCustomGroup(item);
                                    }}
                                >
                                    {truncateText(item.Name, 200)}
                                </MenuItem>
                            ))}
                </MenuGroup>
            )}
            <MenuDivider />
            <MenuItem
                key={4}
                onClick={openAddCustomGroupModal}
                style={{ zIndex: "2000" }}
            >
                <Add size="16" color={global.config.colors.black} />
                Create Custom Group
            </MenuItem>
        </SubMenu>,
        <MenuItem
            key={5}
            hideItem={!hasCustomGroupPermission || removeItemVisibility()}
            onClick={() => {
                removeFromCustomGroup(
                    employeeGridSelectionConsumer &&
                        employeeGridSelectionConsumer.allSelected,
                    filterConsumer.summaryQuery
                );
            }}
        >
            <Delete size="16" viewBox="16" />
            Remove from this Custom Group
        </MenuItem>,
    ];

    return (
        <>
            <MoreMenu
                id={props.id}
                alwaysVisible={props.visible}
                onOpen={() => [getCustomGroups(), getSkillSearchProfileUrl()]}
                direction={props.direction}
                portal={props.portal}
                position="initial"
            >
                {menuOptions}
            </MoreMenu>
            <CustomGroupModal ref={customGroupModalRef} />

            {drawerUserId && (
                <Context.Provider value={contextConsumer}>
                    <CustomGroupContext.Provider value={customGroupConsumer}>
                        <GenericSidebarPortal
                            open={true}
                            content={<EmployeeDrawer userId={drawerUserId} />}
                            onClose={handleDrawerClose}
                        />
                    </CustomGroupContext.Provider>
                </Context.Provider>
            )}
        </>
    );
}

More.propTypes = {};
