import React, { useState } from "react";
import styled from "styled-components";
import env from "react-dotenv";

import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Photo from "components/design-system/ui/Photo";
import { COLOR_VALUES } from "components/design-system/config/colors";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

const Wrapper = styled(VStack)``;

const StyledContactCard = styled(HStack)`
    background: ${COLOR_VALUES.White};
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
    padding: 16px;
`;

const StyledContactCardContent = styled(VStack)`
    gap: 12px;
    min-width: 0;
`;

const StyledPhoto = styled(Photo)`
    width: 44px;
    height: 44px;
`;

export const CopyContainer = styled(HStack)`
    white-space: nowrap;

    .textMassage {
        visibility: hidden;
    }
    &:hover {
        .textMassage {
            visibility: visible;
        }
    }
`;

const ContactCard = ({
    photoUrl = "",
    name,
    jobPost,
    department,
    location,
    slackId,
    emailId,
    contactCardLabel = "Talent Manager",
    contactCardLabelVariant = "Descriptive_1_1",
    contactCardLabelColor = "Gray_2",
    actionProps = {},
    actionIconWidth = 16,
    hasAction = false,
    actionIcon,
}) => {
    const [copy, setCopy] = useState("Copy");
    const copyToClipboard = (e) => {
        e.preventDefault();
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(emailId);
            setCopy("Copied");
        }
    };
    return (
        <Wrapper spacing="Zero_5">
            <HStack>
                <Text
                    variant={contactCardLabelVariant}
                    color={contactCardLabelColor}
                >
                    {contactCardLabel}
                </Text>
                {hasAction && (
                    <TertiaryButton
                        {...actionProps}
                        leadingIcon={actionIcon}
                        iconWidth={actionIconWidth}
                        style={{
                            color: "currentColor",
                            background: "transparent",
                        }}
                    />
                )}
            </HStack>
            <StyledContactCard
                spacing="Zero_75"
                align="flex-start"
                className="contact-card"
            >
                <VStack>
                    <StyledPhoto url={photoUrl} alt={name} />
                </VStack>
                <StyledContactCardContent>
                    <VStack spacing="Zero_25">
                        <Text variant="Headline_5" color="Black">
                            {name}
                        </Text>
                        <Text variant="Descriptive_1_1" color="Gray_2">
                            {jobPost}, {department}
                        </Text>
                        <Text variant="Descriptive_1_1" color="Gray_3">
                            {location}
                        </Text>
                    </VStack>
                    <VStack spacing="Zero">
                        {slackId && (
                            <>
                                <CopyContainer align="center" spacing="Zero_25">
                                    <TertiaryButton
                                        leadingIcon="slack-logo"
                                        label={slackId}
                                        href={`slack://user?team=${env.BESTWORK_REACT_SLACK_TEAM_ID}&id=U0AGBSQTY`}
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                    <Text
                                        variant="Body_2_1"
                                        className="textMassage"
                                        color="Gray_2"
                                    >
                                        Message
                                    </Text>
                                </CopyContainer>
                            </>
                        )}
                        {emailId && (
                            <CopyContainer align="center" spacing="Zero_25">
                                <TertiaryButton
                                    leadingIcon="mail"
                                    label={emailId}
                                    onClick={(e) => {
                                        copyToClipboard(e);
                                    }}
                                />
                                <Text
                                    variant="Body_2_1"
                                    className="textMassage"
                                    color="Gray_2"
                                >
                                    {copy}
                                </Text>
                            </CopyContainer>
                        )}
                    </VStack>
                </StyledContactCardContent>
            </StyledContactCard>
        </Wrapper>
    );
};

export default ContactCard;
