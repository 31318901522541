import { useEffect, useRef } from "react";
import styled from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";

const svgWrapper = (svg) => `url('data:image/svg+xml;utf8,${svg}')`;

const getCheck = (color) =>
    svgWrapper(
        `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.4 4.29995L12.6 3.69995L6.99998 11.17L4.39998 7.69995L3.59998 8.29995L6.99998 12.83L13.4 4.29995Z" fill="${color}"/>
        </svg>`
    );

const getDash = (color) =>
    svgWrapper(
        `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="7" width="8" height="2" fill="${color}"/>
        </svg>
        `
    );

const StyledCheckbox = styled.input`
    width: ${(props) => (props.isSmallSize ? "12px" : "16px")};
    height: ${(props) => (props.isSmallSize ? "12px" : "16px")};
    appearance: none;
    border-radius: 2px;
    margin: 0;
    position: relative;

    ::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 0;
    }

    &:disabled {
        pointer-events: none;
        user-select: none;
    }

    &:not(:checked):not(:indeterminate) {
        box-shadow: 0px 0px 0px 1px inset ${COLOR_VALUES.Gray_4};

        &:not(:disabled) {
            &:hover {
                box-shadow: 0px 0px 0px 1px inset #000;
            }

            &:active {
                box-shadow: 0px 0px 0px 1px inset #000;

                ::after {
                    content: ${getCheck("black")};
                }
            }
        }
        &:disabled {
            background: ${COLOR_VALUES.Gray_8};
        }
    }

    &:checked,
    &:indeterminate {
        &:disabled {
            background: ${COLOR_VALUES.Gray_3};
        }

        &:not(:disabled) {
            background: black;

            &:hover {
                background: ${COLOR_VALUES.Gray_2};
            }

            &:active {
                background: transparent;
                box-shadow: 0px 0px 0px 1px inset #000;
            }
        }
    }

    &:checked {
        ::after {
            content: ${getCheck("white")};
        }

        &:active {
            ::after {
                content: ${getCheck("black")};
            }
        }
    }

    &:indeterminate {
        ::after {
            content: ${getDash("white")};
        }

        &:active {
            ::after {
                content: ${getDash("black")};
            }
        }
    }

    &:before {
        position: absolute;
        inset: 0;
        content: "";
        display: ${(props) => (props.$expandHitArea ? "block" : "none")};
    }
`;

const ControlledCheckbox = ({ checked, indeterminate, onChange, ...props }) => {
    const elRef = useRef();

    useEffect(() => {
        elRef.current.indeterminate = indeterminate;
    }, [indeterminate, checked]);

    const internalOnChange = (event) => {
        elRef.current.indeterminate = indeterminate;

        if (typeof onChange === "function") {
            onChange(event);
        }
    };

    return (
        <StyledCheckbox
            {...props}
            checked={checked}
            onChange={internalOnChange}
            type="checkbox"
            ref={elRef}
        />
    );
};

export default ControlledCheckbox;
