import { useApi } from "utilities/API/Helpers/useApi";

export const useTestConnection = () => {
    const { getData } = useApi();

    const testConnection = () => {
        return getData("userhealth")
            .then((payload) => {
                return payload.json().then((rv) => {
                    return "Your user session info: " + JSON.stringify(rv);
                });
            })
            .catch((err) => {
                return (
                    "There is an error to connect to backend. Detail: " + err
                );
            });
    };

    return { testConnection };
};
