import { useParams } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

export const useClientFamiliesFilterApi = () => {
    const { getDataAsync } = useApi();

    const getClientFamiliesFilterApiCall = useCallback(async () => {
        const response = await getDataAsync("clientFamiliesFilter");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientFamiliesFilter = useQuery(
        "clientFamiliesFilterList",
        getClientFamiliesFilterApiCall
    );

    return useMemo(() => {
        return {
            clientFamiliesFilter,
        };
    }, [clientFamiliesFilter]);
};

export const useClientsFilterByFamilyApi = () => {
    const { getDataAsync } = useApi();

    const getClientsFilterByFamilyApiCall = useCallback(
        async (clientFamilyIds) => {
            if (typeof clientFamilyIds !== "object") {
                const response = await getDataAsync(
                    "clientsFilterByFamilyByClientFamilyIds",
                    {
                        urlParams: [
                            {
                                name: "$clientFamilyIds",
                                value: clientFamilyIds,
                            },
                        ],
                    }
                );
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useClientsFilterByFamily = (clientFamilyIds) =>
            useQuery(["clientsFilterByFamilyList", clientFamilyIds], () =>
                getClientsFilterByFamilyApiCall(clientFamilyIds)
            );

        return {
            useClientsFilterByFamily,
        };
    }, [getClientsFilterByFamilyApiCall]);
};

export const useProjectsFilterApi = () => {
    const { getDataAsync } = useApi();

    const getProjectsFilterApiCall = useCallback(
        async (clientId) => {
            if (typeof clientId === "number") {
                const response = await getDataAsync("projectsFiltersByClient", {
                    urlParams: [
                        {
                            name: "$clientId",
                            value: clientId,
                        },
                    ],
                });
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useProjectsFilter = (clientFamilyIds) =>
            useQuery(["projectsFilterList", clientFamilyIds], () =>
                getProjectsFilterApiCall(clientFamilyIds)
            );

        return {
            useProjectsFilter,
        };
    }, [getProjectsFilterApiCall]);
};

export const useClientsFilterApi = () => {
    const { getDataAsync } = useApi();

    const getClientsFilterApiCall = useCallback(async () => {
        const response = await getDataAsync("clientsFilter");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientsFilter = useQuery(
        "clientsFilterList",
        getClientsFilterApiCall
    );

    return useMemo(() => {
        return {
            clientsFilter,
        };
    }, [clientsFilter]);
};

export const useProjectRelationTypes = () => {
    const { getDataAsync } = useApi();

    const getProjectRelationTypesApiCall = useCallback(async () => {
        const response = await getDataAsync("getProjectRelationTypes");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const projectRelationTypes = useQuery(
        "projectRelationTypesList",
        getProjectRelationTypesApiCall
    );

    return useMemo(() => {
        return {
            projectRelationTypes,
        };
    }, [projectRelationTypes]);
};

const useProjectsLinked = () => {
    const { getDataAsync, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { projectId } = useParams();

    const getProjectsRelatedApiCall = useCallback(
        async ({ jobID }) => {
            const response = await getDataAsync("getProjectsRelated", {
                urlParams: [{ name: "$projectId", value: jobID }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createRelatedProjectApiCall = useCallback(
        ({ projectId, projectRelatedProjectID, relationTypeId, body }) => {
            return postData("postProjectsRelated", {
                urlParams: [
                    { name: "$projectId", value: projectId },
                    {
                        name: "$relatedProjectId",
                        value: projectRelatedProjectID,
                    },
                    { name: "$relationTypeId", value: relationTypeId },
                ],
                body,
            });
        },
        [postData]
    );

    const deleteRelatedProjectApiCall = useCallback(
        ({ id, relatedJobId }) => {
            return deleteData("deleteProjectRelated", {
                urlParams: [
                    { name: "$jobId", value: id },
                    { name: "$relatedJobId", value: relatedJobId },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    "projectsRelatedList",
                    projectId,
                ]);
            },
        };
    }, [queryClient]);

    const createRelatedProject = useMutation(
        createRelatedProjectApiCall,
        mutationOptions
    );
    const deleteRelatedProject = useMutation(
        deleteRelatedProjectApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useProjectsRelatedList = (dataObj) =>
            useQuery(["projectsRelatedList", projectId], () =>
                getProjectsRelatedApiCall(dataObj)
            );

        return {
            useProjectsRelatedList,
            createRelatedProject,
            deleteRelatedProject,
        };
    }, [getProjectsRelatedApiCall, createRelatedProject, deleteRelatedProject]);
};

export default useProjectsLinked;
