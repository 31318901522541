import PropTypes from "prop-types";

const Delete = ({ size, color, viewBox }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4783_1284)">
                <path
                    d="M10.9999 7.41699H4.99994V8.58366H10.9999V7.41699Z"
                    fill={color}
                />
                <path
                    d="M7.99997 15C4.1383 15 0.999969 11.8617 0.999969 8C0.999969 4.13833 4.1383 1 7.99997 1C11.8616 1 15 4.13833 15 8C15 11.8617 11.8616 15 7.99997 15ZM7.99997 2.16667C4.7858 2.16667 2.16664 4.78583 2.16664 8C2.16664 11.2142 4.7858 13.8333 7.99997 13.8333C11.2141 13.8333 13.8333 11.2142 13.8333 8C13.8333 4.78583 11.2141 2.16667 7.99997 2.16667Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_4783_1284">
                    <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.999969 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

Delete.defaultProps = {
    size: "16",
    color: global.config.colors.black,
    viewBox: "16",
};

Delete.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    viewBox: PropTypes.string,
};

export default Delete;
