import { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import env from "react-dotenv";
import moment from "moment";
import { Wrapper, Cells, AllocationColumn } from "./styles/DetailStyles";
import Cell from "../Cell/Cell";
import SaveHoursErrorModal from "../../Error/SaveHoursErrorModal";
import SaveHoursErrorSnack from "../../Error/SaveHoursErrorSnack";
import EmployeeContext from "context/Allocations/EmployeeContext";
import { ClientContext } from "context/Allocations/ClientContext";
import { sendNotification } from "utilities/Notification";
import { allocationsGridModes, saveHoursConfirmation } from "resources/Enums";
import { useAllocations as allocationServiceLayer } from "utilities/API/Allocations";
import { getContextSignalRClientId } from "context/Allocations/SignalRContext";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import GenericModal from "components/Modal/Common/GenericModal";
import { useInsigths } from "utilities/Insights/InsightsLogs";
import usePageActionPermission from "hooks/Access/usePageActionPermission";
import Context from "context/Allocations/Context";
import EmployeeRowsContext from "context/Allocations/EmployeeRowsContext";
import { addWeeksToDate } from "utilities/Dates";

const TRY_AGAIN_CODE_RESPONSES = JSON.parse(env.BESTWORK_REACT_ALLOCATIONS_TRYAGAIN_SAVE_CODES);
const SNACK_NOTIFICATION_DURATION = 5000;
const KEYCODES = [9, 13];
const keyEnter = "Enter";
const keyTab = "Tab";
const nullCellValue = -1;

const ProjectDetail = ({ initialData, cellMaxValue, cellMaxValueResize, cellStepValue, hover, employee, project}) => {
    const [onClickEnabled, setOnClickEnabled] = useState(true);
    const [dragging, setDragging] = useState(false);
    const [cellsGraphicInfo, setCellsGraphicInfo] = useState();
    const [rollbackCells, setRollbackCells] = useState();
    const [cellsGraphicInfoRollback, setCellsGraphicInfoRollback] = useState();
    const [readonly, setReadonly] = useState();
    const { summaryData, summaryDetailsData, setSummaryData, setSummaryDetailsData} = useContext(EmployeeContext);
    const { employeesInProgress, setEmployeesInProgress } = useContext(EmployeeRowsContext);
    const {isLoadingMore, setIsLoadingMore, employees, setEmployees} = useContext(Context);
    const { clientData } = useContext(ClientContext);
    const { saveAllocationsHours } = allocationServiceLayer();
    const { insightsException } = useInsigths();
    const hasManageAllocationsPermission = usePageActionPermission("AC_ADD_EDIT_ALLOCATION");
    const hasNotesPermissionToAddEdit = usePageActionPermission("AC_ADD_EDIT_NOTES");
    const hasNotesPermissionToView = usePageActionPermission("AC_NOTES_VIEW");
    let tabbingIndex = undefined;
    let startTabbingIndex = undefined;
    let confirmOption = undefined;
    const [tabbedCells, setTabbedCells] = useState([]);
    const [startTabIndexClickAway, setStartTabIndexClickAway] = useState(null);
    const tabbedCellsRef = useRef(tabbedCells);
    const [keydownListenerAdded, setKeydownListenerAdded] = useState(false);
    const cellsGraphicInfoRef = useRef(cellsGraphicInfo);
    const keydownListenerAddedRef = useRef(cellsGraphicInfo);
    const [draggingStatus, setDraggingStatus] = useState({index: -1, value: -1});

    useEffect(() => {
        if (initialData) {
            const enhancedData = initialData
                .map((cell, index) => ({
                    ...cell,
                    id: index,
                    value: cell.allocatedHours || 0, 
                    available: cell.availableHours,
                    behavior: {
                        dragged: false,
                        resize: false,
                        showFill: true,
                        outlined: false,
                        typing: false,
                    },
                    projectId: project.jobId,
                    employeeId: employee.userId,
                }));
            setRollbackCells(enhancedData);
            setCellsGraphicInfoRollback(enhancedData);
            setCellsGraphicInfo(enhancedData);
            setReadonly(!hasManageAllocationsPermission);
        }
    }, [initialData]);

    useEffect(() => {
        cellsGraphicInfoRef.current = cellsGraphicInfo;
    }, [cellsGraphicInfo]);

    useEffect(() => {
        keydownListenerAddedRef.current = keydownListenerAdded;
    }, [keydownListenerAdded]);

    useEffect(() => {
        tabbedCellsRef.current = tabbedCells;
    }, [tabbedCells]);

    const handleKeyDown = (event) => {
        if (KEYCODES.find((keycode) => keycode === event.keyCode)) {
            
            let cells = [...cellsGraphicInfoRef.current];
            if (
                project.jobId.toString() ===
                event.target.getAttribute("data-projectid")
            ) {
                tabbingIndex = parseInt(event.target.dataset.tabindex);
                const nextCellIndex = tabbingIndex + 1;
                const cellsLength = cells.filter((cell) => cell.value !== nullCellValue).length - 1;
                const moveToNextCell = nextCellIndex <= cellsLength;
                cells = cells.map((cell, cellIndex) => ({
                    ...cell,
                    behavior: {
                        ...cell.behavior,
                        outlined: undefined,
                        typing:
                            event.key === keyTab &&
                            moveToNextCell &&
                            nextCellIndex === cellIndex,
                    },
                }));
                setCellsGraphicInfo(cells);
                const updatedCell = cells[tabbingIndex];
                let tabbedCellsCopy = [...tabbedCellsRef.current];
                tabbedCellsCopy = tabbedCellsCopy.map((cell) => {
                    return ({
                    ...cell,
                    value: cell.date === updatedCell.date ? updatedCell.value : cell.value,
                    })
                    }
                );

                !tabbedCellsCopy.filter((cell) => cell.date === updatedCell.date).length && tabbedCellsCopy.push(updatedCell);

                if (!moveToNextCell || event.key === keyEnter) {
                    saveCellHours(tabbedCellsCopy, startTabbingIndex, cells);
                    setTabbedCells([]);
                    removeKeyDownListener();
                } else {
                    tabbingIndex = nextCellIndex;
                    setTabbedCells(tabbedCellsCopy);
                    document.activeElement.value = updatedCell.value;
                }

                setCellsGraphicInfoRollback(cells);
                event.preventDefault();
            }
        }
    };

    const removeKeyDownListener = () => {
        if (keydownListenerAddedRef.current) {
            document.removeEventListener("keydown", handleKeyDown);
            setKeydownListenerAdded(false);
        }
    };

    const addKeyDownListener = () => {
        if (!keydownListenerAddedRef.current) {
            document.addEventListener("keydown", handleKeyDown);
            setKeydownListenerAdded(true);
        }
    };

    const findCellContainer = (el) => {
        let current = el;
        while (!current.id || current.id.indexOf("cellContainer") < 0) {
            current = current.parentNode;
        }
        return current;
    };

    const handleCellClick = (event, index) => {
        // prevent errant click events from notes modal
        if (!document.getElementById("root").contains(event.target)) {
            return;
        }

        handleCloseSidebar();
        let cellContainer = findCellContainer(event.target);
        let isLoading = false;
        let cellId = "";
        if (cellContainer.id) {
            cellId = cellContainer.id.split("cellContainer")[1];
            let loading = document.getElementById("cellLoading" + cellId);
            if (loading && loading.style.display === "block") {
                isLoading = true;
            }
        }

        if (onClickEnabled && !isLoading) {
            tabbingIndex = parseInt(event.currentTarget.tabIndex);
            startTabbingIndex = tabbingIndex;
            if (!keydownListenerAdded) addKeyDownListener();
            else removeKeyDownListener();

            let cells = [...cellsGraphicInfo];
            cells = cells.map((cell, cellIndex) => ({
                ...cell,
                behavior: {
                    ...cell.behavior,
                    outlined: undefined,
                    typing: index === cellIndex ? !cell.behavior.typing : false,
                },
            }));
            setCellsGraphicInfo(cells);
            setStartTabIndexClickAway(startTabbingIndex);
        }
    };

    const handleClickAway = () => {
        if (startTabIndexClickAway !== null) {
            removeKeyDownListener();
            let cells = [...cellsGraphicInfo];
            cells = cells.map((cell) => ({
                ...cell,
                behavior: {
                    ...cell.behavior,
                    typing: false,
                },
            }));
            if (tabbedCells?.length) {
                saveCellHours(tabbedCells, startTabIndexClickAway, cells);
                setTabbedCells([]);
            }
            setStartTabIndexClickAway(null);
            setCellsGraphicInfo(cells);
        }
    };

    const getUpdatedCells = (index, cells) => {
        let draggedCells = cells.filter((cell) => cell.behavior.dragged);
        let updatedCells = draggedCells.length ? draggedCells : [cells[index]];
        return updatedCells;
    };

    const handleChange = (
        _event,
        previousValue,
        value,
        index,
        updateRollback = false
    ) => {

        let cells = [...cellsGraphicInfo];
        cells = cells.map((cell, cellIndex) => ({
            ...cell,
            value:
                cellIndex === index || cell.behavior.dragged
                    ? value
                    : cell.value,
            previousValue: cell.value,
            behavior: {
                ...cell.behavior,
                outlined: undefined,
            },
        }));

        const length = cells.filter((c) => c.behavior.dragged).length;

        if (length > 1) {
            let changes = [];

            cells.forEach((cell, cellIndex) => {
                if (cell.behavior.dragged) {
                    let data = summaryData;
                    let newValue = cell.value - cell.previousValue;
                    data[cellIndex].allocatedHours =
                        data[cellIndex].allocatedHours + newValue;
                    const change = {
                        index: cellIndex,
                        oldValue: cell.previousValue,
                        newValue: newValue,
                    };
                    changes.push(change);
                }
            });
        } else {
            let change = value - previousValue;
            let data = summaryData;
            let newValue = data[index].allocatedHours + change;
            data[index].allocatedHours = newValue;
        }

        setSummaryData([]);

        setCellsGraphicInfo(cells);
        updateRollback && setCellsGraphicInfoRollback(cells);
    };

    const handleUpdate = (_event, index, _previousValue, _value) => {
        saveCellHours([cellsGraphicInfo[index]], index, cellsGraphicInfo);
    };

    const handleHover = (_event, index) => {
        if (dragging) {
            const indexResizing = cellsGraphicInfo.findIndex(
                (cell) => cell.behavior.resize
            );

            let aux = [...cellsGraphicInfo];
            aux = aux.map((cell, cellIndex) => ({
                ...cell,
                previousValue: cell.value,
                value:
                    indexResizing <= cellIndex && cellIndex <= index
                        ? cell.value
                        : cellsGraphicInfoRollback[cellIndex].value,
                undo:
                    indexResizing <= cellIndex && cellIndex <= index
                        ? false
                        : true,
                behavior: {
                    ...cell.behavior,
                    dragged: indexResizing <= cellIndex && cellIndex <= index,
                    showFill: cellIndex <= indexResizing || index < cellIndex,
                    outlined: undefined,
                },
            }));
            aux.forEach((cell, cellIndex) => {
                if (cell.undo) {
                    let data = summaryData;
                    let newValue = cell.value - cell.previousValue;
                    data[cellIndex].allocatedHours =
                        data[cellIndex].allocatedHours + newValue;
                }
            });
            setSummaryData([]);
            setCellsGraphicInfo(aux);
        }
    };

    const handleAdjustStart = (e, index) => {
        setCellsGraphicInfo(
            cellsGraphicInfo.map((cell, i) => ({
                ...cell,
                behavior: {
                    ...cell.behavior,
                    resize: i === index,
                    outlined: undefined,
                },
            }))
        );
        setOnClickEnabled(false);
        setDragging(true);
        handleCloseSidebar();
    };

    const handleAdjustEnd = (value, index) => {

        let cells = [...cellsGraphicInfo];

        cells = cells.map((cell, i) => ({
            ...cell,
            value: i === index || cell.behavior.dragged ? value : cell.value,
            behavior: {
                ...cell.behavior,
                resize: false,
                showFill: true,
                typing: false,
            },
        }));

        const indexResizing = cellsGraphicInfo.findIndex((cell) => cell.behavior.resize);

        let updatedCells = getUpdatedCells(index, cells);

        cells = cells.map((cell) => ({
            ...cell,
            behavior: {
                ...cell.behavior,
                dragged: false,
            },
        }));

        setDragging(false);
        setCellsGraphicInfoRollback(cells);
        setCellsGraphicInfo(cells);
        saveCellHours(updatedCells, indexResizing, cells);
        setOnClickEnabled(true);
    };

    const handleMouseUp = () => {
        setCellsGraphicInfo(
            cellsGraphicInfo.map((cell) => ({
                ...cell,
                behavior: {
                    ...cell.behavior,
                    dragged: false,
                    showFill: true,
                },
            }))
        );
    };

    const handleAddRemoveNote = (value, note, index) => {
        let cells = [...cellsGraphicInfoRef.current];

        setCellsGraphicInfo(
            cells.map((cell, i) => ({
                ...cell,
                hasNotes: i === index ? value : cell.hasNotes,
            }))
        );

        let currentCells = [...cellsGraphicInfo];
        currentCells[index].hasNotes = value;
        currentCells[index].note = note;
        setCellsGraphicInfo(currentCells);
        updateEmployeesCollectioNote(moment(currentCells[index].date).format("YYYY-MM-DD").toString(), value, note);
    };

    const confirmCopy = [
        {
            title: "Delete Note?",
            button: "Yes, Delete",
            description:
                "Removing these hours will also delete the attached note(s).",
        },
        {
            title: "Add Hours to Employee",
            button: "Yes, Add Hours",
            description: (
                <>
                    You are about to add hours to{" "}
                    <strong>{summaryData.name}</strong> who is unavailable as
                    they are on leave for the week.
                </>
            ),
        },
    ];

    const updateDetails = (cells) => {
        const stringCopy = JSON.stringify([...summaryDetailsData]);
        const updatedDetails = JSON.parse(stringCopy);

        if (updatedDetails) {
            const clientIndex = updatedDetails.findIndex(
                (client) => client.code === clientData.code
            );

            if (updatedDetails[clientIndex]) {
                const projectIndex = updatedDetails[
                    clientIndex
                ].projects.findIndex(
                    (project) => project.jobId === project.jobId
                );

                let allocations = [
                    ...updatedDetails[clientIndex].projects[projectIndex]
                        .allocations,
                ];

                cells.map(
                    (cell) =>
                        (allocations.filter(
                            (allocation) => allocation.id === cell.id
                        )[0].value = cell.value)
                );

                updatedDetails[clientIndex].projects[projectIndex] = {
                    ...updatedDetails[clientIndex].projects[projectIndex],
                    allocations: allocations,
                };
                setSummaryDetailsData(updatedDetails);
            }
        }
    };

    const showSaveCellHoursConfirmation = (cells) => {
        let show = false;
        if (cells.length > 0) {
            cells.forEach((cell) => {
                if (cell !== undefined) {
                    if (parseInt(cell.value) === 0 && cell.hasNotes) {
                        confirmOption =
                            saveHoursConfirmation.deleteNoteForZeroAllocation;
                        show = true;
                        return;
                    }
                    if (
                        parseInt(cell.value) > 0 &&
                        summaryData[cell.id] &&
                        parseInt(summaryData[cell.id].availableHours) === 0
                    ) {
                        confirmOption =
                            saveHoursConfirmation.addHoursToZeroAvailable;
                        show = true;
                        return;
                    }
                }
            });
        }
        return show;
    };

    const saveCellHours = (updatedCells, index, cells) => {
        if (updatedCells.length > 0) {    
            callSave(updatedCells, index, cells);
        }
    };

    const callSave = async (updatedCells, index, cells) => {
        if (showSaveCellHoursConfirmation(updatedCells)) {
            const deletNotes =
                confirmOption ===
                saveHoursConfirmation.deleteNoteForZeroAllocation;
            return callAPI(updatedCells, index, cells, deletNotes);
        } else {
            return callAPI(updatedCells, index, cells);
        }
    }

    const callAPI = async (updatedCells, index, cells, deleteNotes = false) => {

        let processCells = updatedCells;

        updatedCells.map((cell) => {
            const cellKey = "cellLoading_" + employee.userId + "_" + project.jobId + "_" + cell.id;
            cellLoading(cellKey, true);
        });

        updatedCells = updatedCells.filter(
            (cell) =>
                cell.value !==
                rollbackCells.find(
                    (rollbackCell) => rollbackCell.date === cell.date
                )?.value
        );

        if (updatedCells?.length && rollbackCells?.length) {

            setIsLoadingMore({
                loading: true,
                page: isLoadingMore.page,
                message: "Saving allocations...",
                type: "save"
            });
    
            updateEmployeesStatus("increment");
            
            const hours = updatedCells.map((item) => ({
                userId: employee.userId,
                titleId: employee.titleId,
                officeId: employee.officeId,
                jobId: project.jobId,
                isJobScope: project.isJobScope,
                date: item.date,
                hours: item.value,
                assignmentId: project.assignmentId,
                assignmentStartDate: project.assignmentStart,
                assignmentEndDate: project.assignmentEnd,
                sowStatusName: project.sowStatusName,
                sowStatusLikelihood: project.sowStatusLikelihood,
            }));
    
            let clientId = getContextSignalRClientId();
    
            let anyHoursInZero = hours.some((x) => x.hours === 0);
    
            const parameters = {
                params: {
                    mode: allocationsGridModes.weekly,
                    fromPlanner: "false",
                    clientId: clientId,
                    deleteNotes: deleteNotes || anyHoursInZero,
                },
                body: hours,
            };
    
            return saveAllocationsHours(parameters)
                .then(() => {
                    parameters.body.forEach((item, i) => {
                        var cellKey = "cellLoading_" + item.userId + "_" + item.jobId + "_" + (i + index);
                        cellLoading(cellKey, false);
                    });
    
                    if (deleteNotes || anyHoursInZero) {
                        processCells.map((cell, i) => {
                            if (cell.value === 0 && cell.hasNotes) {
                                cell.hasNotes = false;
                                let selObj = cellsGraphicInfo.findIndex(
                                    (obj) => obj.id == cell.id
                                );
                                cellsGraphicInfo[selObj].value = cell.value;
                                cellsGraphicInfo[selObj].hasNotes = false;
                                setCellsGraphicInfo(cellsGraphicInfo);
                                setRollbackCells(cellsGraphicInfo);
                                sendNotification(
                                    undefined,
                                    <>
                                        {" "}
                                        A note on
                                        <strong> {employee.name} </strong> has
                                        been deleted.
                                    </>
                                );
                            }
                            return cell;
                        });
                    }
    
                    updateRollbackCells(updatedCells);
                })
                .catch((error) => {
                    parameters.body.map((item, i) => {
                        const cellKey =
                            "cellLoading_" +
                            item.userId +
                            "_" +
                            item.jobId +
                            "_" +
                            (i + index);
                        cellLoading(cellKey, false);
                    });
    
                    const dates = getDates(updatedCells);
                    const errorCells = setErrorCells(
                        cells,
                        updatedCells,
                        index
                    );
                    handleSaveError(parameters, error, dates, errorCells);
                }).then(() => {
                    updateEmployeesStatus('decrement');
                }).then(() => {
                    if (employeesInProgress.count == 0){
                        setIsLoadingMore({
                            loading: false,
                            page: isLoadingMore.page,
                            message: "",
                            type: ""
                        });
                    }
                });
        } else {
            processCells.map((cell) => {
                let cellKey =
                    "cellLoading_" +
                    employee.userId +
                    "_" +
                    project.jobId +
                    "_" +
                    cell.id;
                cellLoading(cellKey, false);
            });
        }
    };

    const updateEmployeesStatus = async (value) => {
        setEmployeesInProgress({type: value});
    };

    const addNoteButtonVisibility = async (parentDiv, show) => {
        if (parentDiv) {
            parentDiv.onmouseover = function () {};
            let buttons = parentDiv.getElementsByTagName("button");
            if (buttons && buttons.length > 0) {
                for (let button of buttons) {
                    if (button && show) {
                        button.style.visibility = "visible";
                        button.parentNode.style.visibility = "visible";
                    } else if (button) {
                        button.style.visibility = "hidden";
                        button.parentNode.style.visibility = "hidden";
                    }
                }
            }
        }
    };

    const cellLoading = async (cellKey, show) => {
        let loading = document.getElementById(cellKey);
        if (loading) {
            if (show){
                loading.style.display = "";
            } else {
                loading.style.display = "none";
            }
            let parentDiv = loading.parentNode;
            addNoteButtonVisibility(parentDiv, !show);
        }
    };

    const setErrorCells = (cells, updatedCells, _index) => {
        const cellsCopy = [...cells];

        const firstUpdatedCellIndex = cellsCopy.findIndex(
            (cell) => cell.date === updatedCells[0].date
        );
        const lastUpdatedCellIndex = cellsCopy.findIndex(
            (cell) => cell.date === updatedCells[updatedCells.length - 1].date
        );

        const errorCells = cellsCopy.map((cell, cellIndex) => ({
            ...cell,
            behavior: {
                ...cell.behavior,
                dragged: false,
                showFill: true,
                typing: false,
                outlined: {
                    left: cellIndex === firstUpdatedCellIndex,
                    right: cellIndex === lastUpdatedCellIndex,
                    top:
                        cellIndex >= firstUpdatedCellIndex &&
                        cellIndex <= lastUpdatedCellIndex,
                    bottom:
                        cellIndex >= firstUpdatedCellIndex &&
                        cellIndex <= lastUpdatedCellIndex,
                },
            },
        }));
        setCellsGraphicInfo(errorCells);
        return errorCells;
    };

    const updateRollbackCells = (cells) => {
        let rollbackCellsCopy = [...rollbackCells];
        rollbackCellsCopy = rollbackCellsCopy.map((cell) => ({
            ...cell,
            value:
                cells.find((item) => item.date === cell.date)?.value ??
                cell.value,
            hasNotes:
                cells.find((item) => item.date === cell.date)?.hasNotes ??
                cell.hasNotes,
        }));
        setRollbackCells(rollbackCellsCopy);
    };

    const handleSaveError = (parameters, error, dates, cells) => {
        if (TRY_AGAIN_CODE_RESPONSES.find((code) => code === error.status)) {
            showSaveErrorSnack(parameters, dates, cells);
        } else {
            showSaveErrorPopup(
                error,
                dates,
                handleCloseSaveErrorPopup,
                cells,
                parameters
            );
        }
    };

    const rollbackDeleteNotes = (updatedCells) => {
        if (executeRollBack) {
            let data = summaryData;
            const rollbackedCells = updatedCells.map((cell) => {
                data[cell.id].allocatedHours =
                    data[cell.id].allocatedHours -
                    cell.value +
                    cell.previousValue;
                setSummaryData([]);
                return { ...cell, value: cell.previousValue };
            });

            const enhancedData = cellsGraphicInfoRef.current.map((cell) => ({
                ...cell,
                value:
                    updatedCells.find(
                        (updatedCells) => updatedCells.id === cell.id
                    )?.previousValue ?? cell.value,
                hasNotes:
                    updatedCells.find(
                        (updatedCells) => updatedCells.id === cell.id
                    )?.hasNotes ?? cell.hasNotes,
                behavior: {
                    ...cell.behavior,
                },
            }));

            setCellsGraphicInfo(enhancedData);
            updateDetails(rollbackedCells);
        }
    };

    const handleTryAgain = (parameters, dates, cells) => {
        executeRollBack = false;
        saveAllocationsHours(parameters)
            .then(() => {
                resetCells(cellsGraphicInfo);
            })
            .catch((error) => {
                insightsException(error);
                showSaveErrorPopup(
                    error,
                    dates,
                    handleCloseSaveErrorPopup,
                    cells,
                    parameters
                );
            });
    };

    const resetCells = (cells) => {
        const enhancedData = cells.map((cell) => ({
            ...cell,
            behavior: {
                ...cell.behavior,
                dragged: false,
                resize: false,
                showFill: true,
                outlined: false,
                typing: false,
            },
        }));

        setCellsGraphicInfo(enhancedData);
        setRollbackCells(enhancedData);
        executeRollBack = true;
    };

    let executeRollBack = true;

    const showSaveErrorSnack = (parameters, dates, cells) => {
        sendNotification(
            undefined,
            <SaveHoursErrorSnack projectName={project.jobName} onTryAgain={() => handleTryAgain(parameters, dates,cells)}/>,
            SNACK_NOTIFICATION_DURATION,
            "saveHoursError",
            null
        );
    };

    const showSaveErrorPopup = (error, dates, onCancel, cells, parameters) => {
        const modalContent = (
            <SaveHoursErrorModal
                projectName={project.jobName}
                dates={dates}
                initialError={error}
                onCancel={onCancel}
                parameters={parameters}
                resetCells={resetCells}
                cells={cells}
            />
        );
        const modal = (
            <GenericModal content={modalContent} parent="divPageModal" />
        );
        ReactDOM.render(modal, document.getElementById("divPageModal"));
    };

    const handleCloseSaveErrorPopup = () => {
        executeRollBack = true;
    };

    const getDates = (cells) => {
        let dates = [];
        dates.push(moment(cells[0].date).format("MMM D"));
        cells.length > 1 &&
            dates.push(moment(cells[cells.length - 1].date).format("MMM D"));
        dates = dates.join(", ");
        return dates;
    };

    const handleCloseSidebar = () => {
        ReactDOM.render(null, document.getElementById("divPageSidebar"));
    };

    const updateEmployeesCollectioNote = (date, hasNotes, note, hours = null) => {
        let newEmployees = [...employees];
        let employeeIndex = -1;
        if (employee.userId > 0){
            employeeIndex = newEmployees[0].findIndex((e)=> e.userId == employee.userId);    
        }
        else if (employee.userId == 0){
            employeeIndex = newEmployees[0].findIndex((e)=> e.userId == employee.userId && e.titleId == employee.titleId && e.officeId == employee.officeId && e.assignmentId == employee.assignmentId);
        }
        const projectIndex = newEmployees[0][employeeIndex].projects.findIndex((p)=> p.jobId == project.jobId);
        const weekIndex = newEmployees[0][employeeIndex].projects[projectIndex].weeks.findIndex((w) => moment(w.date).format("YYYY-MM-DD").toString() == date);
        if (projectIndex >= 0 && weekIndex >= 0){
            newEmployees[0][employeeIndex].projects[projectIndex].weeks[weekIndex].hasNotes = hasNotes;
            newEmployees[0][employeeIndex].projects[projectIndex].weeks[weekIndex].note = note;
            if (hours != null && hours > 0){
                newEmployees[0][employeeIndex].projects[projectIndex].weeks[weekIndex].allocatedHours = hours;
            }
        }
        setEmployees(newEmployees);
    }

    const updateNote = (note, event) => {
        if (event == "copy"){
            if (cellsGraphicInfo.length > note.tabIndex){
                if (note.allWeeks) {
                    const currentValue = cellsGraphicInfo[note.tabIndex - 1].value;
                    const currentNote = note.note;
                    let currentDate = note.date;
                    let newCollection = [...cellsGraphicInfo];
                    let noNotesCount = 0;
                    let hasNotesCount = 0;
                    let index = note.tabIndex - 1; 
                    while (index < cellsGraphicInfo.length - 1){
                        index++;
                        if (cellsGraphicInfo[index].allocatedHours == -1 || cellsGraphicInfo[index].value == -1){
                            continue;
                        }
                        if (!note.withHours && (cellsGraphicInfo[index].allocatedHours == 0 || cellsGraphicInfo[index].value == 0)){
                            noNotesCount++;
                            continue;
                        }
                        if (cellsGraphicInfo[index].hasNotes){
                            hasNotesCount++;
                            continue;
                        }
                        note.updateFunc.mutateAsync(
                            {
                                newDate: currentDate,
                                note: currentNote, 
                                days: note.days
                            }).then(() => {
                                sendNotification(undefined, <>The note was copied to next week</>);
                            });
                        if (note.withHours) {
                            newCollection[index].value = currentValue;
                            newCollection[index].allocatedHours = currentValue;
                            handleUpdate(event, index, cellsGraphicInfo[index - 1].allocatedHours, currentValue);
                            let data = summaryData;
                            let newValue = data[index].allocatedHours + currentValue;
                            data[index].allocatedHours = newValue;
                            setSummaryData(data);
                        }
                        newCollection[index].hasNotes = true;
                        newCollection[index].note = currentNote;
                        updateEmployeesCollectioNote(currentDate, true, currentNote, currentValue);
                        currentDate = addWeeksToDate(moment(currentDate).toDate(), 1)
                    }
                    setCellsGraphicInfo(newCollection);
                    if (noNotesCount > 0){
                        sendNotification(undefined, <>{noNotesCount} notes were not copied because the cells have no value</>);
                    }
                    if (hasNotesCount > 0){
                        sendNotification(undefined, <>{hasNotesCount} notes were not copied because the cells already has notes</>);
                    }
                }
                else {
                    if (cellsGraphicInfo[note.tabIndex].allocatedHours == -1 || cellsGraphicInfo[note.tabIndex].value == -1){
                        sendNotification(undefined, <>Cannot copy note to next cell</>);
                    }
                    else if (!note.withHours && (cellsGraphicInfo[note.tabIndex].allocatedHours == 0 || cellsGraphicInfo[note.tabIndex].value == 0)){
                        sendNotification(undefined, <>Cannot add a note on a cell without allotment</>);
                    }
                    else if (cellsGraphicInfo[note.tabIndex].hasNotes){
                        sendNotification(undefined, <>Cannot add a note on a cell with notes</>);
                    }
                    else if (note.withHours){
                        const val = cellsGraphicInfo[note.tabIndex - 1].value;
                        note.updateFunc.mutateAsync(
                            {
                                newDate: note.date,
                                note: note.note, 
                                days: note.days
                            }).then(() => {
                                sendNotification(undefined, <>The note was copied to next week with hours</>);
                            });
                        let newCollection = [...cellsGraphicInfo]
                        newCollection[note.tabIndex].hasNotes = true;
                        newCollection[note.tabIndex].note = note.note;
                        newCollection[note.tabIndex].value = val;
                        newCollection[note.tabIndex].allocatedHours = val;
                        handleUpdate(event, note.tabIndex, cellsGraphicInfo[note.tabIndex - 1].allocatedHours, val);
                        setCellsGraphicInfo(newCollection);
                        let data = summaryData;
                        let newValue = data[note.tabIndex].allocatedHours + val;
                        data[note.tabIndex].allocatedHours = newValue;
                        setSummaryData(data);
                        updateEmployeesCollectioNote(note.date, true, note.note, val);
                    }
                    else if (!note.withHours && (cellsGraphicInfo[note.tabIndex].allocatedHours > 0 || cellsGraphicInfo[note.tabIndex].value > 0)) {   
                        note.updateFunc.mutateAsync(
                            {
                                newDate: note.date,
                                note: note.note, 
                                days: note.days
                            }).then(() => {
                                sendNotification(undefined, <>The note was copied to next week</>);
                            });
                        let newCollection = [...cellsGraphicInfo]
                        newCollection[note.tabIndex].hasNotes = true;
                        newCollection[note.tabIndex].note = note.note;
                        setCellsGraphicInfo(newCollection);
                        updateEmployeesCollectioNote(note.date, true, note.note);
                    } else {
                        sendNotification(undefined, <>Cannot add a note on a cell without allotment</>);
                    }
                }
            } else {
                sendNotification(undefined, <>No more cells to copy a note</>);
            }
        }
        if (event == "delete"){
            if (cellsGraphicInfo.length > note.tabIndex){
                if (!cellsGraphicInfo[note.tabIndex].hasNotes){
                    sendNotification(undefined, <>This cell does not have notes</>);
                }
                note.updateFunc.mutateAsync().then(() => {
                    sendNotification(undefined, <>The note was deleted</>);
                });
                let newCollection = [...cellsGraphicInfo]
                newCollection[note.tabIndex].hasNotes = false;
                newCollection[note.tabIndex].note = "";
                setCellsGraphicInfo(newCollection);
                updateEmployeesCollectioNote(note.date, false, "");
            } else {
                sendNotification(undefined, <>Invalid note delete</>);
            }
        }
    }

    const handleOnDragging = (e, i, v) => {
        setDraggingStatus({index: i, value: v});
    }

    const handleOnCopyHours = (e, index) => {
        e.preventDefault();
        let newCollection = [...cellsGraphicInfo];
        const val = newCollection[index].value;
        if (cellsGraphicInfo[index + 1].allocatedHours > -1 || cellsGraphicInfo[index + 1].value > -1){
            cellsGraphicInfo[index + 1].allocatedHours = val;
            cellsGraphicInfo[index + 1].value = val;
            setCellsGraphicInfo(newCollection);
            handleUpdate(e, index + 1, cellsGraphicInfo[index].allocatedHours, val);
        } else {
            sendNotification(undefined, <>Invalid hours copy</>);
        }
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Wrapper id={"projectDetailWrapper_" + employee.userId + "_" + project.jobId}>
                    <Cells onMouseUp={handleMouseUp} highlighted={hover}>
                        {cellsGraphicInfo &&
                            cellsGraphicInfo.map((cell, index) => (
                                <AllocationColumn
                                    id={"projectColumn_" + employee.userId + "_" + project.jobId + "_" + index}
                                    key={index}
                                    disabled={cell.value === nullCellValue}
                                    outlined={cell.behavior.outlined}
                                >
                                    {cell.value !== nullCellValue && (
                                        <Cell
                                            id={"cell" + employee.userId + "_" + project.jobId + "_" + index}
                                            draggingStatus={draggingStatus}
                                            project={project}
                                            employee={employee}
                                            cellKey={ employee.userId + "_" + project.jobId + "_" + index}
                                            date={cell.date}
                                            value={cell.value}
                                            available={cell.available}
                                            tabIndex={index}
                                            onChange={(event, value, updateRollback) => {
                                                handleChange(event, cell.value, value, index, updateRollback);
                                            }}
                                            onUpdate={(event, value) =>
                                                handleUpdate(event, index, cell.value, value)
                                            }
                                            onHover={(event) => handleHover(event, index)}
                                            onAdjustStart={(e) => handleAdjustStart(e, index)}
                                            onAdjustEnd={(value) => handleAdjustEnd(value, index)}
                                            dragged={cell.behavior.dragged}
                                            maxValue={cellMaxValue}
                                            resizeMaxValue={cellMaxValueResize}
                                            resize={cell.behavior.resize}
                                            showFill={cell.behavior.showFill}
                                            showScale={cell.behavior.dragged}
                                            stepValue={cellStepValue}
                                            hasNotes={cell.hasNotes && cell.value > 0}
                                            onAddRemoveNote={(value, note) => {
                                                handleAddRemoveNote(value, note, index)
                                            }}
                                            onClick={(event) => handleCellClick(event, index)}
                                            typing={cell.behavior.typing}
                                            readOnly={readonly}
                                            userHasNotesAccessToAddEdit={hasNotesPermissionToAddEdit}
                                            userHasNotesAccessToView={hasNotesPermissionToView}
                                            onUpdateNote={updateNote}
                                            noteText={cell.note}
                                            onDragging={handleOnDragging}
                                            onCopyHours={handleOnCopyHours}
                                        />
                                    )}
                                </AllocationColumn>
                            ))}
                    </Cells>
                </Wrapper>
            </ClickAwayListener>
        </>
    );
};

ProjectDetail.propTypes = {};

export default ProjectDetail;
