import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Text from "components/UI/Text";
import Card from "components/UI/Card";
import Button from "components/UI/Button";
import Dot from "components/Icon/Dot";
import { format } from "date-fns";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "styles/Sidebar.css";
import { useProjects } from "utilities/API/Projects";
import env from "react-dotenv";
import { Row, Col } from "react-bootstrap";
import More from "./More";
import ProgressBar from "components/UI/ProgressBar";
import { useStylesForText } from "styles/MakeStyles";
import Loader from "components/UI/Loader";
import { useCrypto } from "hooks/Crypto/useCrypto";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";

import ProgressStatusCircle from "components/design-system/controls/ProgressStatusCircle/ProgressStatusCircle";

let renderCount = 1;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
`;

const Container = styled.div`
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${global.config.sizes.sidebar.gutter};
    flex: 1;

    ::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
    :hover {
        ::-webkit-scrollbar-thumb {
            background-color: #dde1e5;
        }
    }
`;
const Title = styled.div`
    margin: 0.5em 0;
`;
const TitleColumn = styled(Col)`
    padding-right: 0;
`;
const Subtitle = styled.div`
    margin: 0.5em 0 2.5em 0;
`;
const Section = styled.div`
    margin: 1em 0 0.5em 0;
`;
const Footer = styled.div`
    padding: ${global.config.sizes.sidebar.gutter};
    padding-top: 40px;
    padding-bottom: 24px;
    text-align: center;

    > * {
        margin-bottom: 0.5rem;
    }
`;
const ButtonLink = styled.a`
    p {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    button {
        margin-bottom: 0.5rem;
    }
    margin-bottom: 0.5rem;
`;

const TextLink = styled.a`
    color: ${global.config.colors.black};
    text-decoration: underline;
    display: block;
    &:hover {
        color: ${global.config.colors.black};
    }
`;

const MoreMenuContainer = styled.div`
    padding-top: 0.25rem;
`;

const StyledWrapper = styled(HStack)`
    justify-content: space-between;
    padding: 0;
    gap: 24px;
`;

const StyledSowContent = styled(VStack)`
    padding: 0;
    gap: 4px;
`;
const StyledSowUpdatedDate = styled(VStack)`
    color: ${COLOR_VALUES.Gray_4};
    ${TEXT_VARIANT_STYLES.Descriptive_2_1};
    padding: 0;
    gap: 4px;
    white-space: nowrap;
`;

const ProjectDrawer = (props) => {
    const { getProjectDetail } = useProjects();
    const { encrypt } = useCrypto();
    const { projectId } = props;
    const [project, setProject] = useState({
        name: null,
        jobCode: null,
        client: null,
        clientFamily: null,
        startDate: null,
        endDate: null,
        status: null,
        completion: 0,
        producers: [],
        jobId: null,
        statusName: null,
        statusUpdatedDate: null,
        statusLikelihood: 0,
    });
    const classesText = useStylesForText();
    const updatedDate = format(
        new Date(project.statusUpdatedDate),
        "MMM dd, yyyy"
    );

    useEffect(() => {
        if (projectId) {
            getProjectDetail([{ name: "$jobId", value: projectId }])
                .then((payload) => {
                    if (!payload.error && payload.length > 0) {
                        setProject({
                            name: payload[0].JobName,
                            jobCode: payload[0].JobCode,
                            client: payload[0].Client,
                            clientFamily: payload[0].ClientFamily,
                            startDate: payload[0].JobStartDate,
                            endDate: payload[0].JobEndDate,
                            status: payload[0].SOWStatus,
                            completion: payload[0].CompletePercentage
                                ? parseInt(payload[0].CompletePercentage)
                                : 0,
                            producers: payload[0].LeadProducer,
                            jobId: payload[0].JobId,
                            office: payload[0].Office,
                            retainer: payload[0].Retainer,
                            statusName: payload[0].SowStatusName,
                            statusUpdatedDate: payload[0].SOWStatusUpdated,
                            statusLikelihood: payload[0].SowStatusLikelihood,
                        });
                    }
                })
                .catch(() => {
                    /* */
                });
        }
    }, [projectId]);

    return (
        <Wrapper>
            <Container>
                {projectId && project.name ? (
                    <>
                        <Title>
                            <Row>
                                <TitleColumn>
                                    <Text variant="largeBold">
                                        {project.name}
                                    </Text>
                                </TitleColumn>
                                <Col xs={1}>
                                    <MoreMenuContainer>
                                        <More
                                            visible
                                            infoDisabled
                                            project={project}
                                            direction="left"
                                        />
                                    </MoreMenuContainer>
                                </Col>
                            </Row>
                        </Title>
                        <Subtitle>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Job Code: {project.jobCode}
                            </Text>
                        </Subtitle>

                        {project.statusLikelihood !== 0 &&
                            project.statusLikelihood &&
                            project.statusName !== "N/A" &&
                            project.statusName !== "None" && (
                                <StyledWrapper>
                                    <StyledSowContent>
                                        <Text
                                            variant="xsmallLight"
                                            className={classesText.gray2}
                                            style={{ marginBottom: "4px" }}
                                        >
                                            SOW Status
                                        </Text>
                                        <ProgressStatusCircle
                                            value={project.statusLikelihood}
                                            title={project.statusName}
                                            showMessage={true}
                                        />
                                    </StyledSowContent>

                                    <StyledSowUpdatedDate>
                                        Last Updated {updatedDate}
                                    </StyledSowUpdatedDate>
                                </StyledWrapper>
                            )}

                        {project.producers.map((producer, index) => (
                            <Card
                                key={index}
                                label="Lead Producer"
                                photo={
                                    producer.LargePhotoUrl &&
                                    `${env.BESTWORK_REACT_ASSET_DOMAIN}/${producer.LargePhotoUrl}`
                                }
                                name={producer.FullName}
                                title={producer.Title}
                                department={producer.Department}
                                email={producer.Email}
                            />
                        ))}
                        {(!project?.producers?.length ||
                            !project?.producers[0]?.FullName) && (
                            <Section>
                                <Text
                                    variant="xsmallLight"
                                    className={classesText.gray2}
                                >
                                    Lead Producer
                                </Text>
                                <Text variant="small">Not Assigned</Text>
                            </Section>
                        )}
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Client
                            </Text>
                            <Text variant="small">{project.client}</Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Client Family
                            </Text>
                            <Text variant="small">{project.clientFamily}</Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Retainer
                            </Text>
                            <Text variant="small">{project.retainer}</Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Lead Office
                            </Text>
                            <Text variant="small">{project.office}</Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Project Start Date
                            </Text>
                            <Text variant="small">
                                {moment(project.startDate).format("MMM D, YY")}
                            </Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Expected End
                            </Text>
                            <Text variant="small">
                                {moment(project.endDate).format("MMM D, YY")}
                            </Text>
                        </Section>
                        <Section>
                            <Text
                                variant="xsmallLight"
                                className={classesText.gray2}
                            >
                                Completion
                            </Text>
                            <ProgressBar
                                percent={parseInt(project.completion)}
                            />
                            <Text variant="small">{project.completion}%</Text>
                        </Section>
                    </>
                ) : (
                    <Loader height="5rem" width="5rem" />
                )}
            </Container>
            <ActionPermissionGuard code="AC_VIEW_PROJECT_ACTION">
                <Footer>
                    <ButtonLink
                        href={`${global.config.routes.allocation}?filters=${encrypt("jobId=" + project.jobId)}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button style={{ width: "100%" }}>
                            View Project Allocations
                        </Button>
                    </ButtonLink>
                    <TextLink
                        href={
                            "/redirectto?url=" +
                            env.BESTWORK_REACT_REPORTER10_LINK +
                            "job/JobReport.aspx?JobID=" +
                            projectId + 
                            "&env=" + env.ENVIRONMENT_NAME + "&vpn=true"
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text variant="xsmall">View Project Dashboard</Text>
                    </TextLink>
                </Footer>
            </ActionPermissionGuard>
        </Wrapper>
    );
};

export default ProjectDrawer;
