import React, { useContext, useEffect, useRef, useState } from "react";
import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { useOverlayTriggerState } from "@react-stately/overlays";
import {
    OverlayContainer,
    OverlayProvider,
    useOverlayPosition,
    useOverlayTrigger,
} from "@react-aria/overlays";
import { useButton } from "@react-aria/button";
import { useLocale } from "@react-aria/i18n";
import { createCalendar, CalendarDate } from "@internationalized/date";
import { startOfWeek, endOfWeek, getYear, getMonth, format } from "date-fns";
import { useCalendarState } from "@react-stately/calendar";

import DatePickerCalendar, {
    getCurrentWeekStartCalendarDate,
} from "components/design-system/controls/week-navigator/DatePickerCalendar";
import DatePickerPopOver from "components/design-system/controls/datepicker/DatePickerPopOver";
import HStack from "components/design-system/layout/HStack";
import QuaternaryButton from "components/design-system/controls/button/QuaternaryButton";
import {
    DropdownWrapper,
    DropdownButtonWrapper,
    DropdownButton,
    DropdownButtonText,
    DropdownButtonIcon,
    DropdownButtonTextRow,
} from "components/design-system/controls/dropdown/DropdownStyledComponents";
import VStack from "components/design-system/layout/VStack";

import SessionContext from "context/app/SessionContext";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";

import { checkTenureNotStarted } from "pages/timesheet/timesheet-grid/TimesheetGrid";

function ReactAriaButton(props) {
    let ref = props.buttonRef;
    let { buttonProps } = useButton(props, ref);
    return (
        <DropdownButton
            {...buttonProps}
            ref={ref}
            isDisabled={props.disabled}
            feedbackType={props.feedbackType}
            isWeekNavTimesheet={true}
            $hasSelection={props.$hasSelection}
            style={{
                border: "none",
            }}
        >
            {props.children}
        </DropdownButton>
    );
}

export const getWeekStartOrEndDate = (weekCount, isStartOfWeek, date) => {
    let focusedDateFormat = new CalendarDate(
        date?.year,
        date?.month,
        date?.day
    );

    focusedDateFormat = focusedDateFormat.add({ weeks: weekCount });

    let dateFnsFormatDate = getDateFnsFormatDate(
        focusedDateFormat?.year,
        focusedDateFormat?.month,
        focusedDateFormat?.day
    );

    focusedDateFormat = isStartOfWeek
        ? startOfWeek(dateFnsFormatDate, { weekStartsOn: 1 })
        : endOfWeek(dateFnsFormatDate, { weekStartsOn: 1 });

    focusedDateFormat = new CalendarDate(
        getYear(focusedDateFormat),
        getMonth(focusedDateFormat) + 1,
        format(focusedDateFormat, "d")
    );

    focusedDateFormat.toString();
    return focusedDateFormat;
};

export const getDateFnsFormatDate = (year, month, day) => {
    return new Date(year, month - 1, day, 11, 55, 0);
};

const WeekNavigatorMain = ({
    disabled = false,
    isSmallSize = false,
    feedbackType,
    dateRangesWithWarning,
    dateRangesWithIcon,
    ...props
}) => {
    const { startDate } = useContext(SessionContext);

    const datePickerState = useDatePickerState(props);
    const ref = useRef();
    const { groupProps, dialogProps, calendarProps } = useDatePicker(
        props,
        datePickerState,
        ref
    );

    const overlayState = useOverlayTriggerState({});

    const triggerRef = useRef();
    const overlayRef = useRef();

    const { triggerProps, overlayProps } = useOverlayTrigger(
        { type: "dialog" },
        overlayState,
        triggerRef
    );

    const { overlayProps: positionProps } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef,
        placement: "bottom",
        offset: 5,
        isOpen: overlayState.isOpen,
    });

    const { locale } = useLocale();
    const state = useCalendarState({
        ...calendarProps,
        locale,
        createCalendar,
    });

    const {
        selectedTimesheetStartDate,
        setSelectedTimesheetStartDate,
        pendingTimesheetCalendarDate,
    } = useContext(WeekNavigatorContext);

    const [weekStartDate, setWeekStartDate] = useState(null);
    const [weekEndDate, setWeekEndDate] = useState(null);

    const closeOverlay = () => {
        setWeekStartDate(
            getWeekStartOrEndDate(0, true, datePickerState.dateValue)
        );
        setWeekEndDate(
            getWeekStartOrEndDate(0, false, datePickerState.dateValue)
        );
        state.setFocusedDate(datePickerState.dateValue);
        state.setValue(datePickerState.dateValue);

        overlayState.setOpen(false);
        datePickerState.setOpen(false);
    };

    const updateValuesOfWeekNavigator = (
        weekCount,
        weekDate = state.focusedDate
    ) => {
        const date = getWeekStartOrEndDate(weekCount, true, weekDate);
        state.setFocusedDate(date);
        state.setValue(date);

        setWeekStartDate(date);
        setSelectedTimesheetStartDate(date);
        setWeekEndDate(getWeekStartOrEndDate(weekCount, false, weekDate));
    };

    const handleOnClickPrevWeek = () => {
        updateValuesOfWeekNavigator(-1);
    };

    const handleOnClickNextWeek = () => {
        updateValuesOfWeekNavigator(1);
    };

    const handleOnClickCurrentWeek = () => {
        updateValuesOfWeekNavigator(0, getCurrentWeekStartCalendarDate());
    };

    // week start and end date initial value set
    useEffect(() => {
        if (pendingTimesheetCalendarDate) {
            updateValuesOfWeekNavigator(0, pendingTimesheetCalendarDate);
        } else if (selectedTimesheetStartDate) {
            updateValuesOfWeekNavigator(0, selectedTimesheetStartDate);
        } else {
            updateValuesOfWeekNavigator(0);
        }
    }, []);

    const buttonLabel = (
        <>
            {weekStartDate &&
                format(
                    getDateFnsFormatDate(
                        weekStartDate.year,
                        weekStartDate.month,
                        weekStartDate.day
                    ),
                    "MMM"
                )}
            &nbsp;
            {weekStartDate?.day}&nbsp;&#45;&nbsp;
            {weekEndDate &&
                format(
                    getDateFnsFormatDate(
                        weekEndDate.year,
                        weekEndDate.month,
                        weekEndDate.day
                    ),
                    "MMM"
                )}
            &nbsp;{weekEndDate?.day}
        </>
    );

    const prevWeekOfWeekStartDate = weekStartDate
        ? getWeekStartOrEndDate(-1, true, weekStartDate)
        : null;

    const tenureNotStarted = prevWeekOfWeekStartDate
        ? checkTenureNotStarted(prevWeekOfWeekStartDate, startDate)
        : false;

    const currentWeekSelected =
        weekStartDate?.compare(getCurrentWeekStartCalendarDate()) === 0
            ? true
            : false;

    return (
        <>
            <VStack spacing="Zero_25">
                <DropdownWrapper
                    style={{
                        cursor: disabled ? "not-allowed" : "pointer",
                        width: "264px",
                    }}
                >
                    <DropdownButtonWrapper
                        isDisabled={disabled}
                        {...groupProps}
                        ref={ref}
                        style={{
                            pointerEvents: disabled ? "none" : "",
                        }}
                    >
                        <ReactAriaButton
                            {...triggerProps}
                            buttonRef={triggerRef}
                            disabled={disabled}
                            feedbackType={feedbackType}
                            $hasSelection={!overlayState.isOpen}
                        >
                            <DropdownButtonTextRow justify="flex-start">
                                <DropdownButtonText
                                    color="Black"
                                    variant="Headline_4"
                                    style={{ width: "auto" }}
                                >
                                    {buttonLabel}
                                </DropdownButtonText>
                                <DropdownButtonIcon
                                    name="caret-down"
                                    color="Gray_2"
                                    style={{ flexShrink: 0 }}
                                />
                            </DropdownButtonTextRow>
                        </ReactAriaButton>
                    </DropdownButtonWrapper>
                </DropdownWrapper>
                {!disabled && (
                    <OverlayProvider>
                        {overlayState.isOpen && (
                            <>
                                <OverlayContainer>
                                    <DatePickerPopOver
                                        {...dialogProps}
                                        {...overlayProps}
                                        {...positionProps}
                                        ref={overlayRef}
                                        isOpen={overlayState.isOpen}
                                        onClose={closeOverlay}
                                        marginTop={0}
                                    >
                                        <DatePickerCalendar
                                            state={state}
                                            closeOverlay={closeOverlay}
                                            dateRangesWithWarning = {dateRangesWithWarning}
                                            dateRangesWithIcon = {dateRangesWithIcon}
                                        />
                                    </DatePickerPopOver>
                                </OverlayContainer>
                            </>
                        )}
                    </OverlayProvider>
                )}

                <HStack spacing="Zero_5">
                    <QuaternaryButton
                        leadingIcon="caret-left"
                        {...{
                            "aria-label": "Prev Week",
                        }}
                        onClick={handleOnClickPrevWeek}
                        disabled={disabled || tenureNotStarted}
                    />
                    <QuaternaryButton
                        leadingIcon="way-finding-here"
                        {...{
                            "aria-label": "Current Week",
                        }}
                        onClick={handleOnClickCurrentWeek}
                        disabled={disabled || currentWeekSelected}
                    />
                    <QuaternaryButton
                        leadingIcon="caret-right"
                        {...{
                            "aria-label": "Next Week",
                        }}
                        onClick={handleOnClickNextWeek}
                        disabled={disabled}
                    />
                </HStack>
            </VStack>
        </>
    );
};

export default WeekNavigatorMain;
