import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    background-color: ${global.config.colors.gray7};
    padding: 0;
    border-radius: 1rem;
    box-shadow: inset 0 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
`;

const Bar = styled.span`
    display: block;
    background-color: ${global.config.colors.blue1};
    border-radius: 1rem;
    transition: width 500ms ease-in-out;
`;

const ProgressBar = ({ percent, height }) => {
    return (
        <Container>
            <Bar style={{ width: `${percent}%`, height: height }} />
        </Container>
    );
};

ProgressBar.defaultProps = {
    percent: 0,
    height: "0.5rem",
};

ProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,
    height: PropTypes.string,
};

export default ProgressBar;
