import { useContext, useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Dropdown from "components/design-system/forms/Dropdown";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";
import TextArea from "components/design-system/forms/TextArea";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";

import ProjectsDashboardLayoutContext from "context/Projects/ProjectsDashboardLayoutContext";

import useProjectsDashboardNotes, {
    useProjectByIdDetails,
    useProjectsNoteTypes,
} from "pages/projects/project-dashboard/single/useProjectsDashboardNotes";

const ModalCTA = ({
    isEditing,
    handleOnClickDeleteNote,
    isLoading,
    onClose,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add Note",
                leadingIcon: isEditing ? "save" : null,
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteNote(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformProjectsNoteDataToFormData(noteData = {}) {
    return {
        noteType: noteData.TypeId,
        note: noteData.Text,
    };
}

function transformFormDataToProjectsNoteData(
    formData = {},
    ProjectsNoteData = {}
) {
    const newProjectsNoteData = { ...ProjectsNoteData };
    newProjectsNoteData.TypeId = formData.noteType;
    newProjectsNoteData.Content = formData.note;

    return newProjectsNoteData;
}

const ProjectsDashboardNotesForm = ({ noteIdToEdit, onClose }) => {
    const { projectId } = useParams();
    const { setFlagToLoad } = useContext(ProjectsDashboardLayoutContext);
    const { projectByIdDetails } = useProjectByIdDetails();
    const {
        useProjectsNotesList,
        createProjectDashboardNote,
        updateProjectDashboardNote,
        deleteProjectDashboardNote,
    } = useProjectsDashboardNotes();
    const notesList = useProjectsNotesList({
        projectIdFE: projectId,
        noteTypeId: "",
    });
    const { projectsNoteTypes } = useProjectsNoteTypes();
    const [inProgress, setInProgress] = useState(false);

    const currentProjectsNoteData = notesList?.data?.find(
        (note) => note.Id === noteIdToEdit
    );

    if (
        notesList.isLoading ||
        projectsNoteTypes.isLoading ||
        projectByIdDetails.isLoading
    ) {
        return <>Loading...</>;
    }

    if (
        notesList.isError ||
        projectsNoteTypes.isError ||
        projectByIdDetails.isError
    ) {
        return <>Error</>;
    }

    const isEditing = typeof noteIdToEdit !== "undefined";
    const modifier = isEditing
        ? updateProjectDashboardNote
        : createProjectDashboardNote;

    if (isEditing && !currentProjectsNoteData) {
        return <>Could not find note</>;
    }

    const getNameOfNotification = (res) => {
        return res ? `A note on ${projectByIdDetails?.data?.Name}` : "note";
    };

    const handleOnClickDeleteNote = (prevData) => {
        deleteProjectDashboardNote
            .mutateAsync({ noteId: noteIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={getNameOfNotification(response?.status === 200)}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    response?.status === 200,
                    response?.status === 200
                        ? {
                              label: "Undo",
                              onClick: () =>
                                  onSubmit({
                                      ...prevData,
                                      isNoteRemoved: true,
                                  }),
                          }
                        : null
                );
            });
    };

    const onSubmit = (formData) => {
        const data = transformFormDataToProjectsNoteData(
            formData,
            currentProjectsNoteData
        );
        const newModifier = formData.isNoteRemoved
            ? createProjectDashboardNote
            : modifier;
        const allData = {
            noteId: noteIdToEdit,
            body: data,
        };
        newModifier.mutateAsync(allData).then((response) => {
            onClose();
            setInProgress(false);
            isEditing && setFlagToLoad(true);
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={formData.isNoteRemoved ? false : isEditing}
                    name={getNameOfNotification(response?.status === 200)}
                />
            );
        });
    };

    const noteTypesOptions = !projectsNoteTypes.isLoading
        ? getDropdownOptionsNoDefaultVal(projectsNoteTypes?.data)
        : [];

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformProjectsNoteDataToFormData(
                currentProjectsNoteData
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack>
                    <Dropdown
                        name="noteType"
                        placeholder="Select"
                        validations={{
                            required: true,
                        }}
                        label="Note Type"
                        itemsAreLoading={projectsNoteTypes.isLoading}
                        options={noteTypesOptions}
                    />
                    <TextArea
                        name="note"
                        showClearIcon={true}
                        characterCounter={true}
                        label="Note"
                        placeholder="Add a note to your project"
                        validations={{
                            required: true,
                        }}
                    />
                </VStack>

                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteNote={handleOnClickDeleteNote}
                    onClose={onClose}
                    isLoading={modifier?.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default ProjectsDashboardNotesForm;
