import React, { useContext, useEffect, useRef, useState } from "react";
import Dotdotdot from "react-dotdotdot";
import { COLOR_VALUES } from "components/design-system/config/colors";

import VStack from "components/design-system/layout/VStack";
import Cell from "pages/timesheet/timesheet-grid/Cell";
import QuaternaryButton from "components/design-system/controls/button/QuaternaryButton";
import {
    TimesheetGroupRow,
    TimesheetRow,
    TimesheetClientName,
    TimesheetCellProjectName,
    TimesheetProjectNameWrapper,
    StyledIcon,
    TimesheetClickableWrapper,
} from "pages/timesheet/timesheet-grid/StyledElements";
import ProjectDrawer from "pages/timesheet/timesheet-grid/ProjectDrawer";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import { TIME_OFF_ROW_ID } from "pages/timesheet/timesheet-grid/Grid";

import {
    TimesheetProjectRowExpansionContextActions,
    TimesheetProjectRowExpansionContextValue,
} from "context/Timesheet/TimesheetProjectRowExpansionContext";
import SubmitTimesheetContext from "context/Timesheet/SubmitTimesheetContext";
import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import DrawerContext from "context/Drawer/DrawerContext";
import TimesheetGridContext from "context/Timesheet/TimesheetGridContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import HStack from "components/design-system/layout/HStack";

const MemoizedProjectCell = React.memo(Cell);

const Row = ({ hoursData, data, projectHours, oooReasonsData }) => {
    const { toggleProjectRow, toggleProjectRowAfterSubmit } = useContext(
        TimesheetProjectRowExpansionContextActions
    );
    const { isProjectRowOpen } = useContext(
        TimesheetProjectRowExpansionContextValue
    );
    const {
        selectedDateTimesheetStatus,
        hoursUpdateInProgress
    } = useContext(SubmitTimesheetContext);
    const { selectedTimesheetStartDate } = useContext(WeekNavigatorContext);
    const { disableGridUpdate } = useContext(TimesheetGridContext);

    const [projectIdsOfTimesheetApproved, setProjectIdOfTimesheetApproved] =
        useState([]);
    const [projectIdsOfTimesheetRejected, setProjectIdOfTimesheetRejected] =
        useState([]);

    const { setShow } = useContext(DrawerContext);
    const sidebarProjectId = useRef();

    useEffect(() => {
        const hoursApproved = [];
        const hoursRejected = [];
        hoursData?.data?.forEach(x => {
            if (x?.teApproved) {
                if (!hoursApproved.includes(x.jobId)) {
                    hoursApproved.push(x.jobId);
                }
            }
            if (x?.teRejected) {
                if (!hoursRejected.includes(x.jobId)) {
                    hoursRejected.push(x.jobId);
                }
            }
        });
        setProjectIdOfTimesheetApproved(hoursApproved);
        setProjectIdOfTimesheetRejected(hoursRejected)
    }, [hoursData, selectedDateTimesheetStatus]);

    const expanded = isProjectRowOpen(data.clientData.id);

    // collapse row for all empty hours cell when timesheet submitted
    useEffect(() => {
        const holidayExist =
            data?.details?.filter((d) => d?.hasOwnProperty("HalfDay")).length >
            0;

        if (selectedDateTimesheetStatus && !holidayExist) {
            const projectIdList = data?.details?.map(
                (d) => d?.ProjectId || d?.LegacyJobId
            );

            let projectIdElements = [];

            for (const element of projectIdList) {
                let total = 0;
                hoursData?.data
                    ?.filter((d) => d.jobId === element)
                    ?.map((d) => (total = total + d.timeSpan));
                !total && projectIdElements.push(element);
            }

            if (projectIdElements.length === projectIdList.length) {
                toggleProjectRowAfterSubmit(data?.clientData.id);
            }
        }

        if (data?.isTimeOffRow) {
            const isTimeOffExists =
                hoursData?.data?.filter((f) =>
                    oooReasonsData?.data?.some(
                        (s) =>
                            f.timeSpan > 0 &&
                            (s.LegacyJobId === f.jobId ||
                                s.ReasonId === f.jobId)
                    )
                )?.length > 0;

            if (!holidayExist && !isTimeOffExists) {
                toggleProjectRowAfterSubmit(TIME_OFF_ROW_ID);
            }
        }
    }, [data, selectedDateTimesheetStatus]);

    const handleClick = (projectId) => {
        setShow(true);
        if (parseInt(projectId) > 1) {
            sidebarProjectId.current = projectId;
        }
    };

    useEffect(() => {
        sidebarProjectId.current = null;
    }, [sidebarProjectId.current]);

    return (
        <>
            {sidebarProjectId.current && (
                <MasterDrawer stickyAction={true}>
                    <ProjectDrawer projectId={sidebarProjectId.current} />
                </MasterDrawer>
            )}
            <TimesheetGroupRow spacing="Zero_5" align="flex-start">
                <QuaternaryButton
                    leadingIcon={expanded ? "caret-down" : "caret-right"}
                    {...{
                        "aria-label": expanded ? "Collapse" : "Expand",
                    }}
                    onClick={() => toggleProjectRow(data?.clientData.id)}
                />

                <VStack spacing="Zero" style={{ width: "100%" }}>
                    <TimesheetRow>
                        <TimesheetClientName
                            variant="Descriptive_1_1"
                            color="Black"
                        >
                            <HStack spacing="One">
                                {data.clientData.name}
                                {disableGridUpdate && data?.isTimeOffRow &&
                                    <CircularProgress
                                        style={{
                                            color: COLOR_VALUES.Gray_2,
                                            height: "12px",
                                            width: "12px",
                                        }}
                                    />
                                }
                            </HStack>
                        </TimesheetClientName>
                    </TimesheetRow>
                    {expanded && (
                        <VStack spacing="Zero">
                            {data?.details.map((project, projectIndex) => (
                                <TimesheetRow key={projectIndex} spacing="Zero"
                                    disabledTimeOffRow={(disableGridUpdate || hoursUpdateInProgress) && data?.isTimeOffRow}
                                >
                                    <TimesheetProjectNameWrapper
                                        spacing="Zero_25"
                                        align="center"
                                        justify="space-between"
                                    >
                                        <TimesheetClickableWrapper
                                            onClick={(_e) =>
                                                handleClick(project?.ProjectId)
                                            }
                                        >
                                            <Dotdotdot clamp={1}>
                                                <TimesheetCellProjectName>
                                                    {project?.hasOwnProperty("isHoliday") && project.Name}
                                                    {project?.ProjectName || project?.Label}
                                                </TimesheetCellProjectName>
                                            </Dotdotdot>
                                        </TimesheetClickableWrapper>

                                        {projectIdsOfTimesheetApproved.includes(project.ProjectId) &&
                                            <StyledIcon
                                                name="success"
                                                color="Green"
                                            />
                                        }
                                        {projectIdsOfTimesheetRejected.includes(project.ProjectId) &&
                                            <StyledIcon
                                                name="warning"
                                                color="Orange_1"
                                            />
                                        }
                                    </TimesheetProjectNameWrapper>

                                    {projectHours
                                        .find(x => {
                                            const id = project?.ProjectId || project?.LegacyJobId || project?.Id
                                            // if it's a time off we need to find the one that match the reason and the job
                                            if (x.isTimeOff && !x.isHoliday) {
                                                return x.projectId === id && project.ReasonId === x.ReasonId;
                                            }

                                            return x.projectId === id;
                                        })
                                        ?.hours?.map((cellData, cellIndex) => (
                                            <MemoizedProjectCell
                                                key={cellIndex}
                                                data={cellData}
                                                projectData={project}
                                                projectId={
                                                    cellData.hasOwnProperty("jobId")
                                                        ? cellData.jobId
                                                        : project?.ProjectId ||
                                                        project?.LegacyJobId ||
                                                        project?.Id
                                                }
                                                vacationRequestId={cellData.oooVacationRequestId}
                                                isTimeOff={
                                                    project?.LegacyJobId
                                                    || cellData?.oooVacationRequestId
                                                    || project?.VacationRequestId
                                                }
                                                readOnlyCell={!selectedDateTimesheetStatus && cellData.reandOnly}
                                                tooltipText={cellData.tooltipText}
                                                isHolidayData={
                                                    cellData?.hasOwnProperty("HalfDay")
                                                    || project?.hasOwnProperty("HalfDay")
                                                }
                                                reasonId={project.ReasonId}
                                                projectHours={projectHours}
                                            />
                                        ))}
                                </TimesheetRow>
                            ))}
                        </VStack>
                    )}
                </VStack>
            </TimesheetGroupRow>
        </>
    );
};

export default Row;
