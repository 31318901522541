import React from "react";
import Link from "components/UI/Link";

let renderCount = 1;

const SaveHoursErrorSnack = ({ projectName, onTryAgain }) => {
    return (
        <>
            <span>
                Changes to <b>{projectName}</b> have not been save.
            </span>
            <Link
                variant="secondary"
                color={global.config.colors.white}
                href={void 0}
                onClick={onTryAgain}
            >
                Try again
            </Link>
        </>
    );
};

SaveHoursErrorSnack.propTypes = {};

export default SaveHoursErrorSnack;
