import { useMemo } from "react";
import { useApi } from "../Helpers/useApi";

export const usePageAuthorization = () => {
    const { getDataAsync } = useApi();

    const getPageAuthorization = async (pageRoute) => {
        return getDataAsync("pageAuthorization", {
            params: { pageRoute: pageRoute },
        }).then((payload) => {
            return payload.json();
        });
    };

    return useMemo(() => {
        return { getPageAuthorization };
    }, []);
};
