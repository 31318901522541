import React, { useEffect, useState } from "react";
import { useApi } from "utilities/API/Helpers/useApi";

const SessionContext = React.createContext();

export const SessionProvider = (props) => {
    const [session, setSession] = useState(null);
    const { getData } = useApi();

    useEffect(() => {
        loadSession();
    }, []);

    const loadSession = () => {
        if (session === null) {
            getSession().then((payload) => {
                setSession(payload);
            });
        }
    };

    const getSession = () => {
        return getData("session").then((payload) => {
            return payload.json();
        });
    };

    return session !== null ? (
        <SessionContext.Provider value={session}>
            {props.children}
        </SessionContext.Provider>
    ) : (
        <div>Loading...</div>
    );
};

export default SessionContext;
