import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledBaseButton = styled.div`
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-align: inherit;
    text-decoration: none;

    &:disabled {
        cursor: not-allowed;
    }
`;

const BaseButton = React.forwardRef(
    (
        {
            to,
            href,
            target = "_blank",
            rel = "noopener noreferrer",
            isInternalUrlNewTab = false,
            tooltip,
            ...rest
        },
        ref
    ) => {
        const linkTarget = to || href;
        const isLink = typeof linkTarget !== "undefined";
        const isExternalUrl =
            (typeof linkTarget === "string" &&
                /^https?:\/\//.test(linkTarget)) ||
            isInternalUrlNewTab;

        const Element = isExternalUrl ? "a" : isLink ? Link : "button";
        const props = isExternalUrl
            ? { href: linkTarget, target, rel }
            : isLink
            ? { to: linkTarget }
            : {};

        return <StyledBaseButton as={Element} ref={ref} {...props} {...rest} title={tooltip} />;
    }
);

export default BaseButton;
