import React, { useState, useMemo } from "react";

const TimesheetGridContext = React.createContext();

export const TimesheetGridContextProvider = (props) => {
    const [clientAndProjectDetails, setClientAndProjectDetails] = useState([]);
    const [projectHours, setProjectHours] = useState([]);
    const [weekColumnDayHoursLimitExceed, setWeekColumnDayHoursLimitExceed] =
        useState(false);
    const [disableGridUpdate, setDisableGridUpdate] =
        useState(false);
    const [disableSubmitTimesheetButton, setDisableSubmitTimesheetButton] =
        useState(false);
    const [weekDetails, setWeekDetails] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [totalOfWeekHours, setTotalOfWeekHours] = useState([]);
    const [pendingHRTimeOffInWeek, setPendingHRTimeOffInWeek]
        = useState([]);
    const [approvedTimeOffInWeek, setApprovedTimeOffInWeek]
        = useState([]);
    const [usedAllotment, setUsedAllotment]
        = useState([]);

    const contextValue = useMemo(() => {
        return {
            clientAndProjectDetails,
            setClientAndProjectDetails,
            projectHours,
            setProjectHours,
            weekColumnDayHoursLimitExceed,
            setWeekColumnDayHoursLimitExceed,
            disableSubmitTimesheetButton,
            setDisableSubmitTimesheetButton,
            weekDetails,
            setWeekDetails,
            totalOfWeekHours,
            setTotalOfWeekHours,
            pendingHRTimeOffInWeek: pendingHRTimeOffInWeek,
            setPendingHRTimeOffInWeek: setPendingHRTimeOffInWeek,
            approvedTimeOffInWeek: approvedTimeOffInWeek,
            setApprovedTimeOffInWeek: setApprovedTimeOffInWeek,
            holidays: holidays,
            setHolidays: setHolidays,
            disableGridUpdate: disableGridUpdate,
            setDisableGridUpdate: setDisableGridUpdate,
            usedAllotment: usedAllotment,
            setUsedAllotment: setUsedAllotment
        };
    }, [
        clientAndProjectDetails,
        setClientAndProjectDetails,
        projectHours,
        setProjectHours,
        weekColumnDayHoursLimitExceed,
        setWeekColumnDayHoursLimitExceed,
        disableSubmitTimesheetButton,
        setDisableSubmitTimesheetButton,
        weekDetails,
        setWeekDetails,
        totalOfWeekHours,
        setTotalOfWeekHours,
        pendingHRTimeOffInWeek,
        setPendingHRTimeOffInWeek,
        approvedTimeOffInWeek,
        setApprovedTimeOffInWeek,
        holidays,
        setHolidays,
        disableGridUpdate,
        setDisableGridUpdate,
        usedAllotment,
        setUsedAllotment
    ]);

    return (
        <TimesheetGridContext.Provider value={contextValue}>
            {props.children}
        </TimesheetGridContext.Provider>
    );
};

export default TimesheetGridContext;
