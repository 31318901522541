import React, { useRef, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import More from "components/Icon/More";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
    root: {
        display: "none",
        "& .MuiIconButton-root": {
            padding: 0,
            margin: 0,
            "& svg": {
                fontSize: global.config.fonts.sizes.medium,
            },
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "0.125rem",
        },
    },
    notHover: {
        "& .MuiIconButton-root": {
            "&.MuiIconButton-root:hover": {
                backgroundColor: "inherit",
            },
        },
    },
    moreMenu: {
        "&.rc-menu": {
            border: global.config.border.medium,
            minWidth: "fit-content",
            padding: 0,
            zIndex: 1070,
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            "& li.rc-menu__submenu:hover": {
                backgroundColor: global.config.colors.gray8,
            },
            "& > li": {
                "& li.rc-menu__divider": {
                    padding: 0,
                },
                "&.noPadding": {
                    padding: 0,
                },
                "&.rc-menu__item--active": {
                    backgroundColor: "transparent",
                },
                "&.rc-menu__item--disabled": {
                    color: global.config.colors.gray5,
                    "& > a[disabled]": {
                        color: global.config.colors.gray5,
                        "& > svg path": {
                            stroke: global.config.colors.gray5,
                        },
                    },
                    "& > svg path": {
                        stroke: global.config.colors.gray5,
                    },
                },
                "& svg": {
                    marginRight: "0.625rem",
                },
            },
        },
        "& .rc-menu__submenu > .rc-menu__item::after": {
            display: "none",
        },
        "& .rc-menu__submenu:hover .sub_menu_arrow >svg": {
            display: "flex",
        },
        "& .rc-menu__item--hover": {
            backgroundColor: global.config.colors.gray8,
        },
        "& .rc-menu__item": {
            padding: "0.375rem 1rem",

            fontSize: global.config.fonts.sizes.smedium,
            fontFamily: global.config.fonts.families.regular,
            "& > a": {
                fontSize: global.config.fonts.sizes.smedium,
                fontFamily: global.config.fonts.families.regular,
            },
        },
    },
}));

const MoreButton = styled.div`
        display: ${(props) =>
            (props.alwaysVisible || props.open) && "flex!important"};
        -ms-flex-align: ${(props) =>
            (props.alwaysVisible || props.open) && "center"};
        -webkit-align-items:${(props) =>
            (props.alwaysVisible || props.open) && "center"};
        -webkit-box-align: ${(props) =>
            (props.alwaysVisible || props.open) && "center"};
        padding-left: 0px;}
    `;

export default function MoreMenu(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { onOpen, onClose, direction, portal } = props;
    const ref = useRef(null);

    const handleClick = (event) => {
        setOpen(true);
        onOpen && onOpen(event);
    };

    const handleClose = (event) => {
        setOpen(false);
        onClose && onClose(event);
    };

    const children = React.Children.map(props.children, (child) => {
        if (!props?.itemOwned && child.props.ownedOnly) {
            return;
        }

        if (child.props.hideItem) {
            return;
        }

        const { ownedOnly, hideItem, ...childProp } = child.props;
        const clonedChild = { ...child, props: childProp };
        const value = { id: props?.itemId, name: props?.itemName };

        const rv = React.cloneElement(clonedChild, {
            value: value,
            onClick: function (e) {
                childProp.onClick?.(e);
                props?.closeParent && props?.closeParent(e);
            },
        });

        console.log("RV", rv);

        return rv;
    });

    return (
        <MoreButton
            id="moreButton"
            className={[classes.root, props.notHover ? classes.notHover : ""]}
            open={open}
            alwaysVisible={props.alwaysVisible}
        >
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                ref={ref}
                type="button"
            >
                <More />
            </IconButton>
            <ControlledMenu
                anchorRef={ref}
                isOpen={open}
                onClose={handleClose}
                viewScroll="close"
                offsetX={5}
                offsetY={0}
                direction={direction}
                portal={portal}
                className={props.moreMenuClass ?? classes.moreMenu}
                style={{zIndex: 1000000}}
            >
                {children}
            </ControlledMenu>
        </MoreButton>
    );
}

MoreMenu.propTypes = {
    alwaysVisible: PropTypes.bool,
    portal: PropTypes.bool,
    direction: PropTypes.string,
    notHover: PropTypes.bool,
};

MoreMenu.defaultProps = {
    direction: "right",
    portal: true,
    notHover: false,
};
