import styled from "styled-components";
import { Row } from "react-bootstrap";

export const Container = styled.div`
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${global.config.sizes.sidebar.gutter};
    height: 90vh;
    padding-bottom: 2.5rem;
    ::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
    :hover {
        ::-webkit-scrollbar-thumb {
            background-color: #dde1e5;
        }
    }
`;
export const Title = styled.div`
    margin-top: 2.5rem;
    display: flex;
    width: max-content;
`;

export const FormField = styled.div`
    margin: 2.5rem 0;
`;

export const Wrapper = styled(Row)`
    margin-right: 0;
    margin-left: 0;
    flex-direction: column;
`;

export const WrapperEmployee = styled.div`
    padding: 0.02rem 0;
    display: flex;
    cursor: pointer;
    height: 5rem;
    width: ${global.config.sizes.grid.clickableCell};
    align-items: center;
    border-bottom: ${global.config.border.smallGray7};
    > svg {
        margin-left: auto;
        visibility: hidden;
    }
    :hover {
        > svg {
            visibility: visible;
        }
    }
`;

export const Header = styled.div`
    possition: sticky;
    padding-bottom: 1rem;
    border-bottom: ${global.config.border.smallGray7};
`;

export const Back = styled.div`
    display: inline-flex;
    cursor: pointer;
    padding: ${global.config.sizes.sidebar.gutter};
`;
