import { useCalendarGrid } from "@react-aria/calendar";
import { getWeeksInMonth, endOfMonth } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { CalendarCell } from "./CalendarCell";
import Text from "components/design-system/ui/Text";
import styled from "styled-components";

const TextWrapper = styled(Text)`
    text-align: center;
`;

export function CalendarGrid({
    state, offset = {},
    disableDays = [],
    enableDaysFromDate = null,
    enableDaysUntilDate = null
}) {
    let { locale } = useLocale();
    let startDate = state.visibleRange.start.add(offset);
    let endDate = endOfMonth(startDate);
    let { gridProps, headerProps, weekDays } = useCalendarGrid(
        {
            startDate,
            endDate,
        },
        state
    );

    // Get the number of weeks in the month so we can render the proper number of rows.
    let weeksInMonth = getWeeksInMonth(startDate, locale);

    return (
        <table {...gridProps} className="flex-1">
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th key={index}>
                            <TextWrapper
                                a
                                variant="Descriptive_1_1"
                                color="Gray_3"
                            >
                                {day}
                            </TextWrapper>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex, startDate)
                            .map((date, i) =>
                                date ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        date={date}
                                        currentMonth={startDate}
                                        disableDays={disableDays}
                                        enableDaysFromDate={enableDaysFromDate}
                                        enableDaysUntilDate={enableDaysUntilDate}
                                    />
                                ) : (
                                    <td key={i} />
                                )
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
