import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useApi } from "utilities/API/Helpers/useApi";

export const useProjectByIdProjectsDashboard = () => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getProjectByIdApiCall = useCallback(async () => {
        if (projectId !== undefined) {
            const response = await getDataAsync("getProjectById", {
                urlParams: [{ name: "$projectId", value: projectId }],
            });
            return response.status != 204 ? response.json() : null;
        } else return undefined;
    }, [getDataAsync]);

    const projectData = useQuery(
        "projectByIdData",
        () => getProjectByIdApiCall(),
        { refetchOnWindowFocus: false }
    );

    return useMemo(() => {
        return {
            projectData,
        };
    }, [projectData]);
};

const useProjectsDashboardOverviewBudget = () => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getBudgetSummaryApiCall = useCallback(
        async ({ endpointSuffix, startDate, endDate }) => {
            const response = await getDataAsync(
                "projectsDashboardBudgetSummary",
                {
                    urlParams: [
                        { name: "$endpointSuffix", value: endpointSuffix },
                        { name: "$projectId", value: projectId },
                        { name: "$startDate", value: startDate },
                        { name: "$endDate", value: endDate },
                    ],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useBudgetSummary = (dataObj) =>
            useQuery(
                [`budgetSummary${dataObj.endpointSuffix}`, dataObj],
                () => getBudgetSummaryApiCall(dataObj),
                { refetchOnWindowFocus: false }
            );

        return {
            useBudgetSummary,
        };
    }, [getBudgetSummaryApiCall]);
};

export default useProjectsDashboardOverviewBudget;

export const useProjectTimesheetSummaryData = () => {
    const { getDataAsync } = useApi();

    const { projectId } = useParams();

    const getProjectTimesheetSummaryApiCall = useCallback(async () => {
        if (projectId !== undefined) {
            const response = await getDataAsync(
                "getProjectTimesheetSummaryData",
                {
                    urlParams: [{ name: "$projectId", value: projectId }],
                }
            );
            return response.status != 204 ? response.json() : null;
        } else return undefined;
    }, [getDataAsync]);

    const projectTimesheetSummaryData = useQuery(
        "projectSummaryData",
        () => getProjectTimesheetSummaryApiCall(),
        { refetchOnWindowFocus: false }
    );

    return useMemo(() => {
        return {
            projectTimesheetSummaryData,
        };
    }, [projectTimesheetSummaryData]);
};
