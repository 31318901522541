import { useMemo, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";
import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

const useAbsenseReasons = () => {
    const { getDataAsync } = useApi();
    const queryClient = useQueryClient();

    const getAbsenseReasonsApiCall = useCallback(async ({
        pageSize = PAGER_INITIAL_PAGE_SIZE,
        sortBy = "",
        pageIndex = 0,
        active = "",
    }) => {
        const response = await getDataAsync("getAbsenseReasons", {
            urlParams: [
                { name: "$sortBy", value: sortBy },
                { name: "$pageSize", value: pageSize },
                { name: "$pageNumber", value: pageIndex + 1 },
                { name: "$active", value: active },
            ],
        });
        return response.status != 204 ? response.json() : null;
        },
    [getDataAsync]
    );
    

//    const absenseReasons = useQuery("absenseReasonsList", getAbsenseReasonsApiCall);
const mutationOptions = useMemo(() => {
    return {
        onSuccess: () => {
            queryClient.invalidateQueries
            ("absenseReasonsList");
        },
    };
}, [queryClient]);

return useMemo(() => {
    const useAbsenseReasonsList = (dataObj) =>
        useQuery(
            ["absenseReasonsList", dataObj],
            () => getAbsenseReasonsApiCall(dataObj),
            { keepPreviousData: true, staleTime: Infinity }
        );
    return {
        useAbsenseReasonsList
    };
}, [getAbsenseReasonsApiCall]);

}

export default useAbsenseReasons;
