import React, { useContext, useEffect, useState } from "react";
import {
    useTable,
    useSortBy,
    useFilters,
    usePagination,
    useExpanded,
} from "react-table";
import styled from "styled-components";
import { useUID } from "react-uid";

import { usePrevious } from "hooks/usePrevious";
import { COLOR_VALUES } from "components/design-system/config/colors";
import BodyCell from "components/design-system/tables/BodyCell";
import HeadCell from "components/design-system/tables/HeadCell";
import EmptyState from "components/design-system/tables/EmptyState";
import Pager, {
    PAGER_INITIAL_PAGE_SIZE,
} from "components/design-system/tables/Pager";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";

const StyledTable = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    border: ${(props) =>
        props.showTableBorder ? `1px solid ${COLOR_VALUES.Gray_6}` : `none`};
`;

const StyledLoaderTr = styled.tr`
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 50%;
    background-color: ${COLOR_VALUES.Gray_4};
    animation: loading 3s linear infinite;

    @keyframes loading {
        to {
            transform: translateX(93%);
        }
    }
`;

const Table = ({
    dataIsFetching = false,
    columns,
    fixedHeadingRows = [],
    data,
    getRowProps = () => void 0,
    grayBgHeader = false,
    filters = [],
    emptyMessage,
    emptyMessageTableTitle,
    emptyActionProps,
    showTableBorder = true,
    paginationFromFE = true,
    paginationAfterTotalRowCount = 10,
    pagerDisabledOptionsList = [],
    sortBy = [],
    pageSizeInitially = PAGER_INITIAL_PAGE_SIZE,
    displayPager = true,
    expandedAllRows = false,
    noContentInHeaderRow = false,
}) => {
    const uniqId = useUID();

    const {
        querySortBy,
        queryPageIndex,
        queryPageSize,
        totalItems,
        queryPageSizeUpdate,
        queryPageIndexUpdate,
    } = useContext(ServerSidePaginationContext);

    const [values, setValues] = useState({
        totalCount: paginationFromFE ? data.length : totalItems,
        displayPagination: paginationFromFE
            ? data?.length > paginationAfterTotalRowCount
            : totalItems > paginationAfterTotalRowCount,
    });

    useEffect(() => {
        if (!paginationFromFE) {
            setValues({
                ...values,
                displayPagination: totalItems > paginationAfterTotalRowCount,
            });
        }
    }, [totalItems]);

    const tableInstance = useTable(
        {
            columns,
            data,
            hiddenColumns: columns
                .filter((column) => !column?.show)
                .map((column) => column.id),
            initialState: {
                filters: filters,
                pageIndex: paginationFromFE ? 0 : queryPageIndex,
                pageSize: paginationFromFE ? pageSizeInitially : queryPageSize,
                sortBy: sortBy,
                expanded: expandedAllRows,
            },
            pageCount: Math.ceil(totalItems / queryPageSize),
            manualPagination: !paginationFromFE,
            manualSortBy: !paginationFromFE,
            autoResetPage: true,
            autoResetSortBy: false,
            autoResetFilters: false,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        setHiddenColumns,
        setAllFilters,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        toggleAllRowsExpanded,
        isAllRowsExpanded,    
        state: { pageIndex, pageSize, expanded  },
    } = tableInstance;
    const tableRows = values.displayPagination ? page : rows;

    useEffect(() => {
        setHiddenColumns(
            columns
                .filter((column) =>
                    column.hasOwnProperty("show") ? !column?.show : null
                )
                .map((column) => column?.accessor)
        );
    }, [columns]);

    useEffect(() => {      
        if (expanded) toggleAllRowsExpanded(true);
    }, [isAllRowsExpanded]);    

    // for pagination from frontend
    const fetchTableInstanceDataFEPagination = () => {
        setValues({
            ...values,
            totalCount: rows?.length,
            displayPagination: rows?.length > paginationAfterTotalRowCount,
            expandedAllRows
        });
        !expandedAllRows && toggleAllRowsExpanded(false);
    };

    useEffect(() => {
        paginationFromFE && fetchTableInstanceDataFEPagination();
    }, [rows.length, page.length, pageIndex, pageSize, paginationFromFE]);

    // for pagination from backend
    useEffect(() => {
        if (!paginationFromFE) {
            queryPageIndexUpdate(pageIndex);
        }
    }, [pageIndex]);

    useEffect(() => {
        if (!paginationFromFE) {
            queryPageSizeUpdate(pageSize);
            queryPageIndexUpdate(0);
            gotoPage(0);
        }
    }, [pageSize, gotoPage]);

    const prevFilters = usePrevious(filters);

    useEffect(() => {
        JSON.stringify(filters) !== JSON.stringify(prevFilters) &&
            setAllFilters(filters);
    }, [filters, prevFilters, setAllFilters]);

    const getCurrentSort = (isSortedDesc) => {
        if (isSortedDesc === true) {
            return "desc";
        } else if (isSortedDesc === false) {
            return "asc";
        } else {
            return "none";
        }
    };

    const getBESortVal = (isSortedDesc, entityName) => {
        if (isSortedDesc === true) {
            return `${entityName} DESC`;
        } else if (isSortedDesc === false) {
            return `${entityName} ASC`;
        } else {
            return "";
        }
    };

    if (!paginationFromFE && !tableRows.length && data?.length > 0) {
        return <>Loading...</>;
    }

    if (!tableRows.length) {
        return (
            <EmptyState
                message={emptyMessage}
                title={emptyMessageTableTitle}
                actionProps={emptyActionProps}
            />
        );
    } else {
        return (
            <>
                <StyledTable
                    showTableBorder={showTableBorder}
                    {...getTableProps()}
                >
                    <thead style={{ position: "relative" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    const currentSort = getCurrentSort(
                                        column.isSortedDesc
                                    );
                                    const sortValParamBE = !paginationFromFE
                                        ? getBESortVal(
                                            column.isSortedDesc,
                                            column.id
                                        )
                                        : undefined;
                                    return (
                                        <HeadCell
                                            {...column.headCellProps}
                                            sortable={column.canSort}
                                            currentSort={currentSort}
                                            sortByBEVal={sortValParamBE}
                                            loadingIcon={
                                                sortValParamBE
                                                    ? dataIsFetching &&
                                                    querySortBy ==
                                                    sortValParamBE
                                                    : false
                                            }
                                            noContentInHeaderRow={noContentInHeaderRow}
                                            grayBgHeader={grayBgHeader}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                        {dataIsFetching && <StyledLoaderTr />}

                        {/* external fixed row data of respective columns it's not a part of pager/useTable */}
                        {fixedHeadingRows.map((rowData, rowDataIndex) => (
                            <tr key={`rowIndex${rowDataIndex + uniqId}`}>
                                {rowData.data.map((cellData, cellDataIndex) => (
                                    <HeadCell                                    
                                        key={`cellIndex${cellDataIndex + uniqId
                                            }`}
                                        borderBottom={rowData.borderBottom}
                                        text={cellData.text}
                                        alignedRight={cellData.alignedRight}
                                        bgColor={
                                            rowData.bgColor ??
                                            COLOR_VALUES.Gray_7
                                        }
                                        bgTextColor={cellData.textColor ?? COLOR_VALUES.Black}
                                        variant={rowData.variant}
                                    />
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {tableRows.map((row) => {
                            prepareRow(row);

                            return (
                                <tr {...row.getRowProps(getRowProps(row))}>
                                    {row.cells.map((cell) => (
                                        <BodyCell
                                            {...cell.getCellProps({
                                                ...cell.column.getBodyCellProps(
                                                    cell
                                                ),
                                                compact: tableRows.length > 24,
                                                expanded: cell.row.isExpanded,                                                
                                            })}
                                        />
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </StyledTable>

                {displayPager && values.displayPagination && (
                    <Pager
                        totalEntries={
                            paginationFromFE ? values.totalCount : totalItems
                        }
                        pagerDisabledOptionsList={pagerDisabledOptionsList}
                        {...{
                            canPreviousPage,
                            canNextPage,
                            pageOptions,
                            gotoPage,
                            nextPage,
                            previousPage,
                            setPageSize,
                            state: { pageIndex, pageSize },
                        }}
                    />
                )}
            </>
        );
    }
};

export default Table;
