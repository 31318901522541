import React from "react";
import { useCalendarState } from "@react-stately/calendar";
import moment from "moment";
import {
    useCalendar,
    useCalendarCell,
    useCalendarGrid,
} from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import {
    getWeeksInMonth,
    createCalendar,
    isToday,
} from "@internationalized/date";
import styled, { css } from "styled-components/macro";

import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";
import Tooltip from "components/design-system/ui/Tooltip";

export const CalendarTitleBlockPadding = "19px 16px";

const StyledWrapper = styled.div`
    border-radius: 4px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    padding: 12px;
    width: 212px;
`;

const CardTitle = styled(Text)`
    padding-bottom: 8px;
    letter-spacing: 0.14px;
`;

const DateButton = styled.button`
    ${TEXT_VARIANT_STYLES.Descriptive_1_1};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: ${COLOR_VALUES.Black};
    border: none;
    background: transparent;
    padding: 0;
    text-align: center;
    cursor: default !important;

    &:focus-visible {
        outline: none;
    }

    ${(props) =>
        props.isHoliday &&
        css`
            cursor: pointer !important;
            border: 2px solid ${COLOR_VALUES.Black};
            background: ${COLOR_VALUES.White};
        `};

    ${(props) =>
        props.isDisabled &&
        css`
            border: none;
            color: ${COLOR_VALUES.Gray_4};
        `};

    ${(props) =>
        props.isCurrent &&
        css`
            background: ${COLOR_VALUES.Gray_7};
        `};
`;

function CalendarCell({ state, holidaysList, date }) {
    const ref = React.useRef();
    const { cellProps, isOutsideVisibleRange, formattedDate } = useCalendarCell(
        { date },
        state,
        ref
    );

    const currentDate = moment(`${date.year}-${date.month}-${date.day}`).format("YYYY-MM-DD");

    const holidayObj = !isOutsideVisibleRange
        && holidaysList.find(x => {
            const holiday = moment(x.Date).format("YYYY-MM-DD");
            return moment(holiday).isSame(currentDate);
        });

    const tooltipText = holidayObj?.Name ?? "";

    return (
        <td {...cellProps}>
            <Tooltip enterDelay={0} text={tooltipText}>
                <div>
                    <DateButton
                        ref={ref}
                        isHoliday={holidayObj}
                        isCurrent={isToday(date)}
                        isDisabled={isOutsideVisibleRange}
                    >
                        {formattedDate}
                    </DateButton>
                </div>
            </Tooltip>
        </td>
    );
}

function CalendarGrid({ state, holidaysList, ...props }) {
    const { locale } = useLocale();
    const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    return (
        <table
            {...gridProps}
            style={{ padding: "0 7px 0 16px", margin: "0 auto" }}
        >
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th
                            key={index}
                            style={{
                                width: "33px",
                                paddingBottom: "7px",
                                paddingRight: "9px",
                                textAlign: "center",
                            }}
                        >
                            <Text
                                variant="Descriptive_1_1"
                                color={COLORS.Gray_3}
                            >
                                {day}
                            </Text>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex)
                            .map((date, i) =>
                                date ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        holidaysList={holidaysList}
                                        date={date}
                                    />
                                ) : (
                                    <td key={i} />
                                )
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default function DatePickerCalendarCard({
    holidaysList = [],
    ...props
}) {
    const { locale } = useLocale();
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });
    const ref = React.useRef();
    const { calendarProps, title } = useCalendar(props, state, ref);

    return (
        <StyledWrapper {...calendarProps} ref={ref}>
            <CardTitle variant={"Headline_5"} color={COLORS.Gray_1}>
                {title?.split(" ", 1)[0]}
            </CardTitle>

            <CalendarGrid state={state} holidaysList={holidaysList} />
        </StyledWrapper>
    );
}
