import React, { useContext, useEffect, useState } from "react";
import { CalendarDate } from "@internationalized/date";
import styled from "styled-components/macro";
import { format, getMonth } from "date-fns";

import Loading from "components/UI/Loading";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import HStack from "components/design-system/layout/HStack";
import TableFilters from "components/design-system/tables/TableFilters";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import { Calendar } from "components/design-system/controls/holidays-calendar/Calendar";
import { getDropdownOptionsNoDefaultVal } from "common-methods/getDropdownOptionsNoDefaultVal";
import EmptyState from "components/design-system/tables/EmptyState";

import SessionContext from "context/app/SessionContext";

import useTSHolidays, {
    useOfficesHolidaysTab,
} from "pages/timesheet/holidays/useTSHolidays";

const CalendarWrapper = styled(HStack)`
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 8px;
    width: 102%;
`;

const columns = [
    {
        headCellProps: {
            text: "Date",
            width: getCellWidthInPercent(240),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMMM d, yyyy"),
        }),
        accessor: "Date",
    },
    {
        headCellProps: {
            text: "Day",
            width: getCellWidthInPercent(160),
        },
        getBodyCellProps: (cell) => ({
            text: cell.row.original.Date
                ? format(new Date(cell.row.original.Date), "EEEE")
                : "-",
        }),
        disableSortBy: true,
        accessor: "Day",
    },
    {
        headCellProps: {
            text: "Length",
            width: getCellWidthInPercent(160),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ? "Half Day" : "All Day",
        }),
        disableSortBy: true,
        accessor: "HalfDay",
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(752),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        disableSortBy: true,
        accessor: "Name",
    },
];

const viewHolidaysAs = {
    list: "list",
    calendar: "calendar",
};

const DropdownNames = {
    viewAs: "ViewAs",
    year: "Year",
    location: "Location",
};

const getYearsDropdownOptions = () => {
    const currentYear = new Date().getFullYear();
    const yearsList = Array.from({ length: 6 }, (_d, i) => currentYear - i);
    let yearDropdownOptions = [];
    yearsList.forEach((d) => yearDropdownOptions.push({ value: d, label: d }));

    return yearDropdownOptions;
};

const FiltersConfig = [
    {
        filterLabel: "View",
        name: DropdownNames.viewAs,
        columnAccessor: DropdownNames.viewAs,
        unfilteredValue: viewHolidaysAs.list,
        isSegmentedControl: true,
        options: [
            {
                value: viewHolidaysAs.list,
                label: "List",
            },
            {
                value: viewHolidaysAs.calendar,
                label: "Calendar",
            },
        ],
    },
    {
        name: DropdownNames.year,
        columnAccessor: DropdownNames.year,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.year,
        options: getYearsDropdownOptions(),
    },
    {
        name: DropdownNames.location,
        columnAccessor: DropdownNames.location,
        unfilteredValue: "all",
        isDropdownControl: true,
        formInputWrapperClassName: "max-width-160",
        dropdownFilterLabel: DropdownNames.location,
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const monthsArrNumeric = Array.from({ length: 12 }, (_d, i) => 1 + i);

export const TSHolidays = () => {
    const [filters, setFilters] = useState();
    const [filtersConfigVal, setFiltersConfigVal] = useState(FiltersConfig);
    const filterVal =
        filters?.find((f) => f.id === DropdownNames.viewAs)?.value ??
        viewHolidaysAs.list;
    const sessionData = useContext(SessionContext);

    const { activeOffices } = useOfficesHolidaysTab();
    const { useHolidaysTab } = useTSHolidays();

    const defaultLocation = sessionData.currentOfficeId;
    const defaultYear = new Date().getFullYear();

    const holidays = useHolidaysTab({
        officeId:
            filters?.find((f) => f.id === DropdownNames.location)?.value ??
            defaultLocation,
        year:
            filters?.find((f) => f.id === DropdownNames.year)?.value ??
            defaultYear,
    });

    useEffect(() => {
        let newObj = [...filtersConfigVal];
        const activeOfficesIndex = newObj.findIndex(
            (f) => f.name === DropdownNames.location
        );
        newObj[activeOfficesIndex].options = !activeOffices.isLoading
            ? getDropdownOptionsNoDefaultVal(
                  activeOffices?.data,
                  "OfficeRegionId",
                  "Name"
              )
            : [];

        !activeOffices.isLoading &&
            newObj[activeOfficesIndex].options.length <= 0 &&
            setFiltersConfigVal(newObj);
    }, [activeOffices]);

    if (activeOffices.isLoading) {
        return <Loading />;
    }

    if (activeOffices.isError || holidays.isError) {
        return <>Error</>;
    }

    const calendarYear =
        !holidays.isLoading && holidays?.data[0]?.Date
            ? new Date(holidays?.data[0].Date).getFullYear()
            : defaultYear;

    const renderTabContent = () => {
        return (
            <>
                {filterVal === viewHolidaysAs.list ? (
                    <Table
                        grayBgHeader={true}
                        columns={columns}
                        getRowProps={getRowProps}
                        data={holidays?.data}
                        emptyMessageTableTitle={"Holidays"}
                    />
                ) : (
                    <CalendarWrapper>
                        {holidays?.data?.length > 0 ? (
                            monthsArrNumeric.map((d) => (
                                <Calendar
                                    key={d}
                                    {...{ "aria-label": "holiday" }}
                                    value={new CalendarDate(calendarYear, d, 1)}
                                    holidaysList={holidays?.data?.filter(
                                        (f) =>
                                            getMonth(new Date(f.Date)) + 1 === d
                                    )}
                                />
                            ))
                        ) : (
                            <EmptyState
                                title={"Holidays"}
                                style={{ width: "98%" }}
                            />
                        )}
                    </CalendarWrapper>
                )}
            </>
        );
    };

    return (
        <>
            <VStack>
                <HStack>
                    <TableFilters
                        initialFilterData={[
                            {
                                name: DropdownNames.location,
                                value: defaultLocation,
                            },
                            { name: DropdownNames.year, value: defaultYear },
                        ]}
                        filtersConfig={filtersConfigVal}
                        onChange={setFilters}
                    />
                </HStack>
                {holidays.isLoading ? <Loading /> : renderTabContent()}
            </VStack>
        </>
    );
};
