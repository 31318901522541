import { useParams } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useAccessGroupPageAccess = () => {
    const { getDataAsync, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { groupId } = useParams();

    const getAccessGroupApiCall = useCallback(
        async () => {
            const response = await getDataAsync("groupPages", {
                urlParams: [{ name: "$groupId", value: groupId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createAccessGroupApiCall = useCallback(
        ({ pageId }) => {
            return postData("groupPageAccesses", {
                urlParams: [
                    { name: "$groupId", value: groupId },
                    { name: "$pageId", value: pageId },
                ],
            });
        },
        [postData]
    );

    const deleteAccessGroupApiCall = useCallback(
        ({ pageId }) => {
            return deleteData("groupPageAccesses", {
                urlParams: [
                    { name: "$groupId", value: groupId },
                    { name: "$pageId", value: pageId },
                ],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries(["accessGroupsPages", groupId]);
            },
        };
    }, [queryClient]);

    const createAccessGroupPage = useMutation(
        createAccessGroupApiCall,
        mutationOptions
    );

    const deleteAccessGroupPage = useMutation(
        deleteAccessGroupApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useAccessGroupPageAccess = () =>
            useQuery(["accessGroupsPages", groupId], () =>
                getAccessGroupApiCall(groupId)
            );

        return {
            useAccessGroupPageAccess,
            createAccessGroupPage,
            deleteAccessGroupPage,
        };
    }, [getAccessGroupApiCall, createAccessGroupPage, deleteAccessGroupPage]);
};

export default useAccessGroupPageAccess;
