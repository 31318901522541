import { Navigate } from "react-router-dom";
import {  useContext } from "react";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const Home = () => {
    const { insightsTrace } = useInsigths();
    const session = useContext(SessionContext);

    const now = new Date();
    insightsTrace("VIEW", "HomePage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    let defaultPage = session?.defaultPage; 

    let redirectTo = "/timesheets";

    if (defaultPage && defaultPage.length > 0){
        redirectTo = defaultPage; 
    }

    return (
        <Navigate to={redirectTo} />
    );
};

export default Home;
