import { useContext } from "react";
import styled from "styled-components";
import NavBar from "components/UI/NavBar";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const Footer = styled.footer`
    text-align: center;
    margin: 0.5rem;
`;

export const PageLayout = (props) => {    
    const { insightsTrace } = useInsigths();
    const session = useContext(SessionContext);
    const now = new Date();
    if (session){
        insightsTrace("SESSION", "Started", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });
    } else{
        insightsTrace("Session null");
    }
    return (
        <div id="pageLayout">
            <NavBar />
            {props.children}
            <Footer />
            
            <div id="divPageSidebar"></div>
            <div
                id="divPageModal"
                style={{ position: "fixed", zIndex: 3000 }}
            ></div>
            <div id="divTooltip"></div>
        </div>
    );
};
