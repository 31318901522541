import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import Icon from "components/design-system/ui/Icon";

export const TimesheetCellHeadDayText = styled(Text)`
    width: max-content;

    ${(props) =>
        props.current &&
        css`
            border-bottom: 1px solid ${COLOR_VALUES.Black};
        `}
`;

export const TimesheetGroupRow = styled(HStack)`
    padding-top: 12px;
`;

export const TimesheetRow = styled(HStack)`
    gap: 0;
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    ${(props) => props.disabledTimeOffRow &&
         css `
            pointer-events: none;
          `}
`;

export const TimesheetRowHead = styled(HStack)`
    gap: 0;
    border-bottom: 2px solid ${COLOR_VALUES.Gray_2};
`;

export const TimesheetClientName = styled(Text)`
    padding-bottom: 12px;
`;

export const TimesheetCell = styled.div`
    width: 10.58%;
    border-left: 1px solid ${COLOR_VALUES.Gray_6};
    padding: 16px 12px;
`;

export const TimesheetCellHead = styled(TimesheetCell)`
    padding: 10px 12px;
    position: relative;
`;

export const TimesheetProjectNameWrapper = styled(HStack)`
    width: 27.84%;
    padding: 16px 40px 16px 0;
`;

export const TimesheetCellProjectName = styled.div`
    ${TEXT_VARIANT_STYLES.Body_2_1}
    color: ${COLOR_VALUES.Gray_1};
`;

export const StyledIcon = styled(Icon)`
    width: 24px;
    height: 24px;
`;

export const TimesheetCellDropdown = styled.div`
    width: calc(0.5rem + 20px + 27.84%);
    padding: 10px 12px;
`;

export const TimesheetClickableWrapper = styled.a`
    padding: 0;
    cursor: pointer;
`;
