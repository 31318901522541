import React, { useState } from "react";

import HStack from "components/design-system/layout/HStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

import useClientLOBs from "pages/admin/clients/single/useClientLOBs";

const Table_Width = 416;

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(72, Table_Width),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows?.filter((row) => {
                return (
                    (filterValue === "active" && row.values.Active === true) ||
                    (filterValue === "inactive" && row.values.Active === false)
                );
            });
        },
        accessor: "clientGroupID",
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(312, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "GroupName",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(100),
        },
        accessor: "Active",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=editLineOfBusiness&id=${cell.row.original.clientGroupID}`,
                replace: true,
                "aria-label": "Edit",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "clientGroupID",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add",
    to: "?action=addLineOfBusiness",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const ClientGroupsLOBTable = () => {
    const [filters, setFilters] = useState();

    const { clientLOBs } = useClientLOBs();

    if (clientLOBs.isLoading) {
        return <>Loading...</>;
    }

    if (clientLOBs.isError) {
        return <>Error</>;
    }

    return (
        <>
            <HStack align="end" justify="space-between">
                <TableFilters
                    filtersConfig={FiltersConfig}
                    onChange={setFilters}
                />
                <TertiaryButton {...tableCTAProps} />
            </HStack>
            <Table
                columns={columns}
                data={clientLOBs.data}
                filters={filters}
                emptyActionProps={tableCTAProps}
                emptyMessageTableTitle={"Lines of Business"}
                getRowProps={getRowProps}
            />
        </>
    );
};

export default React.memo(ClientGroupsLOBTable);
