import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Text from "components/design-system/ui/Text";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import { NavLink } from "react-router-dom";

const Wrapper = styled.nav`
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
`;

const LinkTabsUL = styled.ul`
    display: flex;
    margin: 0 0 -1px;
    padding: 0;
`;

const LinkTabsLI = styled.li`
    list-style: none;
`;

const TabList = styled(NavLink)`
    display: inline-flex;
    text-decoration: none;
    padding: 16px;
    gap: 4px;
    align-items: center;
    background: ${COLOR_VALUES.White};
    color: ${COLOR_VALUES.Gray_4} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    cursor: pointer;

    &:hover {
        background: ${COLOR_VALUES.Gray_8};
        border-bottom: 2px solid ${COLOR_VALUES.Gray_6};
    }

    &.active {
        color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
        border-bottom: 2px solid ${COLOR_VALUES.Black};
    }
`;

const LinkTab = ({ label, leadingIcon, trailingIcon, iconColor, to }) => {
    return (
        <LinkTabsLI>
            {label && (
                <TabList to={to} exact="true">
                    {leadingIcon && <Icon name={leadingIcon} color={iconColor} />}
                    <Text variant="Headline_5">{label}</Text>
                    {trailingIcon && <Icon name={trailingIcon} color={iconColor} />}
                </TabList>
            )}
        </LinkTabsLI>
    );
};

const LinkTabs = ({ tabs, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <LinkTabsUL>
                {tabs.map((data, index) => (
                    <LinkTab {...data} key={index} />
                ))}
            </LinkTabsUL>
        </Wrapper>
    );
};

LinkTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
            leadingIcon: PropTypes.oneOf(ICON_NAMES),
            trailingIcon: PropTypes.oneOf(ICON_NAMES),
        })
    ),
};

export default LinkTabs;
