import React from "react";
import ReactDOM from "react-dom";
import ApiError from "components/Modal/ErrorModal";
import GenericModal from "components/Modal/Common/GenericModal";
import Button from "components/UI/Button";

const ErrorTestPage = () => {
    const showModal = () => {
        var detail = {
            title: "Test",
            message: "Test",
            actionMessage: "Test",
        };
        const modalContent = <ApiError error="Error test" popup />;
        const modal = (
            <GenericModal
                content={modalContent}
                detail={detail}
                parent="divAppErrorModal"
            />
        );
        ReactDOM.render(modal, document.getElementById("divAppErrorModal"));
    };

    return (
        <>
            <Button
                style={{
                    width: "11rem",
                    height: "2.8rem",
                    alignSelf: "center",
                }}
                onClick={() => showModal()}
            >
                Test Modal
            </Button>
        </>
    );
};

export default ErrorTestPage;
