import { format } from "date-fns";

import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import DashboardDataItem from "components/design-system/ui/dashboard/DashboardDataItem";

import useRetainers from "pages/admin/retainers/useRetainers";
import { useClientsApi } from "pages/admin/clients/useClients";
import { useRateCard } from "pages/projects/retainers-dashboard/single/useRetainersDashboardUserDetails";
import {
    useClientCampaign,
    useClientOffice,
} from "pages/projects/project-dashboard/single/useProjectsDashboardDetailsData";
import { useProjectDashboardCurrency } from "pages/projects/project-dashboard/single/useProjectsDashboardData";

const ProjectsDashboardDetailsData = ({ project }) => {
    const projectInfo = project?.data;

    const { currency } = useProjectDashboardCurrency();
    const { useRetainerById } = useRetainers();
    const retainerData = useRetainerById(projectInfo?.RetainerId);
    const { useCampaignById } = useClientCampaign();
    const campaginData = useCampaignById(projectInfo?.ClientCampaignId);
    const { useClientById } = useClientsApi();
    const clientData = useClientById(projectInfo?.ClientId);
    const { useRateCardById } = useRateCard();
    const rateCardData = useRateCardById(projectInfo?.RateCardTableId);

    const { useGetClientOfficeById } = useClientOffice();
    const officeData = useGetClientOfficeById({
        officeId: clientData?.data?.OfficeRegionId,
    });

    const contactType = projectInfo?.IsNonBillable
        ? "Non-billable"
        : "Billable";

    const getPIStatus = () => {
        if (projectInfo?.CapturingData === 0) return "No";
        else if (projectInfo?.CapturingData === 1) return "Yes";
        else if (projectInfo?.CapturingData === 2) return "Unsure";
    };

    let dataObj = [
        {
            borderBottom: false,
            data: [
                {
                    title: "Client",
                    placeholder: "-",
                    res: clientData?.data?.Name,
                },
                {
                    title: "SOW Status",
                    placeholder: "-",
                    res: projectInfo?.SOWStatus,
                },
                {
                    title: "Campaign",
                    placeholder: "-",
                    res: campaginData?.data?.CampaignName,
                },
                {
                    title: "Alt Job Number",
                    placeholder: "-",
                    res: projectInfo?.AltJobNumber,
                },
                {
                    title: "Start Date",
                    placeholder: "-",
                    res:
                        projectInfo?.ProjStartDate &&
                        format(
                            new Date(projectInfo?.ProjStartDate),
                            "MMM dd, yyyy"
                        ),
                },
            ],
        },
        {
            borderBottom: false,
            data: [
                {
                    title: "Lead Business Unit",
                    placeholder: "-",
                    res: officeData?.data?.Name,
                },
                {
                    title: "Currency",
                    placeholder: "-",
                    res: currency?.data?.Name,
                },
                {
                    title: "Line of Business",
                    placeholder: "-",
                    res: projectInfo?.LOB,
                },
                {
                    title: "Linked DS / Interactive",
                    placeholder: "-",
                    res: projectInfo?.LinkedDS_Interactive,
                },
                {
                    title: "End Date",
                    placeholder: "-",
                    res:
                        projectInfo?.CurrentEndDate &&
                        format(
                            new Date(projectInfo?.CurrentEndDate),
                            "MMM dd, yyyy"
                        ),
                    description: projectInfo?.EndDateChangeBy,
                    isNextLine: true,
                },
            ],
        },
        {
            borderBottom: false,
            data: [
                {
                    title: "Contract Type",
                    placeholder: "-",
                    res: contactType,
                },
                {
                    title: "Rate Card",
                    placeholder: "-",
                    res: rateCardData?.data?.RateCardTableName,
                },
                {
                    title: "Retainer",
                    placeholder: "-",
                    res: retainerData?.data?.RetainerName,
                },
                {
                    title: "Personal Information Status",
                    placeholder: "-",
                    res: getPIStatus(),
                    icon: "edit",
                    actionProps: {
                        to: `?action=editPIS`,
                        replace: true,
                        "aria-label": "Edit PIS",
                    },
                },
            ],
        },
    ];

    if (
        project.isLoading ||
        officeData.isLoading ||
        currency.isLoading ||
        clientData.isLoading ||
        rateCardData.isLoading ||
        retainerData.isLoading
    ) {
        return <>Loading...</>;
    }

    return (
        <>
            <VStack spacing="Two">
                {dataObj.map((row, rowIndex) => (
                    <HStack
                        key={`retainers-data-layout-${rowIndex + 1}`}
                        borderBottom={row.borderBottom}
                        spacing="Two"
                    >
                        {row.data.map((item, itemIndex) => {
                            return (
                                <DashboardDataItem
                                    key={`retainers-data-item-${itemIndex + 1}`}
                                    title={item.title}
                                    placeholder={item.placeholder}
                                    info={item.res}
                                    isLoading={project?.isFetching}
                                    description={item.description}
                                    isNextLine={item.isNextLine}
                                    actionProps={item.actionProps}
                                    infoActionIcon={item.icon}
                                />
                            );
                        })}
                    </HStack>
                ))}
            </VStack>
        </>
    );
};

export default ProjectsDashboardDetailsData;
