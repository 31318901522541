import React, { useState, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import MyRequestTimeOffTable from "pages/timesheet/time-off/MyRequestTimeOffTable";
import Modal from "components/design-system/ui/modal/MasterModal";
import TimeOffRequestForm from "./TimeOffRequestForm";
import DeleteTimeOffRequestConfirmation from "./DeleteTimeOffRequestConfirmation";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const MyRequestsTimeOff = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const title = "Requests";
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const action = searchParams.get("action");
    const id = searchParams.get("id");
    const [refresh, setRefresh] = useState(0);

    const now = new Date();
    insightsTrace("VIEW", "MyRequestsTimeOffPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });
    
    const onClose = () => {
        navigate(".", { replace: true });
        setRefresh(refresh + 1);
    };

    if (action === "add") {
        return (
            <Modal
                title="Time Off Request"
                stickyAction={true}
                onRequestClose={onClose}
            >
                <TimeOffRequestForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal
                title="Time Off Request"
                stickyAction={true}
                onRequestClose={onClose}
            >
                <TimeOffRequestForm
                    timeOffIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "delete" && !isNaN(parsedId)) {
        return (
            <Modal title="Time Off Request" onRequestClose={onClose}>
                <DeleteTimeOffRequestConfirmation
                    timeOffRequestIdToDelete={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return (
        <>
            <VStack>
                <Text variant="Headline_4" color={"Gray_1"}>
                    {title}
                </Text>
                <VStack>
                    <MyRequestTimeOffTable refresh={refresh} />
                </VStack>
            </VStack>
        </>
    );
};

export default MyRequestsTimeOff;
