import { useContext } from "react";
import PageWrapper from "../../PageWrapper";
import ClientFamilyCorpTable from "./ClientFamilyCorpTable";
import { ServerSidePaginationContextProvider } from "context/TablePagination/ServerSidePaginationContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const ClientFamilyCorp = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    
    const now = new Date();
    insightsTrace("VIEW", "AdminClientFamilyPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    return (
        <PageWrapper
            title="Client Families"
            menuOptions={[{ children: "Admin", to: "/admin" }]}
        >
            <ServerSidePaginationContextProvider>
                <ClientFamilyCorpTable />
            </ServerSidePaginationContextProvider>
        </PageWrapper>
    );
};

export default ClientFamilyCorp;
