import React, {
    useMemo,
    useCallback,
    useReducer,
    useEffect,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useLocationState } from "react-router-use-location-state";

function reducer(oldState, action) {
    const { clientId } = action.payload;
    let closedProjectRows = [];
    oldState.map((id) => closedProjectRows.push(id));

    switch (action.type) {
        case "toggleProjectRow":
            const clientIdIndex = closedProjectRows.indexOf(clientId);
            if (clientIdIndex === -1) {
                closedProjectRows.push(clientId);
            } else if (clientIdIndex > -1) {
                closedProjectRows.splice(clientIdIndex, 1);
            }
            return closedProjectRows;
        case "openAllProjectRows":
            closedProjectRows = [];
            return closedProjectRows;
        case "toggleProjectRowAfterSubmit":
            const clientIdIndexs = closedProjectRows.indexOf(clientId);
            if (clientIdIndexs === -1) {
                closedProjectRows.push(clientId);
            }
            return closedProjectRows;
        default:
            throw new Error("Invalid Action Type");
    }
}

export const TimesheetProjectRowExpansionContextActions = React.createContext({
    toggleProjectRow: () => void 0,
    openAllProjectRows: () => void 0,
    toggleProjectRowAfterSubmit: () => void 0,
});

export const TimesheetProjectRowExpansionContextValue = React.createContext({
    isProjectRowOpen: () => false,
});

export const TimesheetProjectRowExpansionContextProvider = (props) => {
    const { state } = useLocation();

    const [initialState] = useState(() => {
        const initialStateData = [];
        let stateType = Array.isArray(state);

        if (state?.length !== 0 && stateType) {
            state?.map((data) => {
                initialStateData.push(data.key);
            });
        }

        return initialStateData;
    });

    const [rowState, setRowState] = useLocationState(
        "timesheetRowExpansionState",
        initialState
    );

    const [newReducerState, dispatch] = useReducer(reducer, rowState);

    const toggleProjectRow = useCallback((clientId) => {
        dispatch({
            type: "toggleProjectRow",
            payload: { clientId },
        });
    }, []);

    const openAllProjectRows = useCallback((_clientId) => {
        dispatch({
            type: "openAllProjectRows",
            payload: {},
        });
    }, []);

    const toggleProjectRowAfterSubmit = useCallback((clientId) => {
        dispatch({
            type: "toggleProjectRowAfterSubmit",
            payload: { clientId },
        });
    }, []);

    useEffect(() => {
        setRowState(newReducerState);
    }, [newReducerState, setRowState]);

    const contextActions = useMemo(
        () => ({
            toggleProjectRow,
            openAllProjectRows,

            toggleProjectRowAfterSubmit,
        }),
        [openAllProjectRows, toggleProjectRow, toggleProjectRowAfterSubmit]
    );

    const isProjectRowOpen = useCallback(
        (clientId) => !newReducerState.includes(clientId),
        [newReducerState]
    );

    const allProjectRowsAreOpen = useCallback(newReducerState.length === 0, [
        newReducerState,
    ]);

    const contextValues = useMemo(
        () => ({
            isProjectRowOpen,
            allProjectRowsAreOpen,
        }),
        [isProjectRowOpen, allProjectRowsAreOpen]
    );

    return (
        <TimesheetProjectRowExpansionContextActions.Provider
            value={contextActions}
        >
            <TimesheetProjectRowExpansionContextValue.Provider
                value={contextValues}
            >
                {props.children}
            </TimesheetProjectRowExpansionContextValue.Provider>
        </TimesheetProjectRowExpansionContextActions.Provider>
    );
};
