import styled from "styled-components/macro";
import { createGlobalStyle } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

export const HeaderPageStyled = createGlobalStyle`
    #top-nav,
    .selector-nav{   
        width: 100%;    
        position: fixed;
        background:${COLOR_VALUES.Gray_8};
        z-index: 10;
    }
    .selector-nav{
        top: ${(props) => (props.top ? `${props.top}px` : "49px")}; 
    }
    .footer{
        margin-top:128px;
    }
    .unauth-msg-wrapper{
        background: ${COLOR_VALUES.White}
    }
    .dashboard-side-nav-wrapper{
        background: ${COLOR_VALUES.White};
        overflow-y: auto;
        height: ${(props) =>
            props.fixedHeaderHeight
                ? `calc(100vh - ${props.fixedHeaderHeight}px)`
                : "auto"};
        -ms-overflow-style: none; 
        scrollbar-width: none;

        ::-webkit-scrollbar {
            width: 0;
            background: transparent; /* make scrollbar transparent */
            display: none; 
        }
    }
`;

export const StyledTertiaryButton = styled(TertiaryButton)`
    color: ${COLOR_VALUES.Gray_2} !important;
    padding: 3px 0 0 3px;
    min-height: unset;
    height: max-content;
    width: max-content;
`;
