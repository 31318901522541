const SPACING_VALUES = {
    Zero: "0rem",
    Zero_25: "0.25rem",
    Zero_5: "0.5rem",
    Zero_75: "0.75rem",
    One: "1rem",
    Two: "2rem",
    Three: "3rem",
    Four: "4rem",
    Five: "5rem",
    Six: "6rem",
    Seven: "7rem",
    Nine: "9rem",
};

const SPACING = {
    Zero: "Zero",
    Zero_25: "Zero_25",
    Zero_5: "Zero_5",
    Zero_75: "Zero_75",
    One: "One",
    Two: "Two",
    Three: "Three",
    Four: "Four",
    Five: "Five",
    Six: "Six",
    Seven: "Seven",
    Nine: "Nine",
};

export default SPACING;
export { SPACING_VALUES };
