import PropTypes from "prop-types";

const Right = ({ size, circle }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + size + " " + size}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {circle && (
                <circle
                    r="11.5"
                    transform="matrix(-1 0 0 1 12 12)"
                    stroke="#E5E8EC"
                />
            )}
            <path d="M10 17L15 12L10 7" stroke="black" />
        </svg>
    );
};

Right.defaultProps = {
    size: "24",
    circle: true,
};

Right.propTypes = {
    size: PropTypes.string,
    circle: PropTypes.bool,
};

export default Right;
