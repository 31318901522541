import { useParams } from "react-router";
import { useState } from "react";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Text from "components/design-system/ui/Text";
import { sendNotification } from "utilities/Notification";

import { useProjectsApi } from "pages/admin/projects/useProjects";
import useProjectsLinked from "pages/admin/projects/single/useProjectsLinked";

const ProjectLinkDeleteModal = ({ projectLinkIdToDelete, onClose }) => {
    const { projectId } = useParams();
    const { useProjectById } = useProjectsApi();
    const projectData = useProjectById(projectId);
    const { useProjectsRelatedList, deleteRelatedProject } =
        useProjectsLinked();
    const relatedProjectsList = useProjectsRelatedList({ jobID: projectId });
    const [inProgress, setInProgress] = useState(false);

    if (relatedProjectsList?.isLoading || projectData.isLoading) {
        return <>Loading...</>;
    }

    if (relatedProjectsList?.isError || projectData.isError) {
        return <>Error</>;
    }

    const currentProjectLinkData = relatedProjectsList.data.find(
        (projectLink) => projectLink.relatedJobID === projectLinkIdToDelete
    );

    if (!currentProjectLinkData) {
        return <div>Could not find project link</div>;
    }

    const modifier = deleteRelatedProject;

    const onSubmit = () => {
        const data = {
            id: projectId,
            relatedJobId: projectLinkIdToDelete,
        };
        modifier.mutateAsync(data).then(() => {
            setInProgress(false);
            sendNotification(
                undefined,
                <>
                    <strong>{currentProjectLinkData.Name}</strong> link has been
                    removed
                </>
            );
            onClose();
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <VStack spacing="Three">
                <VStack>
                    <Text variant="Body_1_1" color="Gray_2">
                        Are you sure you&apos;d like to remove{" "}
                        <span style={{ fontWeight: "700" }}>
                            {currentProjectLinkData.Name}
                        </span>{" "}
                        Link?
                    </Text>
                </VStack>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: "Yes, Remove",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        onClick: onClose,
                    }}
                    as="fieldset"
                    disabled={modifier.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default ProjectLinkDeleteModal;
