import { COLOR_VALUES } from "components/design-system/config/colors";

export const TertiaryButtonStyle = `
display: inline-flex;
gap: 4px;
align-items: center;
justify-content: center;    
border-radius: 4px;

&:not(:disabled) {    
    &:hover {
        background: ${COLOR_VALUES.Gray_7};
    }

    &:active,
    &:focus-visible {
        outline: none;
        background: none;
        box-shadow: 0 0 0px 1px ${COLOR_VALUES.Black} inset;
    }
}

&:disabled {    
    color: ${COLOR_VALUES.Gray_3} !important;
    
    &:focus-visible{
        box-shadow: none;
    }
}`;
