import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useIncompleteTimesheetApi = () => {
    const { getDataAsync } = useApi();

    const getIncompleteTimesheetApiCall = useCallback(
        async (employeeId) => {
            const response = await getDataAsync("incompleteTimesheets", {
                urlParams: [{ name: "$employeeId", value: employeeId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useIncompleteTimesheet = (employeeId) =>
            useQuery(["incompleteTimesheets", employeeId], () =>
                getIncompleteTimesheetApiCall(employeeId)
            );

        return {
            useIncompleteTimesheet,
        };
    }, [getIncompleteTimesheetApiCall]);
};

export default useIncompleteTimesheetApi;
