import { useMemo, useCallback } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";

export const useWeeksTimeEntriesApi = () => {
    const { getDataAsync, postData } = useApi();
    const queryClient = useQueryClient();

    const getWeeksTimeEntriesApiCall = useCallback(
        async ({ employeeId, fromDate }) => {
            const response = await getDataAsync("getWeeksTimeEntries", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$fromDate", value: fromDate },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const submitTimesheetApiCall = useCallback(
        ({ employeeId, weekDate }) => {
            return postData("submitTimesheet", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$weekDate", value: weekDate },
                ],
            });
        },
        [postData]
    );

    const reopenTimesheetApiCall = useCallback(
        ({ employeeId, weekDate }) => {
            return postData("reopenTimesheet", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$weekDate", value: weekDate },
                ],
            });
        },
        [postData]
    );
    const mutationOptionsForSubmission = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("weeksTimeEntries");
            },
        };
    }, [queryClient]);

    const submitTimesheet = useMutation(
        submitTimesheetApiCall,
        mutationOptionsForSubmission
    );

    const reopenTimesheet = useMutation(
        reopenTimesheetApiCall,
        mutationOptionsForSubmission
    );

    return useMemo(() => {
        const useWeeksTimeEntries = (dataObj) =>
            useQuery(["weeksTimeEntries", dataObj], () =>
                getWeeksTimeEntriesApiCall(dataObj)
            );

        return {
            useWeeksTimeEntries,
            submitTimesheet,
            reopenTimesheet,
        };
    }, [getWeeksTimeEntriesApiCall, submitTimesheet, reopenTimesheet]);
};

const useTimesheetGrid = () => {
    const { getDataAsync, postData } = useApi();
    const queryClient = useQueryClient();

    const getAssignmentsApiCall = useCallback(
        async ({ employeeId, fromDate, weeksCount = 1 }) => {
            if (fromDate) {
                const response = await getDataAsync("getAssignments", {
                    urlParams: [
                        { name: "$employeeId", value: employeeId },
                        { name: "$fromDate", value: fromDate },
                        { name: "$weeksCount", value: weeksCount },
                    ],
                });
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    const getDepartmentsAssignmentsApiCall = useCallback(
        async ({ departmentId, officeId, agencyId, fromDate, weeksCount = 1 }) => {
            if (fromDate) {
                const response = await getDataAsync(
                    "getAssignmentsDepartment",
                    {
                        urlParams: [
                            { name: "$departmentId", value: departmentId },
                            { name: "$officeId", value: officeId },
                            { name: "$agencyId", value: agencyId },
                            { name: "$fromDate", value: fromDate },
                            { name: "$weeksCount", value: weeksCount },
                        ],
                    }
                );
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    const getHoursTimeEntriesApiCall = useCallback(
        async ({ employeeId, fromDate }) => {
            if (fromDate) {
                const response = await getDataAsync("getHoursTimeEntries", {
                    urlParams: [
                        { name: "$employeeId", value: employeeId },
                        { name: "$fromDate", value: fromDate },
                    ],
                });
                return response.status != 204 ? response.json() : null;
            } else return null;
        },
        [getDataAsync]
    );

    const updateHoursTimeEntriesApiCall = useCallback(
        ({ employeeId, jobId, titleId, entryDate, hours, isTimeOff, vacationRequestId, agencyId, isHoliday, officeId, reasonId }) => {
            return postData("updateHoursTimeEntries", {
                urlParams: [
                    { name: "$employeeId", value: employeeId },
                    { name: "$jobId", value: jobId },
                    { name: "$titleId", value: titleId },
                    { name: "$entryDate", value: entryDate },
                    { name: "$hours", value: hours },
                    { name: "$isTimeOff", value: isTimeOff },
                    { name: "$vacationRequestId", value: vacationRequestId },
                    { name: "$agencyId", value: agencyId },
                    { name: "$isHoliday", value: isHoliday },
                    { name: "$officeId", value: officeId },
                    { name: "$reasonId", value: reasonId },
                ],
            });
        },
        [postData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("hoursTimeEntries");
                queryClient.invalidateQueries("getApprovedTimeOffByDate");
                queryClient.invalidateQueries("getPendingHRTimeOffByDate");
                queryClient.invalidateQueries("getUsedAllotmentByYear");
            },
        };
    }, [queryClient]);

    const updateHoursTimeEntries = useMutation(
        updateHoursTimeEntriesApiCall,
        mutationOptions
    );

    return useMemo(() => {
        const useAssignments = (dataObj) =>
            useQuery(["assignments", dataObj], () =>
                getAssignmentsApiCall(dataObj)
            );

        const useDepartmentsAssignments = (dataObj) =>
            useQuery(["assignmentsDepartment", dataObj], () =>
                getDepartmentsAssignmentsApiCall(dataObj)
            );

        const useHoursTimeEntries = (dataObj) =>
            useQuery({
                queryKey: ["hoursTimeEntries", dataObj],
                queryFn: () => getHoursTimeEntriesApiCall(dataObj),
                refetchOnMount: true,
                refetchOnReconnect: false,
            });

        return {
            useAssignments,
            useDepartmentsAssignments,
            useHoursTimeEntries,
            updateHoursTimeEntries,
        };
    }, [
        getAssignmentsApiCall,
        getDepartmentsAssignmentsApiCall,
        getHoursTimeEntriesApiCall,
        updateHoursTimeEntries,
    ]);
};

export default useTimesheetGrid;
