import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";
import ControlledToggleButton from "./BaseToggleButton";

const useToggleButtonState = (
    defaultChecked = false,
    defaultOnChange = () => void 0
) => {
    const [internalChecked, setInternalChecked] = useState(defaultChecked);

    const internalOnChange = (event) => {
        setInternalChecked(event.target.checked);
        defaultOnChange(event);
    };

    return [internalChecked, internalOnChange];
};

export const WrapperToggleButton = styled.div`
    display: flex;
    width: max-content;
    height: auto;

    ${({ $disabled }) =>
        $disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const StyledLabel = styled(HStack).attrs({ forwardedAs: "label" })`
    position: relative;

    ${({ $disabled }) =>
        $disabled &&
        css`
            color: ${COLOR_VALUES.Gray_3};
            pointer-events: none;
            user-select: none;
        `}

    ${({ $disabled, $checked }) =>
        $disabled &&
        $checked &&
        css`
            color: ${COLOR_VALUES.Black};
            &:hover {
                color: ${COLOR_VALUES.Gray_2};
            }
            &:active {
                color: ${COLOR_VALUES.Gray_2};
            }
        `}

    ${({ $disabled, $checked }) =>
        !$disabled &&
        !$checked &&
        css`
            color: ${COLOR_VALUES.Gray_1};
            &:hover {
                color: ${COLOR_VALUES.Gray_4};
            }
            &:active {
                color: ${COLOR_VALUES.Black};
            }
        `}
`;

const ToggleButton = React.forwardRef(
    ({ label, checked, defaultChecked, onChange, disabled, ...rest }, ref) => {
        const isControlled = typeof checked === "boolean";
        const [internalChecked, internalOnChange] = useToggleButtonState(
            defaultChecked,
            onChange
        );

        const actualChecked = isControlled ? checked : internalChecked;
        const actualOnChange = isControlled ? onChange : internalOnChange;

        const ToggleButtonControl = (
            <ControlledToggleButton
                checked={actualChecked}
                onChange={actualOnChange}
                disabled={disabled}
                $expandHitArea={label}
                {...rest}
            />
        );

        return (
            <WrapperToggleButton $disabled={disabled} ref={ref}>
                {label ? (
                    <StyledLabel
                        spacing="Zero_5"
                        align="center"
                        $disabled={disabled}
                        $checked={actualChecked}
                    >
                        {ToggleButtonControl}
                        <Text variant={actualChecked ? "Body_2_2" : "Body_2_1"}>
                            {actualChecked ? label[1] : label[0]}
                        </Text>
                    </StyledLabel>
                ) : (
                    ToggleButtonControl
                )}
            </WrapperToggleButton>
        );
    }
);

ToggleButton.propTypes = {
    label: PropTypes.array,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    defaultIndeterminate: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ToggleButton;
