import React from "react";
import styled, { css } from "styled-components/macro";
import { useFormContext, useController } from "react-hook-form";
import VStack from "components/design-system/layout/VStack";
import Checkbox from "components/design-system/controls/checkbox/Checkbox";
import Radio from "components/design-system/controls/radio/Radio";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import { getFeedbackProps } from "./utils";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { COLOR_VALUES } from "components/design-system/config/colors";

const InputLabel = styled.label`
    word-break: break-all;
    color: ${COLOR_VALUES.Gray_1};
    ${(props) =>
        props.labelVariant
            ? css`
                  ${props.labelVariant}
              `
            : css`
                  ${TEXT_VARIANT_STYLES.Headline_5}
              `};

    margin: 0;
`;

const FormInputWrapperText = styled(Text)`
    word-break: break-all;
`;

const Wrapper = styled.fieldset`
    margin: 0;
    padding: 0;
    border: 0;

    &:not(:first-child) {
    }

    &:not(:last-child) {
    }
`;

const Options = ({
    name,
    labelVariant,
    label,
    description,
    defaultSelection,
    options = [],
    multiselect = true,
    validations = {},
    onValueSelected = () => void 0,
    disabled,
}) => {
    const {
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        name,
        rules: validations,
        defaultValue: defaultSelection ? defaultSelection : "",
    });

    const { value, onChange, onBlur } = field;
    const valueArray = value.length ? value.split(",") : [];

    const Element = multiselect ? Checkbox : Radio;

    const fieldErrors = errors[name];
    const feedbackProps = getFeedbackProps(fieldErrors);

    return (
        <Wrapper>
            <VStack>
                {(label || description) && (
                    <div>
                        {label && (
                            <InputLabel labelVariant={labelVariant}>
                                {label}
                            </InputLabel>
                        )}
                        {description && (
                            <FormInputWrapperText
                                color="Gray_2"
                                variant="Descriptive_1_1"
                            >
                                {description}
                            </FormInputWrapperText>
                        )}
                    </div>
                )}
                <VStack spacing="Zero_75" align="start">
                    {options.map(({ value, label }) => (
                        <Element
                            disabled={disabled}
                            label={label}
                            value={value}
                            key={value}
                            checked={valueArray.includes(value)}
                            onBlur={onBlur}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                const newValueArray = checked
                                    ? multiselect
                                        ? [...valueArray, value]
                                        : [value]
                                    : valueArray.filter(
                                          (valueEntry) => valueEntry !== value
                                      );

                                onChange(newValueArray.join(","));
                                onValueSelected(newValueArray.join(","));
                            }}
                        />
                    ))}
                </VStack>
                {feedbackProps.feedbackMessage && (
                    <FormInputWrapper {...feedbackProps} />
                )}
            </VStack>
        </Wrapper>
    );
};

export default Options;
