import PropTypes from "prop-types";

const Clock = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.98078 15.0001C4.68138 15.0001 2 12.3187 2 9.01935C2 5.71996 4.68138 3.03857 7.98078 3.03857C11.2802 3.03857 13.9616 5.71996 13.9616 9.01935C13.9616 12.3187 11.2802 15.0001 7.98078 15.0001ZM7.98078 4.03537C5.2346 4.03537 2.9968 6.27318 2.9968 9.01935C2.9968 11.7655 5.2346 14.0033 7.98078 14.0033C10.7269 14.0033 12.9648 11.7655 12.9648 9.01935C12.9648 6.27318 10.7269 4.03537 7.98078 4.03537Z"
                fill={color}
            />
            <path
                d="M9.49595 11.1975L7.48242 9.18894V5.48584H8.47922V8.77527L10.1987 10.4898L9.49595 11.1975Z"
                fill={color}
            />
            <path d="M9.47598 1H6.4856V1.9968H9.47598V1Z" fill={color} />
        </svg>
    );
};

Clock.defaultProps = {
    size: "16",
    viewBox: "16",
    color: global.config.colors.black,
};

Clock.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default Clock;
