import React, { useContext, useEffect, useState } from "react";
import {
    useCalendar,
    useCalendarGrid,
    useCalendarCell,
} from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import {
    getWeeksInMonth,
    CalendarDate,
    today,
    getLocalTimeZone,
} from "@internationalized/date";
import styled from "styled-components";
import { format, startOfWeek, getYear } from "date-fns";

import DatePickerDisplayMonthAndYear from "components/design-system/controls/datepicker/DatePickerDisplayMonthAndYear";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import HStack from "components/design-system/layout/HStack";
import { DateButton } from "components/design-system/controls/week-navigator/StyledElements";
import { COLOR_VALUES } from "components/design-system/config/colors";
import {
    getDateFnsFormatDate,
    getWeekStartOrEndDate,
} from "components/design-system/controls/week-navigator/WeekNavigatorMain";
import Icon from "components/design-system/ui/Icon";
import { CalendarTitleBlockPadding } from "components/design-system/controls/datepicker/DatePickerCalendar";

import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import SessionContext from "context/app/SessionContext";

import { checkTenureNotStarted } from "pages/timesheet/timesheet-grid/TimesheetGrid";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { Tooltip as DefaultTooltip } from "@mui/material";
import Tooltip from "components/design-system/ui/Tooltip";


const StyledHStack = styled(HStack)`
    padding: ${CalendarTitleBlockPadding};
    border-bottom: 1px solid ${COLOR_VALUES.Gray_5};
`;

const StyledTertiaryButton = styled(TertiaryButton)`
    box-shadow: 0 0 0px 1px ${COLOR_VALUES.Gray_6} inset;
    padding: 0;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50% !important;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;
    }
`;

const StyledTooltip = withStyles({
    tooltip: {
        zindex: "1",
        background: COLOR_VALUES.Gray_1,
        color: COLOR_VALUES.White,
        borderRadius: "2px",
        width: "65px",
        position: "relative",
        top: "-8px",
        // Small variant
        ...TEXT_VARIANT_STYLES.Descriptive_1_1,
    },
})(DefaultTooltip);

const DateButtonContent = ({ displayDateRange, showIcon, icon, label }) => {
    return (
        <Tooltip placement={"bottom-end"} text={label} enabled={!!showIcon} enterDelay={0}>
            <HStack align="flex-end" justify="space-between">
                {displayDateRange}
                {showIcon ?
                    <HStack justify="space-between">
                        <Icon name={icon} />
                    </HStack>
                    : ""}
            </HStack>
        </Tooltip>
    )
};

function CalendarCell({
    state,
    date,
    currentWeek,
    closeOverlay,
    dateRangesWithWarning,
    dateRangesWithIcon,
    incompleteWeek,
}) {
    const ref = React.useRef();
    const { startDate } = useContext(SessionContext);

    const { cellProps, buttonProps, isDisabled, isUnavailable } =
        useCalendarCell({ date }, state, ref);
    const { selectedTimesheetStartDate } = useContext(WeekNavigatorContext);

    const cellCalendarDate = new CalendarDate(date.year, date.month, date.day);

    const isDateSelected =
        selectedTimesheetStartDate?.compare(cellCalendarDate) === 0;

    const dateEnd = getWeekStartOrEndDate(0, false, date);

    const displayDateRange = (
        <>
            {format(
                getDateFnsFormatDate(date.year, date.month, date.day),
                "MMM"
            )}
            &nbsp;{date.day}&nbsp;&#45;&nbsp;
            {format(
                getDateFnsFormatDate(dateEnd.year, dateEnd.month, dateEnd.day),
                "MMM"
            )}
            &nbsp;{dateEnd.day}
        </>
    );

    const isHidden = date.month <= state.visibleRange.end.month ? false : true;

    const dateButtonActionProps = {
        isSelected: isDateSelected,
        isCurrent: currentWeek,
        hidden: isHidden,
        isUnavailable: isUnavailable,
        disabled: isDisabled,
    };

    const isFutureWeek = (currentDate) => {
        let rv = false;
        if (currentDate) {
            let selectDate = moment(currentDate).format("YYYY-MM-DD");
            let currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
            rv = moment(selectDate).isAfter(currentWeek);
        }
        return rv;
    };

    const isInWarningDateRanges = (selectedDate, ranges) => {
        if (isFutureWeek(selectedDate)) {
            return false;
        }

        if (!ranges || ranges.length === 0) {
            return false;
        }

        const isInWarning = ranges.some(range => {

            let startDate = moment(range.startDate).format("YYYY-MM-DD");
            let endDate = moment(range.endDate).format("YYYY-MM-DD");
            let selected = moment(selectedDate).format("YYYY-MM-DD");

            let isBetween = moment(selected).isSameOrAfter(startDate) &&
                moment(selected).isSameOrBefore(endDate);

            return isBetween
        });

        return isInWarning;
    };

    const showIconDateRanges = (selectedDate, ranges) => {

        if (!ranges || ranges.length === 0) {
            return "";
        }

        const item = ranges.find(range => {

            let startDate = moment(range.startDate).format("YYYY-MM-DD");
            let endDate = moment(range.endDate).format("YYYY-MM-DD");
            let firstDayOfweek = moment(selectedDate).format("YYYY-MM-DD");
            let lastDayOfWeek = moment(selectedDate).isoWeekday(5).format("YYYY-MM-DD");

            let isBetween = moment(firstDayOfweek).isSameOrAfter(startDate) &&
                moment(lastDayOfWeek).isSameOrBefore(endDate);

            return isBetween
        });

        return item;
    };

    const stringDate = `${date.year}-${date.month}-${date.day}`;
    const isInWarningRange = isInWarningDateRanges(stringDate, dateRangesWithWarning);
    const showIconInWeek = showIconDateRanges(stringDate, dateRangesWithIcon);
    const tenureNotStarted = checkTenureNotStarted(cellCalendarDate, startDate);

    return (
        <td {...cellProps} style={{ width: "100%" }}>
            {(!isDisabled || !isUnavailable) && tenureNotStarted && (
                <DateButton ref={ref} isDisabled={true}>
                    {DateButtonContent(displayDateRange, false)}
                </DateButton>
            )}
            {isDisabled || isUnavailable ? (
                <DateButton
                    ref={ref}
                    {...dateButtonActionProps}
                    /* Remove this style if we want to display the disabled week option 
                    this is related with thirdWeekCount in CalendarGrid*/
                    style={{ display: "none", height: 0 }}
                >
                    {DateButtonContent(displayDateRange, incompleteWeek)}
                </DateButton>
            ) : (
                !tenureNotStarted && (
                    <DateButton
                        {...buttonProps}
                        ref={ref}
                        onClickCapture={closeOverlay}
                        {...dateButtonActionProps}
                    >
                        <>
                            {showIconInWeek
                                ? <DateButtonContent
                                    displayDateRange={displayDateRange}
                                    showIcon={showIconInWeek}
                                    icon={showIconInWeek.icon}
                                    label={showIconInWeek.label}
                                >
                                </DateButtonContent>
                                :
                                <DateButtonContent
                                    displayDateRange={displayDateRange}
                                    showIcon={incompleteWeek && isInWarningRange}
                                    icon={"warning"}
                                    label={
                                        incompleteWeek && isInWarningRange
                                            ? "Incomplete"
                                            : ""
                                    }
                                ></DateButtonContent>
                            }
                        </>
                    </DateButton>
                )
            )}
        </td>

    );
}

export const getCurrentWeekStartCalendarDate = () => {
    return getWeekStartOrEndDate(0, true, today(getLocalTimeZone()));
};

function CalendarGrid({
    state,
    closeOverlay,
    dateRangesWithWarning,
    dateRangesWithIcon,
    ...props }) {
    const {
        incompleteWeekStartDateList,
        setFromDateToUpdateList,
        fromDateToUpdateList,
        setUpdateList,
    } = useContext(WeekNavigatorContext);
    const { locale } = useLocale();
    const { gridProps } = useCalendarGrid(props, state);

    const weeksInMonth = getWeeksInMonth(state?.visibleRange?.start, locale);

    const weekStartDate = getCurrentWeekStartCalendarDate();

    let incompleteWeekStartDateCalendarArr = [];

    incompleteWeekStartDateList.map((data) => {
        let currentWeekStartDate = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "MMM dd"
        );
        const newDateFormat = new Date(data.date);
        const formatCurrentNewDateFormat = format(newDateFormat, "MMM dd");
        const date = new CalendarDate(
            "AD",
            format(newDateFormat, "yyyy"),
            format(newDateFormat, "M"),
            format(newDateFormat, "d")
        );

        if (currentWeekStartDate !== formatCurrentNewDateFormat) {
            incompleteWeekStartDateCalendarArr.push(date);
        }
    });

    useEffect(() => {
        /* Set the count to 2 if in dropdown list we display the disabled first week option
        this count is related with isDisabled Button Style in CalendarCell*/
        const thirdWeekCount =
            state.visibleRange.start.month === state.getDatesInWeek(0)[1].month
                ? 2
                : 3;

        const mondayDateThirdWeekOfMonth =
            state.getDatesInWeek(thirdWeekCount)[1];

        if (
            !fromDateToUpdateList ||
            (fromDateToUpdateList &&
                mondayDateThirdWeekOfMonth?.compare(fromDateToUpdateList) !== 0)
        ) {
            setFromDateToUpdateList(mondayDateThirdWeekOfMonth);
            setUpdateList(true);
        }
    }, [state.visibleRange.start.month]);

    return (
        <table
            {...gridProps}
            style={{ padding: "0 7px 0 16px", width: "100%" }}
        >
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex)
                            .map(
                                (date, i) =>
                                    date &&
                                    i === 1 && (
                                        <CalendarCell
                                            key={i}
                                            closeOverlay={closeOverlay}
                                            state={state}
                                            date={date}
                                            currentWeek={
                                                date.compare(weekStartDate) ===
                                                0
                                            }
                                            dateRangesWithWarning={dateRangesWithWarning}
                                            dateRangesWithIcon={dateRangesWithIcon}
                                            incompleteWeek={
                                                incompleteWeekStartDateCalendarArr?.filter(
                                                    (f) =>
                                                        f.year === date.year &&
                                                        f.month ===
                                                        date.month &&
                                                        f.day === date.day
                                                )?.length
                                            }
                                        />
                                    )
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default function DatePickerCalendar({
    state,
    closeOverlay,
    dateRangesWithWarning,
    dateRangesWithIcon,
    ...props }) {

    const ref = React.useRef();
    const { calendarProps, prevButtonProps, nextButtonProps, title } =
        useCalendar(props, state, ref);
    const [showMonthAndYear, setShowMonthAndYear] = useState(false);
    let dateInit = new Date();
    let minYearDate = dateInit.setFullYear(dateInit.getFullYear() - 1);
    let minYearDateYear = getYear(minYearDate);

    return (
        <div {...calendarProps} ref={ref}>
            <StyledHStack align="center" justify="space-between">
                <TertiaryButton
                    trailingIcon={showMonthAndYear ? "caret-down" : "caret-up"}
                    label={title}
                    onClick={() => setShowMonthAndYear(!showMonthAndYear)}
                />

                <HStack>
                    <StyledTertiaryButton
                        trailingIcon="caret-left"
                        onClick={prevButtonProps.onPress}
                        {...Object.entries(prevButtonProps).splice(
                            Object.keys(prevButtonProps).indexOf("onPress"),
                            1
                        )}
                        {...{ "aria-label": "Prev" }}
                        disabled={
                            state.focusedDate.month === 1 &&
                                state.focusedDate.year === minYearDateYear
                                ? true
                                : false
                        }
                    />

                    <StyledTertiaryButton
                        trailingIcon="caret-right"
                        onClick={nextButtonProps.onPress}
                        {...Object.entries(nextButtonProps).splice(
                            Object.keys(nextButtonProps).indexOf("onPress"),
                            1
                        )}
                        {...{ "aria-label": "Next" }}
                    />
                </HStack>
            </StyledHStack>
            {showMonthAndYear ? (
                <DatePickerDisplayMonthAndYear
                    state={state}
                    setShowMonthAndYear={setShowMonthAndYear}
                    isWeekNavigator={true}
                />
            ) : (
                <>
                    <CalendarGrid
                        state={state}
                        dateRangesWithWarning={dateRangesWithWarning}
                        dateRangesWithIcon={dateRangesWithIcon}
                        closeOverlay={closeOverlay}
                    />
                </>
            )}
        </div>
    );
}
