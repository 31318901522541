import PropTypes from "prop-types";
import { Tooltip as Tip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useState } from "react";

const StyledToolTip = withStyles((_theme) => ({
    tooltip: {
        backgroundColor: global.config.colors.gray1,
        padding: "0.5rem 1rem",
        marginBottom: "1rem",
        borderRadius: global.config.sizes.borderRadius,
        "&.MuiTooltip-tooltip > p": {
            color: global.config.colors.white,
        },
    },
    popper: {
        zIndex: 1051,
    },
}))(Tip);

const Tooltip = (props) => {
    const { placement, title, enterDelay, leaveDelay, show } = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        show && setOpen(document.activeElement.className !== "modal-open");
    };

    return <>{props.children}</>;
};

Tooltip.defaultProps = {
    placement: "top",
    enterDelay: 2000,
    leaveDelay: 0,
    show: true,
};

Tooltip.propTypes = {
    placement: PropTypes.string,
    enterDelay: PropTypes.number,
    leaveDelay: PropTypes.number,
};

export default Tooltip;
