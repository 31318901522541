import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";
import BaseButton from "components/design-system/controls/button/BaseButton";
import HStack from "components/design-system/layout/HStack";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import Loader from "components/UI/Loader";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";

const StyledTh = styled.th`
    padding: ${({ $includePadding }) =>
        $includePadding ? "6px 12px 4px 12px" : "0"};
    border-bottom: ${({ borderBottom }) =>
        borderBottom ? `2px solid ${COLOR_VALUES.Gray_6}` : "None"};
    height: ${(props) => (props.noContentInHeaderRow ? "24px" : "52px")};
    text-align: start;
    vertical-align: middle;
    white-space: nowrap;
    min-width: ${(props) => props?.minWidth ?? "auto"};

    ${(props) =>
        props.grayBgHeader &&
        css`
            background: ${COLOR_VALUES.Gray_1};
            color: ${COLOR_VALUES.White};

            button {
                color: inherit;
            }
        `}

    ${(props) =>
        props.bgColor &&
        props.bgTextColor &&
        css`
            background: ${props.bgColor};
            color: ${props.bgTextColor};
        `}
`;

const StyledBaseButton = styled(BaseButton)`
    padding: 6px 12px 4px 12px;
    height: 100%;
    width: 100%;

    &:hover {
        background: ${COLOR_VALUES.Gray_7};
    }

    ${(props) =>
        props.grayBgHeader &&
        css`
            &:hover {
                background: ${COLOR_VALUES.Gray_2};
            }
        `}
`;

const StyledSortIcon = styled(Icon)`
    margin-left: auto;

    ${(props) =>
        props.grayBgHeader &&
        css`
            color: ${COLOR_VALUES.Gray_5};
        `}
`;

const ContentLayout = styled.div`
    display: grid;
    gap: 16px;
    align-items: center;
    grid-template-columns: ${({ $centered }) =>
        $centered ? "1fr auto 1fr" : "1fr auto"};

    ${(props) =>
        props.$alignedRight &&
        !props.$sortable &&
        css`
            grid-template-columns: 1fr;
        `}
`;

const StyledText = styled(Text)`
    ${(props) =>
        props.isNextLine &&
        css`
            white-space: pre-line;
        `}
`;

const TextArea = styled(HStack)`
    ${({ $centered, $hasIcon }) =>
        $centered &&
        css`
            grid-column: 2;
            text-align: ${!$hasIcon && "center"};
        `}

    ${(props) =>
        props.$alignedRight &&
        css`
            justify-content: end;
        `}
`;

const HeadCell = ({
    loadingIcon = false,
    text,
    description,
    icon,
    centered = false,
    alignedRight = false,
    sortable = false,
    currentSort = "none",
    onSortChangeRequest = () => void 0,
    isNextLine = false,
    sortByBEVal = undefined,
    variant = "Headline_5",
    borderBottom = true,
    noContentInHeaderRow = false,
    grayBgHeader = false,
    ...rest
}) => {
    const isSorted = sortable && currentSort !== "none";
    const thProps = { ...rest, $includePadding: !sortable };
    const { queryPageIndexUpdate, querySortUpdate } = useContext(
        ServerSidePaginationContext
    );

    useEffect(() => {
        if (sortByBEVal !== undefined) {
            queryPageIndexUpdate(0);
            querySortUpdate(sortByBEVal);
        }
    }, [sortByBEVal]);

    if (isSorted) {
        thProps["aria-sort"] =
            currentSort === "asc" ? "ascending" : "descending";
    }

    const HeadContent = !noContentInHeaderRow ? (
        <ContentLayout
            $centered={centered}
            $alignedRight={alignedRight}
            $sortable={sortable}
        >
            <TextArea
                $centered={centered}
                $hasIcon={!!icon}
                align="center"
                spacing="Zero_5"
                $alignedRight={alignedRight}
                $sortable={sortable}
            >
                {icon && <Icon name={icon} />}
                {(text || description) && (
                    <div>
                        {text && (
                            <StyledText
                                variant={variant}
                                isNextLine={isNextLine}
                            >
                                {text}
                            </StyledText>
                        )}
                        {description && (
                            <Text variant="Descriptive_2_1" color="Gray_2">
                                {description}
                            </Text>
                        )}
                    </div>
                )}
            </TextArea>
            {sortable && loadingIcon && (
                <Loader
                    color={COLOR_VALUES.Gray_4}
                    height={"12px"}
                    width={"12px"}
                />
            )}
            {sortable && !loadingIcon && (
                <StyledSortIcon
                    grayBgHeader={grayBgHeader}
                    name={currentSort === "desc" ? "arrow-down" : "arrow-up"}
                    color={
                        currentSort === "none" ? COLORS.Gray_4 : COLORS.Black
                    }
                />
            )}
        </ContentLayout>
    ) : null;

    return (
        <StyledTh
            {...thProps}
            grayBgHeader={grayBgHeader}
            borderBottom={noContentInHeaderRow ? false : borderBottom}
            noContentInHeaderRow={noContentInHeaderRow}
        >
            {sortable ? (
                <StyledBaseButton
                    onClick={onSortChangeRequest}
                    grayBgHeader={grayBgHeader}
                    aria-label={
                        currentSort === "asc"
                            ? "Sort Descending"
                            : "Sort Ascending"
                    }
                >
                    {HeadContent}
                </StyledBaseButton>
            ) : (
                HeadContent
            )}
        </StyledTh>
    );
};

HeadCell.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    icon: PropTypes.oneOf(ICON_NAMES),
    sortable: PropTypes.bool,
    currentSort: PropTypes.oneOf(["asc", "desc", "none"]),
    onSortChangeRequest: PropTypes.func,
};

export default HeadCell;
