import { useState, forwardRef, useImperativeHandle } from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Link from "../../UI/Link";
import TextInputWithValidation from "../../UI/TextInputWithValidation";
import Left from "../../Icon/Left";
import { useCustomFilters } from "../../../utilities/API/CustomFilters";
import { formModes, customFilterTypes } from "../../../resources/Enums";
import { makeStyles } from "@material-ui/styles";
import { useValidationError } from "hooks/Errors/useValidationError";
import IconButton from "components/UI/IconButton";
import Close from "components/Icon/Close";
import Check from "components/Icon/Check";

const ModalControl = styled.a`
    display: none;
    vertical-align: top;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
`;
const Back = styled.div`
    display: inline-flex;
    cursor: pointer;
    margin: 1rem 0.5rem;
`;
const Content = styled.div`
    width: 310px;
`;
const FormField = styled.div`
    margin: 2rem 0 1rem 0;
`;
const Action = styled.div`
    margin: 3.5rem 0;
    text-align: right;
    > button.MuiButton-root {
        margin-left: 2rem;
        margin-right: 0;
        min-width: 8.75rem;
        height: 2.625rem;
    }
    > button.MuiButton-root:hover {
        opacity: 0.75;
        box-shadow: none;
        background-color: ${global.config.colors.black};
    }
`;
const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 6.125rem;
`;

const useStyles = makeStyles(() => ({
    root: {
        "& .modal-content": {
            height: global.config.sizes.modal.height,
        },
    },
}));

const CustomFilter = forwardRef((props, ref) => {
    const { createCustomFilter, updateCustomFilter, deleteCustomFilter } =
        useCustomFilters();
    const { onCreate, onUpdate, onDelete } = props;
    const {
        control,
        setError,
        trigger,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });
    const [title, setTitle] = useState("");
    const [mode, setMode] = useState(formModes.ADD);
    const [show, setShow] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [previousName, setPreviousName] = useState("");
    const [data, setData] = useState(null);
    const classes = useStyles();

    const { handleFormErrorResponse } = useValidationError();

    useImperativeHandle(ref, () => ({
        add(data) {
            setData(data);
            setId("");
            setName("");
            setMode(formModes.ADD);
            setTitle("Save Filters");
            setShow(true);
        },
        rename(id, name) {
            setPreviousName(name);
            setId(id);
            setName(name);
            setMode(formModes.UPDATE);
            setTitle("Rename Filters");
            setShow(true);
        },
        delete(id, name) {
            setPreviousName(name);
            setId(id);
            setName(name);
            setMode(formModes.DELETE);
            setTitle("Delete Saved Filters");
            setShow(true);
        },
    }));
    const handleClose = () => {
        setMode(props.mode);
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    const handleSave = async function () {
        switch (mode) {
            case formModes.ADD:
                const selection = JSON.stringify(data);

                await createCustomFilter({
                    type: customFilterTypes.ALLOCATION,
                    name: name,
                    content: selection,
                })
                    .then((payload) => {
                        setMode(formModes.ADDED);
                        if (onCreate) {
                            onCreate(payload);
                        }
                    })
                    .catch((error) => {
                        handleFormErrorResponse(error, setError);
                    });
                break;
            case formModes.UPDATE:
                await updateCustomFilter({
                    type: customFilterTypes.ALLOCATION,
                    id: id,
                    name: name,
                })
                    .then(() => {
                        setMode(formModes.UPDATED);
                        if (onUpdate) {
                            onUpdate({ id, name });
                        }
                    })
                    .catch((error) => {
                        handleFormErrorResponse(error, setError);
                    });
                break;
            default:
                break;
        }
    };
    const handleDelete = async function () {
        await deleteCustomFilter([{ name: "$id", value: id }])
            .then((payload) => {
                if (payload.error) {
                    throw payload;
                }
                setMode(formModes.DELETED);
                if (onDelete) {
                    onDelete(id);
                }
            })
            .catch((error) => {
                handleFormErrorResponse(error, setError);
            });
    };

    return (
        <>
            <ModalControl onClick={handleShow}>{props.children}</ModalControl>
            <Modal
                show={show}
                onHide={() => handleClose()}
                backdrop={global.config.modal.static}
                keyboard={global.config.modal.keyboard}
                centered
                animation={false}
                className={classes.root}
            >
                <Modal.Header>
                    <Back onClick={handleClose}>
                        <Left circle={false} />
                        <Text variant="small">Back to Filters</Text>
                    </Back>
                    <IconButton
                        icon={<Close />}
                        onClick={() => handleClose()}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ContentWrapper>
                            <Content>
                                {(mode === formModes.ADD ||
                                    mode === formModes.UPDATE) && (
                                    <>
                                        <Text variant="xlarge">{title}</Text>
                                        <FormField>
                                            <TextInputWithValidation
                                                required={true}
                                                maxLength={40}
                                                label="Name your saved filters"
                                                control={control}
                                                name="name"
                                                errors={errors}
                                                onChange={(event) =>
                                                    setName(event.target.value)
                                                }
                                                value={name}
                                                defaultValue={name}
                                            />
                                        </FormField>
                                        <Action>
                                            <Link
                                                variant="secondary"
                                                onClick={() => handleClose()}
                                            >
                                                <Text variant="smallLight">
                                                    Cancel
                                                </Text>
                                            </Link>
                                            <Button
                                                onClick={async () => {
                                                    const valid =
                                                        await trigger();
                                                    if (valid) {
                                                        handleSave();
                                                    }
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Action>
                                    </>
                                )}
                                {mode === formModes.DELETE && (
                                    <>
                                        <Text variant="xlarge">{title}</Text>
                                        <FormField>
                                            <Text variant="smallLight">
                                                You are about to delete{" "}
                                                <strong>{name}</strong>.<br />
                                                <br />
                                                Do you wish to continue?
                                            </Text>
                                        </FormField>
                                        <Action>
                                            <Link
                                                variant="secondary"
                                                onClick={handleClose}
                                            >
                                                <Text variant="smallLight">
                                                    Cancel
                                                </Text>
                                            </Link>
                                            <Button
                                                onClick={async () => {
                                                    handleDelete();
                                                }}
                                            >
                                                Yes, Delete
                                            </Button>
                                        </Action>
                                    </>
                                )}
                                {mode === formModes.ADDED && (
                                    <>
                                        <Text variant="xlarge">
                                            Filters Saved
                                        </Text>
                                        <FormField>
                                            <Text variant="smallLight">
                                                <strong>{name}</strong> has been
                                                saved.
                                            </Text>
                                        </FormField>
                                        <Action>
                                            <Button onClick={handleClose}>
                                                View Filters
                                            </Button>
                                        </Action>
                                    </>
                                )}
                                {mode === formModes.UPDATED && (
                                    <>
                                        <Check size="24" />
                                        <Text variant="xlarge">
                                            Filters Renamed
                                        </Text>
                                        <FormField>
                                            <Text variant="smallLight">
                                                <strong>{previousName}</strong>{" "}
                                                has been renamed to{" "}
                                                <strong>{name}</strong>.
                                            </Text>
                                        </FormField>
                                        <Action>
                                            <Button onClick={handleClose}>
                                                View Filters
                                            </Button>
                                        </Action>
                                    </>
                                )}
                                {mode === formModes.DELETED && (
                                    <>
                                        <Text variant="xlarge">
                                            Filters Deleted
                                        </Text>
                                        <FormField>
                                            <Text variant="smallLight">
                                                <strong>{name}</strong> has been
                                                deleted.
                                            </Text>
                                        </FormField>
                                        <Action>
                                            <Button onClick={handleClose}>
                                                View Filters
                                            </Button>
                                        </Action>
                                    </>
                                )}
                            </Content>
                        </ContentWrapper>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default CustomFilter;
