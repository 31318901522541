import React, { useState } from "react";
import styled, { css } from "styled-components/macro";

import Text from "components/design-system/ui/Text";
import VStack from "components/design-system/layout/VStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon from "components/design-system/ui/Icon";
import Link from "components/design-system/ui/Link";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";

const Wrapper = styled(VStack)`
    padding: 16px 0px;
    gap: 16px;
`;

const SideNav = styled(Link)`
    display: flex;

    align-items: center;
    padding: 12px 16px;
    gap: 4px;

    width: 192px;
    height: 44px;

    background: ${COLOR_VALUES.White};
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    color: ${COLOR_VALUES.Gray_1};

    &:hover {
        background: ${COLOR_VALUES.Gray_8};
        text-decoration: none;
    }

    ${(props) =>
        props.active &&
        css`
            color: ${COLOR_VALUES.Black};
            border-left: 2px solid ${COLOR_VALUES.Black};
            background: ${COLOR_VALUES.Gray_8};
            border-bottom: none;
        `}
`;

const StyledText = styled(Text)`
    font-variation-settings: "opsz" 18;
    width: 136px;
`;

const SideNavWrapper = ({
    data,
    isActive = 0,
    setActiveTab = () => void 0,
    isHover = -1,
    setIsHover = () => void 0,
}) => {
    return (
        <>
            <SideNav
                active={data.isInternalUrlNewTab ? null : isActive === data.id}
                onClick={() =>
                    data.isInternalUrlNewTab ? void 0 : setActiveTab(data.id)
                }
                onMouseEnter={() => setIsHover(data.id)}
                onMouseLeave={() => setIsHover(-1)}
                to={data.to}
                href={data.href}
                isInternalUrlNewTab={data.isInternalUrlNewTab}
            >
                <StyledText variant="Headline_5" active={isActive === data.id}>
                    {data.label}
                </StyledText>
                {(isHover === data.id || isActive === data.id) && (
                    <Icon name={data.icon} color="Gray_1" />
                )}
            </SideNav>
        </>
    );
};

const DashboardSideNav = ({ sideNavTabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isHover, setIsHover] = useState(-1);
    return (
        <Wrapper className="dashboard-side-nav-wrapper">
            <VStack spacing="Two">
                {sideNavTabs.map((d) => (
                    <VStack key={d.title}>
                        {d.title && (
                            <Text variant="Headline_5" color="Gray_1">
                                {d.title}
                            </Text>
                        )}

                        <VStack spacing="Zero">
                            {d.tabs.map((data) =>
                                data.code ? (
                                    <ActionPermissionGuard
                                        code={data.code}
                                        key={data.id}
                                    >
                                        <SideNavWrapper
                                            data={data}
                                            isHover={isHover}
                                            setIsHover={setIsHover}
                                            isActive={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                    </ActionPermissionGuard>
                                ) : (
                                    <SideNavWrapper data={data} key={data.id} />
                                )
                            )}
                        </VStack>
                    </VStack>
                ))}
            </VStack>
        </Wrapper>
    );
};

export default DashboardSideNav;
