import React from "react";
import { useWatch } from "react-hook-form";

import VStack from "components/design-system/layout/VStack";
import Options from "components/design-system/forms/Options";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import AccessGroupMemberFormShowDropdown from "pages/admin/access-groups/single/AccessGroupMemberFormShowDropdown";

import { sendNotification } from "utilities/Notification";
import useAccessGroupMembersApi, {
    useAccessGroupMembersApiEmployees,
    useAccessGroupMembersApiDepartments,
} from "pages/admin/access-groups/single/useAccessGroupMembers";

const memberTypeNameOptions = [
    { label: "Add Employee", value: "Employee" },
    { label: "Add Department", value: "Department" },
];

function transformMemberDataToFormData(memberData = {}) {
    switch (memberData.type) {
        case "U":
            return {
                employee: memberData.memberId,
                memberTypeName: memberTypeNameOptions[0].value,
            };

        case "D":
            return {
                department: memberData.memberId,
                memberTypeName: memberTypeNameOptions[1].value,
            };
    }
}

function transformFormDataToMemberData(formData = {}, memberData = {}) {
    const newMemberData = { ...memberData };

    switch (formData.memberTypeName) {
        case memberTypeNameOptions[0].value:
            newMemberData.memberId = formData.employee;
            newMemberData.type = "U";
            return newMemberData;

        case memberTypeNameOptions[1].value:
            newMemberData.memberId = formData.department;
            newMemberData.type = "D";
            return newMemberData;
    }
}

function getDepartmentsDropdownOptions(departments = []) {
    return departments.map((department) => ({
        value: department.Id,
        label: department.Name,
    }));
}

function getEmployeesDropdownOptions(employees = []) {
    return employees.map((employee) => ({
        label: employee.FullName,
        value: employee.NewId,
    }));
}

const ModalCTA = ({ isLoading, onClose }) => {
    const { memberTypeName, employee, department } = useWatch();

    const isDisabled =
        memberTypeName === memberTypeNameOptions[0].value
            ? !employee
            : memberTypeName === memberTypeNameOptions[1].value
            ? !department
            : true;

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                disabled: isDisabled,
                label:
                    memberTypeName === memberTypeNameOptions[0].value
                        ? "Add Employee"
                        : memberTypeName === memberTypeNameOptions[1].value
                        ? "Add Department"
                        : "Add",
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

const AccessGroupMemberForm = ({ accessGroupId, accessGroupName, onClose }) => {
    const { useAccessGroupMembers } = useAccessGroupMembersApi();
    const memberData = useAccessGroupMembers(accessGroupId);

    const { createAccessGroupMember } = useAccessGroupMembersApi();
    const { employees } = useAccessGroupMembersApiEmployees();
    const { departments } = useAccessGroupMembersApiDepartments();

    if (departments.isError || employees.isError || memberData.isError) {
        return <div>Error</div>;
    }

    const modifier = createAccessGroupMember;

    const currentMemberData = {};

    const existingEmployees = !memberData.isLoading
        ? memberData.data
              .filter((emp) => emp.type === "U")
              .map((d) => ({
                  value: d.memberId,
              }))
        : [];

    const existingDepartments = !memberData.isLoading
        ? memberData.data
              .filter((dept) => dept.type === "D")
              .map((data) => ({
                  value: data.memberId,
              }))
        : [];

    const departmentsDropdownOptions = getDepartmentsDropdownOptions(
        departments.data
    );

    const employeesDropdownOptions = getEmployeesDropdownOptions(
        employees.data
    );

    const getMemberName = (id) => {
        return employeesDropdownOptions.find((emp) => emp.value === id);
    };

    const getDepartmentName = (id) => {
        return departmentsDropdownOptions.find((dept) => dept.value === id);
    };

    const onSubmit = (formData) => {
        let memberName = null;
        if (formData.memberTypeName === memberTypeNameOptions[0].value) {
            memberName = getMemberName(formData.employee).label;
        } else if (formData.memberTypeName === memberTypeNameOptions[1].value) {
            memberName = getDepartmentName(formData.department).label;
        }

        const membersData = transformFormDataToMemberData(
            formData,
            currentMemberData
        );

        const allData = { groupId: accessGroupId, body: membersData };

        modifier.mutateAsync(allData).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>
                    <b>{memberName}</b> has been added to{" "}
                    <b>{accessGroupName}</b>.
                </>
            );
        });
    };

    return (
        <Form
            style={{ overflowY: "auto", height: "500px" }}
            onSubmit={onSubmit}
            defaultValues={transformMemberDataToFormData(currentMemberData)}
        >
            <VStack spacing="Three">
                <VStack style={{ minHeight: 350 }}>
                    <Options
                        name="memberTypeName"
                        label="Are you adding an Employee or a Department?"
                        options={memberTypeNameOptions}
                        multiselect={false}
                    />
                    <AccessGroupMemberFormShowDropdown
                        employeesDataLoading={
                            employees.isLoading || memberData.isLoading
                        }
                        departmentsDataLoading={
                            departments.isLoading || memberData.isLoading
                        }
                        employeesDropdownOptions={employeesDropdownOptions}
                        departmentsDropdownOptions={departmentsDropdownOptions}
                        existingEmployeesList={existingEmployees}
                        existingDepartmentsList={existingDepartments}
                    />
                </VStack>
                <ModalCTA isLoading={modifier.isLoading} onClose={onClose} />
            </VStack>
        </Form>
    );
};

export default AccessGroupMemberForm;
