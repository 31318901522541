import React, { useEffect, useRef, useState } from "react";
import Text from "../UI/Text";
import styled from "styled-components";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DownCaret from "components/Icon/DownCaret";
import IconButton from "@material-ui/core/IconButton";
import { truncateText } from "utilities/TruncateText";
import { useStylesForMenu } from "../../styles/MakeStyles";
import "@szhsin/react-menu/dist/index.css";
import MoreMenu from "./MoreMenu";
import { MenuItem, Popper } from "@material-ui/core";

const Container = styled.div`
    svg {
        font-size: ${global.config.fonts.sizes.xsmall};
    }
    ${(props) =>
        props.border &&
        `
        border: ${global.config.border.small};
    `}
    width:100%;
    height: 100%;
    border-radius: 0.1875rem;
    &:hover {
        border: ${global.config.border.smallBlack};
    }
`;
const Trigger = styled.div`
    cursor: pointer;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    border-radius: 0.1875rem;
    height: 100%;
    ${(props) =>
        props.width &&
        `
        width: ${props.width};
    `}

    & > p {
        width: 93%;
        align-self: center;
    }
`;

const SelectedLabel = styled.span`
    > span {
        color: ${global.config.colors.gray2};
    }
    width: 81%;
    font-size: ${global.config.fonts.sizes.smedium};
    display: flex;
    align-items: center;
`;

const DownCaretWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    overflow: hidden;
`;

const MenuItemLabel = styled.div`
    width: 100%;
`;

export const SelectMenu = (props) => {
    const {
        triggerLength,
        label,
        items,
        itemAction,
        clearAction,
        moreMenuOptions,
        border,
        reload,
    } = props;
    const classes = useStylesForMenu();
    const [selectedItem, setSelectedItem] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const ref = useRef(null);

    useEffect(() => {
        document
            .getElementsByClassName("modal")[0]
            ?.removeAttribute("tabindex");
    }, []);

    useEffect(() => {
        let isSelected = false;
        if (items.length > 0) {
            items.forEach(function (item) {
                if (item.default && item.default === true) {
                    isSelected = true;
                    setSelectedItem(item);
                }
            });
            if (!isSelected) {
                setSelectedItem(null);
            }
        }
    }, [items, reload]);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopupClose = () => {
        if (anchorEl !== null) {
            setAnchorEl(null);
        }
    };

    const clearSelection = (e) => {
        e.stopPropagation();
        setSelectedItem(null);
        if (clearAction) {
            clearAction(e);
        }
    };

    const listItems = items.map((item, index) => (
        <MenuItem
            key={index}
            value={item.id}
            selected={selectedItem && selectedItem.id === item.id}
            onClick={(e) => (e.keepOpen = true)}
        >
            <MenuItemLabel
                onClick={function () {
                    setSelectedItem(item);
                    itemAction(item);
                    handlePopupClose();
                }}
            >
                {truncateText(item.name, 165)}
            </MenuItemLabel>
            {moreMenuOptions && (
                <MoreMenu
                    key={"moremenu" + index}
                    itemId={item.id}
                    itemName={item.name}
                    closeParent={handlePopupClose}
                    portal={true}
                    notHover={true}
                >
                    {moreMenuOptions}
                </MoreMenu>
            )}
        </MenuItem>
    ));

    return (
        <ClickAwayListener onClickAway={handlePopupClose}>
            <Container border={border}>
                <Trigger onClick={handleClick} width={triggerLength} ref={ref}>
                    {selectedItem ? (
                        <>
                            <SelectedLabel>{selectedItem.name}</SelectedLabel>
                            <IconButton
                                aria-label="clear"
                                onClick={clearSelection}
                                size="small"
                            >
                                X
                            </IconButton>
                        </>
                    ) : (
                        <Text variant="smallLight">{label}</Text>
                    )}
                    <DownCaretWrapper>
                        <DownCaret size="16"></DownCaret>
                    </DownCaretWrapper>
                </Trigger>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopupClose}
                    className={classes.selectMenu}
                >
                    {listItems}
                </Popper>
            </Container>
        </ClickAwayListener>
    );
};

SelectMenu.defaultProps = {
    triggerLength: "11rem",
};

SelectMenu.propTypes = {
    items: PropTypes.any.isRequired,
    moreMenuOptions: PropTypes.node,
};

export default SelectMenu;
