import React, { Fragment, useEffect, useRef } from "react";
import styled from "styled-components";

import TextInput from "components/design-system/controls/input/TextInput";
import {
    DropdownListLoader,
    DropdownListContainer,
    DropdownListContainerUL,
    DropdownListContainerLIWrapper,
    DropdownListContainerLI,
} from "components/design-system/controls/dropdown/DropdownStyledComponents";
import { COLOR_VALUES } from "components/design-system/config/colors";
import DropdownListVariant from "components/design-system/controls/dropdown-multi-select/DropdownListVariant";

const labelInListStyles = `margin-bottom: 0;
display: flex;
align-items: center;
justify-content: flex-start;`;

const StyledDropdownListContainerLIWrapper = styled(
    DropdownListContainerLIWrapper
)`
    padding-right: 4px;

    label {
        ${labelInListStyles}
    }
`;

const DropdownListContainerLIStyled = styled(DropdownListContainerLI)`
    cursor: default;

    div {
        width: inherit;
    }

    label {
        width: 100%;
        cursor: pointer;
    }
`;

const StyledUL = styled.ul`
    list-style-type: none;
    padding-inline-start: 16px;

    li {
        padding: 8px 6px;
    }

    li:first-child {
        padding-top: 6px;
    }

    li:last-child {
        border-bottom-color: ${COLOR_VALUES.Gray_5};
        margin-bottom: 4px;
    }
`;

const DropdownMultiSelectChildren = ({
    isVariantToggle,
    itemChildren,
    getItemProps,
    selectItem,
    // highlightedIndex,
}) => {
    return (
        <>
            <StyledUL>
                {itemChildren?.map((item, index) => (
                    <DropdownListContainerLIStyled
                        // isTraversed={highlightedIndex === index ? true : false}
                        key={item.childOptionIndex ?? index}
                        {...getItemProps({
                            key: item.childOptionIndex ?? index,
                            item,
                            index: item.childOptionIndex ?? index,
                        })}
                    >
                        <DropdownListVariant
                            isVariantToggle={isVariantToggle}
                            id={`downshift-0-item-${
                                item.childOptionIndex ?? index
                            }id`}
                            htmlFor={`downshift-0-item-${
                                item.childOptionIndex ?? index
                            }id`}
                            checked={item.check}
                            onChange={() => {
                                selectItem(item);
                            }}
                            label={item.label}
                        />
                    </DropdownListContainerLIStyled>
                ))}
            </StyledUL>
        </>
    );
};

const DropdownMultiSelectList = ({
    isVariantToggle,
    disabled = false,
    isOpen,
    optionsList,
    optionsAreLoading = false,
    filterValue,
    setFilterValue,
    internalFilterUIShown,
    getMenuProps,
    getInputProps,
    getDropdownProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    isBorder = true,
    position = "absolute",
}) => {
    const filterInputRef = useRef();
    const listScrollerRef = useRef();
    const pauseBlurEvent = useRef(false);

    // focuses list's filter field on open. that focus causes
    // a blur on the menu, so pause Downshift's built-in blur
    // action to keep the menu from closing
    useEffect(() => {
        if (isOpen && internalFilterUIShown) {
            setTimeout(() => {
                pauseBlurEvent.current = true;
                filterInputRef.current?.focus();
                pauseBlurEvent.current = false;
            });
        }
    }, [isOpen, internalFilterUIShown]);

    useEffect(() => {
        listScrollerRef.current.scrollTop = 0;
    }, [filterValue]);

    return (
        <>
            <DropdownListContainer
                hidden={
                    !(
                        isOpen &&
                        (filterValue.length > 0 || optionsList.length > 0)
                    )
                }
                className="dropdown-list-container"
                position={position}
            >
                {optionsAreLoading && <DropdownListLoader />}

                <DropdownListContainerUL
                    hidden={optionsAreLoading}
                    {...getMenuProps({
                        onBlur: (event) => {
                            if (pauseBlurEvent.current) {
                                event.nativeEvent.preventDownshiftDefault = true;
                            }
                        },
                    })}
                    className="dropdown-list-container-ul"
                >
                    <div
                        {...getInputProps(
                            getDropdownProps({
                                preventKeyAction: isOpen,
                                disabled: disabled,
                                placeholder: "Search...",
                            })
                        )}
                        style={{ display: "none" }}
                    ></div>
                    {internalFilterUIShown && (
                        <>
                            <TextInput
                                type="search"
                                placeholder="Search..."
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)}
                                css="margin: -1px -1px 0 -1px; width: calc(100% + 2px);"
                                ref={filterInputRef}
                                onKeyDown={(e) => {
                                    if (e.code === "Space") {
                                        e.stopPropagation();
                                    }
                                }}
                                showClearIcon={true}
                            />
                        </>
                    )}

                    <StyledDropdownListContainerLIWrapper ref={listScrollerRef}>
                        {optionsList?.length !== 0 ? (
                            optionsList.map((item, index) => (
                                <Fragment key={index}>
                                    <DropdownListContainerLIStyled
                                        isBorder={isBorder}
                                    >
                                        <DropdownListVariant
                                            isVariantToggle={isVariantToggle}
                                            isTraversed={
                                                highlightedIndex === index
                                                    ? true
                                                    : false
                                            }
                                            key={`${item.label}${index}`}
                                            {...getItemProps({
                                                key: item.id,
                                                item,
                                                index: item.parentOptionIndex,
                                            })}
                                            indeterminate={item?.indeterminate}
                                            checked={item.check}
                                            label={item.label}
                                        />
                                    </DropdownListContainerLIStyled>
                                    <DropdownMultiSelectChildren
                                        // highlightedIndex={highlightedIndex}
                                        isVariantToggle={isVariantToggle}
                                        selectItem={selectItem}
                                        itemChildren={optionsList[
                                            index
                                        ].children?.filter(
                                            (f) => f.parentId === item.id
                                        )}
                                        getItemProps={getItemProps}
                                    />
                                </Fragment>
                            ))
                        ) : (
                            <DropdownListContainerLI>
                                No matches found
                            </DropdownListContainerLI>
                        )}
                    </StyledDropdownListContainerLIWrapper>
                </DropdownListContainerUL>
            </DropdownListContainer>
        </>
    );
};

export default DropdownMultiSelectList;
