import React, { useState } from "react";
import PropTypes from "prop-types";
import env from "react-dotenv";
import Text, { TEXT_VARIANT_STYLES } from "../design-system/ui/Text";
import VStack from "../design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import styled from "styled-components";
import Tooltip from "components/design-system/ui/Tooltip";

const EmailLink = styled.a`
    color: ${COLOR_VALUES.Blue_3};
    ${TEXT_VARIANT_STYLES.Body_2_1};
`;

const StyledLinkText = styled(Text)`
    cursor:pointer;
    color: ${COLOR_VALUES.Blue_3};
    ${TEXT_VARIANT_STYLES.Body_2_1};
`

const TooltipText = ({ label }) => {
    const [copy, setCopy] = useState("Copy email");

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(env.POWERBI_EMAIL);
            setCopy("Copied to clipboard");
        }
    };
    return (
        <Tooltip text={copy} enterDelay={0} placement="bottom-start">
            <div>
                <StyledLinkText
                    onClick={() => {
                        copyToClipboard();
                    }}
                >
                    {label}
                </StyledLinkText>
            </div>
        </Tooltip>
    )
}

const ProvideFeedback = ({ title, description, linkText, link, label }) => {
    return (
        <>
            <VStack style={{ paddingTop: "32px" }}>
                <HStack
                    style={{
                        background: `${COLOR_VALUES.Gray_1}`,
                        padding: "32px",
                    }}
                >
                    <VStack spacing="Zero_5">
                        {title && (
                            <Text variant="Headline_2" color="Gray_8">
                                {title}
                            </Text>
                        )}
                        {description && (
                            <Text variant="Body_2_1" color="Gray_8">
                                {description}
                            </Text>
                        )}
                        {link && (
                            <EmailLink href={`mailto:${link}`}>
                                {linkText}
                            </EmailLink>
                        )}
                        {label && (
                            <TooltipText label={label} />
                        )}
                    </VStack>
                </HStack>
            </VStack>
        </>
    );
};

ProvideFeedback.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
};

export default ProvideFeedback;
