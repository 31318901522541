import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import env from "react-dotenv";

export const useInsigths = () => {
    const appInsights = useAppInsightsContext();

    const insightsLog = async (event, operation, message, properties) => {
        if (properties !== undefined){
            properties.environment = env.ENVIRONMENT_NAME;
        }
        const log = {
            name: event,
            operation: operation,
            message: message,
            properties: properties,
        };
        if (appInsights !== undefined) {
            console.log(
                "Insights event log",
                log
            );  
            appInsights.trackEvent(log);
        } else {
            console.log("Insights event log fails");
        }
    };

    const insightsException = async (error) => {
        if (appInsights !== undefined) {
            console.error("Insights exception trace", error);
            appInsights.trackException({ error: error, severityLevel: 0 });
        } else {
            console.log("Insights exception trace fails");
        }
    };

    const insightsTrace = async (prefix, message, properties) => {
        if (properties !== undefined){
            properties.environment = env.ENVIRONMENT_NAME;
        }
        const trace = {
            name: `${prefix}-${message}`,
            properties: properties,
        };
        if (appInsights !== undefined) {
            console.log(
                "Insights event trace",
                trace
            );
            appInsights.trackEvent(trace);
        } else {
            console.log("Insights event trace fails");
        }
    };

    return { insightsLog, insightsException, insightsTrace };
};
