import { useApi } from "utilities/API/Helpers/useApi";

const useProjectPlannerAPI = () => {
    const { getDataAsync } = useApi();

    const getProjectPlannerApiCall = async (projectId) => {
            const response = await getDataAsync("getProjectById", {
                urlParams: [
                    { name: "$projectId", value: projectId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        };

    const getProjectPlannerDataApiCall = async (projectId) => {
            const response = await getDataAsync("projectPlannerData", {
                urlParams: [
                    { name: "$projectId", value: projectId },
                ],
            });
            return response.status != 204 ? response.json() : null;
        };

    return {
        getProjectPlannerApiCall,
        getProjectPlannerDataApiCall,
    };
};

export default useProjectPlannerAPI;
