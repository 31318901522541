import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

export const useOfficesHolidaysTab = () => {
    const { getDataAsync } = useApi();

    const getAgenciesApiCall = useCallback(async () => {
        const response = await getDataAsync("holidaysTabActiveOffices");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const activeOffices = useQuery("activeOfficesList", getAgenciesApiCall);

    return useMemo(() => {
        return {
            activeOffices,
        };
    }, [activeOffices]);
};

const useTSHolidays = () => {
    const { getDataAsync } = useApi();

    const getHolidaysTabApiCall = useCallback(
        async ({ officeId, year }) => {
            const response = await getDataAsync("holidaysTab", {
                urlParams: [
                    { name: "$officeId", value: officeId },
                    {
                        name: "$year",
                        value: year,
                    },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useHolidaysTab = (dataObj) =>
            useQuery(["timesheetHolidays", dataObj], () =>
                getHolidaysTabApiCall(dataObj)
            );

        return {
            useHolidaysTab,
        };
    }, [getHolidaysTabApiCall]);
};

export default useTSHolidays;
