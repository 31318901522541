import React from "react";
import PropTypes from "prop-types";

const FormFeedbackNotificationText = ({
    responseStatus = null,
    isEditing = false,
    isDeleting = false,
    name = "",
}) => {
    const responseIsOk = responseStatus === 200;
    const successText = isEditing ? "updated" : "added";
    const errorText = isEditing ? "update the" : "add the";
    return (
        <>
            {responseIsOk ? (
                <>
                    <strong>{name}</strong> has been{" "}
                    {isDeleting ? "removed" : successText}
                </>
            ) : (
                <>
                    Couldn't {isDeleting ? "remove the" : errorText}{" "}
                    <strong>{name}</strong>
                </>
            )}
        </>
    );
};

FormFeedbackNotificationText.propTypes = {
    responseStatus: PropTypes.number,
    isEditing: PropTypes.bool,
    name: PropTypes.string,
};

export default FormFeedbackNotificationText;
