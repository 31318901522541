import styled from "styled-components";

const Wrapper = styled.div`
    background-color: #dadbde;
    text-align: center;
    text-transform: uppercase;
    font-family: ${global.config.fonts.families.bold};
    font-size: ${global.config.fonts.sizes.xxsmall};
    color: ${global.config.colors.black};
    height: 0.9rem;
    padding: 0 0.3rem;
    width: auto;
    border-radius: 0.1rem;
    ${(props) =>
        props.circle &&
        `
        border-radius: 1rem;
    `}
`;

const Tag = (props) => {
    return (
        <Wrapper
            circle={props.circle}
            className={props.className}
            style={props.style}
        >
            {props.children}
        </Wrapper>
    );
};

export default Tag;
