import { useMemo } from "react";
import { useApi } from "./Helpers/useApi";
import orderBy from "lodash/orderBy";
import { batchOperation } from "../../resources/Enums";
import { useAllocations } from "utilities/API/Allocations";

export const useCustomGroups = () => {
    const { getDataAsync, postData, putData, deleteData } = useApi();
    const { getAllocationsUsersIds } = useAllocations();

    const getMyCustomGroups = async (_userId) => {
        return getDataAsync("getCustomGroupsSelf").then((payload) => {
            return payload.json().then((data) => {
                return orderBy(
                    data,
                    [(user) => user.Name.toLowerCase()],
                    ["asc"]
                );
            });
        });
    };

    const getAllCustomGroups = async () => {
        return getDataAsync("getCustomGroups").then((payload) => {
            return payload.json().then((data) => {
                return orderBy(
                    data,
                    [(user) => user.Name.toLowerCase()],
                    ["asc"]
                );
            });
        });
    };

    const getCustomGroup = async (urlParams) => {
        return getDataAsync("getCustomGroup", {
            urlParams: urlParams,
        }).then((payload) => {
            return payload.json().then((data) => {
                return data;
            });
        });
    };

    const validateCustomGroup = async (urlParams) => {
        return getDataAsync("validateCustomGroup", {
            params: urlParams,
        });
    };

    const createCustomGroup = async (
        name,
        description,
        userIds,
        selectedAll,
        summaryQuery
    ) => {
        if (selectedAll) {
            userIds = await getAllocationsUsersIds(summaryQuery);
        }

        const body = {
            name: name,
            description: description,
            userIds: userIds,
        };

        return postData("createCustomGroup", {
            body: body,
        }).then((payload) => {
            return payload.json();
        });
    };

    const updateCustomGroup = async (body) => {
        return putData("updateCustomGroup", {
            body: body,
        });
    };

    const deleteCustomGroup = async (urlParams) => {
        return deleteData("deleteCustomGroup", {
            urlParams: urlParams,
        });
    };

    const addUserToCustomGroup = async (
        customGroupId,
        usersIds,
        skipDuplicatedUsers
    ) => {
        const urlParams = [
            {
                name: "$customgroupid",
                value: customGroupId,
            },
        ];

        return postData("staffingCustomGroup", {
            body: usersIds,
            urlParams: urlParams,
            params: {
                operation: batchOperation.add,
                skipDuplicatedUsers: skipDuplicatedUsers,
            },
        });
    };

    const removeUserFromCustomGroup = async (customGroupId, usersIds) => {
        const urlParams = [
            {
                name: "$customgroupid",
                value: customGroupId,
            },
        ];

        return postData("staffingCustomGroup", {
            body: usersIds,
            urlParams: urlParams,
            params: {
                operation: batchOperation.remove,
            },
        });
    };

    return useMemo(() => {
        return {
            getMyCustomGroups,
            getAllCustomGroups,
            getCustomGroup,
            validateCustomGroup,
            createCustomGroup,
            updateCustomGroup,
            deleteCustomGroup,
            addUserToCustomGroup,
            removeUserFromCustomGroup,
        };
    }, []);
};
