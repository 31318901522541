import PropTypes from "prop-types";

const External = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#extclip0)">
                <path
                    d="M8.66797 2.2998H13.7042V7.33607"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M13.7054 9.21788V13.6997H2.30127V2.30078H6.7935"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
                <path
                    d="M13.7048 2.2998L8.59595 7.40861"
                    stroke={color}
                    strokeWidth="1.17"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="extclip0">
                    <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
External.defaultProps = {
    size: "16",
    viewBox: "16",
    color: global.config.colors.black,
};

External.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default External;
