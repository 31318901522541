import styled, { createGlobalStyle } from "styled-components";
import env from "react-dotenv";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isBefore } from "date-fns";

import ContactCard from "components/design-system/ui/drawer/ContactCard";
import VStack from "components/design-system/layout/VStack";
import BlockHead from "components/design-system/ui/BlockHead";
import HStack from "components/design-system/layout/HStack";
import Icon from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import ProjectsDashboardDetailsData from "pages/projects/project-dashboard/single/ProjectsDashboardDetailsData";
import Modal from "components/design-system/ui/modal/MasterModal";

import useRetainersDashboardUserDetails from "pages/projects/retainers-dashboard/single/useRetainersDashboardUserDetails";
import { useProjectById } from "pages/projects/project-dashboard/single/useProjectsDashboardData";
import { useProjectLeadAssignment } from "pages/projects/project-dashboard/single/useProjectsDashboardDetailsData";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import ProjectDashboardDetailsDataLeadProducerForm from "pages/projects/project-dashboard/single/ProjectDashboardDetailsDataLeadProducerForm";
import ProjectDashboardOverviewDataPISForm from "pages/projects/project-dashboard/single/ProjectDashboardOverviewDataPISForm";

const StyledContactCard = createGlobalStyle`
    .contact-card{
        width:max-content;
    }
`;

const DetailsWrapper = styled(VStack)`
    gap: 40px;
`;

const FormPresenter = () => {
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("leadProducerId");
    const parsedId = parseInt(id);

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "addLeadProducer") {
        return (
            <Modal title="Select Lead Producer" onRequestClose={onClose}>
                <ProjectDashboardDetailsDataLeadProducerForm
                    projectIdToAddEditLead={projectId}
                    key={projectId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal title="Select Lead Producer" onRequestClose={onClose}>
                <ProjectDashboardDetailsDataLeadProducerForm
                    projectIdToAddEditLead={projectId}
                    leadProducerId={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "editPIS") {
        return (
            <Modal
                title="Personal Information Status"
                onRequestClose={onClose}
                stickyAction={true}
            >
                <ProjectDashboardOverviewDataPISForm
                    projectIdToEditPIS={projectId}
                    key={projectId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const NoLeadProducerCardContent = () => {
    return (
        <>
            <VStack>
                <HStack>
                    <BlockHead title="Lead Producer" />

                    <TertiaryButton
                        to="?action=addLeadProducer"
                        replace={true}
                        aria-label="Add lead producer"
                        trailingIcon="edit"
                        iconWidth={16}
                        style={{
                            color: "currentColor",
                            background: "transparent",
                        }}
                    />
                </HStack>
                <VStack spacing="Zero_5">
                    <HStack spacing="Zero_25">
                        <Icon size={16} name="warning" color="Yellow" />
                        <Text color="Black" variant="Body_2_2">
                            No Lead Producer
                        </Text>
                    </HStack>
                </VStack>
            </VStack>
        </>
    );
};

const ProjectsDashboardDetails = () => {
    const { projectId } = useParams();
    const { project } = useProjectById();
    const { useLeadAssignment } = useProjectLeadAssignment();
    const leadProducer = useLeadAssignment(projectId);
    const [leadProducerId, setLeadProducerId] = useState();
    const { useUserById } = useRetainersDashboardUserDetails();
    const userDetails = useUserById(leadProducerId);
    const actionProps = {
        to: `?action=edit&leadProducerId=${leadProducerId}`,
        replace: true,
        "aria-label": "Edit Lead Producer",
    };

    useEffect(() => {
        if (!leadProducer.isLoading && leadProducer?.data?.length !== 0) {
            const mostRecentDate = leadProducer?.data?.reduce((a, b) =>
                a.StartDate > b.StartDate ? a : b
            ).StartDate;
            const recentDate = new Date(mostRecentDate);
            const recentObject = leadProducer?.data?.filter((e) => {
                const date = new Date(e.StartDate);
                return date.getTime() == recentDate.getTime();
            })[0];            
            
            const isInactive = recentObject?.EndDate ? isBefore(new Date(recentObject?.EndDate), new Date()) : false;
            isInactive ? setLeadProducerId(null) : setLeadProducerId(recentObject?.EmployeeId);
        }
    }, [leadProducer]);

    if (userDetails.isLoading || leadProducer.isLoading || project.isLoading) {
        return <>Loading...</>;
    }

    return (
        <>
            <FormPresenter />
            <DetailsWrapper>
                {leadProducerId ? (
                    <>
                        <StyledContactCard />
                        <ContactCard
                            contactCardLabel="Lead Producer"
                            contactCardLabelVariant="Body_2_1"
                            photoUrl={
                                env.BESTWORK_REACT_ASSET_DOMAIN +
                                "/" +
                                userDetails?.data?.photoURL
                            }
                            name={userDetails?.data?.fullName}
                            jobPost={userDetails?.data?.currentTitle}
                            department={userDetails?.data?.currentDepartment}
                            location={userDetails?.data?.currentOffice}
                            emailId={userDetails?.data?.email}
                            actionIcon="edit"
                            hasAction={true}
                            actionProps={actionProps}
                        />
                    </>
                ) : (
                    <>
                        <NoLeadProducerCardContent />
                    </>
                )}
                <ProjectsDashboardDetailsData project={project} />
            </DetailsWrapper>
        </>
    );
};

export default ProjectsDashboardDetails;
