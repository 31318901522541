import React, { useState, useMemo } from "react";

const CustomGroupContext = React.createContext();

export const CustomGroupContextProvider = (props) => {
    const [selectedCustomGroup, setSelectedCustomGroup] = useState(null);
    const [selectCustomGroupOption, setSelectCustomGroupOption] = useState();

    const contextValue = useMemo(() => {
        return {
            selectedCustomGroup,
            selectCustomGroupOption,
            setSelectedCustomGroup,
            setSelectCustomGroupOption,
        };
    }, [selectedCustomGroup, selectCustomGroupOption]);

    return (
        <CustomGroupContext.Provider value={contextValue}>
            {props.children}
        </CustomGroupContext.Provider>
    );
};

export default CustomGroupContext;
