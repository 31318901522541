import { useState } from "react";
import { useParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import env from "react-dotenv";

import ContactCard, {
    CopyContainer,
} from "components/design-system/ui/drawer/ContactCard";
import VStack from "components/design-system/layout/VStack";
import BlockHead from "components/design-system/ui/BlockHead";
import HStack from "components/design-system/layout/HStack";
import Icon from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

import useRetainers from "pages/admin/retainers/useRetainers";
import useRetainersDashboardUserDetails from "pages/projects/retainers-dashboard/single/useRetainersDashboardUserDetails";
import RetainersDashboardDetailsData from "pages/projects/retainers-dashboard/single/RetainersDashboardDetailsData";

const StyledContactCard = createGlobalStyle`
    .contact-card{
        width:max-content;
    }
`;

const DetailsWrapper = styled(VStack)`
    gap: 40px;
`;

const NoLeadProducerCardContent = () => {
    const [copy, setCopy] = useState("Copy");

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText("reporter@rga.com");
            setCopy("Copied");
        }
    };
    return (
        <>
            <VStack>
                <BlockHead title="Lead Producer" />
                <VStack spacing="Zero_5">
                    <HStack spacing="Zero_25">
                        <Icon size={16} name="warning" color="Yellow" />
                        <Text color="Black" variant="Body_2_2">
                            No Lead Producer
                        </Text>
                    </HStack>
                    <HStack spacing="Zero_5">
                        <Text variant="Body_2_1" color="Gray_2">
                            Assign a Lead Producer to this Retainer by contacting
                            the
                        </Text>
                        <CopyContainer spacing="Zero_5">
                            <TertiaryButton
                                style={{ padding: "0", minHeight: "0" }}
                                trailingIcon="mail"
                                label="Reporter Team"
                                onClick={() => {
                                    copyToClipboard();
                                }}
                            />
                            <Text
                                variant="Body_2_1"
                                className="textMassage"
                                color="Gray_2"
                            >
                                {copy}
                            </Text>
                        </CopyContainer>
                    </HStack>
                </VStack>
            </VStack>
        </>
    );
};

const RetainersDashboardDetails = () => {
    const { retainerId } = useParams();
    const { useRetainerById } = useRetainers();
    const retainerData = useRetainerById(retainerId);
    const retainerInfo = retainerData?.data;

    const { useUserById } = useRetainersDashboardUserDetails();
    const userDetails = useUserById(retainerInfo?.LeadProducerID);

    if (retainerData.isLoading || userDetails.isLoading) {
        return <>Loading...</>;
    }

    if (retainerData.isError) {
        return <>Error</>;
    }

    return (
        <>
            <DetailsWrapper>
                {retainerInfo?.LeadProducerID && userDetails?.data?.active ? (
                    <>
                        <StyledContactCard />
                        <ContactCard
                            contactCardLabel="Lead Producer"
                            contactCardLabelVariant="Body_2_1"
                            photoUrl={
                                env.BESTWORK_REACT_ASSET_DOMAIN +
                                "/" +
                                userDetails?.data?.photoURL
                            }
                            name={userDetails?.data?.fullName}
                            jobPost={userDetails?.data?.currentTitle}
                            department={userDetails?.data?.currentDepartment}
                            location={userDetails?.data?.currentOffice}
                            emailId={userDetails?.data?.email}
                        />
                    </>
                ) : (
                    <>
                        <NoLeadProducerCardContent />
                    </>
                )}
                <RetainersDashboardDetailsData retainerData={retainerData} />
            </DetailsWrapper>
        </>
    );
};

export default RetainersDashboardDetails;
