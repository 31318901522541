import React, { useContext } from "react";
import { createGlobalStyle } from "styled-components";
import env from "react-dotenv";
import { Report } from "powerbi-report-component";
import Unauthorized from "pages/Unauthorized";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const BusinessIntelligencePageStyles = createGlobalStyle`
iframe{
    border: none;
}
`;

const PowerBIReport = ({ reportData }) => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();

    const now = new Date();
    insightsTrace("VIEW", "PowerBIPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    const reportStyle = {
        width: "100%",
        height: "90vh",
    };

    const settings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: 0,
    };

    const { token, dashboardId, reportId, pageName } = reportData;
    const embedURL = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${dashboardId}&w=2&${env.POWERBI_NAVIGATION_MAIN_REPORT_URL}`;

    return token && reportId && dashboardId ? (
        <>
            <BusinessIntelligencePageStyles />
            <Report
                tokenType="Aad"
                accessToken={token}
                embedUrl={embedURL}
                embedId={reportId}
                permissions="All"
                style={reportStyle}
                extraSettings={settings}
                pageName={pageName}
            />
        </>
    ) : (
        <Unauthorized />
    );
};

export default PowerBIReport;
