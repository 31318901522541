import React, { useMemo, useCallback, useReducer, useEffect, useState, } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocationState } from "react-router-use-location-state";

// Map of open employee rows
//  Keys: employee id
//  Values: Set of closed client ids

function reducer(oldState, action) {
    const { employeeId, clientId } = action.payload;

    // deep copy of Map to ensure correct rendering
    const openEmployeeRows = new Map();
    for (let [key, value] of oldState) {
        openEmployeeRows.set(key, new Set(value));
    }

    switch (action.type) {
        case "toggleEmployeeRow":
            if (openEmployeeRows.has(employeeId)) {
                openEmployeeRows.delete(employeeId);
            } else {
                openEmployeeRows.set(employeeId, new Set());
            }
            return openEmployeeRows;
        case "toggleClientRow":
            if (openEmployeeRows.has(employeeId)) {
                const closedClientRows = openEmployeeRows.get(employeeId);
                if (closedClientRows.has(clientId)) {
                    closedClientRows.delete(clientId);
                } else {
                    closedClientRows.add(clientId);
                }
            } else {
                openEmployeeRows.set(employeeId, [clientId]);
            }
            
            return openEmployeeRows;
        case "closeAllEmployeeRows":
            openEmployeeRows.clear();
            return openEmployeeRows;
        default:
            throw new Error("Invalid Action Type");
    }
}

export const EmployeeRowExpansionContextActions = React.createContext({
    toggleEmployeeRow: () => {
        /**/
    },
    toggleEmployeeClientRow: () => {
        /**/
    },
    closeAllEmployeeRows: () => {
        /**/
    },
});

export const EmployeeRowExpansionContextValue = React.createContext({
    isEmployeeRowOpen: () => false,
    isEmployeeClientRowOpen: () => false,
});

export const EmployeeRowExpansionContextProvider = (props) => {
    const [searchParams] = useSearchParams();
    const { state } = useLocation();

    const [initialState] = useState(() => {
        const initialStateData = new Map();
        let stateType = Array.isArray(state);

        if (state?.length !== 0 && stateType) {
            state?.map((data) => {
                initialStateData.set(data.key, new Set(data.value));
            });
        } else if (searchParams.has("userId")) {
            initialStateData.set(searchParams.get("userId"), new Set());
        }
        return initialStateData;
    });

    const [rowState, setRowState] = useLocationState(
        "rowExpansionState",
        initialState
    );

    const [reducerState, dispatch] = useReducer(reducer, rowState);

    let modifier = rowState.size === 0 ? reducerState : rowState;
    const isSessionExpired = localStorage.getItem("sessionExpired");

    useEffect(() => {
        setRowState(reducerState);
    }, [reducerState]);

    useEffect(() => {
        if (
            rowState.size === 0 &&
            reducerState.size !== 0 &&
            isSessionExpired
        ) {
            setRowState(reducerState);
        }
    }, [rowState, isSessionExpired]);

    const toggleEmployeeRow = useCallback((employeeId) => {
        dispatch({
            type: "toggleEmployeeRow",
            payload: { employeeId },
        });
    }, []);

    const toggleEmployeeClientRow = useCallback((employeeId, clientId) => {
        dispatch({
            type: "toggleClientRow",
            payload: { employeeId, clientId },
        });
    }, []);

    const closeAllEmployeeRows = useCallback((_employeeId, _clientId) => {
        dispatch({
            type: "closeAllEmployeeRows",
            payload: {},
        });
    }, []);

    const contextActions = useMemo(
        () => ({
            toggleEmployeeRow,
            toggleEmployeeClientRow,
            closeAllEmployeeRows,
        }),
        [closeAllEmployeeRows, toggleEmployeeClientRow, toggleEmployeeRow]
    );

    const isEmployeeRowOpen = useCallback(
        (employeeId) => { 
            return modifier.has(employeeId);
        },
        [modifier]
    );

    const isEmployeeClientRowOpen = useCallback(
        (employeeId, clientId) =>
            modifier.has(employeeId) && !modifier.get(employeeId).has(clientId),
        [modifier]
    );

    const contextValues = 
        {
            isEmployeeRowOpen,
            isEmployeeClientRowOpen,
        }
    ;

    return (
        <EmployeeRowExpansionContextActions.Provider value={contextActions}>
            <EmployeeRowExpansionContextValue.Provider value={contextValues}>
                {props.children}
            </EmployeeRowExpansionContextValue.Provider>
        </EmployeeRowExpansionContextActions.Provider>
    );
};
