import DropdownSingleSelectWithoutSearch from "components/design-system/controls/dropdown/DropdownSingleSelectWithoutSearch";
import FormInputWrapper from "../ui/FormInputWrapper";
import { useFormInput } from "./utils";

const Dropdown = ({
    name,
    validations,
    label,
    description,
    helpText,
    className,
    isSmallSize,
    disabled = false,
    onChange = () => void 0,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    const feedbackMessageData =
        !inputProps?.value && wrapperProps?.feedbackMessage;

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className, isSmallSize }}
            {...wrapperProps}
            feedbackMessage={feedbackMessageData}
            disabled={disabled}
        >
            <DropdownSingleSelectWithoutSearch
                {...rest}
                {...inputProps}
                onSelectedValue={onChange(inputProps?.value)}
                feedbackMessage={feedbackMessageData}
                disabled={disabled}
                isSmallSize={isSmallSize}
            />
        </FormInputWrapper>
    );
};

export default Dropdown;
