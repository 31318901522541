import { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import Text from "../../UI/Text";
import { useStylesForText } from "styles/MakeStyles";
import SelectRows from "./SelectRows";
import GridContext from "context/Allocations/Context";
import ActionPermissionGuard from "components/UI/ActionPermissionGuard";

const Wrapper = styled(Row)`
    border-bottom: ${global.config.border.small};
    margin: 0;
    background-color: ${global.config.colors.white};
    z-index: 100;
`;

const IndentColumn = styled(Col)`
    flex: 0 0 4%;
`;

const Column = styled(Col)`
    padding: 0;
    border-right: ${global.config.border.small};
    text-align: left;
    display: flex;
    align-items: center;

    &:last-child {
        border-right: none;
    }
`;

const ItemColum = styled(Col)`
    padding: 1em 0;
    border-right: ${global.config.border.small};
    display: grid;
    justify-content: center;
    align-content: center;

    &:last-child {
        border-right: none;
    }
`;

const getColumnLabels = (gridStartDate, columnCount) => {
    if (!gridStartDate) {
        return null; // Array(columnCount).fill({ description: "AA" });
    }
    let columnsHeaders = [];
    for (let i = 1; i <= columnCount; i++) {
        const startDate = moment(gridStartDate).add((i - 1) * 7, "days");
        const endDate = moment(startDate).add(6, "days");
        let week = "";
        if (moment(startDate).format("MMM") === moment(endDate).format("MMM")) {
            week = `${moment(startDate).format("MMM D")}-${moment(endDate).format("D")}`;
        } else {
            week = `${moment(startDate).format("MMM D")} - ${moment(endDate).format("MMM D")}`;
        }
        columnsHeaders.push({ description: week });
    }
    return columnsHeaders;
};

const Header = ({
    _columns,
    _onSelect,
    _onSelectAll,
    _onEnableRowsSelection,
    ..._selectionHandler
}) => {
    const classesText = useStylesForText();
    const { gridParams, columnsView } = useContext(GridContext);
    const [columnLabels, setColumnLabels] = useState(null)

    useEffect(() => {
        setColumnLabels(getColumnLabels(gridParams.dateFrom, columnsView));
    }, [gridParams.dateFrom, gridParams.dateTo])

    return (
        <>
            <Wrapper id="date_cols_wrapper" style={{paddingTop: "0px", position: "fixed", width: "100%"}}>
                <IndentColumn xs={1} />
                <Column xs={4} lg={3}>
                    <ActionPermissionGuard code="AC_SELECT_MULTIPLE_EMP"><SelectRows /></ActionPermissionGuard>
                </Column>
                {columnLabels && columnLabels.map((column) => 
                     (
                        <ItemColum key={column.description}>
                            <div style={{fontSize: "0.6rem"}}>{column.description}</div>
                        </ItemColum>
                    )
                )}
                <IndentColumn xs={1} />
            </Wrapper>
        </>
    );
};

export default Header;
