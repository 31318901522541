import { parseDate } from "@internationalized/date";
import RangeCalendarMain from "components/design-system/controls/RangeCalendar/RangeCalendarMain";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import { useFormInput } from "components/design-system/forms/utils";

const DateRangePicker = ({
    name,
    validations,
    label = "",
    description,
    helpText,
    className,
    onChange,
    disableDays,
    enableDaysFromDate,
    enableDaysUntilDate,
    minDate,
    maxWidth,
    ...rest
}) => {
    const { wrapperProps, inputProps } = useFormInput({
        name,
        validations,
        isControlled: true,
    });

    const confirmedChange = (dateValue) => {
        onChange(dateValue);
    };

    const feedbackMessageData =
        (!inputProps?.value || inputProps?.value) &&
        wrapperProps?.feedbackMessage;

    return (
        <FormInputWrapper
            {...{ label, description, helpText, className }}
            {...wrapperProps}
            feedbackMessage={feedbackMessageData}
            maxWidth={maxWidth}
        >
            <RangeCalendarMain
                {...rest}
                {...inputProps}
                label={label}
                feedbackType={inputProps.feedbackType}
                confirmedChange={confirmedChange}
                disableDays={disableDays}
                enableDaysFromDate={enableDaysFromDate}
                enableDaysUntilDate={enableDaysUntilDate}
                minDate={minDate}
                feedbackMessage={feedbackMessageData}
            />
        </FormInputWrapper>
    );
};

export default DateRangePicker;
