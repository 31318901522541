import styled from "styled-components/macro";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { SPACING_VALUES } from "components/design-system/config/spacing";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import PrimaryButton from "components/design-system/controls/button/PrimaryButton";

const DEFAULT_MESSAGE = "There doesn’t appear to be anything here.";

const Wrapper = styled(VStack)`
    border: 1px dashed ${COLOR_VALUES.Gray_5};
    border-radius: 4px;
    padding: ${SPACING_VALUES.Two};
`;

const EmptyState = ({
    message = DEFAULT_MESSAGE,
    title = null,
    actionProps,
    actionPropsForPrimary,
    actionPropsForTernaryButton,
    ...rest
}) => (
    <Wrapper align="center" {...rest}>
        <Text variant="Body_1_1" color="Gray_2" css="text-align: center">
            {title ? `There are no ${title} to show.` : message}
        </Text>
        {actionProps && <SecondaryButton {...actionProps} />}
        {actionPropsForPrimary && <PrimaryButton {...actionPropsForPrimary} />}
        {actionPropsForTernaryButton && (
            <TertiaryButton {...actionPropsForTernaryButton} />
        )}
    </Wrapper>
);

export default EmptyState;
