import { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { truncateText } from "utilities/TruncateText";
import MoreMenu from "./MoreMenu";

const MenuItemLabel = styled.div`
    width: 100%;
`;

const Wrapper = styled.div`
    background-color: ${(props) =>
        props.active ? global.config.colors.gray8 : "initial"};
`;

const CustomMenuItem = ({
    value,
    name,
    owned,
    moreMenuOptions,
    selectedItem,
    onClickItem,
    closeMenu,
    key,
}) => {
    const [active, setActive] = useState(false);

    const onItemActive = (_event) => {
        setActive(!active);
    };
    const onItemInactive = (_event) => {
        setActive(false);
    };

    return (
        <Wrapper active={active}>
            <MenuItem
                key={key}
                value={value}
                selected={selectedItem && selectedItem.Id === value}
            >
                <MenuItemLabel onClick={onClickItem}>
                    {truncateText(name, 205)}
                </MenuItemLabel>
                {moreMenuOptions && (
                    <MoreMenu
                        itemId={value}
                        itemName={name}
                        itemOwned={owned}
                        closeParent={closeMenu}
                        onOpen={(e) => onItemActive(e)}
                        onClose={(e) => onItemInactive(e)}
                        portal={true}
                    >
                        {moreMenuOptions}
                    </MoreMenu>
                )}
            </MenuItem>
        </Wrapper>
    );
};

CustomMenuItem.propTypes = {};

export default CustomMenuItem;
