import React from "react";
import { useModal, useOverlay } from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import styled, { css } from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";

const StyledPopOver = styled.div`
    background: white;
    padding: 0;
    overflow: auto;
    background: #ffffff;
    border: 1px solid #e5e8ec;
    border-radius: 4px;
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginTop}px;

    &::-webkit-scrollbar {
        width: 8px;
        border-left: 1px solid ${COLOR_VALUES.Gray_6};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${COLOR_VALUES.Gray_5};
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 999px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${COLOR_VALUES.Gray_6};
    }
`;

const RangeCalendarPopOver = React.forwardRef(
    (
        {
            _title,
            children,
            isOpen,
            onClose,
            style,
            marginTop = 20,
            ...otherProps
        },
        ref
    ) => {
        const { overlayProps } = useOverlay(
            {
                onClose,
                isOpen,
                isDismissable: true,
            },
            ref
        );
        const { modalProps } = useModal();
        const { dialogProps } = useDialog({}, ref);

        return (
            <FocusScope restoreFocus>
                <StyledPopOver
                    {...mergeProps(
                        overlayProps,
                        dialogProps,
                        otherProps,
                        modalProps
                    )}
                    ref={ref}
                    style={{
                        ...style,
                    }}
                    marginTop={marginTop}
                >
                    {children}
                </StyledPopOver>
            </FocusScope>
        );
    }
);

export default RangeCalendarPopOver;
