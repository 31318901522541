import React, { useContext } from "react";
import Loading from "components/UI/Loading";
import HStack from "components/design-system/layout/HStack";
import PageWrapper, { LoadingWrapper } from "pages/PageWrapper";
import { Card } from "pages/AdminMainPage";
import useAllPages from "pages/useAllPages";
import { usePageAccess } from "hooks/Access/usePageAccess";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

export const ProjectCard = ({ to, ...rest }) => {
    const { authorized } = usePageAccess(to);
    return !authorized ? <></> : <Card to={to} {...rest} />;
};

const ProjectsMainPage = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const { pages } = useAllPages();

    const now = new Date();
    insightsTrace("VIEW", "ProjectsMainPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    if (pages.isLoading) {
        return (
            <LoadingWrapper>
                <Loading text="Loading Page" />
            </LoadingWrapper>
        );
    }

    if (pages.isError) {
        return <>Error</>;
    }

    const adminModuleElements = pages.data.filter(
        (d) => d.module === "Projects"
    );

    const adminSections = adminModuleElements.filter(
        (element) => element.parentID
    );

    return (
        <PageWrapper title="Projects">
            <HStack spacing="Two" style={{ paddingTop: "32px" }}>
                {adminSections.map((data, index) => (
                    <ProjectCard
                        key={index}
                        title={data.name}
                        description={data.description}
                        to={data.route}
                    />
                ))}
            </HStack>
        </PageWrapper>
    );
};

export default ProjectsMainPage;
