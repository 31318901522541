import { useEffect, useState } from "react";
import Client from "../Client/Client";
import styled from "styled-components";
import getEmployeeKey from "./utils/getEmployeeKey";

const Wrapper = styled.div`
    border-bottom: ${global.config.border.small};
    transition: height 2s;
`;

const EmployeeDetail = ({ details, employee }) => {
    const [clients, setClients] = useState([])

    useEffect(() => {
        let clis = [];
        if (details != null){
            let flags = [], l = details.length, i;
            for(i=0; i < l; i++) {
                if(flags[details[i].clientId]) continue;
                flags[details[i].clientId] = true;
                const cli = {client: details[i].client, clientId: details[i].clientId, allocatedHours: 0};
                clis.push(cli);
            }
        }

        for(let c= 0; c < clis.length; c++){
            let allocatedHoursByClient = 0;
            const projClis = details.filter(d => d.clientId == clis[c].clientId);
            if (projClis) {
                projClis.forEach((projCli) => {
                    if (projCli.weeks){
                        projCli.weeks.forEach((week) => {
                            allocatedHoursByClient += (week.allocatedHours > 0 ? week.allocatedHours : 0);
                        })
                    }
                });
            }
            clis[c].allocatedHours = allocatedHoursByClient;
        }

        setClients(clis);
    }, [details])

    return (
        <>
            {
                details && clients ? 
                (
                    <Wrapper id={"div_detail_" + getEmployeeKey(employee)}>
                        {clients.map((item, index) => {
                                console.log("A", item)
                                return (
                                    <Client
                                        client={item.client}
                                        clientId={item.clientId}
                                        key={employee.userId + "_" + item.clientId}
                                        data={details.filter((x) => x.client == item.client)}
                                        employee={employee}
                                        index={index}
                                        opened={item.allocatedHours > 0 || item.clientId <= 0}
                                    />
                                )
                            })}
                    </Wrapper>
                ) : 
                <div style={{paddingLeft: "100px"}}>
                    No Projects
                </div>
            }
        </>
    );
};

export default EmployeeDetail;
