import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { MenuItem } from "@szhsin/react-menu";
import { makeStyles } from "@material-ui/core/styles";

import { COLOR_VALUES } from "components/design-system/config/colors";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import HStack from "components/design-system/layout/HStack";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text, { TEXT_VARIANTS } from "components/design-system/ui/Text";
import Tooltip from "components/design-system/ui/Tooltip";
import MoreMenu from "components/UI/MoreMenu";
import MenuItemButton from "components/design-system/controls/button/MenuItemButton";
import { moreMenuClassStyle } from "components/design-system/tables/moreMenuStyle";
import Dotdotdot from "react-dotdotdot";

const StyledTd = styled.td`
    padding: ${({ $compact }) =>
        $compact ? "2px 12px 1px 12px" : "6px 12px 5px 12px"};
    border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
    height: ${({ $compact }) => ($compact ? "40px" : "52px")};
    text-align: start;
    vertical-align: middle;
    color: ${(props) => (props.textColor ? props.textColor : COLOR_VALUES.Gray_1)} ;
    padding: ${(props) => props.padding};
    border-bottom-color: ${(props) => props.borderColor};

    ${({ $shownByRowHover }) =>
        $shownByRowHover &&
        css`
            > * {
                opacity: 0;
            }
        `}

    tr:hover &, tr:focus-within & {
        color: ${COLOR_VALUES.Black};
        border-bottom-color: ${COLOR_VALUES.Gray_4};

        ${({ $shownByRowHover }) =>
        $shownByRowHover &&
        css`
                > * {
                    opacity: inherit;
                }
            `}
    }
`;

const StyledText = styled(Text)`
    ${(props) =>
        !props.ellipsisAtLine &&
        css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `}
`;

const useStyles = makeStyles(() => ({
    moreMenu: moreMenuClassStyle,
}));

const BodyCell = ({
    text,
    description,
    icon = null,
    iconWidth,
    iconHeight,
    iconViewBox,
    iconColor,
    justifyContent = "start",
    actionIcon,
    actionProps = {},
    tooltip,
    placement,
    tooltipEnterDelay = 2000,
    compact = false,
    shownByRowHover = false,
    color,
    padding,
    borderColor,
    variant = "Body_2_1",
    moreMenuActionProps = [],
    ellipsisAtLine = null,
    expanded,
    actionIconWidth = 16,
    cellTextOnMouseEnter = () => void 0,
    cellTextOnTextMouseLeave = () => void 0,
    ...rest
}) => {
    const hasCellText = text || description;
    const hasAction =
        typeof actionProps === "object" &&
        ["to", "href", "onClick"].some((prop) =>
            Object.keys(actionProps).includes(prop)
        );
    const hasMoreMenu = moreMenuActionProps.length > 0 ? true : false;
    const classes = useStyles();

    return (
        <StyledTd
            $compact={compact}
            $shownByRowHover={shownByRowHover}
            padding={padding}
            borderColor={borderColor}
            {...rest}
        >
            <>
                <Tooltip
                    text={tooltip}
                    enabled={!!tooltip}
                    placement={placement}
                    enterDelay={tooltipEnterDelay}
                >
                    <HStack
                        align="center"
                        justify={hasCellText ? justifyContent : "center"}
                        spacing="Zero_5"
                    >
                        {icon && (
                            <Icon
                                name={icon}
                                color={iconColor}
                                width={iconWidth}
                                height={iconHeight}
                                viewBox={iconViewBox}
                            />
                        )}
                        {hasMoreMenu && (
                            <MoreMenu
                                alwaysVisible={true}
                                position="initial"
                                moreMenuClass={classes.moreMenu}
                            >
                                {moreMenuActionProps.map(
                                    (menuItemData, menuItemIndex) => (
                                        <MenuItem
                                            key={menuItemIndex}
                                            disabled={false}
                                        >
                                            <MenuItemButton
                                                {...menuItemData.actionProps}
                                                label={menuItemData.text}
                                                trailingIcon={
                                                    menuItemData.actionIcon
                                                }
                                                style={{
                                                    color: "currentColor",
                                                    ...(hasCellText && {
                                                        marginLeft: "-8px",
                                                        marginTop: "-4px",
                                                        marginBottom: "-4px",
                                                    }),
                                                }}
                                            />
                                        </MenuItem>
                                    )
                                )}
                            </MoreMenu>
                        )}
                        {(hasCellText || hasAction) && (
                            <>
                                {hasAction ? (
                                    <TertiaryButton
                                        {...actionProps}
                                        trailingIcon={actionIcon}
                                        iconWidth={actionIconWidth}
                                        label={text}
                                        style={{
                                            color: "currentColor",
                                            ...(hasCellText && {
                                                marginLeft: "-8px",
                                                marginTop: "-4px",
                                                marginBottom: "-4px",
                                                wordBreak: "break-all",
                                                background: "transparent",
                                            }),
                                        }}
                                    />
                                ) : (
                                    text && (
                                        <StyledText
                                            expanded={expanded}
                                            ellipsisAtLine={ellipsisAtLine}
                                            color={color}
                                            variant={variant}
                                            onMouseEnter={cellTextOnMouseEnter}
                                            onMouseLeave={
                                                cellTextOnTextMouseLeave
                                            }
                                        >
                                            {!ellipsisAtLine || expanded
                                                ? text
                                                : !expanded && (
                                                    <Dotdotdot
                                                        clamp={ellipsisAtLine}
                                                    >
                                                        {text}
                                                    </Dotdotdot>
                                                )}
                                        </StyledText>
                                    )
                                )}
                            </>
                        )}
                    </HStack>
                </Tooltip>
                {description && (
                    <Text variant="Descriptive_2_1" color="Gray_2">
                        {description}
                    </Text>
                )}
            </>
        </StyledTd>
    );
};

BodyCell.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.oneOf(ICON_NAMES),
    iconColor: PropTypes.oneOf(Object.keys(COLOR_VALUES)),
    justifyContent: PropTypes.string,
    actionIcon: PropTypes.oneOfType([
        PropTypes.oneOf(ICON_NAMES),
        PropTypes.bool,
    ]),
    actionProps: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    compact: PropTypes.bool,
    variant: PropTypes.oneOf(TEXT_VARIANTS),
};

export default BodyCell;
