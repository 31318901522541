import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import Icon from "components/design-system/ui/Icon";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import SPACING from "components/design-system/config/spacing";

import { COLOR_VALUES } from "components/design-system/config/colors";
import VStack from "components/design-system/layout/VStack";

const areaDisabled = `&[aria-disabled="true"] {
    color: ${COLOR_VALUES.Gray_2};
}`;
const InputLabel = styled.label`
    word-break: break-all;
    color: ${COLOR_VALUES.Gray_1};
    ${TEXT_VARIANT_STYLES.Headline_5}
    ${areaDisabled}
    margin: 0;

    ${(props) =>
        props.isDisabled &&
        css`
            pointer-events: none;
            user-select: none;
        `}
`;

const FeedbackIcon = styled(Icon)`
    flex-shrink: 0;
`;
const FormInputWrapperText = styled(Text)`
    word-break: break-all;
    ${areaDisabled}
`;

const RenderIcon = ({ feedbackType }) => {
    switch (feedbackType) {
        case FEEDBACK_TYPES.Success:
            return <FeedbackIcon size={17} name="success" color="Green" />;
        case FEEDBACK_TYPES.Warning:
            return <FeedbackIcon size={15} name="warning" color="Yellow" />;
        case FEEDBACK_TYPES.Error:
            return <FeedbackIcon name="error" color="Orange_1" />;
        default:
            return <></>;
    }
};

const FormInputWrapper = ({
    disabled,
    label,
    description,
    feedbackMessage,
    feedbackType,
    helpText,
    className,
    _spacing,
    isSmallSize,
    maxWidth = "100%",
    ...rest
}) => {
    return (
        <VStack
            spacing={isSmallSize ? "Zero_25" : "Zero_5"}
            className={className}
            css={("min-width: 64px", `max-width:${maxWidth}`)}
        >
            {(label || description) && (
                <div>
                    {label && (
                        <InputLabel
                            aria-disabled={disabled}
                            isDisabled={disabled}
                            {...rest}
                        >
                            {label}
                        </InputLabel>
                    )}
                    {description && (
                        <FormInputWrapperText
                            aria-disabled={disabled}
                            color="Gray_2"
                            variant="Descriptive_1_1"
                        >
                            {description}
                        </FormInputWrapperText>
                    )}
                </div>
            )}
            {rest.children}
            {(feedbackMessage || helpText) && (
                <div>
                    {feedbackMessage && (
                        <HStack spacing={SPACING.Zero_25} align="center">
                            <RenderIcon feedbackType={feedbackType} />
                            <FormInputWrapperText
                                aria-disabled={disabled}
                                color="Black"
                                variant="Body_2_2"
                            >
                                {feedbackMessage}
                            </FormInputWrapperText>
                        </HStack>
                    )}
                    {helpText && (
                        <FormInputWrapperText
                            aria-disabled={disabled}
                            color="Gray_2"
                            variant="Body_2_1"
                        >
                            {helpText}
                        </FormInputWrapperText>
                    )}
                </div>
            )}
        </VStack>
    );
};

FormInputWrapper.defaultProps = {
    disabled: false,
    label: null,
    description: null,
    feedbackType: FEEDBACK_TYPES.None,
    feedbackMessage: null,
    helpText: null,
    isSmallSize: false,
};

FormInputWrapper.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    description: PropTypes.string,
    feedbackType: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
    feedbackMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isSmallSize: PropTypes.bool,
};

export default FormInputWrapper;
