import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import styled from "styled-components";

import { COLOR_VALUES } from "components/design-system/config/colors";
import {
    TEXT_VARIANTS,
    TEXT_VARIANT_STYLES,
} from "components/design-system/ui/Text";

const StyledText = styled.div((props) => {
    return {
        ...TEXT_VARIANT_STYLES[props.$variant],
        ...(props.$color ? { color: COLOR_VALUES[props.$color] } : {}),
        a: {
            cursor: "pointer",
            color: "inherit",
            textDecoration: "underline",
        },
        p: {
            marginBottom: "10px",
        },
        ul: {
            marginBottom: 0,
            marginBlockStart: 0,
            marginBlockEnd: "25px",
            marginInlineStart: 0,
            marginInlineEnd: 0,
            paddingInlineStart: "25px",
        },
    };
});

const RichText = ({ variant, color, children, ...rest }) => {
    return (
        <StyledText $variant={variant} $color={color} {...rest}>
            <ReactMarkdown children={children} />
        </StyledText>
    );
};

RichText.propTypes = {
    variant: PropTypes.oneOf(TEXT_VARIANTS),
    color: PropTypes.oneOf(Object.keys(COLOR_VALUES)),
};

export default RichText;
