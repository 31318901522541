import React from "react";
import styled, { css } from "styled-components";
import { isValid, format } from "date-fns";

import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Icon from "components/design-system/ui/Icon";
import ProgressStatusCircle from "components/design-system/controls/ProgressStatusCircle/ProgressStatusCircle";

const StyledVStack = styled(VStack)`
    ${(props) =>
        props.isPadding &&
        css`
            padding: 32px 0;
        `}
`;

const StyledWrapper = styled(HStack)`
    justify-content: space-between;
    padding: 0;
`;

const StyledText = styled(Text)`
    ${TEXT_VARIANT_STYLES.Body_2_1};
    white-space: nowrap;
`;

const DataItem = ({
    label,
    updatedDate,
    iconName,
    value,
    title,
    showMessage,
    updatedDateLabel = "Last Updated",
    textMessage,
    iconColor = "Black",
    isPadding = true,
}) => {
    const lastUpdated = isValid(new Date(updatedDate))
        ? format(new Date(updatedDate), "MMM dd, yyyy")
        : null;
    return (
        <StyledVStack spacing="Zero_25" isPadding={isPadding}>
            <StyledWrapper spacing="Zero_25">
                <Text variant="Descriptive_1_1" color="Gray_2">
                    {label}
                </Text>

                {lastUpdated && (
                    <Text variant="Descriptive_2_1" color="Gray_4">
                        {updatedDateLabel} {lastUpdated}
                    </Text>
                )}
            </StyledWrapper>
            {iconName ? (
                <HStack spacing="Zero_25" align="center">
                    <Icon name={iconName} color={iconColor} />
                    <StyledText color="Black">{title}</StyledText>
                </HStack>
            ) : (
                <ProgressStatusCircle
                    value={value}
                    title={title}
                    showMessage={showMessage}
                />
            )}
            {textMessage && (
                <HStack spacing="Zero_25" align="center">
                    <Text color="Black" variant="Body_2_1">
                        {textMessage}
                    </Text>
                </HStack>
            )}
        </StyledVStack>
    );
};

export default DataItem;
