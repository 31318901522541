import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Modal from "components/design-system/ui/modal/MasterModal";
import HStack from "components/design-system/layout/HStack";
import ClientContactLeadsTable from "pages/admin/clients/single/ClientContactLeadsTable";
import ClientRateCardsTable from "pages/admin/clients/single/ClientRateCardsTable";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import ClientRateCardForm from "pages/admin/clients/single/ClientRateCardsForm";
import ClientContactLeadsForm from "pages/admin/clients/single/ClientContactLeadsForm";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "addRateCard") {
        return (
            <Modal title="Add Rate Card" onRequestClose={onClose}>
                <ClientRateCardForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    if (action === "addContactLead") {
        return (
            <Modal title="Add Contact Lead" onRequestClose={onClose}>
                <ClientContactLeadsForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "editRateCard" && !isNaN(parsedId)) {
        return (
            <Modal title="Edit Rate Card" onRequestClose={onClose}>
                <ClientRateCardForm
                    rateCardIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "editContactLead" && !isNaN(parsedId)) {
        return (
            <Modal title="Edit Contact Lead" onRequestClose={onClose}>
                <ClientContactLeadsForm
                    contactLeadIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const ClientContactsAndRateCards = () => {
    return (
        <>
            <FormPresenter />
            <HStack
                spacing="Two"
                justify="space-between"
                style={{ overflow: "auto" }}
            >
                <VStack style={{ width: "50%" }}>
                    <Text variant="Headline_2" color="Gray_1">
                        Contact Leads
                    </Text>
                    <ClientContactLeadsTable />
                </VStack>

                <VStack style={{ width: "50%" }}>
                    <Text variant="Headline_2" color="Gray_1">
                        Rate Cards
                    </Text>
                    <ClientRateCardsTable />
                </VStack>
            </HStack>
        </>
    );
};

export default ClientContactsAndRateCards;
