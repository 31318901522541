import React from "react";
import styled, { css } from "styled-components/macro";

import Text from "components/design-system/ui/Text";
import Loader from "components/UI/Loader";
import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";
import Tooltip from "components/design-system/ui/Tooltip";
import HStack from "components/design-system/layout/HStack";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Icon from "components/design-system/ui/Icon";
import Link from "components/design-system/ui/Link";

import { getCurrencyToLocaleString } from "common-methods/commonMethods";

const DataItemCard = styled.div`
    display: block;
    width: 12rem;
    cursor: default;
`;

const StyledLoaderWrapper = styled.div`
    padding-top: 7px;
    padding-left: 7px;
`;

const StyledDescriptionText = styled(Text)`
    ${(props) =>
        props.isNextLine &&
        css`
            white-space: pre-line;
        `}
`;

const StyledButton = styled.button`
    width: 16px;
    height: 16px;
    border: none;
    background: transparent;
`;

const StyledLink = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`;

const DashboardDataItem = ({
    title,
    placeholder = "",
    count = null,
    description,
    info = null,
    isCurrency = true,
    currencySymbol,
    isLoading = true,
    tooltipText = null,
    onClick = () => void 0,
    actionProps = {},
    isNextLine = false,
    actionIconWidth = 16,
    feedbackMessage = "",
    infoActionIcon,
    titleActionIconColor,
    iconTooltipText,
    titleActionIcon,
    linkData,
    href,
    isInternalUrlNewTab,
    feedbackIconColor,
    feedbackIcon,
}) => {
    const isNumber = typeof count === "number";
    const renderNumberData = (isNegativeVal = false) => {
        return (
            <Text
                variant={"Headline_3"}
                color={isNegativeVal ? COLORS.Orange_1 : COLORS.Black}
            >
                {isNegativeVal && <>&#40;</>}
                {isCurrency && currencySymbol}
                {getCurrencyToLocaleString(count)}
                {!isCurrency && <>&#37;</>}
                {isNegativeVal && <>&#41;</>}
            </Text>
        );
    };
    const renderContent = () => {
        return (
            <>
                {isNumber && count < 0
                    ? renderNumberData(true)
                    : isNumber && renderNumberData(false)}
                {info && (
                    <HStack spacing="Zero_25">
                        <Text variant={"Body_2_1"} color={COLORS.Black}>
                            {info}
                        </Text>
                        {infoActionIcon && (
                            <TertiaryButton
                                {...actionProps}
                                trailingIcon={infoActionIcon}
                                iconWidth={actionIconWidth}
                                style={{
                                    color: "currentColor",
                                    background: "transparent",
                                }}
                            />
                        )}
                    </HStack>
                )}
                {href ? (
                    <StyledLink
                        href={href}
                        isInternalUrlNewTab={isInternalUrlNewTab}
                    >
                        <Text variant="Headline_3" color={COLORS.Black}>
                            {linkData}
                        </Text>
                    </StyledLink>
                ) : (
                    <Text variant="Headline_3" color={COLORS.Black}>
                        {linkData}
                    </Text>
                )}
                {!isNumber && !info && (
                    <Text
                        variant={isNumber ? "Headline_3" : "Body_2_1"}
                        color={COLORS.Black}
                    >
                        {placeholder}
                    </Text>
                )}
                <StyledDescriptionText
                    variant={"Descriptive_2_1"}
                    color={COLORS.Gray_4}
                    isNextLine={isNextLine}
                >
                    {description}
                </StyledDescriptionText>
                {feedbackMessage && (
                    <HStack spacing="Zero_25" align="center">
                        <Icon name={feedbackIcon} color={feedbackIconColor} />
                        <Text color="Black" variant="Body_2_2">
                            {feedbackMessage}
                        </Text>
                    </HStack>
                )}
            </>
        );
    };
    return (
        <Tooltip text={tooltipText} enabled={tooltipText} enterDelay={0}>
            <DataItemCard>
                <HStack spacing="Zero_25">
                    <Text variant={"Descriptive_1_1"} color={COLORS.Gray_2}>
                        {title}
                    </Text>
                    {titleActionIcon && (
                        <Tooltip
                            text={iconTooltipText}
                            enabled={iconTooltipText}
                            enterDelay={0}
                        >
                            <StyledButton type="button" onClick={onClick}>
                                <Icon
                                    name={titleActionIcon}
                                    color={titleActionIconColor}
                                />
                            </StyledButton>
                        </Tooltip>
                    )}
                </HStack>
                {isLoading ? (
                    <StyledLoaderWrapper>
                        <Loader
                            width="18px"
                            height="18px"
                            color={COLOR_VALUES.Gray_1}
                        />
                    </StyledLoaderWrapper>
                ) : (
                    renderContent()
                )}
            </DataItemCard>
        </Tooltip>
    );
};

export default DashboardDataItem;
