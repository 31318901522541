import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Modal from "components/design-system/ui/modal/MasterModal";
import ProjectsDashboardNotesTable from "pages/projects/project-dashboard/single/ProjectsDashboardNotesTable";
import ProjectsDashboardNotesForm from "pages/projects/project-dashboard/single/ProjectsDashboardNotesForm";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal title="Add Note" onRequestClose={onClose}>
                <ProjectsDashboardNotesForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal title="Edit Note" onRequestClose={onClose}>
                <ProjectsDashboardNotesForm
                    noteIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const ProjectsDashboardNotes = () => {
    return (
        <>
            <FormPresenter />
            <ProjectsDashboardNotesTable />
        </>
    );
};

export default ProjectsDashboardNotes;
