import { useMemo } from "react";
import { toArray, groupBy, orderBy } from "lodash";

export const useAllocationsMessages = () => {
    const processMessagesQueue = (employeesToUpdate, messagesToProcess) => {
        let updatedEmployees = [...employeesToUpdate];

        if (
            updatedEmployees &&
            updatedEmployees.length > 0 &&
            messagesToProcess &&
            messagesToProcess.length > 0
        ) {
            let messagesCopy = [...messagesToProcess];
            const messagesByUser = toArray(
                groupBy(messagesCopy, (message) => message["UserId"])
            );
            let groupIndex = undefined;
            let employeeIndex = undefined;

            messagesByUser.forEach((messagesByUser) => {
                const userId = messagesByUser[0].UserId;
                const titleId = messagesByUser[0].TitleId;
                const officeId = messagesByUser[0].OfficeId;

                updatedEmployees.forEach((group, index) => {
                    groupIndex = index;
                    employeeIndex = group.findIndex(
                        (employee) =>
                            employee.id === userId &&
                            employee.titleId === titleId &&
                            employee.locationId === officeId
                    );
                });

                const employee = updatedEmployees[groupIndex][employeeIndex];

                if (employee) {
                    if (employee.details) {
                        processEmployeeDetail(
                            messagesByUser,
                            updatedEmployees,
                            groupIndex,
                            employeeIndex
                        );
                    } else {
                        processEmployyeSummary(
                            messagesByUser,
                            updatedEmployees,
                            groupIndex,
                            employeeIndex
                        );
                    }
                }
            });
        }
        return updatedEmployees;
    };

    const processEmployeeDetail = (
        messagesToProcess,
        employeesToUpdate,
        groupIndex,
        employeeIndex
    ) => {
        let clientIndex = undefined;
        let projectIndex = undefined;
        let dateIndex = undefined;

        const projectMessages = toArray(
            groupBy(messagesToProcess, (message) => message["JobId"])
        );

        projectMessages.forEach((messageByProject) => {
            const jobId = messageByProject[0].JobId;
            let details = [
                ...employeesToUpdate[groupIndex][employeeIndex].details,
            ];
            details.forEach((client, index) => {
                clientIndex = index;
                projectIndex = client.projects.findIndex(
                    (project) => project.jobId === jobId
                );

                if (projectIndex > -1) {
                    let project = {
                        ...details[clientIndex].projects[projectIndex],
                    };

                    const dateMessages = toArray(
                        groupBy(messageByProject, (message) => message["Date"])
                    );

                    dateMessages.forEach((messagesByDate) => {
                        const date = messagesByDate[0].Date;

                        dateIndex = project.allocations.findIndex(
                            (allocation) => allocation.date === date
                        );

                        if (dateIndex > -1) {
                            const latestMessage = orderBy(
                                messagesByDate,
                                "UpdateOn",
                                "desc"
                            )[0];

                            employeesToUpdate[groupIndex][
                                employeeIndex
                            ].summary[dateIndex].allocatedHours =
                                latestMessage.TotalAllocatedSummary;

                            employeesToUpdate[groupIndex][
                                employeeIndex
                            ].details[clientIndex].projects[
                                projectIndex
                            ].allocations[dateIndex].value =
                                latestMessage.Hours;
                        }
                    });
                }
            });
        });
    };

    const processEmployyeSummary = (
        messagesToProcess,
        employeesToUpdate,
        groupIndex,
        employeeIndex
    ) => {
        let summaryCopy = [
            ...employeesToUpdate[groupIndex][employeeIndex].summary,
        ];

        let dateIndex = undefined;

        const dateMessages = toArray(
            groupBy(messagesToProcess, (message) => message["Date"])
        );

        dateMessages.forEach((messagesByDate) => {
            const date = messagesByDate[0].Date;

            dateIndex = summaryCopy.findIndex(
                (allocation) => allocation.date === date
            );

            if (dateIndex > -1) {
                const latestMessage = orderBy(
                    messagesByDate,
                    "UpdateOn",
                    "desc"
                )[0];

                employeesToUpdate[groupIndex][employeeIndex].summary[
                    dateIndex
                ].allocatedHours = latestMessage.TotalAllocatedSummary;
            }
        });
    };

    const methods = useMemo(() => {
        return { processMessagesQueue };
    }, []);

    return methods;
};
