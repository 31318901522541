import React, { useState } from "react";

import HStack from "components/design-system/layout/HStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";

import useClientBizGroups from "pages/admin/clients/single/useClientBizGroups";

const Table_Width = 416;

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(72, Table_Width),
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows?.filter((row) => {
                return (
                    (filterValue === "active" && !row.original.RemovedDate) ||
                    (filterValue === "inactive" &&
                        row.original.RemovedDate?.length > 0)
                );
            });
        },
        accessor: "BizGroupClientId",
    },
    {
        headCellProps: {
            text: "Name",
            width: getCellWidthInPercent(312, Table_Width),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "BizGroupName",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(100),
        },
        accessor: "RemovedDate",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=editBusinessGroupLink&id=${cell.row.original.BizGroupClientId}`,
                replace: true,
                "aria-label": "Edit",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "BizGroupClientId",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Link",
    to: "?action=addLinkedBusinessGroup",
    replace: true,
};

const getRowProps = (row) => ({
    style: {
        background:
            row.values.RemovedDate?.length > 0 ? COLOR_VALUES.Gray_9 : "",
    },
});

const ClientBizGroupsTable = () => {
    const [filters, setFilters] = useState();

    const { clientBizGroups } = useClientBizGroups();

    if (clientBizGroups.isLoading) {
        return <>Loading...</>;
    }

    if (clientBizGroups.isError) {
        return <>Error</>;
    }

    return (
        <>
            <HStack align="end" justify="space-between">
                <TableFilters
                    filtersConfig={FiltersConfig}
                    onChange={setFilters}
                />
                <TertiaryButton {...tableCTAProps} />
            </HStack>
            <Table
                columns={columns}
                data={clientBizGroups?.data}
                filters={filters}
                emptyActionProps={tableCTAProps}
                emptyMessageTableTitle={"Linked Business Groups"}
                getRowProps={getRowProps}
                initialPageSize={16}
            />
        </>
    );
};

export default React.memo(ClientBizGroupsTable);
