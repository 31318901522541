import { useEffect, useState } from "react";
import { usePrevious } from "hooks/usePrevious";
import Link from "components/UI/Link";
import Loading from "components/UI/Loading";
import Text from "components/UI/Text";
import { Row } from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";
import { useAgencies } from "utilities/API/Agencies";
import { useLocations } from "utilities/API/Locations";
import Transform from "utilities/Transform";
import {
    Subtitle,
    TitleColumn,
    ErrorColumn,
    Checkbox,
    LoadingContainer,
    LocationColumn,
    AgenciesWraper,
} from "../Filter-Styles";
import WarningBox from "./WarningBox";

const warningMessage =
    "You have adjusted filters. Saved Filters have been cleared.";

const defaultAgencyIndex = 0;
const Locations = ({
    onLoadAgencyAndLocations,
    onChangeAgency,
    onChangeLocations,
    savedAgency,
    savedLocations,
    handleSavedFilterChangedWarning,
    filterSelected = false,
}) => {
    const { getAgenciesFilter } = useAgencies();
    const { getLocationsFilter } = useLocations();
    const [isLoading, setIsLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const [agencies, setAgencies] = useState([]);
    const [locationsTree, setLocationsTree] = useState([]);

    const [selectedAgency, setSelectedAgency] = useState(savedAgency);
    const prevSelectedAgency = usePrevious(selectedAgency);
    const [selectedLocations, setSelectedLocations] = useState(savedLocations);

    const [showAgencyWarning, setShowAgencyWarning] = useState(false);
    const [showLocationsWarning, setShowLocationsWarning] = useState(false);

    const [mainFilterModalOnLoad, setMainFilterModalOnLoad] = useState(true);

    useEffect(() => {
        savedAgency && setSelectedAgency(savedAgency);
    }, [savedAgency]);

    useEffect(() => {
        async function init() {
            if (!initialized) {
                loadAgencies();
                setInitialized(true);
            }
        }

        !initialized && init();
    }, [initialized]);

    useEffect(() => {
        savedLocations && setSelectedLocations(savedLocations);
    }, [savedLocations]);

    useEffect(() => {
        selectedAgency &&
            loadLocations(
                selectedAgency,
                selectedLocations,
                !Object.is(selectedAgency, prevSelectedAgency)
            );
    }, [selectedAgency, selectedLocations]);

    const loadAgencies = () => {
        if (agencies.length === 0) {
            getAgencies().then(async (payload) => {
                const agency = selectedAgency
                    ? selectedAgency
                    : {
                          value: payload[defaultAgencyIndex].Id,
                          label: payload[defaultAgencyIndex].Name,
                      };
                setSelectedAgency(agency);
                setAgencies(payload);
            });
        }
    };

    const getAgencies = async () => {
        return getAgenciesFilter().then(async (payload) => {
            return payload?.Items;
        });
    };

    const handleChangeAgency = async (value, label) => {
        setMainFilterModalOnLoad(false);
        const agency = { value: value, label: label };
        setSelectedAgency(agency);
        onChangeAgency(agency);
        setSelectedLocations([]);

        setShowAgencyWarning(
            filterSelected && selectedAgency?.value !== savedAgency?.value
        );
    };

    const loadLocations = async (
        agency,
        selectedLocations,
        reloadLocations
    ) => {
        let locations = [];
        if (reloadLocations) {
            setIsLoading(true);
            locations = await getLocations(agency);
        } else {
            locations = [...locationsTree];
        }
        loadLocationTree(locations, selectedLocations);
    };

    const getLocations = async (agency) => {
        return getLocationsFilter({
            agencyId: agency?.value,
        }).then(async (payload) => {
            const locations = [...payload].map((item) => {
                const location = Transform.locations(payload, item.RegionId);
                const { target, expanded } = location;
                return {
                    nodes: target,
                    expanded: expanded,
                    checked: [],
                    region: item.RegionId,
                };
            });
            setIsLoading(false);
            return locations;
        });
    };

    const loadLocationTree = async (locations, selectedLocations) => {
        const locationsCopy = [...locations].map((item) => {
            const checked = selectedLocations[`${item.region}`];
            return { ...item, checked: checked ?? [] };
        });
        setLocationsTree(locationsCopy);
        const selection = getLocationsSelection(locations, selectedLocations);
        onChangeLocations(
            selection.regions,
            selection.offices,
            selection.groups,
            selectedLocations,
            false
        );
    };

    const getLocationsSelection = (locations, selectedLocations) => {
        let regions = [];
        let offices = [];
        let groups = [];

        locations.forEach((item) => {
            if (item.nodes && item.nodes.length > 0) {
                const regionObj = item.nodes[0];
                const location = selectedLocations[`${regionObj.region}`];
                location &&
                    location.forEach((node) => {
                        if (node) {
                            const selectedNode = node.split("-");
                            const [type, value] = selectedNode;
                            let label = "";
                            switch (type) {
                                case "Region":
                                    label = regionObj?.label;
                                    regions.push({ value, label });
                                    break;
                                case "Office":
                                    label = getOfficeLabel(
                                        node,
                                        regionObj.children
                                    );
                                    offices.push({ value, label });
                                    break;
                                case "Group":
                                    label = getGroupLabel(
                                        node,
                                        regionObj.children
                                    );
                                    groups.push({ value, label });
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
            }
        });
        return { regions, offices, groups };
    };

    const handleLocationChange = async (values, region) => {
        setMainFilterModalOnLoad(false);
        const nodes = locationsTree
            .filter((item) => item.region === region)
            .map((item2) => item2.nodes);
        const regionObj = nodes[0][0];
        let regions = [];
        let offices = [];
        let groups = [];
        let locations = [...selectedLocations];
        locations[`${region}`] = values;

        locations.forEach((node) => {
            node &&
                node.forEach(async function (item) {
                    const selection = item.split("-");
                    const [type, value] = selection;
                    let label = "";
                    switch (type) {
                        case "Region":
                            label = regionObj?.label;
                            regions.push({ value, label });
                            break;
                        case "Office":
                            label = getOfficeLabel(item, regionObj.children);
                            offices.push({ value, label });
                            break;
                        case "Group":
                            label = getGroupLabel(item, regionObj.children);
                            groups.push({ value, label });
                            break;
                        default:
                            break;
                    }
                });
        });

        setSelectedLocations(locations);
        onChangeLocations(regions, offices, groups, locations, true);

        const validacion =
            JSON.stringify(savedLocations) !==
            JSON.stringify(selectedLocations);
        setShowLocationsWarning(filterSelected && !validacion);
    };

    const getOfficeLabel = (id, offices) => {
        let label = undefined;
        const office = offices.filter((item) => item.value === id);
        if (office && office.length > 0) label = office[0].label;
        return label;
    };

    const getGroupLabel = (id, offices) => {
        let group = undefined;
        let label = undefined;
        offices.forEach((office) => {
            if (office.children) {
                group = office.children.filter((group) => group.value === id);
                if (group && group.length > 0) label = group[0].label;
            }
        });
        return label;
    };

    const handleClearLocation = async () => {
        setMainFilterModalOnLoad(false);
        setSelectedLocations([]);
        onChangeLocations([], [], [], [], true);
    };

    useEffect(() => {
        setShowAgencyWarning(
            filterSelected && selectedAgency?.value !== savedAgency?.value
        );
    }, [selectedAgency]);

    useEffect(() => {
        if (showAgencyWarning || showLocationsWarning) {
            handleSavedFilterChangedWarning(true);
        }
    }, [showAgencyWarning, showLocationsWarning]);

    useEffect(() => {
        if (selectedAgency && !isLoading && mainFilterModalOnLoad) {
            const selection = getLocationsSelection(
                locationsTree,
                selectedLocations
            );
            onLoadAgencyAndLocations(selectedAgency, selection);
        }
    }, [locationsTree, selectedAgency, selectedLocations]);

    return (
        <>
            <Subtitle>
                <TitleColumn>
                    <Text variant="medium">Agency</Text>
                </TitleColumn>
                <ErrorColumn>
                    {showAgencyWarning && (
                        <WarningBox inline>{warningMessage}</WarningBox>
                    )}
                </ErrorColumn>
            </Subtitle>
            <AgenciesWraper>
                {agencies?.length === 0 ? (
                    <Text variant="smallLight">Loading data...</Text>
                ) : (
                    agencies?.length > 0 &&
                    agencies.map((item, index) => (
                        <Checkbox key={index}>
                            <input
                                style={{ border: "1px solid red" }}
                                key={index}
                                type="radio"
                                name="agency"
                                value={item.Id}
                                checked={selectedAgency.value == item.Id}
                                onChange={(event) =>
                                    handleChangeAgency(
                                        event.target.value,
                                        item.Name
                                    )
                                }
                            />
                            <label>{item.Name}</label>
                        </Checkbox>
                    ))
                )}
            </AgenciesWraper>
            <Subtitle marginTop>
                <TitleColumn>
                    <Text variant="smedium">Pay Office</Text>
                    <Link
                        onClick={() => handleClearLocation()}
                        disabled={
                            !(selectedLocations && selectedLocations.length > 0)
                        }
                        style={{ paddingTop: "2px" }}
                    >
                        Clear
                    </Link>
                </TitleColumn>
                <ErrorColumn>
                    {showLocationsWarning && (
                        <WarningBox inline>{warningMessage}</WarningBox>
                    )}
                </ErrorColumn>
            </Subtitle>
            {isLoading ? (
                <LoadingContainer>
                    <Loading text="Loading data..." />
                </LoadingContainer>
            ) : (
                <Row>
                    {locationsTree &&
                        locationsTree.map((location, index) => (
                            <LocationColumn key={index}>
                                <CheckboxTree
                                    nodes={location.nodes}
                                    checked={location.checked}
                                    expanded={location.expanded}
                                    nativeCheckboxes={true}
                                    onCheck={(values) =>
                                        handleLocationChange(
                                            values,
                                            location.region
                                        )
                                    }
                                />
                            </LocationColumn>
                        ))}
                </Row>
            )}
        </>
    );
};

export default Locations;
