import { COLOR_VALUES } from "components/design-system/config/colors";
import { getMoneyColumnVal } from "common-methods/commonMethods";

export const getSum = (totalOneColumns, totalsStrColmAtStart, data, arr) => {
    data?.forEach((item) => {
        totalOneColumns.forEach((column, i) => {
            if (item[column]) {
                arr[i + totalsStrColmAtStart].text += item[column];
            }
        });
    });
};

export const updateArrayWithCurrency = (
    symbol,
    summaryData,
    totalOneColumns,
    moneyArr,
    totalsStrColmAtStart
) => {
    return totalOneColumns.map((column, i) => {
        const amt = summaryData[column];
        moneyArr[i + totalsStrColmAtStart].text =
            typeof amt === "number" ? getMoneyColumnVal(symbol, amt) : `-`;
        moneyArr[i + totalsStrColmAtStart].textColor =
            typeof amt === "number" && amt < 0 ? COLOR_VALUES.Orange_1 : null;
    });
};

export const updateArrayWithCurrencyForSum = (
    symbol,
    totalOneColumns,
    moneyArr,
    totalsStrColmAtStart
) => {
    return totalOneColumns.map((_column, i) => {
        const amt = moneyArr[i + totalsStrColmAtStart]?.text;
        moneyArr[i + totalsStrColmAtStart].text =
            typeof amt === "number" ? getMoneyColumnVal(symbol, amt) : `-`;
        moneyArr[i + totalsStrColmAtStart].textColor =
            typeof amt === "number" && amt < 0 ? COLOR_VALUES.Orange_1 : null;
    });
};

export const updateArrayWithToFixed = (
    totalOneColumns,
    hoursArr,
    totalsStrColmAtStart
) => {
    return totalOneColumns.map((_column, i) => {
        hoursArr[i + totalsStrColmAtStart].text =
            typeof hoursArr[i + totalsStrColmAtStart]?.text === "number"
                ? hoursArr[i + totalsStrColmAtStart].text.toFixed(2)
                : "-";
    });
};
