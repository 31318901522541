import { sendNotification } from "utilities/Notification";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Options from "components/design-system/forms/Options";
import styled from "styled-components/macro";

import Link from "components/design-system/ui/Link";
import Accordion from "components/design-system/controls/accordion/Accordion";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";
import { getReporterUrl } from "pages/projects/useExternalUrl";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import { useProjectsApi } from "pages/admin/projects/useProjects";
import { useProjectByIdDetails } from "pages/projects/project-dashboard/single/useProjectsDashboardNotes";

const StyledVStack = styled(VStack)`
    gap: 24px;
`;

const StyledAccordionDescription = styled(Text)`
    ${TEXT_VARIANT_STYLES.Body_2_1};
    color: ${COLOR_VALUES.Gray_2};
    text-align: initial;
`;

const attributesOptions = [
    { label: "Unsure", value: "2" },
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
];

const ModalCTA = ({ isEditing, onClose }) => {
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                leadingIcon: isEditing ? "save" : null,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            as="fieldset"
        />
    );
};

function transformProjectsDataToFormData(projectsData = {}) {
    return {
        capturingData: attributesOptions
            .filter((f) => f.value == projectsData?.CapturingData)
            .map((d) => d.value)
            .join(","),
    };
}

function transformFormDataToProjectsData(formData = {}, projectsData = {}) {
    const newProjectsData = { ...projectsData };
    newProjectsData.CapturingData = Number(formData.capturingData);
    return newProjectsData;
}

const ProjectDashboardOverviewDataPISForm = ({
    projectIdToEditPIS,
    onClose,
}) => {
    const { updateProject } = useProjectsApi();
    const { projectByIdDetails } = useProjectByIdDetails();

    const isEditing = typeof projectIdToEditPIS !== "undefined";
    const modifier = isEditing ? updateProject : null;

    const currentProjectData = projectByIdDetails?.data;

    if (projectByIdDetails.isLoading) {
        return <>Loading...</>;
    }

    if (projectByIdDetails.error) {
        return <>Error</>;
    }

    if (isEditing && !currentProjectData) {
        return <div>Could not find Personal Information Status</div>;
    }

    const onSubmit = (formData) => {
        const dataPIS = transformFormDataToProjectsData(
            formData,
            currentProjectData
        );

        const allData = {
            projectId: projectIdToEditPIS,
            body: dataPIS,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={"Personal Information Status"}
                />
            );
        });
    };

    return (
        <>
            <Form
                onSubmit={onSubmit}
                defaultValues={transformProjectsDataToFormData(
                    currentProjectData
                )}
            >
                <VStack spacing="Three">
                    <VStack spacing="Two">
                        <Options
                            multiselect={false}
                            name="capturingData"
                            labelVariant={TEXT_VARIANT_STYLES.Headline_4}
                            label="Will this project, at any time, involve data, provided by the client or other source(s), that could include personal Information?"
                            options={attributesOptions}
                        />

                        <Accordion
                            accordionData={[
                                {
                                    hasNoBorder: true,
                                    hasNoPadding: true,
                                    title: "About personal information",
                                    children: (
                                        <StyledAccordionDescription>
                                            <StyledVStack>
                                                <div>
                                                    Personal information
                                                    &#40;PI&#41; is information
                                                    &#40;physical and
                                                    digital&#41; that identifies
                                                    or relates to an individual
                                                    or household &#40;
                                                    e&#46;g&#46; personal
                                                    details&#44; location
                                                    data&#44; interaction
                                                    habits&#44; and
                                                    more&#41;&#46;
                                                </div>
                                                <div>
                                                    <Link
                                                        href={getReporterUrl(
                                                            `job/documents/RGA_PI_Capture_LearnMore_Letter.aspx`
                                                        )}
                                                        isInternalUrlNewTab={
                                                            true
                                                        }
                                                        variant={
                                                            TEXT_VARIANT_STYLES.Body_2_3
                                                        }
                                                        color={COLORS.Gray_2}
                                                    >
                                                        Click here
                                                    </Link>{" "}
                                                    to learn more about the
                                                    different kinds of Personal
                                                    Information that can be
                                                    captured or handled on a
                                                    project&#46;
                                                </div>
                                            </StyledVStack>
                                        </StyledAccordionDescription>
                                    ),
                                },
                            ]}
                        />
                    </VStack>
                    <ModalCTA
                        isEditing={isEditing}
                        projectIdToEditPIS={projectIdToEditPIS}
                        onClose={onClose}
                        isLoading={modifier.isLoading}
                    />
                </VStack>
            </Form>
        </>
    );
};

export default ProjectDashboardOverviewDataPISForm;
