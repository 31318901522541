import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import VStack from "../layout/VStack";

const BlockHead = ({ title, description, ...rest }) => {
    return (
        <VStack spacing="Zero_25" {...rest}>
            {title && (
                <Text variant="Headline_4" color="Gray_1">
                    {title}
                </Text>
            )}
            {description && (
                <Text variant="Body_2_1" color="Gray_2">
                    {description}
                </Text>
            )}
        </VStack>
    );
};

BlockHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default BlockHead;
