import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Tag from "../../UI/Tag";

export const ModalControl = styled.a`
    display: inline-flex;
    vertical-align: top;
    margin-top: 0.1rem;
`;

export const SelectedFilters = styled.div`
    margin-left: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const LeftColumn = styled(Col)`
    border-right: ${global.config.border.small};
    padding: 2rem 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: ${global.config.sizes.modal.height};
`;

export const RightColumn = styled(Col)`
    padding: 6rem 4rem 0 4rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: ${global.config.sizes.modal.height};
`;

export const LeftTop = styled.div`
    height: 46vh;
`;

export const LeftBottom = styled.div`
    padding: 0 2rem;
`;

export const TotalColumn = styled(Col)`
    text-align: right;
    white-space: nowrap;
    margin-top: 0.25rem;
`;

export const Error = styled.div`
    margin-top: 0.75rem;
`;

export const Title = styled.div`
    padding: 2.5rem 2rem 1.438rem 2rem;
`;

export const Subtitle = styled.div`
    padding: 1.625rem 0 1rem 0;
    display: flex;
    ${(props) =>
        props.marginTop &&
        `
        margin-top: 2rem;
    `}
`;

export const TitleColumn = styled(Col)`
    margin: 0;
    padding: 0.25rem 0 0 0;
    display: inline-flex;
    > a {
        margin-left: 1rem;
    }
`;

export const ErrorColumn = styled(Col)`
    margin: 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;
`;

export const ResultRow = styled(Row)`
    padding: 1.75rem 0 0.75rem 0;
`;

export const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem 0.75rem 2rem;
    background-color: ${global.config.colors.white};
    border: 1px solid #edeff2;
    cursor: pointer;
    width: 100%;
    > svg {
        margin-left: auto;
        visibility: hidden;
    }
    :hover {
        background-color: ${global.config.colors.gray8};
        > svg {
            visibility: hidden;
        }
    }
    ${(props) =>
        props.selected &&
        `
        background-color: ${global.config.colors.gray8};
        > svg {
            visibility: visible;
        }
        :hover {
            > svg {
                visibility: visible;
            }
        }
    `}
    ${(props) =>
        props.borderBottom &&
        `
        border-bottom: ${global.config.border.small};
        margin-bottom: 1rem;
    `}
`;

export const MenuContent = styled.div`
    display: none;
    ${(props) =>
        props.selected &&
        `
        display: block;
    `}
`;

export const LocationColumn = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    &:first-child {
        padding-left: 0.5rem;
    }
`;

export const Checkbox = styled.span`
   margin-right:5rem;
   font-size: ${global.config.fonts.sizes.small};
`;

export const TagContainer = styled(Tag)``;

export const SavedContainer = styled.div`
    display: inline-flex;
    width: 100%;
    height: 3rem;
`;

export const LoadingContainer = styled.div`
    text-align: center;
    padding: 3em 0;
    display: grid;
    justify-content: center;
    align-content: center;
`;

export const Warning = styled.p`
    display: inline-flex;
    font-size: ${global.config.fonts.sizes.small};
    > svg {
        margin-right: 0.5rem;
    }
    margin-top: 0.5rem;
    ${(props) =>
        props.inline &&
        `
        margin: 0;
        margin-top: -0.5rem;
        text-align: right;
    `}
`;

export const SaveWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    margin: 0;
`;

export const SelectWrapper = styled(Col)`
    padding: 0;
    height: 100%;
`;

export const AgenciesWraper = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap:wrap;
    column-gap: 3rem;
    row-gap: 1rem;
`;

export const DDLWrapper = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
