import PropTypes from "prop-types";

const Close = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                y="15.1091"
                width="21.3676"
                height="1.17"
                transform="rotate(-45 0 15.1091)"
                fill={color}
            />
            <rect
                x="0.827271"
                width="21.3676"
                height="1.17"
                transform="rotate(45 0.827271 0)"
                fill={color}
            />
        </svg>
    );
};

Close.defaultProps = {
    size: "18",
    viewBox: "18",
    color: global.config.colors.black,
};

Close.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default Close;
