// for css attribute:
import "styled-components/macro";

import { StyledAction } from "components/design-system/ui/drawer/StyledDrawer";
import PrimaryButton from "components/design-system/controls/button/PrimaryButton";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import VStack from "components/design-system/layout/VStack";
import DrawerContext from "context/Drawer/DrawerContext";
import { useContext } from "react";

const DrawerActions = ({
    primaryAction,
    secondaryAction,
    tertiaryAction,
    isCTAAllActions = false,
    ...rest
}) => {
    const { isCTAPrimaryAction } = useContext(DrawerContext);

    return (
        <StyledAction
            isCTAPrimaryAction={isCTAPrimaryAction}
            isCTAAllActions={isCTAAllActions}
            {...rest}
        >
            <VStack>
                {primaryAction && <PrimaryButton {...primaryAction} />}
                {secondaryAction && <SecondaryButton {...secondaryAction} />}
                {tertiaryAction && (
                    <TertiaryButton
                        {...tertiaryAction}
                        style={{ width: "max-content", margin: "0 auto" }}
                    />
                )}
            </VStack>
        </StyledAction>
    );
};

export default DrawerActions;
