import React, { useContext, useRef } from "react";
import VStack from "components/design-system/layout/VStack";
import SummaryTimeOff from "./SummaryTimeOff";
import MyRequestsTimeOff from "./MyRequestsTimeOff";
import SessionContext from "context/app/SessionContext";

const UserTimeOff = () => {
    const session = useContext(SessionContext);
    if (session.freelancer || session.contractor){
        window.location.replace("/timesheets/");
    }

    return (
        <>
            <VStack spacing={"Three"}>
                <VStack>
                    <SummaryTimeOff />
                </VStack>
                <VStack>
                    <MyRequestsTimeOff />
                </VStack>
            </VStack>
        </>
    );
};

export default UserTimeOff;
