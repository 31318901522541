import PropTypes from "prop-types";

const DownArrow = ({ size, viewBox, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={"0 0 " + viewBox + " " + viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="downArrow"
        >
            <circle cx="9" cy="9" r="8" stroke={color} strokeWidth="1.17" />
            <path d="M13 7L9 11L5 7" stroke={color} strokeWidth="1.17" />
        </svg>
    );
};

DownArrow.defaultProps = {
    size: "18",
    viewBox: "18",
    color: global.config.colors.gray3,
};

DownArrow.propTypes = {
    size: PropTypes.string,
    viewBox: PropTypes.string,
    color: PropTypes.string,
};

export default DownArrow;
