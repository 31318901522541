import React, { useEffect, useRef } from "react";
import "styled-components/macro";

import TextInput from "components/design-system/controls/input/TextInput";
import {
    DropdownListLoader,
    DropdownListContainer,
    DropdownListContainerUL,
    DropdownListContainerLIWrapper,
    DropdownListContainerLI,
    DropdownListItem,
} from "components/design-system/controls/dropdown/DropdownStyledComponents";
import Tooltip from "components/design-system/ui/Tooltip";
import DropdownListVariant from "components/design-system/controls/dropdown-multi-select/DropdownListVariant";
import { OPTION_VARIANTS } from "components/design-system/config/optionVariants";

const ShowList = ({
    optionVariant,
    selectedValue,
    item,
    highlightedIndex,
    getItemProps,
    index,
    isBorder,
}) => {
    return optionVariant ? (
        item.label && (
            <DropdownListContainerLI isBorder={isBorder}>
                <DropdownListVariant
                    isVariantToggle={optionVariant === OPTION_VARIANTS.Toggle}
                    isTraversed={highlightedIndex === index}
                    key={`${item.label}${index}`}
                    {...getItemProps({
                        item,
                        index,
                    })}
                    checked={selectedValue === item.value}
                    label={item.label}
                    isBorder={isBorder}
                />
            </DropdownListContainerLI>
        )
    ) : (
        <DropdownListContainerLI
            isSelected={selectedValue === item.value}
            isTraversed={highlightedIndex === index}
            key={`${item.label}${index}`}
            {...getItemProps({ item, index })}
        >
            {item.label}
        </DropdownListContainerLI>
    );
};

const DropdownList = ({
    isOpen,
    options,
    optionsAreLoading,
    selectedValue,
    highlightedIndex,
    getMenuProps,
    getItemProps,
    internalFilterUIShown,
    filterValue,
    setFilterValue,
    disabledOptionsList,
    disabledOptionTooltipText,
    position = "absolute",
    optionVariant = null,
    isBorder = true,
    maxHeightOfOptionsList = 215,
}) => {
    const filterInputRef = useRef();
    const listScrollerRef = useRef();
    const pauseBlurEvent = useRef(false);

    // focuses list's filter field on open. that focus causes
    // a blur on the menu, so pause Downshift's built-in blur
    // action to keep the menu from closing
    useEffect(() => {
        if (isOpen && internalFilterUIShown) {
            setTimeout(() => {
                pauseBlurEvent.current = true;
                filterInputRef.current.focus();
                pauseBlurEvent.current = false;
            });
        }
    }, [isOpen, internalFilterUIShown]);

    useEffect(() => {
        listScrollerRef.current.scrollTop = 0;
    }, [filterValue]);

    return (
        <DropdownListContainer
            position={position}
            hidden={!(isOpen && (filterValue.length > 0 || options.length > 0))}
            className="dropdown-list-container"
        >
            {optionsAreLoading && <DropdownListLoader />}

            <DropdownListContainerUL
                className="dropdown-list-container-ul"
                isBorder={isBorder}
                isVariant={optionVariant === OPTION_VARIANTS.Checkbox}
                hidden={optionsAreLoading}
                {...getMenuProps({
                    onBlur: (event) => {
                        if (pauseBlurEvent.current) {
                            event.nativeEvent.preventDownshiftDefault = true;
                        }
                    },
                })}
            >
                {internalFilterUIShown && (
                    <TextInput
                        type="search"
                        placeholder="Search..."
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        css="margin: -1px -1px 0 -1px; width: calc(100% + 2px);"
                        ref={filterInputRef}
                        onKeyDown={(e) => {
                            if (e.code === "Space") {
                                e.stopPropagation();
                            }
                        }}
                    />
                )}

                <DropdownListContainerLIWrapper ref={listScrollerRef} maxHeightOfOptionsList={maxHeightOfOptionsList}>
                    {isOpen && options.length !== 0 ? (
                        options.map((item, index) => {
                            return disabledOptionsList.find(
                                (d) => d.value === item.value
                            ) ? (
                                <Tooltip
                                    key={`${item.label}${index}`}
                                    placement="top"
                                    title={disabledOptionTooltipText}
                                    disableFocusListener
                                >
                                    <DropdownListContainerLI
                                        isSelected={
                                            selectedValue === item.value
                                        }
                                        isTraversed={highlightedIndex === index}
                                        disabled={true}
                                        {...getItemProps({
                                            item,
                                            index,
                                            disabled: true,
                                        })}
                                    >
                                        {item.label}
                                    </DropdownListContainerLI>
                                </Tooltip>
                            ) : (
                                <ShowList
                                    highlightedIndex={highlightedIndex}
                                    key={`${item.label}${index}`}
                                    index={index}
                                    getItemProps={getItemProps}
                                    selectedValue={selectedValue}
                                    item={item}
                                    isBorder={isBorder}
                                    optionVariant={optionVariant}
                                />
                            );
                        })
                    ) : (
                        <DropdownListItem>No matches found</DropdownListItem>
                    )}
                </DropdownListContainerLIWrapper>
            </DropdownListContainerUL>
        </DropdownListContainer>
    );
};

export default DropdownList;
