import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";

export const NavBarStyles = makeStyles(() => ({
    logo: {
        "&.MuiLink-root": {
            fontFamily: global.config.fonts.families.medium,
            color: global.config.colors.black,
            textDecoration: "none",
        },
    },
    navItem: {
        "&.MuiLink-root": {
            color: global.config.colors.black,
            textDecoration: "none",
            display: "inline-block",
            height: "1.25rem",
        },
    },
}));

export const NavWrapper = styled.header`
    position: fixed;
    top: 0px;
    height: 50px;
    width: 100%;
    z-index: 999;
    background-color: ${global.config.colors.gray8};
`;

export const NavContainer = styled(Container)`
    padding: 0;
    margin: 0;
`;

export const NavRow = styled(Row)`
    padding 0.813em 0 0 0;
    margin: 0;
`;

export const IndentColumn = styled(Col)`
    flex: 0 0 4%;
`;

export const LogoColumn = styled(Col)`
    margin-top: 0.25rem;
    white-space: nowrap;
    padding: 0;
`;

export const NavColumn = styled(Col)`
    text-align: right;
    padding: 0;
`;

export const ExternalLink = styled.a`
    display: inline-block;
    margin-left: 2em;
    color: ${global.config.colors.black};
    text-decoration: none;
    white-space: nowrap;
    &:hover {
        color: ${global.config.colors.black};
        text-decoration: none;
    }
`;

export const UserProfileMenuWrapper = styled.div`
    display: inline-block;
    margin-left: 4em;
`;

export const EnvNameWrapper = styled.div`
    background-color: ${global.config.colors.red1};
    color: ${global.config.colors.white};
    margin: 0 2rem;
    display: inline-block;
    padding: 0 1rem;
    font-weight: bold;
`;
