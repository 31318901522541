import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";
import { PAGER_INITIAL_PAGE_SIZE } from "components/design-system/tables/Pager";

export const useAltJobCodesApi = () => {
    const { getDataAsync } = useApi();

    const getAltJobCodesApiCall = useCallback(async () => {
        const response = await getDataAsync("getAltJobCodes");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const altJobCodes = useQuery("altJobCodesList", getAltJobCodesApiCall);

    return useMemo(() => {
        return {
            altJobCodes,
        };
    }, [altJobCodes]);
};

export const useProjectClassificationsApi = () => {
    const { getDataAsync } = useApi();

    const getProjectClassificationsApiCall = useCallback(async () => {
        const response = await getDataAsync("getProjectClassifications");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const projectClassifications = useQuery(
        "projectClassificationsList",
        getProjectClassificationsApiCall
    );

    return useMemo(() => {
        return {
            projectClassifications,
        };
    }, [projectClassifications]);
};

export const useRateCardsApi = () => {
    const { getDataAsync } = useApi();

    const getRateCardsApiCall = useCallback(async () => {
        const response = await getDataAsync("getRateCards");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const rateCards = useQuery("rateCardsList", getRateCardsApiCall);

    return useMemo(() => {
        return {
            rateCards,
        };
    }, [rateCards]);
};

export const useRateCardsByClientIdApi = () => {
    const { getDataAsync } = useApi();

    const getRateCardsByClientIdApiCall = useCallback(
        async ({ clientId }) => {
            const response = await getDataAsync(
                isNaN(clientId) || clientId == ""
                    ? "getRateCards"
                    : "getRateCardsByClientId",
                {
                    urlParams: [{ name: "$clientId", value: clientId }],
                }
            );
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useRateCardsByClientId = (dataObj) =>
            useQuery(["rateCardsList", dataObj], () =>
                getRateCardsByClientIdApiCall(dataObj)
            );
        return {
            useRateCardsByClientId,
        };
    }, [getRateCardsByClientIdApiCall]);
};

export const useVantageLOBApi = () => {
    const { getDataAsync } = useApi();

    const getVantageLOBApiCall = useCallback(async () => {
        const response = await getDataAsync("getVantageLOB");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const vantageLOB = useQuery("vantageLOBList", getVantageLOBApiCall);

    return useMemo(() => {
        return {
            vantageLOB,
        };
    }, [vantageLOB]);
};

export const useClientGroupLOBApi = () => {
    const { getDataAsync } = useApi();

    const getClientGroupLOBApiCall = useCallback(async () => {
        const response = await getDataAsync("getClientGroupLOB");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientGroupLOB = useQuery(
        "clientGroupLOBList",
        getClientGroupLOBApiCall
    );

    return useMemo(() => {
        return {
            clientGroupLOB,
        };
    }, [clientGroupLOB]);
};

export const useClientCampaignsApi = () => {
    const { getDataAsync } = useApi();

    const getClientCampaignsApiCall = useCallback(async () => {
        const response = await getDataAsync("getClientCampaigns");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientCampaigns = useQuery(
        "clientCampaignsList",
        getClientCampaignsApiCall
    );

    return useMemo(() => {
        return {
            clientCampaigns,
        };
    }, [clientCampaigns]);
};

export const useTaxCodesApi = () => {
    const { getDataAsync } = useApi();

    const getTaxCodesApiCall = useCallback(async () => {
        const response = await getDataAsync("getTaxCodes");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const taxCodes = useQuery("taxCodesList", getTaxCodesApiCall);

    return useMemo(() => {
        return {
            taxCodes,
        };
    }, [taxCodes]);
};

export const useRetainersApi = () => {
    const { getDataAsync } = useApi();

    const getRetainersApiCall = useCallback(async () => {
        const response = await getDataAsync("retainers");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const retainers = useQuery("retainersList", getRetainersApiCall);

    return useMemo(() => {
        return {
            retainers,
        };
    }, [retainers]);
};

export const useProjectsApi = () => {
    const { getDataAsync, postData, putData } = useApi();
    const queryClient = useQueryClient();

    const getProjectsApiCall = useCallback(
        async ({
            sortBy = "",
            pageSize = PAGER_INITIAL_PAGE_SIZE,
            pageIndex = 0,
            active = "",
            leadAgencyIds = [],
            clientIds = [],
            rateCardTableIds = [],
            leadProducerIds = [],
            bizUnitIds = [],
            apiStatusIds = [],
        }) => {
            const response = await getDataAsync("projects", {
                urlParams: [
                    { name: "$Active", value: active },
                    { name: "$sortBy", value: sortBy },
                    { name: "$pageSize", value: pageSize },
                    { name: "$pageNumber", value: pageIndex + 1 },
                    { name: "$LeadAgencyIds", value: leadAgencyIds },
                    { name: "$ClientIds", value: clientIds },
                    { name: "$RateCardTableIds", value: rateCardTableIds },
                    { name: "$LeadProducerIds", value: leadProducerIds },
                    { name: "$BizUnitIds", value: bizUnitIds },
                    { name: "$APIStatusIds", value: apiStatusIds },
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const getProjectByIdApiCall = useCallback(
        async (projectId) => {
            const response = await getDataAsync("getProjectById", {
                urlParams: [{ name: "$projectId", value: projectId }],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    const createProjectApiCall = useCallback(
        (body) => {
            return postData("createProject", {
                body,
            });
        },
        [postData]
    );

    const updateProjectApiCall = useCallback(
        ({ projectId, body }) => {
            return putData("updateProjects", {
                urlParams: [{ name: "$projectId", value: projectId }],
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("projectsList");
            },
        };
    }, [queryClient]);

    const createProject = useMutation(createProjectApiCall, mutationOptions);
    const updateProject = useMutation(updateProjectApiCall, mutationOptions);

    return useMemo(() => {
        const useProjectsList = (dataObj) =>
            useQuery(
                ["projectsList", dataObj],
                () => getProjectsApiCall(dataObj),
                { keepPreviousData: true, staleTime: Infinity }
            );

        const useProjectById = (projectId) =>
            useQuery(["getProjectById", projectId], () =>
                getProjectByIdApiCall(projectId)
            );

        return {
            useProjectsList,
            useProjectById,
            createProject,
            updateProject,
        };
    }, [
        getProjectsApiCall,
        getProjectByIdApiCall,
        createProject,
        updateProject,
    ]);
};
