import { useMemo } from "react";
import { useApiRequest } from "./ApiRequest";
import { cleanObject } from "utilities/Objects";
import { getEndpoints } from "configs/Endpoints";
import { ServerError } from "utilities/Errors/ErrorsTypes";
import { useValidationError } from "hooks/Errors/useValidationError";
import { useForm } from "react-hook-form";

const getMicroServiceConfig = async (name) => {
    return getEndpoints(name);
};

const getOptions = (method, options) => {
    return {
        method: method,
        params: (options && cleanObject(options.params)) || undefined,
        body: (options && options.body) || undefined,
    };
};

const getResource = (resource, options) => {
    if (options && options.urlParams) {
        options.urlParams.map(
            (param) => (resource = resource.replace(param.name, param.value))
        );
    }
    return resource;
};

export const useApi = () => {
    const { callBackend } = useApiRequest();
    const { handleFormErrorResponse } = useValidationError();
    const { setError } = useForm({ mode: "onChange" });

    const validateResponse = async (response) => {
        if (response === undefined) {
            return null;
        } else {
            if (response.ok) return response;

            let error = null;

            const correlationId =
                response?.headers.has("X-Correlation-ID") &&
                response?.headers.get("X-Correlation-ID");

            const errorBody = await response.json().catch((e) => {
                error = e;
            });

            error = new ServerError(
                response.statusText,
                response.status,
                correlationId,
                errorBody,
                response.url
            );

            handleFormErrorResponse(error, setError); //to handle the popup errors and validations
            throw error; //to throw the exception to add any handler on the UI component
            //ALL CALLS FROM UI COMPONENTS NEEDS TO USE A CATCH TO AVOID ANY ISSUE ON COMPONENT
            //ADD .catch(()=> {}) after any API call inside the UI component
            //Ask to M. Creimerman about this error handling and flow
        }
    };

    const getDataAsync = async (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then(async (config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "GET",
                    serviceOptions
                )
            ).then((response) => {
                return validateResponse(response);
            });
        });
    };

    const getData = (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then((config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "GET",
                    serviceOptions
                )
            ).then((response) => {
                return validateResponse(response);
            });
        });
    };

    const postData = async (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then(async (config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "POST",
                    serviceOptions
                )
            )
                .then((response) => {
                    return validateResponse(response);
                })
                .catch((error) => {
                    return error;
                });
        });
    };

    const putData = async (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then(async (config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "PUT",
                    serviceOptions
                )
            )
                .then((response) => {
                    return validateResponse(response);
                })
                .catch((error) => {
                    return error;
                });
        });
    };

    const patchData = async (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then(async (config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "PATCH",
                    serviceOptions
                )
            )
                .then((response) => {
                    return validateResponse(response);
                })
                .catch((error) => {
                    return error;
                });
        });
    };

    const deleteData = async (serviceName, serviceOptions) => {
        return getMicroServiceConfig(serviceName).then(async (config) => {
            return callBackend(
                config.api,
                config.version,
                getResource(config.resource, serviceOptions),
                getOptions(
                    (serviceOptions && serviceOptions.method) || "DELETE",
                    serviceOptions
                )
            )
                .then((response) => {
                    return validateResponse(response);
                })
                .catch((error) => {
                    return error;
                });
        });
    };

    return useMemo(() => {
        return {
            getData,
            getDataAsync,
            postData,
            putData,
            patchData,
            deleteData,
        };
    }, []);
};
