import PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";

import SPACING, { SPACING_VALUES } from "../config/spacing";

const StyledVStack = styled.div((props) => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: props.$align,
        gap: props.$spacing ? SPACING_VALUES[props.$spacing] : "0",
    };
});

const VStack = React.forwardRef(({ spacing, align, ...props }, ref) => {
    return (
        <StyledVStack $spacing={spacing} $align={align} {...props} ref={ref} />
    );
});

VStack.defaultProps = {
    spacing: SPACING.One,
};

VStack.propTypes = {
    spacing: PropTypes.oneOf(Object.keys(SPACING)),
};

export default VStack;
