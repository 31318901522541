const OFFICE_TYPES = [
    {
        value: 454,
        altValue: "Pay Region Only",
        label: "Pay Region Only",
    },
    {
        value: 453,
        altValue: "Seat Only",
        label: "Seat Only",
    },
    {
        value: 452,
        altValue: "Seat and Pay Region",
        label: "Seat and Pay Region",
    },
];

function normalizeOption(value, options = []) {
    return options.find(
        (option) => option.value === value || option.altValue === value
    );
}

function getTypeOption(value) {
    return normalizeOption(value, OFFICE_TYPES);
}

export { OFFICE_TYPES, getTypeOption };
