import { useCallback, useEffect, useState } from "react";

export const useReloadData = () => {
    const [reloadData, setReloadData] = useState(false);

    const handleRefreshDataClick = useCallback(() => {
        setReloadData(!reloadData);
    }, []);

    useEffect(() => {
        if (reloadData) {
            setReloadData(false);
        }
    }, [reloadData]);

    return [handleRefreshDataClick, reloadData];
};
