import env from "react-dotenv";
import { loginRequest } from "configs/Authentication";
import { isString } from "lodash";

let endpoints = null;
let token = null;
let _msalInstance = null;
let msalStorageKey = null;

export async function getEndpoints(name) {
    if (endpoints !== null) {
        return endpoints.find((config) => config.name === name);
    } else {
        console.log("Endpoints - Can't get endpoint config", name);
    }
}

export async function setEndpoints() {
    let i = Math.floor(Math.random() * 1000000);
    return fetch("/endpoints.json?i=" + i)
        .then(function (response) {
            return response.status != 204 ? response.json() : null;
        })
        .then(function (json) {
            endpoints = json;
            return endpoints;
        });
}

export async function getToken(msalInstance) {
    if (msalInstance) {
        _msalInstance = msalInstance;
    }
    let now = new Date();
    let microsoftTokenExpirationDate = getMicrosoftTokenExpirationDate();
    let account = _msalInstance.getAllAccounts()[0];
    const tokenRequest = {
        ...loginRequest,
        scopes: [env.BESTWORK_REACT_AZUREAD_SCOPE],
        account: account,
    };
    let refresh = false;
    let refreshTokenDate = getMicrosoftTokenRefreshDate();
    if (refreshTokenDate !== null && now.getTime() >= refreshTokenDate) {
        redirectLogin();
    }
    if (
        microsoftTokenExpirationDate !== null &&
        now.getTime() >= microsoftTokenExpirationDate
    ) {
        refresh = true;
    }
    if (token === null || refresh) {
        token = await _msalInstance
            .acquireTokenSilent(tokenRequest)
            .then((tokenResponse) => {
                const now = new Date();
                token = tokenResponse.accessToken;
                let refreshDate = now.getTime() + 12 * 60 * 60 * 1000;
                localStorage.setItem("usrsesref", refreshDate);
                return tokenResponse.accessToken;
            })
            .catch((_error) => {
                return null;
            });
    }
    if (token !== null && getSessionCacheItems().length === 0) {
        token = null;
        redirectLogin();
    }
    return token;
}

export async function clearToken() {
    token = null;
    localStorage.removeItem("usrsesref");
    localStorage.removeItem("VPNAlert");
    sessionStorage.removeItem("securityToken");
}

function getMicrosoftTokenRefreshDate() {
    let rv = null;
    let tokenInfo = localStorage.getItem("usrsesref");
    if (tokenInfo !== null) {
        rv = tokenInfo;
    }
    return rv;
}

function getMicrosoftTokenExpirationDate() {
    let microsoftTokenExpirationDate = null;
    for (const key of Object.keys(localStorage)) {
        let data = localStorage.getItem(key);
        let dataValue = null;

        if (data) {
            try {
                dataValue = JSON.parse(data);
            } catch {}
        }
        if (data && dataValue) {
            const checkCondition1 = dataValue.expiresOn && dataValue.clientId;
            let tokenExpiration = checkCondition1 ? dataValue.expiresOn : null;

            if (checkCondition1 && isString(tokenExpiration)) {
                tokenExpiration = parseInt(tokenExpiration);
            }
            if (checkCondition1 && key.length > 20) {
                msalStorageKey = key.substring(0, 20);
            }
            if (
                checkCondition1 &&
                dataValue.clientId === env.BESTWORK_REACT_AZUREAD_CLIENT_ID
            ) {
                let msExpiration = tokenExpiration * 1000;
                microsoftTokenExpirationDate = msExpiration;
            }
        }
    }
    return microsoftTokenExpirationDate;
}

function getSessionCacheItems() {
    let keys = [];
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, 20) === msalStorageKey) {
            keys.push(localStorage.key(i));
        }
    }
    return keys;
}

function redirectLogin() {
    localStorage.setItem("sessionExpired", true);
    const state = window.history.state.usr?.__useLocationState;
    let stateArr = [];
    if (state) {
        for (let data of Object.values(state)) {
            stateArr.push([...data]);
        }
    }
    const mapToArrayOfObject = (data) => {
        if (data) {
            return Array.from(data).map(([key, value]) => {
                if (value) {
                    return { key, value: [...value] };
                }
            });
        }
    };

    let stateObj = mapToArrayOfObject(stateArr[0]);

    let keys = getSessionCacheItems();
    const redirectParam = encodeURIComponent(
        JSON.stringify({
            pathname: window.location.pathname,
            search: window.location.search,
            state: stateObj ?? [],
        }).replaceAll('"', "TOKEN")
    );

    for (let i = 0; i < keys.length; i++) {
        localStorage.removeItem(keys[i]);
    }
    localStorage.removeItem("usrsesref");
    localStorage.removeItem("VPNAlert");
    sessionStorage.removeItem("securityToken");
    window.location.replace("/");
}

export function getMSALInstance() {
    return _msalInstance;
}

export function clearCacheItems(){
    caches.keys().then((names) => {
        names.forEach((name) => {
            console.log("CACHE", name);
            caches.delete(name);
        });
    });
}
