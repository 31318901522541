import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import HeaderPanel from "components/Allocations/HeaderPanel";
import FooterPanel from "components/Allocations/FooterPanel";
import Loading from "../components/UI/Loading";
import { AllocationsProvider } from "context/Allocations/Context";
import { GridUIContextProvider } from "context/Allocations/GridUIContext";
import { AllocationsSignalRProvider } from "context/Allocations/SignalRContext";
import { usePageAccess } from "hooks/Access/usePageAccess.js";
import styled, { createGlobalStyle } from "styled-components";
import Grid from "components/Allocations/Grid/Grid";
import { CustomGroupContextProvider } from "context/Allocations/CustomGroupContext";
import { EmployeeGridSelectionProvider } from "context/Allocations/EmployeeGridSelectionContext";
import PillTooltip from "../components/Modal/Allocations/PillTooltip";
import { EmployeeRowExpansionContextProvider } from "context/Allocations/EmployeeRowExpansionContext";
import { useLocation } from "react-router-dom";
import { EmployeeRowsProvider } from "context/Allocations/EmployeeRowsContext";
import { AllocationsPageContext } from "context/Allocations/AllocationsPageContext"; 

const LoadingWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-content: center;
    align-content: center;
`;

const Main = styled.div`
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
`;

const GlobalStyle = createGlobalStyle`
  body {
    
  }
`;

const MemoizedGrid = React.memo(Grid);
const MemoizedHeaderPanel = React.memo(HeaderPanel);
const MemoizedFooterPanel = React.memo(FooterPanel);

const usePathname = () => {
    const location = useLocation();
    return location.pathname;
};

const AllocationsPage = () => {
    const { authorized } = usePageAccess(usePathname());
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        if (authorized !== null && isLoading) {
            setIsLoading(false);
        }
    }, [authorized]);

    window.onbeforeunload = function(e) {
    }

    window.stickyHeader = () => {
        const header = document.getElementById("date_cols_wrapper");
        const headerBottom = header.getBoundingClientRect().bottom; // + window.pageYOffset;
        const headers = document.getElementsByClassName("allocEmpHeader");
        for (let i = 0; i < headers.length; i++) {
            const id = headers[i].id.replace("emp_header_wrapper_", "div_");
            const divEmp = document.getElementById(id).getBoundingClientRect().top;
            const divEmpTop = i === 0 ? divEmp : divEmp - 35;
            if (headerBottom > divEmpTop) {
                headers[i].classList.add("allocationsStickyEmployee");
                headers[i].style.top = headerBottom + "px";
            }
            else {
                headers[i].classList.remove("allocationsStickyEmployee");
                headers[i].style.top = 0;
            }
        }
    }

    window.onscroll = () => {
        window.scroll({behavior: "smooth"});
        window.stickyHeader();
    }

    const renderComponents = (
        <AllocationsSignalRProvider>
            <AllocationsProvider>
                <GridUIContextProvider>
                    <GlobalStyle></GlobalStyle>
                    <Main id="gridContainer">
                        <EmployeeRowExpansionContextProvider>
                            <EmployeeGridSelectionProvider>
                                <EmployeeRowsProvider>
                                    <CustomGroupContextProvider>
                                        <MemoizedHeaderPanel gridId="allocationsGrid" />
                                        <MemoizedGrid id="allocationsGrid" />
                                    </CustomGroupContextProvider>
                                </EmployeeRowsProvider>
                            </EmployeeGridSelectionProvider>
                        </EmployeeRowExpansionContextProvider>
                        <MemoizedFooterPanel />
                    </Main>
                    <PillTooltip />
                </GridUIContextProvider>
            </AllocationsProvider>
        </AllocationsSignalRProvider>
    );

    const renderPage = authorized ? (
        renderComponents
    ) : (
        <Navigate to={global.config.routes.unauthorized} />
    );

    const renderLoading = (
        <LoadingWrapper>
            <Loading text="Loading Page" />
        </LoadingWrapper>
    );

    return <>{isLoading ? renderLoading : renderPage}</>;
};

export default AllocationsPage;
