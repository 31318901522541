import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../ui/Breadcrumb";
import Text from "../ui/Text";
import VStack from "../layout/VStack";

const SectionHead = ({ title, description, menuOptions }) => {
    return (
        <>
            <VStack spacing="One">
                {menuOptions && <Breadcrumb menuOptions={menuOptions} />}
                <VStack spacing="Zero_5">
                    {title && (
                        <Text variant="Headline_2" color="Gray_1">
                            {title}
                        </Text>
                    )}
                    {description && (
                        <Text variant="Body_2_1" color="Gray_2">
                            {description}
                        </Text>
                    )}
                </VStack>
            </VStack>
        </>
    );
};

SectionHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    menuOptions: PropTypes.array,
};

export default SectionHead;
