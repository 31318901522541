import React from "react";
import styled, { css } from "styled-components/macro";

import DashboardDataItem from "components/design-system/ui/dashboard/DashboardDataItem";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";

import useRetainersDashboardData from "pages/projects/retainers-dashboard/single/useRetainersDashboardData";

const StyledHStack = styled(HStack)`
    ${(props) =>
        props.borderBottom &&
        css`
            border-bottom: 1px solid ${COLOR_VALUES.Gray_6};
            padding-bottom: 40px;
        `}
    gap: 2rem;
`;

export const endpointPathText = {
    sowFee: "sowfee",
    totalFee: "totalfee",
    currency: "currency",
    totalBaseline: "totalbaseline",
    subJobVariance: "subjobs/totalvariance",
    subJobBaseline: "subjobs/totalbudget",
    preAdjustedFee: "preadjustedfee",
    pcnFee: "pcnfee",
    completePercent: "completepercent",
    discount: "adjustments",
};

let dataObj = [
    {
        borderBottom: false,
        data: [
            {
                title: "Total Fee",
                placeholder: "0.00",
                res: endpointPathText.totalFee,
            },
        ],
    },
    {
        borderBottom: true,
        data: [
            {
                title: "Pre-Adjusted Fee",
                placeholder: "Not Defined",
                res: endpointPathText.preAdjustedFee,
            },
            {
                title: "Discounts & Investments",
                placeholder: "0.00",
                res: endpointPathText.discount,
            },
            {
                title: "SOW Fee",
                placeholder: "Not Defined",
                res: endpointPathText.sowFee,
            },
            {
                title: "PCN Fee",
                placeholder: "-",
                res: endpointPathText.pcnFee,
            },
        ],
    },
    {
        borderBottom: true,
        data: [
            {
                title: "Retainer Baseline",
                placeholder: "-",
                res: endpointPathText.totalBaseline,
            },
            {
                title: "Sub-Job Baseline",
                placeholder: "0.00",
                res: endpointPathText.subJobBaseline,
            },
            {
                title: "Retainer and Sub-Job Variance",
                placeholder: "0.00",
                res: endpointPathText.subJobVariance,
            },
            {
                title: "Percent Complete (Calculated)",
                isCurrency: false,
                placeholder: "0.00%",
                tooltipText: "Calculated by Planner",
                res: endpointPathText.completePercent,
            },
        ],
    },
];

const RetainersDashboardOverviewData = () => {
    const currencyDL = useRetainersDashboardData(endpointPathText.currency);
    const subJobVarianceDL = useRetainersDashboardData(
        endpointPathText.subJobVariance
    );
    const sowFeeDL = useRetainersDashboardData(endpointPathText.sowFee);
    const totalFeeDL = useRetainersDashboardData(endpointPathText.totalFee);
    const totalBaselineDL = useRetainersDashboardData(
        endpointPathText.totalBaseline
    );
    const subJobBaselineDL = useRetainersDashboardData(
        endpointPathText.subJobBaseline
    );
    const preAdjustedFeeDL = useRetainersDashboardData(
        endpointPathText.preAdjustedFee
    );
    const pcnFeeDL = useRetainersDashboardData(endpointPathText.pcnFee);
    const completePercentDL = useRetainersDashboardData(
        endpointPathText.completePercent
    );
    const discountInvestmentsDL = useRetainersDashboardData(
        endpointPathText.discount
    );

    if (
        currencyDL.list.isError ||
        subJobVarianceDL.list.isError ||
        sowFeeDL?.list?.isError ||
        totalFeeDL?.list?.isError ||
        totalBaselineDL?.list?.isError ||
        subJobBaselineDL?.list?.isError ||
        preAdjustedFeeDL?.list?.isError ||
        pcnFeeDL?.list?.isError ||
        completePercentDL?.list?.isError ||
        discountInvestmentsDL?.list?.isError
    ) {
        return <>Error</>;
    }

    const listMap = new Map([
        [endpointPathText.subJobVariance, subJobVarianceDL],
        [endpointPathText.sowFee, sowFeeDL],
        [endpointPathText.totalFee, totalFeeDL],
        [endpointPathText.totalBaseline, totalBaselineDL],
        [endpointPathText.subJobBaseline, subJobBaselineDL],
        [endpointPathText.preAdjustedFee, preAdjustedFeeDL],
        [endpointPathText.pcnFee, pcnFeeDL],
        [endpointPathText.completePercent, completePercentDL],
        [endpointPathText.discount, discountInvestmentsDL],
    ]);

    const getDataDetail = (dataItem) => {
        return listMap.get(dataItem) || null;
    };

    return (
        <>
            {dataObj.map((row, rowIndex) => (
                <StyledHStack
                    key={`retainers-data-layout-${rowIndex + 1}`}
                    borderBottom={row.borderBottom}
                >
                    {row.data.map((item, itemIndex) => {
                        const dataItem = item.res
                            ? getDataDetail(item.res)
                            : [];

                        return item.res ? (
                            <DashboardDataItem
                                key={`retainers-data-layout-item-${
                                    itemIndex + 1
                                }`}
                                title={item.title}
                                placeholder={item.placeholder}
                                count={dataItem?.list?.data}
                                isLoading={currencyDL?.list?.isFetching || dataItem?.list?.isFetching}
                                currencySymbol={currencyDL?.list?.data?.Symbol}
                                isCurrency={item.isCurrency}
                                tooltipText={item.tooltipText}
                            />
                        ) : (
                            <DashboardDataItem
                                key={`retainers-data-layout-dummy-item-${
                                    itemIndex + 1
                                }`}
                                title={item.title}
                                placeholder={item.placeholder}
                                count={item.count}
                                isLoading={currencyDL?.list?.isFetching}
                                currencySymbol={currencyDL?.list?.data?.Symbol}
                                isCurrency={item.isCurrency}
                                tooltipText={item.tooltipText}
                            />
                        );
                    })}
                </StyledHStack>
            ))}
        </>
    );
};

export default RetainersDashboardOverviewData;
