import styled, { css } from "styled-components";

import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import { TimesheetCell } from "pages/timesheet/timesheet-grid/StyledElements";
import TextInputField from "components/design-system/controls/input/TextInput";
import { COLOR_VALUES } from "components/design-system/config/colors";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";
import { TextInputStylesError } from "components/design-system/controls/input/StyledTextInput";
import HStack from "components/design-system/layout/HStack";

export const InputFieldWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const StyledTextInput = styled(TextInputField)`
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    height: 100%;
    margin-bottom: -1px;
    padding: 16px 12px;
    color: ${COLOR_VALUES.Gray_1};
    border-color: transparent;

    ${TEXT_VARIANT_STYLES.Body_2_2}

    &:hover {
        color: ${COLOR_VALUES.Black};

        &::placeholder {
            color: inherit;
        }
    }

    &:focus {
        border-color: ${COLOR_VALUES.Gray_2};
    }

    ${(props) =>
        props.feedbackType === FEEDBACK_TYPES.Error &&
        css`
            ${TextInputStylesError}
        `}

    &:disabled {
        background: transparent;
        border-color: transparent;
    }
`;

export const StyledTimesheetCell = styled(TimesheetCell)`
    padding: 0;
    display: flex;
    align-items: center;
    ${(props) =>
        props.disabled &&
        css`
            cursor: not-allowed;
            color: ${COLOR_VALUES.Gray_3};
            background: ${COLOR_VALUES.Gray_8};
        `}
`;

export const StyledTextCell = styled(Text)`
    padding: 16px 12px;
`;

export const ReadOnlyCellWrapper = styled(HStack)`
    width: 100%;
`;
