import { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import AccessGroupForm from "pages/admin/access-groups/AccessGroupForm";
import AccessGroupTable from "pages/admin/access-groups/AccessGroupTable";
import PageWrapper from "pages/PageWrapper";
import AccessGroupDeleteForm from "./AccessGroupDeleteConfirmationForm";
import SearchEmployeeNew from "components/Allocations/Grid/Employee/SearchEmployeeNew";
import MasterDrawer from "components/design-system/ui/drawer/MasterDrawer";
import CustomGroupContext from "context/Allocations/CustomGroupContext";
import DrawerContext from "context/Drawer/DrawerContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action");
    const id = searchParams.get("id");

    const onClose = () => {
        navigate(".", { replace: true });
    };

    if (action === "add") {
        return (
            <Modal title="Add Access Group" onRequestClose={onClose}>
                <AccessGroupForm key="new" onClose={onClose} />
            </Modal>
        );
    }

    const parsedId = parseInt(id);

    if (action === "edit" && !isNaN(parsedId)) {
        return (
            <Modal title="Edit Access Group" onRequestClose={onClose}>
                <AccessGroupForm
                    accessGroupIdToEdit={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "delete" && !isNaN(parsedId)) {
        return (
            <Modal title="Delete Access Group" onRequestClose={onClose}>
                <AccessGroupDeleteForm
                    accessGroupIdToDelete={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const AccessGroups = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const customGroupConsumer = useContext(CustomGroupContext);
    const { displaySearch, handleSearchEmployeeClick } =
        useContext(DrawerContext);
        
    const now = new Date();
    insightsTrace("VIEW", "AdminAccessGroupsPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    return (
        <PageWrapper
            title="Access Groups"
            description="View and manage permissions to access pages and actions."
            menuOptions={[{ children: "Admin", to: "/admin" }]}
            isSearch={true}
            onClickSearch={handleSearchEmployeeClick}
        >
            <FormPresenter />
            <AccessGroupTable />
            <MasterDrawer stickyAction={!displaySearch}>
                <CustomGroupContext.Provider value={customGroupConsumer}>
                    <SearchEmployeeNew />
                </CustomGroupContext.Provider>
            </MasterDrawer>
        </PageWrapper>
    );
};

export default AccessGroups;
