import React, { useState } from "react";
import ProjectDrawer from "./ProjectDrawer";
import ProjectDetail from "./ProjectDetail";
import More from "./More";
import {
    InteractiveCells,
    EmptyColumn,
    IndentColumn,
    Column,
    Wrapper,
    MoreMenuContainer,
    ClickableWrapper,
    StyledProjectDetail,
} from "./styles/ProjectStyles";

import GenericSidebarPortal from "components/Modal/Common/GenericSidebarPortal";

const MemoProjectDetail = React.memo(ProjectDetail);

const Project = ({ project, employee }) => {
    const [sidebarProjectId, setSidebarProjectId] = useState(null);

    const handleCloseDrawer = () => {
        setSidebarProjectId(null);
    };

    const handleClick = (projectId) => {
        if (parseInt(projectId) > 1) {
            if (sidebarProjectId == null){
                setSidebarProjectId(projectId);
            } else {
                setSidebarProjectId(null);
            }
        }
    };

    return (
        <>
            {sidebarProjectId && (
                <GenericSidebarPortal open={true} content={<ProjectDrawer projectId={sidebarProjectId} />} onClose={handleCloseDrawer} />
            )}
            
                <Wrapper id={`div_project_${project.jobId}`}>
                    <EmptyColumn xs={1} />
                    <Column xs={4} lg={3}>
                        {project.jobId > 1 ? (
                            <>
                                <ClickableWrapper onClick={(e) =>handleClick(project.jobId)}>
                                    <StyledProjectDetail
                                        key={project.jobId}
                                        name={project.jobName}
                                        sowStatus={project.sowStatusName}
                                        sowValue={project.sowStatusLikelihood}
                                        link
                                    />
                                </ClickableWrapper>
                                <MoreMenuContainer><More project={project} /></MoreMenuContainer>
                            </>
                        ) : (
                            <StyledProjectDetail key={project.jobId} name={project.jobName} />
                        )}
                    </Column>
                    <InteractiveCells>
                        <MemoProjectDetail
                            initialData={project.weeks}
                            cellMaxValue={120}
                            cellMaxValueResize={40}
                            cellStepValue={4}
                            employee={employee}
                            project={project}
                        />
                    </InteractiveCells>
                    <IndentColumn xs={1} />
                </Wrapper>
            
        </>
    );
};

export default Project;
