export const getDropdownOptionsNoDefaultVal = (
    optionDetails = [],
    Id = "Id",
    Name = "Name"
) => {
    return optionDetails.reduce((acc, val) => {
        return [
            ...acc,
            {
                value: val[Id],
                label: val[Name],
            },
        ];
    }, []);
};