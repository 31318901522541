import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useCombobox, useMultipleSelection } from "downshift";
import styled, { createGlobalStyle } from "styled-components";

import HStack from "components/design-system/layout/HStack";
import {
    DropdownWrapper,
    DropdownButtonWrapper,
    DropdownButton,
    DropdownButtonText,
    DropdownButtonIcon,
    DropdownButtonTextRow,
} from "components/design-system/controls/dropdown/DropdownStyledComponents";
import DropdownMultiSelectList from "components/design-system/controls/dropdown-multi-select/DropdownMultiSelectList";
import useDropdownListOptions from "components/design-system/controls/dropdown/useDropdownListOptions";
import {
    AccordionIcon,
    AccordionTitle,
} from "components/design-system/controls/accordion/AccordionStyledComponent";
import Tag from "components/design-system/controls/accordion/Tag";

export const AccordionDropdownMultiSelectList = createGlobalStyle`    
    .dropdown-list-container-ul {
        border: none;
    }
    .dropdown-list-container{
        position: relative;
        top: 0;
    }
`;

export const DropdownButtonTextRowMultiSelect = styled(HStack)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const DropdownMultiSelect = ({
    onChangeDropdown = () => void 0,
    optionsData = [],
    initialData = [],
    isVariantToggle = false,
    disabled = false,
    isSmallSize = false,
    itemsAreLoading = false,
    feedbackType,
    isAccordion = false,
    isClearAllSelectedValue = false,
    isLabeledInTag = true,
    showCountWithLabel = true,
    title,
    ...rest
}) => {
    const [optionsList, setOptionsList] = useState(optionsData);
    const initialOptionsList = useRef([]);
    const [initialLoad, setInitialLoad] = useState(true);

    const handleInitialLoadValue = () => {
        if (initialLoad) {
            setInitialLoad(false);
        }
    };

    useEffect(() => {
        setOptionsList(optionsData);
    }, [optionsData]);

    // set index for accessing the list items
    useEffect(() => {
        optionsList.forEach((optionData, index) => {
            optionsList[index] = { ...optionData, parentOptionIndex: index };

            optionData?.children?.map((childData, childIndex) => {
                optionsList[index].children[childIndex] = {
                    ...childData,
                    childOptionIndex: `${index}_${childIndex}`,
                };
            });
        });
    }, [itemsAreLoading, optionsList]);

    const {
        filteredOptions,
        filterValue,
        setFilterValue,
        internalFilterUIShown,
    } = useDropdownListOptions(optionsList);

    useEffect(() => {
        initialData?.forEach((initialDataObj) => {
            if (initialDataObj) {
                const isParent = initialDataObj?.hasOwnProperty("children");
                const isChild = initialDataObj?.hasOwnProperty("parentId");

                switch (true) {
                    case isParent:
                        let parentObj = optionsList.filter(
                            (fp) => fp.id === initialDataObj.id
                        )[0];
                        parentObj.check = true;
                        if (parentObj.hasOwnProperty("children")) {
                            parentObj.children.map((c) => (c.check = true));
                        }
                        initialOptionsList?.current.push(parentObj);
                        break;

                    case isChild:
                        const parentObjContainD = optionsList.filter(
                            (fp) => fp.id === initialDataObj.parentId
                        )[0];
                        parentObjContainD.indeterminate = true;
                        let childObj = parentObjContainD.children.filter(
                            (fpc) => fpc.id === initialDataObj.id
                        )[0];
                        childObj.check = true;
                        initialOptionsList?.current.push(childObj);
                        break;

                    default:
                        let singleObj = optionsList.filter(
                            (fs) => fs.id === initialDataObj.id
                        )[0];
                        if (singleObj) {
                            singleObj.check = true;
                            initialOptionsList?.current.push(singleObj);
                        }
                        break;
                }
            }
        });
    }, [initialData]);

    const {
        getSelectedItemProps,
        getDropdownProps,
        selectedItems,
        addSelectedItem,
        removeSelectedItem,
    } = useMultipleSelection({
        initialSelectedItems: initialOptionsList?.current,
    });

    useEffect(() => {
        /* remove duplicate objects which is adding by library add event when clicked on parent elements */
        const duplicateData = selectedItems.filter(
            (val, index, arr) => arr.indexOf(val) !== index
        );
        duplicateData.forEach((d) => removeSelectedItem(d));

        selectedItems.forEach((data) => {
            if (!data.check && data.indeterminate) {
                removeSelectedItem(data);
            }
        });
    }, [selectedItems]);

    useEffect(() => {
        initialData?.length > 0 && handleInitialLoadValue();
    }, [initialData]);

    const deselectAllOptions = () => {
        handleInitialLoadValue();
        const clearAllSelection =
            selectedItems.length > 0 ? selectedItems : optionsData;

        clearAllSelection.forEach((data) => {
            data.check = false;
            if (data.hasOwnProperty("children")) {
                data.indeterminate = false;
                data?.children?.map((childData) => (childData.check = false));
            }
            if (data.hasOwnProperty("parentId")) {
                let parentElement = optionsList.filter(
                    (f) => f.id === data.parentId
                )[0];
                parentElement.indeterminate = false;
            }
            removeSelectedItem(data);
        });
    };

    const handleOnSelectedItemChangeParentCheckbox = (
        selectedItem,
        parentObj
    ) => {
        const selectedItemHasParentId = selectedItem.hasOwnProperty("parentId");
        let allChildrenCheckboxFalse =
            selectedItemHasParentId &&
            parentObj?.children.filter((d) => d.check === false)?.length ===
                parentObj?.children.length;

        if (allChildrenCheckboxFalse) {
            parentObj.check = false;
            parentObj.indeterminate = false;
            removeSelectedItem(parentObj);
        }
        if (allChildrenCheckboxFalse && parentObj.children) {
            parentObj.children.map((data) => {
                data.check = false;
                removeSelectedItem(data);
            });
        }

        let checkAllChildrenCheckboxTrue =
            selectedItemHasParentId &&
            parentObj?.children.filter((d) => d.check === true)?.length ===
                parentObj?.children.length;

        if (checkAllChildrenCheckboxTrue) {
            parentObj.check = true;
            parentObj.indeterminate = false;
            addSelectedItem(parentObj);
        }
        if (checkAllChildrenCheckboxTrue && parentObj.children) {
            parentObj.children.map((data) => {
                data.check = true;
                removeSelectedItem(data);
            });
        }
    };

    const handleOnSelectedItemChange = (selectedItem) => {
        let parenIndex = null;
        let childIndex = null;
        let obj = null;

        handleInitialLoadValue();
        const selectedItemHasParentId = selectedItem.hasOwnProperty("parentId");
        if (selectedItemHasParentId) {
            parenIndex = optionsList.findIndex(
                (d) => d.id == selectedItem.parentId
            );
            childIndex = optionsList[parenIndex]?.children.findIndex(
                (d) => d.id == selectedItem.id
            );

            obj = optionsList[parenIndex].children[childIndex];
            optionsList[parenIndex].check = false;
            optionsList[parenIndex].indeterminate = true;
        }

        let parentObj = optionsList[parenIndex];

        switch (true) {
            case !selectedItemHasParentId &&
                !selectedItem.check &&
                !selectedItems.some((d) => d.id === selectedItem.id):
                {
                    addSelectedItem(selectedItem);
                    selectedItem.check = true;
                    selectedItem.indeterminate = false;

                    if (selectedItem.children) {
                        selectedItem.children.map((data) => {
                            data.check = true;
                            removeSelectedItem(data);
                        });
                    }
                }
                break;

            case !selectedItemHasParentId && selectedItem.check:
                {
                    removeSelectedItem(selectedItem);
                    selectedItem.check = false;
                    selectedItem.indeterminate = false;
                    if (selectedItem.children) {
                        selectedItem.children.map((data) => {
                            data.check = false;
                            removeSelectedItem(data);
                        });
                    }
                }
                break;

            case selectedItemHasParentId && obj.check:
                {
                    removeSelectedItem(selectedItem);
                    obj.check = false;
                    let trueDataArr = parentObj?.children?.filter(
                        (d) => d.check === true
                    );
                    if (trueDataArr?.length !== parentObj?.children?.length) {
                        parentObj.check = false;
                        parentObj.indeterminate = true;
                        removeSelectedItem(parentObj);
                        trueDataArr.map((data) => {
                            addSelectedItem(data);
                        });
                    }
                }
                break;

            case selectedItemHasParentId &&
                !selectedItems.some((d) => d.id === selectedItem.id):
                {
                    addSelectedItem(selectedItem);
                    obj.check = true;
                }
                break;

            default:
                break;
        }

        selectedItemHasParentId &&
            handleOnSelectedItemChangeParentCheckbox(selectedItem, parentObj);
    };

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectItem,
        openMenu,
    } = useCombobox({
        inputValue: "",
        selectedItem: null,
        items: optionsList,
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                        highlightedIndex: state.highlightedIndex,
                    };
            }
            return changes;
        },

        onSelectedItemChange: ({ type, selectedItem }) => {
            if (
                type === useCombobox.stateChangeTypes.ItemClick ||
                type === useCombobox.stateChangeTypes.FunctionSelectItem
            ) {
                handleOnSelectedItemChange(selectedItem);
            }
        },
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    setFilterValue(inputValue);
                    break;
                case useCombobox.stateChangeTypes.ToggleButtonClick:
                    handleInitialLoadValue();
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setFilterValue("");
                    }
                    break;
                default:
                    break;
            }
        },
    });

    useEffect(() => {
        !initialLoad && onChangeDropdown(selectedItems);
    }, [selectedItems, initialLoad]);

    useEffect(() => {
        if (disabled && isOpen) {
            openMenu();
        }
    }, [disabled]);

    useEffect(() => {
        if (isClearAllSelectedValue) {
            deselectAllOptions();
        }
    }, [isClearAllSelectedValue]);

    const onClickClearSearchFieldAndResetList = () => {
        setFilterValue("");
    };

    const selectedOptionsLength = selectedItems.reduce((total, element) => {
        const len = element.children ? element.children.length : 1;
        return total + len;
    }, 0);

    const getTagLabel = (selectedItem) => {
        if (isLabeledInTag) {
            return selectedItem.label;
        } else {
            return selectedItem.value;
        }
    };

    const getTagCount = () => {
        if (showCountWithLabel) {
            return selectedOptionsLength > 1 ? "Options" : "Option";
        } else return "";
    };

    return (
        <>
            <DropdownWrapper isAccordion={isAccordion}>
                <DropdownButtonWrapper
                    isDisabled={disabled}
                    {...getComboboxProps()}
                >
                    <DropdownButton
                        feedbackType={feedbackType}
                        isDisabled={disabled}
                        type="button"
                        {...getToggleButtonProps({
                            onClick: onClickClearSearchFieldAndResetList,
                        })}
                        aria-label={"toggle menu"}
                        isAccordion={isAccordion}
                        isSmallSize={isSmallSize}
                        {...rest}
                    >
                        <DropdownButtonTextRow
                            justify="space-between"
                            {...getInputProps()}
                        >
                            {selectedOptionsLength <= 0 && !isAccordion && (
                                <DropdownButtonText
                                    variant="Body_1_1"
                                    color="Gray_2"
                                >
                                    Select
                                </DropdownButtonText>
                            )}
                            {isAccordion && (
                                <AccordionTitle
                                    variant="Headline_5"
                                    show={isOpen}
                                >
                                    {title}
                                </AccordionTitle>
                            )}
                            <DropdownButtonTextRowMultiSelect
                                spacing="Zero_25"
                                {...getDropdownProps()}
                            >
                                {selectedOptionsLength !== 0 &&
                                    !isSmallSize &&
                                    selectedItems.map((selectedItem, index) => (
                                        <HStack
                                            key={`selected-item-${index}`}
                                            {...getSelectedItemProps({
                                                selectedItem,
                                                index,
                                            })}
                                            spacing="Zero"
                                        >
                                            <Tag
                                                label={
                                                    selectedItem.hasOwnProperty(
                                                        "parentId"
                                                    )
                                                        ? `${
                                                              optionsList[
                                                                  optionsList.findIndex(
                                                                      (i) =>
                                                                          i.id ===
                                                                          selectedItem.parentId
                                                                  )
                                                              ]?.label
                                                          }: ${getTagLabel(
                                                              selectedItem
                                                          )}`
                                                        : getTagLabel(
                                                              selectedItem
                                                          )
                                                }
                                                handleButtonOnClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOnSelectedItemChange(
                                                        selectedItem
                                                    );
                                                }}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            />
                                        </HStack>
                                    ))}
                                {selectedOptionsLength !== 0 && isSmallSize && (
                                    <>
                                        <Tag
                                            label={` ${selectedOptionsLength} 
                                            ${getTagCount()} `}
                                            handleButtonOnClick={(e) => {
                                                e.stopPropagation();
                                                deselectAllOptions();
                                            }}
                                        />
                                    </>
                                )}
                                {isAccordion && (
                                    <AccordionIcon
                                        name={
                                            isOpen ? "caret-up" : "caret-down"
                                        }
                                        show={isOpen}
                                    />
                                )}
                            </DropdownButtonTextRowMultiSelect>
                            {!isAccordion && (
                                <DropdownButtonIcon
                                    name="caret-down"
                                    color="Gray_2"
                                    style={{ flexShrink: 0 }}
                                />
                            )}
                        </DropdownButtonTextRow>
                    </DropdownButton>
                </DropdownButtonWrapper>

                {isAccordion && <AccordionDropdownMultiSelectList />}
                <DropdownMultiSelectList
                    isBorder={isAccordion ? false : true}
                    isVariantToggle={isVariantToggle}
                    isOpen={isOpen}
                    optionsList={filteredOptions}
                    optionsAreLoading={itemsAreLoading}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    internalFilterUIShown={internalFilterUIShown}
                    getLabelProps={getLabelProps}
                    getMenuProps={getMenuProps}
                    getInputProps={getInputProps}
                    getDropdownProps={getDropdownProps}
                    highlightedIndex={highlightedIndex}
                    getItemProps={getItemProps}
                    selectItem={selectItem}
                    onClickClearSearchFieldAndResetList={
                        onClickClearSearchFieldAndResetList
                    }
                />
            </DropdownWrapper>
        </>
    );
};

DropdownMultiSelect.propTypes = {
    disabled: PropTypes.bool,
    isSmallSize: PropTypes.bool,
    itemsAreLoading: PropTypes.bool,
};

export default DropdownMultiSelect;
