let build_json = null;
//.version to get version number and .date to get version date
const Build = {
    get: async function () {
        let i = Math.floor(Math.random() * 1000000);
        if (build_json !== null) {
            return build_json;
        } else {
            return fetch("build.json?i=" + i)
                .then(function (response) {
                    return response.status != 204 ? response.json() : null;
                })
                .then(function (json) {
                    build_json = json;
                    return build_json;
                });
        }
    },
};

export default Build;
