import { useEffect, useState, useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { StyledHeader } from "components/design-system/ui/drawer/StyledDrawer";
import SectionHead from "components/design-system/ui/SectionHead";
import VStack from "components/design-system/layout/VStack";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import FormInputWrapper from "components/design-system/ui/FormInputWrapper";
import Checkbox from "components/design-system/controls/checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const StyledMessageWrapper = styled(VStack)`
    padding: 32px;
    gap: 32px;
`;

const StyledAction = styled(ModalActions)`
    padding: 16px 32px 32px;
    gap: 8px;
`;

const StyledRedirectToFooter = createGlobalStyle`
    footer {
        position: absolute;
        bottom: 0;
    }
`;

const Wrapper = styled.div`
    heigth: 100%;
    width: 480px;
    position: relative;
    padding-top: 38px;
`;

const RedirectTo = () => {
    // const [counter, setCounter] = useState(60);
    const [checked, setChecked] = useState(false);
    const [sessionStatus, setSessionStatus] = useState(localStorage.getItem("VPNAlert"));
    const queryParameters = new URLSearchParams(window.location.search)
    const redirectTo = queryParameters.get("url");
    const env = queryParameters.get("env");
    const vpn = queryParameters.get("vpn");
    const navigate = useNavigate();
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();

    const now = new Date();
    insightsTrace("VIEW", "RedictToPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });


    const handleCancel = () => {
        if (window.history.length < 2) {
            window.close();
        } else {
            navigate(-1);
        }
    };


    // useEffect(() => {
    //     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    //     if (counter == 0) window.location.replace(redirectTo);
    //     return () => clearInterval(timer); 
    // }, [counter]);

    useEffect(() => {
        if (sessionStatus && sessionStatus === "true") {
            window.location.replace(redirectTo);
        }
    }, [sessionStatus]);

    const rememberCheck = () => {
        localStorage.setItem("VPNAlert", !checked);
        setChecked(!checked);
    }

    // const getDomain = (url)=> {
    //     let site = new URL(url);
    //     return site.hostname;
    // }
    // const toNavigateDomain = getDomain(redirectTo);
    // const requireVPN = <div style={{paddingBottom: "50px", color: "red"}}><b>You are going to a page that requires a VPN connection, please connect</b></div>;

    return (
        (!sessionStatus || sessionStatus === "false") && (
            <Wrapper>
                {/* <Message>
                <div style={{paddingBottom: "50px"}}><b>Navigating to an Internal Site</b></div>
                {vpn && requireVPN}
                <div style={{paddingBottom: "50px"}}>You are being connected to: <a style={{color: "blue"}} href={redirectTo}>{toNavigateDomain}</a> in <span style={{color: "red"}}>{counter}</span> seconds... (click in the link to navigate now)</div> 
                <div>Don't alert me again in today's session: <input type="checkbox" checked={checked} onChange={rememberCheck}></input></div>
            </Message> */}
                <>
                    <Wrapper>
                        <StyledHeader>
                            <SectionHead title="Are you connected to the VPN?" />
                        </StyledHeader>
                        <StyledMessageWrapper>
                            <VStack spacing="Two" style={{ paddingTop: "32px" }}>
                                <div
                                    variant="Body_2_1"
                                    color="Gray_1"
                                >
                                    {`You are going to a page that requires VPN connection.\n
                                 Make sure you are connected before continuing.`}
                                </div>
                                <FormInputWrapper
                                    feedbackType={checked ? "Success" : "None"}
                                    feedbackMessage={
                                        checked &&
                                        "You won’t be asked again during this session."
                                    }
                                >
                                    <Checkbox
                                        label="Don’t ask again."
                                        onChange={(e) => rememberCheck(e)}
                                    />
                                </FormInputWrapper>
                            </VStack>
                        </StyledMessageWrapper>
                        <StyledAction
                            primaryAction={{
                                type: "button",
                                label: "Continue",
                                leadingIcon: "arrow-right",
                                href: redirectTo,
                                rel: "noreferrer",
                                target: "_self",
                            }}
                            secondaryAction={{
                                type: "button",
                                label: "Cancel",
                                onClick: handleCancel,
                            }}
                        />
                    </Wrapper>
                    <StyledRedirectToFooter />
                </>
            </Wrapper>
        )
    );
};

export default RedirectTo;
