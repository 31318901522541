import React, { useState, useMemo, useCallback, useRef } from "react";
import { isEqual, uniq, without } from "lodash";

const EmployeeGridSelectionContext = React.createContext();

function getCleanArray(array) {
    return uniq(array.sort(), true);
}

export const EmployeeGridSelectionProvider = (props) => {
    const employeeNameCache = useRef(new Map());
    const [selectionIsEnabled, setSelectionIsEnabled] = useState(false);
    const [selectedEmployeeIDs, setSelectedEmployeeIDs] = useState([]);
    const [allSelectableEmployeeIDs, setAllSelectableEmployeeIDs] = useState(
        []
    );

    const allSelected = useMemo(
        () =>
            allSelectableEmployeeIDs.length > 0 &&
            isEqual(selectedEmployeeIDs, allSelectableEmployeeIDs),
        [allSelectableEmployeeIDs, selectedEmployeeIDs]
    );

    const addToEmployeeNameCache = useCallback((id, name) => {
        employeeNameCache.current.set(id, name);
    }, []);

    const getEmployeeNameFromCache = useCallback((id) => {
        return employeeNameCache.current.get(id);
    }, []);

    const getNamesFromSelectedIDs = useCallback(
        (args = {}) => {
            const { idToExclude } = args;
            const employeeNames = [];

            for (const employeeId of selectedEmployeeIDs) {
                if (employeeId !== idToExclude) {
                    const employeeName = getEmployeeNameFromCache(employeeId);

                    if (employeeName) {
                        employeeNames.push(employeeName);
                    }
                }
            }

            return employeeNames;
        },
        [getEmployeeNameFromCache, selectedEmployeeIDs]
    );

    const updateSelectableIDs = useCallback(
        (newIdList) => {
            const cleanNewIdList = getCleanArray(newIdList);

            if (!isEqual(allSelectableEmployeeIDs, cleanNewIdList)) {
                setSelectedEmployeeIDs([]);
                setAllSelectableEmployeeIDs(cleanNewIdList);
                setSelectionIsEnabled(false);
            }
        },
        [allSelectableEmployeeIDs]
    );

    const addIdToSelection = useCallback(
        (id) => {
            if (allSelectableEmployeeIDs.includes(id)) {
                setSelectedEmployeeIDs(
                    getCleanArray([id, ...selectedEmployeeIDs])
                );
            }
        },
        [allSelectableEmployeeIDs, selectedEmployeeIDs]
    );

    const removeIdFromSelection = useCallback(
        (id) => {
            setSelectedEmployeeIDs(without(selectedEmployeeIDs, id));
        },
        [selectedEmployeeIDs]
    );

    const selectAllEmployeeIDs = useCallback(() => {
        setSelectedEmployeeIDs(allSelectableEmployeeIDs);
    }, [allSelectableEmployeeIDs]);

    const deselectAllEmployeeIDs = () => {
        setSelectedEmployeeIDs([]);
    };

    const contextValue = useMemo(() => {
        return {
            selectionIsEnabled,
            setSelectionIsEnabled,
            allSelectableEmployeeIDs,
            selectedEmployeeIDs,
            allSelected,
            updateSelectableIDs,
            addIdToSelection,
            removeIdFromSelection,
            selectAllEmployeeIDs,
            deselectAllEmployeeIDs,
            addToEmployeeNameCache,
            getEmployeeNameFromCache,
            getNamesFromSelectedIDs,
        };
    }, [
        addIdToSelection,
        addToEmployeeNameCache,
        allSelectableEmployeeIDs,
        allSelected,
        getEmployeeNameFromCache,
        removeIdFromSelection,
        selectAllEmployeeIDs,
        selectedEmployeeIDs,
        selectionIsEnabled,
        updateSelectableIDs,
        getNamesFromSelectedIDs,
    ]);

    return (
        <EmployeeGridSelectionContext.Provider value={contextValue}>
            {props.children}
        </EmployeeGridSelectionContext.Provider>
    );
};

export default EmployeeGridSelectionContext;
