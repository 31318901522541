import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import VStack from "components/design-system/layout/VStack";
import Options from "components/design-system/forms/Options";
import TextArea from "components/design-system/forms/TextArea";
import Form from "components/design-system/forms/Form";
import Text from "components/design-system/ui/Text";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import { StyledHR } from "components/design-system/ui/drawer/DataGroup";
import Dropdown from "components/design-system/forms/Dropdown";
import DataItem from "components/design-system/ui/drawer/DataItem";
import DateRangePicker from "components/design-system/forms/DateRangePicker";
import HStack from "components/design-system/layout/HStack";
import Tooltip from "components/design-system/ui/Tooltip";
import Icon from "components/design-system/ui/Icon";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import { sendNotification } from "utilities/Notification";
import {
    getIcon,
    getIconColor,
    getStatusLabel,
} from "pages/timesheet/manage-time-off/ManageTimeOffTable";
import useTimeOffApi from "pages/timesheet/time-off/useTimeOff";
import useManageUserTimeOff from "pages/timesheet/manage-time-off/useManageUserTimeOff";
import SessionContext from "context/app/SessionContext";
import ManageTimeOffContext from "context/ManageTimeOff/ManageTimeOffContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const daysOptions = [
    { label: "Full Day", value: "FullDay" },
    { label: "Morning Out", value: "MorningOut" },
    { label: "Afternoon Out", value: "AfternoonOut" },
];

const StyledVStack = styled(VStack)`
    padding: 24px 0px;
    gap: 24px;
`;
const OptionsWrapper = styled.div`
    margin-bottom: 2rem;
`;

const StyledTextArea = styled(TextArea)`
    gap: 16px;
    textarea {
        min-height: 160px;
    }
`;

const SupportWrapper = styled(VStack)`
    margin-top: 1rem;
`;

const TalentSupportMail = styled(HStack)`
    cursor: pointer;
    padding-right: 6px;
`;

const StyledIcon = styled(Icon)`
    align-self: center;
`;

function setDayOption(morning, afternoon) {
    if (morning && afternoon) {
        return "FullDay";
    }
    if (morning) {
        return "MorningOut";
    } else {
        return "AfternoonOut";
    }
}

function transformTimeOffDataToFormData(timeOffData = {}) {
    return {
        OOOType: timeOffData.reasonId,
        date: {
            start: format(new Date(timeOffData.firstDayOut), "yyyy-MM-dd"),
            end: format(new Date(timeOffData.lastDayOut), "yyyy-MM-dd"),
        },
        firstDayOptions: setDayOption(
            timeOffData.firstDayOutMorning,
            timeOffData.firstDayOutAfternoon
        ),
        lastDayOptions: setDayOption(
            timeOffData.lastDayOutMorning,
            timeOffData.lastDayOutAfternoon
        ),
        note: timeOffData.note,
    };
}

const ApproveTimeOffForm = ({ timeOffRequestId, onClose }) => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    const { useGetTimeOffReasonsForNewRequest, useTimeOff } = useTimeOffApi();
    const { approveTimeOff } = useManageUserTimeOff();
    const [reasonData, setReasonData] = useState();
    const [hideSecondDayOptions, setHideSecondDayOptions] = useState(true);
    const [supportMailTooltipText, setSupportMailTooltipText] =
        useState("Copy");
    const { isManageViewRequest, manageTimeOffRequestsData } =
        useContext(ManageTimeOffContext);

    const now = new Date();
    insightsTrace("VIEW", "ApproveTimeOffForm", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });

    const currentManageUserData = manageTimeOffRequestsData?.find(
        (data) => data.id === timeOffRequestId
    );

    const year = new Date(currentManageUserData?.firstDayOut).getFullYear();
    const dataReasonsRequest = {
        employeeId: session.legacyId,
        year: year
    };

    const timeOffData = useTimeOff(dataReasonsRequest);
    const reasons = useGetTimeOffReasonsForNewRequest(dataReasonsRequest);

    const isEditing = typeof timeOffRequestId !== "undefined";

    const getReasonNoLongerAvailableForUser = () => {
        if (!reasons?.isLoading && reasons?.data && currentManageUserData) {
            var isReasonAvailable = reasons?.data.some(x => x.reasonId === currentManageUserData.reasonId)
            if (isReasonAvailable) {
                return reasons?.data;
            }
            else {
                return [{
                    reasonId: currentManageUserData.reasonId,
                    reasonName: currentManageUserData.reasonName
                }];
            }
        }
    };

    const reasonsDropdownOptions = !reasons.isLoading
        ? getDropdownOptions(getReasonNoLongerAvailableForUser(), "reasonId", "reasonName")
        : [];

    useEffect(() => {
        if (!timeOffData.isLoading && currentManageUserData) {
            let reasonData = timeOffData?.data?.find(
                (timeOff) =>
                    timeOff?.reasonCode === currentManageUserData?.reasonCode
            );
            setReasonData(reasonData);
        }
    }, [currentManageUserData, timeOffData]);

    if (isEditing && !currentManageUserData) {
        return <div>Could not find holiday</div>;
    }

    if (timeOffData.isLoading || reasons?.isLoading) {
        return <>Loading...</>;
    }

    if (timeOffData.isError || reasons?.isError) {
        return <>Error</>;
    }

    const setTalentSupportEmail = (isClick) => {
        if (isClick) {
            navigator.clipboard.writeText("TalentSupport@rga.com");
            setSupportMailTooltipText("Copied");
        } else {
            setSupportMailTooltipText("Copy");
        }
    };

    const dateRangeChange = (dates) => {
        const start = dates.start;
        const end = dates.end;

        const show =
            start &&
            end &&
            (start.day !== end.day ||
                start.month !== end.month ||
                start.year !== end.year);

        setHideSecondDayOptions(!show);
    };

    const modifier = approveTimeOff;

    const onSubmit = () => {
        const allData = {
            timeOffRequestId: timeOffRequestId,
        };

        modifier.mutateAsync(allData).then(() => {
            onClose();
            sendNotification(
                undefined,
                <>
                    Time off request for{" "}
                    {currentManageUserData?.employeeFullName} has been approved
                </>
            );
        });
    };

    return (
        <>
            <Form
                onSubmit={onSubmit}
                defaultValues={transformTimeOffDataToFormData(
                    currentManageUserData
                )}
            >
                <VStack spacing="Two">
                    <StyledVStack>
                        <Text variant="Headline_3" color="Black">
                            {currentManageUserData?.employeeFullName}
                        </Text>
                        <VStack spacing="Zero_25">
                            <Text variant="Descriptive_1_1" color="Gray_2">
                                Submitted
                            </Text>
                            <Text variant="Body_2_1" color="Black">
                                {format(
                                    new Date(
                                        currentManageUserData?.createdDate
                                    ),
                                    "dd MMM, yyyy"
                                )}
                            </Text>
                        </VStack>
                        <StyledHR />
                    </StyledVStack>
                </VStack>
                <VStack spacing="Two">
                    {isManageViewRequest && (
                        <>
                            <DataItem
                                label="Request Decision"
                                updatedDateLabel="Submitted"
                                updatedDate={format(
                                    new Date(
                                        currentManageUserData?.createdDate
                                    ),
                                    "dd MMM, yyyy"
                                )}
                                iconName={getIcon(
                                    currentManageUserData?.status
                                )}
                                title={getStatusLabel(currentManageUserData)}
                                textMessage={
                                    getStatusLabel(currentManageUserData) ===
                                    "Pending HR Approval" &&
                                    getStatusLabel(currentManageUserData)
                                }
                                iconColor={getIconColor(
                                    currentManageUserData?.status
                                )}
                                isPadding={false}
                            />
                        </>
                    )}
                    <VStack spacing="One">
                        <Dropdown
                            name="OOOType"
                            label="Type Of Request"
                            options={reasonsDropdownOptions}
                            itemsAreLoading={reasons.isLoading}
                            disabled={true}
                        />
                        {(reasonData && reasonData.allotment !== 0)
                            &&
                            <VStack >
                                <Text variant="Descriptive_1_1" color="Gray_2">
                                    Remaining / Total
                                </Text>
                                <Text variant="Body_2_1" color="Black">
                                    {reasonData?.daysRemaining} /{" "}
                                    {reasonData?.allotment}
                                </Text>
                            </VStack>
                        }
                    </VStack>
                    <DateRangePicker
                        name="date"
                        label="Date"
                        onChange={dateRangeChange}
                        disabled={true}
                    />

                    <OptionsWrapper>
                        <HStack spacing="Seven">
                            <Options
                                name="firstDayOptions"
                                label={"Start Date"}
                                multiselect={false}
                                options={daysOptions}
                                disabled={true}
                            />
                            {!hideSecondDayOptions && (
                                <Options
                                    name="lastDayOptions"
                                    label={"End Date"}
                                    multiselect={false}
                                    options={daysOptions}
                                    disabled={true}
                                />
                            )}
                        </HStack>
                    </OptionsWrapper>

                    <StyledTextArea
                        name="note"
                        label="Provide further information"
                        description="This field is optional"
                        disabled={true}
                    />
                    <SupportWrapper spacing="One">
                        <HStack spacing="Zero_5">
                            <Text variant="Body_2_1" color="Gray_2">
                                If there are any issues, please contact
                            </Text>
                            {
                                <Tooltip
                                    placement="right"
                                    enterDelay={0}
                                    text={supportMailTooltipText}
                                >
                                    <TalentSupportMail
                                        spacing="Zero_25"
                                        onMouseEnter={() =>
                                            setTalentSupportEmail(false)
                                        }
                                        onClick={() =>
                                            setTalentSupportEmail(true)
                                        }
                                    >
                                        <Text variant="Body_2_2" color="Black">
                                            Talent Support
                                        </Text>
                                        <StyledIcon name="mail" />
                                    </TalentSupportMail>
                                </Tooltip>
                            }
                        </HStack>
                    </SupportWrapper>
                </VStack>
                <ModalActions
                    primaryAction={
                        !isManageViewRequest
                            ? {
                                type: "submit",
                                label: "Approve",
                                leadingIcon: "success",
                            }
                            : null
                    }
                    secondaryAction={
                        isManageViewRequest && {
                            type: "button",
                            label: "Close",
                            onClick: onClose,
                            fullWidth: isManageViewRequest,
                        }
                    }
                    tertiaryAction={
                        !isManageViewRequest && {
                            type: "button",
                            label: "Reject",
                            leadingIcon: "error",
                            to: `?action=rejectRequest&id=${timeOffRequestId}`,
                        }
                    }
                />
            </Form >
        </>
    );
};

export default ApproveTimeOffForm;
