import React from "react";

import VStack from "components/design-system/layout/VStack";
import styled, { css } from "styled-components/macro";
import Text from "components/design-system/ui/Text";
import HStack from "components/design-system/layout/HStack";
import COLORS, { COLOR_VALUES } from "components/design-system/config/colors";

const TimeOffCard = styled(VStack)`
    box-sizing: border-box;
    gap: 8px;
    border: 1px solid ${COLOR_VALUES.Gray_6};
    border-radius: 4px;
    flex: 2 1 auto;
    max-width: 19.75rem;
    min-width: 9.38rem;

    ${(props) =>
        props.isSmallSize
            ? css`
                  padding: 12px 8px 16px 16px;
              `
            : css`
                  padding: 16px 8px 24px 16px;
              `}
`;

const StyledTextLabel = styled(Text)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const VStackWrapper = styled(VStack)`
    flex-grow: 1;
    max-width: 50%;
    padding-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const TotalWrapper = styled.div`
    flex-grow: 1;
`;

const VerticalLine = styled.div`
    margin-right: 16px;
    border-left: 1px solid ${COLOR_VALUES.Gray_6};
`;

const SummaryTimeOffCard = ({
    isSmallSize = false,
    currentCards = null,
    title,
    remainingTime,
    totalTime,
}) => {
    const titleVariant = isSmallSize ? "Descriptive_2_2" : "Descriptive_1_2";
    const labelVariant = isSmallSize ? "Descriptive_2_1" : "Descriptive_1_1";
    const labelContentVariant = isSmallSize ? "Headline_4" : "Headline_3";
    
    return (
        <TimeOffCard isSmallSize={isSmallSize} currentCards={currentCards}>
            <VStack>
                <StyledTextLabel variant={titleVariant} color={COLORS.Gray_1}>
                    {title}
                </StyledTextLabel>
            </VStack>
            <HStack spacing="Zero">
                <VStackWrapper spacing={"Zero"}>
                    <StyledTextLabel
                        variant={labelVariant}
                        color={COLORS.Gray_2}
                    >
                        {"Remaining "}
                    </StyledTextLabel>
                    {remainingTime < 0 ? (
                        <StyledTextLabel
                            variant={labelContentVariant}
                            color={COLORS.Orange_1}
                        >
                            ({remainingTime * -1})
                        </StyledTextLabel>
                    ) : (
                        <StyledTextLabel
                            variant={labelContentVariant}
                            color={COLORS.Black}
                        >
                            {remainingTime}
                        </StyledTextLabel>
                    )}
                </VStackWrapper>
                <VerticalLine></VerticalLine>
                <TotalWrapper spacing="Zero">
                    <Text variant={labelVariant} color={COLORS.Gray_2}>
                        Total
                    </Text>
                    <Text variant={labelContentVariant} color={COLORS.Black}>
                        {totalTime}
                    </Text>
                </TotalWrapper>
            </HStack>
        </TimeOffCard>
    );
};

export default SummaryTimeOffCard;
