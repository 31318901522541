// for css attribute:
import "styled-components/macro";

import { StyledAction } from "components/design-system/ui/modal/StyledMasterModal";
import HStack from "components/design-system/layout/HStack";
import PrimaryButton from "components/design-system/controls/button/PrimaryButton";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import Loading from "components/UI/Loading";
import styled from "styled-components";

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`;

const ModalActions = ({
    primaryAction,
    secondaryAction,
    tertiaryAction,
    isSubmiting,
    ...rest
}) => {
    return (
        <StyledAction {...rest}>
            {
                isSubmiting ?
                <LoadingWrapper>
                    <Loading></Loading>
                </LoadingWrapper>
                : 
                <HStack spacing="Zero_5">
                    {tertiaryAction && !isSubmiting && <TertiaryButton {...tertiaryAction} />}
                    {secondaryAction && !isSubmiting && <SecondaryButton {...secondaryAction} />}
                    {primaryAction && !isSubmiting && (
                        <PrimaryButton css="flex: 1" {...primaryAction} />
                    )}
                </HStack>
            }
        </StyledAction>
    );
};

export default ModalActions;
