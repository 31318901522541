import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { format } from "date-fns";

import Dropdown from "components/design-system/forms/Dropdown";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import DatePicker from "components/design-system/forms/DatePicker";
import Options from "components/design-system/forms/Options";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useClientBizGroups, {
    useBizGroups,
} from "pages/admin/clients/single/useClientBizGroups";
import {
    getCalendarDateFromNewDate,
    getDropdownOptions,
} from "pages/admin/offices/OfficesForm";
import { useClientsFilterApi } from "pages/admin/projects/single/useProjectsLinked";

const attributesOptions = [{ label: "Active", value: "Active" }];

const ModalCTA = ({
    isEditing,
    isLoading,
    onClose,
    handleOnClickDeleteBizGroup,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteBizGroup(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformBizGroupDataToFormData(bizGroupData = {}, clientId = "") {
    return {
        group: bizGroupData.BizGroupId ?? "",
        client: bizGroupData.ClientId ?? clientId,
        effectiveDate: bizGroupData.EffectiveDate
            ? format(new Date(bizGroupData.EffectiveDate), "yyyy-MM-dd")
            : null,
        retireDate: bizGroupData.RemovedDate
            ? format(new Date(bizGroupData.RemovedDate), "yyyy-MM-dd")
            : null,
        attributes:
            bizGroupData?.RemovedDate?.length > 0
                ? ""
                : attributesOptions[0].value,
    };
}

function transformFormDataToBizGroupData(formData = {}, bizGroupData = {}) {
    const newBizGroupData = { ...bizGroupData };
    newBizGroupData.BizGroupId = formData.group;
    newBizGroupData.ClientId = formData.client;
    newBizGroupData.EffectiveDate = formData.effectiveDate;
    newBizGroupData.RemovedDate = formData.retireDate ?? null;

    return newBizGroupData;
}

const RenderEffectiveDate = ({ effectiveDate }) => {
    const currentEffectiveDateDate = effectiveDate
        ? getCalendarDateFromNewDate(new Date(effectiveDate))
        : null;

    return (
        <DatePicker
            defaultDateValue={currentEffectiveDateDate}
            name="effectiveDate"
            label="Effective Date"
            validations={{
                required: true,
            }}
        />
    );
};

const RenderRetireDate = ({ retiredDate }) => {
    const currentRetiredDate = retiredDate
        ? getCalendarDateFromNewDate(new Date(retiredDate))
        : null;

    const currentAttribute = useWatch({ name: "attributes" });
    const displayRetireDate = currentAttribute !== attributesOptions[0].value;

    return displayRetireDate ? (
        <DatePicker
            defaultDateValue={currentRetiredDate}
            name="retireDate"
            label="Retire Date"
            validations={{
                required: true,
            }}
        />
    ) : (
        <></>
    );
};

const ClientBizGroupsForm = ({ bizGroupIdToEdit, onClose }) => {
    const {
        clientBizGroups,
        createClientBizGroup,
        updateClientBizGroup,
        deleteClientBizGroup,
    } = useClientBizGroups();
    const { bizGroups } = useBizGroups();
    const { clientId } = useParams();
    const { clientsFilter } = useClientsFilterApi();

    if (clientBizGroups.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientBizGroups.error) {
        return <div>Error</div>;
    }

    const clientOptions = !clientsFilter.isLoading
        ? getDropdownOptions(clientsFilter?.data, "ClientId")
        : [];

    const isEditing = typeof bizGroupIdToEdit !== "undefined";
    const modifier = isEditing ? updateClientBizGroup : createClientBizGroup;

    const currentClientBizGroupData = isEditing
        ? clientBizGroups?.data.find(
              (bizGroup) => bizGroup.BizGroupClientId === bizGroupIdToEdit
          )
        : "";

    if (isEditing && !currentClientBizGroupData) {
        return <div>Could not find contact lead</div>;
    }

    const bizGroupOptions = !bizGroups.isLoading
        ? getDropdownOptions(bizGroups?.data, "BizGroupId", "BizGroupName")
        : [];

    const handleOnClickDeleteBizGroup = (prevData) => {
        deleteClientBizGroup
            .mutateAsync({ clientBizGroupId: bizGroupIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={
                            bizGroupOptions.find(
                                (f) =>
                                    f.value ===
                                    currentClientBizGroupData.BizGroupId
                            )?.label
                        }
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    true,
                    {
                        label: "Undo",
                        onClick: () => onSubmit(prevData),
                    }
                );
            });
    };

    const onSubmit = (formData) => {
        const clientBizGroupsData = transformFormDataToBizGroupData(
            formData,
            currentClientBizGroupData
        );

        const bizGroupId = isEditing
            ? clientBizGroupsData?.BizGroupId
            : formData.group;
        const bizGroupName = bizGroupOptions.find(
            (f) => f.value === bizGroupId
        )?.label;

        const allData = {
            body: clientBizGroupsData,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={bizGroupName}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformBizGroupDataToFormData(
                currentClientBizGroupData,
                parseInt(clientId)
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <Dropdown
                        name="client"
                        validations={{
                            required: true,
                        }}
                        label="Client"
                        options={clientOptions}
                        itemsAreLoading={clientsFilter.isLoading}
                    />
                    <Dropdown
                        name="group"
                        validations={{
                            required: true,
                        }}
                        label="Group"
                        options={bizGroupOptions}
                    />
                    <VStack>
                        <RenderEffectiveDate
                            effectiveDate={
                                currentClientBizGroupData?.EffectiveDate
                            }
                        />
                        <Options
                            name="attributes"
                            options={attributesOptions}
                        />
                        <RenderRetireDate
                            retiredDate={currentClientBizGroupData?.RemovedDate}
                        />
                    </VStack>
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteBizGroup={handleOnClickDeleteBizGroup}
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default ClientBizGroupsForm;
