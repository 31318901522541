import { Outlet, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import moment from "moment";
import { format, startOfWeek } from "date-fns";

import GlobalConfig from "configs/Global";
import COLORS from "components/design-system/config/colors";
import PageWrapper from "pages/PageWrapper";
import ProjectsDashboard from "pages/projects/project-dashboard/ProjectsDashboard";

import { useReloadData } from "pages/projects/useReloadData";
import { useScroll } from "pages/projects/useScroll";
import { getReporterUrl } from "pages/projects/useExternalUrl";

import ProjectsDashboardLayoutContext from "context/Projects/ProjectsDashboardLayoutContext";
import { useProjectsApi } from "pages/admin/projects/useProjects";
import { useProjectLeadAssignment } from "pages/projects/project-dashboard/single/useProjectsDashboardDetailsData";
import useOffices from "pages/admin/offices/useOffices";

const ampersand = encodeURIComponent("&");

const REGION_NAME = "Latin America";

const projectsTabs = (projectId, projectData, offices) => {    
    const isRegionLATAM = offices?.data?.filter(f => f.RegionName === REGION_NAME)?.some(s => s.OfficeRegionId === projectData?.data?.OfficeRegionId);
    const billingPath = isRegionLATAM ? "job/contractBillings.aspx" : "job/contractBilling/contractBillings.aspx";

    const startDate = moment(projectData?.data?.ProjStartDate).format(
        "MM-DD-YYYY"
    );
    const endDate = moment(projectData?.data?.CurrentEndDate).format(
        "MM-DD-YYYY"
    );

    const currentWeekStartDate = format(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        "M/d/yyyy"
    );

    return [
        {
            title: "Project",
            tabs: [
                {
                    id: 0,
                    label: "Dashboard",
                    icon: "caret-right",
                    code: "AC_DASHBOARD_VIEW",
                },
                {
                    id: 1,
                    label: "Health",
                    icon: "link",
                    to: getReporterUrl(
                        `job/tempeditProject.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_HEALTH_VIEW",
                },
                {
                    id: 2,
                    label: "Planner",
                    icon: "link",
                    to: getReporterUrl(
                        `Planner/ProjectPlanner.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_PLANNER_VIEW",
                },
                {
                    id: 3,
                    label: "Allocations",
                    icon: "caret-right",
                    code: "AC_ALLOCATIONS_VIEW",
                    isInternalUrlNewTab: true,
                    to: `${global.config.routes.allocation}`
                },
                {
                    id: 4,
                    label: "Edit",
                    icon: "link",
                    to: getReporterUrl(
                        `admin/editProject.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_EDIT",
                },
            ],
        },
        {
            title: "Timesheet",
            tabs: [
                {
                    id: 5,
                    label: "Completion",
                    icon: "link",
                    to: getReporterUrl(
                        `job/jobpoliceReport.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_TIMESHEETS_COMPLETION_VIEW",
                },
                {
                    id: 6,
                    label: "Approval",
                    icon: "link",
                    to: getReporterUrl(
                        `TimeTNu/summary.aspx?dt=${currentWeekStartDate}${ampersand}job=${projectId}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_TIMESHEETS_APPROVAL_VIEW",
                },
            ],
        },
        {
            title: "Finance",
            tabs: [
                {
                    id: 7,
                    label: isRegionLATAM ? "Project  Billing" : "Billing and OOP",                   
                    icon: "link",
                    to: getReporterUrl(
                        `${billingPath}?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_BILLING_VIEW",
                },
                {
                    id: 8,
                    label: "Cost Details ",
                    icon: "link",
                    to: getReporterUrl(
                        `job/JobUsed.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_COST_DETAILS_VIEW",
                },
                {
                    id: 9,
                    label: "Discounts / Internal Investments",
                    icon: "link",
                    to: getReporterUrl(
                        `job/requestContAdj.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_DISCOUNTS_VIEW",
                },
                {
                    id: 10,
                    label: "Close/Cancel",
                    icon: "link",
                    to: getReporterUrl(
                        `JobClosure/ProductionJobClosure.aspx?JobID=${projectId}${ampersand}FR=JDSH`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_CLOSE",
                },
            ],
        },
        {
            title: "Admin",
            tabs: [
                {
                    id: 11,
                    label: "Team",
                    icon: "link",
                    to: getReporterUrl(
                        `job/editTeam.aspx?JobID=${projectId}${ampersand}sort=0${ampersand}SD=${startDate}${ampersand}ED=${endDate}`
                    ),
                    isInternalUrlNewTab: true,
                    code: "AC_TEAM",
                },              
            ],
        },
    ];
};

const ProjectsDashboardLayout = () => {
    const { projectId } = useParams();
    const { useProjectById } = useProjectsApi();
    const projectData = useProjectById(projectId);
    const { useLeadAssignment } = useProjectLeadAssignment();
    const leadProducer = useLeadAssignment(projectId);
    const [handleRefreshDataClick, reloadData] = useReloadData();
    const [fixedHeaderHeight] = useScroll();
    const { offices } = useOffices();

    const { getNotesTabIndicator } = useContext(ProjectsDashboardLayoutContext);
    const showNotesTabIndicator = getNotesTabIndicator(projectId);

    useEffect(() => {
        localStorage.setItem("storedProjectId", projectId);
        return () => {
            localStorage.removeItem("storedProjectId");
        };
    }, []);

    return (
        <>
            <ProjectsDashboard
                handleRefreshDataClick={handleRefreshDataClick}
                fixedHeaderHeight={fixedHeaderHeight}
            />
            <PageWrapper
                paddingTop={"6px"}
                title={projectData?.data?.Name ?? "Error"}
                description={projectData?.data?.JobCode}
                tabs={[
                    { label: "Overview", to: "overview" },
                    leadProducer?.data?.length !== 0
                        ? {
                              label: "Details",
                              to: "details",
                          }
                        : {
                              label: "Details",
                              to: "details",
                              trailingIcon: "warning",
                              iconColor: COLORS.Yellow,
                          },
                    {
                        label: "Notes",
                        to: "notes",
                        trailingIcon: showNotesTabIndicator
                            ? "indicator"
                            : null,
                        iconColor: showNotesTabIndicator ? COLORS.Yellow : null,
                    },
                ]}
                tabsMarginBottom={"40px"}
                loading={projectData.isFetching || reloadData}
                customAuthPath={GlobalConfig.routes.projectsDashboardLanding}
                positionOfWrapper={`${fixedHeaderHeight}px`}
                dashboardSideNav={projectsTabs(projectId, projectData, offices)}
            >
                <Outlet />
            </PageWrapper>
        </>
    );
};

export default ProjectsDashboardLayout;
