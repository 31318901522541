import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import VStack from "components/design-system/layout/VStack";
import Text from "components/design-system/ui/Text";
import EmptyState from "components/design-system/tables/EmptyState";
import usePermissionsByEmployeeApi from "./usePermissionsByEmployeeApi";
import Loading from "components/UI/Loading";

const Container = styled(VStack)`
    text-align: left;
    padding: 0px;
    gap: 8px;
`;

const arrow = " > ";

const AccessGroupList = ({ userId, departmentId, onClose }) => {
    const [permissions, setPermissions] = useState(null); 
    const [loading, setLoading] = useState(false);
    const { getAccessesByEmployee } = usePermissionsByEmployeeApi();
    const location = useLocation();

    const CTAProps = {
        leadingIcon: "filter",
        label: "Manage Access Groups",
        onClick: onClose,
    };

    const pageItemOnClick = (pageId) => {
        let div = document.getElementById(`permissions_${pageId}`);
        if (div){
            div.style.display = div.style.display == "block" ? "none" : "block";
        }
    }

    useEffect(() => {
        setLoading(true);
        getAccessesByEmployee(userId, departmentId).then(
            (result) => {
                setPermissions(result);   
                setLoading(false);
            }
        );    
    }, []);

    if (location.pathname === "/admin/accessgroups") {
        return (
            <>
                <Container>
                    <Text variant="Descriptive_1_1" color="Gray_2">
                        Page Accesses
                    </Text>
                {
                    loading ? (<Loading />)
                    :
                    (
                        permissions && permissions.pages && permissions.pages.length === 0 ? (
                            <EmptyState
                                message="This Employee has not any page access"
                                actionPropsForTernaryButton={CTAProps}
                            />
                        ) : (
                            <>
                                {permissions && permissions.pages
                                    .map((page) => (
                                        <>
                                            <div style={{color: 'black', cursor: 'pointer'}} title={page.tooltip} onClick={()=> pageItemOnClick(page.id)}><b>{page.name}</b>{arrow}</div>
                                            <div style={{display: "none"}} id={"permissions_" + page.id}>
                                                {
                                                    page.permissions && page.permissions.length > 0 &&
                                                    <u>Permissions allowed in page</u>
                                                }
                                                {page.permissions.map((permpage)=> (
                                                    <div>
                                                        {permpage.name}
                                                    </div>
                                                ))}
                                                <div></div>
                                                <u>Granted by</u>
                                                {
                                                    page.grantedBy.map((g)=> (
                                                        <div>{g.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ))
                                }
                            </>
                        )
                    )
                }                                
                </Container>
            </>
        );
    } else {
        return <></>;
    }
};

export default AccessGroupList;
