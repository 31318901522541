import React, { useState } from "react";

import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Text from "components/design-system/ui/Text";

const NumberColumnAccessorName = "JobCode";
const columns = [
    {
        headCellProps: {
            text: "Number",
            width: "25%",
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows?.filter((row) => {
                return (
                    (filterValue === "active" && row.values.Active === true) ||
                    (filterValue === "inactive" && row.values.Active === false)
                );
            });
        },
        accessor: NumberColumnAccessorName,
    },
    {
        headCellProps: {
            text: "Name",
            width: "70%",
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "Name",
    },
    {
        accessor: "Active",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "delete",
            actionProps: {
                to: `?action=delete&linkId=${cell.row.original.relatedJobID}`,
                replace: true,
                "aria-label": "Delete",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: NumberColumnAccessorName,
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const ProjectsContentStudioTable = ({ contentStudioProjects }) => {
    const [filters, setFilters] = useState();

    return (
        <>
            {contentStudioProjects.length > 0 && (
                <VStack style={{ width: "100%" }}>
                    <VStack spacing="Zero_5">
                        <Text variant="Headline_2" color="Gray_1">
                            Content Studio
                        </Text>

                        <Text variant="Body_2_1" color="Gray_2">
                            Linked Projects from Content Studio
                        </Text>
                    </VStack>
                    <HStack align="end" justify="space-between">
                        <TableFilters
                            filtersConfig={FiltersConfig}
                            onChange={setFilters}
                        />
                    </HStack>
                    <Table
                        columns={columns}
                        data={contentStudioProjects}
                        filters={filters}
                        getRowProps={getRowProps}
                    />
                </VStack>
            )}
        </>
    );
};

export default React.memo(ProjectsContentStudioTable);
