export const vantageStatusOptions = [
    { value: "", label: "None" },
    { value: "CRTD", label: "Created" },
    { value: "REL ", label: "Released / All" },
    { value: "COST", label: "Released / HC and SC" },
    { value: "TIME", label: "Released / Time" },
    { value: "HOLD", label: "Released / On Hold" },
    { value: "KILL", label: "Killed" },
    { value: "STBI", label: "Released / Stop Billing" },
    { value: "STIN", label: "Released / Stop Incurring" },
    { value: "STRR", label: "Released / Stop Rev.Recognition" },
    { value: "HARD", label: "Released / HC" },
    { value: "SOFT", label: "Released / SC" },
    { value: "TISC", label: "Released / Time and SC" },
];

export const apiStatusOptions = [
    { value: "0", label: "None" },
    { value: "1", label: "To be extracted" },
    { value: "2", label: "Extracted" },
    { value: "3", label: "Success" },
    { value: "4", label: "Error" },
];

export const ptoUtilizationOption = [
    {
        label: "Do Not reduce employee's utilization",
        value: "UtilizationPTO",
    },
];
export const allotmentTypeOption = [
    { value: 1, label: "PTO", code: "PTO" },
    { value: 2, label: "Carryover PTO", code: "CARRCARR" },
    { value: 3, label: "Compensation time award", code: "CMPPTO" },
    { value: 4, label: "Sick days", code: "SICK" },
    { value: 5, label: "Summer days", code: "SMRNY" },
    { value: 6, label: "Carry over sick days", code: "PTOAUTO" },
    { value: 7, label: "Move", code: "MOVE" },
    { value: 8, label: "Study", code: "STUDY" },
    { value: 9999, label: "Sick, Rest, Family", code: "GENERALREST" },
    { value: 999, label: "Others", code: "OTHERS" },
];
export const attributesActiveCancelledOptions = [
    { label: "Active", value: "Active" },
    {
        label: "Canceled",
        value: "ProjectCancelled",
    },
];
export const classificationSegmentOptions = [
    {
        value: "segmentBillable",
        label: "Billable",
    },
    {
        value: "segmentNonBillable",
        label: "Non-Billable",
    },
];
export const rateCardSegmentOptions = [
    {
        value: "segmentClientDefault",
        label: "Client Default",
    },
    {
        value: "segmentAll",
        label: "All",
    },
];
export const attributesRequestOptions = [
    {
        label: "In Request",
        value: "IsRequest",
    },
];
export const attributesEACOptions = [
    {
        label: "Send EAC/PTD to Vantage",
        value: "SendToVantage",
    },
    {
        label: "Overwrite EAC/PTD to Vantage",
        value: "OverwriteEACPTD",
    },
];
export const attributesCostOptions = [
    {
        label: "Pass through",
        value: "ExpensesPassThru",
    },
    {
        label: "Approval request",
        value: "TimesheetAppReq",
    },
    {
        label: "SOW Signed",
        value: "SOWSigned",
    },
    {
        label: "SOW Received by Finance",
        value: "SOWReceivedFin",
    },
    {
        label: "PO Received by Finance",
        value: "POReceivedFin",
    },
];
