import React, { useState } from "react";
import styled from "styled-components";
import { useDateFormatter } from "@react-aria/i18n";
import Text from "components/design-system/ui/Text";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";

const HeaderRangeWrapper = styled(HStack)`
    padding: 15px;
    gap: 100px;
`;

const StyledTertiaryButton = styled(TertiaryButton)`
    box-shadow: 0 0 0px 1px ${COLOR_VALUES.Gray_6} inset;
    padding: 0;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50% !important;
    align-items: center;
    align-self: center;
    svg {
        width: 15px;
        height: 15px;
    }
`;

export function CalendarHeader({
    state,
    calendarProps,
    prevButtonProps,
    nextButtonProps,
    minDate,
}) {
    let monthDateFormatter = useDateFormatter({
        month: "long",
        year: "numeric",
        timeZone: state.timeZone,
    });

    const disablePrevButton =
        new Date(minDate.year, minDate.month, 1).getTime() >=
        new Date(
            state.visibleRange.start.year,
            state.visibleRange.start.month,
            1
        ).getTime();

    return (
        <HeaderRangeWrapper justify="space-between">
            <HStack>
                <StyledTertiaryButton
                    disabled={disablePrevButton}
                    trailingIcon="caret-left"
                    onClick={prevButtonProps.onPress}
                    {...Object.entries(prevButtonProps).splice(
                        Object.keys(prevButtonProps).indexOf("onPress"),
                        1
                    )}
                    {...{ "aria-label": "Prev" }}
                />
                <Text variant="Headline_5">
                    {monthDateFormatter.format(
                        state.visibleRange.start.toDate(state.timeZone)
                    )}
                </Text>
            </HStack>
            <HStack>
                <Text variant="Headline_5">
                    {monthDateFormatter.format(
                        state.visibleRange.start
                            .add({ months: 1 })
                            .toDate(state.timeZone)
                    )}
                </Text>
                <StyledTertiaryButton
                    trailingIcon="caret-right"
                    onClick={nextButtonProps.onPress}
                    {...Object.entries(nextButtonProps).splice(
                        Object.keys(nextButtonProps).indexOf("onPress"),
                        1
                    )}
                    {...{ "aria-label": "Next" }}
                />
            </HStack>
        </HeaderRangeWrapper>
    );
}
