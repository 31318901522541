import React, { useReducer } from "react";

export const EmployeeRowCellsContext = React.createContext();

const counterReducer = (state, action) => {
    switch (action.type) {
      case 'increment': {
        return {count: state.count + 1}
      }
      case 'decrement': {
        return {count: state.count - 1}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
};

export const EmployeeRowCellsProvider = (props) => {
    const [cellsInProgress, setCellsInProgress] = useReducer(counterReducer, { count: 0});

    const contextValue = {
        cellsInProgress,
        setCellsInProgress,
    };

    return (
        <EmployeeRowCellsContext.Provider value={contextValue}>
            {props.children}
        </EmployeeRowCellsContext.Provider>
    );
}

export default EmployeeRowCellsContext;
