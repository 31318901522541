import React, { useState } from "react";

import { COLOR_VALUES } from "components/design-system/config/colors";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import useAgencies from "./useAgencies";
import { getTypeOption } from "./utils";

const columns = [
    {
        headCellProps: {
            text: "ID",
            width: getCellWidthInPercent(100),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value,
            tooltip: `Legacy ID: ${cell.row.original.LegacyId}`,
        }),
        accessor: "Id",
    },
    {
        headCellProps: {
            text: "Name",
            width: "24%",
        },

        getBodyCellProps: (cell) => ({
            text: cell.value,
        }),
        accessor: "Name",
    },
    {
        headCellProps: {
            text: "Type",
            width: "24%",
        },
        getBodyCellProps: (cell) => {
            const typeValue = getTypeOption(cell.value);
            const cellText = typeValue ? typeValue.label : "-";

            return { text: cellText };
        },
        accessor: "TypeId",
    },
    {
        headCellProps: {
            text: "Parent",
            width: "24%",
        },
        getBodyCellProps: (cell) => {
            const parentAgencyId = cell.value;
            let cellValue = "-";

            if (parentAgencyId) {
                const parentAgencyRow = cell.column.preFilteredRows.find(
                    (row) => row.original.Id === parentAgencyId
                );

                if (parentAgencyRow) {
                    cellValue = parentAgencyRow.values.Name;
                }
            }

            return { text: cellValue };
        },
        accessor: "ParentId",
    },
    {
        headCellProps: {
            text: "Active",
            centered: true,
            width: "7%",
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "Active",
        disableSortBy: true,
        filter: (rows, _columnIds, filterValue) => {
            return rows.filter((row) => {
                return (
                    (filterValue === "active" && row.values.Active === true) ||
                    (filterValue === "inactive" && row.values.Active === false)
                );
            });
        },
    },
    {
        headCellProps: {
            text: "Billable",
            centered: true,
            width: "7%",
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "CanHoldClient",
        disableSortBy: true,
    },
    {
        headCellProps: {
            text: "Groups",
            centered: true,
            width: "7%",
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "HasGroup",
        disableSortBy: true,
    },
    {
        headCellProps: {
            text: "Hires",
            centered: true,
            width: "7%",
        },
        getBodyCellProps: (cell) => ({
            icon: cell.value ? "success" : "error",
        }),
        accessor: "CanHire",
        disableSortBy: true,
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(60),
        },
        accessor: "edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=edit&id=${cell.row.original.Id}`,
                replace: true,
                "aria-label": "Edit Agency",
            },
        }),
    },
];

const FiltersConfig = [
    {
        name: "active",
        columnAccessor: "Active",
        unfilteredValue: "all",
        isSegmentedControl: true,
        options: [
            {
                value: "all",
                label: "All",
            },
            {
                value: "active",
                label: "Active",
            },
            {
                value: "inactive",
                label: "Inactive",
            },
        ],
    },
];

const getRowProps = (row) => ({
    style: {
        background: row.values.Active === false ? COLOR_VALUES.Gray_9 : "",
    },
});

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add Agency",
    to: "?action=add",
    replace: true,
};

const AgencyTable = () => {
    const { agencies } = useAgencies();
    const [filters, setFilters] = useState();

    if (agencies.isLoading) {
        return <>Loading...</>;
    }

    if (agencies.isError) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="center" justify="space-between">
                    <TableFilters
                        filtersConfig={FiltersConfig}
                        onChange={setFilters}
                    />
                    <SecondaryButton {...tableCTAProps} />
                </HStack>
                <Table
                    columns={columns}
                    data={agencies?.data?.Items}
                    getRowProps={getRowProps}
                    filters={filters}
                    emptyActionProps={tableCTAProps}
                />
            </VStack>
        </>
    );
};

export default React.memo(AgencyTable);
