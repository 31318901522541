import React, { useReducer } from "react";

export const AllocationsPageContext = React.createContext();

const counterReducer = (state, action) => {
    switch (action.type) {
      case 'increment': {
        return {count: state.count + 1}
      }
      case 'decrement': {
        return {count: state.count - 1}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
};

export const AllocationsPageContextProvider = (props) => {
    const [rowsInProgress, setRowsInProgress] = useReducer(counterReducer, { count: 0});

    const contextValue = {
      rowsInProgress,
      setRowsInProgress,
    };

    return (
        <AllocationsPageContext.Provider value={contextValue}>
            {props.children}
        </AllocationsPageContext.Provider>
    );
}

export default AllocationsPageContext;
