import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";
import { useApi } from "utilities/API/Helpers/useApi";

const useAccrualRulesApi = () => {
    const { getDataAsync } = useApi();

    const getAccrualRulesByFilterApiCall = useCallback(
        async ({ employeeTypeId, officeId, year }) => {
            const response = await getDataAsync("getAccrualRulesByFilter", {
                urlParams: [
                    { name: "$employeeTypeId", value: employeeTypeId },
                    { name: "$officeId", value: officeId },
                    { name: "$year", value: year }
                ],
            });
            return response.status != 204 ? response.json() : null;
        },
        [getDataAsync]
    );

    return useMemo(() => {
        const useGetAccrualRulesByFilter = (dataObj) =>
            useQuery(["getAccrualRulesByFilter", dataObj], () =>
                getAccrualRulesByFilterApiCall(dataObj)
            );

        return {
            useGetAccrualRulesByFilter
        };
    }, []);
};

export default useAccrualRulesApi;
