import React, { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "components/design-system/ui/modal/MasterModal";
import VStack from "components/design-system/layout/VStack";
import ApproveTimeOffForm from "pages/timesheet/manage-time-off/ApproveTimeOffForm";
import RejectTimeOffForm from "pages/timesheet/manage-time-off/RejectTimeOffForm";
import ManageTimeOffTable from "pages/timesheet/manage-time-off/ManageTimeOffTable";
import ManageTimeOffContext from "context/ManageTimeOff/ManageTimeOffContext";
import { DrawerContextProvider } from "context/Drawer/DrawerContext";
import SessionContext from "context/app/SessionContext";
import { useInsigths } from "utilities/Insights/InsightsLogs";

const FormPresenter = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const action = searchParams.get("action");
    const id = searchParams.get("id");
    const {
        setRejectActionFromMenu,
        isManageViewRequest,
        setIsManageViewRequest,
    } = useContext(ManageTimeOffContext);

    const onClose = () => {
        setRejectActionFromMenu(false);
        setIsManageViewRequest(false);
        navigate(".", { replace: true });
    };

    const parsedId = parseInt(id);

    if (action === "viewRequest" && !isNaN(parsedId)) {
        return (
            <Modal
                title={
                    isManageViewRequest
                        ? "Time Off Request"
                        : "Review Time Off Request"
                }
                stickyAction={true}
                onRequestClose={onClose}
            >
                <ApproveTimeOffForm
                    timeOffRequestId={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    if (action === "rejectRequest" && !isNaN(parsedId)) {
        return (
            <Modal title="Review Time Off Request" onRequestClose={onClose}>
                <RejectTimeOffForm
                    timeOffRequestId={parsedId}
                    key={parsedId}
                    onClose={onClose}
                />
            </Modal>
        );
    }

    return <></>;
};

const ManageTimeOff = () => {
    const session = useContext(SessionContext);
    const { insightsTrace } = useInsigths();
    
    const now = new Date();
    insightsTrace("VIEW", "ManageTimeOffPage", { userId: session.id, email: session.email, user: session.fullName, localDate: now.toString(), UTCDate: now.toUTCString() });
    
    if (session.freelancer || session.contractor){
        window.location.replace("/timesheets/");
    }
    return (
        <>
            <VStack>
                <FormPresenter />
                <DrawerContextProvider>
                    <ManageTimeOffTable />
                </DrawerContextProvider>
            </VStack>
        </>
    );
};

export default ManageTimeOff;
