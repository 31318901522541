import PropTypes from "prop-types";

import { Tooltip as DefaultTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { COLOR_VALUES } from "components/design-system/config/colors";
import { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";

const StyledToolTip = withStyles({
    tooltip: {
        background: COLOR_VALUES.Gray_1,
        color: COLOR_VALUES.White,
        borderRadius: "2px",
        margin: "8px 0",
        maxWidth: "240px",

        // Small variant
        ...TEXT_VARIANT_STYLES.Descriptive_1_1,
        padding: "2px 8px 4px",
    },
    popper: {
        zIndex: 1051,
    },
})(DefaultTooltip);

const Tooltip = ({ text, enabled, children, ...rest }) =>
    enabled && text ? (
        <StyledToolTip title={<span style={{ whiteSpace: "pre-line" }}>{text}</span>}{...rest}>
            {children}
        </StyledToolTip>
    ) : (
        children
    );

Tooltip.defaultProps = {
    placement: "bottom",
    enterDelay: 2000,
    leaveDelay: 0,
    enabled: true,
};

Tooltip.propTypes = {
    placement: PropTypes.string,
    enterDelay: PropTypes.number,
    leaveDelay: PropTypes.number,
};

export default Tooltip;
