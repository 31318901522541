import { useRef } from "react";
import { useCalendarCell } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import {
    isSameDay,
    getDayOfWeek,
    isSameMonth,
    isWeekend,
} from "@internationalized/date";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { DateButton } from "components/design-system/controls/datepicker/StyledElements";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    selectionStartEnd: {
        color: "White",
        backgroundColor: "Black",
    },
    middleRange: {
        color: "Black",
        backgroundColor: "#EDEFF2",
    },
    firstSelectionBackGround: {
        backgroundColor: "#EDEFF2",
        borderRadius: "40px 0px 0px 40px",
    },
    lastSelectionBackGround: {
        backgroundColor: "#EDEFF2",
        borderRadius: "0px 40px 40px 0px",
    },
}));

export function CalendarCell({ state,
    date,
    currentMonth,
    disableDays = [],
    enableDaysFromDate,
    enableDaysUntilDate
}) {
    const classes = useStyles();
    let { locale } = useLocale();
    let ref = useRef();
    let { cellProps, buttonProps, isSelected, isDisabled, formattedDate } =
        useCalendarCell({ date }, state, ref);
    let { focusProps, isFocusVisible } = useFocusRing();

    let isOutsideMonth = !isSameMonth(currentMonth, date);

    // The start and end date of the selected range will have
    // an emphasized appearance.
    let isSelectionStart = state.highlightedRange
        ? isSameDay(date, state.highlightedRange.start)
        : isSelected;
    let isSelectionEnd = state.highlightedRange
        ? isSameDay(date, state.highlightedRange.end)
        : isSelected;
    // We add rounded corners on the left for the first day of the month,
    // the first day of each week, and the start date of the selection.
    // We add rounded corners on the right for the last day of the month,
    // the last day of each week, and the end date of the selection.
    let dayOfWeek = getDayOfWeek(date, locale);
    let isRoundedLeft =
        isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
    let isRoundedRight =
        isSelected &&
        (isSelectionEnd ||
            dayOfWeek === 6 ||
            date.day === date.calendar.getDaysInMonth(date));

    const parsedCurrentDate = new Date(date.year, date.month - 1, date.day)?.getTime();
    isDisabled = isWeekend(date, locale)
        || (!!enableDaysFromDate && parsedCurrentDate < enableDaysFromDate)
        || (!!enableDaysUntilDate && parsedCurrentDate >= enableDaysUntilDate)
        || (disableDays.length > 0
            && disableDays?.some((x) => x?.getTime() === parsedCurrentDate));

    const lastSelectionBackGround = isSelectionEnd && !isDisabled && isRoundedRight && !isSelectionStart;
    const firstSelectionBackGround = isSelected && isSelectionStart && !isDisabled && isRoundedLeft;
    const middleRange = isSelected && !(isSelectionStart || isSelectionEnd) && !isDisabled;

    return (
        <td
            {...cellProps}
            className={`
                    ${lastSelectionBackGround ? classes.lastSelectionBackGround : ""}
                    ${firstSelectionBackGround ? classes.firstSelectionBackGround : ""}
                    ${middleRange ? classes.middleRange : ""}
                    `}
        >
            {!isDisabled && (
                <DateButton
                    disabled={isDisabled}
                    notHover={isDisabled}
                    {...mergeProps(buttonProps, focusProps)}
                    ref={ref}
                    hidden={isOutsideMonth}
                    className={`
                        ${isSelectionStart || isSelectionEnd
                            ? classes.selectionStartEnd
                            : ""
                        }
                    `}
                >
                    {formattedDate}
                </DateButton>
            )}
            {isDisabled && (
                <DateButton
                    disabled={isDisabled}
                    notHover={isDisabled}
                    hidden={isOutsideMonth}
                >
                    {formattedDate}
                </DateButton>
            )}
        </td>
    );
}
