import { useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { useParams } from "react-router";

import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Dropdown from "components/design-system/forms/Dropdown";

import { sendNotification } from "utilities/Notification";

import { useProjectsApi } from "pages/admin/projects/useProjects";
import useProjectsLinked, {
    useClientsFilterApi,
    useProjectRelationTypes,
    useProjectsFilterApi,
} from "pages/admin/projects/single/useProjectsLinked";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";
import { getIdOfLinkedProjectRelationType } from "pages/admin/projects/single/ProjectsLinked";

const ModalCTA = ({ isEditing, isLoading, onClose }) => {
    const { isValid } = useFormState();

    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Link Project",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => void 0,
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformProjectLinkDataToFormData(
    linkToProjectDefaultVal,
    projectLinkData = {}
) {
    return {
        linkTo: linkToProjectDefaultVal.value,
        client: "",
        project: projectLinkData.projectRelatedProjectID ?? "",
    };
}

let projectName = null;

function transformFormDataToProjectLinkData(projectLinkData = {}) {
    const newProjectLinkData = { ...projectLinkData };

    return newProjectLinkData;
}

let projectssDropdownOptions = [];
const RenderProject = ({ disabledOptionsList }) => {
    const clientId = useWatch({ name: "client" });

    const { useProjectsFilter } = useProjectsFilterApi();
    const projectsFilter = useProjectsFilter(clientId);

    projectssDropdownOptions =
        !projectsFilter?.isLoading && projectsFilter?.data
            ? getDropdownOptions(projectsFilter?.data, "JobId", "JobName")
            : [];
    console.log(disabledOptionsList);
    return (
        <Dropdown
            name="project"
            validations={{
                required: true,
            }}
            label="Project"
            options={projectssDropdownOptions}
            disabledOptionsList={disabledOptionsList}
        />
    );
};

const ProjectLinkForm = ({ projectLinkIdToEdit, onClose }) => {
    const { projectId } = useParams();
    const { useProjectById } = useProjectsApi();
    const projectData = useProjectById(projectId);
    const { useProjectsRelatedList, createRelatedProject } =
        useProjectsLinked();
    const relatedProjectsList = useProjectsRelatedList({ jobID: projectId });
    const { clientsFilter } = useClientsFilterApi();
    const { projectRelationTypes } = useProjectRelationTypes();

    const [inProgress, setInProgress] = useState(false);

    if (relatedProjectsList?.isLoading || projectData.isLoading) {
        return <>Loading...</>;
    }

    if (relatedProjectsList?.isError || projectData.isError) {
        return <>Error</>;
    }

    const isEditing = typeof projectLinkIdToEdit !== "undefined";
    const modifier = createRelatedProject;

    const currentProjectLinkData = relatedProjectsList.data.find(
        (projectLink) => projectLink.relatedJobID === projectLinkIdToEdit
    );

    if (isEditing && !currentProjectLinkData) {
        return <div>Could not find project link</div>;
    }

    const clientsDropdownOptions =
        !clientsFilter?.isLoading && clientsFilter?.data
            ? getDropdownOptions(clientsFilter?.data, "ClientId")
            : [];

    const linkToOptions = [
        { value: projectData?.data?.JobId, label: projectData?.data?.Name },
    ];

    const onSubmit = (formData) => {
        const typeId = getIdOfLinkedProjectRelationType(
            projectRelationTypes?.data
        );
        const data = transformFormDataToProjectLinkData(currentProjectLinkData);

        const obj = {
            projectId: projectId,
            projectRelatedProjectID: formData.project,
            relationTypeId: typeId,
            body: data,
        };

        const linkId = isEditing
            ? currentProjectLinkData.projectRelatedProjectID
            : formData.project;

        const linkName =
            projectssDropdownOptions.length > 0
                ? projectssDropdownOptions.find((f) => f.value === linkId)
                      ?.label
                : "";

        modifier.mutateAsync(obj).then(() => {
            setInProgress(false);
            sendNotification(
                undefined,
                isEditing ? (
                    <>
                        <strong>{linkName}</strong> link has been updated
                    </>
                ) : (
                    <>
                        <strong>{linkName}</strong> has been linked to{" "}
                        <strong>{projectData?.data?.Name}</strong>
                    </>
                )
            );
            onClose();
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformProjectLinkDataToFormData(
                linkToOptions[0],
                currentProjectLinkData
            )}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <Dropdown
                        name="linkTo"
                        validations={{
                            required: true,
                        }}
                        label="Link To"
                        options={linkToOptions}
                        disabled={true}
                    />
                    <Dropdown
                        name="client"
                        validations={{
                            required: true,
                        }}
                        label="Client"
                        itemsAreLoading={clientsFilter?.isLoading}
                        options={clientsDropdownOptions}
                    />
                    <RenderProject
                        disabledOptionsList={
                            !relatedProjectsList.isLoading
                                ? relatedProjectsList.data.map((data) => ({
                                      value: data.relatedJobID,
                                  }))
                                : []
                        }
                    />
                </VStack>

                <ModalCTA
                    isEditing={isEditing}
                    onClose={onClose}
                    isLoading={modifier.isLoading || inProgress}
                />
            </VStack>
        </Form>
    );
};

export default ProjectLinkForm;
