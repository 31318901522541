import { format, isWeekend } from "date-fns";
import React, { useState, useContext } from "react";
import VStack from "components/design-system/layout/VStack";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import FormComponent from "components/design-system/forms/FormComponent";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import DateRangePicker from "components/design-system/forms/DateRangePicker";
import TextArea from "components/design-system/forms/TextArea";
import { sendNotification } from "utilities/Notification";
import ContactCard from "components/design-system/ui/drawer/ContactCard";
import env from "react-dotenv";
import styled from "styled-components";
import Text, { TEXT_VARIANT_STYLES } from "components/design-system/ui/Text";
import Icon from "components/design-system/ui/Icon";
import useTimeOffApi from "./useTimeOff";
import SessionContext from "context/app/SessionContext";
import HStack from "components/design-system/layout/HStack";
import { COLOR_VALUES } from "components/design-system/config/colors";
import moment from "moment";
import useOffices from "pages/admin/offices/useOffices";
import { AllotmentTypes } from "resources/Enums";
import Loading from "components/UI/Loading";
import useAccrualRulesApi from "./useAccrualRules";
import { useForm, trigger, clearErrors } from "react-hook-form";

export const LoadingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    background-color: white;
`;

const InputLabel = styled.label`
    word-break: break-all;
    color: ${COLOR_VALUES.Gray_1};
    ${TEXT_VARIANT_STYLES.Headline_5}
    margin: 0;
`;

const StyledTextArea = styled(TextArea)`
    textarea {
        min-height: 160px;
    }
`;

const TalentSupportMail = styled(HStack)`
    cursor: pointer;
    padding-right: 6px;
`;

const OptionsWrapper = styled.div`
    margin-bottom: 1rem;
`;

const StyledIcon = styled(Icon)`
    align-self: center;
`;

const SupportWrapper = styled(VStack)`
    border-top: 1px solid ${COLOR_VALUES.Gray_5};
    margin-top: 1rem;
    padding-top: 1.5rem;
`;

const StyledFeedbackIcon = styled(Icon)`
    flex-shrink: 0;
`;

const daysOptions = [
    { label: "Full Day", value: "FullDay" },
    { label: "Morning Out", value: "MorningOut" },
    { label: "Afternoon Out", value: "AfternoonOut" },
];

function setDayOption(morning, afternoon) {
    if (morning && afternoon) {
        return "FullDay";
    }
    if (morning) {
        return "MorningOut";
    } else {
        return "AfternoonOut";
    }
}

function transformTimeOffDataToFormData(timeOffData) {
    if (!timeOffData) {
        return {
            OOOType: undefined,
            date: {
                start: null,
                end: null,
            },
            firstDayOptions: "FullDay",
            lastDayOptions: "FullDay",
            note: "",
        };
    }
    return {
        OOOType: timeOffData.reasonId,
        date: {
            start: moment(timeOffData.firstDayOut).format("YYYY-MM-DD"),
            end: moment(timeOffData.lastDayOut).format("YYYY-MM-DD"),
        },
        firstDayOptions: setDayOption(
            timeOffData.firstDayOutMorning,
            timeOffData.firstDayOutAfternoon
        ),
        lastDayOptions: setDayOption(
            timeOffData.lastDayOutMorning,
            timeOffData.lastDayOutAfternoon
        ),
        note: timeOffData?.note,
    };
}

function parseDate(date) {
    if (typeof date === "string") {
        var changeHyper = date.replace(/-/g, '\/').replace(/T.+/, '');
        return new Date(Date.parse(changeHyper));
    }

    return new Date(date?.year, date?.month - 1, date?.day);
}

function formDataToOOOData(
    formData = {},
    userId,
    approverId,
    vacationRequestId,
    oooOptions,
    agencyId
) {
    const newOutOfOfficeData = {};
    newOutOfOfficeData.reasonId = formData.OOOType;
    newOutOfOfficeData.firstDayOptions = formData.firstDayOptions;
    newOutOfOfficeData.lastDayOptions = formData.lastDayOptions;
    newOutOfOfficeData.note = formData.note;

    const start = formData?.date?.start;
    const end = formData?.date?.end;
    newOutOfOfficeData.firstDayOut = parseDate(start);
    newOutOfOfficeData.lastDayOut = parseDate(end);
    newOutOfOfficeData.userId = userId;
    newOutOfOfficeData.agencyId = agencyId;
    newOutOfOfficeData.approver = approverId;
    newOutOfOfficeData.id = vacationRequestId;
    newOutOfOfficeData.reasonName = oooOptions?.find(
        (x) => x?.value === formData.OOOType
    )?.label;
    return newOutOfOfficeData;
}

const getDropdownOptions = (optionDetails = [], Id = "Id", Name = "Name") => {
    return optionDetails.reduce((acc, val) => {
        return [
            ...acc,
            {
                value: val[Id],
                label: val[Name],
            },
        ];
    }, []);
};

const DateRangevalidationForAllotment = ({
    isPTORequest,
    exceededDaysErrorMessage,
}) => {
    return (
        <>
            <HStack spacing="Zero_25" align="flex-start">
                <StyledFeedbackIcon
                    size={16}
                    name={isPTORequest ? "warning" : "error"}
                    color={isPTORequest ? "Yellow" : "Orange_1"}
                />
                <Text color="Black" variant="Body_2_2">
                    {exceededDaysErrorMessage}
                </Text>
            </HStack>
        </>
    );
};

const NoApproverAssinged = () => {
    return (
        <>
            <HStack spacing="Zero_25" align="flex-start">
                <StyledFeedbackIcon
                    size={16}
                    name={"warning"}
                    color={"Yellow"}
                />
                <Text color="Black" variant="Body_2_2">
                    You will not be able to submit requests for time off because you do not have a Reviewer assigned to you.
                </Text>
            </HStack>
        </>
    );
};

const ContactSupport = ({
    userOffice
}) => {

    const [supportMailTooltipText, setSupportMailTooltipText] = useState("");

    const setTalentSupportEmail = () => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(
                userOffice.data?.TalentSupportEmail ?? ""
            );
            setSupportMailTooltipText("Copied");
        }
    };

    return (
        <HStack spacing="Zero_5">
            <Text variant="Body_2_1" color="Gray_2">
                If there are any issues, please contact
            </Text>
            <>
                <TalentSupportMail
                    spacing="Zero_25"
                    onMouseEnter={() => setSupportMailTooltipText("Copy")}
                    onMouseLeave={() => setSupportMailTooltipText("")}
                    onClick={() => setTalentSupportEmail()}
                >
                    <Text
                        variant="Body_2_2"
                        color="Black"
                    >
                        Talent Support
                    </Text>
                    <StyledIcon name="mail" />
                </TalentSupportMail>
                <Text
                    variant="Body_2_1"
                    color="Gray_2"
                >
                    {supportMailTooltipText}
                </Text>
            </>
        </HStack>
    );
};

const TimeOffRequestForm = ({ timeOffIdToEdit, onClose }) => {
    const SUBMIT_HR_APPROVAL = "You are able to submit more days than you have remaining, but will require HR approval.";
    const UNABLE_SUBMIT_MORE_THAN_ALLOTMENT = "You are unable to submit more days than you have remaining";
    const NOT_ALLOTMENT_FOR_NEXT_YEAR = "Allotment for next year has not been set yet";

    const session = useContext(SessionContext);
    const isEditing = typeof timeOffIdToEdit !== "undefined";

    const noApproverAssigned = !session.approverId || session.approverId <= 0;

    const accrualRulesData = {
        officeId: session.currentOfficeId,
        employeeTypeId: session.type,
        year: new Date().getFullYear()
    };

    const { useTimeOff } = useTimeOffApi();

    const userTimeOffReasons = useTimeOff({
        employeeId: session.legacyId,
        year: new Date().getFullYear()
    });

    const userTimeOffReasonsNextYear = useTimeOff({
        employeeId: session.legacyId,
        year: new Date().getFullYear() + 1
    });

    const { useGetAccrualRulesByFilter } = useAccrualRulesApi();
    const accrualRules = useGetAccrualRulesByFilter(accrualRulesData);

    const { useOfficeHolidays } = useTimeOffApi();
    const holidays = useOfficeHolidays({ officeId: session.currentOfficeId });

    const { useGetTimeOffRequests } = useTimeOffApi();
    const timeOffRequests = useGetTimeOffRequests({ employeeId: session.legacyId });

    const { useGetTimeOffRequest } = useTimeOffApi();
    const timeOffRequest = useGetTimeOffRequest({
        id: isEditing ? timeOffIdToEdit : 0,
    });

    const { useGetOfficeById } = useOffices();
    const userOffice = useGetOfficeById({ officeId: session.currentOfficeId });

    const {
        useGetTimeOffReasonsForNewRequest,
        useGetTimeOffApprover,
        createTimeOffRequest,
        editTimeOffRequest,
    } = useTimeOffApi();

    const approver = useGetTimeOffApprover({ employeeId: session.approverId });
    const reasons = useGetTimeOffReasonsForNewRequest({ employeeId: session.legacyId });
    const [hideSecondDayOptions, setHideSecondDayOptions] = useState(true);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [startOOODate, setStartOOODate] = useState();
    const [endOOODate, setEndOOODate] = useState();

    const today = moment();

    const [enableDaysFromDate, setEnableDaysFromDate] = useState(today);
    const [enableDaysUntilDate, setEnableDaysUntilDate] = useState(null);

    const [exceededDaysErrorMessage, setExceededDaysErrorMessage] =
        useState("");

    const [isPTORequest, setIsPTORequest] = useState(false);
    const [showAllowed, setShowAllowed] = useState(true);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const modifier = isEditing ? editTimeOffRequest : createTimeOffRequest;
    let isPendingRequest = true;
    let isApprovedRequest = false;
    let isRejectedRequest = false;
    let currentTimeOfRequest = null;
    let canUserEditDelete = true && !noApproverAssigned;
    let firstDay = "";
    let secondDay = "";
    let initialReasonType = null;
    let isMultiYearEdition = false;
    const minDate = new Date(new Date().setMonth(new Date().getMonth() - 2));
    const minPreviousDate = {
        year: minDate.getFullYear(),
        month: minDate.getMonth() + 1,
    };

    if (isEditing && timeOffRequest?.isFetched) {
        currentTimeOfRequest = timeOffRequest.data;
        currentTimeOfRequest.note = currentTimeOfRequest?.note ?? "";
        initialReasonType = userTimeOffReasons?.data?.find(
            x => x.reasonId === timeOffRequest?.data?.reasonId)
            ?? null;
        firstDay = setDayOption(
            currentTimeOfRequest?.firstDayOutMorning,
            currentTimeOfRequest?.firstDayOutAfternoon
        );
        secondDay = setDayOption(
            currentTimeOfRequest?.lastDayOutMorning,
            currentTimeOfRequest?.lastDayOutAfternoon
        );
        const FDYear = parseDate(currentTimeOfRequest?.firstDayOut).getFullYear();
        const LDYear = parseDate(currentTimeOfRequest?.lastDayOut).getFullYear();
        isMultiYearEdition = !!FDYear !== LDYear;
        isPendingRequest = !!currentTimeOfRequest?.pending;
        isApprovedRequest = !!currentTimeOfRequest?.approved;
        isRejectedRequest = !!currentTimeOfRequest?.rejected;
        const isReasonOnlyHR = isApprovedRequest && (!!currentTimeOfRequest?.reason?.isUnPaidLeave ||!!currentTimeOfRequest?.reason?.onlyShowHR);
        canUserEditDelete = currentTimeOfRequest?.canUserEditDelete && !noApproverAssigned && !isReasonOnlyHR;
    } else {
        firstDay = setDayOption(true, true);
        secondDay = setDayOption(true, true);
    }

    let defaultValues = transformTimeOffDataToFormData(currentTimeOfRequest);

    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const [reasonType, setReasonType] = useState(initialReasonType);
    const [firstDaySelection, setFirstDaySelection] = useState(firstDay);
    const [secondDaySelection, setSecondDaySelection] = useState(secondDay);

    const [showAllotmentNextYear, setShowAllotmentNextYear] = useState(isMultiYearEdition);
    const [nextYearAllotment, setNextYearAllotment] = useState(null);
    const [yearOfRequest, setYearOfRequest] = useState(new Date().getFullYear());

    let datePickerError = "";

    let reasonsDropdownOptions = [];
    let disabledDropdownOptions = [];

    if (isEditing && !reasons.isLoading && !timeOffRequest.isLoading) {
        let reasonsToShow = [];
        if (reasons.data) {
            reasonsToShow = [...reasons.data];
        }

        // We need to check if the reason previously selected is stil a valid reason for the user
        if (timeOffRequest.data
            && !reasonsToShow.some(x => x.reasonId === timeOffRequest.data.reasonId)) {

            const disabledReason = {
                reasonId: timeOffRequest.data.reasonId,
                reasonName: timeOffRequest.data.reasonName
            };
            reasonsToShow.push(disabledReason);
            disabledDropdownOptions = getDropdownOptions([disabledReason], "reasonId", "reasonName");
        }

        reasonsDropdownOptions = getDropdownOptions(reasonsToShow, "reasonId", "reasonName");
    }
    else if (!isEditing && !reasons.isLoading) {
        reasonsDropdownOptions = getDropdownOptions(reasons.data, "reasonId", "reasonName");
    }

    const dateRangeChange = (dates) => {
        const start = dates.start;
        const end = dates.end;
        setStartOOODate(start);
        setEndOOODate(end);

        const show =
            start &&
            end &&
            (start.day !== end.day ||
                start.month !== end.month ||
                start.year !== end.year);

        setHideSecondDayOptions(!show);
        if (showAllowed) {
            const reason = reasonType ? getReason(reasonType.reasonId) : null;
            checkExceededDays(reason, start, end, firstDaySelection, secondDaySelection);
        }
    };

    const reasonDropDownChange = (val) => {
        if ((reasonType?.reasonId ?? "") === val) {
            return;
        }

        const reason = getReason(val);
        methods.clearErrors("date");
        setCalendarAccrualRules(reason);

        var reasonWithNotAllotment = !reason.hasOwnProperty("allotmentTypeId") ||
            (reason.hasOwnProperty("allotmentTypeId") && (reason?.allotmentTypeId === AllotmentTypes.OTHERS || reason?.allotmentTypeId === AllotmentTypes.GENERALREST));

        if (reasonWithNotAllotment) {
            noLimitationDays();
        }
        else {
            setShowAllowed(true);
            checkExceededDays(reason, startOOODate, endOOODate, firstDaySelection, secondDaySelection);
        }
    };

    const getReason = (val) => {
        let isNextYear = false;
        let isMultiYear = false;
        let reasonsByYear = userTimeOffReasons?.data;
        const startDate = methods.getValues("date").start;
        const endDate = methods.getValues("date").end;
        // check if user request a "next year request" or a "multiyear request"
        if (startDate) {
            const FDYear = parseDate(startDate).getFullYear();
            isNextYear = FDYear !== new Date().getFullYear();
            setYearOfRequest(FDYear);
            // try to find reason and allotment for next year
            if (isNextYear && userTimeOffReasonsNextYear?.data?.length > 0) {
                reasonsByYear = userTimeOffReasonsNextYear?.data;
            }
            // is multi year?
            else if (endDate) {
                const LDYear = parseDate(endDate).getFullYear();
                isMultiYear = FDYear !== LDYear;
                if (isMultiYear) {
                    let reasonMultiyear = null;
                    // try to find reason and allotment for next year
                    if (userTimeOffReasonsNextYear?.data?.length > 0) {
                        reasonMultiyear = userTimeOffReasonsNextYear.data.find((x) => x.reasonId === val);
                    }
                    reasonMultiyear = reasonMultiyear ?? { allotment: 0, daysRemaining: 0 };
                    setNextYearAllotment(reasonMultiyear);
                }
            }
        }

        setShowAllotmentNextYear(isMultiYear);

        let reason = reasonsByYear?.find((x) => x.reasonId === val)
            ?? reasons?.data?.find((x) => x.reasonId === val)
            ?? { reasonId: val };

        setReasonType(reason);
        return reason;
    }

    const checkExceededDays = (reason, startDate, endDate, firstDay, lastDay) => {
        setExceededDaysErrorMessage("");

        if (!startDate || !endDate || !reason || (isEditing && !editingEnabled)) {
            return;
        }

        const amountOfDaysOut = calculateAmountOfDaysOut(startDate, endDate, reason.reasonId, firstDay, lastDay);
        const FDYear = parseDate(startDate).getFullYear();
        const LDYear = parseDate(endDate).getFullYear();
        const reasonNextYear = userTimeOffReasonsNextYear.data?.find(x =>
            x.reasonId === reason.reasonId && x.allotment != null);
        const isPTO = reason.allotmentTypeId === AllotmentTypes.PTO;
        const calculateInEachYear = isPTO || (!isPTO && !!reasonNextYear);

        // handle multiyear 
        if (FDYear !== LDYear && calculateInEachYear) {

            // check for exceeded for current year request
            const lastDayOfYear = `${FDYear}-12-31`;
            const amountOfDaysOutCurrentYear = calculateAmountOfDaysOut(startDate, lastDayOfYear, reason.reasonId, firstDay, "FullDay");

            if (amountOfDaysOutCurrentYear > reason.daysRemaining) {
                handleExceededDays(reason);
                return;
            }

            // check for exceeded for next year request
            const firstDayOfYear = `${LDYear}-01-01`;
            const amountOfDaysOutNextYear = calculateAmountOfDaysOut(firstDayOfYear, endDate, reason.reasonId, "FullDay", lastDay);

            if (!reasonNextYear || amountOfDaysOutNextYear > reasonNextYear.daysRemaining) {
                handleExceededDays(reason);
                return;
            }
            setDisabledSubmit(false);
        }
        else if (amountOfDaysOut > reason.daysRemaining) {
            handleExceededDays(reason);
        } else {
            setDisabledSubmit(false);
        }
    };

    const handleExceededDays = (reason) => {
        switch (reason.allotmentTypeId) {
            case AllotmentTypes.PTO:
                setExceededDaysErrorMessage(SUBMIT_HR_APPROVAL);
                setDisabledSubmit(false);
                setIsPTORequest(true);
                break;
            default:
                const message = reason.allotment != null
                    ? UNABLE_SUBMIT_MORE_THAN_ALLOTMENT
                    : NOT_ALLOTMENT_FOR_NEXT_YEAR
                setExceededDaysErrorMessage(message);
                setDisabledSubmit(true);
                setIsPTORequest(false);
                break;
        }
    }

    const calculateAmountOfDaysOut = (startDate, endDate, selectedReasonId, firstDay, secondDay) => {
        const amountOfdays = getAmountOfDaysOut(
            parseDate(startDate),
            parseDate(endDate)
        );

        const halfDaySelection = calculateHalfDaySelection(
            parseDate(startDate),
            parseDate(endDate),
            firstDay,
            secondDay
        );

        if (isEditing && selectedReasonId === currentTimeOfRequest?.reasonId) {
            return (amountOfdays - getAmountOfDaysOutOfYear(currentTimeOfRequest) - halfDaySelection);
        }

        return (amountOfdays - halfDaySelection);
    }

    const getAmountOfDaysOutOfYear = (request) => {
        const FDYear = parseDate(request?.firstDayOut).getFullYear();
        const LDYear = parseDate(request?.lastDayOut).getFullYear();

        if (FDYear !== LDYear) {
            const amountOfdaysInYear = getAmountOfDaysOut(
                parseDate(request?.firstDayOut),
                parseDate(`${FDYear}-12-31`)
            );

            return amountOfdaysInYear;
        }
        return request?.amountOfDaysOut;
    }

    const noLimitationDays = () => {
        setShowAllowed(false);
        clearExceededDaysErrorMessage();
    }

    const clearExceededDaysErrorMessage = () => {
        setDisabledSubmit(false);
        setExceededDaysErrorMessage("");
    }

    const setCalendarAccrualRules = (reason) => {
        if (!accrualRules?.data) {
            return;
        };

        let dateFrom = null;
        let dateUntil = null;

        switch (reason?.allotmentTypeId) {
            case AllotmentTypes.CARR: // carry over days
                if (accrualRules?.data?.lastDayForCarryOver) {
                    dateUntil = new Date(accrualRules?.data?.lastDayForCarryOver);
                }
                break;
            case AllotmentTypes.SMRNY: // summer days
                if (accrualRules?.data?.summerDaysStart) {
                    dateFrom = moment(accrualRules?.data?.summerDaysFirst);
                }
                if (accrualRules?.data?.summerDaysEnd) {
                    dateUntil = moment(accrualRules?.data?.summerDaysEnd);
                }
                break;
            case AllotmentTypes.PTOAUTO: // carry over sick days
                if (accrualRules?.data?.lastDayForCarryOverSick) {
                    dateUntil = moment(accrualRules?.data?.lastDayForCarryOverSick);
                }
                break;
            default:
                dateFrom = today;
                break;
        }

        if (!dateFrom || dateFrom < today)
            dateFrom = today;

        setEnableDaysFromDate(dateFrom);
        setEnableDaysUntilDate(dateUntil);
    };

    const onSubmit = (formData) => {

        setIsSubmiting(true);

        const outOfOfficeData = formDataToOOOData(
            formData,
            session.legacyId,
            session.approverId,
            currentTimeOfRequest ? currentTimeOfRequest.id : 0,
            reasonsDropdownOptions,
            session.agencyId
        );

        const amountOfdays = getAmountOfDaysOut(
            new Date(outOfOfficeData.firstDayOut),
            new Date(outOfOfficeData.lastDayOut)
        );

        const halfDaySelection = calculateHalfDaySelection(
            outOfOfficeData.firstDayOut,
            outOfOfficeData.lastDayOut,
            outOfOfficeData.firstDayOptions,
            outOfOfficeData.lastDayOptions
        );

        outOfOfficeData.AmountOfDaysOut = amountOfdays - halfDaySelection;

        if (outOfOfficeData.AmountOfDaysOut <= 0) {
            sendNotification(undefined, `No valid dates - check holidays`);
            setIsSubmiting(false);
            return;
        }

        sendRequest(outOfOfficeData);
    };

    const dateRangeValidations = (value) => {
        const start = value?.start;
        const end = value?.end;
        const firstDayOut = parseDate(start);
        const lastDayOut = parseDate(end);

        if (!start || !end) {
            datePickerError = "Dates are not set";
            return false;
        }

        if (!chekMinAndMaxDate(firstDayOut, lastDayOut)) {
            return false;
        }

        const overlapsError = datesOverlapsWithExisting(firstDayOut.getTime(), lastDayOut.getTime());
        if (overlapsError) {
            datePickerError = overlapsError;
            return false;
        }

        const notAllowedDates = getDisabledDays();
        if (
            notAllowedDates.some(
                (x) => x.getTime() === firstDayOut.getTime()
            ) ||
            notAllowedDates.some((x) => x.getTime() === lastDayOut.getTime())
        ) {
            datePickerError = "Dates are not allowed";
            return false;
        }

        if ((firstDayOut && enableDaysFromDate
            && firstDayOut.getTime() < enableDaysFromDate)
            ||
            (lastDayOut && enableDaysUntilDate
                && lastDayOut.getTime() >= enableDaysUntilDate)) {
            datePickerError = "Dates are not allowed";
            return false;
        }

        datePickerError = "";
        return true;
    };

    const chekMinAndMaxDate = (startDate, endDate) => {
        let currentDate = new Date();
        let firstDayOfCurrentMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );
        const minDate = new Date(
            firstDayOfCurrentMonth.setMonth(
                firstDayOfCurrentMonth.getMonth() - 2
            )
        );

        if (startDate.getTime() < minDate.getTime()) {
            datePickerError = "Min date exceeded";
            return false;
        }

        const maxDate = new Date(
            currentDate.setFullYear(currentDate.getFullYear() + 1)
        );
        if (endDate.getTime() > maxDate.getTime()) {
            datePickerError = "Max date exceeded";
            return false;
        }
        return true;
    };

    const datesOverlapsWithExisting = (startDate, endDate) => {
        const fdOption = methods.getValues("firstDayOptions");
        const ldOption = startDate === endDate ? undefined
            : (methods.getValues("lastDayOptions")
                ? methods.getValues("lastDayOptions")
                : "FullDay");

        var overlaps = timeOffRequests?.data.filter(
            (x) => !x.rejected
                && x.id != currentTimeOfRequest?.id
                && ((Date.parse(x.firstDayOut) <= startDate && Date.parse(x.lastDayOut) >= startDate)
                    || (Date.parse(x.firstDayOut) <= endDate && Date.parse(x.lastDayOut) >= endDate)
                    || (Date.parse(x.firstDayOut) <= startDate && Date.parse(x.lastDayOut) >= endDate)
                    || (Date.parse(x.firstDayOut) >= startDate && Date.parse(x.lastDayOut) <= endDate))
        );

        var halfDaysOverlapsOk = checkIfHalfDaysOverlapsOK(overlaps, startDate, endDate, fdOption, ldOption);
        if (halfDaysOverlapsOk.length >= overlaps.length) {
            return "";
        }

        var halfDaysOverlapsWrong = checkIfHalfDaysOverlapsWrong(overlaps, startDate, endDate, fdOption, ldOption);
        if (halfDaysOverlapsWrong) {
            return "Half day overlaps with existing time off";
        }

        return (overlaps?.length > 0) ? "Dates overlaps with existing time off" : "";
    };

    const checkIfHalfDaysOverlapsWrong = (overlaps, startDate, endDate, fdOption, ldOption) => {
        if (overlaps?.length > 0) {

            const overlapsOneDay = overlaps.filter(x =>
                (Date.parse(x.firstDayOut) === Date.parse(x.lastDayOut)
                    || startDate === endDate)
                && startDate === Date.parse(x.firstDayOut)
                && ((fdOption === "FullDay")
                    || (x.firstDayOutMorning && x.firstDayOutAfternoon)
                    || (x.firstDayOutMorning && fdOption === "MorningOut")
                    || (x.firstDayOutAfternoon && fdOption === "AfternoonOut"))
            );

            if (overlapsOneDay.length > 0)
                return true;

            const overlapsWithoutHalfDaysFirstDate = overlaps.filter(x =>
            (startDate === Date.parse(x.lastDayOut)
                && (fdOption === "FullDay"
                    || (x.lastDayOutMorning && x.lastDayOutAfternoon)
                    || (x.lastDayOutMorning && fdOption === "MorningOut")
                    || (x.lastDayOutAfternoon && fdOption === "AfternoonOut"))
            ));

            if (overlapsWithoutHalfDaysFirstDate.length > 0)
                return true;

            const overlapsWithoutHalfDaysEndDate = overlaps.filter(x =>
            (endDate === Date.parse(x.firstDayOut)
                && (ldOption === "FullDay"
                    || (x.firstDayOutMorning && x.firstDayOutAfternoon)
                    || (x.firstDayOutMorning && ldOption === "MorningOut")
                    || (x.firstDayOutAfternoon && ldOption === "AfternoonOut"))
            ));

            if (overlapsWithoutHalfDaysEndDate.length > 0)
                return true;
        }
        return false;
    }

    const checkIfHalfDaysOverlapsOK = (overlaps, startDate, endDate, fdOption, ldOption) => {
        let overlapsOneDay = [];
        let overlapsWithoutHalfDaysFirstDate = [];
        let overlapsWithoutHalfDaysEndDate = [];
        if (overlaps?.length > 0) {

            overlapsOneDay = overlaps.filter(x =>
                (Date.parse(x.firstDayOut) === Date.parse(x.lastDayOut)
                    || startDate === endDate)
                && startDate === Date.parse(x.firstDayOut)
                && (fdOption !== "FullDay")
                && (!x.firstDayOutMorning || !x.firstDayOutAfternoon)
                && ((x.firstDayOutMorning && fdOption === "AfternoonOut")
                    || (x.firstDayOutAfternoon && fdOption === "MorningOut"))
            );

            overlapsWithoutHalfDaysFirstDate = overlaps.filter(x =>
            (startDate === Date.parse(x.lastDayOut)
                && (fdOption !== "FullDay")
                && (!x.lastDayOutMorning || !x.lastDayOutAfternoon)
                && ((x.lastDayOutMorning && fdOption === "AfternoonOut")
                    || (x.lastDayOutAfternoon && fdOption === "MorningOut"))
            ));

            overlapsWithoutHalfDaysEndDate = overlaps.filter(x =>
            (endDate === Date.parse(x.firstDayOut)
                && (ldOption !== "FullDay")
                && (!x.firstDayOutMorning || !x.firstDayOutAfternoon)
                && ((x.firstDayOutMorning && ldOption === "AfternoonOut")
                    || (x.firstDayOutAfternoon && ldOption === "MorningOut"))
            ));
        }
        var overlapsOk = overlapsOneDay.concat(overlapsWithoutHalfDaysFirstDate).concat(overlapsWithoutHalfDaysEndDate);

        return overlapsOk.map(x => x.id);
    }

    // do not count weekends and holidays
    const getAmountOfDaysOut = (startDate, endDate) => {
        let amountOfDays = 0;
        const holidaysDates = holidays?.data?.map((x) =>
            format(new Date(x.Date), "MMM dd, yyyy")
        );

        do {
            let dateFormatted = format(new Date(startDate), "MMM dd, yyyy");
            let isDayHoliday = holidaysDates?.some(
                (x) => x === dateFormatted
            );
            let isDayWeekend = isWeekend(new Date(startDate));

            if (!isDayWeekend && !isDayHoliday) {
                amountOfDays++;
            }

            startDate.setDate(startDate.getDate() + 1);
        } while (startDate <= endDate);
        return amountOfDays;
    };

    const calculateHalfDaySelection = (startDate, endDate, firstDay, secondDay) => {
        let total = 0;
        if (startDate.getTime() === endDate.getTime()) {
            return firstDay === "FullDay" ? 0 : 0.5;
        }
        total = (firstDay === "FullDay") ? 0 : 0.5;
        total += (secondDay === "FullDay") ? 0 : 0.5;
        return total;
    };

    const changeFirstDay = async (selection) => {
        setFirstDaySelection(selection);
        if (showAllowed) {
            checkExceededDays(reasonType, startOOODate, endOOODate, selection, secondDaySelection);
        }
        // this will trigger the validation of the control named "date"
        await methods.trigger("date");
    };

    const changeLastDay = async (selection) => {
        setSecondDaySelection(selection);
        if (showAllowed) {
            checkExceededDays(reasonType, startOOODate, endOOODate, firstDaySelection, selection);
        }
        // this will trigger the validation of the control named "date"
        await methods.trigger("date");
    };

    const closeForm = () => {
        onClose();
        setIsSubmiting(false);
    };

    const sendRequest = (outOfOfficeData) => {
        modifier.mutateAsync(outOfOfficeData).then(() => {
            sendNotification(
                undefined,
                !isEditing
                    ? `Time off request has been submitted`
                    : `Time off request has been updated`
            );
        }).then(() => {
            setTimeout(closeForm, 1000);
        });
    };

    const getDisabledDays = () => {
        const timeOffDays = geTimeOffDays();
        const holidays = getHolidays();
        return [].concat(timeOffDays, holidays);
    };

    const getHolidays = () => {
        return holidays?.data?.map((x) => new Date(x.Date));
    };

    const geTimeOffDays = () => {
        let timeOffToDisable = [];
        const daysOut = timeOffRequests?.data.filter(
            (x) => x.id != currentTimeOfRequest?.id
                && !x.rejected
        );
        const today = new Date();
        let firstDayOfCurrentMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );
        const minDate = new Date(
            firstDayOfCurrentMonth.setMonth(
                firstDayOfCurrentMonth.getMonth() - 2
            )
        );
        daysOut.forEach((timeOff) => {
            let lastDay = parseDate(timeOff.lastDayOut);
            let firstDay = parseDate(timeOff.firstDayOut);

            //if last day it's half day, do not add. 
            if (firstDay.getTime() != lastDay.getTime()
                && (!timeOff.lastDayOutMorning || !timeOff.lastDayOutAfternoon)) {
                lastDay.setDate(lastDay.getDate() - 1);
            }

            if (lastDay > minDate) {

                //if first day it's half day, do not add. 
                if (!timeOff.firstDayOutMorning || !timeOff.firstDayOutAfternoon) {
                    firstDay.setDate(firstDay.getDate() + 1);
                }

                while (firstDay.getTime() <= lastDay.getTime()) {
                    timeOffToDisable.push(new Date(firstDay));
                    firstDay.setDate(firstDay.getDate() + 1);
                }
            }
        });

        return timeOffToDisable;
    };

    const [editingEnabled, setEditingEnabled] = useState(false);

    const changePrimaryButton = (e) => {
        e.preventDefault();
        setEditingEnabled(true);
        if (disabledDropdownOptions.length > 0) {
            methods.setValue('OOOType', '');
        }
    };

    const newRequestOrEnableEditAction =
        isSubmiting ?
            (
                {
                    isSubmiting: true
                }
            ) :
            !isEditing
                ? {
                    type: "submit",
                    label: "Submit",
                    disabled: !approver?.data,
                }
                : {
                    type: "button",
                    label: "Edit",
                    leadingIcon: "edit",
                    disabled: !approver?.data,
                    onClick: changePrimaryButton,
                };

    const newRequestOrEnableEditActionDisabled =
        !isEditing ?
            {
                type: "submit",
                label: "Submit",
                disabled: true,
            }
            : {
                type: "button",
                label: "Edit",
                leadingIcon: "edit",
                disabled: true,
                onClick: changePrimaryButton,
            };

    const updateRequestAction =
        isSubmiting ?
            (
                {
                    isSubmiting: true
                }
            ) :
            {
                type: "submit",
                label: "Save",
                leadingIcon: "save",
                disabled: !approver?.data,
            };

    const updateRequestActionDisabled =
    {
        type: "submit",
        label: "Save",
        leadingIcon: "save",
        disabled: true,
    };

    const tertiaryActionEnabled = {
        type: "button",
        label: "Delete",
        to: isEditing && "?action=delete&id=" + timeOffIdToEdit,
    };

    const secondaryAction = {
        type: "button",
        label: "Close",
        onClick: onClose,
        fullWidth: (isEditing && !canUserEditDelete) || noApproverAssigned,
    };

    const primaryActionButton =
        (isSubmiting) ?
            {
                isSubmiting: true,
            } :
            (canUserEditDelete && !editingEnabled) ?
                {
                    primaryAction: !disabledSubmit
                        ? newRequestOrEnableEditAction
                        : newRequestOrEnableEditActionDisabled
                    ,
                }
                :
                editingEnabled ?
                    {
                        primaryAction: !disabledSubmit
                            ? updateRequestAction
                            : updateRequestActionDisabled,
                    }
                    : <></>;

    const tertiaryActionButton =
        (editingEnabled && canUserEditDelete) ?
            {
                tertiaryAction: tertiaryActionEnabled,
            } : <></>;

    return (
        <>
            {
                (timeOffRequests.isLoading
                    || userTimeOffReasons.isLoading
                    || userTimeOffReasonsNextYear.isLoading
                    || reasons.isLoading
                    || accrualRules.isLoading
                    || (isEditing && timeOffRequest.isLoading))
                    ? (
                        <LoadingWrapper>
                            <Loading text="Loading form..." />
                        </LoadingWrapper>
                    )
                    : (timeOffRequests.error
                        || reasons.error
                        || userTimeOffReasons.error
                        || accrualRules.error
                        || (isEditing && timeOffRequest.error))
                        ? (<>Error Loading The Form. Try Again Please. Refresh the page.</>)
                        :
                        (
                            <FormComponent
                                methods={methods}
                                onSubmit={onSubmit}
                                defaultValues={transformTimeOffDataToFormData(
                                    currentTimeOfRequest
                                )}
                            >
                                <VStack>
                                    <VStack spacing="Two">
                                        {(!isRejectedRequest && noApproverAssigned) &&
                                            <VStack spacing="Two">
                                                <NoApproverAssinged />

                                                {!userOffice.isLoading && (
                                                    <ContactSupport userOffice={userOffice} />
                                                )}
                                            </VStack>
                                        }
                                        {(isApprovedRequest || isRejectedRequest) && (
                                            <>
                                                <VStack>
                                                    <HStack justify="space-between">
                                                        <InputLabel
                                                            variant="Descriptive_2_1"
                                                            color="Gray_4"
                                                        >
                                                            Request Decision
                                                        </InputLabel>
                                                        {currentTimeOfRequest?.approvedHRDate && (
                                                            <Text
                                                                variant="Descriptive_2_1"
                                                                color="Gray_4"
                                                            >
                                                                Reviewed{" "}
                                                                {format(
                                                                    new Date(
                                                                        currentTimeOfRequest?.approvedHRDate
                                                                    ),
                                                                    "MMM dd, yyyy"
                                                                )}
                                                            </Text>
                                                        )}
                                                    </HStack>
                                                    <HStack spacing="Zero_5">
                                                        <StyledIcon
                                                            size={17}
                                                            name={
                                                                isRejectedRequest
                                                                    ? "error"
                                                                    : "success"
                                                            }
                                                            color={
                                                                isRejectedRequest
                                                                    ? "Orange_1"
                                                                    : "Green"
                                                            }
                                                        />
                                                        <Text
                                                            variant="Body_1_2"
                                                            color="Black"
                                                        >
                                                            {currentTimeOfRequest?.status}
                                                        </Text>
                                                    </HStack>
                                                </VStack>
                                                {!noApproverAssigned && !approver.isLoading && (
                                                    <ContactCard
                                                        photoUrl={
                                                            env.BESTWORK_REACT_ASSET_DOMAIN +
                                                            "/" +
                                                            approver?.data.SmallPhotoUrl
                                                        }
                                                        name={approver?.data.FullName}
                                                        jobPost={approver?.data.Title}
                                                        department={
                                                            approver?.data.Department
                                                        }
                                                        emailId={approver?.data.Email}
                                                        contactCardLabel="Request Reviewed by"
                                                        contactCardLabelVariant="Body_2_2"
                                                        contactCardLabelColor="Gray_1"
                                                    />
                                                )}
                                            </>
                                        )}
                                        <VStack spacing="One">
                                            <Dropdown
                                                name="OOOType"
                                                validations={{
                                                    required: true,
                                                }}
                                                onChange={reasonDropDownChange}
                                                label="Type Of Request"
                                                options={reasonsDropdownOptions}
                                                disabledOptionsList={disabledDropdownOptions}
                                                disabled={
                                                    noApproverAssigned
                                                    || isRejectedRequest
                                                    || (isEditing && !editingEnabled)
                                                }
                                            />
                                            {showAllowed && reasonType != null && (
                                                <VStack spacing="Zero_25">
                                                    <Text
                                                        variant="Descriptive_1_1"
                                                        color="Gray_2"
                                                    >
                                                        Remaining / Total
                                                    </Text>
                                                    <HStack spacing="Zero_25">
                                                        <Text variant="Body_2_1" color="Black">
                                                            {reasonType?.daysRemaining} /{" "}
                                                            {reasonType?.allotment ?? 0}
                                                            {" (" + yearOfRequest + ")"}
                                                        </Text>
                                                        {showAllotmentNextYear &&
                                                            <Text variant="Body_2_1" color="Black">
                                                                {" ; "}
                                                                {nextYearAllotment?.daysRemaining} /{" "}
                                                                {nextYearAllotment?.allotment ?? 0}
                                                                {" (" + (yearOfRequest + 1) + ")"}
                                                            </Text>}
                                                    </HStack>
                                                </VStack>
                                            )}
                                        </VStack>
                                        <VStack spacing="Zero_5">
                                            <DateRangePicker
                                                name="date"
                                                label="Date"
                                                onChange={dateRangeChange}
                                                validations={{
                                                    required: true,
                                                    validate: (value) =>
                                                        dateRangeValidations(value) ||
                                                        datePickerError,
                                                }}
                                                disableDays={getDisabledDays()}
                                                minDate={minPreviousDate}
                                                enableDaysFromDate={enableDaysFromDate}
                                                enableDaysUntilDate={enableDaysUntilDate}
                                                disabled={
                                                    noApproverAssigned
                                                    || isRejectedRequest
                                                    || (isEditing && !editingEnabled)
                                                }
                                            />
                                            {exceededDaysErrorMessage !== "" && (
                                                <DateRangevalidationForAllotment
                                                    isPTORequest={isPTORequest}
                                                    exceededDaysErrorMessage={
                                                        exceededDaysErrorMessage
                                                    }
                                                />
                                            )}
                                        </VStack>
                                        <OptionsWrapper>
                                            <HStack spacing="Seven">
                                                <Options
                                                    name="firstDayOptions"
                                                    label={"Start Date"}
                                                    multiselect={false}
                                                    options={daysOptions}
                                                    defaultSelection={"FullDay"}
                                                    onValueSelected={changeFirstDay}
                                                    disabled={
                                                        noApproverAssigned
                                                        || isRejectedRequest
                                                        || (isEditing && !editingEnabled)
                                                    }
                                                />
                                                {!hideSecondDayOptions && (
                                                    <Options
                                                        name="lastDayOptions"
                                                        label={"End Date"}
                                                        multiselect={false}
                                                        options={daysOptions}
                                                        defaultSelection={"FullDay"}
                                                        onValueSelected={changeLastDay}
                                                        disabled={
                                                            noApproverAssigned
                                                            || isRejectedRequest
                                                            || (isEditing && !editingEnabled)
                                                        }
                                                    />
                                                )}
                                            </HStack>
                                        </OptionsWrapper>
                                        <StyledTextArea
                                            name="note"
                                            showClearIcon={true}
                                            label="Provide further information"
                                            description="This field is optional"
                                            validations={{
                                                maxLength: {
                                                    value: 120,
                                                    message: "Please use fewer characters",
                                                },
                                            }}
                                            disabled={
                                                noApproverAssigned
                                                || isRejectedRequest
                                                || (isEditing && !editingEnabled)
                                            }
                                        />
                                        {!noApproverAssigned && !approver.isLoading && isPendingRequest && (
                                            <>
                                                <VStack spacing="Zero_25">
                                                    <InputLabel
                                                        variant="Body_2_2"
                                                        color="Gray_1"
                                                    >
                                                        Reviewer
                                                    </InputLabel>
                                                    <ContactCard
                                                        photoUrl={
                                                            env.BESTWORK_REACT_ASSET_DOMAIN +
                                                            "/" +
                                                            approver?.data.SmallPhotoUrl
                                                        }
                                                        name={approver?.data.FullName}
                                                        jobPost={approver?.data.Title}
                                                        department={
                                                            approver?.data.Department
                                                        }
                                                        emailId={approver?.data.Email}
                                                        contactCardLabel=""
                                                    />
                                                </VStack>
                                            </>
                                        )}
                                        {isEditing && currentTimeOfRequest && (
                                            <VStack spacing="Zero_25">
                                                <Text
                                                    variant="Descriptive_1_1"
                                                    color="Gray_2"
                                                >
                                                    Submitted
                                                </Text>
                                                <Text variant="Body_2_2" color="Black">
                                                    {format(
                                                        new Date(
                                                            currentTimeOfRequest.createdDate
                                                        ),
                                                        "MMM dd, yyyy"
                                                    )}
                                                </Text>
                                            </VStack>
                                        )}
                                        {(isRejectedRequest || !noApproverAssigned)
                                            && !userOffice.isLoading
                                            && (
                                                <SupportWrapper spacing="One">
                                                    <ContactSupport userOffice={userOffice} />
                                                </SupportWrapper>
                                            )}
                                    </VStack>

                                    <ModalActions
                                        //Primary Action Button
                                        {...primaryActionButton}
                                        //Secondary Action Button - Close button
                                        secondaryAction={secondaryAction}
                                        //Tertiary Action Button - Delete button
                                        {...tertiaryActionButton}
                                        as="fieldset"
                                    />
                                </VStack>
                            </FormComponent>
                        )}
        </>
    );
};

export default TimeOffRequestForm;
