import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";

import HStack from "components/design-system/layout/HStack";
import VStack from "components/design-system/layout/VStack";
import Table from "components/design-system/tables/Table";
import TableFilters from "components/design-system/tables/TableFilters";
import SecondaryButton from "components/design-system/controls/button/SecondaryButton";
import { getCellWidthInPercent } from "pages/admin/clients/ClientsTable";

import useHolidays from "pages/admin/holidays/useHolidays";
import useOffices from "pages/admin/offices/useOffices";

const columns = [
    {
        headCellProps: {
            text: "Location",
            width: getCellWidthInPercent(160),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        filter: (rows, _columnIds, filterValue) => {
            if (filterValue === "All") {
                return rows;
            } else {
                return rows.filter((row) => {
                    return filterValue === row.values.OfficeName;
                });
            }
        },
        accessor: "OfficeName",
    },
    {
        headCellProps: {
            text: "Name",
            minWidth: getCellWidthInPercent(230),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value ?? "-",
        }),
        accessor: "Name",
    },
    {
        headCellProps: {
            text: "Holiday Date",
            width: getCellWidthInPercent(160),
        },
        getBodyCellProps: (cell) => ({
            text: format(new Date(cell.value), "MMM dd, yyyy") ?? "-",
        }),
        filter: (rows, _columnIds, filterValue) => {
            return rows.filter((row) => {
                return (
                    filterValue === format(new Date(row.values.Date), "yyyy")
                );
            });
        },
        accessor: "Date",
    },
    {
        headCellProps: {
            text: "Time",
            width: getCellWidthInPercent(100),
        },
        getBodyCellProps: (cell) => ({
            text: cell.value === false ? "All Day" : "Half Day",
        }),
        accessor: "HalfDay",
    },
    {
        headCellProps: {
            width: getCellWidthInPercent(60),
        },
        accessor: "edit",
        disableSortBy: true,
        getBodyCellProps: (cell) => ({
            shownByRowHover: true,
            actionIcon: "edit",
            actionProps: {
                to: `?action=edit&id=${cell.row.original.Date}`,
                replace: true,
                "aria-label": "Edit Office Holidays",
            },
        }),
    },
];

const tableCTAProps = {
    leadingIcon: "expand",
    label: "Add Holiday",
    to: "?action=add",
    replace: true,
};

const HolidaysTable = () => {
    const { offices } = useOffices();
    const { holidays } = useHolidays();

    const [locations, setLocations] = useState([]);
    const results = useRef();

    useEffect(() => {
        results.current = [];
        offices?.data?.map((locationData) => {
            results.current?.push({
                value: locationData?.Name,
                label: `${locationData?.Name}, ${locationData?.CountryName}`,
            });
        });
        setLocations([{ value: "All", label: "All" }, ...results.current]);
    }, [offices?.data]);

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const nextYear = currentYear + 1;

    const FiltersConfig = [
        {
            name: "Date",
            columnAccessor: "Date",
            isDropdownControl: true,
            options: [
                { value: currentYear.toString(), label: "This Year" },
                { value: nextYear.toString(), label: "Next Year" },
                { value: previousYear.toString(), label: "Last Year" },
            ],
            dropdownFilterLabel: "Year",
        },
        {
            name: "OfficeName",
            columnAccessor: "OfficeName",
            isDropdownControl: true,
            options: locations?.map((data) => ({
                value: data.value,
                label: data.label,
            })),
            dropdownFilterLabel: "Location",
        },
    ];

    const [filters, setFilters] = useState();

    const sortBy = [
        {
            id: "Date",
            desc: true,
        },
    ];

    if (holidays.isLoading || offices.isLoading) {
        return <>Loading...</>;
    }

    if (holidays.error || offices.error) {
        return <>Error</>;
    }

    return (
        <>
            <VStack>
                <HStack align="end" justify="space-between">
                    <HStack align="flex-end">
                        <TableFilters
                            filtersConfig={FiltersConfig}
                            onChange={setFilters}
                            initialFilterData={[
                                { name: "Date", value: currentYear.toString() },
                                {
                                    name: "OfficeName",
                                    value: "All",
                                },
                            ]}
                        />
                    </HStack>
                    <SecondaryButton {...tableCTAProps} />
                </HStack>
                <Table
                    columns={columns}
                    data={holidays?.data}
                    filters={filters}
                    emptyActionProps={tableCTAProps}
                    sortBy={sortBy}
                />
            </VStack>
        </>
    );
};

export default React.memo(HolidaysTable);
