import { useContext, useEffect, useState } from "react";
import env from "react-dotenv";
import { isValid, format } from "date-fns";
import styled from "styled-components";

import DrawerActions from "components/design-system/ui/drawer/DrawerActions";
import DataGroup from "components/design-system/ui/drawer/DataGroup";
import ContactCard from "components/design-system/ui/drawer/ContactCard";
import Subject from "components/design-system/ui/drawer/Subject";
import AccessGroupList from "components/Allocations/Grid/AccessGroup/AccessGroup";
import VStack from "components/design-system/layout/VStack";

import Loader from "components/UI/Loader";
import { useUser } from "utilities/API/Users";
import { useSkillSearchProfileUrlByEmployeeEmailGetter } from "utilities/API/SkillSearch";
import { errorNotification } from "utilities/Notification";
import CustomErrors from "resources/CustomErrors";
import usePageActionPermission from "hooks/Access/usePageActionPermission";

import DrawerContext from "context/Drawer/DrawerContext";

const LoaderContainer = styled.div`
    text-align: center;
`;

const EmployeeDrawerNew = (props) => {
    const { userId } = props;
    const canViewAllocations = usePageActionPermission(
        "AC_VIEW_OTHER_EMP_ALLOCATION",
        global.config.routes.allocation
    );
    const { getUserDetail } = useUser();
    const getSkillSearchUrl = useSkillSearchProfileUrlByEmployeeEmailGetter();
    const [profileLink, setProfileLink] = useState(null);
    const [user, setUser] = useState({
        id: null,
        name: null,
        title: null,
        photo: null,
        type: null,
        department: null,
        departmentId: null,
        location: null,
        talentManager: null,
        departureDate: null,
        community: null,
    });
    const { handleCloseMasterDrawer, setIsCTAPrimaryAction } =
        useContext(DrawerContext);

    useEffect(() => {
        if (!canViewAllocations) {
            setIsCTAPrimaryAction(false);
        }
    }, []);

    useEffect(() => {
        if (userId) {
            getUserDetail([{ name: "$userId", value: userId }])
                .then((payload) => {
                    const fetchedUser = payload;
                    setUser({
                        id: userId,
                        name: fetchedUser.FullName,
                        title: fetchedUser.Title,
                        photo:
                            fetchedUser.LargePhotoUrl &&
                            `${env.BESTWORK_REACT_ASSET_DOMAIN}/${fetchedUser.LargePhotoUrl}`,
                        type: fetchedUser.EmploymentType,
                        department: fetchedUser.Department,
                        departmentId: fetchedUser.DepartmentId,
                        seatOffice: fetchedUser.Office,
                        payOffice: fetchedUser.PayOffice,
                        talentManager: fetchedUser.TalentManager,
                        departureDate: fetchedUser.DepartureDate,
                        community: fetchedUser.Community,
                    });

                    getSkillSearchUrl(fetchedUser.Email).then((url) => {
                        setProfileLink(url);
                    });
                })
                .catch(() => {
                    /**/
                });
        } else {
            errorNotification(undefined, CustomErrors.OnGetUser);
        }
    }, [userId]);

    return (
        <>
            {userId && user.name ? (
                <>
                    <VStack spacing="Two">
                        <Subject
                            photoUrl={user.photo}
                            subjectName={user.name}
                            label={
                                user.type === "Freelancer" ? "FREELANCE" : null
                            }
                            user={user}
                            profileLink={profileLink}
                        />

                        <DataGroup
                            isPadding={false}
                            groupData={[
                                {
                                    label: "Title",
                                    value: user.title,
                                },
                                {
                                    label: "Department",
                                    value: user.department,
                                    isHr: true,
                                },
                                {
                                    label: "Pay Office",
                                    value: user.payOffice,
                                },
                                {
                                    label: " Community / Group",
                                    value: user.community ? (
                                        user.community
                                    ) : (
                                        <>&mdash;</>
                                    ),
                                    isHr: true,
                                },
                                {
                                    label: "Seat Office",
                                    value: user.seatOffice,
                                    isHr: isValid(new Date(user.departureDate))
                                        ? false
                                        : true,
                                },
                                {
                                    label: "Departure Date",
                                    value: isValid(new Date(user.departureDate))
                                        ? format(
                                              new Date(user.departureDate),
                                              "MMM dd, yyyy"
                                          )
                                        : null,
                                    isHr: true,
                                },
                            ]}
                        />

                        {user.talentManager && (
                            <ContactCard
                                photoUrl={
                                    env.BESTWORK_REACT_ASSET_DOMAIN +
                                    "/" +
                                    user.talentManager.SmallPhotoUrl
                                }
                                name={user.talentManager.FullName}
                                jobPost={user.talentManager.Title}
                                department={user.talentManager.Department}
                                emailId={user.talentManager.Email}
                            />
                        )}

                        <AccessGroupList
                            userId={userId}
                            departmentId={user.departmentId}
                            onClose={handleCloseMasterDrawer}
                        />
                    </VStack>
                    <DrawerActions
                        primaryAction={
                            canViewAllocations
                                ? {
                                      isInternalUrlNewTab: true,
                                      label: "View Employee Allocations",
                                      href: `${global.config.routes.allocation}?userId=${userId}`,
                                  }
                                : null
                        }
                        tertiaryAction={{
                            ...{
                                "aria-label":
                                    user.type === "Freelancer"
                                        ? "Skill Search not available for Freelance"
                                        : "",
                            },
                            type: "button",
                            label: "Skill Search Profile",
                            to: profileLink,
                            disabled: !profileLink,
                        }}
                    />
                </>
            ) : (
                <LoaderContainer>
                    <Loader height="5rem" width="5rem" />
                </LoaderContainer>
            )}
        </>
    );
};

export default EmployeeDrawerNew;
