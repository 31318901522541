import PropTypes from "prop-types";
import styled from "styled-components";

import Loader from "components/UI/Loader";
import { COLOR_VALUES } from "components/design-system/config/colors";
import Icon, { ICON_NAMES } from "components/design-system/ui/Icon";
import Text from "components/design-system/ui/Text";
import BaseButton from "components/design-system/controls/button/BaseButton";
import Tooltip from "components/design-system/ui/Tooltip";
import { TertiaryButtonStyle } from "components/design-system/controls/button/StyledElements";

const StyledQuaternaryButton = styled(BaseButton)`
    color: ${COLOR_VALUES.Black} !important; /* !important to override css reboot */
    text-decoration: none !important; /* !important to override css reboot */
    padding: 2px;
    min-height: 20px;
    border: 1px solid ${COLOR_VALUES.Gray_6};

    ${TertiaryButtonStyle}

    gap: 0;
    border-radius: 10px;

    &:not(:disabled) {
        &:hover {
            color: ${COLOR_VALUES.Gray_2} !important;
            background: ${COLOR_VALUES.Gray_7};
            border-color: ${COLOR_VALUES.Gray_6};
        }

        &:active,
        &:focus-visible {
            border-color: transparent;
        }
    }

    &:disabled {
        background: ${COLOR_VALUES.Gray_8};
        border-color: ${COLOR_VALUES.Gray_6};
    }
`;

const StyledText = styled(Text)`
    padding-left: 4px;
    padding-right: 4px;
    color: ${(props) => COLOR_VALUES[props.$color] ?? ""};
`;

const InlineBlockToPreventTooltipDisabledButtonError = styled.div`
    display: inline-block;
`;

const QuaternaryButton = ({
    loading = false,
    label,
    labelColor,
    leadingIcon,
    leadingIconColor,
    trailingIcon,
    ...rest
}) => {
    const ariaLabel = rest["aria-label"];

    if (!label && !ariaLabel) {
        console.error("Icon-only button missing aria-label");
    }

    return (
        <>
            {loading ? (
                <Loader width={18} height={18} color={COLOR_VALUES.Gray_1} />
            ) : (
                <Tooltip text={ariaLabel} enabled={!label}>
                    <InlineBlockToPreventTooltipDisabledButtonError>
                        <StyledQuaternaryButton {...rest}>
                            {leadingIcon && (
                                <Icon
                                    size={14}
                                    name={leadingIcon}
                                    color={leadingIconColor}
                                />
                            )}
                            {label && (
                                <StyledText
                                    $color={labelColor}
                                    variant="Descriptive_1_1"
                                >
                                    {label}
                                </StyledText>
                            )}
                            {trailingIcon && (
                                <Icon size={14} name={trailingIcon} />
                            )}
                        </StyledQuaternaryButton>
                    </InlineBlockToPreventTooltipDisabledButtonError>
                </Tooltip>
            )}
        </>
    );
};

QuaternaryButton.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
    leadingIcon: PropTypes.oneOf(ICON_NAMES),
    trailingIcon: PropTypes.oneOf(ICON_NAMES),
    "aria-label": PropTypes.string,
    disabled: PropTypes.bool,
};

export default QuaternaryButton;
