import { useMemo } from "react";
import env from "react-dotenv";
import { useApi } from "./Helpers/useApi";

export const useAllocations = () => {
    const { getDataAsync, postData } = useApi();

    const getAllocationsSummary = async (params) => {
        return getDataAsync("allocationsSummary", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getAllocationsDetail = async (params) => {
        return getDataAsync("allocationsDetail", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getAllocationsEstimation = async (params) => {
        return getDataAsync("allocationsEstimation", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const getAllocationsUsersIds = async (params) => {
        return getDataAsync("allocationsUsersIds", { params: params }).then(
            (payload) => {
                return payload.json();
            }
        );
    };

    const saveAllocationsHours = async (params) => {
        if (
            env.BESTWORK_REACT_ALLOCATIONS_ENABLE_SAVE.toLowerCase() === "true"
        ) {
            return postData("saveAllocationsHours", params);
        }
    };

    return useMemo(() => {
        return {
            getAllocationsSummary,
            getAllocationsDetail,
            getAllocationsEstimation,
            saveAllocationsHours,
            getAllocationsUsersIds,
        };
    }, []);
};
