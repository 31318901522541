import VStack from "components/design-system/layout/VStack";
import RetainersDashboardOverviewData from "pages/projects/retainers-dashboard/single/RetainersDashboardOverviewData";
import RetainersDashboardOverviewBudget from "pages/projects/retainers-dashboard/single/RetainersDashboardOverviewBudget";
import RetainersDashboardOverviewProject from "pages/projects/retainers-dashboard/single/RetainersDashboardOverviewProject";

const RetainersDashboardOverview = () => {
    return (
        <>
            <VStack style={{ gap: "40px" }}>
                <RetainersDashboardOverviewData />
                <div>
                    <RetainersDashboardOverviewBudget />
                </div>
                <div>
                    <RetainersDashboardOverviewProject />
                </div>
            </VStack>
        </>
    );
};

export default RetainersDashboardOverview;
