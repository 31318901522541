import styled from "styled-components";

export const BodyWrapper = styled.div`
    margin: auto 1rem;
`;

export const LineWrapper = styled.div`
    margin: 1rem;
`;

export const ButtonWrapper = styled.div`
    float: right;
    padding: 0rem 3rem 0 0;
    width: 100%;
    text-align: right;
`;

export const Title = styled.div`
    margin: -2rem -4rem;
`;
