import PropTypes from "prop-types";

const File = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M11.0833 14H0V0H8.40583L11.0833 2.6775V14ZM1.16667 12.8333H9.91667V3.15583L7.9275 1.16667H1.16667V12.8333Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="11.0833"
                        height="14"
                        fill="white"
                        transform="translate(3 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

File.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

File.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default File;
