import { useMemo } from "react";
import env from "react-dotenv";

export const useCrypto = () => {
    let CryptoJS = require("crypto-js");

    const decrypt = (value) => {
        let reb64 = CryptoJS.enc.Hex.parse(value);
        let bytes = reb64.toString(CryptoJS.enc.Base64);
        let decrypt = CryptoJS.AES.decrypt(bytes, env.ENCRYPTION_KEY);
        let plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    };

    const encrypt = (value) => {
        let b64 = CryptoJS.AES.encrypt(value, env.ENCRYPTION_KEY).toString();
        let e64 = CryptoJS.enc.Base64.parse(b64);
        let eHex = e64.toString(CryptoJS.enc.Hex);
        return eHex;
    };

    const methods = useMemo(() => {
        return {
            encrypt,
            decrypt,
        };
    }, []);

    return methods;
};
