import MenuItem from "@material-ui/core/MenuItem";
import Text from "./Text";
import VersionsListIcon from "components/Icon/VersionsList";
import styled from "styled-components";

const TextWrapper = styled.div`
    margin: auto 0.5rem;
    height: 1.75rem;
    padding-top: 0.1rem;
`;

export const AboutMenuProfileItem = (props) => {
    return (
        <MenuItem onClick={props.onClick}>
            <VersionsListIcon />
            <TextWrapper>
                <Text variant="medium">Version</Text>
            </TextWrapper>
        </MenuItem>
    );
};
