import VStack from "components/design-system/layout/VStack";
import ProjectsDashboardOverviewData from "pages/projects/project-dashboard/single/ProjectsDashboardOverviewData";
import ProjectsDashboardOverviewBudget from "pages/projects/project-dashboard/single/ProjectsDashboardOverviewBudget";
import ProjectsDashboardOverviewProject from "pages/projects/project-dashboard/single/ProjectsDashboardOverviewProject";
import Loading from "components/UI/Loading";

import { useProjectsDashboardRelatedData } from "pages/projects/project-dashboard/single/useProjectsDashboardData";

const ProjectsDashboardOverview = () => {
    const { useRelatedProjectsDashboard } = useProjectsDashboardRelatedData();
    const summaryMoney = useRelatedProjectsDashboard("money");
    const summaryHours = useRelatedProjectsDashboard("hours");

    if (summaryMoney.isLoading || summaryHours.isLoading) {
        return <Loading />;
    }

    return (
        <>            
            <VStack style={{ gap: "40px" }}>
                <ProjectsDashboardOverviewData />
                <div>
                    <ProjectsDashboardOverviewBudget />
                </div>
                {(summaryMoney?.data?.length > 0 ||
                    summaryHours?.data?.length > 0) && (
                    <div>
                        <ProjectsDashboardOverviewProject />
                    </div>
                )}
            </VStack>
        </>
    );
};

export default ProjectsDashboardOverview;
