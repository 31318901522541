import PropTypes from "prop-types";

const AngleCirculeRight = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rightArrow"
        >
            <circle r="8.5" transform="matrix(-1 0 0 1 9 9)" stroke="#E5E8EC" />
            <path d="M7.875 12.375L11.25 9L7.875 5.625" stroke={color} />
        </svg>
    );
};

AngleCirculeRight.defaultProps = {
    size: "18",
    color: global.config.colors.black,
};

AngleCirculeRight.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

export default AngleCirculeRight;
