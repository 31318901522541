import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { useApi } from "utilities/API/Helpers/useApi";

export const useAllUsers = () => {
    const { getDataAsync } = useApi();

    const getAllUsersApiCall = useCallback(async () => {
        const response = await getDataAsync("allUsers");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const allUsers = useQuery("allUsersList", getAllUsersApiCall);

    return useMemo(() => {
        return {
            allUsers,
        };
    }, [allUsers]);
};

const useClientContactLeads = () => {
    const { getDataAsync, putData, postData, deleteData } = useApi();
    const queryClient = useQueryClient();
    const { clientId } = useParams();

    const getClientContactLeadsApiCall = useCallback(async () => {
        const response = await getDataAsync("getContactLeadsByClientId", {
            urlParams: [{ name: "$clientId", value: clientId }],
        });
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const clientContactLeads = useQuery(
        "clientContactLeadsList",
        getClientContactLeadsApiCall
    );

    const createClientContactLeadApiCall = useCallback(
        ({ body }) => {
            return postData("clientContactLeads", {
                body,
            });
        },
        [postData]
    );

    const updateClientContactLeadApiCall = useCallback(
        ({ body }) => {
            return putData("clientContactLeads", {
                body,
            });
        },
        [putData]
    );

    const deleteClientContactLeadApiCall = useCallback(
        ({ clientContactLeadId }) => {
            return deleteData("deleteClientContactLead", {
                urlParams: [{ name: "$id", value: clientContactLeadId }],
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("clientContactLeadsList");
            },
        };
    }, [queryClient]);

    const createClientContactLead = useMutation(
        createClientContactLeadApiCall,
        mutationOptions
    );
    const updateClientContactLead = useMutation(
        updateClientContactLeadApiCall,
        mutationOptions
    );
    const deleteClientContactLead = useMutation(
        deleteClientContactLeadApiCall,
        mutationOptions
    );

    return useMemo(() => {
        return {
            clientContactLeads,
            createClientContactLead,
            updateClientContactLead,
            deleteClientContactLead,
        };
    }, [
        clientContactLeads,
        createClientContactLead,
        updateClientContactLead,
        deleteClientContactLead,
    ]);
};

export default useClientContactLeads;
