import React, { useContext, useEffect, useState } from "react";
import { today, getLocalTimeZone } from "@internationalized/date";
import styled from "styled-components";

import VStack from "components/design-system/layout/VStack";
import HStack from "components/design-system/layout/HStack";
import Text from "components/design-system/ui/Text";
import TertiaryButton from "components/design-system/controls/button/TertiaryButton";
import { COLOR_VALUES } from "components/design-system/config/colors";
import {
    currentWeekStartDate,
    previousWeekStartDate,
} from "pages/timesheet/timesheet-grid/SubmitTimesheet";

import WeekNavigatorContext from "context/WeekNavigator/WeekNavigatorContext";
import SubmitTimesheetContext from "context/Timesheet/SubmitTimesheetContext";
import env from "react-dotenv";

const StyledText = styled(Text)`
    font-variation-settings: "opsz" 12;
    font-weight: 425;
    padding-top: 4.63px;
`;

const StyledCopyText = styled(Text)`
    padding-top: 4.63px;
`;

const StyledWrapper = styled(HStack)`
    .textMassage {
        visibility: hidden;
        font-weight: 425;
    }
    &:not(:disabled) {
        &:hover {
            button {
                background: ${COLOR_VALUES.White};
            }
        }
    }
    &:hover {
        .textMassage {
            visibility: visible;
        }
    }
`;

const LockedTimesheetFooter = () => {
    const { selectedTimesheetStartDate } = useContext(WeekNavigatorContext);
    const { selectedDateTimesheetStatus } = useContext(SubmitTimesheetContext);

    const [copy, setCopy] = useState("Copy");

    const copyToClipboard = (mail) => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(mail);
            setCopy("Copied");
        }
    };

    let fromDateVal = selectedTimesheetStartDate ?? today(getLocalTimeZone());
    let formatFromDateVal = `${fromDateVal.year}-${fromDateVal.month}-${fromDateVal.day}`;

    useEffect(() => {
        setCopy("Copy");
    }, [selectedTimesheetStartDate]);

    return (
        <>
            {previousWeekStartDate !== formatFromDateVal &&
                currentWeekStartDate !== formatFromDateVal &&
                new Date(currentWeekStartDate) > new Date(formatFromDateVal) &&
                selectedDateTimesheetStatus &&
                    (
                        <VStack spacing="Two">
                            <HStack spacing="Zero_25">
                                <StyledText variant="Body_2_1" color="Gray_2">
                                    This week&#39;s timesheet is locked and may not
                                    be edited. If you need to reopen the
                                    timesheet&#44; please contact
                                </StyledText>
                                <StyledWrapper spacing="Zero_25">
                                    <TertiaryButton
                                        trailingIcon="mail"
                                        label="Reporter Team"
                                        onClick={() => {
                                            copyToClipboard(env.REPORTER_SUPPORT_EMAIL);
                                        }}
                                    />
                                    <StyledCopyText
                                        variant="Body_2_1"
                                        className="textMassage"
                                        color="Gray_2"
                                    >
                                        {copy}
                                    </StyledCopyText>
                                </StyledWrapper>
                            </HStack>
                        </VStack>
                    )}
        </>
    );
};

export default LockedTimesheetFooter;
