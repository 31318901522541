import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useRegions = () => {
    const { getDataAsync, postData, putData } = useApi();
    const queryClient = useQueryClient();

    const getRegionsApiCall = useCallback(async () => {
        const response = await getDataAsync("regions");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const regions = useQuery("regionsList", getRegionsApiCall);

    const createRegionApiCall = useCallback(
        (body) => {
            return postData("regions", {
                body,
            });
        },
        [postData]
    );

    const updateRegionApiCall = useCallback(
        (body) => {
            return putData("regions", {
                body,
            });
        },
        [putData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("regionsList");
            },
        };
    }, [queryClient]);

    const createRegion = useMutation(createRegionApiCall, mutationOptions);
    const updateRegion = useMutation(updateRegionApiCall, mutationOptions);

    return useMemo(() => {
        return {
            regions,
            createRegion,
            updateRegion,
        };
    }, [regions, createRegion, updateRegion]);
};

export default useRegions;
