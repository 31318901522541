export const allocationsGridModes = {
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
};

export const formModes = {
    ADD: 0,
    ADDED: 1,
    UPDATE: 2,
    UPDATED: 3,
    DELETE: 4,
    DELETED: 5,
    DETAIL: 6,
};

export const customFilterTypes = {
    ALLOCATION: "Allocations",
};

export const batchOperation = {
    add: "add",
    remove: "remove",
};

export const saveHoursConfirmation = {
    deleteNoteForZeroAllocation: 0,
    addHoursToZeroAvailable: 1,
};

export const modules = {
    Allocations: "Allocations",
    Admin: "Admin",
    Timesheets: "Timesheets",
    BusinessIntelligence: "BusinessIntelligence",
};

export const EDGES = {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
};

export const AllotmentTypes = {
    PTO: 1, // Personal time off
    CARR: 2, // Carry over pto
    CMPPTO: 3, // Compensation time award
    SICK: 4, // Sick days
    SMRNY: 5, // summer days
    PTOAUTO: 6, // Carry over sick days
    MOVE: 7, // Move
    STUDY: 8, // Study
    GENERALREST: 9999, // sick, rest, family
    OTHERS: 999, // other
}

export const ReasonCode = {
    PTO: "PTO", 
    FTO: "FTO"
}
