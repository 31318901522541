import { useContext, useState } from "react";
import { useFormState, useWatch } from "react-hook-form";

import VStack from "components/design-system/layout/VStack";
import TextInput from "components/design-system/forms/TextInput";
import Options from "components/design-system/forms/Options";
import Dropdown from "components/design-system/forms/Dropdown";
import Form from "components/design-system/forms/Form";
import ModalActions from "components/design-system/ui/modal/ModalActions";
import Text from "components/design-system/ui/Text";
import { sendNotification } from "utilities/Notification";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";

import useOffices from "pages/admin/offices/useOffices";
import {
    useClientsApi,
    useClientsFamilies,
    useCorporateByFamilyIdApi,
} from "pages/admin/clients/useClients";
import useAgencies from "pages/admin/agencies/useAgencies";

import { ServerSidePaginationContext } from "context/TablePagination/ServerSidePaginationContext";
import ClientContext from "context/Admin/ClientContext";

const attributesOptions = [
    { label: "Active", value: "Active" },
    { label: "Internal", value: "ExcludeInternalOH" },
    { label: "Admin", value: "IsAdmin" },
    { label: "Pitch", value: "IsPitch" },
    { label: "Not publish", value: "IsConfidential" },
    { label: "External Agency", value: "IsAgency" },
];

let corporateOptions = [];

function transformClientDataToFormData(clientData = {}) {
    return {
        name: clientData.Name ?? "",
        shortName: clientData.ShortName ?? "",
        sanitizedName: clientData.SanitizedName ?? "",
        externalCode: clientData.ExtAccountingCode ?? "",
        alternativeCode: clientData.AltAccountingCode ?? "",
        agency: clientData.LeadAgencyId,
        family: clientData.ClientFamilyId,
        corporateEntity: clientData.ClientCorpEntityId,
        leadOffice: clientData.OfficeRegionId,
        attributes: attributesOptions
            .map((attribute) => attribute.value)
            .filter((item) =>
                item === "Active" ? clientData[item] ?? true : clientData[item]
            )
            .join(","),
        addressLine1: clientData.Address1 ?? "",
        addressLine2: clientData.Address2 ?? "",
        city: clientData.City ?? "",
        state: clientData.State ?? "",
        zipCode: clientData.Zip ?? "",
    };
}

function transformFormDataToClientData(
    formData = {},
    clientData = {},
    officeName = "",
    agencyName = "",
    familyName = "",
    clientCorporateName = ""
) {
    const newClientData = { ...clientData };
    newClientData.Name = formData.name ?? "";
    newClientData.ShortName = formData.shortName ?? "";
    newClientData.SanitizedName = formData.sanitizedName ?? "";
    newClientData.ExtAccountingCode = formData.externalCode ?? "";
    newClientData.AltAccountingCode = formData.alternativeCode ?? "";
    newClientData.LeadAgencyId = formData.agency;
    newClientData.ClientFamilyId = formData.family;
    newClientData.ClientCorpEntityId = formData.corporateEntity;
    newClientData.OfficeRegionId = formData.leadOffice;
    attributesOptions.forEach((attribute) => {
        newClientData[attribute.value] = formData.attributes
            .split(",")
            .includes(attribute.value);
    });
    newClientData.Address1 = formData.addressLine1 ?? "";
    newClientData.Address2 = formData.addressLine2 ?? "";
    newClientData.City = formData.city ?? "";
    newClientData.State = formData.state ?? "";
    newClientData.Zip = formData.zipCode ?? "";
    newClientData.LastUpdate = formData.LastUpdate ?? new Date();
    newClientData.OfficeName = officeName;
    newClientData.LeadAgencyName = agencyName;
    newClientData.ClientFamily = familyName;
    newClientData.ClientCorporate = clientCorporateName;
    return newClientData;
}

const validateSanitizedName = (value) => /^[a-zA-Z0-9_]*$/.test(value);

const replaceSpecialCharacter = (name) => {
    return name?.replaceAll(/[^\w ]/g, "")?.replaceAll(/ /g, "_");
};

const getSanitizeNameHelpText = (
    clientName,
    isEditing,
    currentClientDataName
) => {
    return clientName
        ? `Suggestion: ${replaceSpecialCharacter(clientName)}`
        : isEditing &&
              `Suggestion: ${replaceSpecialCharacter(currentClientDataName)}`;
};

const getClientDataExceptCurrent = (allData, clientIdToEdit) => {
    return allData?.filter(
        (filterClient) => filterClient.ClientId !== clientIdToEdit
    );
};

const RenderCorporateDropdown = () => {
    const currentFamilyId = useWatch({ name: "family" });
    const { useCorporateByFamilyId } = useCorporateByFamilyIdApi();
    const corporateByFamilyId = useCorporateByFamilyId({
        familyId: currentFamilyId ?? "",
    });
    corporateOptions = !corporateByFamilyId?.isLoading
        ? getDropdownOptions(
            corporateByFamilyId?.data,
            "clientCorpEntityID",
            "clientCorpEntityName"
          )
        : [];
    return (
        <Dropdown
            name="corporateEntity"
            validations={{
                required: true,
            }}
            label="Corporate Entity"
            options={corporateOptions}
        />
    );
};

const ClientForm = ({ clientIdToEdit, onClose }) => {
    const { offices } = useOffices();
    const { useClientsList, createClient, updateClient } = useClientsApi();
    const { agencies } = useAgencies();
    const { clientFamilies } = useClientsFamilies();
    const { queryPageIndex, queryPageSize, querySortBy } = useContext(
        ServerSidePaginationContext
    );
    const { activeInactiveState } = useContext(ClientContext);

    const clientsDataApi = useClientsList({
        sortBy: querySortBy,
        pageSize: queryPageSize,
        pageIndex: queryPageIndex,
        active: activeInactiveState,
    });

    const [clientName, setClientName] = useState();
    const [shortName, setShortName] = useState();

    if (clientsDataApi.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientsDataApi.error) {
        return <div>Error</div>;
    }

    const isEditing = typeof clientIdToEdit !== "undefined";
    const modifier = isEditing ? updateClient : createClient;

    const currentClientData = clientsDataApi?.data?.Items.find(
        (client) => client.ClientId === clientIdToEdit
    );

    if (isEditing && !currentClientData) {
        return <div>Could not find Client</div>;
    }

    const officeDropdownOptions = !offices.isLoading
        ? getDropdownOptions(offices?.data, "OfficeRegionId")
        : [];

    const agenciesDropdownOptions = !agencies.isLoading
        ? getDropdownOptions(agencies?.data?.Items, "Id")
        : [];

    const familyDropdownOptions = !clientFamilies.isLoading
        ? getDropdownOptions(clientFamilies?.data, "clientFamilyID")
        : [];


    const onSubmit = (formData) => {
        const clientData = transformFormDataToClientData(
            formData,
            currentClientData,
            officeDropdownOptions?.find((f) => f.value === formData.leadOffice)
                ?.label,
            agenciesDropdownOptions?.find((f) => f.value === formData.agency)
                ?.label,
            familyDropdownOptions?.find((f) => f.value === formData.family)
                ?.label,
            corporateOptions?.find(
                (f) => f.value === formData.corporateEntity
            )?.label
        );

        modifier.mutateAsync(clientData).then(() => {
            onClose();
            sendNotification(
                undefined,
                isEditing
                    ? `${clientData.Name} has been updated.`
                    : `${clientData.Name} has been added.`
            );
        });
    };

    const handleBlurForClientName = (event) => {
        setClientName(event.target.value);
    };

    const handleBlurForShortName = (event) => {
        setShortName(event.target.value);
    };

    const clientsDetails = isEditing
        ? getClientDataExceptCurrent(
              clientsDataApi?.data?.Items,
              clientIdToEdit
          )
        : clientsDataApi?.data?.Items;

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformClientDataToFormData(currentClientData)}
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <VStack>
                        <TextInput
                            type="text"
                            name="name"
                            label="Name"
                            validations={{
                                required: true,
                                validate: (value) =>
                                    clientsDetails.filter(
                                        (currentClientName) =>
                                            currentClientName.Name.toLowerCase() ===
                                            value.toLowerCase()
                                    ).length === 0 ||
                                    "A Client with this name already exists.",
                            }}
                            placeholder="Client Name"
                            onMouseOut={(e) => handleBlurForClientName(e)}
                        />
                        <TextInput
                            name="shortName"
                            label="Short Name"
                            validations={{
                                required: true,
                            }}
                            placeholder="e.g. AABB"
                            onMouseOut={(e) => handleBlurForShortName(e)}
                        />
                    </VStack>

                    <VStack>
                        <TextInput
                            name="sanitizedName"
                            label="Sanitized Name"
                            validations={{
                                required: true,
                                validate: (value) =>
                                validateSanitizedName(value) ||
                                "Use only letters, numbers and underscores",
                            }}
                            placeholder="Client_Name"
                            helpText={getSanitizeNameHelpText(
                                clientName,
                                isEditing,
                                currentClientData?.Name
                            )}
                        />
                        <TextInput
                            name="externalCode"
                            label="External Code"
                            validations={{
                                required: true,
                            }}
                            placeholder="e.g. 10101010"
                        />
                        <TextInput
                            name="alternativeCode"
                            label="Alternative Code"
                            validations={{
                                required: true,
                            }}
                            placeholder="e.g. SSAU"
                            helpText={
                                shortName
                                    ? `Suggestion: ${shortName}`
                                    : isEditing &&
                                      `Suggestion: ${currentClientData?.ShortName}`
                            }
                        />
                    </VStack>
                    <VStack>
                        <Dropdown
                            name="agency"
                            validations={{
                                required: true,
                            }}
                            label="Agency"
                            options={agenciesDropdownOptions}
                            itemsAreLoading={agencies.isLoading}
                        />
                        <Dropdown
                            name="family"
                            validations={{
                                required: true,
                            }}
                            label="Family"
                            options={familyDropdownOptions}
                            itemsAreLoading={clientFamilies.isLoading}
                        />
                        <RenderCorporateDropdown />
                        <Dropdown
                            name="leadOffice"
                            validations={{
                                required: true,
                            }}
                            label="Lead Office"
                            options={officeDropdownOptions}
                            itemsAreLoading={offices.isLoading}
                        />
                    </VStack>
                    <VStack>
                        <Options
                            name="attributes"
                            label="Attributes"
                            options={attributesOptions}
                        />
                    </VStack>

                    <VStack>
                        <VStack spacing="Zero_25">
                            <Text variant="Headline_4" color="Gray_1">
                                Optional
                            </Text>
                            <Text variant="Body_2_1" color="Gray_2">
                                The fields below are not required
                            </Text>
                        </VStack>

                        <TextInput
                            name="addressLine1"
                            label="Address line 1"
                            placeholder="e.g. 123 Street Name"
                        />
                        <TextInput
                            name="addressLine2"
                            label="Address line 2"
                            placeholder="e.g. 1st Floor"
                        />
                        <TextInput
                            name="city"
                            label="City"
                            placeholder="e.g. Beijing"
                        />
                        <TextInput
                            name="state"
                            label="State"
                            placeholder="e.g. FL"
                        />
                        <TextInput
                            name="zipCode"
                            label="ZIP Code"
                            placeholder="e.g. 1000"
                        />
                    </VStack>
                </VStack>
                <ModalActions
                    primaryAction={{
                        type: "submit",
                        label: isEditing ? "Save" : "Add Client",
                    }}
                    secondaryAction={{
                        type: "button",
                        label: "Cancel",
                        onClick: onClose,
                    }}
                    as="fieldset"
                    disabled={
                        modifier.isLoading ||
                        offices.isLoading ||
                        clientFamilies.isLoading ||
                        agencies.isLoading
                    }
                />
            </VStack>
        </Form>
    );
};

export default ClientForm;
