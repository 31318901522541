import { useFormState, useWatch } from "react-hook-form";

import Dropdown from "components/design-system/forms/Dropdown";
import VStack from "components/design-system/layout/VStack";
import Form from "components/design-system/forms/Form";
import { useAccessGroupMembersApiDepartments } from "pages/admin/access-groups/single/useAccessGroupMembers";
import ModalActions from "components/design-system/ui/modal/ModalActions";

import { sendNotification } from "utilities/Notification";
import FormFeedbackNotificationText from "components/design-system/ui/FormFeedbackNotificationText";

import useClientContactLeads, {
    useAllUsers,
} from "pages/admin/clients/single/useClientContactLeads";
import { getDropdownOptions } from "pages/admin/offices/OfficesForm";

const ModalCTA = ({
    isEditing,
    isLoading,
    onClose,
    handleOnClickDeleteContactLead,
}) => {
    const { isValid } = useFormState();
    const formData = useWatch();
    return (
        <ModalActions
            primaryAction={{
                type: "submit",
                label: isEditing ? "Save" : "Add",
                disabled: !isValid,
            }}
            secondaryAction={{
                type: "button",
                label: "Cancel",
                onClick: onClose,
            }}
            {...(isEditing && {
                tertiaryAction: {
                    type: "button",
                    leadingIcon: "delete",
                    label: "Delete",
                    onClick: () => handleOnClickDeleteContactLead(formData),
                },
            })}
            as="fieldset"
            disabled={isLoading}
        />
    );
};

function transformContactLeadDataToFormData(
    contactLeadData = {},
    departmentId = ""
) {
    return {
        name: contactLeadData.userId ?? "",
        department: departmentId,
    };
}

function transformFormDataToContactLeadData(
    formData = {},
    contactLeadData = {},
    departmentName = "",
    userData = null
) {
    const newContactLeadData = { ...contactLeadData };
    newContactLeadData.userId = formData.name;
    newContactLeadData.currentDepartmentId = formData.department;
    newContactLeadData.currentDepartment = departmentName;
    newContactLeadData.fullName = userData?.fullName;
    newContactLeadData.email = userData?.email;
    newContactLeadData.currentTitle = userData?.currentTitle ?? null;

    return newContactLeadData;
}

const ClientContactLeadsForm = ({ contactLeadIdToEdit, onClose }) => {
    const {
        clientContactLeads,
        createClientContactLead,
        updateClientContactLead,
        deleteClientContactLead,
    } = useClientContactLeads();

    const { allUsers } = useAllUsers();

    const { departments } = useAccessGroupMembersApiDepartments();

    if (clientContactLeads.isLoading) {
        return <div>Loading...</div>;
    }

    if (clientContactLeads.error) {
        return <div>Error</div>;
    }

    const departmentOptions = !departments.isLoading
        ? getDropdownOptions(departments?.data)
        : [];

    const contactLeadOptions = !allUsers.isLoading
        ? getDropdownOptions(allUsers?.data, "id", "fullName")
        : [];

    const isEditing = typeof contactLeadIdToEdit !== "undefined";
    const modifier = isEditing
        ? updateClientContactLead
        : createClientContactLead;

    const currentClientContactLeadData = clientContactLeads?.data.find(
        (ContactLead) => ContactLead.userId === contactLeadIdToEdit
    );

    if (isEditing && !currentClientContactLeadData) {
        return <div>Could not find contact lead</div>;
    }

    const handleOnClickDeleteContactLead = (prevData) => {
        deleteClientContactLead
            .mutateAsync({ clientContactLeadId: contactLeadIdToEdit })
            .then((response) => {
                onClose();
                sendNotification(
                    undefined,
                    <FormFeedbackNotificationText
                        responseStatus={response?.status}
                        isDeleting={true}
                        name={currentClientContactLeadData.fullName}
                    />,
                    global.config.notification.duration,
                    "",
                    null,
                    true,
                    true,
                    {
                        label: "Undo",
                        onClick: () => onSubmit(prevData),
                    }
                );
            });
    };

    const onSubmit = (formData) => {
        const clientContactLeadsData = transformFormDataToContactLeadData(
            formData,
            currentClientContactLeadData,
            departmentOptions.find((f) => f.value === formData.department)
                ?.label,
            allUsers?.data?.find((f) => f.id === formData.name)
        );

        const allData = {
            body: clientContactLeadsData,
        };

        modifier.mutateAsync(allData).then((response) => {
            onClose();
            sendNotification(
                undefined,
                <FormFeedbackNotificationText
                    responseStatus={response?.status}
                    isEditing={isEditing}
                    name={clientContactLeadsData.fullName}
                />
            );
        });
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={transformContactLeadDataToFormData(
                currentClientContactLeadData,
                departmentOptions.find(
                    (f) => f.label === currentClientContactLeadData?.department
                )?.value
            )}
            mode="onChange"
        >
            <VStack spacing="Three">
                <VStack spacing="Two">
                    <Dropdown
                        name="department"
                        validations={{
                            required: true,
                        }}
                        label="Department"
                        options={departmentOptions}
                        itemsAreLoading={departments.isLoading}
                    />
                    <Dropdown
                        name="name"
                        validations={{
                            required: true,
                        }}
                        label="Name"
                        itemsAreLoading={allUsers.isLoading}
                        disabledOptionsList={
                            !clientContactLeads.isLoading
                                ? clientContactLeads.data.map((data) => ({
                                      value: data.userId,
                                  }))
                                : []
                        }
                        disabledOptionTooltipText="Already in this Contact Lead"
                        options={contactLeadOptions}
                    />
                </VStack>
                <ModalCTA
                    isEditing={isEditing}
                    handleOnClickDeleteContactLead={
                        handleOnClickDeleteContactLead
                    }
                    onClose={onClose}
                    isLoading={modifier.isLoading}
                />
            </VStack>
        </Form>
    );
};

export default ClientContactLeadsForm;
