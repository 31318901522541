import PropTypes from "prop-types";
import ClientHeader from "./ClientHeader";
import ClientDetail from "./ClientDetail";
import { ClientContext } from "context/Allocations/ClientContext";

const Client = ({ client, clientId, data, employee, opened }) => {
    const clientContextValue = {
        clientData: { client: client, clientId: clientId },
        projectsData: data,
    };

    const handleToggleClick = () => {
        const divDetail = document.getElementById(`projects_${employee.userId}_${clientId}_${data[0].assignmentId}`);
        const divIco1 = document.getElementById(`ico1_client_${employee.userId}_${clientId}_${data[0].assignmentId}`);
        const divIco2 = document.getElementById(`ico2_client_${employee.userId}_${clientId}_${data[0].assignmentId}`);
        if (divDetail){
            if (divDetail.style.display == ""){
                divDetail.style.display = "none";
                if (divIco1 && divIco2){
                    divIco1.style.display = "none";
                    divIco2.style.display = "";
                }
            } else {
                divDetail.style.display = "";
                if (divIco1 && divIco2){
                    divIco1.style.display = "";
                    divIco2.style.display = "none";
                }
            }
        }
    }

    const assignmentId = data && data[0] ? data[0].assignmentId : 0;
    const headerId = `${employee.userId}_${clientId}_${assignmentId}`;
    const detailId = `projects_${employee.userId}_${clientId}_${assignmentId}`;

    return (
        <>
            <ClientContext.Provider value={clientContextValue}>
                <ClientHeader
                    name={client}
                    isOpen={opened}
                    onToggleClick={handleToggleClick}
                    id={headerId}
                />
                <div id={detailId} style={{display: opened ? "" : "none"}}>
                    <ClientDetail
                        data={data}
                        employee={employee}
                    />
                </div>
            </ClientContext.Provider>
        </>
    );
};

Client.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Client;
