import React from "react";

import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";

import DatePickerCalendarCard from "components/design-system/controls/holidays-calendar/DatePickerCalendarCard";

export const Calendar = ({ holidaysList = [], ...props }) => {
    const datePickerState = useDatePickerState(props);
    const ref = React.useRef();
    const { calendarProps } = useDatePicker(props, datePickerState, ref);

    return (
        <>
            <DatePickerCalendarCard
                {...calendarProps}
                holidaysList={holidaysList}
            />
        </>
    );
};
