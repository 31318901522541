import { useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useHolidays = () => {
    const { getDataAsync, postData, putData, deleteData } = useApi();
    const queryClient = useQueryClient();

    const getHolidaysApiCall = useCallback(async () => {
        const response = await getDataAsync("holidays");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const holidays = useQuery("holidaysList", getHolidaysApiCall);

    const createHolidayApiCall = useCallback(
        (body) => {
            return postData("holidays", {
                body,
            });
        },
        [postData]
    );

    const updateHolidayApiCall = useCallback(
        (body) => {
            return putData("holidays", {
                body,
            });
        },
        [putData]
    );

    const deleteHolidayApiCall = useCallback(
        (body) => {
            return deleteData("deleteHoliday", {
                body,
            });
        },
        [deleteData]
    );

    const mutationOptions = useMemo(() => {
        return {
            onSuccess: () => {
                queryClient.invalidateQueries("holidaysList");
            },
        };
    }, [queryClient]);

    const createHoliday = useMutation(createHolidayApiCall, mutationOptions);
    const updateHoliday = useMutation(updateHolidayApiCall, mutationOptions);
    const deleteHoliday = useMutation(deleteHolidayApiCall, mutationOptions);

    return useMemo(() => {
        return {
            holidays,
            createHoliday,
            updateHoliday,
            deleteHoliday,
        };
    }, [holidays, createHoliday, updateHoliday, deleteHoliday]);
};

export default useHolidays;
