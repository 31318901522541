import React from "react";
import PropTypes from "prop-types";

import Icon from "components/design-system/ui/Icon";
import {
    TextInputWrapper,
    StyledTextInput,
    TextInputRow,
    StyledTextInputSearchIcon,
    StyledTextInputClearIcon,
    StyledCharacterCounter,
} from "components/design-system/controls/input/StyledTextInput";
import { FEEDBACK_TYPES } from "components/design-system/config/feedbackTypes";

const TextInput = React.forwardRef(
    (
        {
            value = "",
            onChange = () => void 0,
            onKeyDown = () => void 0,
            disabled,
            feedbackType,
            placeholder,
            showClearIcon,
            characterCounter,
            type = "text",
            isSmallSize = false,
            ...rest
        },
        ref
    ) => {
        const resetValue = () => {
            onChange({ target: { value: "" } });
        };

        return (
            <TextInputWrapper disabled={disabled}>
                <StyledTextInput
                    {...rest}
                    type={type}
                    disabled={disabled}
                    feedbackType={feedbackType}
                    placeholder={placeholder}
                    value={value}
                    characterCounter={characterCounter}
                    showClearIcon={showClearIcon}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={ref}
                    isSmallSize={isSmallSize}
                    autoComplete="off"
                />
                {type === "search" && (
                    <StyledTextInputSearchIcon
                        name="search"
                        size={13}
                        disabled={disabled}
                    />
                )}
                <TextInputRow>
                    {showClearIcon && value.length >= 1 && (
                        <StyledTextInputClearIcon
                            type="button"
                            tabindex={-1}
                            onClick={resetValue}
                            aria-label="clear search field"
                        >
                            <Icon
                                name="clear"
                                color="Gray_2"
                                style={{ flexShrink: 0 }}
                            />
                        </StyledTextInputClearIcon>
                    )}
                    {characterCounter && (
                        <StyledCharacterCounter
                            variant="Descriptive_1_1"
                            color="Gray_4"
                        >
                            {value.length}
                        </StyledCharacterCounter>
                    )}
                </TextInputRow>
            </TextInputWrapper>
        );
    }
);

TextInput.propTypes = {
    disabled: PropTypes.bool,
    feedbackType: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
    placeholder: PropTypes.string,
    showClearIcon: PropTypes.bool,
    characterCounter: PropTypes.bool,
    type: PropTypes.string,
    isSmallSize: PropTypes.bool,
};
export default TextInput;
