import styled from "styled-components";
import Alert from "../Icon/Alert";
import Button from "../UI/Button";
import moment from "moment";
import Link from "../UI/Link";

import { useEmailError } from "hooks/Errors/useEmailError";
import { useTestConnection } from "hooks/useTestConnection";

const Icon = styled.div`
    height: fit-content;
`;

const Content = styled.div`
    padding-top: 0.5rem;
    font-size: ${global.config.fonts.sizes.medium};
    font-family: ${global.config.fonts.families.light};
    height: fit-content;
`;

const ErrorDetail = styled.div`
    color: ${global.config.colors.grey2};
    font-size: ${global.config.fonts.sizes.small};
    height: fit-content;
    padding-bottom: 1rem;
    p {
        margin: 0;
    }
`;
const Header = styled.div`
    padding-top: 0.5rem;
    font-size: ${global.config.fonts.sizes.xlarge};
    font-family: ${global.config.fonts.families.medium};
    height: fit-content;
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Footer = styled.div`
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.5rem 4rem 4rem 4rem;
    position: relative;

    min-width: ${(props) => (props.popup ? "28.25rem" : "inherit")};
    width: ${(props) => (props.popup ? "28.25rem" : "inherit")};
    max-width: ${(props) => (props.popup ? "28.25rem" : "inherit")};

    min-height: ${(props) => (props.popup ? "35.25rem" : "inherit")};
    height: ${(props) => (props.popup ? "35.25rem" : "inherit")};
    max-height: ${(props) => (props.popup ? "35.25rem" : "inherit")};

    text-align: ${(props) => (props.popup ? "center" : "left")};

    ${Header} {
        text-align: ${(props) => (props.popup ? "center" : "left")};
    }
    ${Body} {
        text-align: ${(props) => (props.popup ? "center" : "left")};
    }
    ${Footer} {
        text-align: ${(props) => (props.popup ? "left" : "center")};
        justify-content: ${(props) => (props.popup ? "flex-end" : "center")};
    }
`;

const StyledButton = styled.button`
    background-color: inherit;
    border: none;
    padding: 0;
    width: fit-content;
    position: absolute;
    top: 1rem;
    right: 1rem;
    &:hover {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:active {
        background-color: ${global.config.colors.orange1};
        border: none;
        box-shadow: none;
        outline: none;
    }
    &:focus {
        background-color: inherit;
        border: none;
        box-shadow: none;
        outline: none;
        outline: none;
    }
`;

const ReloadButton = styled(Link)`
    width: 81px;
    background: transparent;
    color: ${global.config.colors.black};
    font-size: ${global.config.fonts.sizes.smedium};
    margin-right: 32px;
    order: -1;
`;

const DEFAULT_ERROR = {
    detail: {
        title: "Something Went Wrong",
        message: "Sorry, we couldn't process your request because of an error.",
        actionMessage:
            " Please check your URL, or reload the page to start again.",
    },
    status: undefined,
    date: undefined,
    correlationId: undefined,
    action: undefined,
    errorPageURL: undefined,
    reloadPageURL: global.config.routes.root,
    rawError: undefined,
    showDetail: false,
};

const ErrorModal = (props) => {
    const { error, popup } = props;

    const {
        detail,
        status,
        date,
        correlationId,
        action,
        reloadPageURL,
        showDetail,
        rawError,
    } = error ?? DEFAULT_ERROR;

    const { sendEmail } = useEmailError();
    const { testConnection } = useTestConnection();

    const handleclick = () => {
        sendEmail(rawError);
    };

    const handleTestConnection = () => {
        testConnection().then((rv) => {
            alert(rv);
        });
    };

    const reloadPage = (url) => {
        action && action();
        window.location.assign(url);
    };

    return (
        <Wrapper popup={popup}>
            {popup}
            <Icon>
                <Alert size="25" viewBox="18" />
            </Icon>
            <Header>{detail ? detail.title : ""}</Header>
            <Body>
                <Content>
                    <p>
                        <b>{detail ? detail.subTitle : ""}</b>
                    </p>
                    <p>{detail ? detail.message : ""}</p>
                    <br />
                    {detail.stack && (
                        <div
                            style={{
                                height: "100px",
                                height: "100px",
                                overflow: "auto",
                            }}
                        >
                            {detail.stack.replace(/<[^>]+>/g, "")}
                        </div>
                    )}
                    <br />
                    {
                        (rawError && rawError.detail) ? rawError.detail : ""
                    }
                    <br />
                    <br />
                    <p>{detail ? detail.actionMessage : ""}</p>
                    <br />
                    {showDetail && (
                        <ErrorDetail>
                            <p>
                                Date:{" "}
                                {date
                                    ? moment(date).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                      )
                                    : "Unknown"}
                            </p>
                            <p>Error code: {status ?? "Unknown"}</p>
                            <p>Transaction ID: {correlationId ?? "Unknown"}</p>
                        </ErrorDetail>
                    )}
                </Content>
            </Body>
            <Footer>
                <Button style={{ width: "11.75rem" }} onClick={handleclick}>
                    Email Support
                </Button>
                {!popup && (
                    <ReloadButton onClick={() => reloadPage(reloadPageURL)}>
                        Reload Page
                    </ReloadButton>
                )}
                <Button
                    style={{ width: "11.75rem" }}
                    onClick={handleTestConnection}
                >
                    Test Connection
                </Button>
            </Footer>
        </Wrapper>
    );
};

ErrorModal.propTypes = {};

export default ErrorModal;
