import { useMemo, useCallback } from "react";
import { useQuery } from "react-query";

import { useApi } from "utilities/API/Helpers/useApi";

const useFilters = () => {
    const { getDataAsync } = useApi();

    const getRateCardApiCall = useCallback(async () => {
        const response = await getDataAsync("getRateCards");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const getBizUnitsApiCall = useCallback(async () => {
        const response = await getDataAsync("getBizUnits");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const getUsersApiCall = useCallback(async () => {
        const response = await getDataAsync("getLeadProducers");
        return response.status != 204 ? response.json() : null;
    }, [getDataAsync]);

    const rateCards = useQuery("ratecardsList", getRateCardApiCall);
    const bizUnits = useQuery("bizUnitsList", getBizUnitsApiCall);
    const leadProducers = useQuery("leadProducerList", getUsersApiCall);

    return useMemo(() => {
        return {
            rateCards,
            bizUnits,
            leadProducers,
        };
    }, [rateCards, bizUnits, leadProducers]);
};

export default useFilters;
