import { Typography } from "@material-ui/core";

export const ErrorComponent = ({ error }) => {
    return (
        <>
            <Typography variant="h6">Something went wrong...</Typography>
            <br />
            {error.errorCode}-{error.message}
        </>
    );
};
