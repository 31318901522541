import styled from "styled-components";
import PropTypes from "prop-types";

const Input = styled.input`
    text-align: center;
    width: 100%;
    border: none;
    background: transparent;
    font-size: ${global.config.fonts.sizes.small};
    font-family: ${global.config.fonts.families.bold};
    -moz-appearance: textfield;
    padding: 0;
    margin: 0;
    user-select: none;
    cursor: pointer;
    user-select: none;

    &:focus {
        outline: none;
        user-select: none;
    }
`;

const Text = styled.span`
    font-size: ${global.config.fonts.sizes.small};
    font-family: ${global.config.fonts.families.bold};
`;
const PLACEHOLDER_VALUE = "--";

const InputLayer = ({
    onChange,
    value,
    editable,
    color,
    onUpdate,
    onFocus,
    ...props
}) => {
    
    const handleKeyDown = (event) => {
        const allowedValues = /^(\s*|\d+)$/;
        !event.target.value.match(allowedValues) && event.preventDefault();
    };

    const handleChange = (event) => {
        onChange(event);
    }
    
    const handleFocus = (event) => {
        onFocus(event);
        event.target.select();
    }
    
    const handleBlur = (event) => {
        onUpdate(event);
    }

    return (
        <>
            <div className={props.className}>
                {editable ? (
                    <Input
                        type="number"
                        style={{ color: color }}
                        value={value}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={PLACEHOLDER_VALUE}
                        autoFocus
                        data-tabindex={props.tabIndex}
                        data-projectId={props.projectId}
                    />
                ) : (
                    <Text>{value || PLACEHOLDER_VALUE}</Text>
                )}
            </div>
        </>
    );
};

InputLayer.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
};

export default InputLayer;
